import {createStyles, makeStyles} from '@material-ui/core';
import {MAGENTA} from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({

        button: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: 48,
        },
        icon: {
            color: MAGENTA,
        },
        adorment: {
            marginRight: '-14px',
        },
    }),
);

export default useStyles;
