import {FC, ReactNode} from "react";
import {createStyles, Link, makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            margin: '0',
            color: theme.palette.secondary.main,
            fontSize: 16,
            lineHeight: '24px',
            textAlign: 'end',
            textDecoration: 'none',
            overflowWrap: 'anywhere',
            whiteSpace: 'pre-line',
        }
    }),
);

interface Props {
    to?: string;
    onClick?: () => void;
    children: ReactNode;
}

const CalendarLink: FC<Props> = (props) => {
    const {to, onClick, children} = props;
    const classes = useStyles();

    return (
        <Link
            className={classes.link}
            underline="hover"
            href={to || '#'}
            onClick={onClick || null}
            component={to ? 'a' : 'button'}
        >
            {children}
        </Link>
    )
}

export default CalendarLink;