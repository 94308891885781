import {
    ACCOUNT_DATA,
    ACCOUNT_DATA_PAGE,
    APPOINTMENT_CALENDAR,
    APPOINTMENT_SEARCH_WIDGET_PAGE,
    AVAILABILITY_TEMPLATE,
    HELP_PAGE,
    MEDIC_PROFILE_PAGE,
    MEDIC_SETTINGS,
    MEDIC_WORKING_TIME_SETTLEMENTS,
    MEDICAL_TEST_RESULTS,
    USER_ACCOUNT_PAGE,
    YOUR_BUNDLES,
    YOUR_DOCTORS_PAGE,
    VOUCHER_PAGE,
    VOUCHER,
    SICK_LEAVES
} from '../const/routes';
import {
    CalendarToday,
    EditOutlined,
    FavoriteBorderOutlined,
    HelpOutline,
    InsertDriveFile,
    InsertDriveFileOutlined,
    MicNone,
    Search,
    SettingsOutlined,
    CardGiftcardOutlined,
    LocalHospitalOutlined
} from '@material-ui/icons';
import {MobileNavigationLinkGroups} from '../types/navigation';
import {ReactComponent as MedicalTestResult} from '../assets/img/testResults.svg';
import {Path} from './landingPaths';
import configuration from '../configuration';

export const getLogOutNavigationLinks = (landingPageUrl: {
    getCurrentUrl: () => string;
    getRoute: (path: Path) => any;
}) =>
    [
        {
            id: 0,
            links: [
                {
                    id: 0,
                    labelKey: 'searchForADoctor',
                    route:
                        configuration?.environment === 'Development'
                            ? APPOINTMENT_SEARCH_WIDGET_PAGE
                            : landingPageUrl.getRoute('SEARCH_FOR_A_DOCTOR'),
                    icon: <Search />,
                    external: true,
                },
                {
                    id: 1,
                    labelKey: 'individualPackages',
                    route: landingPageUrl.getRoute('INDIVIDUAL_PACKAGES'),
                    icon: <InsertDriveFile />,
                    external: true,
                },
                {
                    id: 2,
                    labelKey: 'articles',
                    route: landingPageUrl.getRoute('NEWS'),
                    icon: <MicNone />,
                    external: true,
                },
                {
                    id: 3,
                    labelKey: 'help',
                    route: `${HELP_PAGE}`,
                    icon: <HelpOutline />,
                    external: false,
                },
            ],
        },
    ] as MobileNavigationLinkGroups[];

export const getMedicMobileNavigationLinks = () =>
    [
        {
            id: 0,
            links: [
                {
                    id: 0,
                    labelKey: 'availabilityTemplate',
                    route: `${MEDIC_SETTINGS}${AVAILABILITY_TEMPLATE}`,
                    icon: <CalendarToday />,
                },
                {
                    id: 1,
                    labelKey: 'myProfile',
                    route: `${MEDIC_SETTINGS}${MEDIC_PROFILE_PAGE}`,
                    icon: <EditOutlined />,
                },
                {
                    id: 2,
                    labelKey: 'accountDetails',
                    route: `${MEDIC_SETTINGS}${ACCOUNT_DATA_PAGE}`,
                    icon: <SettingsOutlined />,
                },
                {
                    id: 3,
                    labelKey: 'workingTimeSettlements',
                    route: `${MEDIC_SETTINGS}${MEDIC_WORKING_TIME_SETTLEMENTS}`,
                    icon: <InsertDriveFileOutlined />,
                },
            ],
        },
    ] as MobileNavigationLinkGroups[];

export const getPatientNavigationLinks = (landingPageUrl: {
    getCurrentUrl: () => string;
    getRoute: (path: Path) => any;
}) =>
    [
        {
            id: 0,
            links: [
                {
                    id: 0,
                    labelKey: 'searchForADoctor',
                    route:
                        configuration?.environment === 'Development'
                            ? APPOINTMENT_SEARCH_WIDGET_PAGE
                            : landingPageUrl.getRoute('SEARCH_FOR_A_DOCTOR'),
                    icon: <Search />,
                    external: true,
                },
                {
                    id: 1,
                    labelKey: 'individualPackages',
                    route: landingPageUrl.getRoute('INDIVIDUAL_PACKAGES'),
                    icon: <InsertDriveFile />,
                    external: true,
                },
                {
                    id: 2,
                    labelKey: 'articles',
                    route: landingPageUrl.getRoute('NEWS'),
                    icon: <MicNone />,
                    external: true,
                },
                {
                    id: 3,
                    labelKey: 'help',
                    route: `${HELP_PAGE}`,
                    icon: <HelpOutline />,
                    external: false,
                },
                
            ],
        },
        {
            id: 1,
            links: [
                {
                    id: 0,
                    labelKey: 'consultationCalendar',
                    route: `${USER_ACCOUNT_PAGE}${APPOINTMENT_CALENDAR}`,
                    icon: <CalendarToday />,
                },
                {
                    id: 1,
                    labelKey: 'yourDoctors',
                    route: `${USER_ACCOUNT_PAGE}${YOUR_DOCTORS_PAGE}`,
                    icon: <FavoriteBorderOutlined />,
                },
                {
                    id: 2,
                    labelKey: 'medicalTestResults',
                    route: `${USER_ACCOUNT_PAGE}${MEDICAL_TEST_RESULTS}`,
                    icon: <MedicalTestResult />,
                },
                {
                    id:3,
                    labelKey: 'sickLeaves',
                    route: `${USER_ACCOUNT_PAGE}${SICK_LEAVES}`,
                    icon: <LocalHospitalOutlined style={{color:'inherit'}}/>
                },
                {
                    id: 4,
                    labelKey: 'yourPackages',
                    route: `${USER_ACCOUNT_PAGE}${YOUR_BUNDLES}`,
                    icon: <InsertDriveFileOutlined />,
                },
                {
                    id: 5,
                    labelKey: 'accountDetails',
                    route: `${USER_ACCOUNT_PAGE}${ACCOUNT_DATA}`,
                    icon: <SettingsOutlined style={{color: 'inherit'}} />,
                },
                {
                    id:6,
                    labelKey: 'vouchers',
                    route: `${USER_ACCOUNT_PAGE}${VOUCHER}`,
                    icon: <CardGiftcardOutlined style={{color:'inherit'}}/>
                },
                
            ],
        },
    ] as MobileNavigationLinkGroups[];
