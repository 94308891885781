import {Button, ButtonProps, Theme, withStyles} from '@material-ui/core';
import {CSSProperties, WithStylesOptions} from '@material-ui/styles';

const CustomButton = withStyles<string, WithStylesOptions<Theme>, ButtonProps>({
    root: props => {
        const rootStyles = {
            borderRadius: 3,
            boxShadow: 'none',
            height: 48,
        } as CSSProperties;

        if (props.variant !== 'outlined') {
            rootStyles.border = 0;
        }

        if (props.variant === 'contained' && props.color === 'secondary') {
            rootStyles.fontWeight = 'bold';
        }

        return rootStyles;
    },
})(Button);

export default CustomButton;
