import { Grid, Box, IconButton } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Loading, MedicalTestDetails, MedicalTestDiagnosticOrderSummary, MedicalTestDiagnosticServicesDetails, MedicaltestFiles } from "../../components";
import { PatientOrderedDiagnosticOrderHeadersDto } from "../../services/diagnostic/orderHeadersProvider";
import referralsProvider from "../../services/referralsProvider";
import useStyles from "./MedicalTestResultDetailsPageStyle";
import { useTranslation } from "react-i18next";
import { MEDICAL_TEST_RESULTS_PAGE } from "../../const/routes";
import {GoBackIcon} from "../../assets";
import i18nNamespaces from "../../const/i18nNamespaces";



const MedicalTestResultDetailsPage = () =>{
    const classes = useStyles();
    const history = useHistory();
    
    const {id} = useParams<{id: string}>();
    const [data, setData] = useState<PatientOrderedDiagnosticOrderHeadersDto>();
    const [loading, setLoading] = useState<boolean>(false); 
    
    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);
    const fetchData = async () => {        
        setLoading(true);
        const {data} = await referralsProvider.getOrderedMedicalTestDetails(id);
        setData(data);
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box>
            <Loading withBackdrop={true} loading={loading} />
            <Grid container>
            
                    <Grid item md={6} xs={12} className={classes.pageLeftColumn}>
                        <Box
                                className={classes.goBackWrapper}
                                onClick={
                                    () => history.push(`${MEDICAL_TEST_RESULTS_PAGE}`)
                                }>
                                <IconButton className={classes.goBackButton}>
                                    <img src={GoBackIcon} alt="Go back" />
                                </IconButton>
                                {t('testDetails')}
                            </Box>
                            {data && <MedicalTestDetails orderedDiagnosticServiceOrder={data} />}
                    </Grid>

                <Grid item md={6} xs={12}>
                    {data && <MedicalTestDiagnosticOrderSummary 
                        diagnosticOrderHeaderdId={data.diagnosticOrderHeaderId}
                        orderExtenralId={data.orderExternalId}/>
                    }
                    {data && 
                        <MedicalTestDiagnosticServicesDetails
                            diagnosticServices={data.orderedDiagnosticServices}
                            type={data.type}
                            medicalPackage={data.medicalPackageName}
                        />
                    }
                    {data && <MedicaltestFiles/>}
                </Grid>
            </Grid>
        </Box>
        
    )
}

export default MedicalTestResultDetailsPage;