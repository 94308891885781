import {List, ListItem} from '@material-ui/core';
import CustomChip from '../../../common/CustomChip/CustomChip';
import {selectMedicTags, selectNewMedicTags, setNewMedicTags} from "../../../../store/medicProfileSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/customReduxHooks";
import {isNotNull} from "../../../../utils/lang";
import useStyles from './AddedTagsStyles';
import {Tag} from "../../../../types/medic";

const AddedTags = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const medicTags = useAppSelector(selectMedicTags);
    const newMedicTags = useAppSelector(selectNewMedicTags);

    const tagsListItems = isNotNull(newMedicTags)
        ? newMedicTags
        : medicTags;

    const deleteTagClickHandler = (tag: Tag) => {
        const newMedicTagsUpdate = [...tagsListItems];
        const tagIndex = tagsListItems.indexOf(tag);
        newMedicTagsUpdate.splice(tagIndex, 1);
        dispatch(
            setNewMedicTags(newMedicTagsUpdate)
        );
    }

    return (
        <List
            className={classes.list}
            disablePadding={true}
        >
            {
                tagsListItems.map(tag => (
                    <ListItem
                        className={classes.listItem}
                        key={tag.id}
                        disableGutters={true}
                    >
                        <CustomChip
                            label={tag.name}
                            onDelete={() => deleteTagClickHandler(tag)}
                        />
                    </ListItem>
                ))
            }
        </List>
    )
}

export default AddedTags;