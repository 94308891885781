
import {makeStyles, Theme} from '@material-ui/core';
import { GRAY } from '../../../const/colors';
import {desktop, LARGE_DESKTOP_BREAKPOINT, mobile} from '../../../const/sizes';

const textsCommonStyles = {
    size: 16,
    lineHeight: '24px',
}

const useStyles = makeStyles((theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
        arrowIcon: {
            cursor: 'pointer',
        },
    },
    photo: {
        width: '70px',
        height: '70px',
    },
    type: {
        ...textsCommonStyles,
        fontWeight: 'bold',
    },
    laboratoryAddress: {
        ...textsCommonStyles,
    },
    patientName: {
        ...textsCommonStyles,
    },
    status:{
        ...textsCommonStyles,
        color:GRAY
    }
}));

export default useStyles;
