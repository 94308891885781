import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {AppointmentPurchaseType} from '../../../const/appointmentPurchaseType';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {MedicalServicePurchaseOptionsDto} from '../../../types/medicalServicePurchaseOptions';
import {
    formatPercent,
    formatPercentWithoutMultiply,
} from '../../../utils/formatPercent';
import {formatPrice} from '../../../utils/price';
import DiscountCode from '../../payment/DiscountCode/DiscountCode';
import useStyles from './TimeSlotPaymentPriceStyles';

type TimeSlotPaymentPriceProps = {
    medicalServicePurchaseOptions: MedicalServicePurchaseOptionsDto;
    individualPackageDraftId?: number;
    patientGroupDraftId?: number;
    type: AppointmentPurchaseType;
    onUseDiscountCode: (code: string) => void;
    loading: boolean;
};

const TimeSlotPaymentPrice = ({
    medicalServicePurchaseOptions,
    individualPackageDraftId,
    patientGroupDraftId,
    type,
    onUseDiscountCode,
    loading,
}: TimeSlotPaymentPriceProps) => {
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS_PAYMENT);

    const classes = useStyles();

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const oneTimePrice =
        type === ('OneTime' as AppointmentPurchaseType) &&
        medicalServicePurchaseOptions.oneTimePrice;

    const oneTimePriceWithDiscountCode =
        type === ('OneTime' as AppointmentPurchaseType) &&
        medicalServicePurchaseOptions.oneTimePriceWithDiscountCode;

    const OneTimePriceDiscountCodeErrorText =
        type === ('OneTime' as AppointmentPurchaseType) &&
        medicalServicePurchaseOptions.oneTimePriceDiscountCodeErrorText;

    const individualPackage =
        type === ('IndividualPackage' as AppointmentPurchaseType) &&
        individualPackageDraftId &&
        medicalServicePurchaseOptions.individualPackageOptions.find(
            x => x.individualPackageDraftId == individualPackageDraftId,
        );

    const groupPackage =
        type === ('GroupPackage' as AppointmentPurchaseType) &&
        patientGroupDraftId &&
        medicalServicePurchaseOptions.groupPackageOptions.find(
            x => x.patientGroupDraftId === patientGroupDraftId,
        );

    const discountCode = medicalServicePurchaseOptions.discountCode;

    const getPriceText = (price: number) => `${formatPrice(price)} zł`;

    const priceAlignment = oneTimePrice ? 'center' : 'flex-start';

    return (
        <Box className={classes.priceSection}>
            {individualPackage && (
                <Box className={classes.packageName}>
                    {individualPackage.name}
                </Box>
            )}
            {groupPackage && (
                <Box className={classes.packageName}>{groupPackage.name}</Box>
            )}
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems={priceAlignment}
                className={classes.priceContainer}
            >
                <Box className={classes.priceText}>
                    {(individualPackage || groupPackage) && (
                        <Box>{t('consultationCost')}:</Box>
                    )}
                    {oneTimePrice !== null && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            className={classes.oneTimeConst}
                        >
                            <Box>{t('oneTimeCost1')}</Box>
                            {isDesktop && (
                                <Box style={{whiteSpace: 'pre'}}> </Box>
                            )}
                            <Box>{t('oneTimeCost2')}:</Box>
                        </Grid>
                    )}
                </Box>
                {isDesktop && <Box style={{whiteSpace: 'pre'}}> </Box>}
                {(individualPackage || groupPackage) && (
                    <Box>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                            className={classes.priceValueContainer}
                        >
                            <Box className={classes.price}>
                                {individualPackage &&
                                    getPriceText(individualPackage.price)}
                                {groupPackage &&
                                    getPriceText(groupPackage.price)}
                            </Box>
                            {isDesktop && (
                                <Box style={{whiteSpace: 'pre'}}> </Box>
                            )}
                            <Box className={classes.discount}>
                                ({t('discount')}{' '}
                                {individualPackage &&
                                    formatPercent(individualPackage.discount)}
                                {groupPackage &&
                                    formatPercent(groupPackage.discount)}
                                )
                            </Box>
                        </Grid>
                    </Box>
                )}
                {oneTimePrice !== null &&
                    oneTimePriceWithDiscountCode === null && (
                        <Box>
                            <Box className={classes.price}>
                                {getPriceText(oneTimePrice)}
                            </Box>
                        </Box>
                    )}
                {oneTimePriceWithDiscountCode && (
                    <Box>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems={priceAlignment}
                            className={classes.priceContainer}
                        >
                            <Box className={classes.price}>
                                {getPriceText(oneTimePriceWithDiscountCode)}
                            </Box>
                            <Box className={classes.oldPrice}>
                                {getPriceText(oneTimePrice)}
                            </Box>
                            <Box className={classes.discount}>
                                ({t('discount')}{' '}
                                {formatPercentWithoutMultiply(
                                    discountCode.discountPercentage,
                                )}
                                )
                            </Box>
                        </Grid>
                    </Box>
                )}
            </Grid>

            {discountCode === null && oneTimePrice !== null && (
                <DiscountCode
                    onUseDiscountCode={onUseDiscountCode}
                    errorText={OneTimePriceDiscountCodeErrorText}
                    loading={loading}
                ></DiscountCode>
            )}
        </Box>
    );
};

export default TimeSlotPaymentPrice;
