import React, {useCallback, useContext, useEffect} from 'react';
import {useHistory} from 'react-router';
import {Box, createStyles, Divider, Hidden, makeStyles, Snackbar, Theme, useMediaQuery} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {GoBackBar, Loading, LogoAndCloseTopBar, TopBar, TwoColumnLayout} from '../../../components';
import {APPOINTMENT_CALENDAR, USER_ACCOUNT_PAGE} from '../../../const/routes';
import MedicalLaboratoriesSearch from './components/MedicalLaboratoriesSearch';
import {
    DiagnosticRegionsContext,
} from '../../../hooks/diagnostic/regions/useDiagnosticRegionContext';
import useDiagnosticRegions from '../../../hooks/diagnostic/regions/useDiagnosticRegions';
import useLaboratoryAddresses from '../../../hooks/diagnostic/laboratoryAddresses/useLaboratoryAddresses';
import {WHITE} from '../../../const/colors';
import {
    LaboratoryAddressesContext,
} from '../../../hooks/diagnostic/laboratoryAddresses/useLaboratoryAddressesContext';
import useQuery from '../../../hooks/useQuery';
import SelectedLaboratorySummary from './components/SelectedLaboratorySummary';
import useOrderMedicalTests from "../../../hooks/diagnostic/orderHeaders/useOrderMedicalTests";
import {OrderMedicalTestsContext} from '../../../hooks/diagnostic/orderHeaders/useOrderMedicalTestContext';
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import {AlertContext} from "../../../contexts/AlertContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        goBackWrapper: {
            marginTop: 1,
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                marginTop: 0,
                backgroundColor: 'transparent',
            }
        }
    }),
);

const OrderMedicalTestsPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const query = useQuery();
    const openAlert = useContext(AlertContext);
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);

    const medicalTestNameFromQuery = query.get('medicalTestName');

    const medicalTestName = medicalTestNameFromQuery && tReferrals(medicalTestNameFromQuery);

    useEffect(
        () => {
            if (!medicalTestNameFromQuery) {
                openAlert({
                    severity: 'warning',
                    message: tReferrals('cannotFindMedicalTestNameParameterInUrlQuery')
                })
                console.warn('cannotFindMedicalTestNameParameterInUrlQuery');
            }
        },
        [medicalTestNameFromQuery]
    );

    const referralIdPathParam = query.get('referralId');

    const diagnosticRegionsState = useDiagnosticRegions();
    const {regionsLoading, regionsError, selectedRegion, fetchRegions} = diagnosticRegionsState;

    const laboratoryAddressesState = useLaboratoryAddresses();
    const {
        laboratoryAddresses,
        laboratoryAddressesLoading,
        laboratoryAddressesError,
        selectedLaboratoryAddress,
        fetchLaboratoryAddresses,
        setSelectedLaboratoryAddress,
        resetLaboratoryAddressesState,
    } = laboratoryAddressesState;

    const orderMedicalTestsState = useOrderMedicalTests();
    const {loading: orderMedicalTestsLoading, error: orderMedicalTestsError} = orderMedicalTestsState;

    const loading = regionsLoading || laboratoryAddressesLoading || orderMedicalTestsLoading;
    const error = regionsError ||
        laboratoryAddressesError ||
        orderMedicalTestsError;

    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const selectedLaboratoryDetailsDisplayedMobile = !mdUp && selectedLaboratoryAddress;

    const goBackClickHandler = useCallback(() => {
        history.push(`${USER_ACCOUNT_PAGE}/${APPOINTMENT_CALENDAR}`);
    }, []);

    const unselectLaboratoryAddress = useCallback(() => {
        setSelectedLaboratoryAddress(null);
    }, []);

    useEffect(() => {
        fetchRegions();
    }, []);

    useEffect(() => {
        if (selectedRegion && referralIdPathParam) {
            fetchLaboratoryAddresses({
                referralId: referralIdPathParam,
                regionId: selectedRegion.regionId,
            });
        } else if (!selectedRegion) {
            resetLaboratoryAddressesState();
            setSelectedLaboratoryAddress(null);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (mdUp) {
            setSelectedLaboratoryAddress(laboratoryAddresses[0] || null);
        }
    }, [laboratoryAddresses]);

    return (
        <Box>
            <DiagnosticRegionsContext.Provider value={diagnosticRegionsState}>
                <LaboratoryAddressesContext.Provider value={laboratoryAddressesState}>
                <TwoColumnLayout>
                    <TwoColumnLayout.Header>
                        {
                            selectedLaboratoryDetailsDisplayedMobile
                                ? <LogoAndCloseTopBar onCloseIconClick={unselectLaboratoryAddress} />
                                : <TopBar />
                        }
                    </TwoColumnLayout.Header>
                    <TwoColumnLayout.Main>
                        <TwoColumnLayout.LeftColumn>
                            {
                                selectedLaboratoryDetailsDisplayedMobile
                                    ? null
                                    : (
                                        <>
                                            <Box className={classes.goBackWrapper}>
                                                <GoBackBar
                                                    text={medicalTestName}
                                                    onClick={goBackClickHandler}
                                                />
                                            </Box>
                                            <Hidden smDown>
                                                <Divider />
                                            </Hidden>
                                            {!error && <MedicalLaboratoriesSearch />}
                                        </>
                                    )
                            }
                        </TwoColumnLayout.LeftColumn>
                        <TwoColumnLayout.RightColumn>
                            <OrderMedicalTestsContext.Provider value={orderMedicalTestsState}>
                                {selectedLaboratoryAddress && !error && <SelectedLaboratorySummary />}
                            </OrderMedicalTestsContext.Provider>
                        </TwoColumnLayout.RightColumn>
                    </TwoColumnLayout.Main>
                </TwoColumnLayout>
                </LaboratoryAddressesContext.Provider>
            </DiagnosticRegionsContext.Provider>
            <Loading withBackdrop={true} loading={loading} />
            {
                error && (
                    <Snackbar
                        open={true}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                )
            }
        </Box>
    );
}

export default OrderMedicalTestsPage;
