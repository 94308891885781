import {createTheme, ThemeOptions} from '@material-ui/core/styles';
import {MuiPickersOverrides} from '@material-ui/pickers/typings/overrides';
import {
    WHITE,
    BACKGROUND_PRIMARY,
    MAGENTA,
    PRIMARY,
    TEXT_PRIMARY,
    TEXT_SECONDARY,
    BLACK,
    RED,
} from '../const/colors';

type overridesNameToClassKey = {
    [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const defaultThemeOptions: ThemeOptions = {
    spacing: 16,
    palette: {
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: MAGENTA,
        },
        text: {
            primary: TEXT_PRIMARY,
            secondary: TEXT_SECONDARY,
        },
        background: {
            default: BACKGROUND_PRIMARY,
        },
    },
    typography: {
        fontFamily: 'Ubuntu',
        fontSize: 16,
        h1: {
            fontWeight: 700,
            fontSize: 56,
        },
        h3: {
            fontWeight: 700,
            fontSize: 36,
        },
    },
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 15px) scale(1)',
            },
        },
        MuiTypography: {
            caption: {
                fontWeight: 'bold',
                color: 'black',
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: MAGENTA,
            },
        },
        MuiPickersCalendar: {
            transitionContainer: {
                minHeight: '254px',
            },
        },
        MuiPickersDay: {
            day: {
                borderColor: '#e0e0e0',
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 0,
                margin: 0,
                width: '40px',
                height: '40px',
            },
            daySelected: {
                backgroundColor: MAGENTA,
                borderColor: MAGENTA,
            },
            current: {
                borderColor: BLACK,
                borderWidth: 2,
            },
            dayDisabled: {
                backgroundColor: '#F5F5F5',
                color: '#BDBDBD',
            },
        },
        MuiInputBase: {
            root: {
                width: 300,
                height: 48,
                minHeight: 48, //for multiline
                '&.MuiAutocomplete-inputRoot.MuiInputBase-multiline': {
                    // multiline is broken, by setting fixed width,
                    // paddings change makes MuiInputBase inside Autocomplete having 48px height
                    height: 'auto',
                    paddingTop: '6px!important',
                    paddingBottom: '0!important',
                },
                '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
                    {
                        // backgroundColor: 'red',
                        padding: '0px',
                    },
            },
        },
        MuiSelect: {
            select: {
                padding: '0 17px',
            },
            selectMenu: {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            },
        },
        MuiTableCell: {
            head: {
                fontWeight: 'bold',
                fontSize: 20,
            },
        },
        MuiButton: {
            contained: {
                height: 48,
                fontWeight: 'bold',
            },
        },
    },
};

const alternativeThemeOptions = {
    ...defaultThemeOptions,
    palette: {
        ...defaultThemeOptions.palette,
        background: {
            default: WHITE,
        },
    },
};

export const defaultTheme = createTheme(defaultThemeOptions);

export const alternativeTheme = createTheme(alternativeThemeOptions);
