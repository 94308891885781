import {DateTime} from 'luxon';
import clsx from 'clsx';
import {Box, Grid, useTheme} from "@material-ui/core";
import {TEXT_PRIMARY, WHITE} from "../../../../const/colors";
import useStyles from "./AppointmentTimeDetailsBarStyles";
import {getRelativeDay} from "../../../../utils/date";
import { getLanguage } from '../../../../utils/translation';
import i18n from '../../../../i18n/config';

interface Props {
    isNow: boolean;
    isInTheFuture: boolean;
    date: DateTime;
    square?: boolean;
}

const AppointmentTimeDetails = ({isNow, isInTheFuture, date, square}: Props) => {
    const classes = useStyles();
    const theme = useTheme();

    const language = getLanguage(i18n?.language);
    
    return (
        <Grid
            className={
                clsx({
                    [classes.container]: !square,
                    [classes.containerSquare]: square,
                })
            }
            style={{
                backgroundColor: isNow
                    ? theme.palette.secondary.main
                    : theme.palette.grey[300],
            }}
        >
            <Box
                style={{
                    color: isNow ? WHITE : TEXT_PRIMARY,
                }}
            >
                {getRelativeDay(date, 'dd-MM-yyyy')}
            </Box>
            <Box
                className={clsx(classes.timeValueContainer, {
                    [classes.timeValueContainerSquare]: square
                })}
                style={{
                    color: isNow
                        ? WHITE
                        : isInTheFuture
                            ? theme.palette.secondary.main
                            : theme.palette.grey[500],
                }}
            >
                {language==='EN' ? date.toFormat('hh:mm a') : date.toFormat('HH:mm')}
            </Box>
        </Grid>
    );
}

export default AppointmentTimeDetails;