import {
    CaseReducer,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {Device} from '@twilio/voice-sdk';
import {isFirefox, isSafari} from 'react-device-detect';
import {LocalTrack, Room} from 'twilio-video';

import {LoadingStatus} from '../const/loadingStatus';
import AppointmentsProvider from '../services/appointmentsProvider';
import BrowserDefaultMediaDevicesProvider from '../services/browserDefaultMediaDevicesProvider';
import {Appointment} from '../types/appointments';
import mapAppointment, {synchronizeAppointment} from './mappers/mapAppointment';
import {RootState} from './store';
import {ConversationStatus} from '../types/conversation';
import {DateTime} from 'luxon';
import appointmentsProvider from "../services/appointmentsProvider";

const sliceName = 'consultation';

const initialState = {
    appointmentDetails: null as Appointment,
    room: null as Room,
    status: 'idle' as LoadingStatus,
    audioInputDevices: null as MediaDeviceInfo[],
    selectedAudioInputDeviceId: null as string,
    audioOutputDevices: null as MediaDeviceInfo[],
    selectedAudioOutputDeviceId: null as string,
    videoInputDevices: null as MediaDeviceInfo[],
    selectedVideoInputDeviceId: null as string,
    videoStream: null as MediaStream,
    videoInputOn: true as boolean,
    audioInputOn: true as boolean,
    browserAudioOutputCapabilitiesSupported: false as boolean,
    browserMultipleAudioInputDevicesSupported: true as boolean,
    previousTracks: null as LocalTrack[],
    device: null as Device,
    serviceToken: null as string,
    callStatus: null as ConversationStatus,
    callError: null as string,
    appointmentSynchronizationErrorKey: ''
};

export type ConsultationSliceState = typeof initialState;

export const fetchAppointmentDetails = createAsyncThunk<
    Appointment,
    {appointmentId: string; fetchSilently: boolean},
    {state: RootState}
>(`${sliceName}/fetchAppointmentDetails`, async ({appointmentId}) => {
    const appointment = await AppointmentsProvider.getAppointment(
        appointmentId,
    );
    return mapAppointment(appointment);
});

export const synchronizeAppointmentDetails =
    createAsyncThunk<Appointment, {appointmentId: number}, {state: RootState}>(
        `${sliceName}/synchronizeAppointmentDetails`,
        async ({appointmentId}, thunkAPI) => {
            const appointment = thunkAPI.getState().consultation.appointmentDetails;
            const {data} = await appointmentsProvider.getAppointmentChangingData(appointmentId);
            return synchronizeAppointment(appointment, data);
        }
    );

const audioInputDevicesSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<MediaDeviceInfo[]>
> = (state, action) => {
    const devices = action.payload;
    if (state.selectedAudioInputDeviceId === null) {
        state.selectedAudioInputDeviceId = devices.find(
            x => x.deviceId === 'default',
        )
            ? 'default'
            : devices[0].deviceId;
    }
    state.browserMultipleAudioInputDevicesSupported = isFirefox ? false : true;
    state.audioInputDevices = devices;
};

const audioOutputDevicesSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<MediaDeviceInfo[]>
> = (state, action) => {
    const devices = action.payload;

    if (isSafari && devices.length === 0) {
        const safariAudioOutput =
            BrowserDefaultMediaDevicesProvider.getSafariAudioOutput();
        state.selectedAudioOutputDeviceId = safariAudioOutput[0].deviceId;

        state.browserAudioOutputCapabilitiesSupported = false;
        state.audioOutputDevices = safariAudioOutput;
    } else if (isFirefox && devices.length === 0) {
        const firefoxAudioOutput =
            BrowserDefaultMediaDevicesProvider.getFirefoxAudioOutput();
        state.selectedAudioOutputDeviceId = firefoxAudioOutput[0].deviceId;

        state.browserAudioOutputCapabilitiesSupported = false;
        state.audioOutputDevices = firefoxAudioOutput;
    } else {
        if (state.selectedAudioOutputDeviceId === null) {
            state.selectedAudioOutputDeviceId = devices.find(
                x => x.deviceId === 'default',
            )
                ? 'default'
                : devices[0].deviceId;
        }
        state.browserAudioOutputCapabilitiesSupported = true;
        state.audioOutputDevices = devices;
    }
};

const videoStreamSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<MediaStream>
> = (state, action) => {
    const stream = action.payload;
    state.videoStream = stream;
};

const videoInputDevicesSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<MediaDeviceInfo[]>
> = (state, action) => {
    const devices = action.payload;
    if (state.selectedVideoInputDeviceId === null) {
        state.selectedVideoInputDeviceId = devices.find(
            x => x.deviceId === 'default',
        )
            ? 'default'
            : devices[0].deviceId;
    }
    state.videoInputDevices = devices;
};

const selectedVideoInputDeviceIdSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<string>
> = (state, action) => {
    state.selectedVideoInputDeviceId = action.payload;
};

const selectedAudioInputDeviceIdSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<string>
> = (state, action) => {
    state.selectedAudioInputDeviceId = action.payload;
};

const selectedAudioOutputDeviceIdSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<string>
> = (state, action) => {
    state.selectedAudioOutputDeviceId = action.payload;
};

const videoInputOnSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<boolean>
> = (state, action) => {
    state.videoInputOn = action.payload;
};

const audioInputOnSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<boolean>
> = (state, action) => {
    state.audioInputOn = action.payload;
};

const roomSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<Room>
> = (state, action) => {
    state.room = action.payload;
};

const deviceSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<Device>
> = (state, action) => {
    state.device = action.payload;
};

const serviceTokenSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<string>
> = (state, action) => {
    state.serviceToken = action.payload;
};

const callStatusSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<ConversationStatus>
> = (state, action) => {
    state.callStatus = action.payload;
};

const callErrorSpecifiedReducer: CaseReducer<
    ConsultationSliceState,
    PayloadAction<string>
> = (state, action) => {
    state.callError = action.payload;
};

const consultationSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        audioInputDevicesSpecified: audioInputDevicesSpecifiedReducer,
        audioOutputDevicesSpecified: audioOutputDevicesSpecifiedReducer,
        videoInputDevicesSpecified: videoInputDevicesSpecifiedReducer,
        videoStreamSpecified: videoStreamSpecifiedReducer,
        selectedVideoInputDeviceIdSpecified:
            selectedVideoInputDeviceIdSpecifiedReducer,
        selectedAudioInputDeviceIdSpecified:
            selectedAudioInputDeviceIdSpecifiedReducer,
        selectedAudioOutputDeviceIdSpecified:
            selectedAudioOutputDeviceIdSpecifiedReducer,
        videoInputOnSpecified: videoInputOnSpecifiedReducer,
        audioInputOnSpecified: audioInputOnSpecifiedReducer,
        roomSpecified: roomSpecifiedReducer,
        deviceSpecified: deviceSpecifiedReducer,
        serviceTokenSpecified: serviceTokenSpecifiedReducer,
        callStatusSpecified: callStatusSpecifiedReducer,
        callErrorSpecified: callErrorSpecifiedReducer,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAppointmentDetails.fulfilled, (state, action) => {
                state.appointmentDetails = action.payload;
                state.status = 'loaded';
            })
            .addCase(fetchAppointmentDetails.pending, (state, action) => {
                state.status = action.meta.arg.fetchSilently
                    ? state.status
                    : 'loading';
            })
            .addCase(fetchAppointmentDetails.rejected, state => {
                state.status = 'failed';
            });

        builder
            .addCase(synchronizeAppointmentDetails.fulfilled, (state, action) => {
                state.appointmentDetails = action.payload;
            })
            .addCase(synchronizeAppointmentDetails.rejected, (state) => {
                state.appointmentSynchronizationErrorKey = 'errorOccurredWhileFetchingActualDataOfAppointment';
            })
    },
});

export const selectVideoStream = (state: RootState) =>
    state.consultation.videoStream;
export const selectVideoInputOn = (state: RootState) =>
    state.consultation.videoInputOn;
export const selectAudioInputOn = (state: RootState) =>
    state.consultation.audioInputOn;
export const selectSelectedAudioInputDeviceId = (state: RootState) =>
    state.consultation.selectedAudioInputDeviceId;
export const selectSelectedAudioOutputDeviceId = (state: RootState) =>
    state.consultation.selectedAudioOutputDeviceId;
export const selectSelectedVideoInputDeviceId = (state: RootState) =>
    state.consultation.selectedVideoInputDeviceId;
export const selectRoom = (state: RootState) => state.consultation.room;
export const selectAudioInputDevices = (state: RootState) =>
    state.consultation.audioInputDevices;
export const selectAudioOutputDevices = (state: RootState) =>
    state.consultation.audioOutputDevices;
export const selectBrowserAudioOutputCapabilitiesSupported = (
    state: RootState,
) => state.consultation.browserAudioOutputCapabilitiesSupported;
export const selectBrowserMultipleAudioInputDevicesSupported = (
    state: RootState,
) => state.consultation.browserMultipleAudioInputDevicesSupported;
export const selectVideoInputDevices = (state: RootState) =>
    state.consultation.videoInputDevices;
export const selectAppointmentDetails = (state: RootState) => {
    const {appointmentDetails} = state.consultation;
    return appointmentDetails
        ? {
              ...appointmentDetails,
              endDate: appointmentDetails.endDate.minus({minutes: appointmentDetails.timeInMinutesToCompleteAppointmentBeforeEnd}),
          }
        : null;
};

export const selectConsultationStatus = (state: RootState) =>
    state.consultation.status;
export const selectDevice = (state: RootState) => state.consultation.device;
export const selectServiceToken = (state: RootState) =>
    state.consultation.serviceToken;
export const selectCallStatus = (state: RootState) =>
    state.consultation.callStatus;
export const selectCallError = (state: RootState) =>
    state.consultation.callError;

export const {
    audioInputDevicesSpecified,
    audioOutputDevicesSpecified,
    videoInputDevicesSpecified,
    videoStreamSpecified,
    selectedVideoInputDeviceIdSpecified,
    selectedAudioInputDeviceIdSpecified,
    selectedAudioOutputDeviceIdSpecified,
    videoInputOnSpecified,
    audioInputOnSpecified,
    roomSpecified,
    deviceSpecified,
    serviceTokenSpecified,
    callStatusSpecified,
    callErrorSpecified,
} = consultationSlice.actions;

export default consultationSlice.reducer;
