import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import {BACKGROUND_SECONDARY, LIGHT_GRAY, WHITE} from "../../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: WHITE,
        [theme.breakpoints.up('md')]: {
            minHeight: `calc(100vh - 128px)`
        }
    },
    listItem: {cursor: 'pointer'},
    goBackWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 90,
        padding: `0 ${theme.spacing(1)}px`,
        borderBottom: `9px solid ${LIGHT_GRAY}`,
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            borderBottom: `1px solid ${LIGHT_GRAY}`,
            marginLeft: `${theme.spacing(3)}px`,
            padding: '0',
        },
    },
    goBackButton: {
        backgroundColor: BACKGROUND_SECONDARY,
        width: 40,
        height: 40,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        marginRight: 17,
    },
    divider: {
        [theme.breakpoints.up('md')]: {
            marginLeft: `${theme.spacing(3)}px`,
            backgroundColor: LIGHT_GRAY,
        }
    }
}));

export default useStyles;