import {Box, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {getMedicFullName} from '../../../utils/appointment';
import {LIGHT_DISABLED} from '../../../const/colors';
import React, {useEffect, useState} from 'react';
import {Appointment} from '../../../types/appointments';
import {DateTime, DurationObjectUnits} from 'luxon';
import {setInterval} from 'timers';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../../store/auth';

const useStyles = makeStyles(() => ({
    participantName: {
        paddingTop: 24,
        paddingBottom: 24,
    },
}));

type ConsultationInfoProps = {
    consultation?: Appointment;
    inConsultationView?: boolean;
};

const ConsultationInfo = ({
    consultation,
    inConsultationView,
}: ConsultationInfoProps) => {
    const classes = useStyles();
    const [timeLeft, setTimeLeft] = useState<DurationObjectUnits>();
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);
    const authUserData = useAppSelector(selectAuthUserData);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

    useEffect(() => {
        const interval = setInterval(() => {
            const {minutes, seconds} = consultation.endDate
                .diff(DateTime.local(), ['minutes', 'seconds'])
                .toObject();
            setTimeLeft({
                minutes: seconds > 59 ? minutes + 1 : minutes,
                seconds: seconds > 59 ? 0 : seconds,
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return consultation ? (
        <Box
            className={classes.participantName}
            style={{fontSize: isDesktop ? 16 : 12}}
        >
            <Box fontWeight="bold" component={'span'}>
                {authUserData.role === 'Medic'
                    ? `${consultation.patient.firstName} ${consultation.patient.surname}`
                    : getMedicFullName(consultation.medic)}
            </Box>
            {inConsultationView ? (
                <Box color={LIGHT_DISABLED} component={'span'}>
                    -{' '}
                    {t('timeLeft', {
                        time: `${timeLeft?.minutes || 0}m ${
                            timeLeft?.seconds.toFixed() || 0
                        }s`,
                    })}
                </Box>
            ) : (
                <Box color={LIGHT_DISABLED} component={'span'}>
                    - {t('hr')} {consultation.startDate.toFormat('HH:mm')}
                </Box>
            )}
        </Box>
    ) : null;
};

export default ConsultationInfo;
