export default class TimeOfDay {
    private static readonly minutesInHour = 60;
    private static readonly hoursInDay = 24;
    private static readonly minMinutes = 0;

    constructor(private minutesSinceMidnight: number) {}

    valueOf() {
        return this.minutesSinceMidnight;
    }

    toString() {
        const value = `${TimeOfDay.formatTime(
            this.hour,
        )}:${TimeOfDay.formatTime(this.minute)}`;
        return value;
    }

    plus(minutes: number) {
        return new TimeOfDay(this.minutesSinceMidnight + minutes);
    }

    minus(minutes: number) {
        return new TimeOfDay(this.minutesSinceMidnight - minutes);
    }

    get hour() {
        return Math.floor(this.minutesSinceMidnight / TimeOfDay.minutesInHour);
    }

    get minute() {
        return this.minutesSinceMidnight % TimeOfDay.minutesInHour;
    }

    get isNegative() {
        return this.minutesSinceMidnight < TimeOfDay.minMinutes;
    }

    get isExceeded() {
        return this.minutesSinceMidnight > TimeOfDay.maxMinutes;
    }

    static from(hour: number, minute: number) {
        return new TimeOfDay(hour * TimeOfDay.minutesInHour + minute);
    }

    static getEarliestTime() {
        return new TimeOfDay(TimeOfDay.minMinutes);
    }

    static getClosestHour() {
        return new TimeOfDay(new Date().getHours() * TimeOfDay.minutesInHour);
    }

    static getLatestTime() {
        return new TimeOfDay(TimeOfDay.maxMinutes);
    }

    private static get maxMinutes() {
        return TimeOfDay.minutesInHour * TimeOfDay.hoursInDay;
    }

    private static formatTime(value: number) {
        return (value < 10 ? '0' : '') + value;
    }
}
