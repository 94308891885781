import React, {memo, useCallback} from 'react';
import {Box, createStyles, Grid, makeStyles, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {LaboratoryAddressDto} from '../../../../services/diagnostic/laboratoryAddressesProvider';
import {CustomAvatar} from '../../../../components';
import {MedicalTestIcon} from '../../../../assets';
import {ArrowForwardIos} from '@material-ui/icons';
import {useLaboratoryAddressesContext} from '../../../../hooks/diagnostic/laboratoryAddresses/useLaboratoryAddressesContext';
import {MAGENTA} from '../../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 16,
            [theme.breakpoints.up('md')]: {
                padding: 0,
            }
        },
        laboratoryAddress: {
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: 'bold',
        },
        laboratoryName: {
            fontSize: 16,
            lineHeight: '24px',
        }
    }),
);

interface Props {
    laboratoryAddress: LaboratoryAddressDto,
}

const LaboratoryAddress = memo(function MedicalSearchFilters({laboratoryAddress}: Props) {
    const classes = useStyles();
    const theme = useTheme();
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
    const {selectedLaboratoryAddress, setSelectedLaboratoryAddress} = useLaboratoryAddressesContext();

    const isSelected = laboratoryAddress === selectedLaboratoryAddress;

    const laboratoryAddressCardClickHandler = useCallback(() => {
        if (!isSelected) {
            setSelectedLaboratoryAddress(laboratoryAddress);
        }
    }, [isSelected]);

    return (
        <Grid
            className={classes.root}
            container
            wrap={'nowrap'}
            alignItems="center"
            style={{cursor: 'pointer'}}
            spacing={lgUp ? 2 : 1}
            onClick={laboratoryAddressCardClickHandler}
        >
            <Grid item xs="auto">
                <CustomAvatar
                    alt="medical tests icon"
                    src={MedicalTestIcon}
                    isActive={mdUp && laboratoryAddress === selectedLaboratoryAddress}
                />
            </Grid>
            <Grid item xs>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Box
                        className={classes.laboratoryAddress}
                        component={'span'}
                    >
                        {laboratoryAddress.address}, {laboratoryAddress.city}
                    </Box>
                    <Box className={classes.laboratoryName}>
                        {laboratoryAddress.laboratoryName}
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs="auto">
                <ArrowForwardIos
                    style={{
                        color: isSelected ? MAGENTA : theme.palette.grey[300]
                    }}
                />
            </Grid>
        </Grid>
    );
});

export default LaboratoryAddress;