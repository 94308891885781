import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        priceSection: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
            marginBottom: mobile.contentSmallerSpacing,
        },
        priceText: {
            fontSize: 18,
            fontWeight: 'bold',
        },
        packageName: {
            marginBottom: desktop.contentSmallerSpacing,
            fontSize: 18,
            fontWeight: 'bold',
        },
        price: {
            fontSize: 18,
            fontWeight: 'bold',
            color: theme.palette.secondary.main,
        },
        oldPrice: {
            fontSize: 18,
            fontWeight: 'bold',
            textDecoration: 'line-through',
            textDecorationColor: theme.palette.secondary.main,
            textDecorationThickness: '3px',
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        discount: {
            fontSize: 14,
            color: theme.palette.grey[600],
        },
        [theme.breakpoints.up('sm')]: {
            priceSection: {
                padding: `${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
            },
            priceContainer: {
                justifyContent: 'flex-start',
            },
            priceText: {
                fontSize: 20,
            },
            packageName: {
                marginBottom: mobile.contentLargerSpacing,
                fontSize: 20,
            },
            oneTimeConst: {
                flexDirection: 'row',
            },
            priceValueContainer: {
                flexDirection: 'row',
                alignItems: 'center',
            },
            price: {
                fontSize: 20,
            },
            discount: {
                fontSize: 16,
            },
        },
    }),
);

export default useStyles;
