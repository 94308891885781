import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {selectFinishedAppointmentById} from '../../../store/patientCalendar/finishedAppointmentsSlice';
import AppointmentItem from '../AppointmentItem/AppointmentItem';
import {EVALUATION_PAGE, FIND_TIME_SLOTS} from '../../../const/routes';
import {v4} from 'uuid';
import {
    AppointmentGroupEnum,
    AppointmentStateEnum,
} from '../../../types/appointments';
import {isAppointmentEndedOrCanceled} from '../../../utils/appointment';
import {parseTimeSlotQuery} from '../../../utils/timeSlots';
import { useState } from 'react';
import CustomAlert from '../../common/feedback/CustomAlert';

type FinishedAppointmentProps = {
    finishedAppointmentId: number;
};

const FinishedAppointment = ({
    finishedAppointmentId,
}: FinishedAppointmentProps) => {
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);
    const [displayAlertOnRepeate, setDisplayAlertOnRepeate] = useState<boolean>(false);

    const finishedAppointment = useAppSelector(state =>
        selectFinishedAppointmentById(state, finishedAppointmentId),
    );

    const {
        state: {state: finishedAppointmentState},
        medic: {userId: toMedicId},
        medicalService: {id: medicalServiceId},
    } = finishedAppointment;

    const isOMAppointment =
        finishedAppointment.appointmentGroup ===
        AppointmentGroupEnum.OccupationalMedicineAppointment;

    const openAlertOnRepeate = () =>{
        setDisplayAlertOnRepeate(true);
    }

    const links = [
        {
            display:
                finishedAppointmentState === AppointmentStateEnum.Completed,
            id: v4(),
            label: t('rate'),
            URL: EVALUATION_PAGE + '/' + finishedAppointmentId,
        },
        {
            display:
                isAppointmentEndedOrCanceled(finishedAppointmentState) &&
                !isOMAppointment,
            id: v4(),
            label: t('repeat'),
            URL:
                FIND_TIME_SLOTS +
                parseTimeSlotQuery({medicalServiceId, medicId: toMedicId}),
        },
    ];

    const linksWithConstraints = [
        {
            display:
                finishedAppointmentState === AppointmentStateEnum.Completed,
            id: v4(),
            label: t('rate'),
            URL: EVALUATION_PAGE + '/' + finishedAppointmentId,
        },
        {
            display:
                isAppointmentEndedOrCanceled(finishedAppointmentState) &&
                !isOMAppointment,
            id: v4(),
            label: t('repeat'),
            onClick: openAlertOnRepeate
        },
    ]

    return (
        <>
         <CustomAlert 
                         open={displayAlertOnRepeate}
                         severity="warning" 
                         message={t('alertOnRepeate')} 
                         onClose={() => setDisplayAlertOnRepeate(false)}/>
       
        <AppointmentItem
            appointment={finishedAppointment}
            links={finishedAppointment.bookedByAgent ? linksWithConstraints : links}
            canMoveToDetails
        />
         </>
    );
};

export default FinishedAppointment;
