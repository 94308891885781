import {makeStyles, Theme} from "@material-ui/core";
import {desktop, HD_READY_DESKTOP_BREAKPOINT, mobile} from "../../../../const/sizes";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: mobile.contentLargerSpacing,
        paddingBottom: 0,
    },
    appointmentHeader: {
        fontSize: mobile.defaultFontSize,
        padding: `${mobile.contentSmallerSpacing} ${mobile.contentLargerSpacing}`,
    },
    appointmentHeaderTime: {
        fontWeight: 'bold',
    },
    appointmentContent: {
        padding: `${desktop.contentSmallerSpacing} 0`,
    },
    appointmentPhoto: {
        width: '70px',
        height: '70px',
    },
    appointmentDetails: {
        fontSize: mobile.defaultFontSize,
        flex: '1',
        paddingLeft: mobile.contentLargerSpacing,
    },
    appointmentMedic: {
        fontWeight: 'bold',
    },
    appointmentStatesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        margin: '0 0 12px 86px',
    },
    appointmentState: {
        marginRight: 8,
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            padding: `${desktop.contentSmallerSpacing} ${mobile.contentLargerSpacing}`,
        },
        appointment: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        appointmentHeader: {
            flexDirection: 'column',
            width: 118,
            padding: `${mobile.contentSmallerSpacing} ${desktop.contentSmallerSpacing}`,
        },
        appointmentHeaderDate: {
            fontSize: '14px',
        },
        appointmentHeaderTime: {
            fontSize: '24px',
        },
        appointmentContentWrapper: {
            flex: 1,
            paddingLeft: desktop.contentSmallerSpacing,
        },
        appointmentContent: {
            padding: 0,
        },
        appointmentPhoto: {
            width: '88px',
            height: '88px',
        },
        appointmentDetails: {
            paddingLeft: desktop.contentSmallerSpacing,
        },
        appointmentStatesContainer: {
            margin: '16px 0 0 255px',
        }
    },
    [theme.breakpoints.up('md')]: {
        container: {
            padding: `${desktop.contentSmallerSpacing} 24px ${desktop.contentSmallerSpacing} ${theme.spacing(3)}px`,
        },
    },
    [`@media ${HD_READY_DESKTOP_BREAKPOINT}`]: {
        appointmentStatesContainer: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            margin: '0 0 0 12px',
        },
        appointmentState: {
            margin: '0 0 2px 0',
        },
    }
}));

export default useStyles;