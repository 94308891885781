import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        isActive: {
            color: theme.palette.secondary.main,
            textDecoration: 'underline !important',
        },
        menuButton: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: 32,
            },
            textDecoration: 'none',
            cursor: 'pointer',
        },
    }),
);

export default useStyles;
