import * as React from 'react';
import {FormikProps} from 'formik';
import {CustomTextField, Label} from '../../../index';
import {FormGroup, TextFieldProps} from '@material-ui/core';
import useStyles from './FormikTextFieldStyles';

type FormikTextFieldProps = {
    form: FormikProps<any>;
    label: string;
    fieldName: string;
    fullWidth?: boolean;
    noStyles?: boolean;
} & TextFieldProps;

const FormikTextField = ({
    form,
    label,
    fieldName,
    fullWidth,
    noStyles,
    ...props
}: FormikTextFieldProps) => {
    const classes = useStyles();
    return (
        <FormGroup className={!noStyles && classes.formGroup}>
            <Label htmlFor={label}>{label}</Label>
            <CustomTextField
                type={props.type || 'text'}
                error={Boolean(
                    form.touched[fieldName] && form.errors[fieldName],
                )}
                helperText={form.touched[fieldName] && form.errors[fieldName]}
                name={fieldName}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={props.value || form.values[fieldName]}
                fullWidth={fullWidth}
                {...props}
            />
        </FormGroup>
    );
};

export default FormikTextField;
