import {
    Box,
    Grid,
    Link,
    Snackbar,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    IndividualPackageSubscriptionDto,
    MedicalServiceGroupAssignmentDto,
    MemberDto,
} from '../../../types/patientPackages';
import useStyles from './SubscriptionDetailsStyles';
import {CreditCardOutlined, RemoveCircleOutline} from '@material-ui/icons';
import {IconButtonWithText} from '../../index';
import AddPersonToPackageForm from '../../forms/AddPersonToPackageForm/AddPersonToPackageForm';
import MembersList from '../MembersList/MembersList';
import {AxiosError} from 'axios';
import {patientPackagesErrorHandler as errorHandler} from '../../../utils/error';
import packagesProvider from '../../../services/packagesProvider';
import React, {useState} from 'react';
import {usePayUSecureForms} from '../../common/payUSecureForms';
import {BuyerDataDto} from '../../../types/buyerData';
import {Alert} from '@material-ui/lab';
import EditBuyerDataForm from '../../forms/EditBuyerDataForm/EditBuyerDataForm';
import {useHistory} from 'react-router';
import {ERROR} from '../../../const/routes';
import { getLanguage, getTranslation } from '../../../utils/translation';
import i18n from '../../../i18n/config';

type SubscriptionDetailsProps = {
    subscription: IndividualPackageSubscriptionDto;
    setLoading: (loading: boolean) => void;
    onChange: () => void;
    onShowHistory: (subscription: IndividualPackageSubscriptionDto) => void;
    showHistory: boolean;
};

const SubscriptionDetails = ({
    subscription,
    setLoading,
    onChange,
    onShowHistory,
    showHistory,
}: SubscriptionDetailsProps) => {
    const {t} = useTranslation(i18nNamespaces.PATIENT_PACKAGES);
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const [error, setError] = useState('');
    const [showCardEdit, setShowCardEdit] = useState(false);
    const [successInfo, setSuccessInfo] = useState('');
    const history = useHistory();
    const {
        individualPackage,
        id,
        subscriptionMembers,
        isCancelled,
        isOwn,
        numberOfAvailableMemberChanges,
        paymentStatus,
        medicalServiceGroupAssignments
    } = subscription;
    const maxAdditionalMembersCount = individualPackage && individualPackage.maxUsersCount - 1;
    const isAdultMemberLimitReached =
        subscriptionMembers.length >= maxAdditionalMembersCount;
    const memberCanBeAdded =
        !isAdultMemberLimitReached &&
        isOwn &&
        numberOfAvailableMemberChanges > 0 &&
        !isCancelled;

    const {tokenize, sendCvv} = usePayUSecureForms();
    const currentLanguage = getLanguage(i18n?.language);

    const updateBuyerData = async (buyerData: BuyerDataDto) => {
        setLoading(true);
        const tokenizedCard = await tokenize('MULTI');
        if (tokenizedCard.status !== 'SUCCESS') return;
        const {paymentContinuation} = await packagesProvider.updatePayment(
            id,
            buyerData,
            tokenizedCard.body.token,
        );

        // If there is no paymentContinuation, then update is finished.
        if (!paymentContinuation) {
            return;
        }

        const {type, paymentUrl} = paymentContinuation;

        // Go to the payment URL in case an additional 3DS verification is required.
        if (type == 'CONTINUE_3DS') {
            window.location.href = paymentUrl;
        }
        // Retrieve the card CVV code if it is required by PayU.
        else if (type == 'CONTINUE_CVV') {
            const cvvResult = await sendCvv(paymentUrl);

            if (cvvResult.status == 'SUCCESS') {
                return;
            } else {
                history.push(paymentUrl + '?error=CVV_FAILURE');
            }
        }
        // Payment continuation with an unknown type. Go to the error page.
        else {
            history.push(ERROR);
        }
    };

    const onSubmit = async (buyerData: BuyerDataDto) => {
        await updateBuyerData(buyerData);
        setLoading(false);
        setShowCardEdit(false);
        setSuccessInfo(t('paymentDataHasBeenChanged'));
    };

    const handleError = (error: AxiosError) => {
        setError(t(errorHandler(error.response.data[0])));
        setLoading(false);
    };

    const cancelHandler = async (subscriptionId: number) => {
        try {
            setError('');
            setLoading(true);
            await packagesProvider.cancelSubscription(subscriptionId);
            onChange();
        } catch (error) {
            handleError(error as AxiosError);
        }
    };

    const addAdultHandler = async (id: number, member: MemberDto) => {
        try {
            setError('');
            setLoading(true);
            await packagesProvider.addMemberToSubscription(id, member);
            onChange();
        } catch (error) {
            handleError(error as AxiosError);
        }
    };

    const removeAdultHandler = async (id: number, pesel: string) => {
        try {
            setError('');
            setLoading(true);
            await packagesProvider.removeSubscriptionMember(id, pesel);
            onChange();
        } catch (error) {
            handleError(error as AxiosError);
        }
    };

    const getLabelProps = () => {
        switch (paymentStatus) {
            case 'Paid':
                return tCommon('paid');

            case 'Unpaid':
                return tCommon('unpaid');

            case 'Pending':
                return tCommon('paymentPending');

            default:
                return tCommon('undefined');
        }
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.horizontalPadding}>
                <Box className={classes.packageName}>
                    {individualPackage && getTranslation(individualPackage.name, individualPackage.translations, currentLanguage, "name")}
                </Box>
                {medicalServiceGroupAssignments.map((medicalServiceGroup, idx) => (
                    <Box key={idx} className={classes.remainingServiceLimit}>
                        {getTranslation(medicalServiceGroup.medicalServiceGroup, medicalServiceGroup.translations, currentLanguage, "description")}
                        
                        {medicalServiceGroup.medicalServices && 
                            <ul>
                                {medicalServiceGroup.medicalServices.map((medicalService, i)=>(
                                    <li
                                        key={i}
                                    >
                                        <Box>{getTranslation(medicalService.name, medicalService.translations, currentLanguage, "name")}</Box>
                                    </li>

                                ))}
                            </ul>
                        }

                    </Box>
                ))}

                <Grid
                    container
                    direction={isDesktop ? 'row' : 'column'}
                    alignItems={isDesktop ? 'center' : 'flex-start'}
                    style={{
                        marginTop: 16,
                        marginBottom: 8,
                    }}
                >
                    {subscriptionMembers && (
                        <MembersList
                            members={subscriptionMembers}
                            onRemoveMember={pesel =>
                                removeAdultHandler(id, pesel)
                            }
                            isRemoveEnabled={!isCancelled}
                        />
                    )}
                </Grid>

                {isOwn && (
                    <Grid
                        container
                        direction={isDesktop ? 'row' : 'column'}
                        className={classes.subscriptionOptions}
                    >
                        <Grid item className={classes.paymentInfo}>
                            {getLabelProps()}{' '}
                            <span className={classes.grayDot}> &#8226; </span>{' '}
                            <Link
                                onClick={() => onShowHistory(subscription)}
                                color="secondary"
                                style={{cursor: 'pointer'}}
                            >
                                {showHistory
                                    ? t('hideHistory')
                                    : t('seeHistory')}
                            </Link>
                        </Grid>

                        {!isCancelled && (
                            <Grid item>
                                <IconButtonWithText
                                    className={classes.editCardDataButton}
                                    label={t('editCardData')}
                                    icon={<CreditCardOutlined />}
                                    onClick={() => setShowCardEdit(!showCardEdit)}
                                />
                            </Grid>
                        )}

                        {!isCancelled && (
                            <Grid item>
                                <IconButtonWithText
                                    className={classes.cancelSubscriptionButton}
                                    label={t('cancelSubscription')}
                                    icon={<RemoveCircleOutline />}
                                    onClick={() => cancelHandler(id)}
                                />
                            </Grid>
                        )}
                        {isCancelled && (
                            <Grid item>
                                {t('subscriptionCancelled')}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Box>
            {memberCanBeAdded && (
                <>
                    <Box className={classes.horizontalLine} />
                    <Box className={classes.horizontalPadding}>
                        <AddPersonToPackageForm
                            onSubmit={(data: MemberDto) =>
                                addAdultHandler(id, data)
                            }
                        />
                        {!!error && <Box style={{color: 'red'}}>{error}</Box>}
                    </Box>
                </>
            )}
            {showCardEdit && (
                <>
                    <Box className={classes.horizontalLine} />
                    <Box className={classes.horizontalPadding}>
                        <EditBuyerDataForm onSubmit={onSubmit} />
                    </Box>
                </>
            )}
            <Snackbar
                open={!!successInfo}
                autoHideDuration={5000}
                onClose={() => setSuccessInfo('')}
            >
                <Alert onClose={() => setSuccessInfo('')} severity="success">
                    {successInfo}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SubscriptionDetails;
