import { Box, Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import useStyles from "./CancelZLAFormStyles";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { useEffect, useState } from "react";
import { AZLADto, CancelReasonDto, LinkedZlaDto, ZLAHistoryDto } from "../../../types/sickLeave";
import kedZLAApi from "../../../api/zusZLA/kedZLAApi";
import { Delete } from "@material-ui/icons";
import { AppointmentDetailsForPrescriptionDto } from "../../../types/appointments";
import Loading from "../../common/Loading/Loading";


const REQUIRED_STRING = 'requiredField';

type Props = {
    handleOnDelete: () => void;
    zlaToCancel:ZLAHistoryDto;
    onSubmit: (seriesAndNumber: string, zla: AZLADto) => void;
    appointment:AppointmentDetailsForPrescriptionDto
}

const CancelZLAForm = ({handleOnDelete, zlaToCancel, onSubmit, appointment}:Props) => 
{
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.L4);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const [codes, setCodes] = useState<CancelReasonDto[]>([]);
    const [linkedZlas, setLinkedZlas] = useState<LinkedZlaDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    
    const formik = useFormik({
        initialValues: {
            code: '' as string,
            description: '' as string,
        },
        validationSchema: yup.object({
            code: yup
                .string()
                .nullable()
                .required(commonT(REQUIRED_STRING)),
            description: yup
                .string()
                .nullable()
                .required(commonT(REQUIRED_STRING)),
                
        }),
        onSubmit: values => {
            onSubmit(
                `${zlaToCancel.series}${zlaToCancel.number}`,
                {
                    appointmentId:appointment.id, 
                    patientId:appointment.patient.userId,
                    code:values.code,
                    description: values.description
                })            
        },
    });

    const handleChangeCode = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        formik.setFieldValue('code', event.target.value);
    };

    const fetchCancelCodes = async () => {
        try {
            // setLoading(true);
            const {data} = await kedZLAApi.getCancelCode()
            setCodes(data.result);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };


    const fetchLinkedZLAs = async () =>{
        try {
            
            const {data} = await kedZLAApi.getLinkedZLA(`${zlaToCancel.series}${zlaToCancel.number}`)
            setLinkedZlas(data.result);
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    useEffect(()=>{
        setLoading(true);
        fetchLinkedZLAs();
        fetchCancelCodes();  
        setLoading(false);  
    }, []);

    const handleCancel = async() =>{

    }

    const getLinkedZlas = () =>{
        return  linkedZlas.filter(x=>  `${x.series}${x.number}` !== `${zlaToCancel.series}${zlaToCancel.number}`)
            .map(x=> `${x.series}${x.number} (${t('payer')} ${t(x.payerTypeId)}: ${x.payerId})`).join(", ");
    }



    return (
        <form onSubmit={formik.handleSubmit}>
             <Loading loading={loading} withBackdrop />
        
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={8} alignItems="center" className={classes.titleContainer}>
                    {t('cancelZla')} {zlaToCancel.series}{zlaToCancel.number}

                {linkedZlas.length>0 && 
                    <Box className={classes.text}>
                        {t('linkedZLAs')}: {getLinkedZlas()}
                    </Box>}

                                   
                </Grid>
                <Grid item xs={4} className={classes.iconContainer}>
                    <IconButton onClick={handleOnDelete}>
                        <Delete />
                    </IconButton>
                </Grid>    
                </Grid>
        

            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(
                            formik.touched.code &&
                                formik.errors.code,
                        )}
                    >
                        <InputLabel>{t('cancelCode')}</InputLabel>
                        <Select
                            name="code"
                            label={t('cancelCode')}
                            value={formik.values.code}
                            onChange={handleChangeCode}
                            fullWidth
                        >
                            {codes.map(option => (
                                <MenuItem key={option.code} value={option.code}>
                                    {option.code} - {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText
                            variant="outlined"
                            error={Boolean(
                                formik.touched.code &&
                                    formik.errors.code,
                            )}
                        >
                            {formik.errors.code}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                <TextField
                        fullWidth
                        type="text"
                        label={t('cancelDescription')}
                        variant="outlined"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        helperText={
                            formik.touched.description &&
                            formik.errors.description
                        }
                        error={Boolean(
                            formik.touched.description &&
                                formik.errors.description,
                        )}
                        inputProps={{maxLength:100}}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} style={{textAlign: 'right'}}>
                    <Button type='submit'
                            variant={'contained'}
                            color={'secondary'}
                            style={{marginTop: 10, fontWeight: 'bold'}}
                            onClick={(handleCancel)}
                            fullWidth>
                                {t('signAndCancel')}
                            </Button>
                </Grid>
            </Grid>
        </form>
    )
};

export default CancelZLAForm;