import {useCallback, useEffect, useState} from 'react';
import {isArray} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import referralsProvider from '../../services/referralsProvider';
import {useAppDispatch} from '../customReduxHooks';
import {setMedicalTestsSelectedCategory} from '../../store/referrals/medicalTestsSlice';

const useMedicalTestsCategories = () => {
    const [categories, setCategories] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(null);

    const [categoryChangeModalOpen, setCategoryChangeModalOpen] = useState(false);
    const [onModalConfirmCategoryName, setOnModalConfirmCategoryName] = useState<string>(null);

    const dispatch = useAppDispatch();
    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const fetchCategoriesOfMedicalTests = useCallback(
        async () => {
            setLoading(true);
            try {
                const response = await referralsProvider.getCategoriesOfMedicalTests();
                if (!isArray(response.data)) {
                    throw '';
                }
                setCategories(response.data);
                dispatch(
                    setMedicalTestsSelectedCategory(response.data[0] || null)
                )
            } catch {
                setError(tErrors('fetchingDataFromServerError'));
                setCategories([]);
            }
            setLoading(false);
        },
        [setLoading, setCategories, setError]
    );

    useEffect(() => {
        fetchCategoriesOfMedicalTests();
    }, []);

    return {
        categories,
        categoriesLoading: loading,
        categoriesError: error,
        categoryChangeModalOpen,
        setCategoryChangeModalOpen,
        onModalConfirmCategoryName,
        setOnModalConfirmCategoryName
    }
}

export default useMedicalTestsCategories;