import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { GRAY, GREEN, LIGHT_GRAY, LIGHT_GRAY_BORDER } from '../../const/colors';
import { mobile } from '../../const/sizes';

const useOccupationalMedicineStyles = makeStyles((theme: Theme) =>
    createStyles({
        container:{
            padding: '0 47px',
            marginBottom: mobile.contentSmallerSpacing, 
        },
        detailsContainer:{
        },
        titleContainer:{
            display:'flex',
            alignItems:'center',
        },
        gridContainer:{
            maxWidth:1200
        },
        sectionHeader:{
            fontWeight:'bold'
        },
        alertIcon:{
            color:GREEN,
            margin:'16px'
        },
        buttonNext:{
            minWidth:200,
            float:'right',
            marginBottom:'32px',
            marginTop:'32px'
        },
        buttonReturn:{
            float:'left',
            minWidth:200,
            marginBottom:'32px',
            marginTop:'32px'
        },
        label:{
            marginBottom:'16px'
        },
        text:{
            fontSize:'16px'
        },
        subGrid:{
            paddingLeft:'40px'
        }
    }),
);

export default useOccupationalMedicineStyles;
