import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { LIGHT_GRAY } from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: 50,
            marginBottom: 64,
            paddingTop: 36, 
            paddingBottom:36,
            backgroundColor:LIGHT_GRAY
        },
        title: {fontSize: 40, marginBottom:36},
        subContainer:{
            maxWidth:300,
            textAlign:'center'
        },
        arrow:{
            margin:40
        },
        description:{
            marginTop:36,
            marginBottom:36,
            fontSize:16
        },
        [theme.breakpoints.down('sm')]: {
            arrow:{
                margin:40
            }
        }
    }),
);

export default useStyles;
