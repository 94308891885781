import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {useEffect} from 'react';
import {REFRESH_TIME} from '../../../const/commonConstants';
import {
    fetchReferrals,
    resetState,
    selectPatientOccupationalMedicineReferralsIds,
} from '../../../store/patientCalendar/referralsToOccupationalMedicineSlice';
import calendarSectionUseStyles from '../appointmentsCalendarSectionStyles';
import {CardList, CardListItem} from '../../index';
import PatientOccupationalMedicineReferral from '../PatientOccupationalMedicineReferral/PatientOccupationalMedicineReferral';

const PatientOccupationalMedicineReferrals = () => {
    const calendarSectionStyles = calendarSectionUseStyles();
    const {t} = useTranslation(i18nNamespaces.REFERRALS);
    const theme = useTheme();
    const isSmallScreenUp = useMediaQuery(theme.breakpoints.up('sm'));
    const patientReferralsIds = useAppSelector(
        selectPatientOccupationalMedicineReferralsIds,
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchReferrals());

        const intervalId = setInterval(() => {
            dispatch(fetchReferrals());
        }, REFRESH_TIME);

        return () => {
            clearInterval(intervalId);
            dispatch(resetState());
        };
    }, [dispatch]);

    if (patientReferralsIds.length === 0) return null;

    return (
        <Box className={calendarSectionStyles.appointmentWrapper}>
            <Box className={calendarSectionStyles.boxTitle}>
                {t('referralsToOccupationalMedicine')}
            </Box>
            <CardList disableFirstItemTopDivider disableLastItemBottomDivider>
                {patientReferralsIds.map(referralId => (
                    <CardListItem
                        key={referralId}
                        itemDisableGutters={isSmallScreenUp}
                    >
                        <PatientOccupationalMedicineReferral
                            referralId={referralId as number}
                        />
                    </CardListItem>
                ))}
            </CardList>
        </Box>
    );
};

export default PatientOccupationalMedicineReferrals;
