import useStyles from './MedicPictureStyles'
import {useAppSelector} from "../../../../hooks/customReduxHooks";
import {selectMedicDetails, selectNewMedicPicture} from "../../../../store/medicProfileSlice";
import {useEffect, useState} from "react";
import {CustomAvatar} from "../../../index";

const MedicPicture = () => {
    const classes = useStyles();
    const [medicPicture, setMedicPicture] = useState('');
    const medicDetails = useAppSelector(selectMedicDetails);
    const newMedicPicture = useAppSelector(selectNewMedicPicture);

    useEffect(() => {
        if (newMedicPicture) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setMedicPicture(reader.result as string)
            });
            reader.readAsDataURL(newMedicPicture);
        } else {
            setMedicPicture(medicDetails?.image || '')
        }
    }, [newMedicPicture, medicDetails?.image]);

    return (
        <CustomAvatar
            className={classes.picture}
            alt={'medic picture'}
            src={medicPicture}
        />
    );
}

export default MedicPicture;