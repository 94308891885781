import {useEffect} from 'react';

export const useInnerHeightProperty = () => {
    const refreshDocumentVhProperty = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        refreshDocumentVhProperty();
        window.addEventListener('resize', refreshDocumentVhProperty);
        return () =>
            window.removeEventListener('resize', refreshDocumentVhProperty);
    }, []);

    return {refreshDocumentVhProperty};
};
