import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import {LoadingStatus} from '../const/loadingStatus';
import {MedicCalendarEntry} from '../types/medicCalendarEntry';
import {RootState} from './store';
import medicCalendarEntriesProvider from '../services/medicCalendarEntriesProvider';
import mapMedicCalendarEntry from './mappers/mapMedicCalendarEntry';
import getDateWithoutTime from '../utils/getDateWithoutTime';
import medicsProvider from '../services/medicsProvider';

const sliceName = 'medic-calendar';

const medicCalendarEntriesAdapter = createEntityAdapter<MedicCalendarEntry>({
    selectId: medicCalendarEntry => medicCalendarEntry.id,
    sortComparer: (first, second) =>
        first.startDate < second.startDate
            ? -1
            : first.startDate > second.startDate
            ? 1
            : 0,
});

const initialState = medicCalendarEntriesAdapter.getInitialState({
    entriesStatus: 'idle' as LoadingStatus,
    settingsStatus: 'idle' as LoadingStatus,
    selectedDate: getDateWithoutTime(DateTime.now()),
    historical: false,
    minDate: null as DateTime,
    maxDate: null as DateTime,
});

export type MedicCalendarSliceState = typeof initialState;

export const fetchMedicCalendarSettings = createAsyncThunk<
    {minDate: DateTime; maxDate: DateTime},
    void,
    {
        state: RootState;
    }
>(`${sliceName}/fetchMedicCalendarSettings`, async () => {
    const {data: dates} = await medicsProvider
        .getMedicCalendarUTCDatesConvertedFromPolishTimeZoneMidnightTimeDates();

    return {
        minDate: dates.firstDayOfMedicCalendar,
        maxDate: dates.lastDayOfMedicCalendar
    };
});

export const fetchMedicCalendarEntries = createAsyncThunk<
    MedicCalendarEntry[],
    {
        fetchSilently: boolean;
    },
    {
        state: RootState;
    }
>(`${sliceName}/fetchMedicCalendarEntries`, async (_, thunkApi) => {
    const {selectedDate, historical} = thunkApi.getState().medicCalendar;

    const medicCalendarEntryDtos =
        await medicCalendarEntriesProvider.getCalendarEntriesForCurrentMedic(
            selectedDate,
            historical,
        );

    return medicCalendarEntryDtos.map(medicCalendarEntryDto =>
        mapMedicCalendarEntry(medicCalendarEntryDto),
    );
});

const medicCalendarSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        historicalFlagSpecified: {
            prepare(historical: boolean) {
                return {
                    payload: {
                        historical,
                        currentDateTime: DateTime.now(),
                    },
                };
            },
            reducer(
                state,
                action: PayloadAction<{
                    historical: boolean;
                    currentDateTime: DateTime;
                }>,
            ) {
                const {currentDateTime, historical} = action.payload;

                const currentDate = getDateWithoutTime(currentDateTime);

                if (historical && state.selectedDate <= currentDate) {
                    state.historical = true;
                }

                if (!historical && state.selectedDate >= currentDate) {
                    state.historical = false;
                }
            },
        },
        selectedDateSpecified: {
            prepare(selectedDate: DateTime) {
                return {
                    payload: {
                        selectedDate,
                        currentDateTime: DateTime.now(),
                    },
                };
            },
            reducer(
                state,
                action: PayloadAction<{
                    selectedDate: DateTime;
                    currentDateTime: DateTime;
                }>,
            ) {
                const {currentDateTime, selectedDate} = action.payload;

                state.selectedDate = selectedDate;

                const currentDate = getDateWithoutTime(currentDateTime);
                state.historical = selectedDate < currentDate;
            },
        },
        nextSelectedDateSpecified: {
            prepare() {
                return {
                    payload: DateTime.now(),
                };
            },
            reducer(state, action: PayloadAction<DateTime>) {
                state.selectedDate = state.selectedDate.plus({days: 1});

                const currentDate = getDateWithoutTime(action.payload);
                state.historical = state.selectedDate < currentDate;
            },
        },
        previousSelectedDateSpecified: {
            prepare() {
                return {
                    payload: DateTime.now(),
                };
            },
            reducer(state, action: PayloadAction<DateTime>) {
                state.selectedDate = state.selectedDate.minus({days: 1});

                const currentDate = getDateWithoutTime(action.payload);
                state.historical = state.selectedDate < currentDate;
            },
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMedicCalendarSettings.pending, state => {
                state.settingsStatus = 'loading';
            })
            .addCase(fetchMedicCalendarSettings.fulfilled, (state, action) => {
                state.settingsStatus = 'loaded';

                state.minDate = action.payload.minDate;
                state.maxDate = action.payload.maxDate;
            })
            .addCase(fetchMedicCalendarSettings.rejected, state => {
                state.settingsStatus = 'failed';
            });

        builder
            .addCase(fetchMedicCalendarEntries.pending, (state, action) => {
                state.entriesStatus = action.meta.arg.fetchSilently
                    ? state.entriesStatus
                    : 'loading';
            })
            .addCase(fetchMedicCalendarEntries.fulfilled, (state, action) => {
                state.entriesStatus = 'loaded';

                medicCalendarEntriesAdapter.setAll(
                    state as MedicCalendarSliceState,
                    action.payload,
                );
            })
            .addCase(fetchMedicCalendarEntries.rejected, state => {
                state.entriesStatus = 'failed';
            });
    },
});

export const {
    historicalFlagSpecified,
    selectedDateSpecified,
    nextSelectedDateSpecified,
    previousSelectedDateSpecified,
} = medicCalendarSlice.actions;

export const {
    selectIds: selectMedicCalendarEntryIds,
    selectById: selectMedicCalendarEntryById,
    selectAll: selectAllMedicCalendarEntries,
} = medicCalendarEntriesAdapter.getSelectors<RootState>(
    state => state.medicCalendar,
);

export const selectMedicCalendarEntriesStatus = (state: RootState) =>
    state.medicCalendar.entriesStatus;
export const selectMedicCalendarSettingsStatus = (state: RootState) =>
    state.medicCalendar.settingsStatus;
export const selectMedicCalendarSelectedDate = (state: RootState) =>
    state.medicCalendar.selectedDate;
export const selectMedicCalendarHistoricalFlag = (state: RootState) =>
    state.medicCalendar.historical;
export const selectMedicCalendarMinDate = (state: RootState) =>
    state.medicCalendar.minDate;
export const selectMedicCalendarMaxDate = (state: RootState) =>
    state.medicCalendar.maxDate;

export const selectNumberOfMedicCalendarAppointments = createSelector(
    [selectAllMedicCalendarEntries],
    medicCalendarEntries =>
        medicCalendarEntries.reduce(
            (acc, current) => acc + (current.appointmentId ? 1 : 0),
            0,
        ),
);

export default medicCalendarSlice.reducer;
