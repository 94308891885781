export const WHITE = '#FFFFFF';
export const MAGENTA = '#FF007F';
export const TEXT_PRIMARY = '#212121';
export const PRIMARY = '#212121';
export const TEXT_SECONDARY = '#666666';
export const BACKGROUND_PRIMARY = '#E5E5E5';
export const BACKGROUND_SECONDARY = '#EEEEEE';
export const GRAY = '#767676';
export const LIGHT_GRAY = '#DEDEDE';
export const LIGHT_GRAY_BORDER = '#E0E0E0';
export const LIGHT_DISABLED = '#9E9E9E';
export const CONVERSATION_BACKGROUND = '#4A4A4A';
export const BLACK = '#000000';
export const GREEN = '#01B810';
export const RED = '#D32F2F';
export const ORANGE = '#FFAB00';