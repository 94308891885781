import {Box, Grid, useMediaQuery, useTheme} from '@material-ui/core';
import {isInteger, toInteger} from 'lodash-es';
import {useHistory} from 'react-router';
import {
    Loading,
    ReferPatientToSpecialistHeaderMobile,
    ReferPatientToSpecialistMedicDetails,
    ReferPatientToSpecialistMedicSearch,
    ReferPatientToSpecialistTopBar,
} from '../../components';
import Footer from '../../components/Layout/Footer/Footer';
import useStyles from './ReferPatientToSpecialistPageStyles';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    fetchMedicalServices,
    selectMedicalServicesStatus,
    selectSelectedMedic,
    selectMedicsStatus,
    fetchTags,
    selectTagsStatus,
    fetchAppointment,
    selectAppointmentStatus,
    selectAppointment,
    resetState
} from '../../store/referrals/makePatientReferralSlice';
import {useEffect} from "react";
import useQuery from "../../hooks/useQuery";
import {ERROR} from "../../const/routes";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../const/i18nNamespaces";

const ReferPatientToSpecialistPage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const query = useQuery();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useAppDispatch();
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);

    const appointment = useAppSelector(selectAppointment);
    const medicSelected = useAppSelector(selectSelectedMedic);
    const appointmentStatus = useAppSelector(selectAppointmentStatus);
    const medicsStatus = useAppSelector(selectMedicsStatus);
    const medicalServicesStatus = useAppSelector(selectMedicalServicesStatus);
    const tagsStatus = useAppSelector(selectTagsStatus);

    const loading = appointmentStatus === 'loading'
      ||  medicalServicesStatus === 'loading'
      || medicsStatus === 'loading'
      || tagsStatus === 'loading';

    useEffect(() => {
        const appointmentIdAsInteger = toInteger(
            query.get('appointmentId')
        );

        if (!isInteger(appointmentIdAsInteger)) {
            history.push(ERROR);
        }

        dispatch(fetchAppointment(appointmentIdAsInteger))
        dispatch(fetchMedicalServices());
        dispatch(fetchTags());

        return () => {
            dispatch(resetState())
        }
    }, [dispatch]);

    return (
        <Box>
            <Grid
                container
                className={classes.pageLayout}
            >
                {
                    isDesktop
                        ? (
                            <ReferPatientToSpecialistTopBar
                                referralForText={tReferrals('referralFor')}
                                firstName={appointment?.child?.firstName ?? appointment?.patient.firstName}
                                surname={appointment?.child?.surname ?? appointment?.patient.surname}
                            />
                        ) : (
                            <ReferPatientToSpecialistHeaderMobile
                                referralForText={tReferrals('referralFor')}
                                firstName={appointment?.child?.firstName ?? appointment?.patient.firstName}
                                surname={appointment?.child?.surname ?? appointment?.patient.surname}
                            />
                        )
                }
                <Grid
                    className={classes.pageLayoutMain}
                    container
                >
                    {
                        isDesktop ? (
                            <>
                                <Box className={classes.pageLayoutLeftColumnDesktop}>
                                    <ReferPatientToSpecialistMedicSearch />
                                </Box>
                                {
                                    medicSelected && (
                                        <Box className={classes.pageLayoutRightColumnDesktop}>
                                            <ReferPatientToSpecialistMedicDetails />
                                        </Box>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                {
                                    medicSelected
                                        ? <ReferPatientToSpecialistMedicDetails />
                                        : <ReferPatientToSpecialistMedicSearch />
                                }
                            </>
                        )
                    }
                </Grid>
                <Footer />
            </Grid>
            <Loading withBackdrop={true} loading={loading} />
        </Box>
    )
}

export default ReferPatientToSpecialistPage;