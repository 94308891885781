import axios, {AxiosError} from 'axios';
import configuration from '../configuration';
import {ERROR, LOGOUT_PAGE, NO_INTERNET_ERROR} from '../const/routes';
import {getToken} from '../storage/storage';
import history from '../utils/history';
import {isSuccessStatusCode} from '../utils/http';

const notFoundStatusCode = /404/;

const createConfiguredAxiosInstance = (
    validateStatus?: (status: number) => boolean,
) => {
    const axiosInstance = axios.create({
        baseURL: configuration.ZLAbaseURL,
        withCredentials: true,
        validateStatus,
    });

    axiosInstance.interceptors.request.use(config => {
        const token = getToken();
        const editedConfig = {...config};
        if (token) {
            editedConfig.headers = config.headers || {};
            editedConfig.headers.Authorization = `Bearer ${token}`;
        }
        return editedConfig;
    }, Promise.reject);

    return axiosInstance;
};

export const rawZLAService = createConfiguredAxiosInstance(
    status => status >= 200 && status < 300,
);

export const ZLAService = createConfiguredAxiosInstance(() => true);

ZLAService.interceptors.response.use(
    response => {
        const statusCode = response.status;

        if (notFoundStatusCode.test(statusCode.toString())) {
            return {data: null};
        }

        if (isSuccessStatusCode(statusCode)) {
            return response;
        }

        if (statusCode === 401) {
            history.push(LOGOUT_PAGE);
        } else {
            history.push(ERROR);
            return response;
        }
    },
    (error: AxiosError) => {
        if (!window.navigator.onLine && error.message === 'Network Error')
            history.push(NO_INTERNET_ERROR);
        else history.push(ERROR);
    },
);
