import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    fillLink: {
        color: theme.palette.secondary.main,
        marginTop: 16,
    },
    listWrapper: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        marginTop: 16,
        '& li:not(:first-child)': {
            marginTop: 32,
        },
    },
    dropzone: {
        marginTop: 8,
    },
}));

export default useStyles;
