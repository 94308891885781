import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { BACKGROUND_PRIMARY, GRAY, MAGENTA } from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderTop: `8px solid ${BACKGROUND_PRIMARY}`,
            padding:0,
            [theme.breakpoints.up('md')]: {
                padding: '16px 0',
                borderTop: `1px solid ${BACKGROUND_PRIMARY}`
            },
        },
        timeDetailsSquareWrapper: {
            padding:'16px',
            [theme.breakpoints.up('sm')]: {
                marginRight: '24px',
                padding:0
            },
        },
        status:{
            fontSize:'14px',
            color:GRAY,
            display: 'flex',
            justifyContent: 'flex-end'

        },

        cancelButton:{
            fontSize:'14px',
            color:MAGENTA, 
            marginLeft:'36px'
        },
        title:{
            fontWeight:'bold',
            marginRight:'10px'
        },
        
    }),
);

export default useStyles;
