import React, {ReactNode} from 'react';
import {Box, Checkbox, FormControlLabel, useTheme} from '@material-ui/core';
import {FormikProps} from 'formik';
import {createStyles, makeStyles} from '@material-ui/core';
import {desktop} from '../../../../const/sizes';
import {RED} from '../../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            marginTop: desktop.contentSmallerSpacing,
            display: 'flex',
            alignItems: 'flex-start',
        },
    }),
);

interface FormikCheckboxFieldProps {
    form: FormikProps<any>;
    label: ReactNode;
    fieldName: string;
    fullWidth?: boolean;
    error?: string;
}

const FormikCheckboxField = (props: FormikCheckboxFieldProps) => {
    const {form, label, fieldName, fullWidth, error} = props;
    const classes = useStyles();
    const {palette} = useTheme();
    return (
        <>
            <FormControlLabel
                className={classes.container}
                control={
                    <Checkbox
                        id={fieldName}
                        name={fieldName}
                        checked={form.values[fieldName]}
                        onChange={form.handleChange}
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    />
                }
                label={<Box style={{fontSize: 16, marginTop: 1}}>{label}</Box>}
            />
            {error && (
                <Box style={{color: palette.error.main, marginTop: 10}}>
                    {error}
                </Box>
            )}
        </>
    );
};

export default FormikCheckboxField;
