import {DateTime} from 'luxon';
import {AppointmentPurchaseType} from '../const/appointmentPurchaseType';
import {TimeWindowDates} from '../types/timeSlot';
import {generatePath} from 'react-router';
import {FIND_TIME_SLOTS, TIME_SLOT_PAYMENT_PAGE} from '../const/routes';

export type TimeSlotQueryParams = {
    medicalServiceId: number;
    startDateTime?: DateTime;
    endDateTime?: DateTime;
    appointmentId?: string;
    medicId?: string;
    referralId?: number;
    childId?: number;
    occupationalMedicineReferralId?: number;
};

export type FindTimeSlotsUrlParams = {
    medicalServiceId: number;
    medicId?: string;
    referralId?: number;
    childId?: number;
};

export const parseTimeSlotQuery = ({
    medicalServiceId,
    startDateTime,
    endDateTime,
    appointmentId,
    medicId,
    referralId,
    childId,
    occupationalMedicineReferralId,
}: TimeSlotQueryParams) => {
    const startDateTimeISO = startDateTime?.toUTC().toISO();
    const endDateTimeISO = endDateTime?.toUTC().toISO();

    let path = `?medicalServiceId=${medicalServiceId}`;

    if (startDateTime && endDateTime)
        path += `&startDateTime=${startDateTimeISO}&endDateTime=${endDateTimeISO}`;

    if (appointmentId) path += `&appointmentId=${appointmentId}`;

    if (medicId) path += `&medicId=${medicId}`;

    if (referralId) path += `&referralId=${referralId}`;

    if (childId) path += `&childId=${childId}`;

    if (occupationalMedicineReferralId)
        path += `&occupationalMedicineReferralId=${occupationalMedicineReferralId}`;

    return path;
};

export const generatePaymentUrl = (
    timeSlotId: string,
    appointmentType: string,
    appointmentPurchaseType: AppointmentPurchaseType,
    individualPackageDraftId?: number,
    patientGroupDraftId?: number,
    timeWindow?: TimeWindowDates,
    cancelUrl?: string,
    childId?: number,
    _referralId?: number,
    occupationalMedicineReferralId?: number,
) => {
    let url = generatePath(TIME_SLOT_PAYMENT_PAGE, {
        timeSlotId,
    });

    url = `${url}?appointmentType=${appointmentType}&appointmentPurchaseType=${appointmentPurchaseType}`;

    if (individualPackageDraftId) {
        url = `${url}&individualPackageDraftId=${individualPackageDraftId}`;
    }

    if (patientGroupDraftId) {
        url = `${url}&patientGroupDraftId=${patientGroupDraftId}`;
    }

    if (timeWindow) {
        url = `${url}&addPatientToQueue=${!!timeWindow}&startDate=${
            timeWindow.startDate
        }&endDate=${timeWindow.endDate}`;
    }

    if (cancelUrl) {
        url = `${url}&cancelUrl=${encodeURIComponent(cancelUrl)}`;
    }

    if (childId) {
        url = `${url}&childId=${childId}`;
    }

    if (_referralId) {
        url = `${url}&referralId=${_referralId}`;
    }

    if (occupationalMedicineReferralId) {
        url = `${url}&occupationalMedicineReferralId=${occupationalMedicineReferralId}`;
    }

    return url;
};

export const generateFindTimeSlotsUrl = ({
    medicalServiceId,
    medicId,
    referralId,
    childId,
}: FindTimeSlotsUrlParams) => {
    let url = FIND_TIME_SLOTS;
    url += `?medicalServiceId=${medicalServiceId}&medicId=${medicId}`;

    if (referralId) url += `&referralId=${referralId}`;

    if (childId) url += `&childId=${childId}`;

    return url;
};
