import {
    getMedicationPackList,
    getPrescriptionPdfEndpoint,
    getPrescriptionsEndpoint,
    ISSUE_PRESCRIPTION,
    MEDICINAL_PRODUCT_LIST,
    PRESCRIPTION_HISTORY,
} from './prescriptionsEndpoints';
import {P1Service, rawP1Service} from '../../services/P1Service';
import {
    IssuePrescriptionDto,
    MedicationPackDto,
    MedicinalProductDto,
    PatientPrescriptionHistoryDto,
    PatientPrescriptionHistoryPayload,
    PrescriptionSetDto,
} from '../../types/prescription';
import {P1Response, P1ResponseWithPagination} from '../../types/p1Service';

class PrescriptionApi {
    getMedicinalProductList(search: string) {
        return P1Service.get<P1Response<MedicinalProductDto[]>>(
            MEDICINAL_PRODUCT_LIST,
            {
                params: {search},
            },
        );
    }
    getMedicationPackList(medicinalProductId: number) {
        return P1Service.get<P1Response<MedicationPackDto[]>>(
            getMedicationPackList(medicinalProductId),
        );
    }
    issuePrescription(prescription: IssuePrescriptionDto) {
        return P1Service.post<P1Response<IssuePrescriptionDto>>(
            ISSUE_PRESCRIPTION,
            prescription,
        );
    }
    getPrescriptionsForAppointment(appointmentId: number) {
        return rawP1Service.get<P1Response<PrescriptionSetDto[]>>(
            getPrescriptionsEndpoint(appointmentId),
        );
    }
    getPrescriptionPdf(prescriptionSetId: string) {
        return rawP1Service.get<string>(
            getPrescriptionPdfEndpoint(prescriptionSetId),
        );
    }
    getPatientPrescriptionHistory(
        appointmentData: PatientPrescriptionHistoryPayload,
    ) {
        return P1Service.post<
            P1ResponseWithPagination<PatientPrescriptionHistoryDto>
        >(PRESCRIPTION_HISTORY, appointmentData);
    }
}

export default new PrescriptionApi();
