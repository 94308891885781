import {useAppDispatch, useAppSelector} from './customReduxHooks';
import {
    clearAttachmentsMetaData,
    clearPrescriptionsMetaData,
    getAttachmentsMetaData,
    getPrescriptionsMetaData,
    selectAttachmentsMetaDataStatus,
    selectPrescriptionsMetaDataStatus,
} from '../store/appointmentAttachmentsSlice';
import {
    clearQuestionnaireDetails,
    fetchQuestionnaireDetails,
    selectQuestionnaireDetailsStatus,
} from '../store/questionnaireDetailsSlice';
import {MEDIC} from '../const/auth';
import {selectAuthUserData} from '../store/auth';
import {
    fetchAppointmentDetails,
    selectAppointmentDetails, synchronizeAppointmentDetails,
} from '../store/consultationSlice';
import {Appointment, AppointmentStateEnum} from '../types/appointments';
import {useEffect} from 'react';
import {
    clearHistoricalAppointments,
    fetchHistoricalAppointmentsFilterTypes,
    fetchPageOfUserHistoricalAppointments,
    selectHistoricalAppointmentsFilterTypesStatus,
    selectHistoricalAppointmentsStatus,
} from '../store/userAppointmentsHistorySlice';
import {APPOINTMENT_DETAILS_DATA_REFRESH_TIMER} from '../const/appointmentDetails';

const isNotTestConsultation = (consultationId: string) =>
    consultationId !== 'test_token';

export const useOngoingConsultationDetailsFetchEffect = (
    consultationId: string,
) => {
    const dispatch = useAppDispatch();
    let intervalId: NodeJS.Timeout;
    useEffect(() => {
        if (isNotTestConsultation(consultationId)) {
            dispatch(
                fetchAppointmentDetails({
                    appointmentId: consultationId,
                    fetchSilently: false,
                }),
            );
            intervalId = setInterval(() => {
                dispatch(
                    synchronizeAppointmentDetails({
                        appointmentId: parseInt(consultationId)
                    }),
                );
            }, APPOINTMENT_DETAILS_DATA_REFRESH_TIMER);
        }
        return () => {
            if (isNotTestConsultation(consultationId)) {
                clearInterval(intervalId);
            }
        };
    }, [dispatch, consultationId]);
};

export const useAppointmentAttachmentsFetchEffect = (
    consultationId: string,
) => {
    const dispatch = useAppDispatch();
    const authUserData = useAppSelector(selectAuthUserData);
    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (isNotTestConsultation(consultationId)) {
            dispatch(
                getAttachmentsMetaData({
                    appointmentId: consultationId,
                    fetchSilently: false,
                }),
            );
            intervalId = setInterval(() => {
                dispatch(
                    getAttachmentsMetaData({
                        appointmentId: consultationId,
                        fetchSilently: true,
                    }),
                );
            }, APPOINTMENT_DETAILS_DATA_REFRESH_TIMER);
            if (authUserData.role === MEDIC) {
                dispatch(
                    getPrescriptionsMetaData({
                        appointmentId: consultationId,
                        fetchSilently: false,
                    }),
                );
            }
        }
        return () => {
            if (isNotTestConsultation(consultationId)) {
                clearInterval(intervalId);
                dispatch(clearAttachmentsMetaData());
                if (authUserData.role === MEDIC) {
                    dispatch(clearPrescriptionsMetaData());
                }
            }
        };
    }, [dispatch, consultationId]);
};

export const usePatientDetailsForMedicDataFetchEffect = (
    consultationId: string,
    appointment?: Appointment,
) => {
    const dispatch = useAppDispatch();
    const authUserData = useAppSelector(selectAuthUserData);
    const canFetchDataForMedic =
        isNotTestConsultation(consultationId) && authUserData.role === MEDIC;

    useEffect(() => {
        if (canFetchDataForMedic) {
            dispatch(
                fetchQuestionnaireDetails({
                    appointmentId: consultationId,
                    fetchSilently: false,
                }),
            );
            dispatch(fetchHistoricalAppointmentsFilterTypes());
        }
        return () => {
            if (canFetchDataForMedic) {
                dispatch(clearQuestionnaireDetails());
            }
        };
    }, [dispatch, consultationId]);

    const appointmentDataAvailable = !!appointment?.patient;
    // Second effect is fired, when appointment data have been fetched.
    // Separate useEffect is used to not re-fetch data, that doesn't depend on userId.
    useEffect(() => {
        if (canFetchDataForMedic && appointmentDataAvailable) {
            dispatch(
                fetchPageOfUserHistoricalAppointments({
                    page: 0,
                    userId: appointment.patient.userId,
                    childId: appointment.child?.childId,
                    appointmentId: appointment.id,
                }),
            );
        }
        return () => {
            if (canFetchDataForMedic && appointmentDataAvailable) {
                dispatch(clearHistoricalAppointments());
            }
        };
    }, [dispatch, appointment?.patient?.userId]);
};

export const useHangoutAppointmentByStatus = (
    consultationId: string,
    onHangout?: () => void,
) => {
    const appointmentDetails = useAppSelector(selectAppointmentDetails);

    useEffect(() => {
        const status = appointmentDetails?.state.state;
        if (
            status === AppointmentStateEnum.Completed ||
            status === AppointmentStateEnum.Canceled ||
            status === AppointmentStateEnum.PatientHasNotConnected ||
            status === AppointmentStateEnum.MedicHasNotConnected
        ) {
            onHangout();
        }
    }, [appointmentDetails?.state]);
};

export const useAppointmentDetailsFetchForOngoingConsultation = (
    consultationId: string,
    consultation: Appointment,
) => {
    useOngoingConsultationDetailsFetchEffect(consultationId);
    usePatientDetailsForMedicDataFetchEffect(consultationId, consultation);
    useAppointmentAttachmentsFetchEffect(consultationId);
};

export const useAllPatientDetailsForMedicLoading = () => {
    const questionnaireDetailsStatus = useAppSelector(
        selectQuestionnaireDetailsStatus,
    );
    const historicalAppointmentsStatus = useAppSelector(
        selectHistoricalAppointmentsStatus,
    );
    const historicalAppointmentsFilterTypesStatus = useAppSelector(
        selectHistoricalAppointmentsFilterTypesStatus,
    );
    const attachmentsMetaDataStatus = useAppSelector(
        selectAttachmentsMetaDataStatus,
    );
    const prescriptionsMetaDataStatus = useAppSelector(
        selectPrescriptionsMetaDataStatus,
    );
    return (
        questionnaireDetailsStatus === 'loading' ||
        historicalAppointmentsStatus === 'loading' ||
        historicalAppointmentsFilterTypesStatus === 'loading' ||
        attachmentsMetaDataStatus === 'loading' ||
        prescriptionsMetaDataStatus === 'loading'
    );
};
