import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    addTagWrapper: {
        marginTop: 21,
    },
    addedTagsWrapper: {
        marginTop: 8,
        [theme.breakpoints.up('md')]: {
            marginTop: 12,
        }
    }
}));

export default useStyles;