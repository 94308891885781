import { useTranslation } from "react-i18next";
import useStyles from "./StampMobileStyles";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { Box, Grid } from "@material-ui/core";
import useTileStyles from '../common/tileCommonStyles'
import EditStampActionButton from "./editStampActionButton/EditStampActionButton";
import StampPicture from "./stampPicture/StampPicture";

const StampMobile = () =>{

    const classes = useStyles();
    const tileClasses = useTileStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);

    return (
        <Grid
            container
            className={tileClasses.root}
        >
            <Grid
                item
                className={classes.leftContainer}
            >
                <h3 className={tileClasses.headerText}>
                    {commonT('yourStamp')}
                </h3>
                <Box className={classes.actionButtonsWrapper}>
                    <EditStampActionButton />
                </Box>
            </Grid>
            <Grid item>
                <StampPicture />
            </Grid>
        </Grid>
    );
}
export default StampMobile;