import {makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';

export default makeStyles((theme: Theme) => ({
    container: {
        marginBottom: mobile.contentLargerSpacing,
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            margin: `0 ${desktop.contentSmallerSpacing} ${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
        },
    },
    topContainer:{
        marginTop:mobile.contentLargerSpacing,
        [theme.breakpoints.up('sm')]: {
            marginTop:'24px'
        }
    }
}));
