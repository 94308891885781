import { Box, Grid, IconButton, Theme, useMediaQuery } from "@material-ui/core";
import { AddCircleOutline, DeleteOutline } from "@material-ui/icons";
import findIndex from "lodash-es/findIndex";
import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MedicalTestIcon, MedicalTestDisabledIcon } from "../../../assets";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { AlertContext } from "../../../contexts/AlertContext";
import { useAppDispatch, useAppSelector } from "../../../hooks/customReduxHooks";
import medicPackageProvider from "../../../services/diagnostic/medicPackageProvider";
import { addSelectedMedicalPackage, addSelectedMedicalTest, clearSelectedMedicalPackage, clearSelectedMedicalTests, selectSelectedMedicalPackage } from "../../../store/referrals/medicalTestsSlice";
import { MedicPackageDto } from "../../../types/diagnosticServicePackage";
import Loading from "../../common/Loading/Loading";
import CustomAvatar from "../../common/UserPhoto/CustomAvatar";
import useStyles from "./MedicalPackageCardStyles";

interface Props {
    medicalPackage: MedicPackageDto;
}

const MedicalPackageCard = memo(function MedicalSearchFilters({
    medicalPackage,
}: Props) {
    const dispatch = useAppDispatch();
    const openAlert = useContext(AlertContext);

    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);
    
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);

    const selectedMedicalPackage = useAppSelector(selectSelectedMedicalPackage);

    const isMedicalPackageSelected = 
        selectedMedicalPackage && selectedMedicalPackage.id === medicalPackage.id;

    const medicalPackageRemoveHandler = () => {
        dispatch(clearSelectedMedicalTests());
        dispatch(clearSelectedMedicalPackage());
    }

    const isDisabled = selectedMedicalPackage!== null ? selectedMedicalPackage.id !== medicalPackage.id : false;

    const getSelectedPackageMedicalTest = async () =>{
        setLoading(true);
        try{
            const diagnosticServices = await medicPackageProvider.getPackageDiagnosticServices(medicalPackage.id);
            diagnosticServices.forEach(diagnosticService =>{
                dispatch(addSelectedMedicalTest(diagnosticService));
            })
        }
        catch{

        }
        finally{
            setLoading(false);
        }
    }

    const medicalPackageAddHandler = async () => {
        if (isMedicalPackageSelected) return;

        if (selectedMedicalPackage){
            openAlert({
                severity: 'error',
                message: tReferrals('canAddOnlyOnePackage')
            });
        }
        else{

            dispatch(addSelectedMedicalPackage(medicalPackage));
            await getSelectedPackageMedicalTest();
        }
    }

    return (
        <>
        <Grid container wrap={'nowrap'} alignItems="center" spacing={1}>
            <Grid item xs="auto">

                {isDisabled
                    ? <CustomAvatar
                        alt={`photo`}
                        src={MedicalTestDisabledIcon}
                        isActive={isMedicalPackageSelected} />
                    : <CustomAvatar
                        alt={`photo`}
                        src={MedicalTestIcon}
                        isActive={isMedicalPackageSelected} />
                }
                    
                
            </Grid>
            <Grid item xs>
                {isDisabled ? 
                    <Box className={classes.medicalPackageNameDisabled}>
                        {medicalPackage.name}
                    </Box>    
                    :
                    <Box className={classes.medicalPackageName}>
                        {medicalPackage.name}
                    </Box>  
                }
                
            </Grid>
            <Grid item xs="auto">
                {isMedicalPackageSelected ? (
                    <IconButton onClick={medicalPackageRemoveHandler}>
                        <DeleteOutline
                            color="secondary"
                            className={classes.medicalPackageAddDeleteIcon}
                            style={{cursor: 'pointer'}}
                        />
                    </IconButton>
                ) : (
                    <IconButton onClick={medicalPackageAddHandler} 
                        disabled={isDisabled}>
                            {isDisabled 
                            ?   <AddCircleOutline
                                color='disabled'
                                className={classes.medicalPackageAddDeleteIcon}
                                style={{cursor: 'pointer'}}
                            />
                                : <AddCircleOutline
                                color="secondary"
                                className={classes.medicalPackageAddDeleteIcon}
                                style={{cursor: 'pointer'}}
                            />}
                        
                    </IconButton>
                )}
            </Grid>
        </Grid>
        <Loading loading={loading} withBackdrop />
        </>
    );
});

export default MedicalPackageCard;