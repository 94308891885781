import {createStyles, makeStyles} from '@material-ui/core';
import { LIGHT_GRAY } from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            marginTop: 50,
            marginBottom: 64,
            backgroundColor: LIGHT_GRAY,
            paddingTop: 36, 
            paddingBottom:36
        },
        title: {fontSize: 40},
        description: {
            marginTop: 14,
            marginBottom: 48,
            maxWidth: 900,
            textAlign: 'center',
        },
        logo:{
            maxHeight:50,
            minHeight:25,
            margin:56
        }
    }),
);

export default useStyles;
