import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import {mobile} from '../../../const/sizes';
import {availabilityTemplateDayWidth} from '../../../pages/AvailabilityTemplate/AvailabilityTemplateStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        templateDayWrapper: {
            backgroundColor: WHITE,
        },
        dayOfWeekWrapper: {
            padding: `20px ${mobile.contentLargerSpacing}`,
        },
        dayOfWeek: {
            fontWeight: 'bold',
            fontSize: 16,
        },
        addTimeSlotTemplate: {
            width: 26,
            height: 26,
            color: theme.palette.secondary.main,
            cursor: 'pointer',
        },
        timeSlotTemplateWrapper: {
            padding: mobile.contentLargerSpacing,
        },
        noTimeSlotsWrapper: {
            width: availabilityTemplateDayWidth,
            height: '72px',
        },
        noTimeSlots: {
            color: theme.palette.grey[600],
        },
        [theme.breakpoints.up('sm')]: {
            dayOfWeek: {
                fontSize: 20,
            },
            addTimeSlotTemplate: {
                width: 22,
                height: 22,
            },
        },
    }),
);

export default useStyles;
