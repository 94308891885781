import { MutableRefObject, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { useAppDispatch } from "../../../hooks/customReduxHooks";
import { isNotNullOrUndefined } from "../../../utils/lang";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18nNamespaces from "../../../const/i18nNamespaces";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import { useFormik } from "formik";
import * as yup from 'yup';
import { PatientProfessionalExperienceDto } from "../../../types/occupationalMedicine";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import CustomSelectField from "../../common/CustomSelectField/CustomSelectField";
import { RenderGridWithCheckBox, RenderReturnNextButtons } from "../CommonView";
import { useParams } from "react-router";
import { fetchSection4Data } from "../../../store/occupationalMedicineSlice";

const REQUIRED_STRING = 'requiredField';
const SECTION = 4;

export type Props = {
    data: PatientProfessionalExperienceDto,
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void
    currentExpanded:number
    onReturn: () => void;
    refSection:MutableRefObject<any>
}
const PatientProfessionalExperienceComponent = ({
    data, onSubmitSection, currentExpanded, onReturn, refSection
}:Props) =>{

    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const dispatch = useAppDispatch();

    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);
    
    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values),fetchSection4Data({id:appointmentId}),4);
    }
    
    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {
                occupationalDisease:{
                    occured: data?.occupationalDisease.occured ?? false,
                    what: data?.occupationalDisease.what ?? '',
                    diseaseCode: data?.occupationalDisease.diseaseCode ?? '',
                },            
                requestToChangePosition:{
                    occured:data?.requestToChangePosition.occured ?? false,
                    when:data?.requestToChangePosition.when ?? '',
                    description:data?.requestToChangePosition.description ?? '',
                },
                accidentInWork:{
                    occured:data?.accidentInWork.occured ?? false,
                    when:data?.accidentInWork.when ?? '',
                    description:data?.accidentInWork.description ?? '',
                },
                disabilityBenefit:{
                    occured:data?.disabilityBenefit.occured ?? false,
                    when:data?.disabilityBenefit.when ?? '',
                    description:data?.disabilityBenefit.description ?? '',
                },
                degreeOfDisability:{
                    occured:data?.degreeOfDisability.occured ?? false,
                    when:data?.degreeOfDisability.when ?? '',
                    description:data?.degreeOfDisability.description ?? '',
                },
            },

            validationSchema: yup.object({
            }),
            onSubmit
        });

    
    return (
        <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >

                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('professionalExperience')}
                        </h4>

                        {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.detailsContainer}>
                    <Box>
                        <Grid container spacing={1} className={classes.gridContainer}>

                            <Grid item xs={12}> 
                                <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                        {t('duringYourProfessionalCareer')} 
                                </Box>
                            </Grid>
                         

                            {RenderGridWithCheckBox
                                (12, "occupationalDisease.occured", values.occupationalDisease.occured, false, 
                                handleChange, classes, t('hasAnOccupationalDisease') )}

                            {values.occupationalDisease.occured && 
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="what">{t("what")}?</Label>
                                        <CustomTextField 
                                                fullWidth 
                                                id="occupationalDisease.what"
                                                name="occupationalDisease.what"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.occupationalDisease.what}
                                                />
                                    </FormGroup>
                                
                                </Grid>
                            }
                            {values.occupationalDisease.occured &&
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="diseaseNumber">{t("diseaseNumber")}</Label>
                                        <CustomTextField 
                                                fullWidth 
                                                id="occupationalDisease.diseaseCode"
                                                name="occupationalDisease.diseaseCode"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.occupationalDisease.diseaseCode}
                                                />
                                    </FormGroup>
                                </Grid>
                            }
                           
                            {RenderGridWithCheckBox
                                (12, "requestToChangePosition.occured", values.requestToChangePosition.occured, false, 
                                handleChange, classes, t('wasRequestedToChangeOfPositionWorkDueToHealth') )}

                            {values.requestToChangePosition.occured && 
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="when">{t("when")}?</Label>
                                        <CustomTextField 
                                                fullWidth 
                                                id="requestToChangePosition.when"
                                                name="requestToChangePosition.when"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.requestToChangePosition.when}
                                                />
                                    </FormGroup>
                                </Grid>
                            }
                            {values.requestToChangePosition.occured &&
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="description">{t("whatReason")}</Label>
                                        <CustomTextField 
                                                fullWidth 
                                                id="requestToChangePosition.description"
                                                name="requestToChangePosition.description"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.requestToChangePosition.description}
                                                />
                                    </FormGroup>
                                </Grid>
                            }
        
                            {RenderGridWithCheckBox
                                (12, "accidentInWork.occured", values.accidentInWork.occured, false, 
                                handleChange, classes, t('hadAnAccidentAtWork') )}

                            {values.accidentInWork.occured && 
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="when">{t("when")}?</Label>
                                        <CustomTextField 
                                                fullWidth 
                                                id="accidentInWork.when"
                                                name="accidentInWork.when"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.accidentInWork.when}
                                                />
                                    </FormGroup>
                                </Grid>
                            }
                            {values.accidentInWork.occured && 
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="accidentConsequences">{t("accidentConsequences")}</Label>
                                        <CustomTextField 
                                                fullWidth 
                                                id="accidentInWork.description"
                                                name="accidentInWork.description"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.accidentInWork.description}
                                                />
                                    </FormGroup>
                                </Grid>
                            }

                            {RenderGridWithCheckBox
                                (12, "disabilityBenefit.occured", values.disabilityBenefit.occured, false, 
                                handleChange, classes, t('hasPensionBenefit') )}

                            {values.disabilityBenefit.occured && 
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="when">{t("when")}</Label>
                                        <CustomTextField 
                                                fullWidth 
                                                id="disabilityBenefit.when"
                                                name="disabilityBenefit.when"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.disabilityBenefit.when}
                                                />
                                    </FormGroup>
                                </Grid>
                            }
                            {values.disabilityBenefit.occured && 
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="whatReason">{t("whatReason")}</Label>
                                        <CustomSelectField
                                            id="disabilityBenefit.description"
                                            name="disabilityBenefit.description"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.disabilityBenefit.description}
                                        >
                                            <MenuItem value={'Z'} id="0">
                                                {t('occupantionalDisease')}
                                            </MenuItem>
                                            <MenuItem value={'W'} id="1">
                                                {t('accidentInWork')}
                                            </MenuItem>
                                            <MenuItem value={'O'} id="2">
                                                {t('generalHealth')}
                                            </MenuItem>
                                        </CustomSelectField>               
                                    </FormGroup>
                                </Grid>
                            }

                            {RenderGridWithCheckBox
                                (12, "degreeOfDisability.occured", values.degreeOfDisability.occured, false, 
                                handleChange, classes, t('hasADegreeOfDisability') )}

                            {values.degreeOfDisability.occured && 
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="when">{t("when")}</Label>
                                        <CustomTextField 
                                                fullWidth 
                                                id="degreeOfDisability.when"
                                                name="degreeOfDisability.when"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.degreeOfDisability.when}
                                                />
                                    </FormGroup>
                                </Grid>         
                            }                  
                            {values.degreeOfDisability.occured && 
                                <Grid item xs={4}><FormGroup>
                                    <Label htmlFor="degreeOrReason">{t("degreeOrReason")}</Label>
                                    <CustomTextField 
                                            fullWidth 
                                            id="degreeOfDisability.description"
                                            name="degreeOfDisability.description"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.degreeOfDisability.description}
                                            />
                                </FormGroup>
                            </Grid>
                            }
                        </Grid>
                        {RenderReturnNextButtons(classes, t, onReturn)}
                    </Box>

                </AccordionDetails>
            </Accordion>
        </form>
    )
};

export default PatientProfessionalExperienceComponent;