import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {makeMedicalTestsOrder} from '../../../services/diagnostic/orderHeadersProvider';

export interface OrderMedicalTestsState {
    readonly loading: boolean;
    readonly error: string;
    readonly makeOrder: (referralId: string, laboratoryId: number) => void;
}

const useOrderMedicalTests = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const makeOrder = useCallback(async (referralId, laboratoryId) => {
        setLoading(true);
        try {
            setError(null);
            await makeMedicalTestsOrder(referralId, laboratoryId);
        } catch (error) {
            setError(tErrors('sendingDataToServerError'))
            throw error;
        } finally {
            setLoading(false);
        }
    }, []);

    const value = useMemo<OrderMedicalTestsState>(
        () => ({
            loading,
            error,
            makeOrder
        }),
        [loading, error, makeOrder]
    );

    return value;
}

export default useOrderMedicalTests;