import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import i18nNamespaces from '../../const/i18nNamespaces';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useMemo, useState} from 'react';
import useStyles from './DeclarationOfPublicInsurancePageStyles';
import {RegionalBranch} from '../../types/insuranceDeclaration';
import {CustomSelect, Loading} from '../../components';
import {insuranceDeclarationApi} from '../../api/insuranceDeclaration/insuranceDeclarationApi';
import {useAppSelector} from '../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../store/auth';
import {useHistory} from 'react-router';
import {ACCOUNT_DATA_PAGE} from '../../const/routes';
import {ACCOUNT} from '../../services/endpoints';

enum InsuranceOption {
    INSURED = 'insured',
    NOT_INSURED = 'notInsured',
}
const DeclarationOfPublicInsurancePage = () => {
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const [insuranceOption, setInsuranceOption] =
        useState<InsuranceOption | null>(null);
    const [regionalBranchList, setRegionalBranchList] = useState<
        RegionalBranch[]
    >([]);
    const [selectedRegionalBranchCode, setSelectedRegionalBranchCode] =
        useState<RegionalBranch['code'] | null>(null);
    const authUserData = useAppSelector(selectAuthUserData);
    const [loading, setLoading] = useState<boolean>(false);
    const classes = useStyles();
    const history = useHistory();

    const isSendingDisabled = useMemo(
        () =>
            (!selectedRegionalBranchCode &&
                insuranceOption === InsuranceOption.INSURED) ||
            !insuranceOption,
        [selectedRegionalBranchCode, insuranceOption],
    );
    const fetchRegionalBranches = async () => {
        try {
            const {data} = await insuranceDeclarationApi.getNfzRegionalBranch();
            setRegionalBranchList(data.result);
        } catch (err) {
            console.error(err);
        }
    };

    const sendInsuranceDeclaration = async () => {
        try {
            setLoading(true);
            const {data} =
                await insuranceDeclarationApi.sendInsuranceDeclaration(
                    {
                        hasPublicInsurance:
                            insuranceOption === InsuranceOption.INSURED,
                        nfzRegionalBranchCode: selectedRegionalBranchCode,
                    },
                    authUserData.userId,
                );
            if (data.isSuccess)
                history.push('/account' + ACCOUNT_DATA_PAGE, {
                    successMessage: t('insuranceDeclarationHasBeenSent'),
                });
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRegionalBranches();
    }, []);

    return (
        <div className={classes.container}>
            <Loading loading={loading} withBackdrop />
            <FormControl component="fieldset">
                <Typography variant={'h1'} style={{marginBottom: 30}}>
                    {t('nfzInsuranceDeclaration')}
                </Typography>
                <RadioGroup
                    aria-label="insurance"
                    name="insurance"
                    value={insuranceOption}
                    onChange={e =>
                        setInsuranceOption(e.target.value as InsuranceOption)
                    }
                >
                    <FormControlLabel
                        value={InsuranceOption.INSURED}
                        control={<Radio />}
                        label={t('insured')}
                    />
                    {insuranceOption === InsuranceOption.INSURED &&
                        regionalBranchList.length && (
                            <>
                                <FormControl
                                    variant="outlined"
                                    style={{minWidth: 500, margin: '15px 0'}}
                                >
                                    <InputLabel id="selectRegionalBranchLabel">
                                        {t('selectRegionalBranch')}
                                    </InputLabel>
                                    <CustomSelect
                                        labelId={'selectRegionalBranchLabel'}
                                        value={selectedRegionalBranchCode ?? ''}
                                        label={t('selectRegionalBranch')}
                                        onChange={e =>
                                            setSelectedRegionalBranchCode(
                                                e.target
                                                    .value as RegionalBranch['code'],
                                            )
                                        } //
                                        fullWidth
                                    >
                                        {regionalBranchList.map(
                                            regionalBranchItem => (
                                                <MenuItem
                                                    value={
                                                        regionalBranchItem.code
                                                    }
                                                    key={
                                                        regionalBranchItem.code
                                                    }
                                                >
                                                    {regionalBranchItem.name}
                                                </MenuItem>
                                            ),
                                        )}
                                    </CustomSelect>
                                </FormControl>
                            </>
                        )}
                    <FormControlLabel
                        value={InsuranceOption.NOT_INSURED}
                        control={<Radio />}
                        label={t('notInsured')}
                    />
                </RadioGroup>
            </FormControl>
            <Button
                variant={'contained'}
                color={'secondary'}
                style={{marginTop: 30}}
                disabled={isSendingDisabled}
                onClick={sendInsuranceDeclaration}
            >
                {t('sendDeclaration')}
            </Button>
        </div>
    );
};

export default DeclarationOfPublicInsurancePage;
