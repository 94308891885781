import {ReactNode, useEffect, useMemo, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {refreshToken, selectAuthUserData} from '../../../store/auth';
import {DateTime} from 'luxon';
import {MEDIC, NOT_CONFIRMED_USER, PATIENT, Role} from '../../../const/auth';
import {isRoleAllowedToRefreshToken} from '../../../utils/auth';

const PERIODIC_REFRESH_RATE = 600000; // 10 min

export type AuthProviderProps = {
    children: ReactNode;
};

const AuthProvider = ({children}: AuthProviderProps) => {
    const dispatch = useAppDispatch();
    const authUserData = useAppSelector(selectAuthUserData);
    const timeoutRef = useRef<NodeJS.Timeout>(null);

    useEffect(() => {
        if (!authUserData || !isRoleAllowedToRefreshToken(authUserData?.role))
            return clearTimeout(timeoutRef.current);
        if (!timeoutRef.current) dispatch(refreshToken());

        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            dispatch(refreshToken());
        }, PERIODIC_REFRESH_RATE);

        return () => clearTimeout(timeoutRef.current);
    }, [authUserData]);

    return <>{children}</>;
};

export default AuthProvider;
