import {rawHttp} from '../../services/http';
import {PHONE_NUMBER, REGISTER_SIMPLY, SMS_CODE} from './accountApiEndpoints';
import {RegisterSimplyDto} from '../../types/register';
import {AuthResultDto} from '../../types/auth';

class AccountApi {
    registerSimply(data: RegisterSimplyDto) {
        return rawHttp.put<AuthResultDto>(REGISTER_SIMPLY, data);
    }

    getUserPhoneNumber() {
        return rawHttp.get<{phoneNumber: string}>(PHONE_NUMBER);
    }

    verifyPhoneNumber(code: string) {
        return rawHttp.post<AuthResultDto>(SMS_CODE, {code});
    }
}

export const accountApi = new AccountApi();
