import {Box, Divider, IconButton} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {Prescription} from '../../../types/prescription';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

export interface SelectedPrescription extends Prescription {
    id: string;
}

type PrescriptionItemProps = {
    prescription: SelectedPrescription;
    onDelete: (prescriptionId: string) => void;
    index: number;
};
const PrescriptionItem = ({
    prescription,
    onDelete,
    index,
}: PrescriptionItemProps) => {
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    return (
        <Box style={{marginBottom: 20}}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <h2 style={{margin: '0 0 10px 0'}}>Lek {index + 1}</h2>
                <IconButton onClick={() => onDelete(prescription.id)}>
                    <Delete />
                </IconButton>
            </Box>
            <Box>{prescription.medicinalProduct.displayValue}</Box>
            <Box>
                {prescription.quantity} {t('packs')},{' '}
                {prescription.medicationPack.displayValue}
            </Box>
            <Box>{prescription.usageRecommendation}</Box>
            <Box>
                {t('paymentRate')}:{' '}
                {prescription.reimbursement?.paymentLevelDisplayValue}
            </Box>
            <Divider style={{marginTop: 20}} />
        </Box>
    );
};
export default PrescriptionItem;
