import {Box, Button} from '@material-ui/core';
import clsx, {ClassValue} from 'clsx';
import useStyles from './SelectButtonStyles';

export type SelectButtonOption = {
    value: string | number;
    label: string;
    icon?: JSX.Element;
};

type Props = {
    value?: SelectButtonOption;
    options: SelectButtonOption[];
    onChange: (value: SelectButtonOption) => void;
    containerStyles?: ClassValue;
    buttonStyles?: ClassValue;
    disabled?:boolean
};

const SelectButtons = ({
    value,
    options,
    onChange,
    containerStyles,
    buttonStyles,
    disabled
}: Props) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.container, containerStyles)}>
            {options.map(option => (
                <Button
                    key={option.value}
                    disableElevation
                    variant="outlined"
                    className={clsx(classes.button, buttonStyles)}
                    color={'secondary'}
                    classes={{
                        root:
                            value.value === option.value &&
                            classes.selectedButton,
                    }}
                    startIcon={option.icon || null}
                    onClick={() => onChange(option)}
                    disabled={disabled}
                >
                    {option.label}
                </Button>
            ))}
        </Box>
    );
};

export default SelectButtons;
