import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {TEXT_SECONDARY} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dateSelectionWrapper: {
            padding: desktop.contentSmallerSpacing,
            fontSize: mobile.defaultFontSize,
        },
        selectedDateWrapper: {
            marginBottom: mobile.contentLargerSpacing,
        },
        selectedDate: {
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
        },
        datePickerWrapper: {
            borderColor: theme.palette.grey[300],
            borderWidth: '2px',
            borderStyle: 'solid',
        },
        dateSelectionButtonWrapper: {
            alignSelf: 'stretch',
            marginTop: desktop.contentSmallerSpacing,
        },
        dateSelectionButton: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            dateSelectionWrapper: {
                padding: 0,
            },
            selectedDateWrapper: {
                padding: desktop.contentSmallerSpacing,
                paddingBottom: 0,
            },
            addAppointmentsSection: {
                cursor: 'pointer',
                padding: mobile.contentLargerSpacing,
            },
            addAppointmentIcon: {
                color: theme.palette.secondary.main,
            },
            addAppointmentText: {
                color: TEXT_SECONDARY,
                paddingLeft: mobile.contentSmallerSpacing,
            },
        },
    }),
);

export default useStyles;
