import { createStyles, makeStyles } from "@material-ui/core";


const useStyles = makeStyles(() =>
    createStyles({
        container: {
            paddingTop:'24px'
        }
    }),
    );

export default useStyles;
