import {useAppSelector} from "../../../../hooks/customReduxHooks";
import {selectMedicDetails, selectNewMedicPicture, selectNewMedicStamp} from "../../../../store/medicProfileSlice";
import {useEffect, useState} from "react";
import CustomAvatar from "../../../common/UserPhoto/CustomAvatar";
import useStyles from "./StampPictureStyles";
import { Box } from "@material-ui/core";
import {BrandingWatermarkOutlined} from '@material-ui/icons';

const StampPicture = () => {
    const classes = useStyles();
    const [medicStamp, setMedicStamp] = useState('');
    const medicDetails = useAppSelector(selectMedicDetails);
    const newMedicStamp = useAppSelector(selectNewMedicStamp);

    useEffect(() => {
        if (newMedicStamp) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setMedicStamp(reader.result as string)
            });
            reader.readAsDataURL(newMedicStamp);
        } else {
            setMedicStamp(medicDetails?.stamp || '')
        }
    }, [newMedicStamp, medicDetails?.stamp]);

    return (
        <Box>
            {medicStamp ? 
                <img src={medicStamp} className={classes.picture}/>
                :
                <BrandingWatermarkOutlined className={classes.icon}/>
            }            
        </Box>
    );
}

export default StampPicture;