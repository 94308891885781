import React from 'react';
import useStyles from './IndividualPackagesPageStyles';
import {ExpandedIndividualPackages} from '../../components';
import {Box} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import configuration from '../../configuration';

const IndividualPackagesPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const {t: tMetaDescription} = useTranslation(i18nNamespaces.META_DESCRIPTION);

    return (
        <>
        {configuration.environment ===  'Production' &&
            <Helmet>
                <title>{tMetaDescription('individualPackagePageTitle')}</title>
                <meta name='description' content={tMetaDescription('individualPackagePageDescription')} />
                <meta name='keywords' content={tMetaDescription('individualPackagePageKeywords')}/>
            </Helmet>
        }
            <Box className={classes.container}>
                <ExpandedIndividualPackages />
            </Box>
        </>
    );
};

export default IndividualPackagesPage;
