import {
    Box,
    FormControlLabel,
    Grid,
    Radio,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import {CreateAccountImage, MojeIDLogo} from '../../../assets';
import useStyles from './CreateAccountStyles';
import {Button} from '../../../components';
import {LoginType} from '../LoginPage';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

type Props = {
    selected?: boolean;
    onClickAlternativeSignUp: () => void;
    onClickMojeID: () => void;
};

const CreateAccount = ({
    selected,
    onClickAlternativeSignUp,
    onClickMojeID,
}: Props) => {
    const classes = useStyles();
    const radioWrapperStyle = clsx(classes.radioWrapper, {
        [classes.selectedRadioWrapper]: selected,
    });
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const {t} = useTranslation(i18nNamespaces.AUTH);

    return (
        <Box m={1}>
            <Box px={isDesktop ? '25px' : '17px'} className={radioWrapperStyle}>
                <FormControlLabel
                    className={classes.formControlWrapper}
                    value={'CREATE_ACCOUNT' as LoginType}
                    control={<Radio className={classes.radio} />}
                    aria-label="Załóż konto"
                    label={
                        <Box className={classes.radioText}>
                            {t('createNewAccount')}
                        </Box>
                    }
                />
            </Box>
            <Box
                mt={0.5}
                p={isDesktop ? 2 : 1}
                className={clsx(classes.optionContent, {
                    [classes.contentBackground]: selected,
                })}
            >
                {selected ? (
                    <>
                        <Typography paragraph>
                            {t('createAccountFirstParagraph')}
                        </Typography>
                        <br />
                        <Typography paragraph>
                            {t('createAccountSecondParagraph')}
                        </Typography>
                        <Box mt={3}>
                            <Grid container>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.registerButtonsWrapper}
                                >
                                    <Grid item>
                                        <img
                                            src={MojeIDLogo}
                                            alt="Moje ID"
                                            className={classes.mojeIDLogo}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant={'contained'}
                                            color={'secondary'}
                                            className={classes.signUpButton}
                                            onClick={onClickMojeID}
                                        >
                                            <span className='register-by-bank'>{t('registerByTheBank')}</span>
                                        </Button>
                                        <Box
                                            color={'secondary.main'}
                                            onClick={onClickAlternativeSignUp}
                                            className={
                                                classes.notOnTheListButton
                                            }
                                        >
                                            {t('yourBankIsNotOnTheList')}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <img src={CreateAccountImage} alt="Utwórz konto" />
                )}
            </Box>
        </Box>
    );
};

export default CreateAccount;
