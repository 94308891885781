import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up('md')]: {
                minHeight: `calc(100vh - 128px)`,
            },
            paddingLeft: 30,
            paddingRight: 30,
            
        },
        title: {marginTop: 32, fontSize: 36, paddingLeft: 16, paddingRight: 16, paddingBottom:56},  
        [theme.breakpoints.up('sm')]: {
            title: {
                fontSize: 56,
            }, 
        },
    }),
);

export default useStyles;
