import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { PhysicalTestsByMedicDto } from "../../../types/occupationalMedicine";
import { MutableRefObject, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Accordion, AccordionDetails, AccordionSummary } from "../../common/Accordion/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CheckCircleOutline } from "@material-ui/icons";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem } from "@material-ui/core";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { fetchSection9Data } from "../../../store/occupationalMedicineSlice";
import { useParams } from "react-router";
import { RenderReturnNextButtons } from "../CommonView";


const REQUIRED_STRING = 'requiredField';
const SECTION = 9;

export type Props = {
    currentExpanded:number
    data:PhysicalTestsByMedicDto;
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void;
    onReturn: () => void;
    refSection:MutableRefObject<any>
}

const PhysicalTestsByMedic = ({currentExpanded, data, onSubmitSection, refSection, onReturn}:Props) =>{
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();

    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);

    
    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values), fetchSection9Data({id:appointmentId}),9);
    }

    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {             
                bodyHeight: data?.bodyHeight ?? "",
                bodyWeight: data?.bodyWeight ?? "",
                pulse: data?.pulse ?? t("notTested"),
                rr: data?.rr ?? t("notTested"),
                leftEye: data?.leftEye  ?? t("notTested"),
                rightEye: data?.rightEye ?? t("notTested"),
                colorRecognition: data?.colorRecognition ?? "",
                squint: data?.squint ?? false,
                approximareFieldOfView: data?.approximareFieldOfView ?? t("notTested"),
                whisperRigthEar: data?.whisperRigthEar ?? t("notTested"),
                whisperLeftEar: data?.whisperLeftEar ?? t("notTested"),
                romberg: data?.romberg ?? "",
                nystagmus:data?.nystagmus ?? false,
            },
            validationSchema: yup.object({

            }),
            onSubmit,
        }); 

        return (
            <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">

                        <Box className={classes.titleContainer}>
                            <h4 className={classes.sectionHeader}>
                                {t('phisicalTestsByMedic')}
                            </h4>

                            {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsContainer}>
                        <Box>
                            <Grid container spacing={1} className={classes.gridContainer}>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="bodyHeight">{t("bodyHeight")} </Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="bodyHeight"
                                            name="bodyHeight"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.bodyHeight}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="bodyWeight">{t("bodyWeight")} </Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="bodyWeight"
                                            name="bodyWeight"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.bodyWeight}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="pulse">{t('pulse')}</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="pulse"
                                            name="pulse"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.pulse}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="rr">{t('rr')}</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="rr"
                                            name="rr"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.rr}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={12}>
                                    <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                        {t('sight')}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="leftEye">{t('leftEye')}</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="leftEye"
                                            name="leftEye"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.leftEye}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="rightEye">{t('rightEye')}</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="rightEye"
                                            name="rightEye"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.rightEye}

                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="colorRecognition">{t('colorRecognition')}</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="colorRecognition"
                                            name="colorRecognition"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.colorRecognition}
                                            />
                                    </FormGroup>
                                </Grid>   
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="approximareFieldOfView">{t('approximareFieldOfView')}</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="approximareFieldOfView"
                                            name="approximareFieldOfView"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.approximareFieldOfView}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <FormGroup>     
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="squint"
                                                    name="squint"
                                                    checked={values.squint}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={
                                                <Box className={classes.text}>
                                                    {t('squint')}
                                                </Box>
                                            }     
                                            />
                                </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                        {t('hearing')}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="whisperRigthEar">{t('whisperRightEar')}</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="whisperRigthEar"
                                            name="whisperRigthEar"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.whisperRigthEar}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="whisperLeftEar">{t('whisperLeftEar')}</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="whisperLeftEar"
                                            name="whisperLeftEar"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.whisperLeftEar}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                        {t('balanceSystem')}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup >
                                        <Label htmlFor="romberg">Romberg</Label>
                                        <CustomTextField 
                                            fullWidth 
                                            id="romberg"
                                            name="romberg"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.romberg}
                                            />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={8}></Grid>
                                <Grid item xs={4}>
                                    <FormGroup>     
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="nystagmus"
                                                    name="nystagmus"
                                                    checked={values.nystagmus}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={
                                                <Box className={classes.text}>
                                                    {t('nystagmus')}
                                                </Box>
                                            }     
                                            />
                                </FormGroup>
                                </Grid>
                            </Grid>
                            {RenderReturnNextButtons(classes, t, onReturn)}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </form>

        );

}
export default PhysicalTestsByMedic;