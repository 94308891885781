import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';
import {LIGHT_GRAY, MAGENTA} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: desktop.contentLargerSpacing,
        },
        title: {
            fontSize: 36,
            fontWeight: 'bold',
            marginBottom: 30,
        },
        formGroup: {
            marginTop: desktop.contentSmallerSpacing,
        },
        passwordLabelContainer: {
            width: '100%',
        },
        passwordStrengthBarContainer: {
            flex: 1,
            marginLeft: 10,
            marginBottom: 10,
        },
        formSubsection: {
            marginTop: mobile.contentLargerSpacing,
        },
        submitContainer: {
            width: '100%',
            marginTop: mobile.contentLargerSpacing,
        },
        submitButton: {
            width: '100%',
        },
        termsAndConditionLink: {
            color: MAGENTA,
            marginLeft: '0px !important',
        },
        houseNumber: {},
        [theme.breakpoints.up('sm')]: {
            submitButton: {
                width: 300,
            },
        },
    }),
);

export default useStyles;
