import {useAppSelector} from "../customReduxHooks";
import {selectAuthUserData} from "../../store/auth";
import {useEffect} from "react";
import {MEDIC} from "../../const/auth";
import {MEDIC_CALENDAR} from "../../const/routes";
import {useHistory} from "react-router";

const useMedicRedirect = () => {
    const authUserData = useAppSelector(selectAuthUserData);
    const history = useHistory();

    useEffect(() => {
        authUserData?.role === MEDIC && history.push(MEDIC_CALENDAR);
    }, [authUserData?.role]);
}

export default useMedicRedirect;