import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topbar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        callEndButton: {
            width: 48,
            height: 48,
            display: 'flex',
            minHeight: 'auto',
            minWidth: 'auto',
        },
    }),
);

export default useStyles;
