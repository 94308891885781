import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {desktop, mobile} from '../../../const/sizes';
import AppLogo from '../AppLogo/AppLogo';
import {Logo} from '../../../assets';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topBar: {
            padding: mobile.contentLargerSpacing,
            backgroundColor: 'white',
            alignItems: 'center',
            flexDirection: 'row',
            minHeight: 64,
            boxShadow:
                '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.04)',
        },
        cancel: {
            width: 20,
            height: 20,
            cursor: 'pointer',
        },
        title: {
            fontWeight: 'bold',
            textAlign: 'center',
        },
        rightWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.up('sm')]: {
            topBar: {
                padding: `0 ${desktop.contentLargerSpacing}`,
            },
        },
    }),
);

type EmptyTopBarProps = {
    onCancel?: () => void;
    title?: string;
};

const EmptyTopBar = ({onCancel, title}: EmptyTopBarProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.topBar}
        >
            <Box style={{flex: 1}}>
                <AppLogo />
            </Box>
            <Box style={{flex: 1}} className={classes.title}>
                {title ? title : ''}
            </Box>
            <Box style={{flex: 1}} className={classes.rightWrapper}>
                {onCancel && (
                    <Close className={classes.cancel} onClick={onCancel} />
                )}
            </Box>
        </Grid>
    );
};

export default EmptyTopBar;
