import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { LIGHT_GRAY, MAGENTA } from '../../../const/colors';

const useStyles = makeStyles((theme:Theme) =>
    createStyles({
        container:{
            paddingLeft:30,
            paddingRight:30
        },
        makeAppointment:{
            color: MAGENTA,
            fontSize:20,
            
        },
        button:{
            width:300,
            whiteSpace:'normal',
            wordWrap:'break-word',
        },
        [theme.breakpoints.down('sm')]: {
            button:{
                marginTop:16,
                width:'100%'
                
            }
        }
    }),
);

export default useStyles;
