import {makeStyles} from '@material-ui/core';
import {WHITE} from '../../../../const/colors';

const useStyles = makeStyles(() => ({
    appointmentDetailsContainer: {
        padding: '16px',
        backgroundColor: WHITE,
    },
    appointmentDetails: {
        marginTop: '16px',
        fontSize: '16px',
    },
    patientName: {
        fontWeight: 'bold',
    },
    appointmentStatesContainer: {
        marginTop: 16,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    stateLabel: {
        '&:not(:last-child)': {
            marginRight: 8,
        }
    }
}));

export default useStyles;
