import {Button} from '@material-ui/core';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {
    AppointmentConsultationType,
    AppointmentGroupEnum,
    AppointmentPaymentStatus,
    AppointmentStateEnum,
    AppointmentTypeEnum,
} from '../../../../types/appointments';
import {
    getAppointmentRoute,
    isAppointmentEndedOrCanceled,
} from '../../../../utils/appointment';
import {FIND_TIME_SLOTS} from '../../../../const/routes';
import appointmentsProvider from '../../../../services/appointmentsProvider';

interface ButtonProps {
    children: string;
    onClick?: () => void;
}

interface ComponentProps {
    appointmentType: AppointmentConsultationType;
    appointmentId: string;
    appointmentState: AppointmentStateEnum;
    appointmentPaymentStatus: AppointmentPaymentStatus;
    medicId: string;
    medicalServiceId: number;
    appointmentGroup: AppointmentGroupEnum;
    className: string;
    bookedByTheAgent:boolean;
}

const ActionOnAppointmentButton = ({
    appointmentType,
    appointmentId,
    appointmentState,
    appointmentPaymentStatus,
    medicId,
    medicalServiceId,
    appointmentGroup,
    className,
    bookedByTheAgent
}: ComponentProps) => {
    const {t: tAppointmentDetails} = useTranslation(
        i18nNamespaces.APPOINTMENT_DETAILS,
    );
    const history = useHistory();

    const postponeAppointment = () => {
        history.replace({
            pathname: FIND_TIME_SLOTS,
            search: `?appointmentId=${appointmentId}`,
        });
    };

    const startConsultation = () => {
        const route = getAppointmentRoute(appointmentType, appointmentId);
        history.push(route);
    };

    const repeatConsultation = () => {
        history.replace({
            pathname: FIND_TIME_SLOTS,
            search: `?medicalServiceId=${medicalServiceId}&medicId=${medicId}`,
        });
    };

    const payAgain = () => {
        appointmentsProvider.payAgain(parseInt(appointmentId)).then(result => {
            window.location.href = result.paymentUrl;
        });
    };

    const getButtonProps = (): ButtonProps | null => {
        if (
            appointmentState === AppointmentStateEnum.Planned &&
            appointmentPaymentStatus === 'Paid' && !!!bookedByTheAgent
        ) {
            return {
                children: tAppointmentDetails('postponeAppointment'),
                onClick: postponeAppointment,
            };
        }
        if (
            appointmentState === AppointmentStateEnum.Planned &&
            appointmentPaymentStatus !== 'Paid'
        ) {
            return {
                children: tAppointmentDetails('pay'),
                onClick: payAgain,
            };
        }
        if (
            isAppointmentEndedOrCanceled(appointmentState) &&
            appointmentGroup !==
                AppointmentGroupEnum.OccupationalMedicineAppointment && 
            !!!bookedByTheAgent
        ) {
            return {
                children: tAppointmentDetails('repeatConsultation'),
                onClick: repeatConsultation,
            };
        }
        if (appointmentType === AppointmentTypeEnum.Phone) {
            return null;
        }
        if (
            appointmentState === AppointmentStateEnum.InProgress &&
            appointmentPaymentStatus === 'Paid'
        ) {
            return {
                children: tAppointmentDetails('joinAppointment'),
                onClick: startConsultation,
            };
        }

        return null;
    };

    const buttonProps = getButtonProps();

    return buttonProps ? (
        <Button
            {...buttonProps}
            className={className}
            variant={'contained'}
            color={'secondary'}
        />
    ) : null;
};

export default ActionOnAppointmentButton;
