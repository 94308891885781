import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { useAppDispatch } from "../../../hooks/customReduxHooks";
import { isNotNullOrUndefined } from "../../../utils/lang";

import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18nNamespaces from "../../../const/i18nNamespaces";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import { useFormik } from "formik";
import * as yup from 'yup';
import { PatientPrescriptionHistoryDto } from "../../../types/prescription";
import { PatientPlaceOfWorkDto } from "../../../types/occupationalMedicine";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { DateTime } from "luxon";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { RenderCancelNextButton, RenderGridItemKeyboardDatePicker, RenderGridItemWithRequiredKeyboardDatePicker, RenderReturnNextButtons } from "../CommonView";

const REQUIRED_STRING = 'requiredField';

export type Props = {
    data: PatientPlaceOfWorkDto,
    onSubmitSection: (newPlace: PatientPlaceOfWorkDto, stayOnCurrent:boolean) =>void
    onCancel : () => void;
}
const PatientPlaceOfWorkCreateItem = ({
    data, onSubmitSection, onCancel
}:Props) =>{

    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    
    const onSubmit = () => {
        onSubmitSection(values, true);
    }
    
    const {values, touched, handleChange, handleBlur, errors, handleSubmit, setFieldValue} =
        useFormik({
            initialValues: {
                name:data?.name ?? '',
                street:data?.street ?? '',
                houseNumber:data?.houseNumber ?? '',
                apartmentNumber:data?.apartmentNumber ?? '',
                postcode:data?.postcode ?? '',
                city:data?.city ?? '',
                jobPosition:data?.jobPosition ?? '',
                dateFrom:data?.dateFrom ?? null as DateTime,
                dateTo:data?.dateTo ?? null as DateTime,
                harmfulFactors:data?.harmfulFactors ?? '',
                harmfulFactorsDateFrom:data?.harmfulFactorsDateFrom ?? null as DateTime,
                harmfulFactorsDateTo:data?.harmfulFactorsDateTo ?? null as DateTime,
            },

            validationSchema: yup.object({
                name:yup.string().required(commonT(REQUIRED_STRING)),
                street:yup.string().required(commonT(REQUIRED_STRING)),
                houseNumber:yup.string().required(commonT(REQUIRED_STRING)),
                city:yup.string().required(commonT(REQUIRED_STRING)),
                postcode:yup.string().required(commonT(REQUIRED_STRING)),
                jobPosition:yup.string().required(commonT(REQUIRED_STRING)),
                dateFrom: yup.date().nullable().required(commonT(REQUIRED_STRING)),
                dateTo: yup.date().nullable().required(commonT(REQUIRED_STRING))
            }),
            onSubmit
        });

    
    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={8}>
                            <FormGroup >
                                <Label htmlFor="employerName">{t("employerName")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    helperText={touched.name && t(errors.name)}
                                    error={touched.name && !!errors.name}
                                    />
                            </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                {t('employerAddress')}
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup >
                            <Label htmlFor="street">{t("street")} *</Label>
                            <CustomTextField 
                                fullWidth 
                                id="street"
                                name="street"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.street}
                                helperText={touched.street && t(errors.street)}
                                error={touched.street && !!errors.street}
                                />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup >
                            <Label htmlFor="houseNumber">{t("houseNumber")} *</Label>
                            <CustomTextField 
                                fullWidth 
                                id="houseNumber"
                                name="houseNumber"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.houseNumber}
                                helperText={touched.houseNumber && t(errors.houseNumber)}
                                error={touched.houseNumber && !!errors.houseNumber}
                                />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup >
                            <Label htmlFor="apartmentNumber">{t("apartmentNumber")} </Label>
                            <CustomTextField 
                                fullWidth 
                                id="apartmentNumber"
                                name="apartmentNumber"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.apartmentNumber}

                                />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup >
                            <Label htmlFor="postcode">{t("postcode")} *</Label>
                            <CustomTextField 
                                fullWidth 
                                id="postcode"
                                name="postcode"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.postcode}
                                helperText={touched.postcode && t(errors.postcode)}
                                error={touched.postcode && !!errors.postcode}
                                />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup >
                            <Label htmlFor="city">{t("city")} *</Label>
                            <CustomTextField 
                                fullWidth 
                                id="city"
                                name="city"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.city}
                                helperText={touched.city && t(errors.city)}
                                error={touched.city && !!errors.city}
                                />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={8}>
                        <FormGroup >
                            <Box className={classes.titleContainer + ' ' + classes.sectionHeader + ' '+ classes.label}>
                                {t('jobPositionOrFieldOfStudy')} *
                            </Box>
                            
                            <CustomTextField 
                                fullWidth 
                                id="jobPosition"
                                name="jobPosition"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.jobPosition}
                                helperText={touched.jobPosition && t(errors.jobPosition)}
                                error={touched.jobPosition && !!errors.jobPosition}
                                />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={4}></Grid>
                    <Grid item xs={12}>
                        <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                {t('employmentPeriod')} 
                        </Box>
                    </Grid>
                    {RenderGridItemWithRequiredKeyboardDatePicker(classes, handleBlur,"dateFrom", t('dateFrom'),
                                     values.dateFrom, setFieldValue, touched.dateFrom, 
                                     errors.dateFrom, t(errors.dateFrom as string)  )}

                    {RenderGridItemWithRequiredKeyboardDatePicker(classes, handleBlur,"dateTo", t('dateTo'),
                                     values.dateTo, setFieldValue, touched.dateTo, 
                                     errors.dateTo, t(errors.dateTo as string)  )}
        
                    <Grid item xs={8}>
                        <FormGroup >
                            <Box className={classes.titleContainer + ' ' + classes.sectionHeader + ' '+ classes.label}>
                                {t('harmfulAndBurdensomeFactors')} 
                            </Box>
                            
                            <CustomTextField 
                                fullWidth 
                                id="harmfulFactors"
                                name="harmfulFactors"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.harmfulFactors}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    {RenderGridItemKeyboardDatePicker(classes, handleBlur,"harmfulFactorsDateFrom", t('dateFrom'),
                                     values.harmfulFactorsDateFrom, setFieldValue)}
                   
                    {RenderGridItemKeyboardDatePicker(classes, handleBlur,"harmfulFactorsDateTo", t('dateTo'),
                                        values.harmfulFactorsDateTo, setFieldValue)}
                    
                </Grid>
                {RenderCancelNextButton(classes, t, onCancel)}
            </Box>
        </form>
    )
};

export default PatientPlaceOfWorkCreateItem;