import {memo} from 'react';
import {useHistory} from 'react-router';
import {Box, createStyles, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {CustomButton} from '../../../../components';
import {APPOINTMENT_CALENDAR, USER_ACCOUNT_PAGE} from "../../../../const/routes";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orderButtonWrapper: {
            marginTop: 30,
            width: '100%',
        },
        orderButton: {
            display: 'block',
            margin: '0 auto',
            [theme.breakpoints.up('md')]: {
                marginTop: 28,
            }
        }
    }),
);

const MedicalTestsOrderedNavButton = memo(function MedicalSearchFilters() {
    const classes = useStyles();
    const history = useHistory();
    const {t: tAuth} = useTranslation(i18nNamespaces.AUTH);
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const buttonClickHandler = () => {
        history.replace(`${USER_ACCOUNT_PAGE}/${APPOINTMENT_CALENDAR}`);
    }

    return (
        <Box className={classes.orderButtonWrapper}>
            <CustomButton
                className={classes.orderButton}
                fullWidth={!smUp}
                variant="contained"
                color="secondary"
                onClick={buttonClickHandler}
            >
                {tAuth('goBackToAccount')}
            </CustomButton>
        </Box>
    )
});

export default MedicalTestsOrderedNavButton;