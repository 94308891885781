import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dateWrapper: {
            width: '100%',
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
        },
        currentDate: {
            fontSize: '18px',
            fontWeight: 700,
        },
        entriesSummary: {
            fontSize: '16px',
        },
        leftMargin: {
            marginLeft: mobile.contentLargerSpacing,
        },
        calendarHeaderWrapper: {
            padding: mobile.contentLargerSpacing,
        },
        [theme.breakpoints.up('sm')]: {
            calendarHeaderWrapper: {
                padding: desktop.contentSmallerSpacing,
                backgroundColor: WHITE,
            },
            currentDate: {
                fontSize: '20px',
                fontWeight: 700,
            },
        },
    }),
);

export default useStyles;
