import {memo} from 'react';
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import {GoBackIconButton} from "../../index";
import {TEXT_PRIMARY, WHITE} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '12px 16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                padding: '24px 0',
            },
        },
        text: {
            color: TEXT_PRIMARY,
            fontWeight: 'bold',
            fontSize: 18,
            lineHeight: '24px',
        }
    }),
);

interface Props {
    text?: string,
    onClick?: () => void;
}

const GoBackBar = memo(function MedicalSearchFilters({text, onClick}: Props) {
    const classes = useStyles();
    return (
        <Box
            className={classes.root}
        >
            <GoBackIconButton onClick={onClick} />
            <Box className={classes.text}>
                {text || null}
            </Box>
        </Box>
    )
})

export default GoBackBar;