import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    useTheme,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {MedicationPackDto} from '../../../types/prescription';

interface Props {
    onChange: (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => void;
    medicationPacks: MedicationPackDto[];
    medicationPackId: number | string;
    error?: boolean;
    helperText?: React.ReactNode;
}

const MedicationPackSelect = (props: Props) => {
    const {onChange, medicationPacks, medicationPackId, error, helperText} =
        props;
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const theme = useTheme();

    return (
        <FormControl fullWidth variant="outlined" error={error}>
            <InputLabel>{t('package')}</InputLabel>
            <Select
                name="medicationPack"
                label={t('package')}
                value={medicationPackId}
                onChange={onChange}
                renderValue={value => {
                    const medicationPack = medicationPacks.find(
                        pack => pack.id === value,
                    );
                    return (
                        <Box
                            style={{
                                color: medicationPack?.isReimbursed
                                    ? theme.palette.success.main
                                    : 'initial',
                            }}
                        >
                            {medicationPack?.displayValue}
                        </Box>
                    );
                }}
                fullWidth
            >
                {medicationPacks.map(option => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        style={{
                            color: option.isReimbursed
                                ? theme.palette.success.main
                                : 'initial',
                        }}
                    >
                        {option.displayValue}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText variant="outlined" error={error}>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
};

export default MedicationPackSelect;
