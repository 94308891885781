import React, {useEffect} from 'react';
import useStyles from './IdentityConfirmationNeededPageStyles';
import {useHistory, useLocation} from 'react-router';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {CallCenterContactImage} from '../../assets';
import {useAppSelector} from '../../hooks/customReduxHooks';
import {LOGIN_PAGE} from '../../const/routes';
import {selectAuthUserData} from '../../store/auth';
import {NOT_CONFIRMED_USER} from '../../const/auth';

const IdentityConfirmationNeededPage = () => {
    const classes = useStyles();
    const location = useLocation<{afterRegistration: boolean}>();
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const authUserData = useAppSelector(selectAuthUserData);
    const history = useHistory();

    console.log('IdentityConfirmationNeededPage role', authUserData?.role);

    // useEffect(() => {
    //     if (authUserData?.role !== NOT_CONFIRMED_USER) {
    //         history.push(LOGIN_PAGE);
    //     }
    // })

    return (
        <Grid
            container
            className={classes.container}
            direction={'column'}
            alignItems={'center'}
        >
            <Box className={classes.title}>
                {location?.state?.afterRegistration
                    ? t('thankYou')
                    : t('identityApproveNeeded')}
            </Box>
            <Box className={classes.subtitle}>
                {t('employeeWillContactYou')}
            </Box>
            <img src={CallCenterContactImage} />
        </Grid>
    );
};

export default IdentityConfirmationNeededPage;
