import {ReactNode} from 'react';
import clsx from 'clsx';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    useMediaQuery,
} from '@material-ui/core';

type StyleProps = {
    disableVerticalPadding?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
    createStyles({
        containerRoot: {
            padding: 16,
        },
        containerRootDesktopView: {
            padding: '24px 47px',
            paddingTop: ({disableVerticalPadding}) =>
                disableVerticalPadding ? 0 : 24,
            paddingBottom: ({disableVerticalPadding}) =>
                disableVerticalPadding ? 0 : 24,
            paddingLeft: 47,
            paddingRight: 47,
        },
    }),
);

interface Props {
    className?: string;
    mdDesktop?: boolean;
    children: ReactNode | ReactNode[];
    disableVerticalPadding?: boolean;
}

const Container = ({
    mdDesktop,
    className,
    disableVerticalPadding,
    children,
}: Props) => {
    const classes = useStyles({disableVerticalPadding});
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const desktop = mdDesktop && mdUp;
    return (
        <Box
            className={clsx(
                classes.containerRoot,
                {
                    [classes.containerRootDesktopView]: desktop,
                },
                className,
            )}
        >
            {children}
        </Box>
    );
};

export default Container;
