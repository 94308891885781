import React, {useCallback} from 'react';
import {useHistory} from 'react-router';
import {
    Box,
    createStyles,
    Grid,
    Hidden,
    makeStyles,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {ArrowForwardIos, WorkOutline} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {fetchReferrals} from '../../../store/patientCalendar/referralsToSpecialistSlice';
import {CustomAvatar} from '../../index';
import referralsProvider from '../../../services/referralsProvider';
import CalendarLink from '../AppointmentLink/CalendarLink';
import {selectPatientOccupationalMedicineReferralById} from '../../../store/patientCalendar/referralsToOccupationalMedicineSlice';
import {DateTime} from 'luxon';
import {FIND_OCCUPATIONAL_MEDICINE_TIMESLOTS} from '../../../const/routes';

interface Props {
    referralId: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrowForward: {
            color: theme.palette.grey[300],
        },
    }),
);

const PatientOccupationalMedicineReferral = ({referralId}: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();

    const referral = useAppSelector(state =>
        selectPatientOccupationalMedicineReferralById(state, referralId),
    );

    const getFindTimeSlotsPageRoute = useCallback(
        () =>
            FIND_OCCUPATIONAL_MEDICINE_TIMESLOTS +
            `?medicalServiceId=${referral.toMedicalServiceId}&occupationalMedicineReferralId=${referral.id}`,

        [referral],
    );

    const deleteReferral = useCallback(async () => {
        await referralsProvider.deleteReferral(referralId);
        dispatch(fetchReferrals());
    }, [dispatch, referralId]);

    return (
        <Grid
            container
            wrap={'nowrap'}
            alignItems="center"
            spacing={lgUp ? 2 : 1}
        >
            <Grid item xs="auto">
                <CustomAvatar
                    alt={`Medic photo`}
                    style={{backgroundColor: 'rgb(224, 224, 224)'}}
                >
                    <WorkOutline fontSize={'large'} color={'secondary'} />
                </CustomAvatar>
            </Grid>
            <Grid item xs>
                <Box style={{fontWeight: 'bold'}}>Skierowanie</Box>
                <Box>
                    {tCommon('validUntil')}
                    {': '}
                    {DateTime.fromISO(referral.validDate).toFormat('d.MM.yyyy')}
                </Box>
            </Grid>
            <Hidden smDown>
                <Grid
                    item
                    xs="auto"
                    style={{
                        display: 'flex',
                    }}
                >
                    <CalendarLink to={getFindTimeSlotsPageRoute()}>
                        {tCommon('bookAppointment').replace(/\s/g, '\n')}
                    </CalendarLink>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid item xs="auto">
                    <ArrowForwardIos
                        className={classes.arrowForward}
                        style={{cursor: 'pointer'}}
                        onClick={() =>
                            history.replace(getFindTimeSlotsPageRoute())
                        }
                    />
                </Grid>
            </Hidden>
        </Grid>
    );
};

export default PatientOccupationalMedicineReferral;
