import {Ref, useCallback, useRef} from 'react';
import {Box, Grid, TextField, useMediaQuery, useTheme} from '@material-ui/core';
import clsx from 'clsx';
import {AttachFile} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import useStyles from './NotesInputSectionStyles';
import SaveIconButton from '../SaveIconButton/SaveIconButton';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import React from 'react';
import {
    AppointmentAdditionalInformation,
    Attachment,
} from '../../../../types/appointments';
import {UploadedItem} from '../../../index';
import {getFileIcon} from '../../../../utils/file';
import {useUserCheck} from '../../../../hooks/useUserCheck';

interface Props {
    headerText: string;
    inputValue: AppointmentAdditionalInformation;
    sectionDisabled: boolean;
    inputValueChanged: (value: AppointmentAdditionalInformation) => void;
    saveButtonEnabled: boolean;
    saveButtonLoading: boolean;
    saveButtonClicked: () => void;
    prescriptions?: Attachment[];
    prescriptionUploadHandler?: (files: File[]) => void;
    prescriptionRemoveHandler?: (prescription: string) => void;
    prescriptionDownloadHandler?: (attachmentId: string) => void;
    inputRef?: Ref<any>;
}

const NotesInputSection = ({
    headerText,
    inputValue,
    sectionDisabled,
    inputValueChanged,
    saveButtonEnabled,
    saveButtonLoading,
    saveButtonClicked,
    prescriptions,
    prescriptionUploadHandler,
    prescriptionRemoveHandler,
    prescriptionDownloadHandler,
    inputRef,
}: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isLoggedUser = useUserCheck();
    const fileInputElement = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);

    const renderAttachFileBtn = useCallback(
        () => (
            <Box
                className={clsx(classes.attachPrescriptionIconButton, {
                    [classes.disabled]: sectionDisabled,
                })}
                onClick={() =>
                    !sectionDisabled && fileInputElement.current.click()
                }
            >
                <AttachFile className={classes.clipIcon} />
                {t('attachPrescription').toUpperCase()}
            </Box>
        ),
        [sectionDisabled],
    );

    const renderSaveBtn = useCallback(
        () => (
            <SaveIconButton
                disabled={sectionDisabled || !saveButtonEnabled}
                loading={saveButtonLoading}
                onClick={saveButtonClicked}
            />
        ),
        [
            sectionDisabled,
            saveButtonEnabled,
            saveButtonLoading,
            saveButtonClicked,
        ],
    );

    return (
        <Box>
            {isMobile ? (
                <h4 className={classes.header}>{headerText}</h4>
            ) : (
                <Grid
                    container
                    wrap={'nowrap'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                >
                    <h4 className={classes.header}>{headerText}</h4>
                    <Box className={classes.actionButtonsDesktop}>
                        {prescriptionUploadHandler && renderAttachFileBtn()}
                        {renderSaveBtn()}
                    </Box>
                </Grid>
            )}
            <TextField
                className={classes.textField}
                value={inputValue || ''}
                disabled={sectionDisabled}
                variant="outlined"
                fullWidth
                multiline
                maxRows={8}
                inputRef={inputRef}
                InputProps={{
                    style: {
                        height: 'auto',
                    },
                }}
                onChange={event =>
                    inputValueChanged(event.target.value || null)
                }
            />
            {isMobile && (
                <Box
                    className={clsx(classes.actionIconButtonsMobile, {
                        [classes.actionIconButtonsMobileWithAttachment]:
                            prescriptionUploadHandler,
                    })}
                >
                    {prescriptionUploadHandler && renderAttachFileBtn()}
                    {renderSaveBtn()}
                </Box>
            )}
            {prescriptions && !!prescriptions.length && (
                <Box className={classes.prescriptions}>
                    {prescriptions.map(prescriptionsItem => (
                        <Box
                            className={classes.prescriptionsItem}
                            key={prescriptionsItem.id}
                        >
                            <UploadedItem
                                fileName={prescriptionsItem.fileName}
                                fileIcon={getFileIcon(
                                    prescriptionsItem.contentType,
                                    prescriptionsItem.thumbnail,
                                )}
                                onClick={() =>
                                    prescriptionDownloadHandler(
                                        `${prescriptionsItem.id}`,
                                    )
                                }
                                removeFileEnabled={
                                    !sectionDisabled &&
                                    isLoggedUser(prescriptionsItem.personId)
                                }
                                removeFileHandler={() =>
                                    prescriptionRemoveHandler(
                                        `${prescriptionsItem.id}`,
                                    )
                                }
                            />
                        </Box>
                    ))}
                </Box>
            )}
            {prescriptionUploadHandler && (
                <input
                    ref={fileInputElement}
                    id={'file'}
                    type="file"
                    name="file"
                    disabled={sectionDisabled}
                    hidden
                    onClick={
                        // fix for not calling onChange, when the same file was selected two times in a row
                        () => ((event.target as HTMLInputElement).value = null)
                    }
                    onChange={event =>
                        prescriptionUploadHandler(
                            event.target.files as unknown as File[],
                        )
                    }
                />
            )}
        </Box>
    );
};

export default NotesInputSection;
