import {
    Box,
    Button,
    Grid,
    IconButton,
    Snackbar,
    Tab,
    Tabs,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {TabPanel} from '../../components';
import ConversationTopBar from '../../components/conversations/topBar/ConversationTopBar';
import TopBar from '../../components/Layout/TopBar/TopBar';
import {LIGHT_DISABLED} from '../../const/colors';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    selectAppointmentDetails,
    selectConsultationStatus,
    selectServiceToken,
    serviceTokenSpecified,
} from '../../store/consultationSlice';
import {
    getAppointmentDetailsPageUrlById,
    getMedicFullName,
} from '../../utils/appointment';
import useStyles from './ChatPageStyles';
import ConversationProvider from '../../services/ConversationProvider';
import {consultationErrorHandler} from '../../utils/error';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {Alert} from '@material-ui/lab';
import AttachmentConversation from '../../components/conversations/chat/AttachmentConversation/AttachmentsConversation';
import ChatConversation from '../../components/conversations/chat/ChatConversation/ChatConversation';
import {AxiosError} from 'axios';
import useConversation from '../../hooks/useConversation';
import useChatConversation from '../../hooks/useChatConversation';
import {
    useAllPatientDetailsForMedicLoading,
    useAppointmentDetailsFetchForOngoingConsultation, useHangoutAppointmentByStatus,
} from '../../hooks/appointmentDetailsHooks';
import {MEDIC} from '../../const/auth';
import {selectAuthUserData} from '../../store/auth';
import {removeMessagesAll} from '../../store/consultationMessagesSlice';

const ChatPage = () => {
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);
    const authUserData = useAppSelector(selectAuthUserData);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const history = useHistory();
    const classes = useStyles();

    const [error, setError] = useState<string>(null);
    const [attachFileOpen, setAttachFileOpen] = useState<boolean>(false);
    const [tab, setTab] = useState(0);

    const consultationDetails = useAppSelector(selectAppointmentDetails);
    const serviceToken = useAppSelector(selectServiceToken);
    const dispatch = useAppDispatch();
    const consultationStatus = useAppSelector(selectConsultationStatus);
    const allPatientDetailsForMedicLoading =
        useAllPatientDetailsForMedicLoading();

    const {
        client,
        setConversation,
        conversation,
        loading: conversationLoading,
        setLoading,
        consultationId,
    } = useConversation();

    useChatConversation(
        client,
        setLoading,
        setError,
        consultationId,
        setConversation,
    );

    useAppointmentDetailsFetchForOngoingConsultation(
        consultationId,
        consultationDetails,
    );

    const loading =
        conversationLoading ||
        consultationStatus === 'loading' ||
        allPatientDetailsForMedicLoading;

    const isMedic = authUserData?.role === MEDIC;

    useEffect(()=>{
        if (isMedic)
            setAttachFileOpen(true);
    }, [isMedic])

    const handleStart = () => {
        ConversationProvider.getChatToken(consultationId)
            .then(res => {
                dispatch(serviceTokenSpecified(res.data));
            })
            .catch(gotApiError);
    };

    const handleAttachFile = () => {
        setAttachFileOpen(!attachFileOpen);
    };

    const handleClose = () => {
        dispatch(removeMessagesAll());
        dispatch(serviceTokenSpecified(null));
        history.push(
            getAppointmentDetailsPageUrlById(
                consultationId,
                authUserData.role === 'Medic',
            ),
        );
    };

    const gotApiError = (error: AxiosError) => {
        setError(t(consultationErrorHandler(error.response.data[0])));
    };

    const handleChangeTab = (
        event: ChangeEvent<Record<string, unknown>>,
        newTab: number,
    ) => {
        setTab(newTab);
    };

    useHangoutAppointmentByStatus(consultationId, handleClose);

    return (
        <div>
            {serviceToken ? (
                isDesktop ? (
                    <>
                        <ConversationTopBar
                            chatOpen={true}
                            handleAttachFile={handleAttachFile}
                            attachFileOpen={attachFileOpen}
                            handleHangout={handleClose}
                            chatConversation
                        />
                        <Grid container>
                            {attachFileOpen && (
                                <Box className={classes.attachmentConversation}>
                                    <AttachmentConversation
                                        uploadFileOpen={attachFileOpen}
                                        handleClose={handleAttachFile}
                                        conversation={conversation}
                                        error={error}
                                        setError={setError}
                                        loading={loading}
                                    />
                                </Box>
                            )}
                            <Box className={classes.chatConversation}>
                                <ChatConversation
                                    conversation={conversation}
                                    loading={loading}
                                    error={error}
                                    setError={setError}
                                />
                            </Box>
                        </Grid>
                    </>
                ) : (
                    <>
                        <ConversationTopBar
                            chatOpen={true}
                            handleHangout={handleClose}
                            chatConversation
                        />
                        {/*<DetailsHeaderMobile />*/}
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            className={classes.tabs}
                            aria-label="tabs"
                            variant="fullWidth"
                        >
                            <Tab label={t('chat')} />
                            <Tab
                                label={
                                    authUserData.role === MEDIC
                                        ? t('notesAndFiles')
                                        : t('files')
                                }
                            />
                        </Tabs>
                        <TabPanel value={tab} index={0}>
                            <ChatConversation
                                conversation={conversation}
                                loading={loading}
                                error={error}
                                setError={setError}
                            />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <AttachmentConversation
                                mobileView
                                uploadFileOpen
                                handleClose={handleAttachFile}
                                conversation={conversation}
                                error={error}
                                setError={setError}
                                loading={loading}
                            />
                        </TabPanel>
                    </>
                )
            ) : (
                <>
                    <TopBar>
                        {!isDesktop && (
                            <IconButton onClick={() => history.goBack()}>
                                <Close />
                            </IconButton>
                        )}
                    </TopBar>

                    <Box className={classes.settingsContainer}>
                        {!isDesktop && consultationDetails && (
                            <Box className={classes.participantName}>
                                <Box fontWeight="bold">
                                    {getMedicFullName(
                                        consultationDetails.medic,
                                    )}
                                </Box>
                                <Box color={LIGHT_DISABLED}>
                                    {'  '} - {t('hours')}.
                                    {consultationDetails.startDate.toFormat(
                                        'HH:mm',
                                    )}
                                </Box>
                            </Box>
                        )}
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{width: 300}}
                            onClick={handleStart}
                        >
                            {t('joinAppointment')}
                        </Button>
                    </Box>
                </>
            )}
            <Snackbar
                open={!!error}
                autoHideDuration={5000}
                onClose={() => setError('')}
            >
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ChatPage;
