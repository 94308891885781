import {useTranslation} from 'react-i18next';
import {PaymentContinuePage} from '../../components';
import i18nNamespaces from '../../const/i18nNamespaces';
import useQuery from '../../hooks/useQuery';
import {useHistory} from 'react-router';
import { useEffect, useState } from 'react';
import subscriptionsProvider from '../../services/subscriptionsProvider';
import { useParams } from 'react-router';

const REFRESH_TIME = 500;
const LIMIT = 10;

const SubscriptionPaymentContinuePage = () => {
    const query = useQuery();
    const error = query.get('error');
    const history = useHistory();

    const {subscriptionId} = useParams<{subscriptionId: string}>();
    const {t} = useTranslation(i18nNamespaces.SUBSCRIPTION_PAYMENT_CONTINUE);

    const [waitForTheResult, setWaitForTheResult] = useState<boolean>(true);
    const [paymentStatus, setPaymentStatus] = useState<string>(null);

    useEffect(() => {
        let x = 0;
        const intervalId = setInterval(async ()=>{
            const {data} = await subscriptionsProvider.getPaymentStatus(subscriptionId)
            setPaymentStatus(data);

            if(data==="Paid")
            {
                clearInterval(intervalId);
                setWaitForTheResult(false);
            }

            else if(++x === LIMIT){
                clearInterval(intervalId);
                setWaitForTheResult(false);
            }
        }, REFRESH_TIME);

        return () => {
            clearInterval(intervalId);
        };
    }, []);



    const paymentTitle =
        error ? t('paymentFailed') :
        (
            paymentStatus!=='Paid' ?
            (waitForTheResult ?  t('waitingForPayment') : t('waitingForPaymentTimeoutInfo'))
            :  t('subscriptionStarted')
        );

    const information =
        error ? t('paymentFailedDescription') : paymentStatus!=='Paid' ? `${t('checkStatus')}` : t('thankYou')
    return (
        <PaymentContinuePage
            title={paymentTitle}
            information={information}
            isSuccess={!error}
            actionText={t('goBackToPatientAccount')}
            onAction={() => history.push('/account')}
            isLoading={waitForTheResult}
        />
    );
};

export default SubscriptionPaymentContinuePage;
