import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth';
import dayScheduleTemplatesReducer from './dayScheduleTemplatesSlice';
import timeSlotsReducer from './timeSlotsSlice';
import medicCalendarReducer from './medicCalendarSlice';
import finishedAppointmentsReducer from './patientCalendar/finishedAppointmentsSlice';
import upcomingAppointmentsReducer from './patientCalendar/upcomingAppointmentsSlice';
import patientReferralsToSpecialistStore from './patientCalendar/referralsToSpecialistSlice';
import messagesReducer from './consultationMessagesSlice';
import consultationSlice from './consultationSlice';
import timeSlotsSearchSlice from './timeSlotsSearchSlice';
import individualPackagesReducer from './individualPackagesSlice';
import appointmentDetailsReducer from './appointmentDetailsSlice';
import appointmentAttachmentsReducer from './appointmentAttachmentsSlice';
import userAppointmentsHistoryReducer from './userAppointmentsHistorySlice';
import questionnaireReducer from './questionnaireSlice';
import questionnaireDetailsReducer from './questionnaireDetailsSlice';
import medicProfileReducer from './medicProfileSlice';
import makePatientReferralStore from './referrals/makePatientReferralSlice';
import referralForMedicalTestsStore from './referrals/medicalTestsSlice';
import diagnosticServiceReducer from './referrals/diagnosticServicesSlice';
import articlesReducer from './articleSlice';
import orderedDiagnosticOrderHeadersReducer from './referrals/orderedDiagnosticOrderHeaderSlice';
import childSelectionReducer from './childSelectionSlice';
import appointmentReferralsReducer from './referrals/appointmentReferralSlice';
import prescriptionSlice from './prescriptionSlice';
import prescriptionHistorySlice from './prescriptionHistorySlice';
import orderedDiagnosticOrderHeadersWithResultsSlice from './referrals/orderedDiagnosticOrderHeaderWithResultsSlice';
import patientPersonalDataStatusSlice from './patientCalendar/patientPersonalDataStatusSlice';
import sickLeaveSlice from './sickLeaveSlice';

import occupationalMedicineSlice from './occupationalMedicineSlice';
import referralsToOccupationalMedicineSlice from './patientCalendar/referralsToOccupationalMedicineSlice';
const store = configureStore({
    reducer: {
        auth: authReducer,
        availabilityTemplate: dayScheduleTemplatesReducer,
        timeSlotsSearch: timeSlotsSearchSlice,
        timeSlots: timeSlotsReducer,
        medicCalendar: medicCalendarReducer,
        finishedAppointments: finishedAppointmentsReducer,
        upcomingAppointments: upcomingAppointmentsReducer,
        messages: messagesReducer,
        consultation: consultationSlice,
        individualPackages: individualPackagesReducer,
        appointmentDetails: appointmentDetailsReducer,
        userAppointmentsHistory: userAppointmentsHistoryReducer,
        appointmentAttachments: appointmentAttachmentsReducer,
        questionnaire: questionnaireReducer,
        questionnaireDetails: questionnaireDetailsReducer,
        medicProfile: medicProfileReducer,
        makePatientReferral: makePatientReferralStore,
        patientReferralsToSpecialist: patientReferralsToSpecialistStore,
        referralForMedicalTests: referralForMedicalTestsStore,
        diagnosticServices: diagnosticServiceReducer,
        articles: articlesReducer,
        orderedDiagnosticOrderHeaders: orderedDiagnosticOrderHeadersReducer,
        childSelection: childSelectionReducer,
        appointmentReferrals: appointmentReferralsReducer,
        prescription: prescriptionSlice,
        patientPrescriptionHistory: prescriptionHistorySlice,
        orderedDiagnosticOrderHeadersWithResults:
            orderedDiagnosticOrderHeadersWithResultsSlice,
        patientPersonalDataStatus: patientPersonalDataStatusSlice,
        sickeLeave: sickLeaveSlice,
        occupationalMedicine: occupationalMedicineSlice,
        referralsToOccupationalMedicine: referralsToOccupationalMedicineSlice,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({serializableCheck: false}),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
