import {
    Avatar,
    Badge,
    Box,
    Card,
    Grid,
    Hidden,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import {ArrowForwardIos} from '@material-ui/icons';
import useStyles from './AppointmentItemStyles';
import {
    Appointment,
    AppointmentGroupEnum,
    AppointmentStateEnum,
} from '../../../types/appointments';
import {useHistory} from 'react-router';
import {PATIENT_APPOINTMENT_DETAILS_PAGE} from '../../../const/routes';
import {
    AppointmentPaymentLabel,
    PatientToMedicWithSpeciality,
} from '../../index';
import AppointmentStateLabel from '../../common/Appointment/AppointmentStateLabel/AppointmentStateLabel';
import {LARGE_DESKTOP_BREAKPOINT} from '../../../const/sizes';
import {useCallback, useMemo} from 'react';
import CalendarLink from '../AppointmentLink/CalendarLink';
import AppointmentTimeDetails from '../../common/Appointment/AppointmentTimeDetails/AppointmentTimeDetails';
import {getLanguage, getTranslation} from '../../../utils/translation';
import i18n from '../../../i18n/config';

type PropLink = {
    label: string;
    URL?: string;
    onClick?: () => void;
    id: string;
    display: boolean;
};

type AppointmentItemProps = {
    appointment: Appointment;
    links?: PropLink[];
    canMoveToDetails?: boolean;
};

const AppointmentItem = ({
    appointment,
    links,
    canMoveToDetails,
}: AppointmentItemProps) => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(LARGE_DESKTOP_BREAKPOINT);

    const anyLinks = links.some(l => l.display);

    const startDateTime = appointment.startDate;
    const appointmentState = appointment.state.state;

    const isNow = appointmentState === AppointmentStateEnum.InProgress;
    const isInTheFuture = appointmentState === AppointmentStateEnum.Planned;

    const occupationalMedicineDocumentationRequired =
        appointment.appointmentGroup ===
            AppointmentGroupEnum.OccupationalMedicineAppointment &&
        !appointment.isOccupationalMedicineDocumentationCompleted &&
        appointmentState === AppointmentStateEnum.Planned;

    const appointmentClickHandler = useCallback(() => {
        history.push(`${PATIENT_APPOINTMENT_DETAILS_PAGE}/${appointment.id}`);
    }, [appointment.id]);

    const currentLanguage = getLanguage(i18n?.language);

    const renderAppointmentStatuses = () => (
        <Box className={classes.statusesContainer}>
            <AppointmentStateLabel
                className={classes.appointmentState}
                state={appointmentState}
            />
            {appointment.paymentStatus !== 'Paid' && (
                <AppointmentPaymentLabel
                    paymentStatus={appointment.paymentStatus}
                />
            )}
        </Box>
    );

    const renderLinks = () => (
        <Grid
            container
            direction="column"
            alignItems="flex-end"
            style={{
                cursor: 'pointer',
            }}
        >
            {links.map(
                link =>
                    link.display && (
                        <Grid item xs="auto" key={link.id}>
                            <Box style={{padding: '4px 0'}}>
                                {link.URL ? (
                                    <CalendarLink to={link.URL}>
                                        {link.label}
                                    </CalendarLink>
                                ) : link.onClick ? (
                                    <CalendarLink onClick={link.onClick}>
                                        {link.label}
                                    </CalendarLink>
                                ) : null}
                            </Box>
                        </Grid>
                    ),
            )}
        </Grid>
    );

    return (
        <Card
            style={{
                padding: smUp ? '24px 0' : 16,
                borderBottom: `1px solid ${theme.palette.grey['300']}`,
                boxShadow: 'none',
            }}
        >
            <Grid
                container
                wrap={smUp ? 'nowrap' : 'wrap'}
                alignItems="center"
                onClick={!mdUp ? appointmentClickHandler : null}
                spacing={lgUp ? 2 : 1}
                style={{
                    cursor: !mdUp ? 'pointer' : 'default',
                }}
            >
                <Grid item xs={12} sm="auto">
                    <AppointmentTimeDetails
                        date={startDateTime}
                        isNow={isNow}
                        isInTheFuture={isInTheFuture}
                        square={smUp}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Grid
                        container
                        wrap={'nowrap'}
                        alignItems="center"
                        spacing={lgUp ? 2 : 1}
                    >
                        <Grid item xs="auto">
                            <Badge
                                color={'error'}
                                badgeContent={
                                    occupationalMedicineDocumentationRequired
                                        ? '!'
                                        : null
                                }
                                overlap="circular"
                            >
                                <Avatar
                                    alt={appointment.medic.surname}
                                    src={appointment.medic.photoUrl}
                                    className={classes.appointmentPhoto}
                                />
                            </Badge>
                        </Grid>
                        <Grid item xs>
                            <PatientToMedicWithSpeciality
                                medicTitle={appointment.medic.title}
                                medicFirstName={appointment.medic.firstName}
                                medicSurname={appointment.medic.surname}
                                medicalServiceName={getTranslation(
                                    appointment.medicalService.name,
                                    appointment.medicalService.translations,
                                    currentLanguage,
                                    'name',
                                )}
                                patientFirstName={
                                    appointment.child !== null
                                        ? appointment.child.firstName
                                        : appointment.patient.firstName
                                }
                                patientSurname={
                                    appointment.child !== null
                                        ? appointment.child.surname
                                        : appointment.patient.surname
                                }
                            />
                        </Grid>
                        {lgUp && (
                            <Grid item lg="auto">
                                {renderAppointmentStatuses()}
                            </Grid>
                        )}
                        {anyLinks && (
                            <Hidden smDown>
                                <Grid item xs="auto">
                                    {renderLinks()}
                                </Grid>
                            </Hidden>
                        )}
                        <Grid item xs="auto">
                            {canMoveToDetails && (
                                <ArrowForwardIos
                                    className={classes.arrowIcon}
                                    onClick={
                                        mdUp ? appointmentClickHandler : null
                                    }
                                    style={{
                                        color: isNow
                                            ? theme.palette.secondary.main
                                            : theme.palette.grey[300],
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!lgUp && renderAppointmentStatuses()}
        </Card>
    );
};

export default AppointmentItem;
