import {
    Box,
    Button,
    Grid,
    LinearProgress,
    List,
    ListItem,
} from '@material-ui/core';
import {Conversation} from '@twilio/conversations/lib/conversation';
import {Paginator} from '@twilio/conversations/lib/interfaces/paginator';
import {Message} from '@twilio/conversations/lib/message';
import {
    KeyboardEvent,
    UIEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {Participant} from 'twilio-video';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';
import {
    addMessage,
    removeMessage,
    addMessages,
    addPreviosMessages,
    selectStoreMessages,
    consultationStatusSpecified,
    selectMessagesStatus,
    removeMessagesAll,
} from '../../../../store/consultationMessagesSlice';
import {selectAuthUserData} from '../../../../store/auth';
import useStyles from './ChatStyles';
import {Send} from '@material-ui/icons';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {CHAT_SCROLL_THRESHOLD} from '../../../../const/conversation';
import {isEmpty} from 'lodash-es';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

interface Props {
    conversation: Conversation;
}

const Chat = ({conversation}: Props) => {
    const [typingMessage, setTypingMessage] = useState<string>('');
    const [messagePaginator, setMessagePaginator] =
        useState<Paginator<Message>>(null);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);
    const scrollRef = useRef(null);
    const consultationStatus = useAppSelector(selectMessagesStatus);

    const messages = useAppSelector(selectStoreMessages);
    const authUserData = useAppSelector(selectAuthUserData);

    const isUserTheSender = (author: string) => {
        return authUserData ? author === authUserData.userId : false;
    };

    const scrollDown = useCallback(() => {
        scrollRef.current.scrollIntoView({behaviour: 'smooth'});
    }, [scrollRef]);

    //item.author - user id from DB
    //item.dateCreated - creation date : Date
    const messagesList = messages.map(item => (
        <ListItem
            key={item.sid}
            className={clsx([
                classes.messageWrapper,
                {
                    [classes.authorMessageWrapper]: isUserTheSender(
                        item.author,
                    ),
                },
            ])}
        >
            <Box
                className={clsx(classes.message, {
                    [classes.authorMessage]: isUserTheSender(item.author),
                })}
            >
                {item.body}
            </Box>
        </ListItem>
    ));

    const loadMoreMessages = () => {
        if (messagePaginator?.hasPrevPage) {
            dispatch(consultationStatusSpecified('loading'));
            messagePaginator
                .prevPage()
                .then((paginator: Paginator<Message>) => {
                    dispatch(addPreviosMessages({messages: paginator.items}));
                    setMessagePaginator(paginator);
                    dispatch(consultationStatusSpecified('loaded'));
                })
                .catch(error => {
                    gotError(error);
                    dispatch(consultationStatusSpecified('failed'));
                });
        }
    };

    const loadMessages = () => {
        dispatch(consultationStatusSpecified('loading'));
        conversation
            .getMessages()
            .then((paginator: Paginator<Message>) => {
                dispatch(addMessages({messages: paginator.items}));
                setMessagePaginator(paginator);
                dispatch(consultationStatusSpecified('loaded'));
                scrollDown();
            })
            .catch(error => {
                gotError(error);
                dispatch(consultationStatusSpecified('failed'));
            });
    };

    const gotError = (error: Error) => {
        console.error(error);
    };

    useEffect(() => {
        if (conversation) {
            if (isEmpty(messages)) loadMessages(); //initial loading

            conversation.on('messageAdded', (message: Message) => {
                dispatch(addMessage({message: message}));
                scrollDown();
            });

            conversation.on('messageRemoved', (message: Message) => {
                dispatch(removeMessage({message: message}));
            });
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            conversation.on('typingStarted', (participant: Participant) => {
                //Fired when a Participant's fields has been updated.
            });
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            conversation.on('typingEnded', (participant: Participant) => {
                //Fired when a Participant has stopped typing.
            });
            return () => {
                dispatch(removeMessagesAll());
                conversation.removeAllListeners();
            };
        }
    }, [conversation]);

    const updateMessage = (event: ChangeEvent) => {
        setTypingMessage(event.target.value);
        conversation.typing();
    };

    const onPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const sendMessage = useCallback(() => {
        conversation.sendMessage(typingMessage).then(scrollDown);
        setTypingMessage('');
    }, [typingMessage]);

    const onListScroll = (e: UIEvent<HTMLElement>) => {
        e.stopPropagation();
        if (
            e.currentTarget.scrollTop < CHAT_SCROLL_THRESHOLD &&
            consultationStatus !== 'loading'
        ) {
            loadMoreMessages();
        }
    };

    return (
        <Box className={classes.container}>
            {consultationStatus === 'loading' && (
                <Box style={{width: '100%'}}>
                    <LinearProgress color="secondary" />
                </Box>
            )}
            <List className={classes.messagesList} onScroll={onListScroll}>
                {messagesList}
                <div ref={scrollRef} style={{height: 0}} />
            </List>
            <Box className={classes.inputRow}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <input
                        className={classes.input}
                        value={typingMessage}
                        onChange={updateMessage}
                        placeholder={t('writeAMessage')}
                        onKeyPress={onPressEnter}
                    />
                    <Button
                        onClick={sendMessage}
                        className={classes.sendButton}
                        size="small"
                    >
                        <Send style={{color: 'white'}} />
                    </Button>
                </Grid>
            </Box>
        </Box>
    );
};

export default Chat;
