import {
    Box,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Theme,
} from '@material-ui/core';
import {ReactNode} from 'react';
import {mobile} from '../../../../const/sizes';
import {formatPrice} from '../../../../utils/price';

type AppointmentAnonymousPurchaseOptionProps = {
    children: ReactNode;
    price: number;
};

const AppointmentAnonymousPurchaseOption = ({
    children,
    price,
}: AppointmentAnonymousPurchaseOptionProps) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            container: {
                marginTop: mobile.contentSmallerSpacing,
            },
            optionWrapper: {
                paddingTop: mobile.contentSmallerSpacing,
            },
            price: {
                fontSize: 20,
                fontWeight: 'bold',
                color: theme.palette.secondary.main,
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Divider />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.optionWrapper}
            >
                <Box>{children}</Box>
                <Box className={classes.price}>{formatPrice(price)} zł</Box>
            </Grid>
        </Box>
    );
};

export default AppointmentAnonymousPurchaseOption;
