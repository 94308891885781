import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            boxShadow:
                '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.04)',
        },
        content: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'black',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        logo: {
            [theme.breakpoints.down('sm')]: {
                width: 157,
            },
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'white',
        },
        displayName: {
            marginLeft: 32,
            color: theme.palette.text.secondary,
        },
    }),
);

export default useStyles;
