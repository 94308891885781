import axios from 'axios';
import { useEffect, useState } from 'react';
import {useHistory} from 'react-router';
import {Loading, MedicCalendarAddTimeSlots} from '../../components';
import {MEDIC_CALENDAR} from '../../const/routes';
import { useAppSelector } from '../../hooks/customReduxHooks';
import configurationProvider from '../../services/configurationProvider';
import { selectServiceDuration } from '../../store/dayScheduleTemplatesSlice';

const MedicCalendarAddTimeSlotsPage = () => {
    const [serviceDuration, setServiceDuration] = useState<number>(null);
    const [loading, setLoading] = useState<boolean>(false);
    
    const history = useHistory();

    const timeSlotsAddedHandler = () => {
        history.push(MEDIC_CALENDAR);
    };

    const fetchMedicalServiceDefaultDurationData = async () => {
        try{
            setLoading(true);
            const result = await configurationProvider.getMedicalServiceDefaultDuration();
            setServiceDuration(result.data);
        }
        catch(error){
            if (axios.isAxiosError(error) &&
                error.response.status === 404)
                return;
            console.error(error);
        }
        finally{
            setLoading(false);
        }
    };

    const defaultServiceDuration = useAppSelector(selectServiceDuration);

    useEffect(()=>{
        setServiceDuration(defaultServiceDuration);
        fetchMedicalServiceDefaultDurationData();
    }, []);
    
    return (
        <>
            <Loading loading={loading} withBackdrop/>
            
            {serviceDuration && <MedicCalendarAddTimeSlots
                onTimeSlotsAdded={timeSlotsAddedHandler}
                serviceDuration={serviceDuration}
            ></MedicCalendarAddTimeSlots>}
        </>
    );
};

export default MedicCalendarAddTimeSlotsPage;
