import {SUBSCRIPTION_PAYMENT_HISTORY} from './endpoints';
import {http} from './http';
import {SubscriptionTransactionDto} from '../types/subscriptionTransaction';

class subscriptionPaymentHistoryProvider {
    async getSubscriptionPaymentHistory(id: number) {
        return (
            await http.get<SubscriptionTransactionDto[]>(
                SUBSCRIPTION_PAYMENT_HISTORY(id),
            )
        ).data;
    }
}
export default new subscriptionPaymentHistoryProvider();
