import {createStyles, makeStyles} from '@material-ui/core';
import {desktop} from '../../../const/sizes';

const useStyles = makeStyles(() =>
    createStyles({
        pagingWrapper: {
            padding: `${desktop.contentSmallerSpacing}`,
        },
    }),
);

export default useStyles;
