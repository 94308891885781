import {DateTime} from 'luxon';
import clsx from 'clsx';
import {Box, Grid, useTheme} from "@material-ui/core";
import useStyles from './DateItemStyles';
import { getLanguage } from '../../../utils/translation';
import i18n from '../../../i18n/config';
import { TEXT_PRIMARY, WHITE } from '../../../const/colors';
import { getRelativeDay } from '../../../utils/date';


interface Props {
    isNow: boolean;
    isInTheFuture: boolean;
    date: DateTime;
    square?: boolean;
}

const DateItem = ({isNow, isInTheFuture, date, square}: Props) => {
    const classes = useStyles();
    const theme = useTheme();

    const language = getLanguage(i18n?.language);
    
    return (
        <Grid
            className={
                clsx({
                    [classes.container]: !square,
                    [classes.containerSquare]: square,
                })
            }
            style={{
                backgroundColor: isNow
                    ? theme.palette.secondary.main
                    : theme.palette.grey[300],
            }}
        >
            <Box
                style={{
                    color: isNow ? WHITE : TEXT_PRIMARY,
                }}
            >
                {getRelativeDay(date, 'dd-MM-yyyy')}
            </Box>
        </Grid>
    );
}

export default DateItem;