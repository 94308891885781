import {
    makeStyles,
    createStyles,
    Theme,
    useMediaQuery,
    OutlinedInput,
    InputAdornment,
    OutlinedInputProps,
    IconButton,
    FormHelperText,
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import clsx from 'clsx';
import {ReactNode, useState} from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            height: 48,
            backgroundColor: 'white',
        },
    }),
);

const CustomPasswordField = ({
    className,
    helperText,
    error,
    fullWidth,
    ...props
}: OutlinedInputProps & {helperText?: ReactNode}) => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <OutlinedInput
                className={clsx([classes.container, className])}
                type={showPassword ? 'text' : 'password'}
                error={error}
                {...props}
                fullWidth={fullWidth || !isDesktop}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText
                variant="outlined"
                error={error}
                style={{width: isDesktop ? '272px' : '100%'}}
            >
                {helperText}
            </FormHelperText>
        </>
    );
};

export default CustomPasswordField;
