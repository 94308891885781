import {memo} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Box, Grid} from "@material-ui/core";
import {Button, CustomModal} from "../../index";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";

const useStyles = makeStyles(() => ({
    modalContentContainer: {
        maxWidth: 360,
    },
    modalHeaderText: {
        fontSize: 20,
        textAlign: 'center',
        lineHeight: '24px',
        fontWeight: 'bold',
    },
    modalText: {
        marginTop: 16,
        textAlign: 'center',
        fontSize: 16,
        lineHeight: '24px',
    },
    modalButton: {
        marginTop: 16,
    },
}));

interface Props {
    open: boolean;
    primaryText?: string;
    secondaryText?: string;
    confirmButtonText?: string;
    onConfirm: () => void;
    rejectButtonText?: string;
    onReject: () => void;
}

const ConfirmOrRejectModal = memo(function MedicalSearchFilters({
    open,
    primaryText,
    secondaryText,
    confirmButtonText,
    onConfirm,
    rejectButtonText,
    onReject
}: Props) {
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    return (
        <CustomModal open={open}>
            <Box className={classes.modalContentContainer}>
                <Grid
                    container
                    direction={'column'}
                    wrap={'nowrap'}
                >
                    <Box className={classes.modalHeaderText}>
                        {primaryText}
                    </Box>
                    <Box className={classes.modalText}>
                        {secondaryText}
                    </Box>
                    <Button
                        className={classes.modalButton}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={onConfirm}
                    >
                        {(confirmButtonText || tCommon('continue')).toUpperCase()}
                    </Button>
                    <Button
                        variant={'outlined'}
                        className={classes.modalButton}
                        onClick={onReject}
                    >
                        {(rejectButtonText || tCommon('cancel')).toUpperCase()}
                    </Button>
                </Grid>
            </Box>
        </CustomModal>
    )
})

export default ConfirmOrRejectModal;