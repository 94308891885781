import useStyles from './FileUploadDesktopStyles';
import {Box, List, ListItem} from '@material-ui/core';
import React, {FC} from 'react';
import {UploadedItem} from '../../index';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {Close} from '@material-ui/icons';
import {UploadedFileComponentProps} from '../../../types/fileUpload';
import useOnDropRejectMessage from '../../../hooks/useOnDropRejectMessage';
import {CustomDropzone} from '../../common/inputs';

interface Props {
    filesProps: UploadedFileComponentProps[];
    fileUploadHandler: (files: File[]) => void;
    showHeader: boolean;
    disabled?: boolean;
    handleClose?: () => void;
}

const FileUploadDesktop: FC<Props> = props => {
    const classes = useStyles();
    const {t: tConversations} = useTranslation(i18nNamespaces.CONVERSATIONS);
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);

    const {disabled, filesProps, fileUploadHandler, handleClose, showHeader} =
        props;

    const handleOnDropRejectMessage = useOnDropRejectMessage();

    return (
        <Box>
            {showHeader && (
                <Box className={classes.attachmentTopBar}>
                    {tConversations('files')}
                    <Close
                        className={classes.closeIconButton}
                        onClick={handleClose}
                    />
                </Box>
            )}
            <Box className={classes.fileUploadContainer}>
                <List className={classes.fileList}>
                    {filesProps.map((fileProps, i) => (
                        <ListItem
                            className={classes.fileListItem}
                            key={fileProps.fileItemKey ?? i}
                            onClick={fileProps.fileItemClickHandler}
                        >
                            <UploadedItem
                                fileName={fileProps.fileName}
                                fileIcon={fileProps.fileIcon}
                                fileOwner={fileProps.fileOwner}
                                fileDateCreated={fileProps.fileDateCreated}
                                fileTimeCreated={fileProps.fileTimeCreated}
                                removeFileEnabled={fileProps.removeFileEnabled}
                                removeFileHandler={fileProps.removeFileHandler}
                            />
                        </ListItem>
                    ))}
                </List>
                <CustomDropzone
                    disabled={disabled}
                    text={tCommon('dragDropAttachment')}
                    getDropRejectMessage={handleOnDropRejectMessage}
                    onAdd={fileUploadHandler}
                />
            </Box>
        </Box>
    );
};

export default FileUploadDesktop;
