import {createStyles, makeStyles} from '@material-ui/core';
import {GRAY, RED} from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            backgroundColor: 'white',
            padding: '16px 0',
            marginBottom: 24,
        },
        list: {
            paddingLeft: 0,
        },
        horizontalPadding: {
            paddingLeft: 16,
            paddingRight: 16,
        },
        historyTitle: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 24,
        },

        dateLabel: {
            color: GRAY,
        },
        errorLabel: {
            color: RED,
        },
    }),
);

export default useStyles;
