import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {LIGHT_GRAY} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            paddingTop: 16,
            paddingBottom: 16,
            marginBottom: 24,
        },
        horizontalPadding: {
            paddingLeft: 16,
            paddingRight: 16,
        },
        horizontalLine: {
            width: '100%',
            height: 1,
            backgroundColor: LIGHT_GRAY,
            marginTop: 20,
        },
        packageName: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 24,
        },
        editCardDataButton: {
            marginBottom: 16,
        },
        remainingServiceLimit: {
            marginBottom: 8,
        },
        optionButton: {
            marginTop: 8,
        },
        [theme.breakpoints.up('sm')]: {
            cancelSubscriptionButton: {
                marginLeft: 68,
            },
            optionButton: {
                marginLeft: 18,
                marginTop: 0,
            },
        },
    }),
);

export default useStyles;
