import {Box, Button, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {FormEvent, ReactNode, useEffect, useState} from 'react';
import {BuyerDataForm, Loading} from '../..';
import {WomanWithPhone} from '../../../assets';
import {BuyerDataFormConfig} from '../../../hooks/useBuyerDataFrom';
import EmptyTopBar from '../../Layout/EmptyTopBar/EmptyTopBar';
import Footer from '../../Layout/Footer/Footer';
import PaymentSubmit from '../PaymentSubmit/PaymentSubmit';
import useStyles from './PaymentPageStyles';
import ResellerCode from '../../subscriptionPayment/ResellerCode/ResellerCode';
import {ResellerCodeValidationDto} from '../../../types/subscriptions';
import {GRAY, MAGENTA} from '../../../const/colors';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

type PaymentPageProps = {
    additionalPaymentInfo?: string;
    additionalSection?: ReactNode;
    buyerDataFormConfig: BuyerDataFormConfig;
    handleSubmit: (e?: FormEvent<HTMLFormElement>) => void;
    isLoading: boolean;
    onCancel: () => void;
    paymentInfo: string;
    priceSection: ReactNode;
    submitText: string;
    title: string;
    isResellerCodeRequired?: boolean;
    resellerData?: ResellerCodeValidationDto;
    onUseResellerCode?: (code: string) => void;
    resellerCode?: string;
    resellerCodeError?: string;
};

const PaymentPage = ({
    additionalPaymentInfo,
    additionalSection,
    buyerDataFormConfig,
    handleSubmit,
    isLoading,
    onCancel,
    paymentInfo,
    priceSection,
    submitText,
    title,
    isResellerCodeRequired,
    resellerData,
    onUseResellerCode,
    resellerCode,
    resellerCodeError,
}: PaymentPageProps) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.DISCOUNT_CODE);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const resellerDetails = `(${resellerData?.name}, ${resellerData?.contactEmail}, ${resellerData?.contactPhoneNumber})`;

    const [showPayment, setShowPayment] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShowPayment(false);
    //     }, 1000);
    // }, []);

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={classes.page}
        >
            <Loading withBackdrop={true} loading={isLoading} />

            <EmptyTopBar onCancel={onCancel} />

            <Box className={classes.pageContent}>
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        className={classes.paymentSection}
                    >
                        <Box>
                            <Box className={classes.paymentTitleContainer}>
                                <Box className={classes.paymentTitle}>
                                    {title}
                                </Box>
                            </Box>
                            <Box>{paymentInfo}</Box>
                            {additionalPaymentInfo && (
                                <Box>{additionalPaymentInfo}</Box>
                            )}
                        </Box>
                        {isDesktop && (
                            <Box>
                                <img
                                    src={WomanWithPhone}
                                    alt="Payment image"
                                    className={classes.paymentImage}
                                />
                            </Box>
                        )}
                    </Grid>
                    {priceSection}
                    {isResellerCodeRequired && (
                        <Box className={classes.resellerCodeSection}>
                            {resellerData ? (
                                <Box display={'inline'}>
                                    <Box
                                        className={
                                            classes.resellerCodeSectionTitle
                                        }
                                    >
                                        Kod partnera:{' '}
                                        <Box
                                            style={{color: MAGENTA}}
                                            component={'span'}
                                        >
                                            {resellerCode}
                                        </Box>
                                        <Box
                                            component={'span'}
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 'normal',
                                                color: GRAY,
                                                marginLeft: 40,
                                            }}
                                        >
                                            {resellerDetails}
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <>
                                    <Box
                                        className={
                                            classes.resellerCodeSectionTitle
                                        }
                                    >
                                        {t('typeCodeFromPartner')}
                                    </Box>
                                    <ResellerCode
                                        onUseResellerCode={onUseResellerCode}
                                        loading={false}
                                        errorText={resellerCodeError}
                                        style={{paddingTop: 16}}
                                    />
                                </>
                            )}
                        </Box>
                    )}

                    {showPayment && (resellerData || !isResellerCodeRequired) && (
                        <>
                            <BuyerDataForm
                                buyerDataFormConfig={buyerDataFormConfig}
                                className={classes.buyerDataForm}
                            />
                            {additionalSection}
                            <PaymentSubmit submitText={submitText} />
                        </>
                    )}
                </form>
            </Box>

            <Footer />
        </Grid>
    );
};

export default PaymentPage;
