import {memo} from 'react';
import {createStyles, List, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import clsx from 'clsx';
import {LIGHT_GRAY} from '../../../../const/colors';
import {isNotNullOrUndefined} from '../../../../utils/lang';

interface Props {
    smDownTopOffset?: number;
    mdDownTopOffset?: number;
    disableFirstItemTopDivider?: boolean;
    disableLastItemBottomDivider?: boolean;
    children: JSX.Element[];
}

const useStyles = makeStyles(() =>
    createStyles({
        firstItemTopDividerDisabled: {
            '&>li:first-child': {
                borderTop: 0,
            }
        },
        lastItemBottomDividerDisabled: {
            '&>li:last-child': {
                borderBottom: 0,
            }
        }
    }),
);

const CardList = memo(function CardList({
    smDownTopOffset,
    mdDownTopOffset,
    disableFirstItemTopDivider,
    disableLastItemBottomDivider,
    children
}: Props) {
    const classes = useStyles();
    const theme = useTheme();

    const applySmDownTopOffset = !useMediaQuery(theme.breakpoints.up('sm'))
        && isNotNullOrUndefined(smDownTopOffset);
    const applyMdDownTopOffset = !useMediaQuery(theme.breakpoints.up('md'))
        && isNotNullOrUndefined(mdDownTopOffset);

    return (
        <List
            disablePadding={true}
            className={
                clsx({
                    [classes.firstItemTopDividerDisabled]: disableFirstItemTopDivider,
                    [classes.lastItemBottomDividerDisabled]: disableLastItemBottomDivider
                })
            }
            style={{
                borderTop: applySmDownTopOffset
                    ? `${smDownTopOffset}px solid ${LIGHT_GRAY}`
                    : applyMdDownTopOffset
                        ? `${mdDownTopOffset}px solid ${LIGHT_GRAY}`
                        : 0
            }}
        >
            {children}
        </List>
    )
});

export default CardList;