import {
    Avatar,
    Box,
    Card,
    Grid,
    Hidden,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import {ArrowForwardIos} from '@material-ui/icons';
import useStyles from './AppointmentDtoItemStyles';
import {Appointment, AppointmentDto, AppointmentStateEnum} from '../../../types/appointments';
import {useHistory} from 'react-router';
import {PATIENT_APPOINTMENT_DETAILS_PAGE} from '../../../const/routes';
import {
    AppointmentPaymentLabel,
    PatientToMedicWithSpeciality,
} from '../../index';
import AppointmentStateLabel from '../../common/Appointment/AppointmentStateLabel/AppointmentStateLabel';
import {LARGE_DESKTOP_BREAKPOINT} from '../../../const/sizes';
import {useCallback} from 'react';
import CalendarLink from '../AppointmentLink/CalendarLink';
import AppointmentTimeDetails from '../../common/Appointment/AppointmentTimeDetails/AppointmentTimeDetails';
import { getLanguage, getTranslation } from '../../../utils/translation';
import i18n from '../../../i18n/config';
import { DateTime } from 'luxon';



type AppointmentItemProps = {
    appointment: AppointmentDto;
};

const AppointmentDtoItem = ({appointment}: AppointmentItemProps) => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(LARGE_DESKTOP_BREAKPOINT);


    const startDateTime = appointment.startDate;
    const appointmentState = appointment.state.state;

    const isNow = appointmentState === AppointmentStateEnum.InProgress;
    const isInTheFuture = appointmentState === AppointmentStateEnum.Planned;


    const currentLanguage = getLanguage(i18n?.language);

   

   
    return (
        <Card
            style={{
                padding: smUp ? '24px 0' : 16,
                borderBottom: `1px solid ${theme.palette.grey['300']}`,
                boxShadow: 'none',
            }}
        >
            <Grid
                container
                wrap={smUp ? 'nowrap' : 'wrap'}
                alignItems="center"
                spacing={lgUp ? 2 : 1}
                style={{
                    cursor: !mdUp ? 'pointer' : 'default',
                }}
            >
                <Grid item xs={12} sm="auto">
                    <AppointmentTimeDetails
                        date={DateTime.fromISO(startDateTime)}
                        isNow={isNow}
                        isInTheFuture={isInTheFuture}
                        square={smUp}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Grid
                        container
                        wrap={'nowrap'}
                        alignItems="center"
                        spacing={lgUp ? 2 : 1}
                    >
                        <Grid item xs="auto">
                            <Avatar
                                alt={appointment.medic.surname}
                                src={appointment.medic.photoUrl}
                                className={classes.appointmentPhoto}
                            />
                        </Grid>
                        <Grid item xs>
                            <PatientToMedicWithSpeciality
                                medicTitle={appointment.medic.title}
                                medicFirstName={appointment.medic.firstName}
                                medicSurname={appointment.medic.surname}
                                medicalServiceName={
                                    getTranslation(appointment.medicalService.name, appointment.medicalService.translations, currentLanguage, "name")
                                }
                                patientFirstName={
                                    appointment.child !== null
                                        ? appointment.child.firstName
                                        : appointment.patient.firstName
                                }
                                patientSurname={
                                    appointment.child !== null
                                        ? appointment.child.surname
                                        : appointment.patient.surname
                                }
                            />
                        </Grid>     
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default AppointmentDtoItem;
