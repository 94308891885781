import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {AppointmentStateEnum} from '../../types/appointments';
import {canCancelAppointmentWithoutRefund} from '../../utils/appointment';

export const useCancelAppointmentDetailsText = (
    appointmentState: AppointmentStateEnum,
    canCancelWithRefund: boolean
) => {
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const canCancelWithoutRefund = canCancelAppointmentWithoutRefund(appointmentState, canCancelWithRefund);

    return canCancelWithRefund
        ? t('cancelAppointmentWithRefundInfoText')
        : canCancelWithoutRefund
            ? t('cancelAppointmentInfoText')
            : null
}