import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { tmpdir } from 'os';
import { PapTestCollectedFromDto } from '../../services/diagnostic/orderHeadersProvider';
import { MedicPackageDto } from '../../types/diagnosticServicePackage';
import {DiagnosticServiceDto} from '../../types/referrals';
import {RootState} from '../store';

export interface MedicalTestsSlice {
    selectedCategory: string | null;
    medicalTestNameFilter: string | null;
    selectedMedicalTests: DiagnosticServiceDto[];
    selectedMedicalPackage: MedicPackageDto | null;
    selectedPapTestCollectedFrom: PapTestCollectedFromDto | null;
    selectedPapTestLastMenstruationDate: string | null;
    selectedPapTestChildbirthNumber: number | null;
    selectedPapTestAdditionalInfo: string | null;
}

const initialState: MedicalTestsSlice = {
    selectedCategory: null,
    medicalTestNameFilter: null,
    selectedMedicalTests: [],
    selectedMedicalPackage: null,
    selectedPapTestCollectedFrom: null,
    selectedPapTestLastMenstruationDate: null,
    selectedPapTestChildbirthNumber: null,
    selectedPapTestAdditionalInfo: null
};

export const medicalTestsSlice = createSlice({
    name: 'medicalTests',
    initialState,
    reducers: {
        setMedicalTestsSelectedCategory: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedCategory = action.payload;
        },
        setMedicalTestNameFilter: (state, action: PayloadAction<string>) => {
            state.medicalTestNameFilter = action.payload;
        },
        addSelectedMedicalTest: (
            state,
            action: PayloadAction<DiagnosticServiceDto>,
        ) => {
            if (!state.selectedMedicalTests.find(x=> x.id == action.payload.id))
                state.selectedMedicalTests.push(action.payload);
        },
        addSelectedMedicalPackage: (
            state,
            action: PayloadAction<MedicPackageDto>,
        ) =>{
            state.selectedMedicalPackage = action.payload;
        },
        setSelectedPapTestCollectedFrom: (state, action: PayloadAction<PapTestCollectedFromDto>) =>{
            state.selectedPapTestCollectedFrom = action.payload;
        },
        selectPapTestCollectedFrom: (state, action: PayloadAction<number>)=>{
            state.selectedPapTestCollectedFrom.collectedFrom[action.payload].isSelected = 
                !state.selectedPapTestCollectedFrom.collectedFrom[action.payload].isSelected
        },
        addSelectedPapTestLastMenstruationDate: (state, action: PayloadAction<string>)=>{
            state.selectedPapTestLastMenstruationDate = action.payload;
        },
        addSelectedPapTestChildbirthNumber: (state, action: PayloadAction<number>)=>{
            state.selectedPapTestChildbirthNumber = action.payload;
        },
        addSelectedPapTestAdditionalInfo: (state, action: PayloadAction<string>)=>{
            state.selectedPapTestAdditionalInfo = action.payload;
        },
        addSelectedPapTestFormOtherInfo: (state, action: PayloadAction<string>)=>{
            state.selectedPapTestCollectedFrom.other = action.payload;
        },
        removeSelectedMedicalTestById: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.selectedMedicalTests = state.selectedMedicalTests.filter(
                test => test.id !== action.payload,
            );
        },
        clearSelectedMedicalTests: state => {
            state.selectedMedicalTests = [];
        },
        clearSelectedMedicalPackage: state =>{
            state.selectedMedicalPackage = null;
        },
        clearSelectedPapTestCollectedFrom: state =>{
            state.selectedPapTestCollectedFrom = null;
        },
        clearSelectedPapTestLastMenstruationDate: state =>{
            state.selectedPapTestLastMenstruationDate = null;
        },
        clearSelectedPapTestChildbirthNumber: state =>{
            state.selectedPapTestChildbirthNumber = null;
        },
        clearSelectedPapTestAdditionalInfo: state =>{
            state.selectedPapTestAdditionalInfo= null;
        }
    },
});

export const {
    setMedicalTestsSelectedCategory,
    setMedicalTestNameFilter,
    setSelectedPapTestCollectedFrom,
    addSelectedMedicalTest,
    addSelectedPapTestLastMenstruationDate,
    addSelectedPapTestChildbirthNumber,
    addSelectedPapTestAdditionalInfo,
    addSelectedMedicalPackage,
    addSelectedPapTestFormOtherInfo,
    removeSelectedMedicalTestById,
    clearSelectedMedicalTests,
    clearSelectedMedicalPackage,
    selectPapTestCollectedFrom,
    clearSelectedPapTestCollectedFrom,
    clearSelectedPapTestLastMenstruationDate,
    clearSelectedPapTestChildbirthNumber,
    clearSelectedPapTestAdditionalInfo
} = medicalTestsSlice.actions;

export const selectSelectedMedicalTestsCategory = (state: RootState) =>
    state.referralForMedicalTests.selectedCategory;

export const selectMedicalTestNameFilter = (state: RootState) =>
    state.referralForMedicalTests.medicalTestNameFilter;

export const selectSelectedMedicalTests = (state: RootState) =>
    state.referralForMedicalTests.selectedMedicalTests;

export const selectSelectedMedicalPackage = (state:RootState) =>
    state.referralForMedicalTests.selectedMedicalPackage;

export const selectSelectedPapTestCollectedFrom = (state: RootState) =>
    state.referralForMedicalTests.selectedPapTestCollectedFrom;

export const selectSelectedPapTestLastMenstruationDate = (state: RootState) =>
    state.referralForMedicalTests.selectedPapTestLastMenstruationDate;

export const selectSelectedPapTestChildbirthNumber = (state: RootState) =>
    state.referralForMedicalTests.selectedPapTestChildbirthNumber;

export const selectSelectedPapTestAdditionalInfo = (state: RootState) =>
    state.referralForMedicalTests.selectedPapTestAdditionalInfo;

export default medicalTestsSlice.reducer;
