import {memo, useCallback, useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import MedicalSearchFilters from './MedicalSearchFilters';
import SearchResult from './SearchResult';
import MedicalPackages from '../medicalPackages/MedicalPackages';
import MedicalTestCardSelector from '../cardSelector/MedicalTestCardSelector';
import ConfirmOrRejectModal from '../../common/ConfirmOrRejectModal/ConfirmOrRejectModal';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import { useAppDispatch, useAppSelector } from '../../../hooks/customReduxHooks';
import { clearSelectedMedicalPackage, clearSelectedMedicalTests, selectSelectedMedicalPackage, selectSelectedMedicalTests } from '../../../store/referrals/medicalTestsSlice';


interface Props {
    showMedicalTests: boolean;
    setShowMedicalTests: (showMedicalTests:boolean)=>void;
}


const MedicalTestsSearch = memo(function MedicalTestsSearch({showMedicalTests, setShowMedicalTests}:Props) {
    const dispatch = useAppDispatch();
    const [cardChangeModalOpen, setCardChangeModalOpen] = useState<boolean>(false);

    const selectedMedicalPackage = useAppSelector(selectSelectedMedicalPackage);
    const selectedMedicalTests = useAppSelector(selectSelectedMedicalTests);

    const {t} = useTranslation(i18nNamespaces.REFERRALS);
    
    const showMedicalTestCardView = () =>{
        if (selectedMedicalPackage !== null)
            setCardChangeModalOpen(true);
        
        else
            setShowMedicalTests(true);
    }

    const showMedicalTestPackageCardView = () =>{
        if (selectedMedicalTests.length>0)
            setCardChangeModalOpen(true);

        else
            setShowMedicalTests(false);
    }

    const categoryChangeConfirmed = useCallback(() => {
        dispatch(clearSelectedMedicalTests());
        dispatch(clearSelectedMedicalPackage());
        setCardChangeModalOpen(false);
        
        if (showMedicalTests)
            setShowMedicalTests(false);       
        else  
            setShowMedicalTests(true);        
        
    }, [
        dispatch,
        setCardChangeModalOpen,
        setShowMedicalTests,
        showMedicalTests
    ]);

   
    const categoryChangeRejected = useCallback(() => {
        setCardChangeModalOpen(false);
    }, [setCardChangeModalOpen]);


    return (
        <Box>
            <MedicalTestCardSelector 
                showDefault={showMedicalTests}
                showDefaultCardView = {showMedicalTestCardView}
                showSecondCardView = {showMedicalTestPackageCardView}/>
            {showMedicalTests && 
                (
                <><MedicalSearchFilters />
                <SearchResult /></>)
            }
            {!showMedicalTests && 
                <MedicalPackages/>
            }
            <ConfirmOrRejectModal
                open={cardChangeModalOpen}
                primaryText={t(
                    'cardChangeWhenTestsAlreadyAddedToReferral',
                )}
                onConfirm={categoryChangeConfirmed}
                onReject={categoryChangeRejected}
            />
        </Box>
    );
});

export default MedicalTestsSearch;