import {Box, createStyles, makeStyles} from "@material-ui/core";
import {GREEN, ORANGE, RED, WHITE} from "../../../../const/colors";
import {AppointmentPaymentStatus} from "../../../../types/appointments";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import clsx from "clsx";

interface Props {
    paymentStatus: AppointmentPaymentStatus;
    className?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        label: {
            display: 'inline-block',
            padding: '4px 8px',
            fontSize: 14,
            lineHeight: '24px',
            borderRadius: 4,
            color: WHITE,
        }
    }),
);

const AppointmentPaymentLabel = ({paymentStatus, className}: Props) => {
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);

    const getLabelProps = () => {
        switch (paymentStatus) {
            case 'Paid':
                return {
                    children: tCommon('paid'),
                    style: {backgroundColor: GREEN},
                };
            case 'Canceled':
            case 'Unpaid':
                return {
                    children: tCommon('unpaid'),
                    style: {backgroundColor: RED}
                };
            case 'Pending':
                return {
                    children: tCommon('paymentPending'),
                    style: {backgroundColor: ORANGE},
                };
        }
    }

    return (
        <Box
            component={'span'}
            className={
                clsx(classes.label, {
                    [className]: className
                })
            }
            {...getLabelProps()}
        />
    )
}

export default AppointmentPaymentLabel;