import {makeStyles, Theme} from '@material-ui/core';
import {GRAY, LIGHT_GRAY} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    button: {
        width: 250,
        background: 'white',
        border: `1px solid ${LIGHT_GRAY}`,
        color: GRAY,
        textTransform: 'none',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginBottom: 8,
        },
    },
    selectedButton: {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
    },
}));

export default useStyles;
