import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { AdditionalPhysicalTestDto, PhysicalTestsByMedicDto } from "../../../types/occupationalMedicine";
import { MutableRefObject, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Accordion, AccordionDetails, AccordionSummary } from "../../common/Accordion/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CheckCircleOutline } from "@material-ui/icons";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem } from "@material-ui/core";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { fetchSection10Data, fetchSection9Data } from "../../../store/occupationalMedicineSlice";
import { useParams } from "react-router";
import { mainModule } from "process";
import { RenderReturnNextButtons } from "../CommonView";
import additionalPhisicalTestsValidationSchema from "./AdditionalPhysicalTestsValidationSchema";

const SECTION = 10

export type Props = {
    currentExpanded:number
    data:AdditionalPhysicalTestDto;
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void;
    onReturn: () => void;
    refSection:MutableRefObject<any>
}

const AdditionalPhysicalTestsComponent = ({currentExpanded, data, onSubmitSection, refSection, onReturn}:Props) =>{
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();

    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);
        
    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values), fetchSection10Data({id:appointmentId}),10);
    }

    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {             
                skin:{
                    isNormal:data?.skin.isNormal ?? false,
                    isPathology:data?.skin.isPathology ?? false ,
                    notTested:data?.skin.notTested ?? false,
                    pathologyDescription:data?.skin.pathologyDescription ?? "",
                },
                skull:{
                    isNormal:data?.skull.isNormal ?? false,
                    isPathology:data?.skull.isPathology ?? false ,
                    notTested:data?.skull.notTested ?? false,
                    pathologyDescription:data?.skull.pathologyDescription ?? "",
                },
                lymphNodes:{
                    isNormal:data?.lymphNodes.isNormal ?? false,
                    isPathology:data?.lymphNodes.isPathology ?? false ,
                    notTested:data?.lymphNodes.notTested ?? true,
                    pathologyDescription:data?.lymphNodes.pathologyDescription ?? "",
                },
                nose:{
                    isNormal:data?.nose.isNormal ?? false,
                    isPathology:data?.nose.isPathology ?? false ,
                    notTested:data?.nose.notTested ?? false,
                    pathologyDescription:data?.nose.pathologyDescription ?? "",
                },
                oropharyngealCavity:{
                    isNormal:data?.oropharyngealCavity.isNormal ?? false,
                    isPathology:data?.oropharyngealCavity.isPathology ?? false ,
                    notTested:data?.oropharyngealCavity.notTested ?? true,
                    pathologyDescription:data?.oropharyngealCavity.pathologyDescription ?? "",
                },
                neck:{
                    isNormal:data?.neck.isNormal ?? false,
                    isPathology:data?.neck.isPathology ?? false ,
                    notTested:data?.neck.notTested ?? false,
                    pathologyDescription:data?.neck.pathologyDescription ?? "",
                },
                chest:{
                    isNormal:data?.chest.isNormal ?? false,
                    isPathology:data?.chest.isPathology ?? false ,
                    notTested:data?.chest.notTested ?? false,
                    pathologyDescription:data?.chest.pathologyDescription ?? "",
                },
                lungs:{
                    isNormal:data?.lungs.isNormal ?? false,
                    isPathology:data?.lungs.isPathology ?? false ,
                    notTested:data?.lungs.notTested ?? true,
                    pathologyDescription:data?.lungs.pathologyDescription ?? "",
                },
                cardiovascularSystem:{
                    isNormal:data?.cardiovascularSystem.isNormal ?? false,
                    isPathology:data?.cardiovascularSystem.isPathology ?? false ,
                    notTested:data?.cardiovascularSystem.notTested ?? true,
                    pathologyDescription:data?.cardiovascularSystem.pathologyDescription ?? "",
                },
                abdominalCavity:{
                    isNormal:data?.abdominalCavity.isNormal ?? false,
                    isPathology:data?.abdominalCavity.isPathology ?? false ,
                    notTested:data?.abdominalCavity.notTested ?? true,
                    pathologyDescription:data?.abdominalCavity.pathologyDescription ?? "",
                },
                urinaryGenitalSystem:{
                    isNormal:data?.urinaryGenitalSystem.isNormal ?? false,
                    isPathology:data?.urinaryGenitalSystem.isPathology ?? false ,
                    notTested:data?.urinaryGenitalSystem.notTested ?? true,
                    pathologyDescription:data?.urinaryGenitalSystem.pathologyDescription ?? "",
                },
                locomotorSystem:{
                    isNormal:data?.skin.isNormal ?? false,
                    isPathology:data?.skin.isPathology ?? false ,
                    notTested:data?.skin.notTested ?? false,
                    pathologyDescription:data?.skin.pathologyDescription ?? "",
                },  
                theNervousSystem:{
                    isNormal:data?.theNervousSystem.isNormal ?? false,
                    isPathology:data?.theNervousSystem.isPathology ?? false ,
                    notTested:data?.theNervousSystem.notTested ?? false,
                    pathologyDescription:data?.theNervousSystem.pathologyDescription ?? "",
                },  
                mentalCondition:{
                    isNormal:data?.mentalCondition.isNormal ?? false,
                    isPathology:data?.mentalCondition.isPathology ?? false ,
                    notTested:data?.mentalCondition.notTested ?? false,
                    pathologyDescription:data?.mentalCondition.pathologyDescription ?? "",
                }
            },
            validationSchema: additionalPhisicalTestsValidationSchema(commonT),
            onSubmit,
        }); 

        const renderSection = (sectionName:string, value:any, id:string, touchedValue:any, errorsValue:any)=>
        (
            <>
            <Grid item xs={12}>
                <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                    {t(sectionName)}
                </Box>
            </Grid>
            <Grid item xs={3}>
                <FormGroup>     
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={id+'.isNormal'}
                                name={id+'.isNormal'}
                                checked={value.isNormal}
                                onChange={handleChange}
                                disabled={value.notTested || value.isPathology}
                            />
                        }
                        label={
                            <Box className={classes.text}>
                                {t('normal')}
                            </Box>
                        }     
                        />
                </FormGroup>
            </Grid>
            <Grid item xs={3}>
                <FormGroup>     
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={id+'.isPathology'}
                                name={id+'.isPathology'}
                                checked={value.isPathology}
                                onChange={handleChange}
                                disabled={value.isNormal || value.notTested}
                            />
                        }
                        label={
                            <Box className={classes.text}>
                                {t('pathology')}
                            </Box>
                        }     
                        />
                </FormGroup>
            </Grid>
            <Grid item xs={3}>
                <FormGroup>     
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={id+'.notTested'}
                                name={id+'.notTested'}
                                checked={value.notTested}
                                onChange={handleChange}
                                disabled={value.isNormal || value.isPathology}
                            />
                        }
                        label={
                            <Box className={classes.text}>
                                {t('notTested')}
                            </Box>
                        }     
                        />
                </FormGroup>
            </Grid>
            {value.isPathology && <Grid item xs={8}>
                <FormGroup >
                    <Label htmlFor="pathologyDescription">{t('pathologyDescription')} *</Label>
                    <CustomTextField 
                        fullWidth 
                        id={id+'.pathologyDescription'}
                        name={id+'.pathologyDescription'}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={value.pathologyDescription}
                        helperText={touchedValue && t(errorsValue)}
                        error={touchedValue && !!errorsValue}
                        />
                </FormGroup>
            </Grid>}
            </>
        );
        return (
            <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">

                        <Box className={classes.titleContainer}>
                            <h4 className={classes.sectionHeader}>
                                {t('phisicalTestsAdditional')}
                            </h4>

                            {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsContainer}>
                        <Box>
                            <Grid container spacing={1} className={classes.gridContainer}>
                                {renderSection("skin", values.skin, "skin", touched.skin?.pathologyDescription, errors.skin?.pathologyDescription)}
                                {renderSection("skull", values.skull, "skull", touched.skull?.pathologyDescription, errors.skull?.pathologyDescription)}
                                {renderSection("lymphNodes", values.lymphNodes, "lymphNodes", touched.lymphNodes?.pathologyDescription, errors.lymphNodes?.pathologyDescription)}
                                {renderSection("nose", values.nose, "nose", touched.nose?.pathologyDescription, errors.nose?.pathologyDescription)}
                                {renderSection("neck", values.neck, "neck", touched.neck?.pathologyDescription, errors.neck?.pathologyDescription)}
                                {renderSection("chest", values.chest, "chest", touched.chest?.pathologyDescription, errors.chest?.pathologyDescription)}
                                {renderSection("lungs", values.lungs, "lungs", touched.lungs?.pathologyDescription, errors.lungs?.pathologyDescription)}
                                {renderSection("cardiovascularSystem", values.cardiovascularSystem, "cardiovascularSystem", touched.cardiovascularSystem?.pathologyDescription, errors.cardiovascularSystem?.pathologyDescription)}
                                {renderSection("abdominalCavity", values.abdominalCavity, "abdominalCavity", touched.abdominalCavity?.pathologyDescription, errors.abdominalCavity?.pathologyDescription)}
                                {renderSection("urinaryGenitalSystem", values.urinaryGenitalSystem, "urinaryGenitalSystem", touched.urinaryGenitalSystem?.pathologyDescription, errors.urinaryGenitalSystem?.pathologyDescription)}
                                {renderSection("locomotorSystem", values.locomotorSystem, "locomotorSystem", touched.locomotorSystem?.pathologyDescription, errors.locomotorSystem?.pathologyDescription)}
                                {renderSection("theNervousSystem", values.theNervousSystem, "theNervousSystem", touched.theNervousSystem?.pathologyDescription, errors.theNervousSystem?.pathologyDescription)}
                                {renderSection("mentalCondition", values.mentalCondition, "mentalCondition", touched.mentalCondition?.pathologyDescription, errors.mentalCondition?.pathologyDescription)}
                            </Grid>
                            {RenderReturnNextButtons(classes, t, onReturn)}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </form>

        );

}
export default AdditionalPhysicalTestsComponent;