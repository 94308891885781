import {useCallback, useEffect, useMemo, useState} from 'react';
import {isObject} from 'lodash-es';
import {Appointment} from '../../types/appointments';
import appointmentsProvider from '../../services/appointmentsProvider';
import mapAppointment from '../../store/mappers/mapAppointment';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';

const useAppointment = (appointmentId: number) => {
    const [appointment, setAppointment] = useState<Appointment>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const fetchAppointment = useCallback(async () => {
        setLoading(true);
        try {
            const response = await appointmentsProvider.getAppointmentAxios(`${appointmentId}`);
            if (!isObject(response.data)) {
                throw new Error();
            }
            setAppointment(
                mapAppointment(response.data)
            );
            setError(null);
        } catch {
            setAppointment(null);
            setError(tErrors('fetchingDataFromServerError'));
        } finally {
            setLoading(false);
        }
    }, [appointmentId, setAppointment, setLoading, setError]);

    useEffect(() => {
        if (appointmentId) {
            fetchAppointment();
        }
    }, [appointmentId, fetchAppointment]);

    const value = useMemo(
        () => ({
            appointment,
            appointmentLoading: loading,
            appointmentError: error,
        }),
        [appointment, loading, error]
    );

    return value;
}

export default useAppointment;