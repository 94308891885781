import {http, rawHttp} from './http';
import {
    DIAGNOSTIC_SERVICE_TYPES,
    DIAGNOSTIC_SERVICES,
    REFERRALS,
    ORDERED_DIAGNOSTIC_ORDER_HEADERS,
    PATIENT_DIAGNOSTIC_ORDERS,
    OCCUPATIONAL_MEDICINE_REFERRALS,
    REFERRAL_PDF_URL,
    DIAGNOSTIC_ORDER_PDF_URL,
} from './endpoints';
import {
    createPatientReferralDto,
    DiagnosticServiceDto,
    PatientOccupationalMedicineReferralDto,
    PatientReferralDto,
} from '../types/referrals';
import {PaginatedAndFilteredResults, PaginatedResults} from '../types/common';
import {
    PatientDiagnosticOrdersReferralsDto,
    PatientOrderedDiagnosticOrderHeadersDto,
    PatientOrderedDiagnosticServicesDto,
} from './diagnostic/orderHeadersProvider';
import {PATIENT} from '../const/auth';

class ReferralsProvider {
    getPatientReferrals() {
        return http.get<PatientReferralDto[]>(REFERRALS);
    }

    createPatientReferral(data: createPatientReferralDto) {
        return http.post(REFERRALS, data);
    }

    deleteReferral(referralId: number) {
        return http.delete(`${REFERRALS}/${referralId}`);
    }

    getCategoriesOfMedicalTests() {
        return rawHttp.get<string[]>(DIAGNOSTIC_SERVICE_TYPES);
    }

    async getMedicalTestByCategoryName(
        categoryName: string,
        page = 0,
        size = 10,
        search: string,
    ) {
        return (
            await http.get<PaginatedAndFilteredResults<DiagnosticServiceDto>>(
                DIAGNOSTIC_SERVICES,
                {
                    params: {
                        page,
                        size,
                        type: categoryName,
                        search: search,
                    },
                },
            )
        ).data;
    }

    async getOrderedMedicalTests(childId: string, page = 0, size = 10) {
        return (
            await http.get<
                PaginatedResults<PatientOrderedDiagnosticOrderHeadersDto>
            >(ORDERED_DIAGNOSTIC_ORDER_HEADERS, {
                params: {
                    page,
                    size,
                    childId: childId,
                },
            })
        ).data;
    }
    async getPatientOrderedMedicalTests(
        patientId: string,
        page = 0,
        size = 10,
        childId?: number,
    ) {
        return (
            await http.get<
                PaginatedResults<PatientDiagnosticOrdersReferralsDto>
            >(`${PATIENT_DIAGNOSTIC_ORDERS(patientId)}`, {
                params: {
                    page,
                    size,
                    childId,
                },
            })
        ).data;
    }

    getOrderedMedicalTestDetails(id: string) {
        return rawHttp.get<PatientOrderedDiagnosticOrderHeadersDto>(
            `${ORDERED_DIAGNOSTIC_ORDER_HEADERS}/${id}`,
        );
    }

    getOccupationalMedicineReferrals() {
        return rawHttp.get<
            PaginatedResults<PatientOccupationalMedicineReferralDto>
        >(OCCUPATIONAL_MEDICINE_REFERRALS);
    }

    getReferralPdfUrl (id:number){
        return rawHttp.get<string>(REFERRAL_PDF_URL(id));
    }

    getDiagnosticOrderReferralPdfUrl (id:string){
        return rawHttp.get<string>(DIAGNOSTIC_ORDER_PDF_URL(id));
    }
}

export default new ReferralsProvider();
