import {Attachment, AttachmentTypeEnum} from '../types/appointments';

const occupationalMedicineAttachmentTypes = [
    AttachmentTypeEnum.OMHealthDeclaration,
    AttachmentTypeEnum.OMReferral,
    AttachmentTypeEnum.OMCertificate,
    AttachmentTypeEnum.OMPreventiveExaminationCard,
];

const occupationalMedicineAttachmentsFromPatientTypes = [
    AttachmentTypeEnum.OMHealthDeclaration,
    AttachmentTypeEnum.OMReferral,
];

const occupationalMedicineAttachmentsFromMedicTypes = [
    AttachmentTypeEnum.OMCertificate,
    AttachmentTypeEnum.OMPreventiveExaminationCard,
];

export const isAttachmentFromPatientInOccupationalMedicine = (
    attachment: Attachment,
) =>
    occupationalMedicineAttachmentsFromPatientTypes.includes(
        attachment.attachmentType,
    );
export const isAttachmentFromMedicInOccupationalMedicine = (
    attachment: Attachment,
) =>
    occupationalMedicineAttachmentsFromMedicTypes.includes(
        attachment.attachmentType,
    );
export const isAttachmentFromOccupationalMedicine = (attachment: Attachment) =>
    occupationalMedicineAttachmentTypes.includes(attachment.attachmentType);
