
import * as yup from 'yup';

const REQUIRED_STRING = 'requiredField';

const subjectiveExaminationsValidationsSchema = (commonT:any) => yup.object({
    skullInjuries: yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    musculoskeletalsystemInjuries: yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    fainting:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    epilepsy:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    otherdiseasesOfTheNervousSystem:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    mentalDisorders:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    diabetes:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    hearingDiseases:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    eyeDiseases:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    diseasesOfTheHematopoieticSystem:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    cardiovascularDisease:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    respiratorySystemDiseases:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    digestiveSystemDiseases:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    diseasesOfTheGenitourinarySystem:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    diseasesOfTheMusculoskeletalSystem:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    skinDiseasesAllergies:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    infectiousParasiticDiseases:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    gynecologicalAndObstetricInterview:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    familyHistory:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    otherHealthProblems:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    smoking:yup.object().shape({
        inThePast: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        }),
        currently: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),  
    otherStimulants:yup.object().shape({
        description: yup.string().when('occured', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
});

export default subjectiveExaminationsValidationsSchema;