import {createStyles, makeStyles} from '@material-ui/core';
import {WHITE} from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            backgroundColor: WHITE,
            padding: '25px 24px',
            borderRadius: 3,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        closeButton: {
            alignSelf: 'flex-end',
            width: 13,
            height: 13,
            position: 'absolute',
            top: 10,
            right: 10,
        },
    }),
);

export default useStyles;
