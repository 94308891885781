import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorWrapper: {
            height: '100%',
            marginTop: '16px',
            padding: mobile.contentLargerSpacing,
        },
        errorTitle: {
            fontSize: '36px',
            fontWeight: 'bold',
        },
        errorContent: {
            fontSize: '1rem',
            marginTop: '40px',
            marginBottom: '40px',
            cursor: 'pointer',
            alignSelf: 'flex-start',
        },
        errorImage: {
            width: '100%',
            marginBottom: '40px',
        },
        [theme.breakpoints.up('sm')]: {
            errorWrapper: {
                marginTop: '88px',
            },
            errorContent: {
                fontSize: '1rem',
                marginTop: '32px',
                marginBottom: '32px',
                alignSelf: 'auto',
            },
            errorImage: {
                width: '500px',
                marginBottom: 0,
            },
        },
    }),
);

export default useStyles;
