import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formSection: {
            backgroundColor: WHITE,
            marginBottom: mobile.contentSmallerSpacing,
        },
        formSectionHeader: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: mobile.contentSmallerSpacing,
        },
        formSubsection: {
            width: '100%',
        },
        formGroup: {
            marginTop: desktop.contentSmallerSpacing,
        },
        buyerDataTypeButton: {
            width: '100%',
            marginTop: mobile.contentSmallerSpacing,
        },
        disabledButton: {
            color: theme.palette.grey[800],
            borderColor: theme.palette.grey[800],
        },
        numberFormGroup: {
            width: '50%',
            paddingRight: mobile.contentSmallerSpacing,
        },
        apartmentFormGroup: {
            width: '50%',
            paddingLeft: mobile.contentSmallerSpacing,
        },
        codeFormGroup: {
            width: '25%',
            paddingRight: mobile.contentSmallerSpacing,
        },
        cityFromGroup: {
            width: '75%',
            paddingLeft: mobile.contentSmallerSpacing,
        },
        [theme.breakpoints.up('sm')]: {
            formSectionHeader: {
                fontSize: 20,
                marginBottom: desktop.contentSmallerSpacing,
            },
            formSubsection: {
                width: 'auto',
            },
            buyerDataType: {
                flexDirection: 'row',
            },
            buyerDataTypeButton: {
                width: 300,
                marginTop: 0,
                marginRight: desktop.contentSmallerSpacing,
            },
            numberFormGroup: {
                width: 'auto',
                paddingRight: 0,
            },
            numberInput: {
                width: 84,
            },
            apartmentFormGroup: {
                width: 'auto',
                paddingLeft: 0,
            },
            apartmentInput: {
                width: 84,
            },
            codeFormGroup: {
                width: 'auto',
                paddingLeft: 0,
            },
            codeInput: {
                width: 84,
            },
            cityFromGroup: {
                width: 'auto',
                paddingRight: 0,
            },
        },
    }),
);

export default useStyles;
