import {makeStyles, Theme} from "@material-ui/core";
import {BACKGROUND_SECONDARY, BLACK, TEXT_SECONDARY, WHITE} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    attachmentTopBar: {
        zIndex: theme.zIndex.drawer + 1,
        fontSize: 20,
        fontWeight: 'bold',
        color: WHITE,
        backgroundColor: BLACK,
        height: 72,
        paddingLeft: 42,
        paddingTop: 24,
        flexDirection: 'row',
    },
    closeIconButton: {
        color: WHITE,
        width: '22px',
        height: '22px',
        cursor: 'pointer',
        marginRight: 21,
        float: 'right',
    },
    fileUploadContainer: {
        backgroundColor: WHITE,
        padding: '24px 47px',
    },
    fileList: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0,
        marginBottom: '32px',
    },
    fileListItem: {
        flex: '1 0 50%',
        padding: 0,
    },
    dropzone: {
        height: '80px',
        minHeight: 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: BACKGROUND_SECONDARY,
    },
    dropzoneText: {
        color: TEXT_SECONDARY,
        fontSize: '16px',
        marginTop: '105px',
        marginLeft: '10px'
    },
}));

export default useStyles;