import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: `0 ${mobile.contentLargerSpacing}`,
        },
        title: {
            width: '100%',
            fontSize: 36,
            fontWeight: 'bold',
            marginBottom: 58,
        },
        subtitle: {
            marginBottom: 44,
        },
        [theme.breakpoints.up('sm')]: {
            container: {
                paddingTop: 90,
                textAlign: 'center',
            },
            subtitle: {
                width: 500,
            },
        },
    }),
);

export default useStyles;