import {Box, Grid, Typography} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import useStyles from './ContactPageStyles';
import {EmailOutlined, PhoneOutlined, LocationOnOutlined, Facebook, LinkedIn } from  '@material-ui/icons'
import { NewTabLink } from '../../components/common/navigation';
import configuration from '../../configuration';
import { useEffect } from 'react';

const ContactPage = () => {
    const classes = useStyles();
    const {t: tContent} = useTranslation(i18nNamespaces.FOOTER_CONTENT);
    const {t: tMetaDescription} = useTranslation(i18nNamespaces.META_DESCRIPTION);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {configuration.environment === 'Production' &&
                <Helmet>
                    <title>{tMetaDescription('contactPageTitle')}</title>
                    <meta name='description' content={tMetaDescription('contactPageDescription')} />
                    <meta name='keywords' content={tMetaDescription('contactPageKeywords')} />
                </Helmet>
            }
            <Box className={classes.container}>
                <Grid
                    container>
                    <Grid item>
                        <Typography variant="h1" className={classes.title}>
                            {tContent('contactTitle')}
                        </Typography>
                        <Box
                            className={classes.description}>
                            <Grid container
                                spacing={4}
                                direction='column'
                                >
                                    <Grid item>
                                        <Box className={classes.innerContainer}>
                                            <EmailOutlined className={classes.icons}/>
                                            <span>info@global-teleclinic.com</span>
                                        </Box>

                                        {/* <Box className={classes.innerContainer}>
                                            <PhoneOutlined className={classes.icons}/>
                                            <span>000 000 000</span>
                                        </Box> */}

                                    </Grid>

                                    <Grid item>
                                        <Box className={classes.innerContainer}>
                                            GLOBAL TELECLINIC & ASSISTANCE
                                        </Box>
                                        <Box className={classes.innerContainer}>
                                            <LocationOnOutlined className={classes.icons}/>
                                            <span>ul. Twarda 18, 00-105 Warszawa</span>
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <NewTabLink
                                            className={classes.innerContainer}
                                            href={'https://www.facebook.com/GlobalTeleclinic/'}>
                                            <Facebook className={classes.icons}/>
                                            <span>{tContent('findUsOnFacebook')}</span>
                                        </NewTabLink>

                                        <NewTabLink
                                            className={classes.innerContainer}
                                            href={'https://www.linkedin.com/company/global-teleclinic/'}>
                                            <LinkedIn className={classes.icons}/>
                                            <span>{tContent('findUsOnLinkedin')}</span>
                                        </NewTabLink>

                                    </Grid>
                            </Grid>
                        </Box>


                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
export default ContactPage;
