import { Box, Grid, List } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { PatientOrderedDiagnosticServicesDto } from "../../../services/diagnostic/orderHeadersProvider";
import { isNotNullOrUndefined } from "../../../utils/lang";
import useStyles from "./MedicalTestDiagnosticServicesDetailsStyles";


interface Props{
    diagnosticServices: PatientOrderedDiagnosticServicesDto[],
    type:string
    medicalPackage:string;
}
const MedicalTestDiagnosticServicesDetails = ({diagnosticServices, type, medicalPackage}: Props) =>{

    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);
    return (
        <Box>
            <Grid  
                className={classes.section}
                direction={'column'}
                spacing={1}>
                <Grid item className={classes.header}>
                    {isNotNullOrUndefined(medicalPackage) ? medicalPackage : t(type)}
                </Grid>
                <Grid item className={classes.title}>
                    {t('testsPerformed')}:
                </Grid>
                <Grid item>
                        <List className={classes.diagnosticServiceList}>
                        {
                            diagnosticServices.map((diagnosticService) => (
                                <li key={diagnosticService.id}>
                                    {diagnosticService.diagnosticServiceName}
                                </li>
                            ))
                        }
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MedicalTestDiagnosticServicesDetails;