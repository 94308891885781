import {makeStyles} from '@material-ui/core';
import {CONVERSATION_BACKGROUND} from '../../../../const/colors';

const useStyles = makeStyles(() => ({
    statusScreen: {
        backgroundColor: CONVERSATION_BACKGROUND,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: 'white',
        height: '100vh',
        flexShrink: 1,
    },
    chatWrapper: {
        float:'right',
        width: 679,
        height: 'calc(100vh - 64px)',
    },
}));

export default useStyles;
