import {Redirect, Route, Switch} from 'react-router-dom';
import {
    APPOINTMENT_PAYMENT_CONTINUE,
    APPOINTMENT_SEARCH_WIDGET_PAGE,
    ARTICLE_PAGE,
    ARTICLES_PAGE,
    CALL_PAGE,
    CHANGE_ADDRESS_PAGE,
    CHANGE_PASSWORD_PAGE,
    CHANGE_PHONE_NUMBER_PAGE,
    CHANGE_PHONE_NUMBER_VERIFICATION_PAGE,
    CHAT_PAGE,
    CONFIRMED_IDENTITY,
    CONTACT_PAGE,
    DECLARATION_OF_PUBLIC_INSURANCE_PAGE,
    EMAIL_VERIFICATION_PAGE,
    EMPTY_TIME_SLOTS_PAGE,
    ERROR,
    ERROR_400_PAGE,
    ERROR_404_PAGE,
    EVALUATION_FINISH,
    EVALUATION_PAGE,
    FIND_TIME_SLOTS,
    FORCED_PASSWORD_CHANGE_PAGE,
    FORGOT_PASSWORD_PAGE,
    HELP_PAGE,
    IDENTITY_CONFIRMATION_NEEDED_PAGE,
    INDIVIDUAL_PACKAGE_PAGE,
    INDIVIDUAL_PACKAGES_PAGE,
    LOGIN_PAGE,
    LOGOUT_PAGE,
    MEDIC_APPOINTMENT_DETAILS_PAGE,
    MEDIC_CALENDAR,
    MEDIC_CALENDAR_ADD_TIME_SLOT,
    MEDIC_CALENDAR_DATE_SELECTION,
    MEDIC_SETTINGS,
    MEDICAL_TEST_RESULT_PAGE,
    MEDICAL_TEST_RESULTS_PAGE,
    MEDICAL_TESTS_ORDER_PAYMENT_PAGE,
    MEDICAL_TESTS_SUCCESSFULLY_ORDERED_PAGE,
    MEDICAL_TESTS_SUCCESSFULLY_ORDERED_WITHOUT_PAYMENT_PAGE,
    NEW_PRESCRIPTION_PAGE,
    NO_INTERNET_ERROR,
    ORDER_MEDICAL_TESTS_PAGE,
    PASSWORD_CHANGE_FINISH_PAGE,
    PATIENT_APPOINTMENT_DETAILS_PAGE,
    PATIENT_REFERRAL_FOR_MEDICAL_TESTS_PAGE,
    PATIENT_REFERRAL_SUCCESSFULLY_MADE_PAGE,
    PATIENT_REFERRAL_TO_MEDIC_PAGE,
    PHONE_NUMBER_CHANGED_PAGE,
    PHONE_VERIFICATION_PAGE,
    PRESCRIPTION_ISSUED_PAGE,
    PRIVACY_POLICY_PAGE,
    QUESTIONNAIRE_PAGE,
    REGISTER_MOJE_ID_PAGE,
    REGISTER_PAGE,
    SELECT_ROLE_PAGE,
    SUBSCRIPTION_PAYMENT_CONTINUE,
    SUBSCRIPTION_PAYMENT_FAILED_PAGE,
    SUBSCRIPTION_PAYMENT_PAGE,
    TERMS_AND_CONDITIONS_PAGE,
    TIME_SLOT_PAYMENT_PAGE,
    TIME_SLOTS_PAGE,
    TIME_SLOTS_SEARCH_PAGE,
    USER_ACCOUNT_PAGE,
    VIDEO_PAGE,
    SICK_LEAVE_ISSUED_PAGE,
    NEW_SICK_LEAVE_PAGE,
    SICK_LEAVES,
    SICK_LEAVE_CANCELLED_PAGE,
    VOUCHER_PAGE,
    OCCUPATIONAL_MEDICINE_PAGE,
    OCCUPATIONAL_MEDICINE_PAGE_FILLED,
    FIND_OCCUPATIONAL_MEDICINE_TIMESLOTS,
} from './const/routes';
import withLayout from './hoc/withLayout';
import {
    AppointmentPaymentContinuePage,
    AppointmentSearchWidgetPage,
    ArticlePage,
    ArticlesListPage,
    CallPage,
    ChangePhoneNumberVerificationPage,
    ChatPage,
    ContactPage,
    EmailVerificationPage,
    EmptyTimeSlotsPage,
    Error400Page,
    Error404Page,
    ErrorPage,
    EvaluationFinishPage,
    EvaluationPage,
    FindTimeSlotsPage,
    ForcedPasswordChangePage,
    ForgotPasswordPage,
    IdentityConfirmationNeededPage,
    IndividualPackagesPage,
    LoginPage,
    LogoutPage,
    MedicalTestResultDetailsPage,
    MedicalTestResultsPage,
    MedicalTestsOrderPaymentPage,
    MedicalTestsSuccessfullyOrderedPage,
    MedicalTestsSuccessfullyOrderedWithoutPaymentPage,
    MedicAppointmentDetailsPage,
    MedicCalendar,
    MedicCalendarAddTimeSlotsPage,
    MedicSettingsPage,
    NoInternetPage,
    OrderMedicalTestsPage,
    PasswordChangeFinishPage,
    PatientAppointmentDetailsPage,
    PatientReferralForMedicalTestsPage,
    PatientReferralSuccessfullyMadePage,
    PhoneNumberChangedPage,
    PhoneVerificationPage,
    PrivacyPolicyPage,
    QuestionnairePage,
    ReferPatientToSpecialistPage,
    RegisterMojeIDPage,
    RegisterPage,
    SelectRolePage,
    SubscriptionPaymentContinuePage,
    SubscriptionPaymentPage,
    TermsAndConditionsPage,
    TimeSlotPaymentPage,
    TimeSlotsPage,
    UserAccountPage,
    VideoPage,
    SickLeaveIssuedPage,
    NewSickleavePage,
    SickLeaveCancelledPage,
    VoucherPage,
    OccupationalMedicinePage,
} from './pages';
import {
    AuthRoute,
    MedicCalendarDateSelection,
    PickersProvider,
} from './components';
import {
    EMAIL_CONFIRMATION,
    FORCED_PASSWORD_CHANGE,
    MEDIC,
    NOT_CONFIRMED_USER,
    PATIENT,
    PHONE_NUMBER_CONFIRMATION,
} from './const/auth';
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import useThemeForRoute from './hooks/useThemeForRoute';
import './i18n/config';
import EmptyLayout from './components/Layout/EmptyLayout';
import {PayUSecureFormsProvider} from './components/common/payUSecureForms';
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage';
import {AlertContextProvider} from './contexts/AlertContext';
import ChangePhoneNumberPage from './pages/ChangePhoneNumberPage/ChangePhoneNumberPage';
import HelpPage from './pages/HelpPage/HelpPage';
import configuration from './configuration';
import {usePageTracking} from './tracking/usePageTracing';
import useLanguageFromParam from './hooks/useLanguageFromParam';
import SubscriptionPaymentFailedPage from './pages/SubscriptionPaymentFailedPage/SubscriptionPaymentFailedPage';
import NewPrescriptionPage from './pages/NewPrescriptionPage/NewPrescriptionPage';
import PrescriptionIssuedPage from './pages/PrescriptionIssuedPage/PrescriptionIssuedPage';
import ChangeAddressPage from './pages/ChangeAddressPage/ChangeAddressPage';
import useScrollToTop from './hooks/useScrollToTop';
import DeclarationOfPublicInsurancePage from './pages/DeclarationOfPublicInsurancePage/DeclarationOfPublicInsurancePage';
import IndividualPackagePage from './pages/IndividualPackagePage/IndividualPackagePage';
import payUSecureFormsProvider from './components/common/payUSecureForms/PayUSecureFormsProvider/PayUSecureFormsProvider';
import ConfirmIdentityPage from './pages/ConfirmIdentityPage/ConfirmIdentityPage';
import SickLeaveDetailsPage from './pages/SickLeaveDetailsPage/SickLeaveDetailsPage';
import OccupationalMedicineFilledPage from './pages/OccupationalMedicineFilledPage/OccupationalMedicineFilledPage';
import FindOccupationalMedicineTimeSlotsPage from './pages/FindOccupationalMedicineTimeSlotsPage/FindOccupationalMedicineTimeSlotsPage';

const App = () => {
    if (configuration.environment === 'Production') {
        usePageTracking();
    }
    useLanguageFromParam();
    const theme = useThemeForRoute();
    useScrollToTop();

    return (
        <ThemeProvider theme={theme}>
            <PickersProvider>
                <AlertContextProvider>
                    <CssBaseline />
                    <Switch>
                        <Route
                            exact
                            path={APPOINTMENT_SEARCH_WIDGET_PAGE}
                            component={AppointmentSearchWidgetPage}
                        />
                        <Route
                            exact
                            path={EMPTY_TIME_SLOTS_PAGE}
                            component={withLayout(EmptyTimeSlotsPage)}
                        />
                        <Route
                            exact
                            path={TIME_SLOTS_PAGE}
                            component={TimeSlotsPage}
                        />
                        <Route
                            exact
                            path={REGISTER_PAGE}
                            component={withLayout(RegisterPage)}
                        />
                        <Route
                            exact
                            path={FORGOT_PASSWORD_PAGE}
                            component={withLayout(ForgotPasswordPage)}
                        />
                        <Route
                            exact
                            path={REGISTER_MOJE_ID_PAGE}
                            component={withLayout(RegisterMojeIDPage)}
                        />
                        <Route
                            exact
                            path={IDENTITY_CONFIRMATION_NEEDED_PAGE}
                            component={withLayout(
                                IdentityConfirmationNeededPage,
                            )}
                        />
                        <Route
                            path={INDIVIDUAL_PACKAGES_PAGE}
                            component={withLayout(IndividualPackagesPage)}
                            exact
                        />
                        <Route exact path={ERROR}>
                            <EmptyLayout>
                                <ErrorPage />
                            </EmptyLayout>
                        </Route>
                        <Route exact path={SUBSCRIPTION_PAYMENT_FAILED_PAGE}>
                            <EmptyLayout>
                                <SubscriptionPaymentFailedPage />
                            </EmptyLayout>
                        </Route>
                        <Route exact path={NO_INTERNET_ERROR}>
                            <EmptyLayout>
                                <NoInternetPage />
                            </EmptyLayout>
                        </Route>
                        <Route
                            exact
                            path={ERROR_404_PAGE}
                            component={withLayout(Error404Page)}
                        />
                        <Route
                            exact
                            path={ERROR_400_PAGE}
                            component={withLayout(Error400Page)}
                        />
                        <Route
                            path={CONTACT_PAGE}
                            component={withLayout(ContactPage)}
                            exact
                        />
                        <Route
                            path={PRIVACY_POLICY_PAGE}
                            component={withLayout(PrivacyPolicyPage)}
                            exact
                        />
                        <Route
                            path={TERMS_AND_CONDITIONS_PAGE}
                            component={withLayout(TermsAndConditionsPage)}
                            exact
                        />
                        <Route
                            exact
                            path={ARTICLES_PAGE}
                            component={withLayout(ArticlesListPage)}
                        />
                        <Route
                            exact
                            path={ARTICLE_PAGE}
                            component={withLayout(ArticlePage)}
                        />
                        <Route
                            exact
                            path={TIME_SLOTS_SEARCH_PAGE}
                            component={withLayout(LoginPage)}
                        />
                        <Route
                            path={LOGIN_PAGE}
                            component={withLayout(LoginPage)}
                        />
                        <AuthRoute
                            exact
                            path={LOGOUT_PAGE}
                            component={withLayout(LogoutPage)}
                            roles={[MEDIC, PATIENT]}
                            withoutReturnUrl
                        />

                        <AuthRoute
                            exact
                            path={HELP_PAGE}
                            component={withLayout(HelpPage)}
                            roles={[MEDIC]}
                        />

                        <AuthRoute
                            exact
                            path={PHONE_VERIFICATION_PAGE}
                            component={withLayout(PhoneVerificationPage)}
                            roles={[
                                PHONE_NUMBER_CONFIRMATION,
                                EMAIL_CONFIRMATION,
                                FORCED_PASSWORD_CHANGE,
                                MEDIC,
                                PATIENT,
                                NOT_CONFIRMED_USER,
                            ]}
                            withoutReturnUrl
                        />
                        <AuthRoute
                            exact
                            path={EMAIL_VERIFICATION_PAGE}
                            component={withLayout(EmailVerificationPage)}
                            roles={[
                                EMAIL_CONFIRMATION,
                                FORCED_PASSWORD_CHANGE,
                                MEDIC,
                                PATIENT,
                                NOT_CONFIRMED_USER,
                            ]}
                            withoutReturnUrl
                        />
                        <AuthRoute
                            exact
                            path={FORCED_PASSWORD_CHANGE_PAGE}
                            component={withLayout(ForcedPasswordChangePage)}
                            roles={[
                                FORCED_PASSWORD_CHANGE,
                                MEDIC,
                                PATIENT,
                                NOT_CONFIRMED_USER,
                            ]}
                            withoutReturnUrl
                        />
                        <AuthRoute
                            exact
                            path={SELECT_ROLE_PAGE}
                            component={SelectRolePage}
                            roles={[MEDIC, PATIENT]}
                            withoutReturnUrl
                        />
                        <AuthRoute
                            exact
                            path={PASSWORD_CHANGE_FINISH_PAGE}
                            component={withLayout(PasswordChangeFinishPage)}
                            roles={[MEDIC, PATIENT]}
                            withoutReturnUrl
                        />
                        <AuthRoute
                            path={USER_ACCOUNT_PAGE}
                            component={withLayout(UserAccountPage)}
                            roles={[MEDIC, PATIENT]}
                        />
                        <AuthRoute
                            exact
                            path={CHANGE_PASSWORD_PAGE}
                            component={withLayout(ChangePasswordPage)}
                            roles={[MEDIC, PATIENT]}
                        />
                        <AuthRoute
                            exact
                            path={CHANGE_PHONE_NUMBER_PAGE}
                            component={withLayout(ChangePhoneNumberPage)}
                            roles={[MEDIC, PATIENT]}
                        />
                        <AuthRoute
                            path={PHONE_NUMBER_CHANGED_PAGE}
                            roles={[PATIENT, MEDIC]}
                            component={withLayout(PhoneNumberChangedPage)}
                            exact
                            withoutReturnUrl
                        />
                        <AuthRoute
                            path={CHANGE_PHONE_NUMBER_VERIFICATION_PAGE}
                            roles={[PATIENT, MEDIC]}
                            component={withLayout(
                                ChangePhoneNumberVerificationPage,
                            )}
                            exact
                        />
                        <AuthRoute
                            path={MEDIC_SETTINGS}
                            roles={[MEDIC]}
                            component={withLayout(MedicSettingsPage)}
                        />
                        <AuthRoute
                            exact
                            path={MEDIC_CALENDAR}
                            roles={[MEDIC]}
                            component={withLayout(MedicCalendar)}
                        />
                        <AuthRoute
                            exact
                            path={MEDIC_CALENDAR_DATE_SELECTION}
                            roles={[MEDIC]}
                            component={withLayout(MedicCalendarDateSelection)}
                        />
                        <AuthRoute
                            path={MEDIC_CALENDAR_ADD_TIME_SLOT}
                            roles={[MEDIC]}
                            component={withLayout(
                                MedicCalendarAddTimeSlotsPage,
                            )}
                        />
                        <AuthRoute
                            path={PATIENT_APPOINTMENT_DETAILS_PAGE + '/:id'}
                            roles={[PATIENT]}
                            component={withLayout(
                                PatientAppointmentDetailsPage,
                            )}
                        />
                        <AuthRoute
                            path={MEDIC_APPOINTMENT_DETAILS_PAGE + '/:id'}
                            roles={[MEDIC]}
                            component={withLayout(MedicAppointmentDetailsPage)}
                        />
                        <AuthRoute
                            path={PATIENT_REFERRAL_TO_MEDIC_PAGE}
                            roles={[MEDIC]}
                            component={ReferPatientToSpecialistPage}
                        />
                        <AuthRoute
                            path={PATIENT_REFERRAL_FOR_MEDICAL_TESTS_PAGE}
                            roles={[MEDIC]}
                            component={PatientReferralForMedicalTestsPage}
                        />
                        <AuthRoute
                            path={PATIENT_REFERRAL_SUCCESSFULLY_MADE_PAGE}
                            roles={[MEDIC]}
                            component={PatientReferralSuccessfullyMadePage}
                        />
                        <AuthRoute
                            path={ORDER_MEDICAL_TESTS_PAGE}
                            roles={[PATIENT]}
                            component={OrderMedicalTestsPage}
                        />
                        <AuthRoute
                            path={MEDICAL_TESTS_ORDER_PAYMENT_PAGE}
                            roles={[PATIENT]}
                            component={MedicalTestsOrderPaymentPage}
                        />
                        <AuthRoute
                            path={MEDICAL_TESTS_SUCCESSFULLY_ORDERED_PAGE}
                            roles={[PATIENT]}
                            component={withLayout(
                                MedicalTestsSuccessfullyOrderedPage,
                            )}
                            withoutReturnUrl
                        />
                        <AuthRoute
                            path={
                                MEDICAL_TESTS_SUCCESSFULLY_ORDERED_WITHOUT_PAYMENT_PAGE
                            }
                            roles={[PATIENT]}
                            component={withLayout(
                                MedicalTestsSuccessfullyOrderedWithoutPaymentPage,
                            )}
                            withoutReturnUrl
                        />
                        <AuthRoute
                            path={VIDEO_PAGE + '/:consultationId'}
                            roles={[MEDIC, PATIENT]}
                            component={VideoPage}
                        />
                        <AuthRoute
                            path={CHAT_PAGE + '/:consultationId'}
                            roles={[MEDIC, PATIENT]}
                            component={ChatPage}
                        />
                        <AuthRoute
                            path={CALL_PAGE + '/:consultationId'}
                            roles={[MEDIC]}
                            component={CallPage}
                        />
                        <AuthRoute
                            path={TIME_SLOT_PAYMENT_PAGE}
                            roles={[PATIENT]}
                            component={TimeSlotPaymentPage}
                        />
                        <AuthRoute
                            path={APPOINTMENT_PAYMENT_CONTINUE}
                            roles={[PATIENT]}
                            component={withLayout(
                                AppointmentPaymentContinuePage,
                            )}
                        />
                        <AuthRoute
                            path={SUBSCRIPTION_PAYMENT_PAGE}
                            roles={[PATIENT]}
                        >
                            <PayUSecureFormsProvider>
                                <SubscriptionPaymentPage />
                            </PayUSecureFormsProvider>
                        </AuthRoute>
                        <AuthRoute
                            path={SUBSCRIPTION_PAYMENT_CONTINUE}
                            roles={[PATIENT]}
                            component={withLayout(
                                SubscriptionPaymentContinuePage,
                            )}
                        />
                        <AuthRoute
                            path={QUESTIONNAIRE_PAGE}
                            roles={[PATIENT]}
                            component={QuestionnairePage}
                        />
                        <AuthRoute
                            path={EVALUATION_PAGE + '/:appointmentId'}
                            roles={[PATIENT]}
                            component={EvaluationPage}
                        />
                        <AuthRoute
                            path={EVALUATION_FINISH}
                            roles={[PATIENT]}
                            component={EvaluationFinishPage}
                        />
                        <AuthRoute
                            exact
                            roles={[PATIENT]}
                            path={FIND_TIME_SLOTS}
                            component={withLayout(FindTimeSlotsPage)}
                        />
                        <AuthRoute
                            exact
                            roles={[PATIENT]}
                            path={FIND_OCCUPATIONAL_MEDICINE_TIMESLOTS}
                            component={withLayout(
                                FindOccupationalMedicineTimeSlotsPage,
                            )}
                        />
                        <AuthRoute
                            path={MEDICAL_TEST_RESULT_PAGE + '/:id'}
                            roles={[PATIENT]}
                            component={withLayout(MedicalTestResultDetailsPage)}
                        />
                        <AuthRoute
                            path={MEDICAL_TEST_RESULTS_PAGE}
                            roles={[PATIENT]}
                            component={withLayout(MedicalTestResultsPage)}
                        />
                        <AuthRoute
                            roles={[MEDIC]}
                            path={NEW_PRESCRIPTION_PAGE + '/:appointmentId'}
                            component={NewPrescriptionPage}
                        />
                        <AuthRoute
                            roles={[MEDIC]}
                            path={PRESCRIPTION_ISSUED_PAGE}
                            component={withLayout(PrescriptionIssuedPage)}
                        />
                        <AuthRoute
                            roles={[PATIENT]}
                            path={CHANGE_ADDRESS_PAGE}
                            component={withLayout(ChangeAddressPage)}
                        />
                        <AuthRoute
                            roles={[PATIENT]}
                            path={DECLARATION_OF_PUBLIC_INSURANCE_PAGE}
                            component={withLayout(
                                DeclarationOfPublicInsurancePage,
                            )}
                        />
                        <AuthRoute
                            roles={[PATIENT]}
                            path={CONFIRMED_IDENTITY}
                            component={withLayout(ConfirmIdentityPage)}
                        />

                        <Route
                            exact
                            path={INDIVIDUAL_PACKAGE_PAGE + '/:id'}
                            component={() => (
                                <PayUSecureFormsProvider>
                                    <IndividualPackagePage />
                                </PayUSecureFormsProvider>
                            )}
                        />
                        <AuthRoute
                            roles={[MEDIC]}
                            path={SICK_LEAVE_ISSUED_PAGE}
                            component={withLayout(SickLeaveIssuedPage)}
                        />
                        <AuthRoute
                            roles={[MEDIC]}
                            path={NEW_SICK_LEAVE_PAGE + '/:appointmentId'}
                            component={NewSickleavePage}
                        />

                        <AuthRoute
                            roles={[PATIENT]}
                            path={SICK_LEAVES + '/:seriesAndNumber'}
                            component={withLayout(SickLeaveDetailsPage)}
                        />
                        <AuthRoute
                            roles={[MEDIC]}
                            path={SICK_LEAVE_CANCELLED_PAGE}
                            component={withLayout(SickLeaveCancelledPage)}
                        />

                        <AuthRoute
                            roles={[MEDIC, PATIENT]}
                            path={OCCUPATIONAL_MEDICINE_PAGE}
                            component={OccupationalMedicinePage}
                        />

                        <AuthRoute
                            roles={[MEDIC]}
                            path={OCCUPATIONAL_MEDICINE_PAGE_FILLED}
                            component={withLayout(
                                OccupationalMedicineFilledPage,
                            )}
                        />
                        <Redirect from={'/'} to={ERROR_404_PAGE} />
                    </Switch>
                </AlertContextProvider>
            </PickersProvider>
        </ThemeProvider>
    );
};

export default App;
