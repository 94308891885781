import {
    makeStyles,
    createStyles,
    Theme,
    useMediaQuery,
    Select,
    SelectProps,
    FormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';
import {ReactNode} from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            height: 48,
        },
    }),
);

const CustomSelectField = ({
    className,
    children,
    error,
    helperText,
    ...props
}: SelectProps & {helperText?: ReactNode}) => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const classes = useStyles();

    return (
        <>
            <Select
                inputProps={{
                    className: clsx([classes.container, className]),
                }}
                variant="outlined"
                error={error}
                {...props}
                fullWidth={!isDesktop}
            >
                {children}
            </Select>
            <FormHelperText
                variant="outlined"
                error={error}
                style={{width: isDesktop ? '272px' : '100%'}}
            >
                {helperText}
            </FormHelperText>
        </>
    );
};

export default CustomSelectField;
