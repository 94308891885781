import {
    CaseReducer,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {LoadingStatus} from '../const/loadingStatus';
import AppointmentsProvider from '../services/appointmentsProvider';
import {RootState} from './store';
import {Service, Specialty} from '../types/timeSlot';
import {DateTime} from 'luxon';
import {MEDICAL_SERVICE} from '../const/event';
import {getDateIntervalByCurrentDate} from '../utils/date';

const sliceName = 'timeSlotsSearch';

const initialState = {
    selectedTimeSlotType: MEDICAL_SERVICE as string,
    selectedOptionId: null as number,
    servicesOptions: [] as Service[],
    specialtiesOptions: [] as Specialty[],
    currentDateTime: DateTime.local() as DateTime,
    startDateTime: null as DateTime,
    endDateTime: null as DateTime,
    status: 'idle' as LoadingStatus,
};

export type TimeSlotsSearchSliceState = typeof initialState;

const selectedDateTimeSpecifiedReducer: CaseReducer<
    TimeSlotsSearchSliceState,
    PayloadAction<{startDateTime: DateTime; endDateTime: DateTime}>
> = (state, action) => {
    const {startDateTime, endDateTime} = action.payload;
    state.startDateTime = startDateTime;
    state.endDateTime = endDateTime;
};

const selectedOptionIdSpecifiedReducer: CaseReducer<
    TimeSlotsSearchSliceState,
    PayloadAction<{optionId: number}>
> = (state, action) => {
    const {optionId} = action.payload;
    state.selectedOptionId = optionId;
};

export const selectedTimeSlotTypeSpecifiedReducer: CaseReducer<
    TimeSlotsSearchSliceState,
    PayloadAction<{selectedTimeSlotType: string}>
> = (state, action) => {
    const {selectedTimeSlotType} = action.payload;
    state.selectedTimeSlotType = selectedTimeSlotType;
    state.selectedOptionId =
        selectedTimeSlotType === MEDICAL_SERVICE
            ? state.servicesOptions[0]?.id
            : state.specialtiesOptions[0]?.id;
};

export const initializeOptions = createAsyncThunk<
    {
        servicesOptions: Service[];
        specialtiesOptions: Specialty[];
        currentDateTime: DateTime;
    },
    void,
    {state: RootState}
>(`${sliceName}/initializeOptions`, async () => {
    const {currentDateTime, specialties, medicalServices} =
        await AppointmentsProvider.getInitialization();
    return {
        currentDateTime: DateTime.fromISO(currentDateTime),
        servicesOptions: medicalServices,
        specialtiesOptions: specialties,
    };
});

const timeSlotsSearchSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        selectedDateTimeSpecified: selectedDateTimeSpecifiedReducer,
        selectedOptionIdSpecified: selectedOptionIdSpecifiedReducer,
        selectedTimeSlotTypeSpecified: selectedTimeSlotTypeSpecifiedReducer,
    },
    extraReducers(builder) {
        builder
            .addCase(initializeOptions.pending, state => {
                state.status = 'loading';
            })
            .addCase(initializeOptions.fulfilled, (state, action) => {
                const {currentDateTime, servicesOptions, specialtiesOptions} =
                    action.payload;
                state.currentDateTime = currentDateTime;
                state.servicesOptions = servicesOptions;
                state.specialtiesOptions = specialtiesOptions;
                state.selectedOptionId =
                    state.selectedOptionId || servicesOptions[0]?.id;
                const dateInterval =
                    getDateIntervalByCurrentDate(currentDateTime);
                state.startDateTime = dateInterval.startDateTime;
                state.endDateTime = dateInterval.endDateTime;
                state.status = 'loaded';
            })
            .addCase(initializeOptions.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const actions = timeSlotsSearchSlice.actions;

export const selectSelectedOptionId = (state: RootState) =>
    state.timeSlotsSearch.selectedOptionId;
export const selectStartDateTime = (state: RootState) =>
    state.timeSlotsSearch.startDateTime;
export const selectEndDateTime = (state: RootState) =>
    state.timeSlotsSearch.endDateTime;
export const selectTimeSlotsSearchStatus = (state: RootState) =>
    state.timeSlotsSearch.status;
export const selectTimeSlotsSelectedType = (state: RootState) =>
    state.timeSlotsSearch.selectedTimeSlotType;
export const selectCurrentDateTime = (state: RootState) =>
    state.timeSlotsSearch.currentDateTime;
export const selectSpecialtiesOptions = (state: RootState) =>
    state.timeSlotsSearch.specialtiesOptions;
export const selectServicesOptions = (state: RootState) =>
    state.timeSlotsSearch.servicesOptions;
export const selectSelectedOption = (state: RootState) =>
    state.timeSlotsSearch.selectedTimeSlotType === MEDICAL_SERVICE
        ? state.timeSlotsSearch.servicesOptions.find(
              (option: Service) =>
                  option.id === state.timeSlotsSearch.selectedOptionId,
          )
        : state.timeSlotsSearch.specialtiesOptions.find(
              (option: Specialty) =>
                  option.id === state.timeSlotsSearch.selectedOptionId,
          );

export const {
    selectedDateTimeSpecified,
    selectedOptionIdSpecified,
    selectedTimeSlotTypeSpecified,
} = timeSlotsSearchSlice.actions;

export default timeSlotsSearchSlice.reducer;
