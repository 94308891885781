import {ChangeEvent, useState} from 'react';
import {CircularProgress, Grid, TextField} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';
import {isNull, sortBy, uniqBy} from "lodash-es";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import {useAppDispatch, useAppSelector} from "../../../../hooks/customReduxHooks";
import {
    fetchTags,
    selectMedicTags,
    selectNewMedicTags,
    selectTags,
    selectTagsStatus,
    setNewMedicTags
} from "../../../../store/medicProfileSlice";
import {isNotNull} from "../../../../utils/lang";
import {CustomButton} from '../../../index';
import useStyles from './AddTagStyles';
import {Tag} from "../../../../types/medic";

const AddTag = () => {
    const classes = useStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t: patientPackagesT} = useTranslation(i18nNamespaces.PATIENT_PACKAGES);
    const dispatch = useAppDispatch();

    const tags = useAppSelector(selectTags);
    const tagsStatus = useAppSelector(selectTagsStatus);

    const medicTags = useAppSelector(selectMedicTags);
    const newMedicTags = useAppSelector(selectNewMedicTags);

    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<Tag>(null);

    const autocompleteOpenHandler = () => {
        setOpen(true);
        const tagsNotInitialized = isNull(tags);
        if (tagsNotInitialized) {
            dispatch(fetchTags());
        }
    }

    const autocompleteChangeHandler = (_: ChangeEvent<unknown>, value: string | Tag, reason: string) => {
        if (reason === 'select-option') {
            setSelectedOption(value as Tag);
        }
        if (reason === 'clear') {
            setSelectedOption(null);
        }
    }

    const addTagButtonClickHandler = () => {
        const medicTagsInUse = isNotNull(newMedicTags)
            ? newMedicTags
            : medicTags;
        const newMedicTagsUniq = uniqBy<Tag>(
            [...medicTagsInUse, selectedOption],
            'id'
        );
        dispatch(
            setNewMedicTags(
                sortBy(newMedicTagsUniq, 'name')
            )
        );
        setSelectedOption(null);
    }

    const loading = open && tagsStatus === 'loading';

    return (
        <Grid container>
            <Grid
                className={classes.autocompleteWrapper}
                item
            >
                <Autocomplete
                    classes={{
                        popupIndicator: classes.autocompleteArrow,
                        inputRoot: classes.autocompleteRoot
                    }}
                    open={open}
                    onOpen={autocompleteOpenHandler}
                    onClose={() => {
                        setOpen(false);
                    }}
                    value={selectedOption}
                    options={tags || []}
                    getOptionLabel={(option) => option.name}
                    loading={loading}
                    onChange={autocompleteChangeHandler}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={
                                <span className={classes.autocompleteLabel}>
                                    {commonT('selectOrSearch')}
                                </span>
                            }
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {
                                            loading
                                                ? <CircularProgress color="primary" size={20} />
                                                : null
                                        }
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid
                item
                className={classes.addTagButtonWrapper}
            >
                <CustomButton
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={addTagButtonClickHandler}
                    disabled={!selectedOption}
                >
                    {patientPackagesT('add')}
                </CustomButton>
            </Grid>
        </Grid>
    )
}

export default AddTag;