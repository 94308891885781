import {createStyles, makeStyles} from '@material-ui/core';
import { LIGHT_GRAY } from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            backgroundColor:LIGHT_GRAY,
            paddingTop: 36, 
            paddingBottom:36,
        },
        title: {fontSize: 40},
        seeAll:{
            paddingTop:36
        },
        list:{
        }
    }),
);

export default useStyles;
