import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {GRAY, LIGHT_DISABLED} from "../../../const/colors";

const textsCommonStyles = {
    size: 16,
    lineHeight: '24px',
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        margin: '0',
        fontSize: '16px',
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            lineHeight: '32px',
        },
    },
    dateLabel: {
        color: GRAY,
    },
    listItem: {
        paddingLeft: 0,
        lineHeight:'25px'
    },
    status:{
        ...textsCommonStyles,
        color:GRAY
    },
    link: {
        margin: '0',
        color: theme.palette.secondary.main,
        fontSize: 16,
        lineHeight: '24px',
        textAlign: 'end',
        textDecoration: 'none',
        overflowWrap: 'anywhere',
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('md')]: {
            marginRight:'16px'
        },
    },
}));

export default useStyles;