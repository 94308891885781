import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        padding: '8px 16px',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerSquare: {
        width: '110px',
        height: '64px',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    timeValueContainer: {
        fontWeight: 'bold',
    },
    timeValueContainerSquare: {
        fontSize: '24px',
    }
}));

export default useStyles;