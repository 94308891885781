import {createContext, useContext} from 'react';
import {OrderMedicalTestsState} from "./useOrderMedicalTests";

export const OrderMedicalTestsContext = createContext<OrderMedicalTestsState>(undefined);

export const useDiagnosticRegionsContext = () => {
    const context = useContext(OrderMedicalTestsContext);

    // if `undefined`, then throw an error
    if (context === undefined) {
        throw new Error('OrderMedicalTestsContext was used outside of its Provider');
    }

    return context;
};