import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: mobile.contentLargerSpacing,
        },
        title: {fontSize: 36, fontWeight: 'bold', marginBottom: 30},
        subtitle: {marginBottom: 56},
        image: {
            height: 200,
            marginBottom: 38,
        },
        [theme.breakpoints.up('md')]: {
            image: {
                height: 240,
            },
        },
    }),
);

export default useStyles;
