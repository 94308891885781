import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import ConversationProvider from '../../services/ConversationProvider';
import {consultationErrorHandler, mediaErrorHandler} from '../../utils/error';
import {useHistory, useParams} from 'react-router';
import {Call, Device} from '@twilio/voice-sdk';
import CallConversation from '../../components/conversations/call/Call/CallConversation';
import Settings from '../../components/conversations/settings/Settings';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    audioInputDevicesSpecified,
    audioOutputDevicesSpecified,
    selectedAudioInputDeviceIdSpecified,
    selectSelectedAudioInputDeviceId,
    selectedAudioOutputDeviceIdSpecified,
    selectSelectedAudioOutputDeviceId,
    selectDevice,
    deviceSpecified,
    selectAudioInputDevices,
    selectAudioOutputDevices,
    audioInputOnSpecified,
    selectAudioInputOn,
    selectBrowserAudioOutputCapabilitiesSupported,
    selectBrowserMultipleAudioInputDevicesSupported,
    selectAppointmentDetails,
    callStatusSpecified,
    selectCallStatus,
    selectConsultationStatus,
} from '../../store/consultationSlice';
import TopBar from '../../components/Layout/TopBar/TopBar';
import {
    Box,
    IconButton,
    Snackbar,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import useStyles from './CallPageStyles';
import {Alert} from '@material-ui/lab';
import {Loading} from '../../components';
import {LIGHT_DISABLED} from '../../const/colors';
import {
    getMedicFullName,
    getPatientFullName,
    getAppointmentDetailsPageUrlById,
} from '../../utils/appointment';
import {MediaWarnings, mediaWarningHandler} from '../../utils/warning';
import {DISCONNECTED} from '../../const/conversation';
import {selectAuthUserData} from '../../store/auth';
import {MEDIC} from '../../const/auth';
import {AxiosError} from 'axios';
import {CustomInputChangeEvent} from '../../types/common';
import {
    useAllPatientDetailsForMedicLoading,
    useAppointmentDetailsFetchForOngoingConsultation, useHangoutAppointmentByStatus,
} from '../../hooks/appointmentDetailsHooks';

const CallPage = () => {
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);
    const [callOperationsLoading, setCallOperationsLoading] =
        useState<boolean>(false);
    const [error, setError] = useState<string>(null);
    const [warning, setWarning] = useState<string>(null);
    const {consultationId} = useParams<{consultationId: string}>();
    const consultationDetails = useAppSelector(selectAppointmentDetails);
    const [call, setCall] = useState<Call>(null);
    const dispatch = useAppDispatch();
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const status = useAppSelector(selectCallStatus);
    const authUserData = useAppSelector(selectAuthUserData);

    const device = useAppSelector(selectDevice);
    const selectedAudioInputDeviceId = useAppSelector(
        selectSelectedAudioInputDeviceId,
    );

    const selectedAudioOutputDeviceId = useAppSelector(
        selectSelectedAudioOutputDeviceId,
    );

    const audioInputDevices = useAppSelector(selectAudioInputDevices);
    const audioOutputDevices = useAppSelector(selectAudioOutputDevices);
    const audioInputOn = useAppSelector(selectAudioInputOn);
    const browserAudioOutputCapabilitiesSupported = useAppSelector(
        selectBrowserAudioOutputCapabilitiesSupported,
    );

    const browserMultipleAudioInputDevicesSupported = useAppSelector(
        selectBrowserMultipleAudioInputDevicesSupported,
    );

    useAppointmentDetailsFetchForOngoingConsultation(
        consultationId,
        consultationDetails,
    );

    const consultationStatus = useAppSelector(selectConsultationStatus);
    const allPatientDetailsForMedicLoading =
        useAllPatientDetailsForMedicLoading();
    const loading =
        callOperationsLoading ||
        consultationStatus === 'loading' ||
        allPatientDetailsForMedicLoading;

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const history = useHistory();
    const classes = useStyles();

    const gotMediaSetupError = (error: Error) => {
        setCallOperationsLoading(false);
        setError(t(mediaErrorHandler(error.name)));
    };

    const gotApiError = (error: AxiosError) => {
        setCallOperationsLoading(false);
        setError(t(consultationErrorHandler(error.response.data[0])));
    };

    const handleAudioInputDevice = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const devices = mediaDevices.filter(x => x.kind === 'audioinput');
            dispatch(audioInputDevicesSpecified(devices));
        },
        [dispatch],
    );

    const handleAudioOutputDevice = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const devices = mediaDevices.filter(x => x.kind === 'audiooutput');
            dispatch(audioOutputDevicesSpecified(devices));
        },
        [dispatch],
    );

    const setupMediaDevices = () => {
        const constraints = {
            audio: {
                deviceId: selectedAudioInputDeviceId
                    ? {exact: selectedAudioInputDeviceId}
                    : undefined,
            },
        };
        return navigator.mediaDevices.getUserMedia(constraints);
    };

    const gotAudioStream = () => {
        return ConversationProvider.getAudioInputDevices();
    };

    useEffect(() => {
        const mediaStream = setupMediaDevices();
        mediaStream
            .then(gotAudioStream)
            .then(handleAudioInputDevice)
            .catch(gotMediaSetupError);
        return () => {
            mediaStream.then((stream: MediaStream) => {
                stream.getTracks().forEach((track: MediaStreamTrack) => {
                    track.stop();
                });
            });
        };
    }, [handleAudioInputDevice]);

    useEffect(() => {
        const mediaStream = setupMediaDevices();
        mediaStream
            .then(gotAudioStream)
            .then(handleAudioOutputDevice)
            .catch(gotMediaSetupError);
        return () => {
            mediaStream.then((stream: MediaStream) => {
                stream.getTracks().forEach((track: MediaStreamTrack) => {
                    track.stop();
                });
            });
        };
    }, [handleAudioOutputDevice]);

    useEffect(() => {
        if (!browserMultipleAudioInputDevicesSupported)
            setWarning(
                t(
                    mediaWarningHandler(
                        MediaWarnings.AudioInputSelectInBrowserNotSupported,
                    ),
                ),
            );
    }, [browserMultipleAudioInputDevicesSupported, t]);

    useEffect(() => {
        const mediaStream = setupMediaDevices();

        return () => {
            mediaStream.then((stream: MediaStream) => {
                stream.getTracks().forEach((track: MediaStreamTrack) => {
                    track.stop();
                });
            });
        };
    }, [selectedAudioInputDeviceId]);

    const setAudioDevices = () => {
        if (device) {
            if (browserAudioOutputCapabilitiesSupported)
                device.audio.speakerDevices
                    .set(selectedAudioOutputDeviceId)
                    .catch(() => {
                        console.warn(
                            'Twilio set sink id error. Try change autio output settings.',
                        );
                    });

            if (browserMultipleAudioInputDevicesSupported) {
                device.audio.setInputDevice(selectedAudioInputDeviceId);
            }
        }
    };

    const handleStart = () => {
        setCallOperationsLoading(true);
        setError(null);
        dispatch(callStatusSpecified(null));
        ConversationProvider.getCallToken(consultationId)
            .then(res => {
                const currentDevice = new Device(res.data, {
                    codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
                });

                dispatch(deviceSpecified(currentDevice));

                const params = {appointmentId: consultationId};

                currentDevice.connect({params}).then((currentCall: Call) => {
                    dispatch(audioInputOnSpecified(true)); // starting call with a unmuted option is required
                    setCall(currentCall);
                });
                setCallOperationsLoading(false);
            })

            .catch(gotApiError);
    };

    const handleUpdate = () => {
        setAudioDevices();
        setShowSettings(false);
    };

    const handleSelectAudioInput = (e: CustomInputChangeEvent) => {
        dispatch(selectedAudioInputDeviceIdSpecified(e.target.value as string));
    };

    const handleSelectAudioOutput = (e: CustomInputChangeEvent) => {
        dispatch(
            selectedAudioOutputDeviceIdSpecified(e.target.value as string),
        );
    };

    const handleAudioTurnOnOff = () => {
        if (call) {
            call.mute(audioInputOn);
        }
        dispatch(audioInputOnSpecified(!audioInputOn));
    };

    const handleHangout = () => {
        if (call) {
            call.disconnect();
            setCall(null);
            
            if (device && device.audio)
                device.audio.unsetInputDevice();
            device.removeAllListeners();
            dispatch(deviceSpecified(null));

            device.destroy();

            history.push(
                getAppointmentDetailsPageUrlById(
                    consultationId,
                    authUserData.role === 'Medic',
                ),
            );
        }
    };

    useHangoutAppointmentByStatus(consultationId, handleHangout);

    return (
        <div>
            {call && status !== DISCONNECTED ? (
                <CallConversation
                    consultationId={consultationId}
                    call={call}
                    handleHangout={handleHangout}
                    handleUpdate={handleUpdate}
                    setShowSettings={setShowSettings}
                    handleAudioTurnOnOff={handleAudioTurnOnOff}
                    handleSelectAudioInput={handleSelectAudioInput}
                    handleSelectAudioOutput={handleSelectAudioOutput}
                    showSettings={showSettings}
                    audioInputOn={audioInputOn}
                    setAudioDevices={setAudioDevices}
                />
            ) : (
                <>
                    <TopBar>
                        <IconButton onClick={() => history.goBack()}>
                            <Close />
                        </IconButton>
                    </TopBar>
                    <Box className={classes.settingsContainer}>
                        {audioInputDevices && audioOutputDevices ? (
                            <>
                                {!isDesktop && consultationDetails && (
                                    <Box className={classes.participantName}>
                                        <Box fontWeight="bold">
                                            {authUserData?.role === MEDIC
                                                ? getPatientFullName(
                                                      consultationDetails.patient,
                                                  )
                                                : getMedicFullName(
                                                      consultationDetails.medic,
                                                  )}
                                        </Box>
                                        <Box color={LIGHT_DISABLED}>
                                            {'  '} - godz.
                                            {consultationDetails.startDate.toFormat(
                                                'HH:mm',
                                            )}
                                        </Box>
                                    </Box>
                                )}
                                <Settings
                                    approve={handleStart}
                                    handleSelectAudioInput={
                                        handleSelectAudioInput
                                    }
                                    handleSelectAudioOutput={
                                        handleSelectAudioOutput
                                    }
                                    handleAudioTurnOnOff={handleAudioTurnOnOff}
                                    error={error}
                                />
                            </>
                        ) : (
                            <Loading loading withBackdrop />
                        )}
                    </Box>
                </>
            )}
            <Snackbar
                open={!!warning}
                autoHideDuration={5000}
                onClose={() => setWarning('')}
            >
                <Alert onClose={() => setWarning('')} severity="warning">
                    {warning}
                </Alert>
            </Snackbar>
            <Loading withBackdrop loading={loading} />
        </div>
    );
};

export default CallPage;
