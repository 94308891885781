import {makeStyles, Theme} from "@material-ui/core";
import {LIGHT_GRAY, MAGENTA, TEXT_PRIMARY} from "../../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    autocompleteWrapper: {
        flex: '1 0 230px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 330,
        }
    },
    addTagButtonWrapper: {
        flex: '0 0 auto',
        width: 88,
        marginLeft: 8,
        [theme.breakpoints.up('sm')]: {
            width: 96,
            marginLeft: 15,
        }
    },
    autocompleteRoot: {
        border: LIGHT_GRAY,
    },
    autocompleteLabel: {
        color: TEXT_PRIMARY,
    },
    autocompleteArrow: {
        color: MAGENTA,
    }
}));

export default useStyles;