import {VALIDATE_QB_CARD} from './individualPackageEndpoints';
import {rawHttp} from '../../services/http';
import {QbCardOwner, ValidateQbCardDto} from '../../types/individualPackages';

class IndividualPackageApi {
    validateQbCard(data: ValidateQbCardDto) {
        return rawHttp.post<QbCardOwner>(VALIDATE_QB_CARD, data);
    }
}

export const individualPackageApi = new IndividualPackageApi();
