import React, {useEffect, useRef, useState} from 'react';
import {Autocomplete} from '@material-ui/lab';
import {mapMedicinalProductForAutocomplete} from '../../../utils/prescription';
import {CircularProgress, TextField, useTheme} from '@material-ui/core';
import {MedicinalProductDto} from '../../../types/prescription';
import prescriptionsApi from '../../../api/prescriptions/prescriptionsApi';
import {debounce} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

const DEBOUNCE_TIME = 1000;
interface MedicinalProductAutocompleteProps {
    onChange: (newValue: string | MedicinalProductDto) => void;
    medicinalProduct: MedicinalProductDto | null;
    error?: boolean;
    helperText?: React.ReactNode;
}

const MedicinalProductAutocomplete = (
    props: MedicinalProductAutocompleteProps,
) => {
    const {onChange, medicinalProduct, error, helperText} = props;
    const [medicinalProductInputValue, setMedicinalProductInputValue] =
        useState<string>('');
    const [medicinalProductsLoading, setMedicinalProductsLoading] =
        useState<boolean>(false);
    const [medicinalProducts, setMedicinalProducts] = useState<
        MedicinalProductDto[]
    >([]);
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const theme = useTheme();

    const fetchMedicinalProductsList = async (searchValue: string) => {
        try {
            const {data} = await prescriptionsApi.getMedicinalProductList(
                searchValue,
            );
            setMedicinalProducts(data.result);
        } catch (error) {
            console.error(error);
        } finally {
            setMedicinalProductsLoading(false);
        }
    };

    const debouncedSearch = useRef(
        debounce(async (search: string) => {
            try {
                setMedicinalProductsLoading(true);
                await fetchMedicinalProductsList(search);
            } finally {
                setMedicinalProductsLoading(false);
            }
        }, DEBOUNCE_TIME),
    ).current;

    useEffect(() => {
        debouncedSearch(medicinalProductInputValue);
    }, [medicinalProductInputValue]);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const medicinalProductRender = (option: MedicinalProductDto) => (
        <div
            style={{
                color: option.hasReimbursedPack
                    ? theme.palette.success.main
                    : 'initial',
            }}
        >
            {option.displayValue}
        </div>
    );

    const handleChangeMedicalProductInput = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setMedicinalProductInputValue(event.target.value);
    };

    return (
        <Autocomplete
            loading={medicinalProductsLoading}
            options={medicinalProducts?.map(mapMedicinalProductForAutocomplete)}
            getOptionSelected={option => option.id === medicinalProduct.id}
            getOptionLabel={option => option.displayValue}
            renderOption={medicinalProductRender}
            value={medicinalProduct}
            onChange={(_, newValue) => onChange(newValue)}
            renderInput={params => (
                <TextField
                    {...params}
                    onChange={handleChangeMedicalProductInput}
                    label={t('drugName')}
                    variant="outlined"
                    error={error}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            color: medicinalProduct?.hasReimbursedPack
                                ? theme.palette.success.main
                                : 'initial',
                        },
                        endAdornment: (
                            <React.Fragment>
                                {medicinalProductsLoading ? (
                                    <CircularProgress
                                        color="secondary"
                                        size={20}
                                        style={{marginRight: 10}}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default MedicinalProductAutocomplete;
