import {useCallback, useMemo} from 'react';
import {validate} from 'uuid';
import useConfirmationActions from './useConfirmationActions';
import appointmentsProvider from '../../services/appointmentsProvider';
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../const/i18nNamespaces";

const useCancelAppointment = () => {
    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const {
        modalOpen: cancelAppointmentModalOpen,
        openModal: openCancelAppointmentConfirmModal,
        closeModal: closeCancelAppointmentConfirmModal,
        actionLoading: cancelAppointmentLoading,
        actionHandler: cancelAppointmentActionHandler,
        actionLoadingError: cancelAppointmentError,
    } = useConfirmationActions();

    const cancelAppointmentAction = useCallback(
        async (upcomingAppointmentId: string) => {
            await cancelAppointmentActionHandler(async () => {
                    const {data: tokenAsCancelSuccess} =
                        await appointmentsProvider.cancelAppointment(
                            upcomingAppointmentId,
                        );
                    const tokenInvalid = !validate(tokenAsCancelSuccess);
                    if (tokenInvalid) {
                        throw new Error(
                            tErrors('tokenNotValid')
                        );
                    }
                }
            );
        },
        []
    );

    const value = useMemo(
        () => ({
            cancelAppointmentModalOpen,
            openCancelAppointmentConfirmModal,
            closeCancelAppointmentConfirmModal,
            cancelAppointmentAction,
            cancelAppointmentLoading,
            cancelAppointmentError,
        }),
        [
            cancelAppointmentModalOpen,
            openCancelAppointmentConfirmModal,
            closeCancelAppointmentConfirmModal,
            cancelAppointmentAction,
            cancelAppointmentLoading,
            cancelAppointmentError,
        ]
    );

    return value;
}

export default useCancelAppointment;