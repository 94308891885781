import {withStyles} from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

export const AppointmentsHistoryAccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        marginBottom: -1,
        minHeight: 'auto',
        '&$expanded': {
            minHeight: 'auto',
        },
        '&.Mui-focused': {
            backgroundColor: 'inherit',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'inherit',
        },
        '& .MuiAccordionSummary-content': {
            cursor: 'default',
        },
        '& .MuiAccordionSummary-expandIcon': {
            position: 'absolute',
            top: '8px',
            right: '16px',
            [theme.breakpoints.up('md')]: {
                right: '47px',
            }
        },
        padding: 0,
    },
    content: {
        margin: '0',
        padding: '16px 55px 16px 16px',
        [theme.breakpoints.up('md')]: {
            padding: '16px 86px 16px 47px',
        },
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);