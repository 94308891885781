import {Box, createStyles, makeStyles} from "@material-ui/core";
import {GRAY} from "../../../../const/colors";
import {AppointmentStateEnum} from "../../../../types/appointments";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import clsx from "clsx";

interface Props {
    state: AppointmentStateEnum,
    className?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        label: {
            display: 'inline-block',
            fontSize: 14,
            lineHeight: '24px',
            color: GRAY
        }
    }),
);

const AppointmentStateLabel = ({state, className}: Props) => {
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);
    const classes = useStyles();
    const getStatusValue = () => {
        switch (state) {
            case AppointmentStateEnum.Planned:
                return t('planned');
            case AppointmentStateEnum.InProgress:
                return t('inProgress');
            case AppointmentStateEnum.Completed:
                return t('completed');
            case AppointmentStateEnum.Canceled:
                return t('canceled');
            case AppointmentStateEnum.PatientHasNotConnected:
                return t('patientHasNotConnected');
            case AppointmentStateEnum.MedicHasNotConnected:
                return t('medicHasNotConnected');
        }
    }
    return (
        <Box
            component={'span'}
            className={
                clsx(classes.label, {
                    [className]: className
                })
            }
        >
            {getStatusValue()}
        </Box>
    )
}

export default AppointmentStateLabel;