import {useEffect, useState} from 'react';

import {DiagnosticServiceDto} from '../../types/referrals';
import {
    fetchDiagnosticServices,
    selectDiagnosticServiceArray,
} from '../../store/referrals/diagnosticServicesSlice';
import {useAppDispatch, useAppSelector} from '../customReduxHooks';

const useMedicalTests = (selectedGroupOfMedicalTests: string) => {
    const dispatch = useAppDispatch();

    const [medicalTests, setMedicalTests] = useState<DiagnosticServiceDto[]>(
        [],
    );
    const [loading, setLoading] = useState(false);

    const diagnosticServies = useAppSelector(selectDiagnosticServiceArray);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await dispatch(
                fetchDiagnosticServices(selectedGroupOfMedicalTests),
            );
            setLoading(false);
        })();
    }, [dispatch, setLoading]);

    useEffect(() => {
        setMedicalTests(diagnosticServies);
    }, [diagnosticServies]);

    return {
        medicalTests,
        medicalTestsLoading: loading,
    };
};

export default useMedicalTests;
