import {
    CaseReducer,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {RootState} from './store';
import {PatientPrescriptionHistoryDto} from '../types/prescription';
import PrescriptionsApi from '../api/prescriptions/prescriptionsApi';
import {AppointmentDetailsForPrescriptionDto} from '../types/appointments';
import {P1Response, P1ResponseWithPagination} from '../types/p1Service';
import PatientDataAccessApi from '../api/patientDataAccess/patientDataAccessApi';

const sliceName = 'patientPrescriptionHistory';
interface PrescriptionHistoryState {
    authCode: string;
    codeSent: boolean;
    applicationId: string;
    prescriptionHistory: PatientPrescriptionHistoryDto[];
    page: number;
    size: number;
    count: number;
    errorText: string;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: PrescriptionHistoryState = {
    authCode: '',
    codeSent: false,
    applicationId: '',
    prescriptionHistory: null,
    page: 0,
    size: 5,
    count: 0,
    errorText: '',
    status: 'idle',
};

export const fetchPrescriptionHistory = createAsyncThunk<
    P1ResponseWithPagination<PatientPrescriptionHistoryDto>,
    AppointmentDetailsForPrescriptionDto,
    {state: RootState}
>(
    `${sliceName}/fetchPrescriptionHistory`,
    async ({medic, patient, id}, thunkAPI) => {
        const {page, size} = thunkAPI.getState().patientPrescriptionHistory;
        const {data} = await PrescriptionsApi.getPatientPrescriptionHistory({
            appointmentId: id,
            medicId: medic.userId,
            medicNPWZ: medic.npwz,
            medicBusinessRole: medic.businessRole,
            patientId: patient.userId,
            patientPesel: patient.pesel,
            // appointmentId: 105,
            // patientId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            // medicId: '41eddbd7-a973-4ec5-b026-08da90cf61e7',
            // patientPesel: '70032816894',
            // medicNPWZ: '9543956',
            // medicBusinessRole: 'LEK',
            page,
            size,
        });
        if (data.isSuccess) {
            return data;
        }
        return null;
    },
);

export const authorizePatientDataAccess = createAsyncThunk<
    null,
    AppointmentDetailsForPrescriptionDto,
    {state: RootState}
>(
    `${sliceName}/authorizePatientDataAccess`,
    async ({medic, patient, id}, thunkAPI) => {
        const {applicationId, authCode} =
            thunkAPI.getState().patientPrescriptionHistory;
        await PatientDataAccessApi.authorizePatientDataAccess({
            applicationId,
            authCode,
            medicId: medic.userId,
            medicNPWZ: medic.npwz,
            medicBusinessRole: medic.businessRole,
            // "authCode": "0000",
            // medicId: '41eddbd7-a973-4ec5-b026-08da90cf61e7',
            // medicNPWZ: '9543956',
            // medicBusinessRole: 'LEK',
        });
        return null;
    },
);

export const applyForPatientDataAccess = createAsyncThunk<
    P1Response<string>,
    AppointmentDetailsForPrescriptionDto,
    {state: RootState}
>(
    `${sliceName}/applyForPatientDataAccess`,
    async ({medic, patient, id}, thunkAPI) => {
        const {data} = await PatientDataAccessApi.applyForPatientDataAccess({
            appointmentId: id,
            patientId: patient.userId,
            medicId: medic.userId,
            patientPesel: patient.pesel,
            medicNPWZ: medic.npwz,
            medicBusinessRole: medic.businessRole,
            // appointmentId: 105,
            // patientId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            // medicId: '41eddbd7-a973-4ec5-b026-08da90cf61e7',
            // patientPesel: '70032816894',
            // medicNPWZ: '9543956',
            // medicBusinessRole: 'LEK',
        });
        if (data.isSuccess) {
            return data;
        }
        return null;
    },
);

const authCodeSpecifiedReducer: CaseReducer<
    PrescriptionHistoryState,
    PayloadAction<string>
> = (state, action) => {
    state.authCode = action.payload;
};
const pageSpecifiedReducer: CaseReducer<
    PrescriptionHistoryState,
    PayloadAction<number>
> = (state, action) => {
    state.page = action.payload;
};

const sizeSpecifiedReducer: CaseReducer<
    PrescriptionHistoryState,
    PayloadAction<number>
> = (state, action) => {
    state.size = action.payload;
};

const patientPrescriptionHistory = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        pageSpecified: pageSpecifiedReducer,
        sizeSpecified: sizeSpecifiedReducer,
        authCodeSpecified: authCodeSpecifiedReducer,
    },
    extraReducers: builder => {
        builder
            .addCase(applyForPatientDataAccess.pending, state => {
                state.status = 'loading';
            })
            .addCase(applyForPatientDataAccess.fulfilled, (state, action) => {
                state.applicationId = action.payload.result;
                state.codeSent = true;
                state.status = 'idle';
            })
            .addCase(applyForPatientDataAccess.rejected, state => {
                state.status = 'failed';
            })
            .addCase(authorizePatientDataAccess.pending, state => {
                state.status = 'loading';
            })
            .addCase(authorizePatientDataAccess.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(authorizePatientDataAccess.rejected, state => {
                state.status = 'failed';
            })
            .addCase(fetchPrescriptionHistory.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchPrescriptionHistory.fulfilled, (state, action) => {
                state.prescriptionHistory = action.payload.results;
                state.page = action.payload.page;
                state.size = action.payload.size;
                state.count = action.payload.count;
                state.status = 'idle';
            })
            .addCase(fetchPrescriptionHistory.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const {pageSpecified, sizeSpecified, authCodeSpecified} =
    patientPrescriptionHistory.actions;

export const selectPrescriptionHistory = (state: RootState) =>
    state.patientPrescriptionHistory.prescriptionHistory;

export const selectPage = (state: RootState) =>
    state.patientPrescriptionHistory.page;

export const selectSize = (state: RootState) =>
    state.patientPrescriptionHistory.size;

export const selectCount = (state: RootState) =>
    state.patientPrescriptionHistory.count;

export const selectStatus = (state: RootState) =>
    state.patientPrescriptionHistory.status;

export const selectCodeSent = (state: RootState) =>
    state.patientPrescriptionHistory.codeSent;

export const selectErrorText = (state: RootState) =>
    state.patientPrescriptionHistory.errorText;

export default patientPrescriptionHistory.reducer;
