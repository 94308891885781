export const LANDING_PATHS = {
    PL: {
        SEARCH_FOR_A_DOCTOR: '#gt_search_section',
        NEWS: 'aktualnosci',
        INDIVIDUAL_PACKAGES: '#subscriptions_section',
        PRIVACY_POLICY: 'polityka-prywatnosci',
        CONTACT: 'kontakt',
        TERMS_AND_CONDITIONS: 'regulamin',
        ORGANIZATIONAL_REGULATIONS: 'regulamin-organizacyjny',
        QB_CARD: 'pakiet-mundurowy',
        UNILINK: 'unilink',
        SUBSCRIPTIONS: 'abonamenty'
    },
    EN: {
        SEARCH_FOR_A_DOCTOR: '#gt_search_section',
        NEWS: 'news',
        INDIVIDUAL_PACKAGES: '#subscriptions_section',
        PRIVACY_POLICY: 'privacy-policy',
        CONTACT: 'contact',
        TERMS_AND_CONDITIONS: 'terms-and-conditions',
        ORGANIZATIONAL_REGULATIONS: 'oragnizational-regulations',
        QB_CARD: 'pakiet-mundurowy',
        UNILINK: 'unilink',
        SUBSCRIPTIONS: 'abonamenty'
    },
};
