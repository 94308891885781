import {AxiosError} from 'axios';

export const logNetworkError = (error: AxiosError) =>
    console.error(error?.response?.data || error?.response || error);

export const registerErrorHandler = (code: string) => {
    switch (code) {
        case 'PeselTaken':
            return 'Pesel jest już zajęty';
        case 'EmailTaken':
            return 'Ten e-mail jest już zajęty';
        case 'InvalidPhone':
            return 'Niepoprawny numer telefonu';
        default:
            return 'Wystąpił błąd podczas rejestracji';
    }
};

export const mediaErrorHandler = (name: string) => {
    switch (name) {
        case 'NotAllowedError' || 'PermissionDeniedError':
            return 'permissionDeniedError';
        case 'VideoNotSupported':
            return 'VideoNotSupported';
        case 'NotReadableError' || 'TrackStartError':
            return 'videoNotReadableError';
        case 'NotFoundError' || 'DevicesNotFoundError':
            return 'videoNotFoundError';
        case 'OverconstrainedError' || 'ConstraintNotSatisfiedError':
            return 'videoOverconstrainedError';
        case 'AbortError':
            return 'videoAbortError';
        default:
            return 'defaultError';
    }
};

export const consultationErrorHandler = (name: string) => {
    switch (name) {
        case 'TooEarlyForAppointmentError':
            return 'tooEarlyForAppointmentError';
        case 'TooLateForAppointmentError':
            return 'tooLateForAppointmentError';
        case 'ParticipantIsNotAssignedToAppointment':
            return 'participantIsNotAssignedToAppointment';
        case 'AppointmentDoesNotExist':
            return 'appointmentDoesNotExist';
        case 'TokenSaveError':
            return 'tokenSaveError';
        case 'AddConversationError':
            return 'addConversationError';
        case 'JoinParticipantError':
            return 'joinParticipantError';
        default:
            return 'defaultError';
    }
};

export const callConnectionErrorHandler = (name: string) => {
    switch (name) {
        case 'AcquisitionFailedError':
            return 'acquisitionFailedError';
        case 'PermissionDeniedError':
            return 'permissionDeniedError';
        case 'ConnectionError':
            return 'connectionError';
        default:
            return 'defaultError';
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const uploadFileErrorHandler = (name: string) => {
    return 'uploadFileDefaultError';
};

export const patientPackagesErrorHandler = (name: string) => {
    switch (name) {
        case 'InvalidPatientGroupAssignmentMemberData':
            return 'badMemberDataError';
        case 'InvalidPatientGroupAssignmentMemberPesel':
            return 'invalidPeselError';
        case 'InvalidSubscriptionMemberPesel':
            return 'invalidPeselError';
        default:
            return 'defaultError';
    }
};

export const forgetPasswordErrorHandler = (name: string) => {
    switch (name) {
        case 'VerifyEmailError':
            return 'badEmail';
        case 'ResetPasswordError':
        default:
            return 'defaultError';
    }
};

export const changePasswordErrorHandler = (name: string) => {
    switch (name) {
        case 'VerifyEmailError':
            return 'badEmail';
        case 'BadPassword':
            return 'badPassword';
        case 'ResetPasswordError':
        default:
            return 'defaultError';
    }
};

export const removeTimeSlotErrorHandler = (name: string) => {
    switch (name) {
        case 'AppointmentCreated':
            return 'cannotRemoveTimeSlotAppointmentCreated';
        case 'TimeSlotReserved':
            return 'cannotRemoveTimeSlotReserved';
        default:
            return 'timeSlotRemovalDefaultError';
    }
};

export const changePhoneNumberErrorHandler = (name: string) => {
    switch (name) {
        case 'Invalid parameter: Code':
            return 'invalidCode';
        case 'Code verification status: pending. Code valid: False':
            return 'invalidCode';
        case 'ChangePhoneError':
            return 'defaultError';
        default:
            return 'defaultError';
    }
};

export const createAppointmentErrorHandler = (error: string) => {
    switch (error) {
        case 'TooLateForAppointmentPayment':
            return 'tooLateForAppointmentPayment';
        default:
            return 'appointmentPaymentErrorPleaseTryAgain';
    }
};

export const deleteMedicTimeslotsErrorHandler = (error: string) => {
    switch (error) {
        case 'TimeSlotReserved':
            return 'thereIsReservedTimeslot';
        default:
            return 'defaultError';
    }
};

export const verifySMSErrorHandler = (error: string) => {
    switch (error) {
        case 'VerifyPhoneError':
        case 'Error validating the code':
        default:
            return 'verifySMSCodeError';
    }
};

export const signInErrorHandler = (error: string) => {
    switch (error) {
        case 'INVALID_EMAIL_OR_PASSWORD':
            return 'invalidEmailOrPassword';
        case 'OTHER_ERROR':
        default:
            return 'loginError';
    }
};
