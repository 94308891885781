import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            marginTop: 60,
        },
        boldedCell: {
            fontSize: 20,
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        tableFooterCell: {
            fontWeight: 'bold',
            fontSize: 20,
        },
        buyButton: {
            width: 88,
            fontWeight: 'bold',
        },
        groupDescription: {
            fontSize: 14,
            borderBottom: 'none',
        },
    }),
);

export default useStyles;
