import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { BACKGROUND_SECONDARY, LIGHT_GRAY, WHITE } from '../../const/colors';

const useStyles = makeStyles((theme:Theme) =>
    createStyles({
        pageLeftColumn: {
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                minHeight: `calc(100vh - 128px)`
            }
        },
        goBackWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: 90,
            padding: `0 ${theme.spacing(1)}px`,
            borderBottom: `9px solid ${LIGHT_GRAY}`,
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                borderBottom: `1px solid ${LIGHT_GRAY}`,
                marginLeft: `${theme.spacing(3)}px`,
                marginRight: `${theme.spacing(3)}px`,
                padding: '0',
            },
        },
        goBackButton: {
            backgroundColor: BACKGROUND_SECONDARY,
            width: 40,
            height: 40,
            borderRadius: 20,
            display: 'flex',
            alignItems: 'center',
            marginRight: 17,
        },


    }),
);

export default useStyles;
