import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            cursor: 'pointer',
        },
        layout: {
            flexWrap: 'nowrap',
            alignItems: 'center',
        },
        layoutSecondChild: {
            flex: '1 1 auto',
            marginLeft: 16,
            [theme.breakpoints.up('sm')]: {
                marginLeft: 24,
            }
        },
        specialties: {
            fontSize: 16,
            lineHeight: '24px',
        }
    }),
);

export default useStyles;
