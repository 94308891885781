import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import {GRAY, MAGENTA} from "../../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    buttonsContainer: {
        padding: `24px ${theme.spacing(1)}px`,
        [theme.breakpoints.up('md')]: {
            padding: `24px 24px 24px ${theme.spacing(3)}px `,
        }
    },
    buttonsContainerItem: {
        // nth-child use is necessary, as there can be 2, 1 or none buttons displayed
        // depending on appointment state
        '&:nth-child(1)': {
            [theme.breakpoints.up('sm')]: {
                paddingRight: '12px',
            }
        },
        '&:nth-child(2)': {
            marginTop: '16px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '0',
                paddingLeft: '12px',
            }
        },
    },
    actionBtn: {
        width: '100%',
        height: '48px',
        lineHeight: 1.35,
    },
    rightActionBtn: {
        color: MAGENTA,
        border: `1px solid ${MAGENTA}`
    },
    cancelAppointmentMessage: {
        textAlign: 'center',
        padding: `0 ${theme.spacing(1)}px 16px`,
        color: GRAY,
        [theme.breakpoints.up('sm')]: {
            padding: `0 ${theme.spacing(3)}px 24px`,
        }
    }
}));

export default useStyles;