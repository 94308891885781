import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, GridSize } from "@material-ui/core";
import Label from "../common/Label/Label";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import CustomTextField from "../common/CustomTextField/CustomTextField";

export const RenderReturnNextButtons = (classes:any, t:any, onReturn:()=>void = null) =>(
    <Grid container>
        <Grid item xs={6}>
            <Button
                onClick={onReturn}
                className={classes.buttonReturn}
                variant={'outlined'}
                color={'secondary'}>
                {t('return')}
            </Button>
        </Grid>
        <Grid item xs={6}>
            <Button
                type="submit"
                className={classes.buttonNext}
                variant={'contained'}
                color={'secondary'}>
                {t('next')}
            </Button>
        </Grid>
    </Grid>
)

export const RenderCancelNextButton = (classes:any, t:any, onCancel:()=>void = null) => (
        <Grid container>
        <Grid item xs={6}>
            <Button
                className={classes.buttonReturn}
                variant={'outlined'}
                color={'secondary'}
                onClick={onCancel}>
                {t('cancel')}
            </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    type="submit"
                    className={classes.buttonNext}
                    variant={'contained'}
                    color={'secondary'}>
                    {t('add')}
                </Button>
            </Grid>
        </Grid>
)

export const RenderGridItemWithRequiredKeyboardDatePicker = 
    (classes:any,handleBlur:any, id:string, translation:string, value:ParsableDate, setFieldValue:any, touched:any, errors:any, errorsTranslation:string, maxDate=Date.now()) =>
(
    <Grid item xs={4}>
        <FormGroup>

        <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
            {translation} *
        </Box>
        {maxDate ? 
        <KeyboardDatePicker
            autoOk
            value={value}
            onChange={(v) => setFieldValue(id, v, true)}
            variant="inline"
            format="dd.MM.yyyy"
            inputVariant="outlined"
            fullWidth
            maxDate={maxDate}
            onBlur={handleBlur}
            helperText={
                touched && errorsTranslation
            }
            error={touched && !!errors}/> 
        :
            <KeyboardDatePicker
            autoOk
            value={value}
            onChange={(v) => setFieldValue(id, v, true)}
            variant="inline"
            format="dd.MM.yyyy"
            inputVariant="outlined"
            fullWidth
            onBlur={handleBlur}
            helperText={
                touched && errorsTranslation
            }
            error={touched && !!errors}/> 
            }
        </FormGroup>
    </Grid>
)

export const RenderGridItemKeyboardDatePicker = 
    (classes:any,handleBlur:any, id:string, translation:string, value:ParsableDate, setFieldValue:any) =>
(
    <Grid item xs={4}>
        <FormGroup>

        <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
            {translation} 
        </Box>
        <KeyboardDatePicker
            autoOk
            value={value}
            onChange={(v) => setFieldValue(id, v, true)}
            variant="inline"
            format="dd.MM.yyyy"
            inputVariant="outlined"
            fullWidth
            onBlur={handleBlur}/> 
        </FormGroup>
    </Grid>

)

export const RenderGridWithCheckBox =
     (xs:GridSize, id:string, checked:boolean, disabled:boolean, handleChange:any, classes:any, t:any) =>(
        <Grid item xs={xs}>
                <FormGroup>     
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={id}
                                name={id}
                                checked={checked}
                                onChange={handleChange}
                                disabled={disabled}
                            />
                        }
                        label={
                            <Box className={classes.text}>
                                {t}
                            </Box>
                        }     
                        />
                </FormGroup>
            </Grid>
)

export const RenderGridWithCommonView = 
(xs:GridSize, id:string, value:string, handleChange:any, handleBlur:any, classes:any, t:any) =>
(
    <Grid item xs={xs}>
    <FormGroup>
        <Label htmlFor={id}>{t}</Label>
        <CustomTextField 
                fullWidth 
                id={id}
                name={id}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                />
    </FormGroup>
</Grid>
)

export const RenderGridWithCommonViewRequired = 
(xs:GridSize, id:string, value:string, handleChange:any, handleBlur:any, classes:any, t:any, touched:any, errors:any, errorTranslation:any) =>
(
    <Grid item xs={xs}>
    <FormGroup>
        <Label htmlFor={id}>{t} *</Label>
        <CustomTextField 
                fullWidth 
                id={id}
                name={id}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                helperText={touched && errorTranslation}
                error={touched && !!errors}
                />
    </FormGroup>
</Grid>
)


export const RenderGridWithCommonViewWithTitle = 
(xs:GridSize, id:string, value:string, handleChange:any, handleBlur:any, classes:any, t:any) =>
(
    <Grid item xs={xs}>
    <FormGroup>
            <Box className={classes.titleContainer + ' ' + classes.sectionHeader + ' '+ classes.label}>
                {t}
            </Box>
        <CustomTextField 
                fullWidth 
                id={id}
                name={id}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                />
    </FormGroup>
</Grid>
)

export const RenderGridWithRequiredCommonViewWithTitle = 
(xs:GridSize, id:string, value:string, handleChange:any, handleBlur:any, classes:any, t:any, touched:any, errors:any, errorTranslation:any) =>
(
    <Grid item xs={xs}>
    <FormGroup>
            <Box className={classes.titleContainer + ' ' + classes.sectionHeader + ' '+ classes.label}>
                {t} *
            </Box>
        <CustomTextField 
                fullWidth 
                id={id}
                name={id}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                helperText={touched && errorTranslation}
                error={touched && !!errors}
                />
    </FormGroup>
</Grid>
)