import Chat from '../Chat/Chat';
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Loading} from '../../..';
import {Conversation} from '@twilio/conversations/lib/conversation';
import useChatEventListener from '../../../../hooks/conversation/useChatEventListener';

type Props = {
    loading?: boolean;
    conversation: Conversation;
    error?: string;
    setError: (error: string) => void;
};

const ChatConversation = ({loading, conversation, error, setError}: Props) => (
    <>
        {loading || !conversation ? (
            <Loading loading withBackdrop />
        ) : (
            <Chat conversation={conversation} />
        )}
        <Snackbar
            open={!!error}
            autoHideDuration={5000}
            onClose={() => setError('')}
        >
            <Alert onClose={() => setError('')} severity="error">
                {error}
            </Alert>
        </Snackbar>
    </>
);

export default ChatConversation;
