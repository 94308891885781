export const INITIALIZE = '/api/initialize';
export const EVENTS = '/api/events';
export const EVENTS_HTTP = '/api/events/getList';
export const REFRESH_TOKEN = '/api/account/refresh';
export const REGISTER = '/api/account/register';
export const ACCOUNT = '/api/account';
export const EMAIL_CODE = '/api/account/emailCode';
export const SMS_CODE = '/api/account/smsCode';
export const PASSWORD = '/api/account/password';
export const VIDEO_TOKEN = (id: string) =>
    `api/appointments/${id}/video-chat-token`;
export const CHAT_TOKEN = (id: string) => `api/appointments/${id}/chat-token`;
export const JOIN_CHAT = (id: string) => `api/appointments/${id}/chat`;
export const CALL_TOKEN = (id: string) => `api/appointments/${id}/call-token`;
export const DAY_SCHEDULE_TEMPLATES = '/api/medics/dayScheduleTemplates';
export const MEDICS = '/api/medics';
export const ICD10 = '/api/icd10';
export const ICD10_SIMPLE = '/api/icd10/simple';
export const SET_MEDIC_PHOTO = '/api/photos';
export const SET_MEDIC_MAIN_PHOTO = `${SET_MEDIC_PHOTO}/setMain`;
export const TAGS = '/api/tags';
export const MEDIC_TAGS = '/api/tags/medic';
export const MEDICS_LAST_DAY_OF_CALENDAR = MEDICS + '/lastDayOfCalendar';
export const MEDIC_CALENDAR = `${MEDICS}/calendar`;
export const APPOINTMENTS = '/api/appointments';
export const CANCEL_APPOINTMENT = (id: string) =>
    `/api/appointments/${id}/cancel`;
export const REMOVE_APPOINTMENT = (id: string) =>
    `/api/appointments/${id}/remove`;
export const ADDITIONAL_PATIENT_APPOINTMENT_DETAILS = (id: string) =>
    `/api/appointments/${id}/additional-patient-details`;
export const ADDITIONAL_MEDIC_APPOINTMENT_DETAILS = (id: string) =>
    `/api/appointments/${id}/additional-medic-details`;
export const GET_ALL_ATTACHMENTS_META_DATA = (id: string) =>
    `/api/appointments/${id}/attachments-metadata`;
export const GET_ATTACHMENTS_META_DATA_WITHOUT_PRESCRIPTIONS = (id: string) =>
    `/api/appointments/${id}/attachments-metadata?prescription=false`;
export const GET_PRESCRIPTIONS_META_DATA = (id: string) =>
    `/api/appointments/${id}/attachments-metadata?prescription=true`;
export const UPLOAD_APPOINTMENT_ATTACHMENTS = (id: string) =>
    `/api/appointments/${id}/attachment`;
export const REMOVE_DOWNLOAD_APPOINTMENT_ATTACHMENT = (
    appointmentId: string,
    fileId: string,
) => `/api/appointments/${appointmentId}/attachments/${fileId}`;
export const GET_QUESTIONNAIRE_DETAILS = (appointmentId: string) =>
    `/api/appointments/${appointmentId}/patient-questionnaire-details`;
export const MEDIC_EVENTS = 'api/medics/events';
export const MEDICAL_SERVICE_PURCHASE_OPTIONS = (id: number) =>
    `/api/medicalservices/${id}/purchaseoptions`;
export const MEDICAL_SERVICE_PURCHASE_OPTIONS_FOR_CHILD = (
    id: number,
    childId: number,
    referralId: number,
) =>
    `/api/medicalservices/${id}/purchaseoptions?childId=${childId}&referralId=${referralId}`;
export const MEDICAL_SERVICE_PURCHASE_OPTIONS_WITH_DISCOUNT_CODE = (
    id: number,
    code: string,
) => `/api/medicalservices/${id}/discount-codes/${code}/purchaseoptions`;
export const APPOINTMENT_PURCHASE_OPTIONS = (id: number) =>
    `/api/appointments/${id}/purchaseoptions`;

export const MEDICAL_SERVICE = (id: number) =>
    `api/medicalservices/${id}/simple`;
export const MEDICAL_SERVICES_SIMPLE = 'api/medicalservices/simple';
export const TIME_SLOTS_REQUESTS = '/api/timeslots/requests';
export const TIME_SLOTS = '/api/timeslots';
export const PATIENT_REFERRAL_MEDICS = '/api/medics/referral';
export const REFERRALS = '/api/referrals';
export const DIAGNOSTIC_SERVICES = '/api/diagnosticservices';
export const DIAGNOSTIC_SERVICE_TYPES = `${DIAGNOSTIC_SERVICES}/types`;
export const MEDICAL_PACKAGES = '/api/MedicalPackages';
export const MEDICAL_PACKAGES_FOR_PATIENT = (appointmentId: number) =>
    `/api/MedicalPackages?appointmentId=${appointmentId}`;
export const DIAGNOSTIC_SERVICES_FOR_MEDIC_PACKAGE = (packageId: number) =>
    `${MEDICAL_PACKAGES}/${packageId}`;
export const DIAGNOSTIC_ORDER_HEADERS = '/api/diagnosticOrderHeaders';
export const ORDERED_DIAGNOSTIC_ORDER_HEADERS =
    '/api/diagnosticOrderHeaders/ordered';
export const OCCUPATIONAL_MEDICINE_REFERRALS =
    '/api/OccupationalMedicineReferrals';
export const DIAGNOSTIC_REGIONS = '/api/regions';
export const TIME_SLOT = (id: string) => `/api/timeslots/${id}`;
export const TIME_SLOTS_RESERVED = (id: string) =>
    `${TIME_SLOTS}/${id}/reserved`;
export const HUB_EVENTS = '/hub/events';
export const INDIVIDUAL_PACKAGES = '/api/individualpackageswithservices';
export const TIME_SLOT_APPOINTMENTS = (id: string) =>
    `/api/timeslots/${id}/appointments`;
export const INDIVIDUAL_PACKAGE_INSTANCE = (id: number) =>
    `/api/individualpackages/${id}/instance`;
export const INDIVIDUAL_PACKAGES_SUBSCRIPTIONS = (id: number) =>
    `/api/individualpackages/${id}/subscriptions`;
export const APPOINTMENT_QUESTIONNAIRE = (appointmentId: string) =>
    `/api/appointments/${appointmentId}/questionnaire`;
export const PATIENT_PACKAGES = '/api/patient/packages';
export const CANCEL_SUBSCRIPTION = (id: number) =>
    `/api/subscriptions/${id}/cancelled`;
export const ADD_SUBSCRIPTION_MEMBER = (id: number) =>
    `/api/subscriptions/${id}/members`;
export const REMOVE_SUBSCRIPTION_MEMBER = (id: number, pesel: string) =>
    `/api/subscriptions/${id}/members/${pesel}`;
export const ADD_GROUP_MEMBER = (id: number) =>
    `/api/patientgroupassignments/${id}/members`;
export const REMOVE_GROUP_MEMBER = (id: number, pesel: string) =>
    `/api/patientgroupassignments/${id}/members/${pesel}`;
export const ISSUE_PRESCRIPTION_CONFIGURATION_URL =
    '/api/configurationparameters/issue-prescription-url';
export const ISSUE_SICK_LEAVE_CONFIGURATION_URL =
    '/api/configurationparameters/issue-sick-leave-url';
export const IDENTITY_CONFIRMATION_REQUEST =
    '/api/account/identityconfirmationrequest';
export const CONFIRMED_IDENTITY = '/api/account/confirmedidentity';
export const CONFIRM_IDENTITY_SIMPLY = '/api/account/confirmIdentitySimply';
export const REGISTER_WITH_CONFIRMED_IDENTITY =
    '/api/account/withconfirmedidentity';
export const CONFIRMED_IDENTITY_WITH_MOJE_ID = '/api/account/confirmIdentity';
export const EVALUATION_FORM = (appointmentId: string) =>
    `/api/appointments/${appointmentId}/evaluation-form`;
export const EVALUATION = (appointmentId: string) =>
    `/api/appointments/${appointmentId}/evaluation`;
export const CREATE_MEDIC_TIME_SLOTS = '/api/medic-time-slots';
export const DELETE_MEDIC_TIME_SLOT_REQUEST = (timeSlotId: string) =>
    `/api/medic-time-slots/${timeSlotId}/deleteRequests`;
export const DELETE_MEDIC_TIME_SLOT_RESPONSE = (token: string) =>
    `/api/medic-time-slot-delete-requests/${token}`;
export const RESET_PASSWORD = '/api/account/resetPassword';
export const ADD_PATIENT_TO_QUEUE = '/api/patientsQueue/requests';
export const WORKING_TIME_SETTLEMENTS = '/api/workingTimeSettlements';
export const YEARS_FOR_WORKING_TIME_SETTLEMENTS = `${WORKING_TIME_SETTLEMENTS}/settlement-years`;
export const CHANGE_PASSWORD = '/api/account/password';
export const CHANGE_PHONE_NUMBER = '/api/account/phone';
export const PATIENT_MEDICS = `/api/PatientMedics`;
export const REMOVE_PATIENT_MEDIC = (
    medicId: string,
    medicalServiceId: number,
) => `/api/PatientMedics/${medicId}/medicalservices/${medicalServiceId}`;
export const UPDATE_PAYMENT = (subscriptionId: number) =>
    `/api/Subscriptions/${subscriptionId}/update-payment`;
export const PATIENTS_CHILDREN = `/api/patient/children`;
export const SUBSCRIPTION_PAYMENT_HISTORY = (id: number) =>
    `/api/subscriptions/${id}/history`;
export const GET_ARTICLES_LIST = `/api/knowledgeBase`;
export const GET_SIMPLIFIED_ARTICLE = (id: string) =>
    `/api/knowledgeBase/simplified/${id}`;
export const GET_ARTICLE = (id: string) => `/api/knowledgeBase/${id}`;
export const PAY_AGAIN = (id: number) => `/api/appointments/${id}/pay-again`;
export const ARTICLE_ACCESS = `/api/patient/articles-access`;
export const GET_MEDICAL_TEST_PDF_RESULT = (id: string) =>
    `/api/diagnosticOrderHeaders/ordered/${id}/results`;
export const DEFAULT_BUYER_DATA = `/api/patient/buyerData`;
export const MEDIC_INSTRUCTION = `/api/instructions/medic`;
export const FILES = `api/files`;
export const TERMS_AND_CONDITIONS_FILE = `${FILES}/terms-and-conditions-pdf`;
export const WITHDRAWAL_FORM_PDF_FILE = `${FILES}/withdrawal-form-pdf`;
export const WITHDRAWAL_FORM_PDF_DOC = `${FILES}/withdrawal-form-doc`;
export const GET_APPOINTMENTS_REFERRALS = (id: string) =>
    `${APPOINTMENTS}/${id}/referrals`;
export const MEDICAL_SERVICE_DEFAULT_DURATION =
    '/api/configurationparameters/medical-service-default-duration';
export const OUR_SPECIALISTS = `${MEDICS}/our-specialists`;
export const PRESCRIPTION_APPOINTMENT_ID =
    '/api/configurationparameters/prescription-appointment-id';
export const SICK_LEAVE_APPOINTMENT_ID =
    '/api/configurationparameters/sick-leave-appointment-id';
export const GENERAL_PRACTITIONER_APPOINTMENT_ID =
    '/api/configurationparameters/general-practitioner-appointment-id';
export const PEDIATRIC_APPOINTMENT_ID =
    '/api/configurationparameters/pediatric-appointment-id';
export const LANGUAGES = '/api/languages';
export const GET_PAP_TEST_COLLECTED_FROM = `${DIAGNOSTIC_SERVICES}/pap-test-collected-from`;
export const RESELLER_CODE_VALIDATION = '/api/reseller/validate';
export const PATIENT_CONTACT_DATA = '/api/patient/contactData';
export const getAppointmentDetailsForPrescriptionEndpoint = (id: string) =>
    `/api/appointments/${id}/details4prescription`;
export const PATIENT_ADDRESS = '/api/patient/homeAddress';
export const PATIENT_DIAGNOSTIC_ORDERS = (id: string) =>
    `/api/patient/${id}/diagnostic-orders`;
export const PATIENT_PERSONAL_DATA_STATUS = '/api/patient/personal-data';
export const SET_MEDIC_STAMP = '/api/medics/stamp';
export const OCCUPATIONAL_MEDICINE = (id: string) =>
    `api/occupationalMedicine/${id}`;
export const APPOINTMENT_OCCUPATIONAL_MEDICINE = (id: string) =>
    `api/appointments/${id}/occupational-medicine`;
export const APPOINTMENT_OCCUPATIONAL_MEDICINE_DATA = (id: string) =>
    `api/appointments/${id}/occupational-medicine-data`;
export const PAYMENT_STATUS = (id: string) =>
    `/api/subscriptions/${id}/payment-status`;
export const REFERRAL_PDF_URL = (id: number) =>
    `/api/referrals/${id}/pdf`;
export const DIAGNOSTIC_ORDER_PDF_URL = (id: string) =>
    `/api/diagnosticOrderHeaders/${id}/pdf`;
