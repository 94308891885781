import {Box, Grid} from '@material-ui/core';
import clsx from 'clsx';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    PayUCvvSecureForm,
    PayUDateSecureForm,
    PayUNumberSecureForm,
} from '../../common/payUSecureForms';
import useStyles from './SubscriptionPaymentInputStyles';

type SubscriptionPaymentInputProps = {
    input:
        | typeof PayUNumberSecureForm
        | typeof PayUDateSecureForm
        | typeof PayUCvvSecureForm;
    label: string;
    requiredCheckForced?: boolean;
};

const SubscriptionPaymentInput = ({
    label,
    input: Input,
    requiredCheckForced,
}: SubscriptionPaymentInputProps) => {
    const [focused, setFocused] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);

    const {t} = useTranslation(i18nNamespaces.PAYU);

    const handleBlur = useCallback(() => {
        setFocused(false);
        setTouched(true);
    }, []);

    const handleChange = useCallback((body: payu.SecureFormChangeResponse) => {
        if (body.error) {
            const [errorMessage] = body.error;
            setError(t(errorMessage.code));
        } else {
            setError(null);
        }
    }, []);

    const handleFocus = useCallback(() => setFocused(true), []);

    const classes = useStyles();

    const errorMessage =
        (touched && error) ||
        (!touched && requiredCheckForced && t('requiredField'));

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Box className={classes.label}>{label}</Box>
            <Input
                className={clsx(
                    classes.input,
                    focused && classes.focusedInput,
                    errorMessage && classes.inputWithError,
                )}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
            ></Input>
            {errorMessage && (
                <Box className={classes.error}>{errorMessage}</Box>
            )}
        </Grid>
    );
};

export default SubscriptionPaymentInput;
