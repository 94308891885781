import {Box, FormGroup, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {useFormik} from 'formik';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import {ChangePhoneNumber} from '../../assets';
import {CustomButton, Label, Loading, PhoneNumberField} from '../../components';
import i18nNamespaces from '../../const/i18nNamespaces';
import {CHANGE_PHONE_NUMBER_VERIFICATION_PAGE} from '../../const/routes';
import {changePhoneNumberSchema} from '../../validators/PhoneNumber';
import useStyles from './ChangePhoneNumberPageStyles';
import {AxiosError} from 'axios';
import accountProvider from '../../services/accountProvider';

type PhoneChangeFormValues = {
    phoneNumber: string;
};

const ChangePhoneNumberPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.SETTINGS);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const history = useHistory();

    const handleError = (error: AxiosError | unknown) => {
        console.error(error);
        setError(t('invalidCodeTryAgain'));
    };

    const onSubmit = async (value: PhoneChangeFormValues) => {
        try {
            setLoading(true);
            await accountProvider.sendSMSCodeToChangePhoneNumber(
                value.phoneNumber,
            );
            history.push(CHANGE_PHONE_NUMBER_VERIFICATION_PAGE, {
                phone: value.phoneNumber,
            });
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };

    const {handleChange, handleBlur, handleSubmit, values, touched, errors} =
        useFormik({
            initialValues: {
                phoneNumber: '',
            },
            validationSchema: changePhoneNumberSchema,
            onSubmit,
        });

    const handlePhoneNumberChange = (value: string) =>
        handleChange({
            target: {
                value,
                id: 'phoneNumber',
                name: 'phoneNumber',
            },
        });

    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <Box className={classes.title}>{t('changeOfPhoneNumber')}</Box>
            <Grid container direction={isDesktop ? 'row' : 'column'}>
                <Grid item>
                    <FormGroup className={classes.formField}>
                        <Label htmlFor="enterNewPhoneNumber">
                            {t('enterNewPhoneNumber')}
                        </Label>
                        <PhoneNumberField
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handlePhoneNumberChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            helperText={
                                touched.phoneNumber && authT(errors.phoneNumber)
                            }
                            error={touched.phoneNumber && !!errors.phoneNumber}
                            defaultCountry="PL"
                            fullWidth={!isDesktop}
                        />
                    </FormGroup>
                    <Box className={classes.error}>{authT(error)}</Box>
                    <CustomButton
                        variant={'contained'}
                        color={'secondary'}
                        className={classes.submitButton}
                        type={'submit'}
                        fullWidth={!isDesktop}
                    >
                        {commonT('next')}
                    </CustomButton>
                </Grid>
                <Grid item container xs justifyContent={'center'}>
                    <img
                        className={classes.image}
                        src={ChangePhoneNumber}
                        alt={'Change phone number'}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default ChangePhoneNumberPage;
