import {CSSProperties, ReactNode} from "react";
import {Box, createStyles, makeStyles, Theme} from "@material-ui/core";
import clsx from "clsx";
import {WHITE} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 16,
            boxShadow: 'none',
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                padding: '24px 47px',
            },
        },
    }),
);

interface CommonProps {
    className?: string;
    style?: CSSProperties
    children: ReactNode;
}

const PageSectionCard = (props: CommonProps) => {
    const {className, children, style} = props;
    const classes = useStyles();
    return (
        <Box
            className={
                clsx(
                    classes.root,
                    className,
                )
            }
            style={style}
        >
            {children}
        </Box>
    );
};

PageSectionCard.Header = function Header(props: CommonProps) {
    const {className, children, style} = props;
    return (
        <Box
            className={className}
            style={style}
        >
            {children}
        </Box>
    );
}

PageSectionCard.Content = function Content(props: CommonProps) {
    const {className, children, style} = props;
    return (
        <Box
            className={className}
            style={style}
        >
            {children}
        </Box>
    );
}

export default PageSectionCard;