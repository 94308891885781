import { DateTime } from "luxon";
import { PatientOrderedDiagnosticOrderHeaders, PatientOrderedDiagnosticOrderHeadersDto } from "../../services/diagnostic/orderHeadersProvider";

const mapOrderedDiagnosticOrderHeader = (orderedDiagnosticOrderHeader: PatientOrderedDiagnosticOrderHeadersDto)
    : PatientOrderedDiagnosticOrderHeaders =>
    ({
        id: orderedDiagnosticOrderHeader.id,
        type: orderedDiagnosticOrderHeader.type,
        status:orderedDiagnosticOrderHeader.status,
        laboratoryAddress:orderedDiagnosticOrderHeader.laboratoryAddress,
        laboratoryCity:orderedDiagnosticOrderHeader.laboratoryCity,
        orderDateTime:DateTime.fromISO(orderedDiagnosticOrderHeader.orderDateTime),      
        patientName:orderedDiagnosticOrderHeader.patientName,
        patientSecondName:orderedDiagnosticOrderHeader.patientSecondName,
        patientSurname:orderedDiagnosticOrderHeader.patientSurname,
        zipCode:orderedDiagnosticOrderHeader.zipCode,
        contactEmail:orderedDiagnosticOrderHeader.contactEmail,
        contactPhoneNumber:orderedDiagnosticOrderHeader.contactPhoneNumber,
        medicalPackageName:orderedDiagnosticOrderHeader.medicalPackageName
    });

export default mapOrderedDiagnosticOrderHeader;