import {DateTime} from 'luxon';
import {AppointmentPurchaseType} from '../const/appointmentPurchaseType';
import {BuyerDataDto} from './buyerData';
import {TimeWindowDates} from './timeSlot';
import {ICD10Code} from './icd10';
import {TranslationsDto} from './translations';
import {PatientForPrescription} from './patient';

export type AppointmentAdditionalInformation = string | null;

export enum AppointmentStateEnum {
    Planned = 'Planned',
    InProgress = 'InProgress',
    Canceled = 'Canceled',
    Completed = 'Completed',
    PatientHasNotConnected = 'PatientHasNotConnected',
    MedicHasNotConnected = 'MedicHasNotConnected',
}

export enum AppointmentTypeEnum {
    Phone = 'Phone',
    Video = 'Video',
    Chat = 'Chat',
}

export enum AppointmentGroupEnum {
    GeneralAppointment = 'GeneralAppointment',
    AdultAppointment = 'AdultAppointment',
    PediatricAppointment = 'PediatricAppointment',
    OccupationalMedicineAppointment = 'OccupationalMedicineAppointment',
}

export enum AttachmentTypeEnum {
    OMReferral = 'OMReferral',
    OMHealthDeclaration = 'OMHealthDeclaration',
    OMPreventiveExaminationCard = 'OMPreventiveExaminationCard',
    OMCertificate = 'OMCertificate',
}

export type AppointmentState = {
    isSettled: boolean | null;
    state: AppointmentStateEnum;
};

type UserConnectedToConsultation = {
    connected: boolean;
};

export type AppointmentDto = {
    id: string;
    startDate: string;
    endDate: string;
    medic: Medic;
    medicConnected: boolean;
    patient: Patient;
    patientConnected: boolean;
    child: Child;
    medicalService: {
        id: number;
        name: string;
        translations: TranslationsDto[] | null;
    };
    patientReasons: AppointmentAdditionalInformation;
    drugMedicalRecommendations: AppointmentAdditionalInformation;
    referralMedicalRecommendations: AppointmentAdditionalInformation;
    otherMedicalRecommendations: AppointmentAdditionalInformation;
    medicalInterview: AppointmentAdditionalInformation;
    type: AppointmentConsultationType;
    paymentStatus: AppointmentPaymentStatus;
    state: AppointmentState;
    canCancelWithRefund: boolean;
    currentDateTime?: string;
    icD10Code: string | null;
    icD10TextEn: string | null;
    icD10TextPl: string | null;
    recommendationIsAvailable: boolean;
    chatTranscriptionIsAvailable: boolean;
    appointmentGroup: AppointmentGroupEnum;
    isOccupationalMedicineDocumentationCompleted?: boolean;
    bookedByAgent:boolean;
};

export type Appointment = {
    id: string;
    startDate: DateTime;
    endDate: DateTime;
    medic: Medic & UserConnectedToConsultation;
    patient: Patient & UserConnectedToConsultation;
    child: Child;
    medicalService: {
        name: string;
        id: number;
        translations: TranslationsDto[] | null;
    };
    patientReasons: AppointmentAdditionalInformation;
    drugMedicalRecommendations: AppointmentAdditionalInformation;
    referralMedicalRecommendations: AppointmentAdditionalInformation;
    otherMedicalRecommendations: AppointmentAdditionalInformation;
    medicalInterview: AppointmentAdditionalInformation;
    type: AppointmentConsultationType;
    paymentStatus: AppointmentPaymentStatus;
    state: AppointmentState;
    canCancelWithRefund: boolean;
    icd10Code: ICD10Code;
    recommendationIsAvailable: boolean;
    timeInMinutesToCompleteAppointmentBeforeEnd?: number;
    chatTranscriptionIsAvailable: boolean;
    appointmentGroup: AppointmentGroupEnum;
    isOccupationalMedicineDocumentationCompleted?: boolean;
    bookedByAgent:boolean;
};

export interface AppointmentChangingData {
    state: AppointmentState;
    paymentStatus: AppointmentPaymentStatus;
    medicConnected: boolean;
    patientConnected: boolean;
    patientReasons: string;
    drugMedicalRecommendations: string;
    referralMedicalRecommendations: string;
    otherMedicalRecommendations: string;
    medicalInterview: string;
    canCancelWithRefund: boolean;
    recommendationIsAvailable: boolean;
    chatTranscriptionIsAvailable: boolean;
}

export type AppointmentConsultationType = 'Video' | 'Phone' | 'Chat';

export type AppointmentPaymentStatus =
    | 'Paid'
    | 'Pending'
    | 'Unpaid'
    | 'Canceled';

export type Medic = {
    userId: string;
    firstName: string;
    surname: string;
    title: string;
    photoUrl: string;
    bio?: string;
    reviewCount: number;
    reviewRate: number;
};

export type Patient = {
    firstName: string;
    surname: string;
    userId: string;
    childId?: number;
    pesel?: string;
    phoneNumber?: string;
};

export type Child = {
    childId: number;
    firstName: string;
    surname: string;
    pesel?: string;
};

export type NewAppointmentDto = {
    type: string;
    payment: AppointmentPaymentDto;
    timeWindow?: TimeWindowDates;
    childId?: number;
    discountCodeId?: number;
    referralId?: number;
    occupationalMedicineReferralId?: number;
};

export type AppointmentPaymentDto = {
    type: AppointmentPurchaseType;
    buyerData: BuyerDataDto;
    individualPackageDraftId?: number;
    patientGroupDraftId?: number;
};

export type AppointmentCreationResultDto = {
    appointmentId: number;
    paymentUrl: string;
};

export type UploadAttachmentData = {
    fileName: string;
    isPrescription: boolean;
    contentType: string;
    content: string;
    attachmentType?: AttachmentTypeEnum;
};

export type AttachmentDto = {
    id: number;
    storageId: string;
    fileName: string;
    contentType: string;
    content: string;
    appointmentId: number;
    uploadDate: string;
    personId: string;
    person: string;
    thumbnail: string | null;
};

export type Attachment = {
    id: number;
    storageId: string;
    fileName: string;
    contentType: string;
    content: string;
    appointmentId: number;
    uploadDate: DateTime;
    personId: string;
    person: string;
    thumbnail: string | null;
    attachmentType?: AttachmentTypeEnum;
    downloadDateTimeByPatient?: string;
};

export type QuestionnaireDto = {
    bodyHeight?: number;
    bodyWeight?: number;
    age?: number;
    questionnaireFieldEntries: QuestionnaireFieldEntry[];
    updateRequired: boolean;
};

export type QuestionnaireFieldEntry = {
    value?: boolean;
    detailsValue?: string;
    questionnaireFieldDefinition: {
        id: number;
        group: number;
        order: number;
        questionText: string;
        defaultValue: boolean;
        details: boolean;
        detailsText?: number;
    };
};

export interface QuestionnaireDetails {
    bodyHeight?: number;
    bodyWeight?: number;
    gender?: number;
    age?: number;
    patientPesel: string;
    childPesel: string;
    phoneNumber?: string;
    questionnaireAnswers: Array<{
        group: number;
        questionText: string;
        value: boolean;
        detailsQuestionText: string | null;
        detailsValue: string | null;
    }>;
}

export type AppointmentDetailsForPrescriptionDto = {
    id: number;
    medic: {
        npwz: string;
        businessRole: string;
        userId: string;
        firstName: string;
        surname: string;
    };
    patient: PatientForPrescription;
    userId: string;
    firstName: string;
    surname: string;
    child?: Child;
};

export type OccupationalMedicineData = {
    id: number;
    preventiveExaminationType: string;
    preventiveExaminationUnderCare: string;
    filledByMedic: boolean;
    filledByPatient: boolean;
    number: string;
};
