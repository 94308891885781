import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import namespaces from '../const/i18nNamespaces';

import enCommon from './en/common.json';
import enAppointment from './en/appointment.json';
import enAvailabilityTemplate from './en/availabilityTemplate.json';
import enMedicCalendar from './en/medicCalendar.json';
import enConversations from './en/conversations.json';
import plCommon from './pl/common.json';
import plAppointment from './pl/appointment.json';
import plAvailabilityTemplate from './pl/availabilityTemplate.json';
import plMedicCalendar from './pl/medicCalendar.json';
import plConversations from './pl/conversations.json';
import plAuth from './pl/auth.json';
import enAuth from './en/auth.json';
import plTimeSlots from './pl/timeSlots.json';
import enTimeSlots from './en/timeSlots.json';
import plIndividualPackages from './pl/individualPackages.json';
import enIndividualPackages from './en/individualPackages.json';
import plTimeSlotsPayment from './pl/timeSlotPayment.json';
import enTimeSlotsPayment from './en/timeSlotPayment.json';
import plAppointmentPaymentContinue from './pl/appointmentPaymentContinue.json';
import enAppointmentPaymentContinue from './en/appointmentPaymentContinue.json';
import plAppointmentDetails from './pl/appointmentDetails.json';
import enAppointmentDetails from './en/appointmentDetails.json';
import plSubscriptionPayment from './pl/subscriptionPayment.json';
import enSubscriptionPayment from './en/subscriptionPayment.json';
import plSubscriptionPaymentContinue from './pl/subscriptionPaymentContinue.json';
import enSubscriptionPaymentContinue from './en/subscriptionPaymentContinue.json';
import plPatientPackages from './pl/patientPackages.json';
import enPatientPackages from './en/patientPackages.json';
import plQuestionnaire from './pl/questionnaire.json';
import enQuestionnaire from './en/questionnaire.json';
import plEvaluation from './pl/evaluation.json';
import enEvaluation from './en/evaluation.json';
import plReferrals from './pl/referrals.json';
import enReferrals from './en/referrals.json';
import plICD from './pl/icd.json';
import enICD from './en/icd.json';
import plErrors from './pl/errors.json';
import enErrors from './en/errors.json';
import plPayU from './pl/payU.json';
import enPayU from './en/payU.json';
import plArticles from './pl/articles.json';
import enArticles from './en/articles.json';
import plDiscountCode from './pl/discountCode.json';
import enDiscountCode from './en/discountCode.json';
import plSettings from './pl/settings.json';
import enSettings from './en/settings.json';
import plFooterContent from './pl/footerContent.json';
import enFooterContent from './en/footerContent.json';
import plMedicalTests from './pl/medicalTests.json';
import enMedicalTests from './en/medicalTests.json';
import plHelp from './pl/help.json';
import enHelp from './en/help.json';
import plMetaDescription from './pl/metaDescription.json';
import enMetaDescription from './en/metaDescription.json';
import plLandingPage from './pl/landingPage.json';
import enLandingPage from './en/landingPage.json';
import plMedic from './pl/medic.json';
import enMedic from './en/medic.json';
import plPrescriptions from './pl/prescriptions.json';
import enPrescriptions from './en/prescriptions.json';
import plAccount from './pl/account.json';
import enAccount from './en/account.json';
import plL4 from './pl/l4.json';
import enL4 from './en/l4.json';
import plOccupationalMedicine from './pl/occupationalMedicine.json';
import enOccupationalMedicine from './en/occupationalMedicine.json';


import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
    pl: {
        common: plCommon,
        appointment: plAppointment,
        availabilityTemplate: plAvailabilityTemplate,
        medicCalendar: plMedicCalendar,
        conversations: plConversations,
        auth: plAuth,
        timeSlots: plTimeSlots,
        individualPackages: plIndividualPackages,
        timeSlotsPayment: plTimeSlotsPayment,
        appointmentPaymentContinue: plAppointmentPaymentContinue,
        appointmentDetails: plAppointmentDetails,
        subscriptionPayment: plSubscriptionPayment,
        subscriptionPaymentContinue: plSubscriptionPaymentContinue,
        questionnaire: plQuestionnaire,
        patientPackages: plPatientPackages,
        evaluation: plEvaluation,
        referrals: plReferrals,
        icd: plICD,
        errors: plErrors,
        payU: plPayU,
        articles: plArticles,
        discountCode: plDiscountCode,
        settings: plSettings,
        footerContent: plFooterContent,
        medicalTests: plMedicalTests,
        help: plHelp,
        metaDescription: plMetaDescription,
        landingPage: plLandingPage,
        medic: plMedic,
        prescriptions: plPrescriptions,
        account:plAccount,
        l4:plL4,
        occupationalMedicine:plOccupationalMedicine
    },
    en: {
        common: enCommon,
        appointment: enAppointment,
        availabilityTemplate: enAvailabilityTemplate,
        medicCalendar: enMedicCalendar,
        conversations: enConversations,
        auth: enAuth,
        timeSlots: enTimeSlots,
        individualPackages: enIndividualPackages,
        timeSlotsPayment: enTimeSlotsPayment,
        appointmentPaymentContinue: enAppointmentPaymentContinue,
        appointmentDetails: enAppointmentDetails,
        subscriptionPayment: enSubscriptionPayment,
        subscriptionPaymentContinue: enSubscriptionPaymentContinue,
        questionnaire: enQuestionnaire,
        patientPackages: enPatientPackages,
        evaluation: enEvaluation,
        referrals: enReferrals,
        errors: enErrors,
        payU: enPayU,
        articles: enArticles,
        discountCode: enDiscountCode,
        icd: enICD,
        settings: enSettings,
        footerContent: enFooterContent,
        medicalTests: enMedicalTests,
        help: enHelp,
        metaDescription: enMetaDescription,
        landingPage: enLandingPage,
        medic: enMedic,
        prescriptions: enPrescriptions,
        account:enAccount,
        l4:enL4,
        occupationalMedicine:enOccupationalMedicine

    },
};

const i18next = i18n.use(LanguageDetector).use(initReactI18next);
i18next.init({
    fallbackLng: 'pl',
    // debug: true,
    interpolation: {
        escapeValue: false,
    },
    ns: [namespaces],
    defaultNS: [namespaces.COMMON],
    resources,
});

export default i18n;
