import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    container: {
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        margin: `auto ${theme.spacing(3)}px`,
    },
    input: {
        width: 300,

    },
    button: {
        width: 300,
    },
}));
