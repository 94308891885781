import {Typography, Box, Grid, Button, useMediaQuery, Theme} from '@material-ui/core';
import useStyles from './ArticleContentStyles';
import Loading from '../../common/Loading/Loading';
import {useEffect, useState} from 'react';
import articlesProvider from '../../../services/articlesProvider';
import {KnowledgeBaseDto} from '../../../types/articles';
import {useHistory, useParams} from 'react-router';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import { TIME_SLOTS_PAGE } from '../../../const/routes';
import { parseTimeSlotQuery } from '../../../utils/timeSlots';

const ArticleContent = () => {
    const classes = useStyles();
    const [article, setArticle] = useState<KnowledgeBaseDto>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const {articleId} = useParams<{articleId: string}>();
    const history = useHistory();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const {t} = useTranslation(i18nNamespaces.COMMON);
    
    useEffect(() => {
        setLoading(true);
        articlesProvider
            .getArticle(articleId)
            .then(setArticle)
            .finally(() => setLoading(false));
    }, []);

    const searchTimeSlot = (medicalServiceId: number) => {
        history.push({
            pathname: TIME_SLOTS_PAGE,
            search: parseTimeSlotQuery({
                medicalServiceId: medicalServiceId,
            }),
        });
    }

    return (
        <Box className={classes.container}>
            <Grid container>
                <Grid item>
                    <Typography variant="h1" className={classes.title}>
                        {article !== null && article.title}
                    </Typography>
                    <Typography className={classes.description}>
                        {article !== null && article.text}
                    </Typography>

                    <Box flexDirection={isDesktop ? 'row' : 'column'}
                        alignItems={'center'}
                        display="flex"
                        justifyContent={'space-between'}
                        className={classes.buttonWrapper}>

                        {isDesktop &&
                        <Button
                            className={classes.goBackButton}
                            variant={'contained'}
                            color={'secondary'}
                            onClick={() => history.goBack()}>
                            {t('goBack')}
                        </Button>}
                            
                        {article && article.medicalService && (
                            <Box className={classes.makeAppointment}>
                                {t('makeAnAppointment')}:
                                <Button variant="contained"
                                        color="secondary"
                                        className={classes.makeAnAppointmentButton} 
                                        fullWidth= {!isDesktop}
                                        onClick={() => searchTimeSlot(article.medicalService.id)}
                                        >
                                        {article.medicalService.name}
                                    </Button> 
                            </Box>
                        )}

                        {isMobile && 
                        <Button
                            className={classes.goBackButton}
                            variant={'contained'}
                            color={'secondary'}
                            onClick={() => history.goBack()}>
                            {t('goBack')}
                        </Button>}

                    </Box>
                </Grid>
            </Grid>
            <Loading loading={loading} withBackdrop />
        </Box>
    );
};

export default ArticleContent;
