import {ArticleAccessDto, KnowledgeBaseDto} from '../types/articles';
import {
    ARTICLE_ACCESS,
    GET_ARTICLE,
    GET_ARTICLES_LIST,
    GET_SIMPLIFIED_ARTICLE,
} from './endpoints';
import {http} from './http';
import {PaginatedResults} from '../types/common';

class articlesProvider {
    async getList(page = 0, size = 10) {
        return (
            await http.get<PaginatedResults<KnowledgeBaseDto>>(
                GET_ARTICLES_LIST,
                {
                    params: {
                        page,
                        size,
                    },
                },
            )
        ).data;
    }

    async getSimplifiedArticle(id: string) {
        return (await http.get<KnowledgeBaseDto>(GET_SIMPLIFIED_ARTICLE(id)))
            .data;
    }

    async getArticle(id: string) {
        return (await http.get<KnowledgeBaseDto>(GET_ARTICLE(id))).data;
    }

    async getArticlesPermission() {
        return  (await http.get<ArticleAccessDto>(ARTICLE_ACCESS)).data;
    }
}
export default new articlesProvider();
