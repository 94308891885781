import {useCallback, useMemo, useState} from 'react';
import {isObject} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    getMedicalTestsOrderDetails,
    MedicalTestsOrderDetailsDto
} from '../../../services/diagnostic/orderHeadersProvider';

interface MedicalTestOrderDetailsState {
    readonly orderDetails: MedicalTestsOrderDetailsDto;
    readonly orderDetailsLoading: boolean;
    readonly orderDetailsError: string;
    readonly fetchMedicalTestsOrderDetails: (referralId: string) => void;
}

const useMedicalTestOrderDetails = () => {
    const [orderDetails, setOrderDetails] = useState<MedicalTestsOrderDetailsDto>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const fetchMedicalTestsOrderDetails = useCallback(async (referralId: string) => {
        setLoading(true);
        try {
            setError(null);
            const {data: medicalTestsOrderDetails} = await getMedicalTestsOrderDetails(referralId);
            if (!isObject(medicalTestsOrderDetails)) {
                throw new Error();
            }
            setOrderDetails(medicalTestsOrderDetails);
        } catch {
            setError(tErrors('fetchingDataFromServerError'));
        } finally {
            setLoading(false);
        }
    }, []);

    const state = useMemo<MedicalTestOrderDetailsState>(
        () => ({
            orderDetails,
            orderDetailsLoading: loading,
            orderDetailsError: error,
            fetchMedicalTestsOrderDetails,
        }),
        [
            orderDetails,
            loading,
            error,
            fetchMedicalTestsOrderDetails
        ]
    );

    return state;
}

export default useMedicalTestOrderDetails;