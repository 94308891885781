import {useTranslation} from 'react-i18next';
import {PaymentContinuePage} from '../../components';
import i18nNamespaces from '../../const/i18nNamespaces';
import useQuery from '../../hooks/useQuery';
import {QUESTIONNAIRE_PAGE, USER_ACCOUNT_PAGE} from '../../const/routes';
import {generatePath, useHistory, useLocation} from 'react-router';
import {useParams} from 'react-router';
import appointmentsProvider from '../../services/appointmentsProvider';

type LocationState = {
    freeAppointment: boolean;
    withoutSurvey?: boolean;
};

const AppointmentPaymentContinuePage = () => {
    const query = useQuery();
    const error = query.get('error');
    const history = useHistory();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const location = useLocation<LocationState>();

    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_PAYMENT_CONTINUE);

    const payAgain = () => {
        appointmentsProvider.payAgain(parseInt(appointmentId)).then(result => {
            window.location.href = result.paymentUrl;
        });
    };

    const handleAction = () => {
        if (error) {
            payAgain();
        } else {
            if (location?.state?.withoutSurvey) history.push(USER_ACCOUNT_PAGE);
            else
                history.push(generatePath(QUESTIONNAIRE_PAGE, {appointmentId}));
        }
    };

    return (
        <PaymentContinuePage
            title={
                error
                    ? t('paymentFailed')
                    : location?.state?.freeAppointment ||
                      location?.state?.withoutSurvey
                    ? t('consultationBooked')
                    : t('waitingForPaymentProcessing')
            }
            information={
                error
                    ? t('paymentFailedDescription')
                    : location?.state?.freeAppointment ||
                      location?.state?.withoutSurvey
                    ? t('thankYou')
                    : t('youCanCompleteTheSurvey')
            }
            isSuccess={!error}
            actionText={
                error
                    ? t('tryAgain')
                    : location?.state?.withoutSurvey
                    ? t('goToAccount')
                    : t('completeSurvey')
            }
            onAction={handleAction}
        />
    );
};

export default AppointmentPaymentContinuePage;
