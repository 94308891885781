import {
    AnyAction,
    CaseReducer,
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
    ThunkAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {DateTime} from 'luxon';
import {
    MEDIC,
    PATIENT,
    NOT_CONFIRMED_USER,
    PHONE_NUMBER_CONFIRMATION,
    EMAIL_CONFIRMATION,
    FORCED_PASSWORD_CHANGE,
} from '../const/auth';
import {LoadingStatus} from '../const/loadingStatus';
import {
    clearMedicAsPatient,
    clearToken,
    getMedicAsPatient,
    getToken,
    setToken,
} from '../storage/storage';
import {AuthErrorReason, DecodedAuthToken, UserAuthData} from '../types/auth';
import {RootState} from './store';
import AccountProvider from '../services/accountProvider';
import {isArray, isEmpty} from 'lodash-es';
import {isNotNullOrUndefined} from '../utils/lang';
import HTTPService from '../services/HTTPService';
import {
    RegisterUserDto,
    RegisterUserFormOutput,
    RegisterUserSimplyFormOutput,
    RegisterWithIdentityDto,
} from '../types/Login';
import {registerErrorHandler, verifySMSErrorHandler} from '../utils/error';
import accountProvider from '../services/accountProvider';
import {MojeIDRegisterFormValues} from '../components/forms/MojeIDRegisterForm/MojeIDRegisterForm';
import {accountApi} from '../api/account/accountApi';

const sliceName = 'auth';

const initialState = {
    status: 'idle' as LoadingStatus,
    token: getToken(),
    email: null as string,
    emailConfirmed: false,
    phoneNumberConfirmed: false,
    firstTimeLogin: true,
    errorReason: null as AuthErrorReason,
    medicAsPatient: getMedicAsPatient() as boolean,
    phoneNumber: '' as string,
    error: '' as string,
};

export type AuthSliceState = typeof initialState;

type SignInResult = {
    token: string;
    email: string;
    emailConfirmed: boolean;
    phoneNumberConfirmed: boolean;
    firstTimeLogin?: boolean;
};

type SimpleSignInResult = SignInResult & {
    phoneNumber: string;
};

export const signUp = createAsyncThunk<
    SignInResult,
    RegisterUserFormOutput,
    {
        state: RootState;
    }
>(`${sliceName}/signUp`, async arg => {
    try {
        // TODO: childPesel, childName, childSurname

        const response = await HTTPService.register({
            childName: arg.childName !== '' ? arg.childName : null,
            childSurname: arg.childSurname !== '' ? arg.childSurname : null,
            childPesel: arg.childPesel !== '' ? arg.childPesel : null,
            name: arg.name,
            surname: arg.surname,
            pesel: arg.pesel || null,
            email: arg.email,
            phoneNumber: arg.phoneNumber,
            password: arg.password,
            acceptReceivingEmails: arg.acceptReceivingEmails,
            acceptReceivingSMS: arg.acceptReceivingSMS,
            homeAddress: {
                streetName: arg.streetName,
                houseNumber: arg.houseNumber,
                apartmentNumber: arg.apartmentNumber,
                postcode: arg.postcode,
                cityName: arg.cityName,
            },
        });

        if (response.data.token) {
            setToken(response.data.token);
        }
        return {
            token: response.data.token,
            email: arg.email,
            emailConfirmed: response.data.emailConfirmed,
            phoneNumberConfirmed: response.data.phoneNumberConfirmed,
            firstTimeLogin: response.data.firstTimeLogin,
        };
    } catch (error) {
        if (axios.isAxiosError(error) && isArray(error.response?.data)) {
            throw new Error(error.response.data[0]);
        }
        throw new Error();
    }
});

export const signUpSimply = createAsyncThunk<
    SimpleSignInResult,
    RegisterUserSimplyFormOutput,
    {
        state: RootState;
    }
>(`${sliceName}/signUpSimply`, async arg => {
    try {
        // TODO: childPesel, childName, childSurname

        const response = await accountApi.registerSimply({
            email: arg.email,
            phoneNumber: arg.phoneNumber,
            password: arg.password,
            acceptReceivingEmails: arg.acceptReceivingEmailsAndSMS,
            acceptReceivingSMS: arg.acceptReceivingEmailsAndSMS,
        });

        if (response.data.token) {
            setToken(response.data.token);
        }
        return {
            token: response.data.token,
            email: arg.email,
            phoneNumberConfirmed: response.data.phoneNumberConfirmed,
            emailConfirmed: response.data.emailConfirmed,
            phoneNumber: arg.phoneNumber,
        };
    } catch (error) {
        if (axios.isAxiosError(error) && isArray(error.response?.data)) {
            throw new Error(error.response.data[0]);
        }
        throw new Error();
    }
});

export const signUpRegisterMojeId = createAsyncThunk<
    SignInResult,
    MojeIDRegisterFormValues,
    {state: RootState}
>(`${sliceName}/signUpRegisterMojeId`, async registerWithIdentity => {
    try {
        const {
            streetName,
            cityName,
            houseNumber,
            apartmentNumber,
            postcode,
            ...userData
        } = registerWithIdentity;
        const response = await accountProvider.registerWithIdentity({
            ...userData,
            homeAddress: {
                streetName,
                cityName,
                houseNumber,
                apartmentNumber,
                postcode,
            },
        });

        if (response.data.token) {
            setToken(response.data.token);
        }

        return {
            token: response.data.token,
            email: registerWithIdentity.email,
            emailConfirmed: response.data.emailConfirmed,
            phoneNumberConfirmed: response.data.phoneNumberConfirmed,
            firstTimeLogin: response.data.firstTimeLogin,
        };
    } catch (error) {
        if (axios.isAxiosError(error) && isArray(error.response?.data)) {
            throw new Error(error.response.data[0]);
        }
        throw new Error();
    }
});

export const confirmIdentityWithMojeId = createAsyncThunk<
    void,
    void,
    {state: RootState}
>(`${sliceName}/confirmIdentityWithMojeId`, async () => {
    try {
       
        const response = await accountProvider.confirmIdentityWithModeId();
       
    } catch (error) {
        if (axios.isAxiosError(error) && isArray(error.response?.data)) {
            throw new Error(error.response.data[0]);
        }
        throw new Error();
    }
});

export const signIn = createAsyncThunk<
    SignInResult,
    {
        email: string;
        password: string;
    },
    {
        state: RootState;
    }
>(`${sliceName}/signIn`, async arg => {
    try {
        const response = await AccountProvider.signIn(arg.email, arg.password);

        if (response.data.token) {
            setToken(response.data.token);
        }
        return {
            token: response.data.token,
            email: arg.email,
            emailConfirmed: response.data.emailConfirmed,
            phoneNumberConfirmed: response.data.phoneNumberConfirmed,
            firstTimeLogin: response.data.firstTimeLogin,
        };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 401) {
                throw new Error('INVALID_EMAIL_OR_PASSWORD' as AuthErrorReason);
            }
        }

        throw new Error('OTHER_ERROR' as AuthErrorReason);
    }
});

export const signOut =
    (): ThunkAction<void, RootState, unknown, AnyAction> => dispatch => {
        clearToken();
        clearMedicAsPatient();
        dispatch(signedOut());
    };

export const refreshToken = createAsyncThunk<
    SignInResult,
    void,
    {
        state: RootState;
    }
>(`${sliceName}/refreshToken`, async (arg, thunkAPI) => {
    try {
        const response = await AccountProvider.refreshToken();

        if (response.token) {
            setToken(response.token);
        }

        return {
            token: response.token,
            email: selectEmail(thunkAPI.getState()),
            emailConfirmed: response.emailConfirmed,
            phoneNumberConfirmed: response.phoneNumberConfirmed,
            firstTimeLogin: response.firstTimeLogin,
        };
    } catch (error) {
        clearToken();
        if (axios.isAxiosError(error)) {
            if (error.response.status === 401) {
                throw new Error('REFRESH_TOKEN_EXPIRED' as AuthErrorReason);
            }
        }

        throw new Error('OTHER_ERROR' as AuthErrorReason);
    }
});

export const verifyEmailCode = createAsyncThunk<
    SignInResult,
    {
        email: string;
        code: string;
    },
    {
        state: RootState;
    }
>(`${sliceName}/verifyEmailCode`, async (arg, thunkAPI) => {
    const response = await AccountProvider.verifyEmailCode(arg.email, arg.code);

    if (response.token) {
        setToken(response.token);
    }

    return {
        token: response.token,
        email: arg.email,
        emailConfirmed: response.emailConfirmed,
        phoneNumberConfirmed: response.phoneNumberConfirmed,
        firstTimeLogin: response.firstTimeLogin,
    };
});

export const verifySMSCode = createAsyncThunk<
    SignInResult,
    {
        email: string;
        code: string;
    },
    {
        state: RootState;
    }
>(`${sliceName}/verifySMSCode`, async (arg, thunkAPI) => {
    try {
        const response = await AccountProvider.verifySMSCode(
            arg.email,
            arg.code,
        );

        if (response.token) {
            setToken(response.token);
        }

        return {
            token: response.token,
            email: arg.email,
            emailConfirmed: response.emailConfirmed,
            phoneNumberConfirmed: response.phoneNumberConfirmed,
            firstTimeLogin: response.firstTimeLogin,
        };
    } catch (error) {
        if (axios.isAxiosError(error) && isArray(error.response?.data)) {
            throw new Error(error.response.data[0]);
        }
        throw new Error();
    }
});

export const forcedPasswordChange = createAsyncThunk<
    SignInResult,
    {
        email: string;
        newPassword: string;
    },
    {
        state: RootState;
    }
>(`${sliceName}/password`, async (arg, thunkAPI) => {
    const response = await AccountProvider.forcedPasswordChange(
        arg.email,
        arg.newPassword,
    );

    if (response.token) {
        setToken(response.token);
    }

    return {
        token: response.token,
        email: arg.email,
        emailConfirmed: response.emailConfirmed,
        phoneNumberConfirmed: response.phoneNumberConfirmed,
        firstTimeLogin: response.firstTimeLogin,
    };
});

const authStatusSpecifiedReducer: CaseReducer<
    AuthSliceState,
    PayloadAction<LoadingStatus>
> = (state, action) => {
    state.status = action.payload;
};

const medicAsPatientSpecifiedReducer: CaseReducer<
    AuthSliceState,
    PayloadAction<boolean>
> = (state, action) => {
    state.medicAsPatient = action.payload;
};

const clearErrorReducer: CaseReducer<
    AuthSliceState,
    PayloadAction<null>
> = state => {
    state.error = '';
};

const authSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        signedOut(state) {
            state.status = 'idle';
            state.token = null;
            state.email = null;
            state.emailConfirmed = false;
            state.phoneNumberConfirmed = false;
            state.firstTimeLogin = true;
            state.medicAsPatient = null;
        },
        tokenAcquired(state, action: PayloadAction<SignInResult>) {
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.emailConfirmed = action.payload.emailConfirmed;
            state.phoneNumberConfirmed = action.payload.phoneNumberConfirmed;
            state.firstTimeLogin = action.payload.firstTimeLogin;
        },
        medicAsPatientSpecified: medicAsPatientSpecifiedReducer,
        authStatusSpecified: authStatusSpecifiedReducer,
        clearError: clearErrorReducer,
    },
    extraReducers(builder) {
        builder
            .addCase(signUp.pending, state => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.token = action.payload.token;
                state.email = action.payload.email;
                state.emailConfirmed = action.payload.emailConfirmed;
                state.phoneNumberConfirmed =
                    action.payload.phoneNumberConfirmed;
                state.firstTimeLogin = action.payload.firstTimeLogin;
                state.error = '';
            })
            .addCase(signUp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = registerErrorHandler(action.error.message);
            })
            .addCase(signUpSimply.pending, state => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(signUpSimply.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.token = action.payload.token;
                state.email = action.payload.email;
                state.emailConfirmed = action.payload.emailConfirmed;
                state.phoneNumberConfirmed =
                    action.payload.phoneNumberConfirmed;
                state.phoneNumber = action.payload.phoneNumber;
                state.error = '';
            })
            .addCase(signUpSimply.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(signUpRegisterMojeId.pending, state => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(signUpRegisterMojeId.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.token = action.payload.token;
                state.email = action.payload.email;
                state.emailConfirmed = action.payload.emailConfirmed;
                state.phoneNumberConfirmed =
                    action.payload.phoneNumberConfirmed;
                state.firstTimeLogin = action.payload.firstTimeLogin;
                state.error = '';
            })
            .addCase(signUpRegisterMojeId.rejected, (state, action) => {
                state.error = registerErrorHandler(action.error.message);
            })
            .addCase(signIn.pending, state => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.token = action.payload.token;
                state.email = action.payload.email;
                state.emailConfirmed = action.payload.emailConfirmed;
                state.phoneNumberConfirmed =
                    action.payload.phoneNumberConfirmed;
                state.firstTimeLogin = action.payload.firstTimeLogin;
                state.errorReason = null;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.status = 'failed';
                state.errorReason = action.error.message as AuthErrorReason;
                state.error = action.error.message;
            });

        builder
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.token = action.payload.token;
                state.emailConfirmed = action.payload.emailConfirmed;
                state.phoneNumberConfirmed =
                    action.payload.phoneNumberConfirmed;
                state.firstTimeLogin = action.payload.firstTimeLogin;
                state.errorReason = null;
            })
            .addCase(refreshToken.rejected, (state, action) => {
                state.status = 'idle';
                state.token = null;
                state.email = null;
                state.emailConfirmed = false;
                state.phoneNumberConfirmed = false;
                state.firstTimeLogin = true;
                state.errorReason = action.error.message as AuthErrorReason;
                state.medicAsPatient = null;
            });

        builder
            .addCase(verifyEmailCode.pending, state => {
                state.status = 'loading';
            })
            .addCase(verifyEmailCode.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.token = action.payload.token;
                state.emailConfirmed = action.payload.emailConfirmed;
                state.phoneNumberConfirmed =
                    action.payload.phoneNumberConfirmed;
                state.firstTimeLogin = action.payload.firstTimeLogin;
                state.errorReason = null;
            })
            .addCase(verifyEmailCode.rejected, state => {
                state.status = 'failed';
                state.errorReason = 'EMAIL_VERIFICATION_ERROR';
                state.error = '';
            });

        builder
            .addCase(verifySMSCode.pending, state => {
                state.status = 'loading';
            })
            .addCase(verifySMSCode.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.token = action.payload.token;
                state.emailConfirmed = action.payload.emailConfirmed;
                state.phoneNumberConfirmed =
                    action.payload.phoneNumberConfirmed;
                state.firstTimeLogin = action.payload.firstTimeLogin;
                state.errorReason = null;
            })
            .addCase(verifySMSCode.rejected, (state, action) => {
                state.status = 'failed';
                state.errorReason = 'PHONE_VERIFICATION_ERROR';
                state.error = verifySMSErrorHandler(action.error.message);
            });

        builder
            .addCase(forcedPasswordChange.pending, state => {
                state.status = 'loading';
            })
            .addCase(forcedPasswordChange.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.token = action.payload.token;
                state.emailConfirmed = action.payload.emailConfirmed;
                state.phoneNumberConfirmed =
                    action.payload.phoneNumberConfirmed;
                state.firstTimeLogin = action.payload.firstTimeLogin;
                state.errorReason = null;
            })
            .addCase(forcedPasswordChange.rejected, (state, action) => {
                state.status = 'failed';
                state.errorReason = 'FORCED_PASSWORD_CHANGE_ERROR';
            });
    },
});

export const {
    signedOut,
    tokenAcquired,
    medicAsPatientSpecified,
    authStatusSpecified,
    clearError,
} = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;
export const selectAuthStatus = (state: RootState) => selectAuth(state).status;
export const selectAuthToken = (state: RootState) => selectAuth(state).token;
export const selectEmail = (state: RootState) => selectAuth(state).email;
export const selectEmailConfirmed = (state: RootState) =>
    selectAuth(state).emailConfirmed;
export const selectPhoneNumberConfirmed = (state: RootState) =>
    selectAuth(state).phoneNumberConfirmed;
export const selectFirstTimeLogin = (state: RootState) =>
    selectAuth(state).firstTimeLogin;
export const selectMedicAsPatient = (state: RootState) =>
    selectAuth(state).medicAsPatient;
export const selectAuthEmail = (state: RootState) => selectAuth(state).email;
export const selectAuthPhoneNumber = (state: RootState) =>
    selectAuth(state).phoneNumber;
export const selectAuthError = (state: RootState) => selectAuth(state).error;

export const selectAuthUserData = createSelector(
    [selectAuthToken, selectMedicAsPatient],
    (token, medicAsPatient) => {
        if (!token) return null;

        const decodedAuthData = jwtDecode<DecodedAuthToken>(token);

        let role;
        if (
            !isNotNullOrUndefined(decodedAuthData) ||
            isEmpty(decodedAuthData.role)
        ) {
            role = NOT_CONFIRMED_USER;
        } else {
            if (
                decodedAuthData.role.includes(PATIENT) &&
                decodedAuthData.role.includes(MEDIC)
            ) {
                if (medicAsPatient) {
                    role = PATIENT;
                } else {
                    role = MEDIC;
                }
            } else if (decodedAuthData.role.includes(PATIENT)) {
                role = PATIENT;
            } else if (decodedAuthData.role.includes(MEDIC)) {
                role = MEDIC;
            } else if (
                decodedAuthData.role.includes(PHONE_NUMBER_CONFIRMATION)
            ) {
                role = PHONE_NUMBER_CONFIRMATION;
            } else if (decodedAuthData.role.includes(EMAIL_CONFIRMATION)) {
                role = EMAIL_CONFIRMATION;
            } else if (decodedAuthData.role.includes(FORCED_PASSWORD_CHANGE)) {
                role = FORCED_PASSWORD_CHANGE;
            } else {
                role = NOT_CONFIRMED_USER;
            }
        }

        return {
            userId: decodedAuthData.nameid,
            displayName: decodedAuthData.unique_name,
            email: decodedAuthData.email,
            role,
            expirationDateTime: DateTime.fromSeconds(decodedAuthData.exp),
        } as UserAuthData;
    },
);

export default authSlice.reducer;
