import {MedicDto, MedicSpecialistDto} from '../types/timeSlot';
import {
    MedicCalendarUTCDates,
    MedicCalendarUTCIsoDatesDto,
    MedicPhotoDto,
    PatientReferralMedic,
    Tag,
    UpdateCurrentMedic
} from '../types/medic';
import {
    SET_MEDIC_PHOTO,
    MEDICS,
    SET_MEDIC_MAIN_PHOTO,
    MEDIC_TAGS,
    TAGS,
    PATIENT_REFERRAL_MEDICS,
    MEDIC_CALENDAR,
    OUR_SPECIALISTS,
    SET_MEDIC_STAMP
} from './endpoints';
import {http, rawHttp} from './http';
import { Tags } from '../types/medicTags';
import {DateTime} from "luxon";

class MedicsProvider {
    async getCurrentMedic() {
        return (
            await http.get<MedicDto>(MEDICS)
        ).data;
    }

    async getMedicById(medicId: string) {
        return rawHttp.get<MedicDto>(`${MEDICS}/${medicId}`);
    }

    async updateCurrentMedic(payload: UpdateCurrentMedic) {
        return await http.put<void>(MEDICS, payload);
    }

    async addMedicPhoto(formData: FormData) {
        return (
            await http.post<MedicPhotoDto>(SET_MEDIC_PHOTO, formData)
        ).data;
    }

    async addMedicStamp(formData: FormData){
        return (
            await http.post(SET_MEDIC_STAMP, formData)
        ).data;
    }

    async setMainPhoto(photoId: string) {
        return await http.post<void>(`${SET_MEDIC_MAIN_PHOTO}/${photoId}`);
    }

    async fetchTags() {
        return (
            await http.get<Tag[]>(TAGS)
        ).data ;
    }

    async fetchMedicTags() {
        return (
            await http.get<Tag[]>(MEDIC_TAGS)
        ).data;
    }

    async updateMedicTags(medicTags: Tags) {
        return await http.put<void>(MEDIC_TAGS, medicTags);
    }

    async fetchMedicsByMedicalServiceAndTags(medicalServiceId: number, tagsIds: number[]) {
        // There are few ways of passing array in query string,
        // but axios do it in a way, that backend cannot serialize such parameters.
        const medicalServiceQueryParam = `?medicalServiceId=${medicalServiceId}`;
        const tagsIdsQueryParam = tagsIds
            .map(tagId => `tagIds=${tagId}`)
            .join('&');

        const queryString = tagsIdsQueryParam.length
            ? `${medicalServiceQueryParam}&${tagsIdsQueryParam}`
            : `${medicalServiceQueryParam}`;

        return (
            await http.get<PatientReferralMedic[]>(`${PATIENT_REFERRAL_MEDICS}${queryString}`)
        ).data;
    }

    private static medicCalendarDatesMapper(medicCalendarDataDtoAsJson: string) {
        const {
            firstDayOfMedicCalendar,
            lastDayOfMedicCalendar
        } = JSON.parse(medicCalendarDataDtoAsJson) as MedicCalendarUTCIsoDatesDto;

        return {
            firstDayOfMedicCalendar: DateTime.fromISO(firstDayOfMedicCalendar),
            lastDayOfMedicCalendar: DateTime.fromISO(lastDayOfMedicCalendar),
        } as MedicCalendarUTCDates
    }

    async getMedicCalendarUTCDatesConvertedFromPolishTimeZoneMidnightTimeDates() {
        return rawHttp
            .get<MedicCalendarUTCDates>(
                MEDIC_CALENDAR,
                {
                    transformResponse: [MedicsProvider.medicCalendarDatesMapper],
                }
            );
    }

    async getOurSpecialists(){
        return rawHttp.get<MedicSpecialistDto[]>(OUR_SPECIALISTS);
    }
}


export default new MedicsProvider();
