import {Box, Grid, Typography} from '@material-ui/core';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import configuration from '../../configuration';
import i18nNamespaces from '../../const/i18nNamespaces';
import useStyles from './PrivacyPolicyPageStyles';


const PrivacyPolicyPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const {t: tContent} = useTranslation(i18nNamespaces.FOOTER_CONTENT);
    const {t: tMetaDescription} = useTranslation(i18nNamespaces.META_DESCRIPTION);

    const definitionsContent= tContent('definitionsContent', {returnObjects:true}) as Array<string>;

    const dataAdminListContent= tContent('dataAdminListContent', {returnObjects:true}) as Array<string>;
    const dataAdminListContent2= tContent('dataAdminListContent2', {returnObjects:true}) as Array<string>;
    
    const purposeListContent= tContent('purposeListContent', {returnObjects:true}) as Array<string>;
    
    const dataReceiversListContent= tContent('dataReceiversListContent', {returnObjects:true}) as Array<string>;
    
    const cookiesListContent= tContent('cookiesListContent', {returnObjects:true}) as Array<string>;

    const cookiesListContent2= tContent('cookiesListContent2', {returnObjects:true}) as Array<string>;

    const personRightsListContent= tContent('personRightsListContent', {returnObjects:true}) as Array<string>;
    const personRightsListContent2= tContent('personRightsListContent2', {returnObjects:true}) as Array<string>;
    const personRightListContent3= tContent('personRightListContent3', {returnObjects:true}) as Array<string>;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
        {configuration.environment ===  'Production' &&
        <Helmet>
            <title>{tMetaDescription('privacyPolicyPageTitle')}</title>
            <meta name='description' content={tMetaDescription('privacyPolicyPageDescription')} />
        </Helmet>}
        <Box className={classes.container}>
                    <Box className={classes.title}>
                        {tContent('privacyPolicy')}
                    </Box>
                
                {/* Definicje */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('definitionsTitle')}
                    </Box>  
                    <Box className={classes.description}>
                        <ul className={classes.olSuperiorElement}>{definitionsContent && definitionsContent.map((d, i)=> <li key={i}  >{d}</li>)}</ul>
                    </Box>
                
                
                {/* Administrator i Inspektor Ochrony Danych Osobowych  */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('dataAdminTitle')}
                    </Box>
                    <Box className={classes.description}>
                        {tContent('dataAdminContent')}
                    </Box>
                    <Box className={classes.description}>
                        <ul className={classes.olSuperiorElement}>{dataAdminListContent && dataAdminListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ul>
                    </Box>
                    <Box className={classes.description}>
                        {tContent('dataAdminContent2')}
                    </Box>
                    <Box className={classes.description}>
                        <ul className={classes.olSuperiorElement}>{dataAdminListContent2 && dataAdminListContent2.map((d, i)=> <li key={i}  >{d}</li>)}</ul>
                    </Box>

                {/* Cele, podstawy prawne i okres przetwarzania danych   */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('purposeTitle')}
                    </Box>
                    <Box className={classes.description}>
                        {tContent('purposeContent')}
                    </Box>
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{purposeListContent && purposeListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>


                {/* Odbiorcy danych osobowych */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('dataReceiversTitle')}
                    </Box>
                    <Box className={classes.description}>
                        {tContent('dataReceiversContent')}
                    </Box>
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{dataReceiversListContent && dataReceiversListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                    <Box className={classes.description}>
                        {tContent('dataReceiversContent2')}
                    </Box>

                {/* Profilowanie */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('profileTitle')}
                    </Box>
                    <Box className={classes.description}>
                        {tContent('profileContent')}
                    </Box>


                {/* Pliki cookies */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('cookiesTitle')}
                    </Box>
                    <Box className={classes.desctiptionSubtitle}>
                        {tContent('cookiesSubtitle')}
                    </Box>
                    <Box className={classes.description}>
                        {tContent('cookiesContent')}
                    </Box>
                    <Box className={classes.desctiptionSubtitle}>
                        {tContent('cookiesSubtitle2')}
                    </Box>
                    <Box className={classes.description}>
                        {tContent('cookiesContent2')}
                    </Box>
                    <Box className={classes.description}>
                        <ul className={classes.olSuperiorElement}>{cookiesListContent && cookiesListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ul>
                    </Box>
                    <Box className={classes.description}>
                        {tContent('cookiesContent3')}
                    </Box>
                    <Box className={classes.description}>
                        <ul className={classes.olSuperiorElement}>{cookiesListContent2 && cookiesListContent2.map((d, i)=> <li key={i}  >{d}</li>)}</ul>
                    </Box>
                    <Box className={classes.desctiptionSubtitle}>
                        {tContent('cookiesSubtitle3')}
                    </Box>
                    <Box className={classes.description}>
                        {tContent('cookiesContent4')}
                    </Box>

                {/* Prawa osób, których dane dotyczą  */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('personRightsTitle')}
                    </Box>
                    <Box className={classes.description}>
                        {tContent('personRightsContent')}
                    </Box>
                    <Box className={classes.description}>
                        <ul className={classes.olSuperiorElement}>{personRightsListContent && personRightsListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ul>
                    </Box>
                    <Box className={classes.description}>
                        <ol type='a' className={classes.liSubElement}>{personRightsListContent2 && personRightsListContent2.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                    <Box className={classes.description}>
                        <ul className={classes.olSuperiorElement}>{personRightListContent3 && personRightListContent3.map((d, i)=> <li key={i}  >{d}</li>)}</ul>
                    </Box>
                {/* Zmiany w Polityce Prywatności   */}

                    <Box className={classes.desctiptionTitle}>
                        {tContent('changesTitle')}
                    </Box>
                    
                    <Box className={classes.description}>
                        {tContent('changesContent')}
                    </Box>

            </Box>
        </>
    );
};
export default PrivacyPolicyPage;
