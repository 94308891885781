import {DayScheduleTemplateDto} from '../types/dayScheduleTemplates';
import { DAY_SCHEDULE_TEMPLATES } from './endpoints';
import {http, rawHttp} from './http';
import {ERROR} from "../const/routes";
import axios from "axios";
import history from '../utils/history';

class DayScheduleTemplatesProvider {
    async getDayScheduleTemplates() {
        try {
            return (
                await rawHttp.get<DayScheduleTemplateDto[]>(DAY_SCHEDULE_TEMPLATES)
            ).data;
        } catch(error) {
            if(axios.isAxiosError(error) && (error.response.data[0] !== 'TimeSlotsGeneratorIsBusy')) {
                history.push(ERROR);
                return;
            }
            console.error(error);
            throw (error);
        }
    }

    async saveDayScheduleTemplates(
        dayScheduleTemplates: DayScheduleTemplateDto[],
    ) {
        await http.put(DAY_SCHEDULE_TEMPLATES, dayScheduleTemplates);
    }
}

export default new DayScheduleTemplatesProvider();
