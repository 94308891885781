import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            display: 'flex',
            alignItems: 'center',
        },
        menuButton: {
            marginLeft: 32,
            textDecoration: 'none',
            cursor: 'pointer',
        },
        help: {
            marginLeft: 34,
            width: 24,
            height: 24,
        },
        account: {
            marginLeft: 36,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        accountIcon: {
            width: 24,
            height: 24,
        },
        logout: {
            marginLeft: 36,
            width: 24,
            height: 24,
        },
        isActive: {
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
        },
    }),
);

export default useStyles;
