import React, {ReactNode} from 'react';
import {Box, createStyles, makeStyles} from '@material-ui/core';
import {mobile} from '../../../const/sizes';

type LabelProps = {
    htmlFor: string;
    children: ReactNode;
};

const Label = ({htmlFor, children}: LabelProps) => {
    const useStyles = makeStyles(() =>
        createStyles({
            labelContainer: {
                marginBottom: mobile.contentSmallerSpacing,
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box className={classes.labelContainer}>
            <label htmlFor={htmlFor}>{children}</label>
        </Box>
    );
};

export default Label;
