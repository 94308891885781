import CustomTextField from '../../common/CustomTextField/CustomTextField';
import {
    Button,
    createStyles,
    FormGroup,
    InputAdornment,
    makeStyles,
} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {MAGENTA} from '../../../const/colors';
import {useAppDispatch} from '../../../hooks/customReduxHooks';
import {authCodeSpecified} from '../../../store/prescriptionHistorySlice';

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: 48,
        },
        icon: {
            color: MAGENTA,
        },
        adornment: {
            marginRight: '-14px',
        },
    }),
);

type AccessCodeInputProps = {
    disabled: boolean;
    errorText: string;
    onSubmit: () => void;
};
const AccessCodeInput = ({
    disabled,
    onSubmit,
    errorText,
}: AccessCodeInputProps) => {
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const classes = useStyles();
    const [code, setCode] = useState<string>('');
    const dispatch = useAppDispatch();

    const handleSubmit = () => {
        dispatch(authCodeSpecified(code));
        onSubmit();
    };

    return (
        <FormGroup style={{marginBottom: 30}}>
            <CustomTextField
                disabled={disabled}
                id="code"
                name="code"
                type="text"
                onChange={v => setCode(v.target.value)}
                error={errorText !== null}
                helperText={t(errorText)}
                placeholder={t('enterTheCode')}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            className={classes.adornment}
                        >
                            <Button
                                className={classes.button}
                                size="small"
                                color="secondary"
                                variant="outlined"
                                onClick={handleSubmit}
                                disabled={disabled}
                            >
                                <Add className={classes.icon} />
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />
        </FormGroup>
    );
};

export default AccessCodeInput;
