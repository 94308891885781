import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    contentContainer: {
        marginTop: 26,
        alignItems: 'center',
    },
    actionButtonsWrapper: {
        marginLeft: 26,
    }
}));

export default useStyles;