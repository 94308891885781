import React, {useEffect, useState} from 'react';
import {RemoteParticipant} from 'twilio-video';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {
    selectAudioInputDevices,
    selectAudioInputOn,
    selectBrowserAudioOutputCapabilitiesSupported,
    selectRoom,
    selectSelectedAudioOutputDeviceId,
    selectVideoInputDevices,
    selectVideoInputOn,
} from '../../../../store/consultationSlice';
import useStyles from '../VideoRoom/VideoRoomStyles';
import {
    PARTICIPANT_HUNG_UP,
    PARTICIPANT_JOINED,
    WAITING_FOR_CONNECTION,
} from '../../../../const/conversation';
import VideoParticipant from '../ParticipantVideo/ParticipantVideo';
import VideoRoom from '../VideoRoom/VideoRoom';
import VideoRoomMobile from '../VideoRoomMobile/VideoRoomMobile';
import {CustomInputChangeEvent} from '../../../../types/common';
import useConversation from '../../../../hooks/useConversation';
import useChatConversation from '../../../../hooks/useChatConversation';
import {MEDIC} from '../../../../const/auth';
import {selectAuthUserData} from '../../../../store/auth';

interface Props {
    showSettings: boolean;
    handleSelectVideoInput: (e: CustomInputChangeEvent) => void;
    setShowSettings: (value: boolean) => void;
    onUpdate: () => void;
    handleSelectAudioInput: (e: CustomInputChangeEvent) => void;
    handleSelectAudioOutput: (e: CustomInputChangeEvent) => void;
    handleHangout: () => void;
    handleVideoTurnOnOff: () => void;
    handleAudioTurnOnOff: () => void;
    mobileView?: boolean;
}

const VideoRoomContainer = ({
    showSettings,
    handleSelectVideoInput,
    setShowSettings,
    onUpdate,
    handleSelectAudioInput,
    handleSelectAudioOutput,
    handleHangout,
    handleAudioTurnOnOff,
    handleVideoTurnOnOff,
    mobileView,
}: Props) => {
    const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
    const [roomState, setRoomState] = useState<string>(null);
    const [chatOpen, setChatOpen] = useState<boolean>(false);
    const [uploadFileOpen, setUploadFileOpen] = useState<boolean>(false);
    const audioInputDevices = useAppSelector(selectAudioInputDevices);

    const selectedAudioOutputDeviceId = useAppSelector(
        selectSelectedAudioOutputDeviceId,
    );
    const browserAudioOutputCapabilitiesOn = useAppSelector(
        selectBrowserAudioOutputCapabilitiesSupported,
    );
    const videoInputDevices = useAppSelector(selectVideoInputDevices);
    const videoInputOn = useAppSelector(selectVideoInputOn);
    const audioInputOn = useAppSelector(selectAudioInputOn);
    const room = useAppSelector(selectRoom);

    const classes = useStyles();

    const authUserData = useAppSelector(selectAuthUserData);
    const isMedic = authUserData?.role === MEDIC;

    useEffect(() => {
        if (isMedic) setUploadFileOpen(true);
    }, [isMedic]);

    const {
        client,
        setConversation,
        conversation,
        loading,
        setLoading,
        error,
        setError,
        consultationId,
    } = useConversation();

    useChatConversation(
        client,
        setLoading,
        setError,
        consultationId,
        setConversation,
    );

    useEffect(() => {
        const participantConnected = (participant: RemoteParticipant) => {
            setParticipants(prevParticipants => [
                ...prevParticipants,
                participant,
            ]);
            setRoomState(PARTICIPANT_JOINED);
        };

        const participantDisconnected = (participant: RemoteParticipant) => {
            participant.removeAllListeners();

            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant),
            );
            setRoomState(PARTICIPANT_HUNG_UP);
        };

        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);

        room.participants.forEach(participantConnected);

        if (room.participants.size === 0) {
            setRoomState(WAITING_FOR_CONNECTION);
        }

        return () => {
            room.participants.forEach(participant =>
                participant.removeAllListeners(),
            );

            room.disconnect();
            room.off('participantConnected', participantConnected);
            room.off('participantDisconnected', participantDisconnected);
        };
    }, [room]);

    const remoteParticipants = participants.map(participant => (
        <VideoParticipant
            key={participant.sid}
            participant={participant}
            className={classes.remoteParticipantVideo}
            activeSinkId={selectedAudioOutputDeviceId}
            browserAudioOutputCapabilitiesOn={browserAudioOutputCapabilitiesOn}
        />
    ));
    return mobileView ? (
        <VideoRoomMobile
            handleHangout={handleHangout}
            handleVideoTurnOnOff={handleVideoTurnOnOff}
            handleAudioTurnOnOff={handleAudioTurnOnOff}
            videoInputOn={videoInputOn}
            audioInputOn={audioInputOn}
            room={room}
            roomState={roomState}
            remoteParticipants={remoteParticipants}
            conversation={conversation}
            loading={loading}
            error={error}
            setError={setError}
        />
    ) : (
        <VideoRoom
            showSettings={showSettings}
            handleSelectVideoInput={handleSelectVideoInput}
            setShowSettings={setShowSettings}
            onUpdate={onUpdate}
            handleSelectAudioInput={handleSelectAudioInput}
            handleSelectAudioOutput={handleSelectAudioOutput}
            handleHangout={handleHangout}
            handleAudioTurnOnOff={handleAudioTurnOnOff}
            handleVideoTurnOnOff={handleVideoTurnOnOff}
            setChatOpen={setChatOpen}
            setUploadFileOpen={setUploadFileOpen}
            uploadFileOpen={uploadFileOpen}
            chatOpen={chatOpen}
            videoInputOn={videoInputOn}
            audioInputOn={audioInputOn}
            room={room}
            roomState={roomState}
            remoteParticipants={remoteParticipants}
            audioInputDevices={audioInputDevices}
            videoInputDevices={videoInputDevices}
            conversation={conversation}
            loading={loading}
            error={error}
            setError={setError}
        />
    );
};

export default VideoRoomContainer;
