import React from 'react';
import {Box, Grid, Link} from '@material-ui/core';
import {
    CustomPasswordField,
    CustomTextField,
    FormGroup,
    Label,
} from '../../index';
import CustomButton from '../../common/Button/Button';
import {LOGIN_PAGE} from '../../../const/routes';
import useStyles from './PasswordChangeFormStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useFormik} from 'formik';
import {useHistory} from 'react-router';
import {forgotPasswordSchema} from '../../../validators/forgotPassword';
import PasswordStrengthBar from 'react-password-strength-bar';

export type PasswordChangeFormValues = {
    code: string;
    password: string;
    repeatPassword: string;
};

interface PasswordChangeEmailFormProps {
    email: string;
    onSubmit: (values: PasswordChangeFormValues) => void;
    error?: string;
}

const PasswordChangeForm = ({
    email,
    onSubmit,
    error,
}: PasswordChangeEmailFormProps) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const history = useHistory();
    const {values, handleChange, handleBlur, touched, errors, handleSubmit} =
        useFormik({
            initialValues: {
                code: '',
                password: '',
                repeatPassword: '',
            },
            validationSchema: forgotPasswordSchema,
            validateOnBlur: true,
            onSubmit,
        });

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container direction={'column'}>
                <Box className={classes.title}>{t('typeNewPassword')}</Box>
                <Box className={classes.formField}>
                    {t('ifYouEnteredValidEmailYouWillReceiveCode')}
                </Box>
                <Box className={classes.formField}>Email: {email}</Box>
                <FormGroup className={classes.formField}>
                    <Label htmlFor="codeFromEmail">{t('code')}</Label>
                    <CustomTextField
                        id="codeFromEmail"
                        name="code"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                        helperText={touched.code && t(errors.code)}
                        error={touched.code && !!errors.code}
                        autoComplete="new-password"
                    />
                </FormGroup>
                <input
                    autoComplete="new-password"
                    name="hidden"
                    type="text"
                    style={{display: 'none'}}
                />
                <FormGroup className={classes.formField}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.passwordLabelContainer}
                    >
                        <Box>
                            <Label htmlFor="newPassword">
                                {t('passwordWithMinChars')}
                            </Label>
                        </Box>
                        <Box className={classes.passwordStrengthBarContainer}>
                            <PasswordStrengthBar
                                password={values.password}
                                scoreWords={[]}
                            />
                        </Box>
                    </Grid>
                    <CustomPasswordField
                        id="newPassword"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        helperText={touched.password && t(errors.password)}
                        error={touched.password && !!errors.password}
                        autoComplete="new-password"
                    />
                </FormGroup>
                <FormGroup className={classes.formField}>
                    <Label htmlFor="repeatPassword">
                        {t('repeatPassword')}
                    </Label>
                    <CustomPasswordField
                        id="repeatPassword"
                        name="repeatPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.repeatPassword}
                        helperText={
                            touched.repeatPassword && t(errors.repeatPassword)
                        }
                        error={
                            touched.repeatPassword && !!errors.repeatPassword
                        }
                    />
                </FormGroup>
                <Box className={classes.error}>{t(error)}</Box>
                <CustomButton
                    variant={'contained'}
                    color={'secondary'}
                    className={classes.submitButton}
                    type={'submit'}
                >
                    {commonT('next')}
                </CustomButton>
                <Link
                    color={'secondary'}
                    onClick={() => history.push(LOGIN_PAGE)}
                >
                    {t('goBackToLogin')}
                </Link>
            </Grid>
        </form>
    );
};

export default PasswordChangeForm;
