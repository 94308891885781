import {
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {useFormik} from 'formik';
import {Trans, useTranslation} from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import {
    Button,
    CustomPasswordField,
    CustomSelectField,
    CustomTextField,
    FormGroup,
    Label,
    PhoneNumberField,
} from '../..';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    PRIVACY_POLICY_PAGE,
    TERMS_AND_CONDITIONS_PAGE,
} from '../../../const/routes';
import {RegisterWithIdentityDto} from '../../../types/Login';
import mojeIDRegisterSchema from '../../../validators/MojeIDRegister';
import useStyles from './MojeIDRegisterFormStyles';
import {NewTabLink} from '../../common/navigation';

export type MojeIDRegisterFormValues = {
    gender: number;
    email: string;
    phoneNumber: string;
    password: string;
    repeatPassword: string;
    streetName: string;
    houseNumber: string;
    apartmentNumber: string;
    postcode: string;
    cityName: string;
    acceptPolicy: boolean;
    acceptPrivacyPolicy: boolean;
    acceptReceivingEmails: boolean;
    acceptReceivingSMS: boolean;
};

type MojeIDRegisterFormProps = {
    onSubmit: (values: MojeIDRegisterFormValues) => void;
    error: string;
};

const MojeIDRegisterForm = ({onSubmit, error}: MojeIDRegisterFormProps) => {
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const {handleChange, handleBlur, handleSubmit, values, touched, errors} =
        useFormik({
            initialValues: {
                gender: null,
                email: '',
                password: '',
                repeatPassword: '',
                phoneNumber: '',
                streetName: '',
                houseNumber: '',
                apartmentNumber: '',
                postcode: '',
                cityName: '',
                acceptPolicy: false,
                acceptPrivacyPolicy: false,
                acceptReceivingEmails: false,
                acceptReceivingSMS: false,
            },
            validationSchema: mojeIDRegisterSchema,
            onSubmit,
        });

    const classes = useStyles();

    const handlePhoneNumberChange = (value: string) =>
        handleChange({
            target: {
                value,
                id: 'phoneNumber',
                name: 'phoneNumber',
            },
        });

    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="email">E-mail</Label>
                        <CustomTextField
                            id="email"
                            name="email"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            helperText={touched.email && t(errors.email)}
                            error={touched.email && !!errors.email}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="phoneNumber">{t('phoneNumber')}</Label>
                        <PhoneNumberField
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handlePhoneNumberChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            helperText={
                                touched.phoneNumber && t(errors.phoneNumber)
                            }
                            error={touched.phoneNumber && !!errors.phoneNumber}
                            defaultCountry="PL"
                            fullWidth={!isDesktop}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="gender">{t('gender')}</Label>
                        <CustomSelectField
                            id="gender"
                            name="gender"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gender}
                            helperText={touched.gender && t(errors.gender)}
                            error={touched.gender && !!errors.gender}
                        >
                            <MenuItem value={0} id="0">
                                {t('woman')}
                            </MenuItem>
                            <MenuItem value={1} id="1">
                                {t('man')}
                            </MenuItem>
                        </CustomSelectField>
                    </FormGroup>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormGroup className={classes.formGroup}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.passwordLabelContainer}
                        >
                            <Box>
                                <Label htmlFor="password">
                                    {t('passwordWithMinChars')}
                                </Label>
                            </Box>
                            <Box
                                className={classes.passwordStrengthBarContainer}
                            >
                                <PasswordStrengthBar
                                    password={values.password}
                                    scoreWords={[]}
                                />
                            </Box>
                        </Grid>
                        <CustomPasswordField
                            id="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            helperText={touched.password && t(errors.password)}
                            error={touched.password && !!errors.password}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="repeatPassword">
                            {t('repeatPassword')}
                        </Label>
                        <CustomPasswordField
                            id="repeatPassword"
                            name="repeatPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.repeatPassword}
                            helperText={
                                touched.repeatPassword &&
                                t(errors.repeatPassword)
                            }
                            error={
                                touched.repeatPassword &&
                                !!errors.repeatPassword
                            }
                        />
                    </FormGroup>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.formSubsection}
                >
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="streetName">{t('street')}*</Label>
                        <CustomTextField
                            id="streetName"
                            name="streetName"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.streetName}
                            helperText={
                                touched.streetName && t(errors.streetName)
                            }
                            error={touched.streetName && !!errors.streetName}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="houseNumber">{t('houseNumber')}*</Label>
                        <CustomTextField
                            id="houseNumber"
                            name="houseNumber"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.houseNumber}
                            helperText={
                                touched.houseNumber && t(errors.houseNumber)
                            }
                            error={touched.houseNumber && !!errors.houseNumber}
                            className={classes.numberInput}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="apartmentNumber">
                            {t('apartmentNumber')}
                        </Label>
                        <CustomTextField
                            id="apartmentNumber"
                            name="apartmentNumber"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.apartmentNumber}
                            helperText={
                                touched.apartmentNumber &&
                                t(errors.apartmentNumber)
                            }
                            error={
                                touched.apartmentNumber &&
                                !!errors.apartmentNumber
                            }
                            className={classes.apartmentInput}
                        />
                    </FormGroup>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="postcode">{t('postcode')}*</Label>
                        <CustomTextField
                            id="postcode"
                            name="postcode"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.postcode}
                            helperText={touched.postcode && t(errors.postcode)}
                            error={touched.postcode && !!errors.postcode}
                            className={classes.codeInput}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="cityName">{t('cityName')}*</Label>
                        <CustomTextField
                            id="cityName"
                            name="cityName"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cityName}
                            helperText={touched.cityName && t(errors.cityName)}
                            error={touched.cityName && !!errors.cityName}
                        />
                    </FormGroup>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item className={classes.formSubsection}>
                        * {t('requiredAddress')}
                    </Grid>
                    <Grid item className={classes.formSubsection}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="acceptPolicy"
                                    name="acceptPolicy"
                                    checked={values.acceptPolicy}
                                    onChange={handleChange}
                                />
                            }
                            label={
                                <Trans
                                    t={t}
                                    i18nKey="acceptPolicy"
                                    components={[
                                        <NewTabLink
                                            key={'0'}
                                            href={TERMS_AND_CONDITIONS_PAGE}
                                            className={
                                                classes.termsAndConditionLink
                                            }
                                        ></NewTabLink>,
                                    ]}
                                />
                            }
                        />
                        <FormHelperText
                            error={
                                touched.acceptPolicy && !!errors.acceptPolicy
                            }
                        >
                            {touched.acceptPolicy && t(errors.acceptPolicy)}
                        </FormHelperText>
                    </Grid>
                    {!!error && (
                        <Grid item className={classes.formSubsection}>
                            <Box color="red" fontSize={16}>
                                {error}
                            </Box>
                        </Grid>
                    )}
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item className={classes.formSubsection}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="acceptPrivacyPolicy"
                                        name="acceptPrivacyPolicy"
                                        checked={values.acceptPrivacyPolicy}
                                        onChange={handleChange}
                                    />
                                }
                                label={
                                    <Trans
                                        t={t}
                                        i18nKey="acceptPrivacyPolicy"
                                        components={[
                                            <NewTabLink
                                                key={'0'}
                                                href={PRIVACY_POLICY_PAGE}
                                                className={
                                                    classes.termsAndConditionLink
                                                }
                                            ></NewTabLink>,
                                        ]}
                                    />
                                }
                            />
                            <FormHelperText
                                error={
                                    touched.acceptPrivacyPolicy &&
                                    !!errors.acceptPrivacyPolicy
                                }
                            >
                                {touched.acceptPrivacyPolicy &&
                                    t(errors.acceptPrivacyPolicy)}
                            </FormHelperText>
                        </Grid>

                        {!!error && (
                            <Grid item className={classes.formSubsection}>
                                <Box color="red" fontSize={16}>
                                    {error}
                                </Box>
                            </Grid>
                        )}
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item className={classes.formSubsection}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="acceptReceivingEmails"
                                        name="acceptReceivingEmails"
                                        checked={values.acceptReceivingEmails}
                                        onChange={handleChange}
                                    />
                                }
                                label={
                                    <Trans
                                        t={t}
                                        i18nKey="acceptReceivingEmails"
                                    />
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item className={classes.formSubsection}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="acceptReceivingSMS"
                                        name="acceptReceivingSMS"
                                        checked={values.acceptReceivingSMS}
                                        onChange={handleChange}
                                    />
                                }
                                label={
                                    <Trans t={t} i18nKey="acceptReceivingSMS" />
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item className={classes.submitContainer}>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            className={classes.submitButton}
                        >
                            {t('register')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default MojeIDRegisterForm;
