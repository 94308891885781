import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { ZLAHistoryDto } from "../../../types/sickLeave";
import useStyles from "./SickLeaveTabStyles";
import AppointmentTimeDetails from "../../common/Appointment/AppointmentTimeDetails/AppointmentTimeDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { DateTime } from "luxon";
import DateItem from "../DateItem/DateItem";
import { getRelativeDay } from "../../../utils/date";
import { isNotNullOrUndefined } from "../../../utils/lang";
import { LARGE_DESKTOP_BREAKPOINT } from "../../../const/sizes";
import { SickLeaveHistoryAccordion } from "../SickLeaveHistoryAccordion/SickLeaveHistoryAccordion";
import { SickLeaveHistoryAccordionSummary } from "../SickLeaveHistoryAccordion/SickLeaveHistoryAccordionSummary";


interface Props {
    zla: ZLAHistoryDto;
    handleOnCancel: (zla:ZLAHistoryDto)=>void;
    disabled:boolean;
}
const SickLeaveTab = ({zla, handleOnCancel, disabled}:Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation(i18nNamespaces.L4);
    
    const formatedDate = DateTime.fromISO(zla.createdDate);
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    
    return (
        <div className={classes.container}>
            {
                !smUp && (
                    <Box className={classes.timeDetailsSquareWrapper}>
                        <DateItem
                            isNow={false}
                            isInTheFuture={false}
                            date={formatedDate}
                        />
                </Box>
               
            )}
         <SickLeaveHistoryAccordion square defaultExpanded={false} elevation={0} >
                <SickLeaveHistoryAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        
                        {smUp ? (
                            <Grid container direction="row">
                            
                                <Box className={classes.timeDetailsSquareWrapper}>
                                    <DateItem
                                        square
                                        isNow={false}
                                        isInTheFuture={false}
                                        date={formatedDate}
                                    />
                                </Box>
                                
                            
                                <Grid item xs={4} alignItems="center">
                                    <Box style={{fontWeight:'bold'}}>{zla.medicName} {zla.medicSurname}</Box>
                                    <Box> {t("from")}: {getRelativeDay(DateTime.fromISO(zla.unableToWorkFromDate), 'dd-MM-yyyy')}</Box>
                                    <Box> {t("to")}: {getRelativeDay(DateTime.fromISO(zla.unableToWorkToDate), 'dd-MM-yyyy')}</Box>        
                                </Grid>
                                <Grid item container xs={5} className={classes.status}>       
                                    <Box display={"flex"} alignItems={"center"} >
                                        <Box >{zla.status}</Box>
                                        <Button 
                                            className={classes.cancelButton}
                                            onClick={()=>handleOnCancel(zla) }
                                            disabled= {zla.status=="Anulowane" || disabled}
                                            >{t('cancel')}</Button>           
                                    </Box>                        
                                </Grid>
                            
                                </Grid>
                        ) : 
                        (   
                            <Box>  
                                <Box style={{fontWeight:'bold'}}>
                                    {zla.medicName} {zla.medicSurname}
                                </Box>
                                <Grid container direction="row" spacing={1}>                            
                                    <Grid item>
                                        {t("from")}: {getRelativeDay(DateTime.fromISO(zla.unableToWorkFromDate), 'dd-MM-yyyy')}

                                    </Grid>
                                    <Grid item>
                                        {t("to")}: {getRelativeDay(DateTime.fromISO(zla.unableToWorkToDate), 'dd-MM-yyyy')}</Grid>     
                                </Grid>
                                <Grid container 
                                    direction="row"
                                    alignItems="center">                            
                                    <Grid item xs={8}>
                                        {zla.status}

                                    </Grid>
                                    <Grid item xs={2}> 
                                        <Button 
                                            className={classes.cancelButton}
                                            onClick={()=>handleOnCancel(zla) }
                                            disabled= {zla.status=="Anulowane" || disabled}
                                            >{t('cancel')}</Button>  
                                    </Grid>     
                                </Grid>
                            </Box>
                        )}

                    </Grid>
                </SickLeaveHistoryAccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row">
                        <Grid container direction = "column">
                            <Grid item container direction="row">
                                <Box className={classes.title}>
                                    {t("seriesAndNumber")}: 
                                </Box>
                                <Box>{zla.series}{zla.number}  </Box>
                            </Grid>
           

                            <Grid item container direction="row">
                                <Box className={classes.title}>
                                    {t('payer')} {t(zla.payerTypeId)}:
                                </Box>
                                <Box>{zla.payerId}</Box>
                            </Grid>

                            <Grid item container direction="row">
                                <Box className={classes.title}>
                                    {t('icd10Code')}:
                                </Box>
                                <Box>{zla.icD10}</Box>
                            </Grid>

                            <Grid item container direction="row">
                                <Box className={classes.title}>
                                    {t('medicNPWZ')}:
                                </Box>
                                <Box>{zla.medicNPWZ}</Box>
                            </Grid>

                            {isNotNullOrUndefined(zla.personUnderCareBirthDate) && (
                                <>
                                    <Grid item container direction="row">
                                        <Box className={classes.title}>
                                            {t('personUnderCareBirthDate')}:
                                        </Box>
                                        <Box>{zla.personUnderCareBirthDate}</Box>
                                    </Grid>
                                    <Grid item container direction="row">
                                        <Box className={classes.title}>
                                            {t('kinship')}:
                                        </Box>
                                        <Box>{t(zla.relationshipCode)}</Box>
                                    </Grid>
                                </>
                            )}

                            {zla.daysInHospital!=="0" && (
                                <>
                                     <Grid item container direction="row">
                                        <Box className={classes.title}>
                                            {t('personUnderCareBirthDate')}:
                                        </Box>
                                        <Box>{zla.daysInHospital}</Box>
                                    </Grid>
                                    <Grid item container direction="row">
                                        <Box className={classes.title}>
                                            {t('personUnderCareBirthDate')}:
                                        </Box>
                                        <Box>{zla.inHospitalFromDate}</Box>
                                    </Grid>
                                    <Grid item container direction="row">
                                        <Box className={classes.title}>
                                            {t('kinship')}:
                                        </Box>
                                        <Box>{t(zla.inHospitalToDate)}</Box>
                                    </Grid>
                                </>
                            )}
                            <Grid item container direction="row">
                                <Box className={classes.title}>
                                    Wystawione w Global Teleclinic
                                </Box>
                                <Box>{zla.issuedByGTA ? t('true') : t('false')}</Box>
                            </Grid>
                        </Grid>

                    </Grid>
                </AccordionDetails>
                </SickLeaveHistoryAccordion>
    </div>)
}

export default SickLeaveTab;