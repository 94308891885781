import {Pagination} from '@material-ui/lab';

type PagingProps = {
    page: number;
    size: number;
    count: number;
    onChange: (page: number) => void;
};

const Paging = ({page, size, count, onChange}: PagingProps) => {
    return (
        <Pagination
            color="secondary"
            page={page + 1}
            count={Math.ceil(count / size)}
            onChange={(_, page) => onChange(page - 1)}
        />
    );
};

export default Paging;
