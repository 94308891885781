import {makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';

export default makeStyles((theme: Theme) => ({
    container: {
        marginBottom: mobile.contentLargerSpacing,
        paddingTop:24
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            margin: `0 ${desktop.contentSmallerSpacing} ${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
        },
    },
}));
