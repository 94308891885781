import {rawHttp} from '../../services/http';
import {DELETE_REQUESTS, getTimeslotsEndpoint} from './timeslotsEndpoints';
import axios, {AxiosError} from 'axios';

const DEFERRED_RESPONSE_INTERVAL = 1000;
class TimeslotsApi {
    getDeleteTimeslotsDeferredResponse(token: string) {
        return rawHttp.get(DELETE_REQUESTS + token);
    }
    async deleteTimeslots(date: string, all: boolean) {
        const token = (await rawHttp.delete(getTimeslotsEndpoint(date, all)))
            .data;

        return new Promise((resolve, reject) => {
            const interval: ReturnType<typeof setInterval> = setInterval(
                async () => {
                    try {
                        const response =
                            await this.getDeleteTimeslotsDeferredResponse(
                                token,
                            );
                        resolve(response?.data);
                        clearInterval(interval);
                    } catch (error) {
                        console.error(error);
                        if (
                            axios.isAxiosError(error) &&
                            error.response.status !== 404
                        ) {
                            reject(error?.response.data as []);
                            clearInterval(interval);
                        }
                    }
                },
                DEFERRED_RESPONSE_INTERVAL,
            );
        });
    }
}

export default new TimeslotsApi();
