import {Box, Grid} from '@material-ui/core';
import {Button, Loading} from '../..';
import {ErrorImage, FinishScheduleImage} from '../../../assets';
import useStyles from './PaymentContinuePageStyles';

type PaymentContinuePageProps = {
    title: string;
    information: string;
    isSuccess: boolean;
    actionText?: string;
    onAction?: () => void;
    isLoading?: boolean;
};

const PaymentContinuePage = ({
    title,
    information,
    isSuccess,
    actionText,
    onAction,
    isLoading = false,
}: PaymentContinuePageProps) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.container}>
            <Box className={classes.header}>{title}</Box>
            <Box className={classes.information}>{information}</Box>
            <img
                src={isSuccess ? FinishScheduleImage : ErrorImage}
                alt={isSuccess ? 'Payment succeeded' : 'Payment failed'}
                className={classes.image}
            />
            {actionText && (
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={onAction}
                >
                    {actionText}
                </Button>
            )}
            <Loading loading={isLoading} />
        </Grid>
    );
};

export default PaymentContinuePage;
