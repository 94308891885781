import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {formatPrice} from '../../../utils/price';
import useStyles from './SubscriptionPaymentPriceStyles';

type SubscriptionPaymentPriceProps = {
    individualPackageName: string;
    price: number;
};

const SubscriptionPaymentPrice = ({
    individualPackageName,
    price,
}: SubscriptionPaymentPriceProps) => {
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);

    const classes = useStyles();

    const getPriceText = (price: number) =>
        `${formatPrice(price)} zł / ${commonT('monthly')}`;

    return (
        <Box className={classes.priceSection}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.priceContainer}
            >
                <Box className={classes.priceText}>{individualPackageName}</Box>
                <Box className={classes.price}>{getPriceText(price)}</Box>
            </Grid>
        </Box>
    );
};

export default SubscriptionPaymentPrice;
