import { Box, Grid } from "@material-ui/core";
import useStyles from "./DiagnosticOrdersStyles";
import useAppointmentsStyles from '../../../appointmentsCalendar/appointmentsCalendarSectionStyles';
import Loading from "../../../common/Loading/Loading";
import { useEffect, useState } from "react";
import Paging from "../../../common/Paging/Paging";
import { useAppDispatch, useAppSelector } from "../../../../hooks/customReduxHooks";
import { fetchOrderedDiagnosticOrderHeadersWithResult, pageSpecified, selectOrderedDiagnosticOrderHeadersWithResult, selectOrderedDiagnosticOrderHeadersWithResultCount, selectOrderedDiagnosticOrderHeadersWithResultIds, selectOrderedDiagnosticOrderHeadersWithResultPage, selectOrderedDiagnosticOrderHeadersWithResultSize } from "../../../../store/referrals/orderedDiagnosticOrderHeaderWithResultsSlice";
import DiagnosticOrderItem from "../DiagnosticOrderItem/DiagnosticOrderItem";
import { number } from "yup";

interface Props  {
    patientId: string;
    childId: number;
}

const DiagnosticOrders = ({patientId, childId}: Props) => {
    const classes = useStyles();
    const appointmentsClasses = useAppointmentsStyles();
    const [loading, setLoading] = useState<boolean>(false);


    const dispatch = useAppDispatch();

    const diagnosticOrderHeadersIds = useAppSelector(selectOrderedDiagnosticOrderHeadersWithResultIds);

    const diagnosticOrderHeaderPage = useAppSelector(
        selectOrderedDiagnosticOrderHeadersWithResultPage,
    );
    const diagnosticOrderHeaderSize = useAppSelector(
        selectOrderedDiagnosticOrderHeadersWithResultSize,
    );
    const diagnosticOrderHeaderCount = useAppSelector(
        selectOrderedDiagnosticOrderHeadersWithResultCount,
    );

    const pageChangeHandler = (page: number) => {
        dispatch(pageSpecified(page));
        dispatch(fetchOrderedDiagnosticOrderHeadersWithResult({patientId: patientId, childId:childId})); 
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await dispatch(fetchOrderedDiagnosticOrderHeadersWithResult({patientId: patientId, childId:childId})); 
            setLoading(false);
        })();
    }, [dispatch, setLoading]);


    return(
        <Box >
            <Loading loading={loading} withBackdrop />

           {diagnosticOrderHeadersIds.map(orderHeaderId => (
                <DiagnosticOrderItem
                    key={orderHeaderId}
                    diagnosticServiceOrderId = {orderHeaderId as string}
                    
                />
            ))}
            <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.pagingWrapper}
                >
            <Paging
                page={diagnosticOrderHeaderPage}
                size={diagnosticOrderHeaderSize}
                count={diagnosticOrderHeaderCount}
                onChange={pageChangeHandler}
            /></Grid>
        </Box>
    )
}

export default DiagnosticOrders;