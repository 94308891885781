import {withStyles} from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { MAGENTA, WHITE } from "../../../const/colors";
import { Accordion } from "@material-ui/core";

export const SickLeaveHistoryAccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: WHITE,   
        padding:'0px',
        [theme.breakpoints.up('md')]: {
            padding:0,
        },
        '& .MuiAccordionSummary-expandIcon': {
            position: 'absolute',
            top: '8px',
            right: '16px',
            [theme.breakpoints.up('md')]: {
            }
        },
    },
    content: {
        margin: '0',
        padding: '16px 55px 16px 16px',
        [theme.breakpoints.up('md')]: {
            padding:0,
        },
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);