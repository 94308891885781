import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {MAGENTA, WHITE} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                minHeight: `calc(100vh - 128px)`,
            },
            paddingLeft: 30,
            paddingRight: 30,
        },
        title: {
            marginTop: 32,
            fontSize: 16,
            paddingLeft: 20,
            paddingRight: 16,
            fontWeight: 'bold',
            maxWidth: 820,
            whiteSpace: 'pre-line',
        },
        description: {
            maxWidth: 820,
            marginTop: 56,
            marginBottom: 80,
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16,
        },
        icons: {
            color: MAGENTA,
            marginRight: 10,
        },
        innerContainer: {
            display: 'flex',
            alignItems: 'center',
        },

        [theme.breakpoints.up('sm')]: {
            title: {
                fontSize: 56,
                whiteSpace: 'pre-line',
            },
            description: {
                fontSize: 20,
            },
        },
    }),
);

export default useStyles;
