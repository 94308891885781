import React, {useEffect, useState} from 'react';
import appointmentsProvider from '../../services/appointmentsProvider';
import {Loading, ReplaceAppointment} from '../../components';
import useQuery from '../../hooks/useQuery';
import {parseInt} from 'lodash-es';
import {MedicalServiceDto} from '../../types/timeSlot';
import medicalServiceProvider from '../../services/medicalServiceProvider';
import useMedic from '../../hooks/medic/useMedic';
import {Box, Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

const FindTimeSlotsPage = () => {
    const [appointmentDetails, setAppointmentDetails] = useState(null);
    const [medicalService, setMedicalService] =
        useState<MedicalServiceDto>(null);

    const query = useQuery();
    const appointmentId = query.get('appointmentId');
    const medicalServiceId = parseInt(query.get('medicalServiceId'));
    const medicId = query.get('medicId');
    const referralId = parseInt(query.get('referralId'));
    const childId = parseInt(query.get('childId'));

    const {medic, medicLoading, medicLoadingError, fetchMedic} = useMedic();

    const fetchAppointmentData = async () => {
        const appointment = await appointmentsProvider.getAppointment(
            appointmentId,
        );
        setAppointmentDetails(appointment);
    };

    const fetchMedicalService = async () => {
        const medicalServiceData =
            await medicalServiceProvider.getMedicalService(medicalServiceId);
        setMedicalService(medicalServiceData);
    };

    useEffect(() => {
        if (appointmentId) {
            fetchAppointmentData();
        } else {
            fetchMedic(medicId);
            fetchMedicalService();
        }
    }, []);

    return (
        <Box>
            {!medicLoadingError ? (
                appointmentDetails ? (
                    <ReplaceAppointment
                        medic={appointmentDetails.medic}
                        appointmentId={appointmentId}
                        medicalService={appointmentDetails.medicalService}
                        currentDateTime={appointmentDetails.currentDateTime}
                        childId={childId}
                        referralId={referralId}
                    />
                ) : (
                    medic &&
                    medicalService && (
                        <ReplaceAppointment
                            medic={{
                                userId: medic.userId,
                                firstName: medic.name,
                                surname: medic.surname,
                                title: medic.title,
                                photoUrl: medic.image,
                                bio: medic.bio,
                                reviewRate: medic.reviewRate,
                                reviewCount: medic.reviewCount,
                            }}
                            medicalService={medicalService}
                            childId={childId}
                            referralId={referralId}
                        />
                    )
                )
            ) : (
                <Snackbar
                    open={true}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert severity="error">{medicLoadingError}</Alert>
                </Snackbar>
            )}
            <Loading withBackdrop loading={medicLoading} />
        </Box>
    );
};

export default FindTimeSlotsPage;
