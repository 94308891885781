import {makeStyles, Theme} from '@material-ui/core';
import {
    CONVERSATION_BACKGROUND,
    LIGHT_GRAY,
    PRIMARY,
} from '../../../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    video: {
        position: 'absolute',
        right: 16,
        bottom: 16,
        objectFit: 'cover',
        height: 120,
        zIndex: 100,
        borderRadius: 3,
    },
    mirrorImage: {
        transform: `rotateY(180deg)`,
    },
    videoCallWrapper: {
        flexGrow: 1,
        height: 'calc(100vh - 60px)',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    remoteParticipantVideo: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100% - 170px)',
        objectFit: 'cover',
        [theme.breakpoints.up('md')]: {
            minWidth: '100%',
            minHeight: '100%',
        },
    },
    callEndButton: {
        width: 48,
        height: 48,
        display: 'flex',
        minHeight: 'auto',
        minWidth: 'auto',
    },
    statusScreen: {
        backgroundColor: CONVERSATION_BACKGROUND,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: 'white',
        height: '100vh',
        flexShrink: 1,
    },
    disabledVideo: {
        backgroundColor: PRIMARY,
        width: 341,
        height: 240,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        color: LIGHT_GRAY,
    },
    muteIcon: {
        position: 'absolute',
        top: 16,
        left: 16,
        zIndex: 200,
    },
    chatWrapper: {
        marginRight: 0,
        width: 679,
        flexShrink: 0,
        height: 'calc(100vh - 64px)',
    },
}));

export default useStyles;
