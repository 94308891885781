import {createContext, useContext} from 'react';
import {ReferralsForMedicalTestsState} from './useReferralsForMedicalTests';

export const ReferralsForMedicalTestsContext =
    createContext<ReferralsForMedicalTestsState | null>(undefined);

const useReferralsForMedicalTestsContext = () => {
    const context = useContext(ReferralsForMedicalTestsContext);
    if (context === undefined) {
        throw new Error('usePatientReferralsForMedicalTestsContext was used outside of its Provider');
    }
    return context;
};

export default useReferralsForMedicalTestsContext;