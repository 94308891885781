import {FC, MouseEvent} from 'react';
import useStyles from '../CustomLink/CustomLinkStyles';
import {Link} from '@material-ui/core';
import clsx from 'clsx';

// For details visit page - https://mathiasbynens.github.io/rel-noopener/
const NEW_TAB_LINK_SECURITY_REL = 'noopener noreferrer';

interface Props {
    href: string;
    className?: string;
    color?: string;
}

const NewTabLink: FC<Props> = props => {
    const {href, className, children, color} = props;

    const classes = useStyles();

    return (
        <Link
            className={clsx(classes.menuButton, className)}
            style={{color}}
            href={href}
            target="_blank"
            rel={NEW_TAB_LINK_SECURITY_REL}
        >
            {children}
        </Link>
    );
};

export default NewTabLink;
