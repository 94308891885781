import { Box, Button, Grid, IconButton, Snackbar } from "@material-ui/core";
import { ZLADto, ZUSZLADto } from "../../../types/sickLeave";
import useStyles from "./NewSickLeaveDraftStyles";
import { Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { isNotNullOrUndefined } from "../../../utils/lang";
import { Alert } from "@material-ui/lab";
import { CustomAlert } from "../../common/feedback";
import { useEffect, useState } from "react";


type NewSickLeaveDraftProps = {
    draft: ZUSZLADto,
    onDelete: () => void,
    disable:boolean,
    handleOnSignAndCreate: ()=>void;
};


const NewSickLeaveDraft = ({draft, onDelete, disable, handleOnSignAndCreate}: NewSickLeaveDraftProps) => 
{
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.L4);

    const getAddress = () =>{
        if (isNotNullOrUndefined(draft.isuredAddressApartmentNumber))
            return (
                <Box>
                    {draft.isuredAddressStreet} {draft.isuredAddressHouseNumber}/{draft.isuredAddressApartmentNumber} {draft.isuredAddressPostCode} {draft.isuredAddressCity}
                     
                </Box>)
        else
            return (
                <Box>
                    {draft.isuredAddressStreet} {draft.isuredAddressHouseNumber} {draft.isuredAddressPostCode}{draft.isuredAddressCity}
                </Box>)    
    }

    //temporary layout
    return (
        <Box>
            <Box className={classes.iconContainer}>
                <IconButton 
                    onClick={() => onDelete()}>
                    <Delete />
                </IconButton>         
            </Box>
            
            <Grid container direction={'column'} className={classes.container}>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t("dateFrom")}: 
                    </Box>
                    <Box>{draft.unableToWorkFromDate}</Box>
                </Grid>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t("dateTo")}: 
                    </Box>
                    <Box>{draft.unableToWorkToDate}</Box>
                </Grid>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t("icd10Code")}: 
                    </Box>
                    <Box>{draft.icd10}</Box>
                </Grid>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t("medicalRecommendations")}: 
                    </Box>
                    <Box>{t(draft.medicalRecommendation)}</Box>
                </Grid>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t("stayInStationaryZOZ")}: 
                    </Box>
                    <Box>{t(draft.stayInStationaryZOZ)}</Box>
                </Grid>
            </Grid>
            

            <Grid container direction={'column'} className={classes.container}>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t("nameAndSurname")}: 
                    </Box>
                    <Box>{draft.isuredName} {draft.isuredSurname}</Box>
                </Grid>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        Pesel: 
                    </Box>
                    <Box>{draft.isuredPesel}</Box>
                </Grid>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t('address')}: 
                    </Box>
                    {getAddress()}
                
                </Grid>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t('insurancePlaces')} 
                    </Box>
                    <Box>{draft.isuredPlaceOfInsurance}</Box>
                </Grid>
                {draft.personUnderCareBirthDate && (
                    <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t("personUnderCareBirthDate")}: 
                    </Box>
                    <Box>{draft.personUnderCareBirthDate}</Box>
                </Grid>
                )}
            </Grid>
            
            <Grid container direction={'column'} className={classes.container}>

                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t('payer')} {draft.payerIdType}:
                    </Box>
                    <Box>{draft.payerId}</Box>
                </Grid>
                <Grid item container direction="row">
                    <Box className={classes.title}>
                        {t('notSendInformationToPayer')}:
                    </Box>
                    <Box>{t(draft.doNotSendInformationToPayer)}</Box>
                </Grid>            
            </Grid>
            <Button
                variant={'contained'}
                color={'secondary'}
                style={{marginTop: 10, fontWeight: 'bold'}}
                onClick={handleOnSignAndCreate}
                fullWidth
                disabled={disable}
            >
                {t('signAndIssue')}
            </Button>
        </Box>
    )
};

export default NewSickLeaveDraft;