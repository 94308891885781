import {Box, createStyles, Grid, makeStyles, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {WHITE} from "../../../../const/colors";
import MedicalServiceSelection from "../MedicalServiceSelection/MedicalServiceSelection";
import {fetchMedics, selectDefaultMedic} from "../../../../store/referrals/makePatientReferralSlice";
import {useCallback} from "react";
import {useAppDispatch} from "../../../../hooks/customReduxHooks";
import TagSelection from "../TagSelection/TagSelection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 16,
            background: WHITE,
            [theme.breakpoints.up('md')]: {
                padding: '0 0 16px 0',
                background: 'transparent',
            },
        }
    }),
);

const MedicFilters = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useAppDispatch();

    const dispatchFetchMedics = useCallback((medicalServiceId: number, tagIds: number[]) => {
        return dispatch(
            fetchMedics({medicalServiceId, tagIds})
        );
    }, [dispatch]);

    const fetchMedicAsync = useCallback(async (medicalServiceId: number, tagIds: number[]) => {
        if (isDesktop) {
            await dispatchFetchMedics(medicalServiceId, tagIds);
            dispatch(selectDefaultMedic());
        } else {
            dispatchFetchMedics(medicalServiceId, tagIds);
        }
    }, [dispatch, isDesktop, dispatchFetchMedics]);

    return (
        <Box className={classes.container}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                >
                    <MedicalServiceSelection fetchMedicAsync={fetchMedicAsync} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ marginTop: 16 }}
                >
                    <TagSelection fetchMedicAsync={fetchMedicAsync} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default MedicFilters;