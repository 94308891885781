import {makeStyles, Theme} from '@material-ui/core';
import {desktop, LARGE_DESKTOP_BREAKPOINT, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) => ({
    appointmentHeader: {
        fontSize: mobile.defaultFontSize,
        padding: `${mobile.contentSmallerSpacing} ${mobile.contentLargerSpacing}`,
    },
    appointmentHeaderTime: {
        fontWeight: 'bold',
    },
    appointmentPhoto: {
        width: '70px',
        height: '70px',
    },
    statusesContainer: {
        margin: '5px 0 21px 86px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    appointmentState: {
        margin: '0 8px 0 0',
    },
    [theme.breakpoints.up('sm')]: {
        appointmentHeader: {
            width: 100,
            flexDirection: 'column',
            justifyContent: 'center',
        },
        appointmentHeaderDate: {
            fontSize: '14px',
        },
        appointmentHeaderTime: {
            fontSize: '24px',
        },
        appointmentPhoto: {
            width: '88px',
            height: '88px',
        },
        appointmentDetails: {
            paddingLeft: desktop.contentSmallerSpacing,
        },
        statusesContainer: {
            margin: '5px 0 21px 220px',
        },
    },
    [theme.breakpoints.up('md')]: {
        arrowIcon: {
            cursor: 'pointer',
        },
    },
    [`@media ${LARGE_DESKTOP_BREAKPOINT}`]: {
        statusesContainer: {
            margin: '0 0 0 8px',
            flexDirection: 'column',
            alignItems: 'flex-end',
            flex: '0 0 auto',
            flexWrap: 'nowrap',
        },
        appointmentState: {
            margin: '4px 0 0 0',
        },
    },
}));

export default useStyles;
