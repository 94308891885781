import React, {FormEvent, useState} from 'react';
import useStyles from './EditBuyerDataFormStyles';
import {BuyerDataForm} from '../../index';
import SubscriptionPaymentCard from '../../subscriptionPayment/SubscriptionPaymentCard/SubscriptionPaymentCard';
import CustomButton from '../../common/Button/Button';
import {useBuyerDataFrom} from '../../../hooks/useBuyerDataFrom';
import {BuyerDataDto} from '../../../types/buyerData';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

interface EditBuyerDataFormProps {
    onSubmit: (buyerData: BuyerDataDto) => void;
}

const EditBuyerDataForm = ({onSubmit}: EditBuyerDataFormProps) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const [requiredCheckForced, setRequiredCheckForced] = useState(false);
    const buyerDataFormConfig = useBuyerDataFrom(onSubmit);

    const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
        setRequiredCheckForced(true);
        buyerDataFormConfig.handleSubmit(e);
    };

    return (
        <form onSubmit={handleSubmit}>
            <BuyerDataForm
                buyerDataFormConfig={buyerDataFormConfig}
                className={classes.buyerDataForm}
            />
            <SubscriptionPaymentCard
                requiredCheckForced={requiredCheckForced}
                className={classes.paymentCardForm}
            />
            <CustomButton
                color={'secondary'}
                variant={'contained'}
                type={'submit'}
            >
                {t('changeData')}
            </CustomButton>
        </form>
    );
};

export default EditBuyerDataForm;
