export const getPlacesOfInsuranceEndpoint = () =>
    `/api/Isured/insurance-places`;

export const getPayersEndpoint = (pesel:string, insurancePlace:string) =>
    `/api/Isured/${pesel}/payers?insurancePlace=${insurancePlace}`;

export const getMedicalRecommendationsEndpoint = () =>
    `/api/Isured/recommendation`;

export const getZLAsEndpoint = (pesel:string, page:number, size:number) =>
    `/api/Isured/${pesel}/zla?page=${page}&size=${size}`;
