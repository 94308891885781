import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {Logo} from '../../../assets';
import useLandingPageUrl from '../../../hooks/useLandingPageUrl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                width: 130,
            },
        },
    }),
);

const AppLogo = () => {
    const classes = useStyles();
    const {getCurrentUrl} = useLandingPageUrl();
    return (
        <img
            alt="Global TeleClinic Logo"
            src={Logo}
            className={classes.logo}
            onClick={() => (window.location.href = getCurrentUrl())}
            style={{cursor: 'pointer'}}
        />
    );
};

export default AppLogo;
