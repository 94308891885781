import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    clearAppointmentDetailsState,
    fetchAppointmentDetails,
    selectAppointmentDetails,
    selectAppointmentStatus,
    synchronizeAppointmentDetails,
} from '../../store/appointmentDetailsSlice';
import {
    Loading,
    MedicAppointmentDetailsTabs,
    MedicAppointmentDetailsAboutPatientTab,
    AppointmentFileUploadMobile,
    AppointmentFileUploadDesktop,
} from '../../components';
import {selectAttachmentsMetaData} from '../../store/appointmentAttachmentsSlice';
import {
    useGetFileUploadViewModelItems,
    useHandleUploadAsync,
} from '../../hooks/appointmentFileUpload';
import {
    usePatientDetailsForMedicDataFetchEffect,
    useAllPatientDetailsForMedicLoading,
    useAppointmentAttachmentsFetchEffect,
} from '../../hooks/appointmentDetailsHooks';
import {APPOINTMENT_DETAILS_DATA_REFRESH_TIMER} from '../../const/appointmentDetails';
import {UploadedFileComponentProps} from '../../types/fileUpload';
import MedicAppointmentBasicDetails from './components/MedicAppointmentBasicDetails';

const MedicAppointmentDetailsPage = () => {
    const {id: appointmentId} = useParams<{id: string}>();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const appointment = useAppSelector(selectAppointmentDetails);
    const medicalAppointmentStatus = useAppSelector(selectAppointmentStatus);
    const allPatientDetailsForMedicLoading =
        useAllPatientDetailsForMedicLoading();
    const handleUploadAsync = useHandleUploadAsync();
    const attachmentsMetaData = useAppSelector(selectAttachmentsMetaData);
    const fileUploadViewModelItems: UploadedFileComponentProps[] =
        useGetFileUploadViewModelItems(attachmentsMetaData, appointmentId);

    useEffect(() => {
        dispatch(
            fetchAppointmentDetails({
                appointmentId: appointmentId,
                fetchSilently: false,
            }),
        );

        const intervalId = setInterval(() => {
            dispatch(
                synchronizeAppointmentDetails({
                    appointmentId: parseInt(appointmentId),
                }),
            );
        }, APPOINTMENT_DETAILS_DATA_REFRESH_TIMER);

        return () => {
            clearInterval(intervalId);
            dispatch(clearAppointmentDetailsState());
        };
    }, [dispatch, appointmentId]);

    usePatientDetailsForMedicDataFetchEffect(appointmentId, appointment);
    useAppointmentAttachmentsFetchEffect(appointmentId);

    const loading =
        medicalAppointmentStatus === 'loading' ||
        allPatientDetailsForMedicLoading;

    const handleUpload = (files: File[]) => {
        handleUploadAsync(appointment.id, files);
    };

    return (
        <Box>
            <Loading withBackdrop={true} loading={loading} />
            <MedicAppointmentBasicDetails />
            {appointment && (
                <MedicAppointmentDetailsTabs
                    appointment={appointment}
                    source={'medicDetails'}
                    aboutPatientTabRender={() => (
                        <MedicAppointmentDetailsAboutPatientTab
                            appointment={appointment}
                        />
                    )}
                    filesTabRender={() =>
                        isMobile ? (
                            <AppointmentFileUploadMobile
                                disabled={appointment.state.isSettled}
                                filesProps={fileUploadViewModelItems}
                                fileUploadHandler={handleUpload}
                                containerStyles={{
                                    minHeight: 'calc(100vh - 105px)',
                                }}
                            />
                        ) : (
                            <AppointmentFileUploadDesktop
                                disabled={appointment.state.isSettled}
                                filesProps={fileUploadViewModelItems}
                                fileUploadHandler={handleUpload}
                                showHeader={false}
                            />
                        )
                    }
                />
            )}
        </Box>
    );
};

export default MedicAppointmentDetailsPage;
