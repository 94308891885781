import {makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';
import {WHITE} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    emptyListContainer: {
        backgroundColor: WHITE,
        padding: 0,
    },
    emptyListTitle: {
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 24,
        padding: `0 ${mobile.contentLargerSpacing}`,
    },
    [theme.breakpoints.up('md')]: {
        container: {
            margin: desktop.contentSmallerSpacing,
            marginLeft: desktop.contentLargerSpacing,
        },
        emptyListContainer: {
            backgroundColor: WHITE,
            padding: 24,
        },
        emptyListTitle: {
            padding: 0,
        },
    },
}));

export default useStyles;
