import React from 'react';
import useStyles from './PasswordChangeFinishPageStyles';
import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {FinishScheduleImage} from '../../assets';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import CustomButton from '../../components/common/Button/Button';
import {useHistory} from 'react-router';
import {LOGIN_PAGE, MEDIC_SETTINGS, USER_ACCOUNT_PAGE} from '../../const/routes';
import {useAppSelector} from '../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../store/auth';
import { MEDIC } from '../../const/auth';

const PasswordChangeFinishPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const history = useHistory();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const authUserData = useAppSelector(selectAuthUserData);

    return (
        <Grid
            container
            className={classes.container}
            alignItems={'center'}
            direction={'column'}
        >
            <Box className={classes.title}>{t('passwordHasBeenChanged')}</Box>
            <Box className={classes.subtitle}>
                {t('youCanLoginWithNewPasswordNow')}
            </Box>
            <img
                src={FinishScheduleImage}
                alt="Finish password change"
                className={classes.image}
            />
            <CustomButton
                color={'secondary'}
                variant={'contained'}
                fullWidth={!isDesktop}
                onClick={() =>
                    history.push(authUserData ? 
                        (authUserData.role === MEDIC ? MEDIC_SETTINGS : USER_ACCOUNT_PAGE) 
                        : LOGIN_PAGE)
                }
            >
                {authUserData ? t('goBackToAccount') : t('goToLogin')}
            </CustomButton>
        </Grid>
    );
};

export default PasswordChangeFinishPage;
