import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { AdditionalAuxiliaryTestsDto, AuxiliaryTestItemDto, AuxiliaryTestsDto, DecisionDto, PhysicalTestsByMedicDto, SpecialistConsultationItemDto, SpecialistConsultationsDto } from "../../../types/occupationalMedicine";
import { MutableRefObject, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';
import { useFormik } from "formik";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Link, MenuItem } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { useParams } from "react-router";
import { fetchSection13Data, fetchSection14Data, fetchSection15Data, selectIsQuestionnaireFilledForMedic } from "../../../store/occupationalMedicineSlice";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { RenderGridItemKeyboardDatePicker, RenderGridItemWithRequiredKeyboardDatePicker, RenderReturnNextButtons } from "../CommonView";
import { useAppSelector } from "../../../hooks/customReduxHooks";
import CustomSelectField from "../../common/CustomSelectField/CustomSelectField";
import { DateTime } from "luxon";

const SECTION = 15;
const REQUIRED_STRING ="requiredField";

export type Props = {
    currentExpanded:number
    data:DecisionDto;
    onSubmitSection: (values:string) =>void
    onReturn: () => void;
    refSection:MutableRefObject<any>
}

const DecisionComponent = ({currentExpanded, data, onSubmitSection, refSection, onReturn}:Props) =>{
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const areOtherSeciontsFilled = useAppSelector(selectIsQuestionnaireFilledForMedic);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);

    const onSubmit = () => {

        if(values.nextExaminationAfterOneYear)
            values.nextExaminationDate = DateTime.now().plus({year:1})

        if(values.nextExaminationAfterTwoYears)
            values.nextExaminationDate = DateTime.now().plus({years:2})

        if(values.nextExaminationAfterFourYears)
            values.nextExaminationDate = DateTime.now().plus({years:4})

        onSubmitSection(JSON.stringify(values));
    }

    const {values, touched, handleChange, handleBlur, errors, handleSubmit, setFieldValue} =
        useFormik({
            initialValues: {             
                noHealthContraindicationsToWork: {
                    occured: data?.noHealthContraindicationsToWork.occured ?? false,
                    description: data?.noHealthContraindicationsToWork.description ?? ''
                },
                noHealthContraindicationsToStudy: data?.noHealthContraindicationsToStudy ?? false,

                healthContraindicationsToWork:{
                    occured: data?.healthContraindicationsToWork.occured ?? false,
                    description: data?.healthContraindicationsToWork.description ?? ''
                },
                healthContraindicationsToStudy: data?.healthContraindicationsToStudy ?? false,
                lostOfAbilityToPerformCurrentWork: data?.lostOfAbilityToPerformCurrentWork ?? false,
                healthContraindicationsToWorkDuringPregnancyOrBreastfeeding: {
                    occured: data?.healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.occured ?? false,
                    transferToAnotherJob: data?.healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.transferToAnotherJob ?? false,
                    changeOfWorkingConditions: data?.healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.changeOfWorkingConditions ?? false
                },
                transferToAnotherJobReason:{
                    occured: data?.transferToAnotherJobReason.occured ?? false,
                    harmfulToHealth: data?.transferToAnotherJobReason.harmfulToHealth ?? false ,
                    threadToYoungPerson: data?.transferToAnotherJobReason.threadToYoungPerson ?? false ,
                    suspicionOfAnOccupationalDisease: data?.transferToAnotherJobReason.suspicionOfAnOccupationalDisease ?? false ,
                    unableToWorkDueToOccupationalDisease :data?.transferToAnotherJobReason.unableToWorkDueToOccupationalDisease ?? false ,
                },
                needToUseGlasses: data?.needToUseGlasses ?? false,
                other:{
                    occured: data?.other.occured ?? false,
                    description: data?.other.description ?? ''
                },
                comments : data?.comments ?? '',
                decisionDate: data?.decisionDate ?? null,
                nextExaminationDate: data?.nextExaminationDate ?? null  as DateTime,
                nextExaminationAfterOneYear: data?.nextExaminationAfterOneYear ?? false,
                nextExaminationAfterTwoYears: data?.nextExaminationAfterTwoYears ?? false,
                nextExaminationAfterFourYears: data?.nextExaminationAfterFourYears ?? false,
                nextExaminationSelectCustomDate: data?.nextExaminationSelectCustomDate ?? false  
            },
            validationSchema: yup.object({
                noHealthContraindicationsToWork: yup.object().shape({
                    description: yup.string().when('occured', {
                        is:true,
                        then: yup.string().required(commonT(REQUIRED_STRING)),
                    })
                }),
                healthContraindicationsToWork: yup.object().shape({
                    description: yup.string().when('occured', {
                        is:true,
                        then: yup.string().required(commonT(REQUIRED_STRING)),
                    })
                }),
                other: yup.object().shape({
                    description: yup.string().when('occured', {
                        is:true,
                        then: yup.string().required(commonT(REQUIRED_STRING)),
                    })
                }),
                decisionDate:yup.date().nullable().required(commonT(REQUIRED_STRING)),
                nextExaminationDate:yup.date().nullable().when('nextExaminationSelectCustomDate', {
                    is:true,
                    then: yup.date().nullable().required(commonT(REQUIRED_STRING))
                })
                

            }),
            onSubmit,
        }); 


    const renderGridItemWithCheckBox = (idAndName:string, value:boolean, translation:string, descriptionValue?:string, descriptionId?:string, fieldTranslation?:string, disabled=false) =>
        (
            <>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={idAndName}
                                name={idAndName}
                                checked={value}
                                onChange={handleChange}
                                disabled={disabled}
                            />
                        }
                        label={
                            <Box className={classes.text}>
                                {t(translation)}
                            </Box>
                        }    
                        />
                </FormGroup>
            </Grid>  
            {
                (isNotNullOrUndefined(descriptionId) && value) &&
                (
                    
                    <Grid item xs={8}>
                        <FormGroup >
                                <Label htmlFor="jobPosition">{t(fieldTranslation)} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id={descriptionId}
                                    name={descriptionId}
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={descriptionValue}
                                    />
                            </FormGroup>
                    </Grid>
                )
            }
            </>             
        )
        
    return (
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >

                <Box className={classes.titleContainer}>
                    <h4 className={classes.sectionHeader}>
                        {t('decision')}
                    </h4>

                    {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.detailsContainer}>
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Grid container spacing={1} className={classes.gridContainer}>
                            <Grid item xs={12}>
                                <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                    {t('descisionWasMakeAbout')}
                                </Box>
                            </Grid>

                            {renderGridItemWithCheckBox("noHealthContraindicationsToWork.occured", 
                                    values.noHealthContraindicationsToWork.occured, "noHealthContraindicationsToWork",
                                    values.noHealthContraindicationsToWork.description, "noHealthContraindicationsToWork.description", "jobPosition")}  
                            
                            {renderGridItemWithCheckBox("noHealthContraindicationsToStudy", 
                                    values.noHealthContraindicationsToStudy, "noHealthContraindicationsToStudy")} 

                            {renderGridItemWithCheckBox("healthContraindicationsToWork.occured", 
                                    values.healthContraindicationsToWork.occured, "healthContraindicationsToWork",
                                    values.healthContraindicationsToWork.description, "healthContraindicationsToWork.description", "jobPosition")} 

                            {renderGridItemWithCheckBox("healthContraindicationsToStudy", 
                                    values.healthContraindicationsToStudy, "healthContraindicationsToStudy")}

                            {renderGridItemWithCheckBox("healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.occured", 
                                    values.healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.occured, "healthContraindicationsToWorkDuringPregnancyOrBreastfeeding")} 
                            {
                                values.healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.occured &&
                                <>
                                    <Grid item xs={12} >
                                        <FormGroup className={classes.subGrid}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.transferToAnotherJob"
                                                        name="healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.transferToAnotherJob"
                                                        checked={values.healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.transferToAnotherJob}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={
                                                    <Box className={classes.text}>
                                                        {t('transferToAnotherJob')}
                                                    </Box>
                                                }    
                                                />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormGroup className={classes.subGrid}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.changeOfWorkingConditions"
                                                        name="healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.changeOfWorkingConditions"
                                                        checked={values.healthContraindicationsToWorkDuringPregnancyOrBreastfeeding.changeOfWorkingConditions}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={
                                                    <Box className={classes.text}>
                                                        {t('changeOfWorkingConditions')}
                                                    </Box>
                                                }    
                                                />
                                        </FormGroup>
                                    </Grid>
                                </>
                            }   
     
                            {renderGridItemWithCheckBox("transferToAnotherJobReason.occured", 
                                    values.transferToAnotherJobReason.occured, "transferToAnotherJobReason")} 
                            {
                                values.transferToAnotherJobReason.occured &&
                                <>
                                    <Grid item xs={12} >
                                        <FormGroup className={classes.subGrid}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="transferToAnotherJobReason.harmfulToHealth"
                                                        name="transferToAnotherJobReason.harmfulToHealth"
                                                        checked={values.transferToAnotherJobReason.harmfulToHealth}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={
                                                    <Box className={classes.text}>
                                                        {t('harmfulToHealth')}
                                                    </Box>
                                                }    
                                                />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormGroup className={classes.subGrid}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="transferToAnotherJobReason.threadToYoungPerson"
                                                        name="transferToAnotherJobReason.threadToYoungPerson"
                                                        checked={values.transferToAnotherJobReason.threadToYoungPerson}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={
                                                    <Box className={classes.text}>
                                                        {t('threadToYoungPerson')}
                                                    </Box>
                                                }    
                                                />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormGroup className={classes.subGrid}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="transferToAnotherJobReason.suspicionOfAnOccupationalDisease"
                                                        name="transferToAnotherJobReason.suspicionOfAnOccupationalDisease"
                                                        checked={values.transferToAnotherJobReason.suspicionOfAnOccupationalDisease}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={
                                                    <Box className={classes.text}>
                                                        {t('suspicionOfAnOccupationalDisease')}
                                                    </Box>
                                                }    
                                                />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormGroup className={classes.subGrid}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="transferToAnotherJobReason.unableToWorkDueToOccupationalDisease"
                                                        name="transferToAnotherJobReason.unableToWorkDueToOccupationalDisease"
                                                        checked={values.transferToAnotherJobReason.unableToWorkDueToOccupationalDisease}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={
                                                    <Box className={classes.text}>
                                                        {t('unableToWorkDueToOccupationalDisease')}
                                                    </Box>
                                                }    
                                                />
                                        </FormGroup>
                                    </Grid>
                                </>
                            }   

                            {renderGridItemWithCheckBox("needToUseGlasses", 
                                    values.needToUseGlasses, "needToUseGlasses")}

                            {renderGridItemWithCheckBox("other.occured", 
                                    values.other.occured, "otherSimple",
                                    values.other.description, "values.other.description", "other")} 

                            <Grid item xs={12}>
                                <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                    {t('comments')}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup >
                                        <CustomTextField 
                                            fullWidth 
                                            id="comments"
                                            name="comments"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.comments}
                                            />
                                    </FormGroup>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                    {t('nextExamination')}:
                                </Box>
                            </Grid>

                            {renderGridItemWithCheckBox("nextExaminationAfterOneYear", 
                                    values.nextExaminationAfterOneYear, "afterOneYear", null, null, null, 
                                    values.nextExaminationAfterTwoYears || values.nextExaminationAfterFourYears || values.nextExaminationSelectCustomDate )}  
                            
                            {renderGridItemWithCheckBox("nextExaminationAfterTwoYears", 
                                    values.nextExaminationAfterTwoYears, "afterTwoYears", null, null, null, 
                                    values.nextExaminationAfterOneYear || values.nextExaminationAfterFourYears || values.nextExaminationSelectCustomDate )} 

                            {renderGridItemWithCheckBox("nextExaminationAfterFourYears", 
                                    values.nextExaminationAfterFourYears, "afterFourYears", null, null, null, 
                                    values.nextExaminationAfterTwoYears || values.nextExaminationAfterOneYear || values.nextExaminationSelectCustomDate )} 
                        
                            {renderGridItemWithCheckBox("nextExaminationSelectCustomDate", 
                                    values.nextExaminationSelectCustomDate, "nextExaminationSelectCustomDate", null, null, null, 
                                    values.nextExaminationAfterTwoYears || values.nextExaminationAfterFourYears || values.nextExaminationAfterOneYear )} 

                            {values.nextExaminationSelectCustomDate 
                                && RenderGridItemKeyboardDatePicker
                                (classes, handleBlur,"nextExaminationDate", t('nextExaminationDate'),
                                    values.nextExaminationDate, setFieldValue )}             

                            {RenderGridItemWithRequiredKeyboardDatePicker(classes, handleBlur,"decisionDate", t('decisionDate'),
                                     values.decisionDate, setFieldValue, touched.decisionDate, 
                                     errors.decisionDate, t(errors.decisionDate as string)  )}

                           
                           
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.buttonReturn}
                                    variant={'outlined'}
                                    color={'secondary'}
                                    onClick={onReturn}
                                    >
                                    {t('return')}
                                </Button>
                            </Grid>
                            
                            <Grid item xs={6}>
                              <Button
                                    type="submit"
                                    className={classes.buttonNext}
                                    variant={'contained'}
                                    color={'secondary'}
                                    disabled={!areOtherSeciontsFilled}>
                                    {t('send')}
                                </Button> 
   
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </AccordionDetails>
        </Accordion>
    
    )
}
export default  DecisionComponent;