import {memo} from 'react';
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orderInfoRoot: {
            textAlign: 'center',
            marginTop: -30,
        },
        orderInfoHeaderText: {
            fontSize: 36,
            lineHeight: '48px',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                lineHeight: '40px',
            },
        },
        thanksGivingText: {
            marginTop: 26,
            fontSize: 16,
            lineHeight: '24px',
        },
    }),
);

const MedicalTestsOrderedInfo = memo(function MedicalSearchFilters() {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.REFERRALS);
    const {t: tPaymentContinue} = useTranslation(i18nNamespaces.APPOINTMENT_PAYMENT_CONTINUE);

    return (
        <Box className={classes.orderInfoRoot}>
            <Box className={classes.orderInfoHeaderText}>
                {t('medicalTestHasBeenOrdered')}
            </Box>
            <Box className={classes.thanksGivingText}>
                {tPaymentContinue('thankYou')}
            </Box>
        </Box>
    )
});

export default MedicalTestsOrderedInfo;