import {useCallback, useMemo, useState} from 'react';
import {isArray} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    DiagnosticServicePriceDto,
    getLaboratoryAddresses,
    GetLaboratoryAddressesParams,
    LaboratoryAddressDto
} from '../../../services/diagnostic/laboratoryAddressesProvider';

export interface LaboratoryAddressesState {
    laboratoryAddresses: LaboratoryAddressDto[];
    diagnosticServicePrices: DiagnosticServicePriceDto[];
    laboratoryAddressesLoading: boolean;
    laboratoryAddressesError: string | null;
    selectedLaboratoryAddress: LaboratoryAddressDto;
    fetchLaboratoryAddresses: (params: GetLaboratoryAddressesParams) => void
    setSelectedLaboratoryAddress: (value: LaboratoryAddressDto) => void;
    resetLaboratoryAddressesState: () => void;
}

const useLaboratoryAddresses = () => {
    // laboratoryAddresses and diagnosticServicePrices are fetched in one request
    const [laboratoryAddresses, setLaboratoryAddresses] = useState<LaboratoryAddressDto[]>([]);
    const [diagnosticServicePrices, setDiagnosticServicePrices] = useState<DiagnosticServicePriceDto[]>([]);
    const [laboratoryAddressesLoading, setLaboratoryAddressesLoading] = useState(false);
    const [laboratoryAddressesError, setLaboratoryAddressesError] = useState<string>(null);

    const [selectedLaboratoryAddress, setSelectedLaboratoryAddress] = useState<LaboratoryAddressDto>(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const fetchLaboratoryAddresses = useCallback(
        async (params: GetLaboratoryAddressesParams) => {
            setLaboratoryAddressesLoading(true);
            try {
                const {data} = await getLaboratoryAddresses(params);
                if (!isArray(data.laboratoryAddresses) || !isArray(data.diagnosticServicePriceLists)) {
                    throw new Error();
                }
                setLaboratoryAddresses(data.laboratoryAddresses);
                setDiagnosticServicePrices(data.diagnosticServicePriceLists);
                setLaboratoryAddressesError(null);
            } catch {
                setLaboratoryAddresses([]);
                setDiagnosticServicePrices([]);
                setLaboratoryAddressesError(tErrors('fetchingDataFromServerError'));
            } finally {
                setLaboratoryAddressesLoading(false);
            }
        },
        []
    );

    const resetLaboratoryAddressesState = useCallback(() => {
        setLaboratoryAddresses([]);
        setDiagnosticServicePrices([]);
        setLaboratoryAddressesLoading(false);
        setLaboratoryAddressesError(null);
        setSelectedLaboratoryAddress(null);
    }, []);

    const value = useMemo<LaboratoryAddressesState>(
        () => ({
            laboratoryAddresses,
            diagnosticServicePrices,
            laboratoryAddressesLoading,
            laboratoryAddressesError,
            selectedLaboratoryAddress,
            // methods
            fetchLaboratoryAddresses,
            setSelectedLaboratoryAddress,
            resetLaboratoryAddressesState
        }),
        [
            laboratoryAddresses,
            diagnosticServicePrices,
            laboratoryAddressesLoading,
            laboratoryAddressesError,
            selectedLaboratoryAddress,
            // methods
            fetchLaboratoryAddresses,
            resetLaboratoryAddressesState
        ]
    );

    return value;
}

export default useLaboratoryAddresses;