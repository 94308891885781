import {makeStyles} from '@material-ui/styles';
import { desktop, mobile } from '../../../const/sizes';
import { Theme } from '@material-ui/core';
import { RED, WHITE } from '../../../const/colors';

const useStyles = makeStyles((theme:Theme) => ({
    // container:{
    //     marginTop: desktop.contentLargerSpacing,
    //     paddingBottom: desktop.contentSmallerSpacing,
    //     display:'flex',
    //     flexWrap:'wrap',
    //     [theme.breakpoints.down('sm')]: {
    //         marginTop: desktop.contentSmallerSpacing,
    //     },
    // },
    container:{
        marginTop: mobile.contentLargerSpacing,
        [theme.breakpoints.up('sm')]: {
            marginTop: desktop.contentLargerSpacing,
            padding: '0',
            display:'flex',
            flexWrap:'wrap',
            
        
        },
    },
    button:{
        width:'100%',
        marginTop:'29px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
    },
    input:{
        width:'100%',
    },
    buttonContainer:{
        display:'flex',
        alignItems:'center',
    },

    sectionHeader: {
        margin: '0',
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            lineHeight: '32px',
        },
    },
    section: {
        padding: '16px',
        backgroundColor: WHITE,
    },
    titleContainer:{
        display:'flex',
        alignItems:'center',
    },
    alertIcon:{
        color:RED,
        marginLeft:'16px'
    }
}));

export default useStyles;
