import {Box, Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Loading} from '../../../index';
import AttachmentsContainer from '../../attachments/AttachmentsContainer/AttachmentsContainer';
import {Conversation} from '@twilio/conversations/lib/conversation';
import {BACKGROUND_PRIMARY, WHITE} from "../../../../const/colors";

interface Props {
    handleClose?: () => void;
    uploadFileOpen: boolean;
    mobileView?: boolean;
    loading?: boolean;
    conversation: Conversation;
    error?: string;
    setError: (error: string) => void;
}
const AttachmentConversation = ({
    handleClose,
    uploadFileOpen,
    mobileView,
    loading,
    conversation,
    setError,
    error,
}: Props) => {
    return (
        <div>
            <Loading loading={loading} withBackdrop />
            {conversation &&
                (mobileView ? (
                    <AttachmentsContainer
                        conversation={conversation}
                        handleClose={handleClose}
                        mobileView
                    />
                ) : (
                    uploadFileOpen && (
                        <Box style={{
                            backgroundColor: WHITE,
                            height: 'calc(100vh - 64px)',
                            overflowY: 'auto',
                        }}>
                            <Box style={{
                                backgroundColor: BACKGROUND_PRIMARY,
                            }}>
                                <AttachmentsContainer
                                    conversation={conversation}
                                    handleClose={handleClose}
                                />
                            </Box>
                        </Box>
                    )
                ))}
            <Snackbar
                open={!!error}
                autoHideDuration={5000}
                onClose={() => setError('')}
            >
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AttachmentConversation;
