import {WORKING_TIME_SETTLEMENTS, YEARS_FOR_WORKING_TIME_SETTLEMENTS} from "./endpoints";
import {http} from "./http";
import {MedicWorkingTimeSettlement} from "../types/medicWorkingTimeSettlements";

class MedicWorkingTimeSettlementsProvider {
    async getYearsForWorkingTimeSettlements() {
        return (
            await http.get<number[]>(YEARS_FOR_WORKING_TIME_SETTLEMENTS)
        ).data
    }

    async getWorkingTimeSettlementsByYear(year: number) {
        return (
            await http.get<MedicWorkingTimeSettlement[]>(`${WORKING_TIME_SETTLEMENTS}/${year}`)
        ).data
    }

    async fetchDownloadLinkForWorkingTimeSettlement(year: number, month: number) {
        return (
            await http.get<string>(`${WORKING_TIME_SETTLEMENTS}/${year}/${month}`)
        ).data
    }
}

export default new MedicWorkingTimeSettlementsProvider();