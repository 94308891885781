import {
    EmptyTopBar,
    Loading,
    SelectButtons,
    TwoColumnLayout,
} from '../../components';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {Box, Button} from '@material-ui/core';
import useStyles from './NewPrescriptionPageStyles';
import NewPrescriptionForm from '../../components/prescription/NewPrescriptionForm/NewPrescriptionForm';
import React, {useContext, useEffect, useState} from 'react';
import {Prescription} from '../../types/prescription';
import {v4} from 'uuid';
import prescriptionsApi from '../../api/prescriptions/prescriptionsApi';
import AppointmentsProvider from '../../services/appointmentsProvider';
import {AppointmentDetailsForPrescriptionDto} from '../../types/appointments';
import {PRESCRIPTION_ISSUED_PAGE} from '../../const/routes';
import {useHistory, useParams} from 'react-router';
import PrescriptionItem, {
    SelectedPrescription,
} from '../../components/prescription/PrescriptionItem/PrescriptionItem';
import {
    mapAppointmentDetailsToPrescription,
    mapPrescriptionToIssuePrescription,
} from '../../utils/prescription';
import {SelectButtonOption} from '../../components/common/SelectButtons/SelectButtons';
import {HistoryOutlined, LocalHospitalOutlined} from '@material-ui/icons';
import PrescriptionHistory from '../../components/prescription/PrescriptionHistory/PrescriptionHistory';
import {AlertContext} from '../../contexts/AlertContext';
import axios from 'axios';

const tabs: SelectButtonOption[] = [
    {
        value: 'DRUG_DATABASE',
        label: 'drugDatabase',
        icon: <LocalHospitalOutlined />,
    },
    {
        value: 'PRESCRIPTION_HISTORY',
        label: 'prescriptionHistory',
        icon: <HistoryOutlined />,
    },
];
const NewPrescriptionPage = () => {
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const {t: errorT} = useTranslation(i18nNamespaces.ERRORS);
    const classes = useStyles();
    const params = useParams<{appointmentId: string}>();
    const appointmentId = parseInt(params?.appointmentId);
    const history = useHistory();
    const [prescriptions, setPrescriptions] = useState<SelectedPrescription[]>(
        [],
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [appointmentDetails, setAppointmentDetails] =
        useState<AppointmentDetailsForPrescriptionDto | null>(null);
    const [selectedTab, setSelectedTab] = useState<SelectButtonOption>(tabs[0]);
    const patientOrChildDetails =
        appointmentDetails?.child || appointmentDetails?.patient;
    const topbarTitle = `${t('newPrescriptionFor')} ${
        patientOrChildDetails?.firstName
    } ${patientOrChildDetails?.surname}`;
    const tabsWithTranslations = tabs?.map(tab => ({
        ...tab,
        label: t(tab.label),
    }));
    const openAlert = useContext(AlertContext);

    const handleSubmitPrescriptionForm = (prescription: Prescription) => {
        setPrescriptions(prev => [...prev, {...prescription, id: v4()}]);
    };

    const handleDeletePrescription = (prescriptionId: string) => {
        setPrescriptions(prev =>
            prev.filter(prescription => prescription.id !== prescriptionId),
        );
    };

    const handleIssuePrescription = async () => {
        try {
            setLoading(true);
            if (!appointmentId || !appointmentDetails) return;

            const {data} = await prescriptionsApi.issuePrescription({
                ...mapAppointmentDetailsToPrescription(appointmentDetails),
                prescriptions: prescriptions.map(
                    mapPrescriptionToIssuePrescription,
                ),
            });

            if (data?.isSuccess)
                history.push(PRESCRIPTION_ISSUED_PAGE, {appointmentId});
            else throw new Error(data?.message);
        } catch (error) {
            openAlert({
                severity: 'error',
                message: errorT('unexpectedErrorOccurred'),
            });
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const {data} =
                    await AppointmentsProvider.getAppointmentDetailsForPrescription(
                        appointmentId.toString(),
                    );
                setAppointmentDetails(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const renderTab = (tab: SelectButtonOption) => {
        switch (tab.value) {
            case 'DRUG_DATABASE':
                return (
                    <>
                        <h2 style={{marginBottom: 0}}>
                            Lek {prescriptions.length + 1}
                        </h2>
                        <NewPrescriptionForm
                            onSubmit={handleSubmitPrescriptionForm}
                            patientId={appointmentDetails?.patient?.userId}
                        />
                    </>
                );
            case 'PRESCRIPTION_HISTORY':
                return (
                    <PrescriptionHistory
                        appointmentDetails={appointmentDetails}
                    />
                );
        }
    };

    return (
        <TwoColumnLayout>
            <Loading withBackdrop loading={loading} />
            <TwoColumnLayout.Header>
                <EmptyTopBar title={topbarTitle} />
            </TwoColumnLayout.Header>
            <TwoColumnLayout.Main>
                <TwoColumnLayout.LeftColumn>
                    <Box className={classes.formContainer}>
                        <SelectButtons
                            value={selectedTab}
                            options={tabsWithTranslations}
                            onChange={setSelectedTab}
                            containerStyles={classes.tabsContainer}
                            buttonStyles={classes.selectButton}
                        />

                        {renderTab(selectedTab)}
                    </Box>
                </TwoColumnLayout.LeftColumn>
                <TwoColumnLayout.RightColumn>
                    {prescriptions.length > 0 && (
                        <Box className={classes.card}>
                            {prescriptions.map((prescription, idx) => (
                                <PrescriptionItem
                                    key={prescription.id}
                                    prescription={prescription}
                                    index={idx}
                                    onDelete={handleDeletePrescription}
                                />
                            ))}
                            <Button
                                variant={'contained'}
                                color={'secondary'}
                                style={{marginTop: 10, fontWeight: 'bold'}}
                                onClick={handleIssuePrescription}
                                fullWidth
                            >
                                {t('signAndIssue')}
                            </Button>
                        </Box>
                    )}
                </TwoColumnLayout.RightColumn>
            </TwoColumnLayout.Main>
        </TwoColumnLayout>
    );
};
export default NewPrescriptionPage;
