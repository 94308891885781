import { MEDIC_INSTRUCTION } from './endpoints';
import {rawHttp} from './http';


class InstructionProvider{

    async getMedicInstructionAsync(){
        return rawHttp.get<string>(`${MEDIC_INSTRUCTION}`);
    }
}

export default new InstructionProvider();