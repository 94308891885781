export const TIME_SLOTS_SEARCH_PAGE = '/';
export const TIME_SLOTS_PAGE = '/time-slots';
export const EMPTY_TIME_SLOTS_PAGE = '/no-time-slots';
export const LOGIN_PAGE = '/login';
export const VIDEO_PAGE = '/video';
export const CHAT_PAGE = '/chat';
export const CALL_PAGE = '/call';
export const REGISTER_PAGE = '/register';
export const USER_ACCOUNT_PAGE = '/account';
export const ACCESS_DENIED_PAGE = '/access-denied';
export const MEDIC_SETTINGS = '/medic-settings';
export const EMAIL_VERIFICATION_PAGE = '/email-verification';
export const PHONE_VERIFICATION_PAGE = '/phone-verification';
export const FORCED_PASSWORD_CHANGE_PAGE = '/forced-password-change';
export const AVAILABILITY_TEMPLATE = '/availability-template';
export const MEDIC_WORKING_TIME_SETTLEMENTS = '/medic-working-time-settlements';
export const MEDIC_CALENDAR = '/medic-calendar';
export const MEDIC_CALENDAR_DATE_SELECTION = '/medic-calendar-date-selection';
export const MEDIC_CALENDAR_ADD_TIME_SLOT = '/medic-calendar/add-time-slots';
export const APPOINTMENT_CALENDAR = '/appointment-calendar';
export const PATIENT_APPOINTMENT_DETAILS_PAGE = '/patient-appointment-details';
export const MEDIC_APPOINTMENT_DETAILS_PAGE = '/medic-appointment-details';
export const PATIENT_REFERRAL_TO_MEDIC_PAGE = '/patient-referral-to-medic';
export const PATIENT_REFERRAL_SUCCESSFULLY_MADE_PAGE =
    '/patient-referral-successfully-made';
export const PATIENT_REFERRAL_FOR_MEDICAL_TESTS_PAGE =
    '/patient-referral-for-medical-tests';
export const ORDER_MEDICAL_TESTS_PAGE = '/order-medical-tests';
export const MEDICAL_TESTS_ORDER_PAYMENT_PAGE = '/medical-tests-order-payment';
export const MEDICAL_TESTS_SUCCESSFULLY_ORDERED_PAGE =
    '/medical-tests-successfully-ordered';
export const ERROR = '/error';
export const SELECT_ROLE_PAGE = '/select-role';
export const INDIVIDUAL_PACKAGES_PAGE = '/individual-packages';
export const TIME_SLOT_PAYMENT_PAGE = '/time-slots/:timeSlotId/payment';
export const APPOINTMENT_PAYMENT_CONTINUE =
    '/appointments/:appointmentId/payment-continue';
export const SUBSCRIPTION_PAYMENT_PAGE =
    '/individual-packages/:individualPackageDraftId/payment';
export const SUBSCRIPTION_PAYMENT_CONTINUE =
    '/subscriptions/:subscriptionId/payment-continue';
export const QUESTIONNAIRE_PAGE = '/questionnaire/:appointmentId';
export const REGISTER_MOJE_ID_PAGE = '/register-moje-id';
export const EVALUATION_PAGE = '/appointment-evaluation';
export const EVALUATION_FINISH = '/appointment-evaluation-finish';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const PASSWORD_CHANGE_FINISH_PAGE = '/forgot-password/finish';
export const IDENTITY_CONFIRMATION_NEEDED_PAGE =
    '/identity-confirmation-needed';
export const ACCOUNT_DATA_PAGE = '/account-data';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const ERROR_404_PAGE = '/error-404';
export const MEDIC_PROFILE_PAGE = '/medic-profile';
export const FIND_TIME_SLOTS = '/find-time-slots';
export const FIND_OCCUPATIONAL_MEDICINE_TIMESLOTS =
    '/find-occupational-medicine-time-slots';
export const ARTICLES_PAGE = '/articles';
export const ARTICLE_PAGE = '/articles/:articleId';
export const CONTACT_PAGE = '/contact';
export const PRIVACY_POLICY_PAGE = '/privacy-policy';
export const TERMS_AND_CONDITIONS_PAGE = '/terms-and-conditions';
export const CHANGE_PHONE_NUMBER_PAGE = '/change-phone-number';
export const CHANGE_PHONE_NUMBER_VERIFICATION_PAGE =
    '/change-phone-number-verification';
export const PHONE_NUMBER_CHANGED_PAGE = '/phone-number-changed';
export const MEDICAL_TEST_RESULT_PAGE = '/medical-test-result';
export const MEDICAL_TEST_RESULTS_PAGE = '/account/medical-test-results';
export const HELP_PAGE = '/help';
export const YOUR_DOCTORS_PAGE = '/your-doctors';
export const MEDICAL_TEST_RESULTS = '/medical-test-results';
export const YOUR_BUNDLES = '/your-bundles';
export const ACCOUNT_DATA = '/account-data';
export const MEDICAL_TESTS_SUCCESSFULLY_ORDERED_WITHOUT_PAYMENT_PAGE =
    '/medical-tests-successfully-booked';
export const LOGOUT_PAGE = '/logout-page';
export const NO_INTERNET_ERROR = '/no-internet-error';
export const APPOINTMENT_SEARCH_WIDGET_PAGE = '/appointment-search';
export const ERROR_400_PAGE = 'error-400';
export const SUBSCRIPTION_PAYMENT_FAILED_PAGE = '/subscription-payment-failed';
export const NEW_PRESCRIPTION_PAGE = '/new-prescription';
export const PRESCRIPTION_ISSUED_PAGE = '/prescription-issue-success';
export const CHANGE_ADDRESS_PAGE = '/change-address';
export const VOUCHER_PAGE = '/account/voucher';
export const VOUCHER = '/voucher';
export const SICK_LEAVES = '/sick-leaves';

export const DECLARATION_OF_PUBLIC_INSURANCE_PAGE =
    '/declaration-of-public-insurance';
export const INDIVIDUAL_PACKAGE_PAGE = '/individual-package';
export const CONFIRMED_IDENTITY = '/confirm-identity';
export const OCCUPATIONAL_MEDICINE_PAGE =
    '/occupational-medicine/:appointmentId';
export const OCCUPATIONAL_MEDICINE_PAGE_FILLED =
    '/occupational-medicine-filled';

// Deprecated. Create services in /api and const routes in service catalogs
export const NEW_SICK_LEAVE_PAGE = '/new-sick-leave';
export const SICK_LEAVE_ISSUED_PAGE = '/sick-leave-issue-success';
export const SICK_LEAVE_CANCELLED_PAGE = '/sick-leave-cancell-success';
