import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../const/colors';
import {desktop, mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addAppointmentContainer: {
            padding: mobile.contentLargerSpacing,
            paddingTop: 0,
        },
        addAppointmentButton: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            medicCalendarContent: {
                paddingLeft: desktop.contentLargerSpacing,
                paddingRight: desktop.contentLargerSpacing,
                paddingTop: desktop.contentSmallerSpacing,
                paddingBottom: 0,
            },
            modalBackground: {
                width: '100vw',
                height: '100vh',
            },
            modalContent: {
                position: 'relative',
                top: '20%',
                margin: 'auto',
                maxWidth: 1000,
                padding: `${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
                backgroundColor: WHITE,
                borderRadius: 3,
            },
            modalHeaderText: {
                fontSize: 20,
                fontWeight: 'bold',
            },
        },
    }),
);

export default useStyles;
