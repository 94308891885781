import {P1Service} from '../../services/P1Service';
import {P1Response} from '../../types/p1Service';
import {RegionalBranch} from '../../types/insuranceDeclaration';
import {
    NFZ_REGIONAL_BRANCH_LIST,
    PATIENT_INSURANCE_DECLARATION,
} from './insuranceDeclarationEndpoints';
import {PatientInsuranceDeclarationDto} from '../../types/insuranceDeclaration';

class InsuranceDeclarationApi {
    getNfzRegionalBranch() {
        return P1Service.get<P1Response<RegionalBranch[]>>(
            NFZ_REGIONAL_BRANCH_LIST,
        );
    }

    sendInsuranceDeclaration(
        patientInsuranceDeclaration: PatientInsuranceDeclarationDto,
        patientId: string,
    ) {
        return P1Service.put<P1Response<PatientInsuranceDeclarationDto>>(
            PATIENT_INSURANCE_DECLARATION + `/${patientId}`,
            patientInsuranceDeclaration,
        );
    }

    getInsuranceDeclaration(patientId: string) {
        return P1Service.get<P1Response<PatientInsuranceDeclarationDto>>(
            PATIENT_INSURANCE_DECLARATION + `/${patientId}`,
        );
    }
}

export const insuranceDeclarationApi = new InsuranceDeclarationApi();
