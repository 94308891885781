import {Grid, Box, Typography, Link} from '@material-ui/core';
import useStyles from './ArticleTileDesktopStyles';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {selectArticleById} from '../../../store/articleSlice';
import {generatePath, useHistory} from 'react-router';
import {ARTICLE_PAGE} from '../../../const/routes';
import {getShortDateFromISO} from '../../../utils/date';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

type ArticleTileProps = {
    articleId: number;
};

const ArticleTileDesktop = ({articleId}: ArticleTileProps) => {
    const article = useAppSelector(state =>
        selectArticleById(state, articleId),
    );
    const classes = useStyles();
    const history = useHistory();

    const {t} = useTranslation(i18nNamespaces.ARTICLES);

    const displayArticle = ()=>{
        history.push(
            generatePath(ARTICLE_PAGE, {
                articleId: article.id,
            }),
        )
    }
    return (
        <Box
            key={article.id}
            className={classes.container} >
                <Box
                    onClick={displayArticle}>

                    <Typography className={classes.title}>
                        {article.title}
                    </Typography>

                    <Typography className={classes.multiLineContentEllipsis}>
                        {article.text}
                    </Typography>

                    <Link
                        className={classes.link}>
                        {t('seeMore')}
                    </Link>
                </Box>   
            
        </Box>
    );
};

export default ArticleTileDesktop;
