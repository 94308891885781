import {createStyles, makeStyles} from '@material-ui/core';
import {WHITE} from '../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        settingsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 64px)',
            flexDirection: 'column',
        },
        settingsWrapper: {
            backgroundColor: WHITE,
            padding: '20px 24px',
            borderRadius: 3,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        attachmentConversation: {
            width: 600,
            flexShrink: 0,
            ['@media (min-width: 1060px)']: {
                width: 679,
            }
        },
        chatConversation: {
            flex: '1 0 auto',
        },
        participantName: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 24,
            marginBottom: 24,
        },
        tabs: {
            backgroundColor: 'white',
        },
    }),
);

export default useStyles;
