import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {MAGENTA, WHITE} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                minHeight: `calc(100vh - 128px)`,
            },
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom:30
        },
        title: {
            marginTop: 32,
            fontSize: 36,
            paddingLeft: 16,
            paddingRight: 16,
            maxWidth: 820,
        },
        description: {
            maxWidth: 820,
            marginTop: 56,
            marginBottom: 20,
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16,
            whiteSpace:'pre-line',
        },
        goBackButton:{
            width: '100%',
            fontWeight: 'bold',
            color: MAGENTA,
            backgroundColor: WHITE,
            borderColor: MAGENTA,
            border: '1px solid',
            marginTop:36,
            [theme.breakpoints.up('sm')]: {
                width: 200,
                marginTop:0
            },
        },
        makeAppointment:{
            color: MAGENTA,
            fontSize:20,
            
        },
        makeAnAppointmentButton:{ 
            width: '100%',
            whiteSpace:'normal',
            wordWrap:'break-word',
            marginTop:16,
            [theme.breakpoints.up('sm')]: {
                width: 300,
                marginLeft:16,
                marginTop:0
            },
        },
        buttonWrapper:{
            margin:16,
            marginTop:40,
        }
    }),
);

export default useStyles;
