import React, {useEffect, useState} from 'react';
import {CustomButton, CustomRating, Loading} from '../../index';
import useStyles from './ReplaceAppointmentStyles';
import {getMedicFullName} from '../../../utils/appointment';
import {Box, Grid, Theme, Typography, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {mobile} from '../../../const/sizes';
import DateTimeSelect from '../../forms/SearchForm/DateTimeSelect/DateTimeSelect';
import {useHistory} from 'react-router';
import {parseTimeSlotQuery} from '../../../utils/timeSlots';
import {TIME_SLOTS_PAGE} from '../../../const/routes';
import {getDateIntervalByCurrentDate} from '../../../utils/date';
import {DateTime} from 'luxon';
import {MedicalServiceDto} from '../../../types/timeSlot';
import MedicCardWithDetails from '../../common/MedicCardWithDetails/MedicCardWithDetails';

interface ReplaceAppointmentProps {
    medic?: {
        userId: string;
        firstName: string;
        surname: string;
        title: string;
        photoUrl: string;
        bio?: string;
        reviewCount: number;
        reviewRate: number;
    };
    medicalService?: MedicalServiceDto;
    currentDateTime?: string;
    appointmentId?: string;
    childId?: number;
    referralId?: number;
    occupationalMedicineReferralId?: number;
}

const ReplaceAppointment = (props: ReplaceAppointmentProps) => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const [currentDateTime, setCurrentDateTime] = useState(null);
    const [dateTime, setDateTime] =
        useState<{startDateTime: DateTime; endDateTime: DateTime}>(null);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

    useEffect(() => {
        if (props.currentDateTime) {
            setCurrentDateTime(DateTime.fromISO(props.currentDateTime));
            setDateTime(
                getDateIntervalByCurrentDate(
                    DateTime.fromISO(props.currentDateTime),
                ),
            );
        } else {
            setCurrentDateTime(DateTime.local());
            setDateTime(getDateIntervalByCurrentDate(DateTime.local()));
        }
    }, []);

    const redirectToTimeSlots = () => {
        const {startDateTime, endDateTime} = dateTime;

        const searchParams = {
            medicalServiceId: props?.medicalService?.id,
            startDateTime,
            endDateTime,
            appointmentId: props?.appointmentId,
            medicId: props.medic?.userId || null,
            referralId: props.referralId,
            childId: props.childId,
            occupationalMedicineReferralId:
                props.occupationalMedicineReferralId,
        };

        const search = parseTimeSlotQuery(searchParams);

        history.push({
            pathname: TIME_SLOTS_PAGE,
            search,
        });
    };

    // const getMedicData = () => {
    //     const medic = props.medic;
    //     return (
    //         <>
    //             <Grid container alignItems={'center'} style={{marginBottom: 7}}>
    //                 <CustomRating
    //                     defaultValue={DEFAULT_RATE_VALUE}
    //                     readOnly
    //                     size={'small'}
    //                     value={medic.reviewRate || DEFAULT_RATE_VALUE}
    //                 />
    //                 <Box className={classes.secondaryText}>
    //                     ({medic.reviewCount} {commonT('reviews')})
    //                 </Box>
    //             </Grid>
    //             {isDesktop && (
    //                 <Box className={classes.secondaryText}>{medic.bio}</Box>
    //             )}
    //         </>
    //     );
    // };
    return !!currentDateTime ? (
        <Grid container direction={'row'}>
            <Grid item md={6} xs={12} className={classes.medicDataWrapper}>
                <Box className={classes.title}>{t('arrangeAnAppointment')}</Box>
                <Grid
                    container
                    direction={isDesktop ? 'row' : 'row-reverse'}
                    justifyContent={isDesktop ? 'space-between' : 'flex-end'}
                    style={{marginTop: 30}}
                >
                    {props.medic ? (
                        <MedicCardWithDetails
                            header={getMedicFullName({
                                firstName: props.medic.firstName,
                                surname: props.medic.surname,
                                title: props.medic.title,
                            })}
                            subheader={props.medicalService.name}
                            reviewRate={props.medic.reviewRate}
                            reviewCount={props.medic.reviewCount}
                            description={props.medic.bio}
                            photoUrl={props.medic.photoUrl}
                        />
                    ) : (
                        props.medicalService && (
                            <Typography className={classes.subtitle}>
                                {props.medicalService.name}
                            </Typography>
                        )
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.dateTimeWrapper}>
                <Box
                    style={{
                        backgroundColor: 'white',
                        padding: isDesktop ? 24 : mobile.contentLargerSpacing,
                        marginBottom: 8,
                    }}
                >
                    <Box style={{marginBottom: 16}}>
                        {t('specifyPreferredTerm')}
                    </Box>
                    <DateTimeSelect
                        currentDateTime={currentDateTime}
                        onChangeDateTime={setDateTime}
                    />
                </Box>
                <Box
                    style={{
                        backgroundColor: 'white',
                        padding: isDesktop ? 24 : mobile.contentLargerSpacing,
                    }}
                >
                    <CustomButton
                        variant={'contained'}
                        color={'secondary'}
                        onClick={redirectToTimeSlots}
                        fullWidth
                    >
                        {t('showAvailability')}
                    </CustomButton>
                </Box>
            </Grid>
        </Grid>
    ) : (
        <Loading loading withBackdrop />
    );
};

export default ReplaceAppointment;
