import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {BACKGROUND_PRIMARY} from "../../../../const/colors";
import { desktop } from '../../../../const/sizes';

const useStyles = makeStyles((theme: Theme) => createStyles({
    pagingWrapper: {
        padding: `${desktop.contentSmallerSpacing}`,
    },
}));

export default useStyles;