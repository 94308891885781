import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    leftContainer: {
        flex: '1 0 auto',
        marginRight: 16,
    },
    actionButtonsWrapper: {
        marginTop: 26,
    },
}));

export default useStyles;