import {
    AccountCircleOutlined,
    HelpOutlineOutlined,
    PowerSettingsNew,
} from '@material-ui/icons';
import {MEDIC} from '../../../../const/auth';
import {
    MEDIC_CALENDAR,
    TIME_SLOTS_SEARCH_PAGE,
    MEDIC_SETTINGS,
    USER_ACCOUNT_PAGE,
    VIDEO_PAGE,
    INDIVIDUAL_PACKAGES_PAGE,
    CALL_PAGE,
    CHAT_PAGE,
    ARTICLES_PAGE,
    APPOINTMENT_SEARCH_WIDGET_PAGE,
} from '../../../../const/routes';
import useStyles from './BigMenuStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../../../store/auth';
import {useLocation} from 'react-router';
import clsx from 'clsx';
import CustomLink from '../../../common/CustomLink/CustomLink';
import configuration from '../../../../configuration';
import {getLanguage} from '../../../../utils/translation';
import ExternalLink from '../../../common/ExternalLink/ExternalLink';
import useLandingPageUrl from '../../../../hooks/useLandingPageUrl';
import {Box, MenuItem, Select} from '@material-ui/core';
import {SelectInputProps} from '@material-ui/core/Select/SelectInput';
import {LANGUAGES} from '../../../../const/lang';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

type Props = {
    onChangeLanguage?: SelectInputProps['onChange'];
    onClickHelp?: () => void;
    onClickAccount?: () => void;
    onClickLogout?: () => void;
};

const BigMenu = ({onChangeLanguage, onClickHelp, onClickLogout}: Props) => {
    const authUserData = useAppSelector(selectAuthUserData);
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const location = useLocation();
    const isDev = configuration?.environment === 'Development';
    const {getRoute, getCurrentUrl} = useLandingPageUrl();

    const links = authUserData?.role?.includes(MEDIC) ? (
        <>
            {isDev && (
                <>
                    <CustomLink to={VIDEO_PAGE + '/test_token'} style={{marginLeft: 32}}>
                        Testowa konsultacja wideo
                    </CustomLink>
                    <CustomLink to={CALL_PAGE + '/test_token'} style={{marginLeft: 32}}>
                        Testowa konsultacja telefoniczna
                    </CustomLink>
                    <CustomLink to={CHAT_PAGE + '/test_token'} style={{marginLeft: 32}}>
                        Testowa konsultacja chat
                    </CustomLink>
                </>
            )}
            <CustomLink to={MEDIC_CALENDAR} style={{marginLeft: 32}}>{t('myCalendar')}</CustomLink>
            <CustomLink to={MEDIC_SETTINGS} style={{marginLeft: 32}}>{t('settings')}</CustomLink>
            <LanguageSelector
                className={classes.menuButton}
                onChangeLanguage={onChangeLanguage}
            />
            <HelpOutlineOutlined
                className={classes.help}
                onClick={onClickHelp}
                style={{cursor: 'pointer'}}
            />
        </>
    ) : (
        <>
            {isDev && (
                <CustomLink to={VIDEO_PAGE + '/test_token'}>
                    Testowa konsultacja wideo
                </CustomLink>
            )}
            <ExternalLink
                to={
                    isDev
                        ? APPOINTMENT_SEARCH_WIDGET_PAGE
                        : getRoute('SEARCH_FOR_A_DOCTOR')
                }
            >
                {t('searchForADoctor')}
            </ExternalLink>
            <ExternalLink to={getRoute('INDIVIDUAL_PACKAGES')}>
                {t('individualPackages')}
            </ExternalLink>
            <ExternalLink to={getRoute('NEWS')}>{t('news')}</ExternalLink>
            <LanguageSelector
                className={classes.menuButton}
                onChangeLanguage={onChangeLanguage}
            />
            <CustomLink to={USER_ACCOUNT_PAGE} className={classes.account}>
                <AccountCircleOutlined
                    className={clsx(classes.accountIcon, {
                        [classes.isActive]:
                            location.pathname.includes(USER_ACCOUNT_PAGE),
                    })}
                />
            </CustomLink>
        </>
    );

    return (
        <div className={classes.menu}>
            {links}
            {authUserData && (
                <PowerSettingsNew
                    onClick={onClickLogout}
                    className={classes.logout}
                    style={{cursor: 'pointer'}}
                />
            )}
        </div>
    );
};

export default BigMenu;
