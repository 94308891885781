import React from "react";
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import CustomRating from "../CustomRating/CustomRating";

interface Props {
    reviewRate: number,
    reviewCount: number,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rating: {
            display: 'flex',
            alignItems: 'center',
        },
        reviewCount: {
            fontSize: 14,
            color: theme.palette.text.secondary,
            marginLeft: 10,
        },
    }),
);

const RatingWithCount = React.memo(function RatingWithCount({reviewRate, reviewCount}: Props) {
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    return (
        <Box className={classes.rating}>
            <CustomRating
                defaultValue={reviewRate}
                readOnly
                size={'small'}
            />
            <Box
                component={'span'}
                className={classes.reviewCount}
            >
                {`(${reviewCount} ${tCommon('ratings')})`}
            </Box>
        </Box>
    );
});

export default RatingWithCount;