import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import {withStyles} from "@material-ui/core/styles";

export const Accordion = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            // margin: 'auto', //zakomentowane
        },
        '&.Mui-expanded:last-child':{
            marginBottom:'16px'
        },
        '&.Mui-expanded:first-child':{
            marginTop:'16px'
        }
    },
    expanded: { 
    },
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'white',
        // marginBottom: -1, //zakomentowane
        minHeight: 'auto',
        '&$expanded': {
            minHeight: 'auto',
        },
        '&.Mui-focused': {
            backgroundColor: 'inherit',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'inherit',
        },
        padding: 0,
    },
    content: {
        margin: '0 0 16px',
        '&$expanded': {
            margin: '0 0 16px',
        }
    },
    expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(() => ({
    root: {
        padding: 0, 
        display:'inline'    //dodane
    },
}))(MuiAccordionDetails);