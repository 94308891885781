import React, {memo, useCallback, useContext} from 'react';
import {
    Box,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {AddCircleOutline, DeleteOutline} from '@material-ui/icons';
import findIndex from 'lodash-es/findIndex';
import {CustomAvatar} from '../../index';
import {MedicalTestDisabledIcon, MedicalTestIcon} from '../../../assets';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {DiagnosticServiceDto} from '../../../types/referrals';
import {
    addSelectedMedicalTest,
    removeSelectedMedicalTestById,
    selectSelectedMedicalTests,
} from '../../../store/referrals/medicalTestsSlice';
import {AlertContext} from "../../../contexts/AlertContext";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { LIGHT_DISABLED } from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        medicalTestName: {
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: '24px',
        },
        medicalTestNameDisabled: {
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: '24px',
            color:LIGHT_DISABLED
        },
        medicalTestAddDeleteIcon: {
            width: 23,
        },
    }),
);

const isMicroBiologicalTests = (medicalTest: DiagnosticServiceDto) =>
    parseInt(medicalTest.code) >= 1000 && parseInt(medicalTest.code) <= 2999

interface Props {
    medicalTest: DiagnosticServiceDto;
}

const MedicalTestCard = memo(function MedicalSearchFilters({
    medicalTest,
}: Props) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const selectedMedicalTests = useAppSelector(selectSelectedMedicalTests);

    const openAlert = useContext(AlertContext);

    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);

    const isMedicalTestSelected =
        findIndex(
            selectedMedicalTests,
            selectedMedicalTest => selectedMedicalTest.id === medicalTest.id,
        ) !== -1;

    const isDisabled = selectedMedicalTests!== null && selectedMedicalTests.length>0 && (medicalTest.type === 'Serological' || medicalTest.type === 'PapTest')
        ?  findIndex(
            selectedMedicalTests,
            selectedMedicalTest => selectedMedicalTest.id === medicalTest.id,
        ) === -1         
        : false;


    const medicalTestAddHandler = useCallback(() => {
        if (isMedicalTestSelected) return;

        if (medicalTest.type === 'Serological' || medicalTest.type === 'PapTest') {
            if (selectedMedicalTests.length) {
                openAlert({
                    severity: 'error',
                    message: tReferrals('cannotToAddMoreThanOneMedicalTestFromTheCategory')
                });
            } else {
                dispatch(addSelectedMedicalTest(medicalTest));
            }
        } else if (medicalTest.type === 'Analytical') {
            const cannotAddMedicalTest = (isMicroBiologicalTests(medicalTest) && selectedMedicalTests.length)
                || (selectedMedicalTests[0] && isMicroBiologicalTests(selectedMedicalTests[0]));
            if (cannotAddMedicalTest) {
                openAlert({
                    severity: 'error',
                    message: tReferrals('canAddJustOneMicrobiologicalTestAndCannotOccurWithOtherTestsFromThisCategory')
                });
            } else {
                dispatch(addSelectedMedicalTest(medicalTest));
            }
        }
    }, [dispatch, medicalTest, isMedicalTestSelected, selectedMedicalTests]);

    const medicalTestRemoveHandler = useCallback(() => {
        if (isMedicalTestSelected) {
            dispatch(removeSelectedMedicalTestById(medicalTest.id));
        }
    }, [dispatch, medicalTest, isMedicalTestSelected]);

    return (
        <Grid container wrap={'nowrap'} alignItems="center" spacing={1}>
            <Grid item xs="auto">

                {isDisabled
                    ? <CustomAvatar
                        alt={`photo`}
                        src={MedicalTestDisabledIcon}
                        isActive={isMedicalTestSelected} />
                    : <CustomAvatar
                        alt={`photo`}
                        src={MedicalTestIcon}
                        isActive={isMedicalTestSelected} />
                }

            </Grid>
            <Grid item xs>
                {isDisabled ? 
                    <Box className={classes.medicalTestNameDisabled}>
                        {medicalTest.name}
                    </Box>    
                    :
                    <Box className={classes.medicalTestName}>
                        {medicalTest.name}
                    </Box>  
                }
            </Grid>
            <Grid item xs="auto">
                {isMedicalTestSelected ? (
                    <IconButton onClick={medicalTestRemoveHandler}>
                        <DeleteOutline
                            color="secondary"
                            className={classes.medicalTestAddDeleteIcon}
                            style={{cursor: 'pointer'}}
                        />
                    </IconButton>
                ) : (
                    <IconButton onClick={medicalTestAddHandler}
                        disabled={isDisabled}>
                            {isDisabled 
                            ?   <AddCircleOutline
                                color='disabled'
                                className={classes.medicalTestAddDeleteIcon}
                                style={{cursor: 'pointer'}}
                            />
                                : <AddCircleOutline
                                color="secondary"
                                className={classes.medicalTestAddDeleteIcon}
                                style={{cursor: 'pointer'}}
                            />}

                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
});

export default MedicalTestCard;
