import {memo} from 'react';
import {Theme, useMediaQuery} from '@material-ui/core';
import {CardList, CardListItem, TileList, TileListItem} from '../../../../components';
import {useLaboratoryAddressesContext} from '../../../../hooks/diagnostic/laboratoryAddresses/useLaboratoryAddressesContext';
import {LaboratoryAddressDto} from '../../../../services/diagnostic/laboratoryAddressesProvider';
import LaboratoryAddress from "./LaboratoryAddress";

const LaboratoryAddresses = memo(function MedicalSearchFilters() {
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const {laboratoryAddresses} = useLaboratoryAddressesContext();

    const renderList = (children: JSX.Element[]) => {
        return mdUp ? (
            <CardList>
                {children}
            </CardList>
        ) : (
            <TileList>
                {children}
            </TileList>
        )
    }

    const renderListItem = (laboratoryAddress: LaboratoryAddressDto) => {
        const child = (
            <LaboratoryAddress
                laboratoryAddress={laboratoryAddress}
            />
        )
        return mdUp ? (
            <CardListItem key={laboratoryAddress.id}>
                {child}
            </CardListItem>
        ) : (
            <TileListItem key={laboratoryAddress.id} disablePaddings>
                {child}
            </TileListItem>
        );
    }

    return (
        <div>
            {
                renderList(
                    laboratoryAddresses.map(renderListItem)
                )
            }
        </div>
    );
});

export default LaboratoryAddresses;