import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    useTheme,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    MedicationPackDto,
    MedicationPackReimbursement,
} from '../../../types/prescription';

interface Props {
    onChange: (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => void;
    medicationPackReimbursements: MedicationPackReimbursement[];
    paymentLevel: number | string;
    error?: boolean;
    helperText?: React.ReactNode;
}

const ReimbursementSelect = (props: Props) => {
    const {
        onChange,
        medicationPackReimbursements,
        paymentLevel,
        error,
        helperText,
    } = props;
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);

    return (
        <FormControl fullWidth variant="outlined" error={error}>
            <InputLabel>{t('paymentRate')}</InputLabel>
            <Select
                name="paymentLevel"
                label={t('paymentRate')}
                value={paymentLevel}
                onChange={onChange}
                fullWidth
            >
                <MenuItem key={'0'} value={'0'}>
                    {'100%'}
                </MenuItem>
                {medicationPackReimbursements?.map(option => (
                    <MenuItem
                        key={option.paymentLevel}
                        value={option.paymentLevel}
                    >
                        {option.paymentLevelDisplayValue}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText variant="outlined" error={error}>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
};

export default ReimbursementSelect;
