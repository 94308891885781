import {createStyles, makeStyles} from '@material-ui/core';
import {BLACK} from '../../../const/colors';
import {mobile} from '../../../const/sizes';

const useStyles = makeStyles(() =>
    createStyles({
        label: {
            marginBottom: mobile.contentSmallerSpacing,
        },
        input: {
            width: '100%',
            padding: 14,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderRadius: 4,
            minHeight: 48,
        },
        focusedInput: {
            padding: 13,
            borderWidth: 2,
            borderColor: BLACK,
        },
        inputWithError: {
            borderColor: '#f44336',
        },
        error: {
            margin: '3px 14px 0 14px',
            fontSize: '0.8rem',
            color: '#f44336',
        },
    }),
);

export default useStyles;
