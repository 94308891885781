import {makeStyles, Theme} from "@material-ui/core";
import {RED} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    headerText: {
        marginRight: 12,
    },
    notEnoughNumberOfCharacters: {
        marginTop: 2,
        color: RED,
        fontSize: 15,
        lineHeight: '20px',
    },
    textField: {
        marginTop: 15,
        marginBottom:15,
        [theme.breakpoints.up('md')]: {
            marginTop: 24,
            marginBottom:24
        }
    }
}));

export default useStyles;
