import {useFormik} from 'formik';
import loginSchema from '../../../validators/Login';
import {Button, Input} from '../..';
import {Box, Grid, Link} from '@material-ui/core';
import {AuthErrorReason} from '../../../types/auth';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {FORGOT_PASSWORD_PAGE} from '../../../const/routes';
import {useHistory} from 'react-router';

type Props = {
    onSubmit: (values: {email: string; password: string}) => void;
    error?: AuthErrorReason;
};

const LoginForm = ({onSubmit, error}: Props) => {
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginSchema,
        onSubmit,
    });
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const history = useHistory();

    const loginError =
        error === 'INVALID_EMAIL_OR_PASSWORD'
            ? t('invalidEmailOrPassword')
            : error === 'OTHER_ERROR' && t('loginError');

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Input
                        fullWidth
                        style={{display: 'flex'}}
                        type="text"
                        id="email"
                        name="email"
                        errorText={formik.touched.email && formik.errors.email}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        label="E-mail"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        fullWidth
                        style={{display: 'flex'}}
                        type="password"
                        id="password"
                        name="password"
                        errorText={
                            formik.touched.password && formik.errors.password
                        }
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        label={t('password')}
                    />
                </Grid>
                {loginError && (
                    <Grid item xs={12}>
                        <Box component="span" color="red">
                            {loginError}
                        </Box>
                    </Grid>
                )}
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={12} md={6}>
                    <Link
                        color={'secondary'}
                        onClick={() => history.push(FORGOT_PASSWORD_PAGE)}
                        style={{cursor: 'pointer'}}
                    >
                        {t('dontRememberThePassword')}
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        type={'submit'}
                        style={{width: '100%'}}
                    >
                        {t('logIn')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default LoginForm;
