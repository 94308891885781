import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {MAGENTA, WHITE} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                minHeight: `calc(100vh - 128px)`,
            },
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop:56,
            paddingBottom:56
        },
        title: {fontSize:16, paddingLeft: 20, paddingRight: 16, fontWeight:'bold',maxWidth: 820,},
        desctiptionTitle:
        {
            maxWidth: 820,
            marginTop: 20,
            marginBottom: 20,
            fontSize: 20,
            paddingLeft: 16,
            paddingRight: 16,
            fontWeight:'bold',
        },
        desctiptionSubtitle:
        {
            maxWidth: 820,
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16,
            fontWeight:'bold',
            marginTop:16
        },
        description: {
            maxWidth: 820,
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16,
            whiteSpace:'pre-line'
        },
        liSubElement: {
            paddingLeft: 80,
            marginTop:0,
            paddingTop:0,
            marginBottom:0,
        },
        olSuperiorElement:{
            marginBottom:0,
            marginTop:0,
        },
        [theme.breakpoints.up('sm')]: {
            title: {
                fontSize: 56,
            },
            description: {
                fontSize: 16,
            },
        }
    }),
);

export default useStyles;
