import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { AuxiliaryTestItemDto, AuxiliaryTestsDto, PhysicalTestsByMedicDto } from "../../../types/occupationalMedicine";
import { MutableRefObject, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';
import { useFormik } from "formik";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Button, Grid, Link } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import useStyles from "./AuxiliaryTestsComponentStyles";
import { useParams } from "react-router";
import AuxiliaryTestsCreateItem from "./AuxiliaryTestsCreateItem";
import { fetchSection11Data } from "../../../store/occupationalMedicineSlice";
import AuxiliaryTestsItem from "./AuxiliaryTestsItem";

const SECTION = 11;

export type Props = {
    currentExpanded:number
    data:AuxiliaryTestsDto;
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void
    onReturn: () => void;
    refSection:MutableRefObject<any>
}

const AuxiliaryTestsComponent = ({currentExpanded, data, onSubmitSection, refSection, onReturn}:Props) =>{
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const customClasses = useStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isAddOrEditMode, setIsAddOrEditMode] = useState<boolean>(false);
    const [dataToEdit, setDataToEdit] = useState<AuxiliaryTestItemDto>(null);
    const [indexToEdit, setIndexToEdit] = useState<number>(null);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);


    const onSubmit = (newItem: AuxiliaryTestItemDto, stayOnCurrent=false) =>
    {

        if (newItem===null){
            if(data===null)
                onSubmitSection(JSON.stringify({tests: []}), fetchSection11Data({id:appointmentId}), SECTION, stayOnCurrent);   
            else
                onSubmitSection(JSON.stringify(data), fetchSection11Data({id:appointmentId}), SECTION, stayOnCurrent);   
        }

        //if editing
        else if (isNotNullOrUndefined(indexToEdit)){
            const updatedItems = [...data.tests.slice(0, indexToEdit), ... data.tests.slice(indexToEdit+1)]
            const resultItems = [...updatedItems, newItem];
            onSubmitSection(JSON.stringify({tests: resultItems}),fetchSection11Data({id:appointmentId}), SECTION,stayOnCurrent);
        }
        //if new data
        else{
            if (data!== null){
                const updatedItems= [
                    ...data.tests,
                    newItem,  
                ];
                onSubmitSection(JSON.stringify({tests: updatedItems}), fetchSection11Data({id:appointmentId}), SECTION,stayOnCurrent);
            }
            else{
                const item = [newItem];
                onSubmitSection(JSON.stringify({tests: item}), fetchSection11Data({id:appointmentId}), SECTION,stayOnCurrent);              
            }
        }
        setDataToEdit(null);
        setIndexToEdit(null);
    }
    
    const onCancel = () => {
        setIsAddOrEditMode(false);
        setDataToEdit(null);
        setIndexToEdit(null);
    }

    const onDeletePosition = (index:number) =>{
        const updatedItems = [...data.tests.slice(0, index), ... data.tests.slice(index+1)]
        onSubmitSection(JSON.stringify({tests: updatedItems}),fetchSection11Data({id:appointmentId}), SECTION,true);
    }

    const onEditPosition = (index: number, data:AuxiliaryTestItemDto) => {
        setIndexToEdit(index);
        setDataToEdit(data);
        setIsAddOrEditMode(true);
    }

    return (
        <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >

                <Box className={classes.titleContainer}>
                    <h4 className={classes.sectionHeader}>
                        {t('auxiliaryTests')}
                    </h4>

                    {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.detailsContainer}>
                {!isAddOrEditMode &&
                    <Grid container spacing={1}>
                        {
                            data && data.tests.map((v, i) => (
                                <Grid item xs={6} key={i}>
                                    <AuxiliaryTestsItem 
                                        index={i}
                                        data={v}
                                        onDelete={onDeletePosition}
                                        onEdit={onEditPosition}/>
                                </Grid>
                            ))
                        }
                        <Grid item xs={12}>
                            <Link                                                    
                                className={customClasses.link}
                                onClick={() => {setIsAddOrEditMode(true)}}
                                >
                                 {t('addPosition')}
                                   
                            </Link>  
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <Button
                                    onClick={onReturn}
                                    className={classes.buttonReturn}
                                    variant={'outlined'}
                                    color={'secondary'}>
                                    {t('return')}
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    onClick={() => {onSubmit(null, false)}}
                                    className={classes.buttonNext}
                                    variant={'contained'}
                                    color={'secondary'}>
                                    {t('next')}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                }             
                {isAddOrEditMode && <AuxiliaryTestsCreateItem 
                    data={dataToEdit} 
                    onSubmitSection={onSubmit}
                    onCancel={onCancel}/>}
            </AccordionDetails>
        </Accordion>
    
    )
}
export default  AuxiliaryTestsComponent;