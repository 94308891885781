import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AdditionalAuxiliaryTestsDto, AdditionalPhysicalTestDto, AuxiliaryTestsDto, DecisionDto, ExtendedScopeOfTestingDto, FactorsHarmfulAndBurdensomeToHealthDto,  OccupationalMedicineDto,  OccupationalMedicineRequestBodyDto, PatientPersonalDataDto, PatientPlaceOfWorkDto, PatientPlaceOfWorkHistoryDto, PatientPlaceOfWorkOrOfStudyDto, PatientProfessionalExperienceDto, PatientStatementBodyDto, PatientStatementDto, PatientSummaryDto, PhisicalTestDto, PhysicalTestsByMedicDto, SpecialistConsultationsDto } from "../types/occupationalMedicine";
import occupationalMedicineProvider from "../services/occupationalMedicineProvider";
import { RootState } from "./store";
import { isNotNullOrUndefined } from "../utils/lang";

const sliceName = 'occupationalMedicineSlice';
type OccupationalMedicineStatusType = 'idle' | 'loading' | 'failed';

const initialState = {
    section1:null as PatientPersonalDataDto,
    section2:null as PatientPlaceOfWorkOrOfStudyDto,
    section3:null as PatientPlaceOfWorkHistoryDto,
    section4:null as PatientProfessionalExperienceDto,
    section5:null as FactorsHarmfulAndBurdensomeToHealthDto,
    section6:null as PhisicalTestDto,
    section7:null as PatientSummaryDto,
    section8:null as PatientStatementDto,
    section9:null as PhysicalTestsByMedicDto,
    section10:null as AdditionalPhysicalTestDto,
    section11:null as AuxiliaryTestsDto,
    section12:null as SpecialistConsultationsDto,
    section13:null as ExtendedScopeOfTestingDto,
    section14:null as AdditionalAuxiliaryTestsDto,
    section15:null as DecisionDto,
    occupationalMedicine:null as OccupationalMedicineDto,

    section1Status: 'loading' as OccupationalMedicineStatusType,
    section2Status: 'loading' as OccupationalMedicineStatusType,
    section3Status: 'loading' as OccupationalMedicineStatusType,
    section4Status: 'loading' as OccupationalMedicineStatusType,
    section5Status: 'loading' as OccupationalMedicineStatusType,
    section6Status: 'loading' as OccupationalMedicineStatusType,
    section7Status: 'loading' as OccupationalMedicineStatusType,
    section8Status: 'loading' as OccupationalMedicineStatusType,
    section9Status: 'loading' as OccupationalMedicineStatusType,
    section10Status: 'loading' as OccupationalMedicineStatusType,
    section11Status: 'loading' as OccupationalMedicineStatusType,
    section12Status: 'loading' as OccupationalMedicineStatusType,
    section13Status: 'loading' as OccupationalMedicineStatusType,
    section14Status: 'loading' as OccupationalMedicineStatusType,
    section15Status: 'loading' as OccupationalMedicineStatusType,
    occupationalMedicineStatus: 'loading' as OccupationalMedicineStatusType,
};

export const fetchSection1Data = createAsyncThunk<
    PatientPersonalDataDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection1Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<PatientPersonalDataDto>(id, 1
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection2Data = createAsyncThunk<
    PatientPlaceOfWorkOrOfStudyDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection2Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<PatientPlaceOfWorkOrOfStudyDto>(id, 2
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection3Data = createAsyncThunk<
    PatientPlaceOfWorkHistoryDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection3Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<PatientPlaceOfWorkHistoryDto>(id, 3
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection4Data = createAsyncThunk<
    PatientProfessionalExperienceDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection4Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<PatientProfessionalExperienceDto>(id, 4
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection5Data = createAsyncThunk<
    FactorsHarmfulAndBurdensomeToHealthDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection5Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<FactorsHarmfulAndBurdensomeToHealthDto>(id, 5
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection6Data = createAsyncThunk<
    PhisicalTestDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection6Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<PhisicalTestDto>(id, 6
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});


export const fetchSection7Data = createAsyncThunk<
    PatientSummaryDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection7Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<PatientSummaryDto>(id, 7
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection8Data = createAsyncThunk<
    PatientStatementDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection8Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<PatientStatementDto>(id, 8
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection9Data = createAsyncThunk<
    PhysicalTestsByMedicDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection9Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<PhysicalTestsByMedicDto>(id, 9
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection10Data = createAsyncThunk<
    AdditionalPhysicalTestDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection10Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<AdditionalPhysicalTestDto>(id, 10
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection11Data = createAsyncThunk<
    AuxiliaryTestsDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection11Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<AuxiliaryTestsDto>(id, 11
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection12Data = createAsyncThunk<
    SpecialistConsultationsDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection12Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<SpecialistConsultationsDto>(id, 12
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});



export const fetchSection13Data = createAsyncThunk<
ExtendedScopeOfTestingDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection13Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<ExtendedScopeOfTestingDto>(id, 13
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchSection14Data = createAsyncThunk<
    AdditionalAuxiliaryTestsDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection14Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<AdditionalAuxiliaryTestsDto>(id, 14
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});


export const fetchSection15Data = createAsyncThunk<
    DecisionDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchSection15Data`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.getSection<DecisionDto>(id, 15
        );

        return data;
    } catch (err) {
        console.error(err);
    }
});


export const updateOccupationalMedicineData = createAsyncThunk<
    string,
    {id: string, form:OccupationalMedicineRequestBodyDto}, 
    unknown
>(`${sliceName}/updateOccupationalMedicineData`, async ({id, form}) => {
try {
    const {data} = await occupationalMedicineProvider.putSection(id, form);

    return data;
} catch (err) {
    console.error(err);
}
});

export const saveOccupationalMedicineDataByPatient = createAsyncThunk<
    string,
    {id: string, form:PatientStatementBodyDto}, 
    unknown
>(`${sliceName}/saveOccupationalMedicineDataByPatient`, async ({id, form}) => {
    
    const {data} = await occupationalMedicineProvider.postSection(id, form);
    return data;

});

export const saveOccupationalMedicineDataByMedic = createAsyncThunk<
    string,
    {id: string, form:OccupationalMedicineRequestBodyDto}, 
    unknown
>(`${sliceName}/saveOccupationalMedicineDataByMedic`, async ({id, form}) => {

    const {data} = await occupationalMedicineProvider.postFull(id, form);
    return data;

});

export const fetchOccupationalMedicine = createAsyncThunk<
    OccupationalMedicineDto,
    {id: string}, 
    unknown
>(`${sliceName}/fetchOccupationalMedicine`, async ({id}) => {
    try {
        const {data} = await occupationalMedicineProvider.get(id);

        return data;
    } catch (err) {
        console.error(err);
    }
});


const occupationalMedicineSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSection1Data.pending, state => {
                state.section1Status = 'loading';
            })
            .addCase(fetchSection1Data.fulfilled, (state, action) => {
                state.section1Status = 'idle';
                state.section1 = action.payload;
            })
            .addCase(fetchSection1Data.rejected, state => {
                state.section1Status = 'failed';
            });
        builder
            .addCase(fetchSection2Data.pending, state => {
                state.section2Status = 'loading';
            })
            .addCase(fetchSection2Data.fulfilled, (state, action) => {
                state.section2Status = 'idle';
                state.section2 = action.payload;
            })
            .addCase(fetchSection2Data.rejected, state => {
                state.section2Status = 'failed';
            });
        builder
            .addCase(fetchSection3Data.pending, state => {
                state.section3Status = 'loading';
            })
            .addCase(fetchSection3Data.fulfilled, (state, action) => {
                state.section3Status = 'idle';
                state.section3 = action.payload;
            })
            .addCase(fetchSection3Data.rejected, state => {
                state.section3Status = 'failed';
            });
        builder
            .addCase(fetchSection4Data.pending, state => {
                state.section4Status = 'loading';
            })
            .addCase(fetchSection4Data.fulfilled, (state, action) => {
                state.section4Status = 'idle';
                state.section4= action.payload;
            })
            .addCase(fetchSection4Data.rejected, state => {
                state.section4Status = 'failed';
            });
        builder
            .addCase(fetchSection5Data.pending, state => {
                state.section5Status = 'loading';
            })
            .addCase(fetchSection5Data.fulfilled, (state, action) => {
                state.section5Status = 'idle';
                state.section5= action.payload;
            })
            .addCase(fetchSection5Data.rejected, state => {
                state.section5Status = 'failed';
            });

        builder
            .addCase(fetchSection6Data.pending, state => {
                state.section6Status = 'loading';
            })
            .addCase(fetchSection6Data.fulfilled, (state, action) => {
                state.section6Status = 'idle';
                state.section6= action.payload;
            })
            .addCase(fetchSection6Data.rejected, state => {
                state.section6Status = 'failed';
            });

        builder
            .addCase(fetchSection7Data.pending, state => {
                state.section7Status = 'loading';
            })
            .addCase(fetchSection7Data.fulfilled, (state, action) => {
                state.section7Status = 'idle';
                state.section7= action.payload;
            })
            .addCase(fetchSection7Data.rejected, state => {
                state.section7Status = 'failed';
            });

        builder
            .addCase(fetchSection8Data.pending, state => {
                state.section8Status = 'loading';
            })
            .addCase(fetchSection8Data.fulfilled, (state, action) => {
                state.section8Status = 'idle';
                state.section8= action.payload;
            })
            .addCase(fetchSection8Data.rejected, state => {
                state.section8Status = 'failed';
            });

        builder
            .addCase(fetchSection9Data.pending, state => {
                state.section9Status = 'loading';
            })
            .addCase(fetchSection9Data.fulfilled, (state, action) => {
                state.section9Status = 'idle';
                state.section9= action.payload;
            })
            .addCase(fetchSection9Data.rejected, state => {
                state.section9Status = 'failed';
            });

        builder
            .addCase(fetchSection10Data.pending, state => {
                state.section10Status = 'loading';
            })
            .addCase(fetchSection10Data.fulfilled, (state, action) => {
                state.section10Status = 'idle';
                state.section10= action.payload;
            })
            .addCase(fetchSection10Data.rejected, state => {
                state.section10Status = 'failed';
            });

        builder
            .addCase(fetchSection11Data.pending, state => {
                state.section11Status = 'loading';
            })
            .addCase(fetchSection11Data.fulfilled, (state, action) => {
                state.section11Status = 'idle';
                state.section11= action.payload;
            })
            .addCase(fetchSection11Data.rejected, state => {
                state.section11Status = 'failed';
            });
            
        builder
            .addCase(fetchSection12Data.pending, state => {
                state.section12Status = 'loading';
            })
            .addCase(fetchSection12Data.fulfilled, (state, action) => {
                state.section12Status = 'idle';
                state.section12= action.payload;
            })
            .addCase(fetchSection12Data.rejected, state => {
                state.section12Status = 'failed';
            });

        builder
            .addCase(fetchSection13Data.pending, state => {
                state.section13Status = 'loading';
            })
            .addCase(fetchSection13Data.fulfilled, (state, action) => {
                state.section13Status = 'idle';
                state.section13= action.payload;
            })
            .addCase(fetchSection13Data.rejected, state => {
                state.section13Status = 'failed';
            });

        builder
            .addCase(fetchSection14Data.pending, state => {
                state.section14Status = 'loading';
            })
            .addCase(fetchSection14Data.fulfilled, (state, action) => {
                state.section14Status = 'idle';
                state.section14= action.payload;
            })
            .addCase(fetchSection14Data.rejected, state => {
                state.section14Status = 'failed';
            });

        builder
            .addCase(fetchSection15Data.pending, state => {
                state.section15Status = 'loading';
            })
            .addCase(fetchSection15Data.fulfilled, (state, action) => {
                state.section15Status = 'idle';
                state.section15= action.payload;
            })
            .addCase(fetchSection15Data.rejected, state => {
                state.section15Status = 'failed';
            });

        builder
            .addCase(fetchOccupationalMedicine.pending, state => {
                state.occupationalMedicineStatus = 'loading';
            })
            .addCase(fetchOccupationalMedicine.fulfilled, (state, action) => {
                state.occupationalMedicineStatus = 'idle';
                state.occupationalMedicine= action.payload;
            })
            .addCase(fetchOccupationalMedicine.rejected, state => {
                state.occupationalMedicineStatus = 'failed';
            });
    },
});

export const selectSection1 = (state: RootState) =>
    state.occupationalMedicine.section1;

export const selectSection2 = (state: RootState) =>
    state.occupationalMedicine.section2;

export const selectSection3 = (state: RootState) =>
    state.occupationalMedicine.section3;

export const selectSection4 = (state: RootState) =>
    state.occupationalMedicine.section4;

export const selectSection5 = (state: RootState) =>
    state.occupationalMedicine.section5;

export const selectSection6 = (state: RootState) =>
    state.occupationalMedicine.section6;

export const selectSection7 = (state: RootState) =>
    state.occupationalMedicine.section7;

export const selectSection8 = (state: RootState) =>
    state.occupationalMedicine.section8;

export const selectSection9 = (state: RootState) =>
    state.occupationalMedicine.section9;

export const selectSection10 = (state: RootState) =>
    state.occupationalMedicine.section10;

export const selectSection11 = (state: RootState) =>
    state.occupationalMedicine.section11;

export const selectSection12 = (state: RootState) =>
    state.occupationalMedicine.section12;

export const selectSection13 = (state: RootState) =>
    state.occupationalMedicine.section13;

export const selectSection14 = (state: RootState) =>
    state.occupationalMedicine.section14;

export const selectSection15 = (state: RootState) =>
    state.occupationalMedicine.section15;

export const selectOccupationalMedicine = (state: RootState) =>
    state.occupationalMedicine.occupationalMedicine;

export const selectSection1IsLoaded = (state:RootState) =>
    state.occupationalMedicine.section1Status === 'idle'

export const selectSection2IsLoaded = (state:RootState) =>
    state.occupationalMedicine.section2Status === 'idle'

export const selectSection3IsLoaded = (state:RootState) =>
    state.occupationalMedicine.section3Status === 'idle'

export const selectSection4IsLoaded = (state:RootState) =>
    state.occupationalMedicine.section4Status === 'idle'

export const selectSection5IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section5Status === 'idle'

export const selectSection6IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section6Status === 'idle'

export const selectSection7IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section7Status === 'idle'

export const selectSection8IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section8Status === 'idle'

export const selectSection9IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section9Status === 'idle'

export const selectSection10IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section10Status === 'idle'

export const selectSection11IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section11Status === 'idle'

export const selectSection12IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section12Status === 'idle'

export const selectSection13IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section13Status === 'idle'

export const selectSection14IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section14Status === 'idle'

export const selectSection15IsLoaded= (state:RootState) =>
    state.occupationalMedicine.section15Status === 'idle'

export const selectOccupationalMedicineLoaded= (state:RootState) =>
    state.occupationalMedicine.occupationalMedicineStatus === 'idle'

export const selectIsAllLoadedForPatient = (state: RootState) =>
    selectSection1IsLoaded(state) &&
    selectSection2IsLoaded(state) &&
    selectSection3IsLoaded(state) &&
    selectSection4IsLoaded(state) &&
    selectSection5IsLoaded(state) &&
    selectSection6IsLoaded(state) &&
    selectSection7IsLoaded(state) &&
    selectSection8IsLoaded(state);

export const selectIsAllLoadedForMedic = (state: RootState) =>
        selectOccupationalMedicineLoaded(state) &&
        selectSection1IsLoaded(state) &&
        selectSection2IsLoaded(state) &&
        selectSection3IsLoaded(state) &&
        selectSection4IsLoaded(state) &&
        selectSection5IsLoaded(state) &&
        selectSection6IsLoaded(state) &&
        selectSection7IsLoaded(state) &&
        selectSection8IsLoaded(state) &&
        selectSection9IsLoaded(state) &&
        selectSection10IsLoaded(state) &&
        selectSection11IsLoaded(state) &&
        selectSection12IsLoaded(state) &&
        selectSection13IsLoaded(state) &&
        selectSection14IsLoaded(state) &&
        selectSection15IsLoaded(state);

export const selectIsQuestionnaireFilledForPatient = (state:RootState) =>
    isNotNullOrUndefined(state.occupationalMedicine.section1) &&
    isNotNullOrUndefined(state.occupationalMedicine.section2) &&
    isNotNullOrUndefined(state.occupationalMedicine.section3) &&
    isNotNullOrUndefined(state.occupationalMedicine.section4) &&
    isNotNullOrUndefined(state.occupationalMedicine.section5) &&
    isNotNullOrUndefined(state.occupationalMedicine.section6) &&
    isNotNullOrUndefined(state.occupationalMedicine.section7)

export const selectIsQuestionnaireFilledForMedic = (state:RootState) =>
        isNotNullOrUndefined(state.occupationalMedicine.section9) &&
        isNotNullOrUndefined(state.occupationalMedicine.section10) &&
        isNotNullOrUndefined(state.occupationalMedicine.section11) &&
        isNotNullOrUndefined(state.occupationalMedicine.section12) &&
        isNotNullOrUndefined(state.occupationalMedicine.section13) &&
        isNotNullOrUndefined(state.occupationalMedicine.section14) ;


export default occupationalMedicineSlice.reducer;
