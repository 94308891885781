import React from 'react';
import {Box, FormGroup, Grid} from '@material-ui/core';
import PasswordStrengthBar from 'react-password-strength-bar';
import {createStyles, makeStyles} from '@material-ui/core';
import {FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {CustomPasswordField, Label} from '../../../index';
import clsx from 'clsx';
import {desktop} from '../../../../const/sizes';

const useStyles = makeStyles(() =>
    createStyles({
        passwordStrengthBarContainer: {
            flex: 1,
            marginLeft: 10,
            marginBottom: 10,
        },
        passwordLabelContainer: {
            width: 300,
            marginTop: desktop.contentSmallerSpacing,
        },
    }),
);

interface FormikPasswordFieldProps {
    form: FormikProps<any>;
    fieldName: string;
    className?: string;
    fullWidth?: boolean;
    showStrengthBar?: boolean;
    label?: string;
}

const FormikPasswordField = (props: FormikPasswordFieldProps) => {
    const classes = useStyles();
    const {form, fieldName, fullWidth, className, showStrengthBar, label} =
        props;
    const {t} = useTranslation(i18nNamespaces.AUTH);
    return (
        <FormGroup>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={clsx(classes.passwordLabelContainer, className)}
            >
                <Box>
                    <Label htmlFor="password">{label || t('password')}</Label>
                </Box>
                {showStrengthBar && (
                    <Box className={classes.passwordStrengthBarContainer}>
                        <PasswordStrengthBar
                            password={form.values[fieldName]}
                            scoreWords={[]}
                            scoreWordStyle={{display: 'none'}}
                        />
                    </Box>
                )}
            </Grid>
            <CustomPasswordField
                id="password"
                name="password"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values[fieldName]}
                helperText={form.touched[fieldName] && form.errors[fieldName]}
                error={form.touched[fieldName] && !!form.errors[fieldName]}
                fullWidth={fullWidth}
            />
        </FormGroup>
    );
};

export default FormikPasswordField;
