import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useQuery from '../../hooks/useQuery';
import {
    MedicalTestsSearch,
    ReferralForMedicalTestsSummary,
    ReferPatientToSpecialistHeaderMobile,
    ReferPatientToSpecialistTopBar,
    TwoColumnLayout
} from '../../components';
import i18nNamespaces from '../../const/i18nNamespaces';
import useAppointment from '../../hooks/appointment/useAppointment';
import {AppointmentContext} from '../../hooks/appointment/useAppointmentContext';
import { useState } from 'react';

const PatientReferralForMedicalTestsPage = () => {
    const [showDefaultCard, setShowDefaultCard] = useState<boolean>(true);
    const theme = useTheme();
    const isMdBreakpointUp = useMediaQuery(theme.breakpoints.up('md'));
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);

    const query = useQuery();
    const appointmentId = parseInt(query.get('appointmentId'));

    const appointmentState = useAppointment(appointmentId);

    return (
        <AppointmentContext.Provider value={appointmentState}>
            <TwoColumnLayout>
                <TwoColumnLayout.Header>
                    {
                        isMdBreakpointUp
                            ? (
                                <ReferPatientToSpecialistTopBar
                                    referralForText={tReferrals('referralForMedicalTestsFor')}
                                    firstName={appointmentState.appointment?.patient.firstName}
                                    surname={appointmentState.appointment?.patient.surname}
                                />
                            ) : (
                                <ReferPatientToSpecialistHeaderMobile
                                    referralForText={tReferrals('referralForMedicalTestsFor')}
                                    firstName={appointmentState.appointment?.patient.firstName}
                                    surname={appointmentState.appointment?.patient.surname}
                                />
                            )
                    }
                </TwoColumnLayout.Header>
                <TwoColumnLayout.Main>
                    <TwoColumnLayout.LeftColumn>
                        <MedicalTestsSearch 
                            showMedicalTests={showDefaultCard}
                            setShowMedicalTests={setShowDefaultCard}/>
                    </TwoColumnLayout.LeftColumn>
                    <TwoColumnLayout.RightColumn mobileScreenBottomSticky>
                        <ReferralForMedicalTestsSummary 
                            showMedicalTests={showDefaultCard}
                            setShowMedicalTests={setShowDefaultCard}/>
                        
                    </TwoColumnLayout.RightColumn>
                </TwoColumnLayout.Main>
            </TwoColumnLayout>
        </AppointmentContext.Provider>
    )
}

export default PatientReferralForMedicalTestsPage;