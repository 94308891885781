import {makeStyles} from '@material-ui/styles';
import { desktop } from '../../../const/sizes';
import { Theme } from '@material-ui/core';
import { RED, WHITE } from '../../../const/colors';

const useStyles = makeStyles((theme:Theme) => ({
    container:{
        marginTop: desktop.contentSmallerSpacing,
        paddingBottom: desktop.contentSmallerSpacing,
    },

    button:{
        fontWeight: 'bold',
        width:'100%',
        [theme.breakpoints.down('sm')]: {
            padding:16,
            width:'100%',
        },
    },

    select:{
        marginTop:desktop.contentSmallerSpacing,
        marginBottom:desktop.contentSmallerSpacing,
    },
    sectionHeader: {
        margin: '0',
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            lineHeight: '32px',
        },
    },
    section: {
        padding: '16px',
        backgroundColor: WHITE,
    },
    titleContainer:{
        display:'flex',
        alignItems:'center',
    },
    alertIcon:{
        color:RED,
        marginLeft:'16px'
    }
}));

export default useStyles;
