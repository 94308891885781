import {FormControl, Grid, MenuItem, Select} from '@material-ui/core';
import React, {useState} from 'react';
import {
    consultationDays,
    getInterval,
    getTimeIntervals,
    Interval,
} from '../../../../utils/date';
import {capitalizeFirstLetter} from '../../../../utils/string';
import {DateTime} from 'luxon';
import {CustomInputChangeEvent} from '../../../../types/common';

type Props = {
    currentDateTime: DateTime;
    onChangeDateTime: (selectedInterval: {
        startDateTime: DateTime;
        endDateTime: DateTime;
    }) => void;
};

const DateTimeSelect = ({currentDateTime, onChangeDateTime}: Props) => {
    const [selectedDay, setSelectedDay] = useState<number>(0);
    const [selectedInterval, setSelectedInterval] = useState<number>(0);

    const onChangeDay = (e: CustomInputChangeEvent) => {
        const value = e.target.value as number;
        setSelectedDay(value);
        const interval = getTimeIntervals(currentDateTime, value)[0];
        setInterval(0, interval, value);
    };

    const onChangeTime = (e: CustomInputChangeEvent) => {
        const value = e.target.value as number;
        const interval = getTimeIntervals(currentDateTime, selectedDay)[value];
        setInterval(value, interval, selectedDay);
    };

    const setInterval = (
        value: number,
        interval: Interval,
        currentDay: number,
    ) => {
        setSelectedInterval(value);
        onChangeDateTime(
            getInterval(
                consultationDays(currentDateTime)[currentDay].date,
                interval,
            ),
        );
    };

    const mapIntervals = () =>
        getTimeIntervals(currentDateTime, selectedDay).map((item, index) => {
            return (
                <MenuItem key={index} value={index}>
                    {item.label}
                </MenuItem>
            );
        });

    return (
        <Grid container direction={'row'} spacing={1}>
            <Grid item xs={6}>
                <FormControl component="fieldset" variant="outlined" fullWidth>
                    <Select
                        labelId="daySearchSelect"
                        id="daySearchSelect"
                        value={selectedDay}
                        onChange={onChangeDay}
                        fullWidth
                    >
                        {consultationDays(currentDateTime).map(item => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {capitalizeFirstLetter(item.label)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl component="fieldset" variant="outlined" fullWidth>
                    <Select
                        labelId="timeSearchSelect"
                        id="timeSearchSelect"
                        value={selectedInterval}
                        onChange={onChangeTime}
                        fullWidth
                    >
                        {mapIntervals()}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default DateTimeSelect;
