import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {MAGENTA} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            marginTop: desktop.contentSmallerSpacing,
        },
        passwordLabelContainer: {
            width: '100%',
        },
        passwordStrengthBarContainer: {
            flex: 1,
            paddingLeft: 10,
        },
        formSubsection: {
            marginTop: mobile.contentLargerSpacing,
        },
        submitContainer: {
            width: '100%',
            marginTop: mobile.contentLargerSpacing,
        },
        submitButton: {
            width: '100%',
        },
        termsAndConditionLink: {
            color: MAGENTA,
        },
        [theme.breakpoints.up('sm')]: {
            submitButton: {
                width: 300,
            },
        },
    }),
);

export default useStyles;
