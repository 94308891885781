import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: `0 ${mobile.contentLargerSpacing}`,
        },
        header: {
            fontSize: 36,
            fontWeight: 'bold',
            marginTop: 32,
            marginBottom: 32,
        },
        information: {},
        image: {
            width: '100%',
            marginTop: 40,
            marginBottom: 60,
        },
        button: {
            width: '100%',
            marginBottom: 66,
        },
        [theme.breakpoints.up('sm')]: {
            container: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            header: {
                marginTop: 64,
                marginBottom: 32,
            },
            image: {
                width: 408,
            },
            button: {
                width: 300,
                marginBottom: 66,
            },
        },
    }),
);

export default useStyles;
