import { ZLAService } from "../../services/ZLAService";
import { PaginatedResults } from "../../types/common";
import { P1Response } from "../../types/p1Service";
import { PayerDto, ZLAHistoryDto } from "../../types/sickLeave";
import { getMedicalRecommendationsEndpoint, getPayersEndpoint, getPlacesOfInsuranceEndpoint, getZLAsEndpoint } from "./isuredEndpoints";


class IsuredApi {

    getInsurancePlaces() {
        return ZLAService.get<P1Response<string[]>>(
            getPlacesOfInsuranceEndpoint(),
        );
    }

    getPayers(pesel: string, insurancePlace: string) {
        return ZLAService.get<P1Response<PayerDto[]>>(
            getPayersEndpoint(pesel, insurancePlace),{ withCredentials: true }
        );
    }

    getMedicalRecommendations(){
        return ZLAService.get<P1Response<string[]>>(
            getMedicalRecommendationsEndpoint(),
        );
    }

    getZLAs(pesel: string, page: number, size:number){
        return ZLAService.get<P1Response<PaginatedResults<ZLAHistoryDto>>>(
            getZLAsEndpoint(pesel, page, size),{ withCredentials: true }
        );
    }
}

export default new IsuredApi();
