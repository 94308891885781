import {useCallback, useState} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {PATIENT_REFERRAL_SUCCESSFULLY_MADE_PAGE} from '../../const/routes';
import {Appointment} from '../../types/appointments';
import i18nNamespaces from '../../const/i18nNamespaces';
import {DiagnosticServiceDto} from '../../types/referrals';
import {createPatientReferralForMedicalTests, PapTestCollectedFromDto} from '../../services/diagnostic/orderHeadersProvider';
import { MedicPackageDto } from '../../types/diagnosticServicePackage';
import { isNotNullOrUndefined } from '../../utils/lang';

interface Props {
    appointment: Appointment;
    selectedMedicalTestsCategory: string;
    selectedMedicalTests: DiagnosticServiceDto[];
    selectedMedicalTestsPackage: MedicPackageDto | null;
    selectedPapTestCollectedFrom: PapTestCollectedFromDto | null;
    selectedPapTestLastMenstruationDate: string | null;
    selectedPapTestChildbirthNumber: number | null;
    selectedPapTestAdditionalInfo: string | null;
}

const useCreateReferralForMedicalTests = () => {
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const createReferralForMedicalTests = useCallback(
        async ({
            appointment,
            selectedMedicalTestsCategory,
            selectedMedicalTests,
            selectedMedicalTestsPackage,
            selectedPapTestCollectedFrom,
            selectedPapTestLastMenstruationDate,
            selectedPapTestChildbirthNumber,
            selectedPapTestAdditionalInfo
        }: Props) => {
            if (selectedMedicalTests.length && appointment) {
                setLoading(true);
                const diagnosticOrderHeadersDto = {
                    Type: selectedMedicalTestsPackage!==null  ? selectedMedicalTestsPackage.type : selectedMedicalTestsCategory,
                    AppointmentId: parseInt(appointment.id),
                    PatientId: appointment.patient.userId,
                    ChildId: appointment.child!==null ? appointment.child.childId : null,
                    MedicalPackageId: selectedMedicalTestsPackage!==null ? selectedMedicalTestsPackage.id : null,
                    DiagnosticOrderLines: selectedMedicalTests.map(
                        medicalTest => {
                            return {
                                DiagnosticServiceId: medicalTest.id,
                                PapTestCollectedFrom: selectedPapTestCollectedFrom,
                                PapTestLastMenstruationDate: isNotNullOrUndefined(selectedPapTestLastMenstruationDate) 
                                    ? selectedPapTestLastMenstruationDate.toString() 
                                    : null,
                                    PapTestChildbirthNumber: selectedPapTestChildbirthNumber,
                                    PapTestAdditionalInfo: selectedPapTestAdditionalInfo
                            };
                        },
                    ),
                    
                };

                try {
                    
                    await createPatientReferralForMedicalTests(
                        diagnosticOrderHeadersDto,
                    );
                    history.replace({
                        pathname: PATIENT_REFERRAL_SUCCESSFULLY_MADE_PAGE,
                        search:
                            `?patientFirstName=${appointment.child!==null ? appointment.child.firstName : appointment.patient.firstName}` +
                            `&patientSurname=${appointment.child!==null ? appointment.child.surname :appointment.patient.surname}`,
                    });
                } catch {
                    setError(tErrors('sendingDataToServerError'));
                }

                setLoading(false);
            }
        },
        [],
    );

    return {
        createReferralForMedicalTests,
        createReferralForMedicalTestsLoading: loading,
        createReferralForMedicalTestsError: error,
    };
};

export default useCreateReferralForMedicalTests;
