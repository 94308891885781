import {Box, Grid, Typography} from '@material-ui/core';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import {useTranslation} from 'react-i18next';
import { useHistory } from 'react-router';
import {ArticlesList} from '../../components';
import configuration from '../../configuration';
import { MEDIC } from '../../const/auth';
import i18nNamespaces from '../../const/i18nNamespaces';
import { MEDIC_CALENDAR } from '../../const/routes';
import { useAppSelector } from '../../hooks/customReduxHooks';
import { selectAuthUserData } from '../../store/auth';

import useStyles from './ArticlesListPageStyles';

const ArticlesListPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.ARTICLES);
    const {t: tMetaDescription} = useTranslation(i18nNamespaces.META_DESCRIPTION);

    return (
        <>
            {configuration.environment ===  'Production' &&
                <Helmet>
                    <title>{tMetaDescription('articlesPageTitle')}</title>
                    <meta name='description' content={tMetaDescription('articlesPageDescription')} />
                    <meta name='keywords' content={tMetaDescription('articlesPageKeywords')}/>
                </Helmet>
            }
            <Grid
                    container
                    direction="column"
                    className={classes.container}
                >
                    <Grid item>
                        <Typography variant="h1" className={classes.title}>
                            {t('articlesTitle')}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="center">
                        <ArticlesList size={null}/>
                    </Grid>
                </Grid>
        </>
    );
};

export default ArticlesListPage;
