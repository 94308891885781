import {memo} from 'react';
import LaboratoryDetails from './LaboratoryDetails';
import OrderMedicalTests from './OrderMedicalTests';
import {Box} from '@material-ui/core';

const SelectedLaboratorySummary = memo(function MedicalSearchFilters() {
    return (
        <Box>
            <LaboratoryDetails />
            <OrderMedicalTests />
        </Box>
    )
})

export default SelectedLaboratorySummary;