import {IndividualPackageDto} from '../types/individualPackages';
import {INDIVIDUAL_PACKAGES, INDIVIDUAL_PACKAGE_INSTANCE} from './endpoints';
import {http, rawHttp} from './http';

class IndividualPackagesProvider {
    async getIndividualPackageInstance(individualPackageDraftId: number) {
        return (
            await http.get<IndividualPackageDto>(
                INDIVIDUAL_PACKAGE_INSTANCE(individualPackageDraftId),
            )
        ).data;
    }

    async getIndividualPackages(
        maxUsersCount: number,
        childrenIncluded?: boolean,
    ) {
        return await rawHttp.get<IndividualPackageDto[]>(INDIVIDUAL_PACKAGES, {
            params: {maxUsersCount, childrenIncluded},
        });
    }
}

export default new IndividualPackagesProvider();
