import {Box, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {FinishScheduleImage} from '../../assets';
import {useLocation} from 'react-router';
import useStyles from './SickLeaveCancelledPageStyles';
import {useAppDispatch} from '../../hooks/customReduxHooks';
import { fetchSickLeaves } from '../../store/sickLeaveSlice';

const SickLeaveCancelledPage = () => {
    const classes = useStyles();
    const location = useLocation<{appointmentId: string}>();
    const {t} = useTranslation(i18nNamespaces.L4);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();
    const appointmentId = parseInt(location?.state?.appointmentId);

    const handleGoBack = () => {
        if (appointmentId) dispatch(fetchSickLeaves({appointmentId}));
        window.close();
    };

    return (
        <Box className={classes.orderInfoRoot}>
            <img src={FinishScheduleImage} />
            <Box className={classes.orderInfoHeaderText}>
                {t('SickLeaveHasBeenCancelled')}
            </Box>

            <Box className={classes.thanksGivingText}>
                {t('goBackToAppointment')}
            </Box>
           <Button
                variant={'contained'}
                color={'secondary'}
                style={{fontWeight: 'bold'}}
                onClick={handleGoBack}
            >
                {commonT('goBack')}
            </Button>
        </Box>
    );
};

export default SickLeaveCancelledPage;
