import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        [theme.breakpoints.up('sm')]: {
            listWrapper: {
                backgroundColor: WHITE,
                marginTop: mobile.contentSmallerSpacing,
                paddingTop: desktop.contentSmallerSpacing,
            },
        },
    }),
);

export default useStyles;
