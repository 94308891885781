import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main,
            minHeight: 65,
        },
        copyright: {
            color: theme.palette.grey[500],
        },
        medicalEntity: {
            color: theme.palette.grey[500],
            fontSize: 10,
            whiteSpace: 'nowrap',
        },
        link: {
            color: WHITE,
        },
        menu: {
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.down('sm')]: {
            footer: {
                flexDirection: 'column',
            },
            copyright: {
                textAlign: 'center',
            },
            medicalEntity: {
                marginBottom: 10,
            },
            menu: {
                marginTop: 10,
                width: '75%',
                justifyContent: 'space-between',
            },
            link: {
                marginBottom: 10,
            },
        },
    }),
);

export default useStyles;
