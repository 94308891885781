import React from 'react';
import {Box, Grid, Link} from '@material-ui/core';
import {CustomTextField, FormGroup, Label} from '../../index';
import CustomButton from '../../common/Button/Button';
import {LOGIN_PAGE} from '../../../const/routes';
import useStyles from './PasswordChangeEmailFormStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useFormik} from 'formik';
import {forgotPasswordEmailSchema} from '../../../validators/forgotPassword';
import {useHistory} from 'react-router';

interface PasswordChangeEmailFormProps {
    onSubmit: (values: {email: string}) => void;
    error?: string;
}

const PasswordChangeEmailForm = ({
    onSubmit,
    error,
}: PasswordChangeEmailFormProps) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const history = useHistory();
    const {values, handleChange, handleBlur, touched, errors, handleSubmit} =
        useFormik({
            initialValues: {
                email: '',
            },
            validationSchema: forgotPasswordEmailSchema,
            validateOnBlur: true,
            onSubmit,
        });

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction={'column'}>
                <Box className={classes.title}>
                    {t('typeEmailForPasswordChange')}
                </Box>
                <FormGroup className={classes.emailField}>
                    <Label htmlFor="email">E-mail</Label>
                    <CustomTextField
                        id="email"
                        name="email"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        helperText={touched.email && t(errors.email)}
                        error={touched.email && !!errors.email}
                    />
                </FormGroup>
                <Box className={classes.error}>{t(error)}</Box>
                <CustomButton
                    variant={'contained'}
                    color={'secondary'}
                    className={classes.submitButton}
                    type={'submit'}
                >
                    {commonT('next')}
                </CustomButton>
                <Link
                    color={'secondary'}
                    onClick={() => history.push(LOGIN_PAGE)}
                    style={{cursor: 'pointer'}}
                >
                    {t('goBackToLogin')}
                </Link>
            </Grid>
        </form>
    );
};

export default PasswordChangeEmailForm;
