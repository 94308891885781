import {Trans, useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useFormik} from 'formik';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import useStyles from './NewSickLeaveFormStyles';
import {
    MedicationPackDto,
    MedicinalProductDto,
    Prescription,
} from '../../../types/prescription';
import {mapMedicinalProductForAutocomplete} from '../../../utils/prescription';
import {KeyboardDatePicker} from '@material-ui/pickers';
import * as yup from 'yup';
import {debounce} from 'lodash-es';
import { CheckBox } from '@material-ui/icons';
import { NewTabLink } from '../../common/navigation';
import { PayerDto, ZLADto } from '../../../types/sickLeave';
import isuredApi from '../../../api/isured/isuredApi';
import { isNotNullOrUndefined } from '../../../utils/lang';
import sessionApi from '../../../api/zusZLA/sessionApi';
import kedZLAApi from '../../../api/zusZLA/kedZLAApi';
import { Autocomplete } from '@material-ui/lab';
import { ICD10Code, ICD10CodeTranslated } from '../../../types/icd10';
import { getIcd10Codes, getSimpleIcd10Codes } from '../../../services/ICD10Provider';
import { getStringWithoutUnnecessarySearchCharacters } from '../../common/search/CustomSearch';
import { splitTextBySpaces } from '../../../utils/string';
import { getIcd10CodeNameForProvidedLanguage, mapICD10ForAutocomplete } from '../../../utils/icd10';
import { ICD10AutoCompleteSearch } from '../../common/search';
import { AppointmentDetailsForPrescriptionDto, Child } from '../../../types/appointments';

type NewSickLeaveFormProps = {
    onSubmit: (zla: ZLADto) => void;
    appointment:AppointmentDetailsForPrescriptionDto;
    disabled: boolean
};

const REQUIRED_STRING = 'requiredField';
const POPUP_ICD10_CODE_AND_NAME_SEPARATOR = '-';
const DEBOUNCE_TIME = 1000;

const NewSickLeaveForm = ({onSubmit, appointment,disabled}: NewSickLeaveFormProps) => {
    const {t} = useTranslation(i18nNamespaces.L4);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useStyles();
    
    const [insurancePlaces, setInsurancePlaces] = useState<string[]>([]);
    const [payers, setPayers] = useState<PayerDto[]>(
        []);
    const [medicalRecommendations, setMedicalRecommendations] = useState<string[]>([])

    const [iCD10Codes, setICD10Codes] = useState<
         ICD10Code[]>([]);
    const [iCD10CodesLoading, setICD10CodesLoading] =
        useState<boolean>(false);
    const [icd10CodesInputValue, setICD10CodesInputValue] =
        useState<string>('');

    const formik = useFormik({
        initialValues: {
            stayInStationaryZOZ: false,
            notSendInformationToPayer:false,
            medicalRecommendation: '' as string,
            payer: null as PayerDto,
            unableToWorkFromDate: new Date(),
            unableToWorkToDate: new Date(),
            insurancePlace: '' as string,
            icd10: null as ICD10CodeTranslated,
        },
        validationSchema: yup.object({
            icd10: yup
                .object()
                .nullable()
                .required(commonT(REQUIRED_STRING)),
                payer: yup
            .object()
                .nullable()
                .required(commonT(REQUIRED_STRING)),
            insurancePlace: yup
                .string()
                .nullable()
                .required(commonT(REQUIRED_STRING)),
            medicalRecommendation: yup
                .string()
                .nullable()
                .required(commonT(REQUIRED_STRING)),
            unableToWorkFromDate: yup.date().required(commonT(REQUIRED_STRING)),
            unableToWorkToDate: yup.date().required(commonT(REQUIRED_STRING)),
        }),
        onSubmit: values => {
            onSubmit({
                appointmentId:appointment.id, 
                isured: {
                    patientId:appointment.patient.userId,
                    pesel:appointment.patient.pesel,
                    placeOfInsurance:values.insurancePlace,
                    child: appointment.child && {
                        pesel: appointment.child.pesel
                    } || null
                },
                payer:{
                    payerName:values.payer.payerName,
                    payerIdType:values.payer.payerIdType,
                    id:values.payer.id
                },
                unableToWorkFromDate:values.unableToWorkFromDate,
                unableToWorkToDate:values.unableToWorkToDate,
                stayInStationaryZOZ:values.stayInStationaryZOZ,
                doNotSendInformationToPayer:values.notSendInformationToPayer,
                icd10:values.icd10.code,
                medicalRecommendation:values.medicalRecommendation
                
            });
            
        },
    });


    const handleChangePayer = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        const payer =( payers && payers.find(v => v.id == event.target.value)) ||null
        formik.setFieldValue('payer', payer);
    };

    const handleChangeInsurancePlace = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        formik.setFieldValue('insurancePlace', event.target.value);
    };

    const handleChangeMedicalRecommendation = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        formik.setFieldValue('medicalRecommendation', event.target.value);
    };

    const fetchICD10CodesList = async (searchValue: string) => {
        try {
            const searchTermWithoutUnnecessaryCharacters =
            getStringWithoutUnnecessarySearchCharacters(searchValue);

            const query = splitTextBySpaces(searchTermWithoutUnnecessaryCharacters)
            .filter(
                // removes standalone dash characters
                st => st !== POPUP_ICD10_CODE_AND_NAME_SEPARATOR,
            )
            .join(' ');

            const {data: codes} = await getSimpleIcd10Codes(query);
            setICD10Codes(codes);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    const debouncedSearch = useRef(
        debounce(async (search: string) => {
            try {
                setICD10CodesLoading(true);
                await fetchICD10CodesList(search);
            } finally {
                setICD10CodesLoading(false);
            }
        }, DEBOUNCE_TIME),
    ).current;

    useEffect(() => {
        debouncedSearch(icd10CodesInputValue);
    }, [icd10CodesInputValue]);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const fetchInsurancePlacesList = async () => {
        try {
            const {data} = await isuredApi.getInsurancePlaces( );
            setInsurancePlaces(data.result);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    const fetchMedicalRecommendationsList = async () =>{
        try {
            const {data} = await isuredApi.getMedicalRecommendations( );
            setMedicalRecommendations(data.result);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    const fetchPayersList = async () =>{
        try {
            const {data} = await isuredApi.getPayers(appointment.patient.pesel, formik.values.insurancePlace);
            setPayers(data.result);
        } catch (error) {
            setPayers([])
            console.error(error);
        } finally {
        }
    };

    useEffect(()=> {
        fetchMedicalRecommendationsList();
        fetchInsurancePlacesList();
    }, []);

    useEffect(()=>{
        if (insurancePlaces.length > 0 && isNotNullOrUndefined(formik.values.insurancePlace))
            fetchPayersList();
    }, [formik.values.insurancePlace])

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className={classes.container}>

            <Grid item xs={12}>
                <ICD10AutoCompleteSearch
                    disabled={disabled}
                    iCD10Codes={iCD10Codes}
                    optionSelected={formik.values.icd10}
                    onChange = {(_, newValue) => {
                        formik.setFieldValue('icd10', newValue);
                    }}
                    error={
                        formik.touched.icd10 &&
                        Boolean(formik.errors.icd10)
                    }
                    helperText={
                        formik.touched.icd10 &&
                        formik.errors.icd10
                    }
                    iCD10CodesLoading={iCD10CodesLoading}
                    setICD10CodesInputValue={setICD10CodesInputValue}
                    
                    /> 
            </Grid>


            <Grid item xs={12}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    name="unableToWorkFromDate"
                    id="unableToWorkFromDate"
                    label={t('unableToWorkFromDate')}
                    value={formik.values.unableToWorkFromDate}
                    onChange={value =>
                        formik.setFieldValue('unableToWorkFromDate', value, true)
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    inputVariant="outlined"
                    fullWidth
                    minDate={new Date(Date.now() -3 * 24*60*60*1000)}
                    disabled={disabled}
                />
            </Grid>

            
            <Grid item xs={12}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    name="unableToWorkToDate"
                    id="unableToWorkToDate"
                    label={t('unableToWorkToDate')}
                    value={formik.values.unableToWorkToDate}
                    onChange={value =>
                        formik.setFieldValue('unableToWorkToDate', value, true)
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    inputVariant="outlined"
                    fullWidth
                    minDate={new Date(Date.now() -3 * 24*60*60*1000)}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(
                            formik.touched.medicalRecommendation &&
                                formik.errors.medicalRecommendation,
                        )}
                    >
                        <InputLabel>{t('medicalRecommendations')}</InputLabel>
                        <Select
                            name="medicalRecommendation"
                            label={t('medicalRecommendation')}
                            value={formik.values.medicalRecommendation}
                            onChange={handleChangeMedicalRecommendation}
                            fullWidth
                            disabled={disabled}
                        >
                            {medicalRecommendations.map(option => (
                                <MenuItem key={option} value={option}>
                                    {t(option)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText
                            variant="outlined"
                            error={Boolean(
                                formik.touched.medicalRecommendation &&
                                    formik.errors.medicalRecommendation,
                            )}
                        >
                            {formik.errors.medicalRecommendation}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(
                            formik.touched.insurancePlace &&
                                formik.errors.insurancePlace,
                        )}
                    >
                        <InputLabel>{t('insurancePlaces')}</InputLabel>
                        <Select
                            name="insurancePlace"
                            label={t('insurancePlace')}
                            value={formik.values.insurancePlace}
                            onChange={handleChangeInsurancePlace}
                            fullWidth
                            disabled={disabled}
                        >
                            {insurancePlaces.map(option => (
                                <MenuItem key={option} value={option}>
                                    {t(option)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText
                            variant="outlined"
                            error={Boolean(
                                formik.touched.insurancePlace &&
                                    formik.errors.insurancePlace,
                            )}
                        >
                            {formik.errors.insurancePlace}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                 
                 
                 <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(
                            formik.touched.payer &&
                                formik.errors.payer,
                        )}
                    >
                    <InputLabel>{t('payer')}</InputLabel>
                    <Select
                        name="payer"
                        label={t('payer')}
                        value={formik.values.payer?.payerName}
                        onChange={handleChangePayer}
                        fullWidth
                        disabled={disabled}
                    >
                        {payers?.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.payerName}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText
                        variant="outlined"
                        error={Boolean(
                            formik.touched.payer &&
                                formik.errors.payer,
                        )}
                    >
                        {formik.errors.payer}
                    </FormHelperText>
                </FormControl>
            </Grid>


            <Grid item xs={12}>
                <FormControlLabel
                        control={
                            <Checkbox
                                id="stayInStationaryZOZ"
                                name="stayInStationaryZOZ"
                                checked={formik.values.stayInStationaryZOZ}
                                onChange={formik.handleChange}
                                disabled={disabled}
                            />
                        }
                        label={
                            <Trans
                                t={t}
                                i18nKey="stayInStationaryZOZ" 
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                            control={
                                <Checkbox
                                    id="notSendInformationToPayer"
                                    name="notSendInformationToPayer"
                                    checked={formik.values.notSendInformationToPayer}
                                    onChange={formik.handleChange}
                                    disabled={disabled}
                                />
                            }
                            label={
                                <Trans
                                    t={t}
                                    i18nKey="notSendInformationToPayer" 
                                />
                            }
                        />
                </Grid>

                <Grid item xs={12} style={{textAlign: 'right'}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        style={{fontWeight: 'bold'}}
                        disabled={disabled}
                        className={classes.button}
                    >
                        {t('validate')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default NewSickLeaveForm;
