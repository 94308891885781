import {createContext, useContext} from 'react';
import {LaboratoryAddressesState} from './useLaboratoryAddresses';

export const LaboratoryAddressesContext = createContext<LaboratoryAddressesState>(undefined);

export const useLaboratoryAddressesContext = () => {
    const context = useContext(LaboratoryAddressesContext);

    // if `undefined`, then throw an error
    if (context === undefined) {
        throw new Error("LaboratoryAddressesContext was used outside of its Provider");
    }

    return context;
};