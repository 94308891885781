import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Card, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useStyles from './NotesTabStyles';
import NotesInputSection from './NotesInputSection/NotesInputSection';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    Appointment,
    AppointmentAdditionalInformation,
    AppointmentStateEnum,
} from '../../../types/appointments';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import AppointmentsProvider from '../../../services/appointmentsProvider';
import appointmentsProvider from '../../../services/appointmentsProvider';
import {toBase64} from '../../../utils/file';
import {
    removePrescription,
    selectPrescriptionsMetaData,
    uploadPrescription,
} from '../../../store/appointmentAttachmentsSlice';
import {useDownloadAsync} from '../../../hooks/appointmentFileUpload';
import {fetchAppointmentDetails as fetchAppointmentDetailsForConsultation} from '../../../store/consultationSlice';
import {fetchAppointmentDetails as fetchAppointmentDetailsForMedic} from '../../../store/appointmentDetailsSlice';
import NotesLinks from './NotesLinks/NotesLinks';
import {PageSectionCard} from '../../common/layout';
import {ICD10Search} from '../../common/search';
import {Loading} from '../../index';
import {CustomAlert} from '../../common/feedback';
import {Color} from '@material-ui/lab/Alert/Alert';
import {checkBothSidesConnectedToOngoingAppointment} from '../../../utils/appointment';
import {refineString} from '../../../utils/string';
import ReferralsListTab from '../ReferralsTab/ReferralsListTab';
import PrescriptionsList from './PrescriptionsList/PrescriptionsList';
import SaveIconButton from './SaveIconButton/SaveIconButton';
import L4List from '../L4Tab/L4List';
import {debounce} from 'lodash-es';

const DEBOUNCE_DELAY = 1000;

// undefined for NoteEditedValue occurs initially only, when input is not dirty
export type NoteEditedValue = AppointmentAdditionalInformation | undefined;
type NoteEditedSetValue = (value: AppointmentAdditionalInformation) => void;

interface Props {
    appointment: Appointment;
    source: 'medicDetails' | 'chat';
    referralMedicalRecommendationsEdited: NoteEditedValue;
    setReferralMedicalRecommendationsEdited: NoteEditedSetValue;
    drugMedicalRecommendationsEdited: NoteEditedValue;
    setDrugMedicalRecommendationsEdited: NoteEditedSetValue;
    medicalInterviewEdited: NoteEditedValue;
    setMedicalInterviewEdited: NoteEditedSetValue;
    otherMedicalRecommendationsEdited: NoteEditedValue;
    setOtherMedicalRecommendationsEdited: NoteEditedSetValue;
}

type NoteType =
    | 'drugMedicalRecommendations'
    | 'referralMedicalRecommendations'
    | 'otherMedicalRecommendations'
    | 'medicalInterview'
    | 'all';

const NotesTab = ({
    appointment,
    source,
    referralMedicalRecommendationsEdited,
    setReferralMedicalRecommendationsEdited,
    drugMedicalRecommendationsEdited,
    setDrugMedicalRecommendationsEdited,
    medicalInterviewEdited,
    setMedicalInterviewEdited,
    otherMedicalRecommendationsEdited,
    setOtherMedicalRecommendationsEdited,
}: Props) => {
    const [loadingNote, setLoadingNote] = useState<NoteType>(null);
    const [icd10CodeSaving, setIcd10CodeSaving] = useState(false);
    const [alertInfo, setAlertInfo] =
        useState<{severity: Color; message: string}>(null);

    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const {t: tIcd} = useTranslation(i18nNamespaces.ICD);

    const dispatch = useAppDispatch();
    const downloadFileAsync = useDownloadAsync();
    const prescriptionsMetaData = useAppSelector(selectPrescriptionsMetaData);

    const isAppointmentSettled = appointment?.state.isSettled;

    const icd10SearchEnabled =
        !!appointment &&
        !isAppointmentSettled &&
        (checkBothSidesConnectedToOngoingAppointment(appointment) ||
            appointment.state.state === AppointmentStateEnum.Completed);

    const appointmentIcd10Code = appointment?.icd10Code;

    const drugMedicalRecommendationsRef = useRef(null);
    const referralMedicalRecommendationsRef = useRef(null);
    const otherMedicalRecommendationsRef = useRef(null);
    const medicalInterviewRef = useRef(null);
    const isInputDirty = (editedValue: NoteEditedValue) => {
        return editedValue !== undefined;
    };

    const getInputValue = (
        initialValue: AppointmentAdditionalInformation,
        editedValue: NoteEditedValue,
    ) => {
        return isInputDirty(editedValue) ? editedValue : initialValue;
    };

    const isSaveButtonEnabled = (
        initialValue: AppointmentAdditionalInformation,
        editedValue: NoteEditedValue,
    ) => {
        return (
            isInputDirty(editedValue) &&
            refineString(editedValue) !== refineString(initialValue) &&
            loadingNote === null
        );
    };

    const fetchAppointment = useCallback(async () => {
        if (source === 'medicDetails') {
            await dispatch(
                fetchAppointmentDetailsForMedic({
                    appointmentId: appointment.id,
                    fetchSilently: true,
                }),
            );
        } else if (source === 'chat') {
            await dispatch(
                fetchAppointmentDetailsForConsultation({
                    appointmentId: appointment.id,
                    fetchSilently: true,
                }),
            );
        } else {
            throw new Error('Not supported source');
        }
    }, [source, appointment.id]);

    const saveNote = async (
        additionalDetails: {
            drugMedicalRecommendations: string;
            referralMedicalRecommendations: string;
            otherMedicalRecommendations: string;
            medicalInterview: string;
        },
        noteType: NoteType,
    ) => {
        try {
            setLoadingNote(noteType);
            await AppointmentsProvider.setMedicAppointmentAdditionalDetails(
                appointment.id,
                additionalDetails,
            );
        } catch (e) {
            setAlertInfo({
                severity: 'error',
                message: t('saveNoteError'),
            });
            console.error(e);
        } finally {
            await fetchAppointment();
            setLoadingNote(null);
        }
    };

    const saveMedicalInterview = (newMedicalInterview: string) => {
        const additionalDetails = {
            drugMedicalRecommendations: appointment.drugMedicalRecommendations,
            referralMedicalRecommendations:
                appointment.referralMedicalRecommendations,
            otherMedicalRecommendations:
                appointment.otherMedicalRecommendations,
            medicalInterview: newMedicalInterview,
        };
        return saveNote(additionalDetails, 'medicalInterview');
    };

    const saveDrugMedicalRecommendations = (
        newDrugMedicalRecommendations: string,
    ) => {
        const additionalDetails = {
            drugMedicalRecommendations: newDrugMedicalRecommendations,
            referralMedicalRecommendations:
                appointment.referralMedicalRecommendations,
            otherMedicalRecommendations:
                appointment.otherMedicalRecommendations,
            medicalInterview: appointment.medicalInterview,
        };
        return saveNote(additionalDetails, 'drugMedicalRecommendations');
    };

    const saveReferralMedicalRecommendations = (
        newReferralMedicalRecommendations: string,
    ) => {
        const additionalDetails = {
            drugMedicalRecommendations: appointment.drugMedicalRecommendations,
            referralMedicalRecommendations: newReferralMedicalRecommendations,
            otherMedicalRecommendations:
                appointment.otherMedicalRecommendations,
            medicalInterview: appointment.medicalInterview,
        };
        return saveNote(additionalDetails, 'referralMedicalRecommendations');
    };

    const saveOtherMedicalRecommendations = (
        newOtherMedicalRecommendations: string,
    ) => {
        const additionalDetails = {
            drugMedicalRecommendations: appointment.drugMedicalRecommendations,
            referralMedicalRecommendations:
                appointment.referralMedicalRecommendations,
            otherMedicalRecommendations: newOtherMedicalRecommendations,
            medicalInterview: appointment.medicalInterview,
        };
        return saveNote(additionalDetails, 'otherMedicalRecommendations');
    };

    const prescriptionUploadHandler = async (files: File[]) => {
        const base64: string = await toBase64(files[0]);
        dispatch(
            uploadPrescription({
                appointmentId: appointment.id,
                prescriptionUploadData: {
                    fileName: files[0].name,
                    isPrescription: true,
                    contentType: files[0].type,
                    content: base64,
                },
            }),
        );
    };

    const prescriptionRemoveHandler = async (prescriptionId: string) => {
        dispatch(
            removePrescription({appointmentId: appointment.id, prescriptionId}),
        );
    };

    const prescriptionDownloadHandler = (attachmentId: string) => {
        downloadFileAsync(appointment.id, attachmentId);
    };

    const icd10CodeSelectedHandler = useCallback(
        async (icd10Code: string) => {
            setIcd10CodeSaving(true);
            try {
                await appointmentsProvider.updateAppointmentIcd10Code(
                    appointment.id,
                    icd10Code,
                );
                setAlertInfo({
                    severity: 'success',
                    message: tIcd('saveIcd10Success'),
                });
            } catch (e) {
                setAlertInfo({
                    severity: 'error',
                    message: tIcd('saveIcd10Error'),
                });
                console.error(e);
            } finally {
                await fetchAppointment();
                setIcd10CodeSaving(false);
            }
        },
        [appointment.id, fetchAppointment],
    );

    const isSaveInterviewWithPatientEnabled = isSaveButtonEnabled(
        appointment?.medicalInterview,
        medicalInterviewEdited,
    );

    const isSaveDrugMedicalRecommendationsEnabled = isSaveButtonEnabled(
        appointment?.drugMedicalRecommendations,
        drugMedicalRecommendationsEdited,
    );

    const isSaveReferralMedicalRecommendationsEnabled = isSaveButtonEnabled(
        appointment?.referralMedicalRecommendations,
        referralMedicalRecommendationsEdited,
    );

    const isSaveOtherMedicalRecommendationsEnabled = isSaveButtonEnabled(
        appointment?.otherMedicalRecommendations,
        otherMedicalRecommendationsEdited,
    );

    const isSaveAllButtonEnabled =
        isSaveInterviewWithPatientEnabled ||
        isSaveDrugMedicalRecommendationsEnabled ||
        isSaveReferralMedicalRecommendationsEnabled ||
        isSaveOtherMedicalRecommendationsEnabled;

    const saveAll = async () => {
        try {
            await saveNote(
                {
                    drugMedicalRecommendations:
                        drugMedicalRecommendationsRef?.current?.value,
                    referralMedicalRecommendations:
                        referralMedicalRecommendationsRef?.current?.value,
                    medicalInterview: medicalInterviewRef?.current?.value,
                    otherMedicalRecommendations:
                        otherMedicalRecommendationsRef?.current?.value,
                },
                'all',
            );
        } catch (error) {
            console.error(error);
        }
    };

    const debouncedSaveAll = useRef(debounce(saveAll, DEBOUNCE_DELAY)).current;

    useEffect(() => {
        if (
            medicalInterviewEdited !== undefined ||
            drugMedicalRecommendationsEdited !== undefined ||
            referralMedicalRecommendationsEdited !== undefined ||
            otherMedicalRecommendationsEdited !== undefined
        )
            debouncedSaveAll();
    }, [
        medicalInterviewEdited,
        drugMedicalRecommendationsEdited,
        referralMedicalRecommendationsEdited,
        otherMedicalRecommendationsEdited,
    ]);

    return (
        <Box className={classes.container}>
            <Box
                className={classes.sectionWrapper}
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                }}
            >
                <SaveIconButton
                    disabled={!isSaveAllButtonEnabled}
                    loading={loadingNote === 'all'}
                    label={t('saveAll').toUpperCase()}
                    onClick={saveAll}
                />
            </Box>
            <Box className={classes.sectionWrapper}>
                <NotesInputSection
                    headerText={t('interviewWithPatient')}
                    inputValue={getInputValue(
                        appointment?.medicalInterview,
                        medicalInterviewEdited,
                    )}
                    sectionDisabled={isAppointmentSettled}
                    saveButtonEnabled={isSaveInterviewWithPatientEnabled}
                    inputValueChanged={setMedicalInterviewEdited}
                    saveButtonLoading={loadingNote === 'medicalInterview'}
                    saveButtonClicked={() =>
                        saveMedicalInterview(medicalInterviewEdited)
                    }
                    inputRef={medicalInterviewRef}
                />
            </Box>
            <PageSectionCard style={{marginTop: 8}}>
                <PageSectionCard.Header>
                    <Typography
                        variant={'h5'}
                        style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            lineHeight: '32px',
                        }}
                    >
                        {tIcd('addICD10code')}
                    </Typography>
                </PageSectionCard.Header>
                <PageSectionCard.Content>
                    <ICD10Search
                        defaultIcd10Code={appointmentIcd10Code}
                        onIcd10CodeSelected={icd10CodeSelectedHandler}
                        disabled={!icd10SearchEnabled && !!appointment}
                    />
                </PageSectionCard.Content>
            </PageSectionCard>
            <Box className={classes.sectionWrapper}>
                <NotesInputSection
                    headerText={t('drugMedicalRecommendations')}
                    inputValue={getInputValue(
                        appointment?.drugMedicalRecommendations,
                        drugMedicalRecommendationsEdited,
                    )}
                    sectionDisabled={isAppointmentSettled}
                    saveButtonEnabled={isSaveDrugMedicalRecommendationsEnabled}
                    inputValueChanged={setDrugMedicalRecommendationsEdited}
                    saveButtonLoading={
                        loadingNote === 'drugMedicalRecommendations'
                    }
                    saveButtonClicked={() =>
                        saveDrugMedicalRecommendations(
                            drugMedicalRecommendationsEdited,
                        )
                    }
                    prescriptions={prescriptionsMetaData}
                    prescriptionUploadHandler={prescriptionUploadHandler}
                    prescriptionRemoveHandler={prescriptionRemoveHandler}
                    prescriptionDownloadHandler={prescriptionDownloadHandler}
                    inputRef={drugMedicalRecommendationsRef}
                />
            </Box>
            <Box className={classes.sectionWrapper}>
                <NotesInputSection
                    headerText={t('referralMedicalRecommendations')}
                    inputValue={getInputValue(
                        appointment?.referralMedicalRecommendations,
                        referralMedicalRecommendationsEdited,
                    )}
                    sectionDisabled={isAppointmentSettled}
                    saveButtonEnabled={
                        isSaveReferralMedicalRecommendationsEnabled
                    }
                    inputValueChanged={setReferralMedicalRecommendationsEdited}
                    saveButtonLoading={
                        loadingNote === 'referralMedicalRecommendations'
                    }
                    saveButtonClicked={() =>
                        saveReferralMedicalRecommendations(
                            referralMedicalRecommendationsEdited,
                        )
                    }
                    inputRef={referralMedicalRecommendationsRef}
                />
            </Box>
            <Box className={classes.sectionWrapper}>
                <NotesInputSection
                    headerText={t('otherMedicalRecommendations')}
                    inputValue={getInputValue(
                        appointment?.otherMedicalRecommendations,
                        otherMedicalRecommendationsEdited,
                    )}
                    sectionDisabled={isAppointmentSettled}
                    saveButtonEnabled={isSaveOtherMedicalRecommendationsEnabled}
                    inputValueChanged={setOtherMedicalRecommendationsEdited}
                    saveButtonLoading={
                        loadingNote === 'otherMedicalRecommendations'
                    }
                    saveButtonClicked={() =>
                        saveOtherMedicalRecommendations(
                            otherMedicalRecommendationsEdited,
                        )
                    }
                    inputRef={otherMedicalRecommendationsRef}
                />
            </Box>
            <Box className={classes.sectionWrapper}>
                <NotesLinks appointmentIdAsString={appointment.id} />
            </Box>
            <Box className={classes.sectionWrapper}>
                <PrescriptionsList appointmentId={parseInt(appointment.id)} />
            </Box>
            <Box className={classes.sectionWrapper}>
                <L4List appointmentId={parseInt(appointment.id)} />
            </Box>
            <Box className={classes.sectionWrapper}>
                <ReferralsListTab appointmentIdAsString={appointment.id} />
            </Box>

            <Loading loading={icd10CodeSaving} withBackdrop={true} />
            {alertInfo && <CustomAlert {...alertInfo} />}
        </Box>
    );
};

export default NotesTab;
