import React, {useState} from 'react';
import useStyles from './ForgotPasswordPageStyles';
import {Box, Grid} from '@material-ui/core';
import {
    PASSWORD_CHANGE_FINISH_PAGE,
    TIME_SLOTS_SEARCH_PAGE,
} from '../../const/routes';
import {useHistory} from 'react-router';
import {useAppSelector} from '../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../store/auth';
import {ForgetPasswordImage} from '../../assets';
import accountProvider from '../../services/accountProvider';
import PasswordChangeForm, {
    PasswordChangeFormValues,
} from '../../components/forms/PasswordChangeForm/PasswordChangeForm';
import PasswordChangeEmailForm from '../../components/forms/PasswordChangeEmailForm/PasswordChangeEmailForm';
import {AxiosError} from 'axios';
import {forgetPasswordErrorHandler} from '../../utils/error';
import {Loading} from '../../components';

const ForgotPasswordPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const authUserData = useAppSelector(selectAuthUserData);
    const [email, setEmail] = useState<string>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    if (authUserData) history.replace(TIME_SLOTS_SEARCH_PAGE);

    const handleError = (error: AxiosError | unknown) => {
        const axiosError = error as AxiosError;
        console.error(axiosError);
        setError(forgetPasswordErrorHandler(axiosError.response.data[0]));
    };

    const onEmailSend = async (values: {email: string}) => {
        try {
            setError('');
            setLoading(true);
            await accountProvider.acquireCodeForPasswordReset(values.email);
            setEmail(values.email);
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };

    const onPasswordChange = async (values: PasswordChangeFormValues) => {
        try {
            setError('');
            setLoading(true);
            await accountProvider.resetPassword(
                email,
                values.code,
                values.password,
            );
            history.push(PASSWORD_CHANGE_FINISH_PAGE);
        } catch (error: AxiosError | unknown) {
            handleError(error);
        }
        setLoading(false);
    };

    return (
        <Box className={classes.container}>
            <Loading withBackdrop loading={loading} />
            <Grid container className={classes.pageContent}>
                {email ? (
                    <PasswordChangeForm
                        email={email}
                        onSubmit={onPasswordChange}
                        error={error}
                    />
                ) : (
                    <PasswordChangeEmailForm
                        onSubmit={onEmailSend}
                        error={error}
                    />
                )}
                <Grid item container xs justifyContent={'center'}>
                    <img
                        className={classes.image}
                        src={ForgetPasswordImage}
                        alt={'Forgot password'}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ForgotPasswordPage;
