import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    IconButton,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import instructionProvider from '../../services/instructionProvider';
import { PdfImage } from '../../assets';
import filesDownloadProvider from '../../services/filesDownloadProvider';
import { Loading } from '../../components';

const HelpPage = () => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {t} = useTranslation(i18nNamespaces.HELP);

    const renderEmbedVideo = (link: string) => (
        <Box>
            <iframe
                width={isDesktop ? 600 : 300}
                height={isDesktop ? 315 : 170}
                src={`https://www.youtube.com/embed/${link}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </Box>
    );

    const download = async () => {
        setLoading(true);
        setError(null);
        try {
            const {data: instruction} = await instructionProvider.getMedicInstructionAsync();     
            filesDownloadProvider.downloadFileFromUrl(
                instruction,
                `${t('userGuide')}.pdf`
            )
        } catch (e) {
            console.error(
                e instanceof Error
                    ? e.message
                    : 'An error occurred while fetching instruction data'
            );
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container>
            <Box py={1} textAlign={'center'}>  
                <Typography
                    variant={isDesktop ? 'h2' : 'h5'}
                    style={{fontWeight: 'bold', marginBottom: 30}}>
                        {t('instructionForMedic')}                 
                </Typography>
                <Typography variant={'h6'}>
                    {t('userGuide')}
                    <IconButton
                        onClick={() => download()}>
                        <img src={PdfImage} width={24} />
                    </IconButton>
                </Typography>
                <Typography
                    variant={isDesktop ? 'h2' : 'h5'}
                    style={{fontWeight: 'bold', marginBottom: 30}}
                >
                    {t('instructionalVideos')}
                </Typography>
                <Typography variant={'h6'}>
                    {t('medicFirstLogIn')}
                    {renderEmbedVideo('V3EH6GiKdSo')}
                </Typography>
                <Typography variant={'h6'}>
                    {t('medicChangeBasicData')}
                    {renderEmbedVideo('CdXG42QGfKo')}
                </Typography>
                <Typography variant={'h6'}>
                    {t('medicSetSchedule')}
                    {renderEmbedVideo('qn66Jgs055g')}
                </Typography>
                <Typography variant={'h6'}>
                    {t('medicTeleConsultation')}
                    {renderEmbedVideo('nZydSbTKgxk')}
                </Typography>
                <Typography variant={'h6'}>
                    {t('medicInternalReferral')}
                    {renderEmbedVideo('uNi9qppmycg')}
                </Typography>
                <Typography variant={'h6'}>
                    {t('medicTestReferral')}
                    {renderEmbedVideo('Edpy1pgXF8Q')}
                </Typography>
                <Typography variant={'h6'}>
                {t('needHelpInfo')}:
                    info@global-teleclinic.com +48 792 310 907
                </Typography>
                <Loading loading={loading} withBackdrop />
            </Box>
        </Container>
    );
};

export default HelpPage;
