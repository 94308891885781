import {DateTime} from 'luxon';
import {MedicCalendarEntryDto} from '../types/medicCalendarEntry';
import getDateWithoutTime from '../utils/getDateWithoutTime';
import {MEDIC_EVENTS} from './endpoints';
import { http } from './http';

class MedicCalendarEntriesProvider {
    async getCalendarEntriesForCurrentMedic(
        date: DateTime,
        historical: boolean,
    ): Promise<MedicCalendarEntryDto[]> {
        return (
            await http.get<MedicCalendarEntryDto[]>(MEDIC_EVENTS, {
                params: {
                    from: getDateWithoutTime(date).toUTC().toISO(),
                    to: getDateWithoutTime(date)
                        .plus({days: 1})
                        .toUTC()
                        .toISO(),
                    historical,
                },
            })
        ).data;
    }
}

export default new MedicCalendarEntriesProvider();
