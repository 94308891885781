import React from 'react';
import useStyles from './QuestionnairePageStyles';
import {QuestionnaireForm, Loading} from '../../components';
import EmptyTopBar from '../../components/Layout/EmptyTopBar/EmptyTopBar';
import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {WomanWithPhone} from '../../assets';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {useHistory, useParams} from 'react-router';
import {getAppointmentTypes, getMedicFullName} from '../../utils/appointment';
import {getDateTime} from '../../utils/date';
import {useAppSelector} from '../../hooks/customReduxHooks';
import {selectQuestionnaireStatus} from '../../store/questionnaireSlice';
import useAppointmentInfo from '../../hooks/useAppointmentInfo';
import {USER_ACCOUNT_PAGE} from '../../const/routes';

const QuestionnairePage = () => {
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const {appointmentId} = useParams<{appointmentId: string}>();
    const appointmentDetails = useAppointmentInfo(appointmentId);
    const {t: tAppointment} = useTranslation(i18nNamespaces.APPOINTMENT);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const history = useHistory();
    const status = useAppSelector(selectQuestionnaireStatus);

    const getAppointmentTypeInfo = () =>
        getAppointmentTypes(commonT)
            .find(type => type.value === appointmentDetails.type)
            .label.toLowerCase();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={classes.page}
        >
            <Loading withBackdrop={true} loading={status === 'loading'} />

            <EmptyTopBar
                onCancel={() => history.push(USER_ACCOUNT_PAGE)}
            />

            <Box className={classes.pageContent}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={classes.paymentSection}
                >
                    <Box>
                        <Box className={classes.paymentTitleContainer}>
                            <Box className={classes.paymentTitle}>
                                {tAppointment('arrangeAnAppointment')}
                            </Box>
                        </Box>
                        {appointmentDetails && (
                            <>
                                <Box>
                                    {getMedicFullName(
                                        appointmentDetails?.medic,
                                    )}
                                </Box>
                                <Box>
                                    {getDateTime(appointmentDetails?.startDate)}
                                    {', '}
                                    {getAppointmentTypeInfo()}
                                </Box>
                            </>
                        )}
                    </Box>
                    {isDesktop && (
                        <Box>
                            <img
                                src={WomanWithPhone}
                                alt="Payment image"
                                className={classes.paymentImage}
                            />
                        </Box>
                    )}
                </Grid>
                <QuestionnaireForm />
            </Box>
        </Grid>
    );
};

export default QuestionnairePage;
