import {DateTime} from 'luxon';
import {
    Appointment,
    AppointmentChangingData,
    AppointmentDto,
} from '../../types/appointments';

export const synchronizeAppointment = (
    appointment: Appointment,
    appointmentChangingData: AppointmentChangingData,
) => ({
    ...appointment,
    state: appointmentChangingData.state,
    paymentStatus: appointmentChangingData.paymentStatus,
    medic: {
        ...appointment.medic,
        connected: appointmentChangingData.medicConnected,
    },
    patient: {
        ...appointment.patient,
        connected: appointmentChangingData.patientConnected,
    },
    patientReasons: appointmentChangingData.patientReasons,
    drugMedicalRecommendations:
        appointmentChangingData.drugMedicalRecommendations,
    referralMedicalRecommendations:
        appointmentChangingData.referralMedicalRecommendations,
    otherMedicalRecommendations:
        appointmentChangingData.otherMedicalRecommendations,
    canCancelWithRefund: appointmentChangingData.canCancelWithRefund,
    recommendationIsAvailable:
        appointmentChangingData.recommendationIsAvailable,
    chatTranscriptionIsAvailable:
        appointmentChangingData.chatTranscriptionIsAvailable,
    medicalInterview: appointmentChangingData.medicalInterview,
});

const mapAppointment = (appointmentDto: AppointmentDto): Appointment => ({
    id: appointmentDto.id,
    startDate: DateTime.fromISO(appointmentDto.startDate),
    endDate: DateTime.fromISO(appointmentDto.endDate),
    patient: {
        ...appointmentDto.patient,
        connected: appointmentDto.patientConnected,
    },
    child: appointmentDto.child,
    medic: {
        ...appointmentDto.medic,
        connected: appointmentDto.medicConnected,
    },
    medicalService: appointmentDto.medicalService,
    patientReasons: appointmentDto.patientReasons,
    drugMedicalRecommendations: appointmentDto.drugMedicalRecommendations,
    referralMedicalRecommendations:
        appointmentDto.referralMedicalRecommendations,
    otherMedicalRecommendations: appointmentDto.otherMedicalRecommendations,
    medicalInterview: appointmentDto.medicalInterview,
    type: appointmentDto.type,
    paymentStatus: appointmentDto.paymentStatus,
    state: appointmentDto.state,
    canCancelWithRefund: appointmentDto.canCancelWithRefund,
    recommendationIsAvailable: appointmentDto.recommendationIsAvailable,
    chatTranscriptionIsAvailable: appointmentDto.chatTranscriptionIsAvailable,
    icd10Code: appointmentDto.icD10Code
        ? {
              code: appointmentDto.icD10Code,
              text: {
                  pl: appointmentDto.icD10TextPl,
                  en: appointmentDto.icD10TextEn,
              },
          }
        : null,
    appointmentGroup: appointmentDto.appointmentGroup,
    isOccupationalMedicineDocumentationCompleted:
        appointmentDto.isOccupationalMedicineDocumentationCompleted,
    bookedByAgent: appointmentDto.bookedByAgent
});

export default mapAppointment;
