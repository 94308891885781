import {Box, Button, Grid} from '@material-ui/core';
import useStyles from './OccupationalMedicineFilledPageStyles';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import i18nNamespaces from '../../const/i18nNamespaces';
import {CustomButton, EmptyTopBar} from '../../components';
import { USER_ACCOUNT_PAGE } from '../../const/routes';
import {EvaluationFinishImage, FinishScheduleImage} from '../../assets';
import Footer from '../../components/Layout/Footer/Footer';

const OccupationalMedicineFilledPage = () =>{
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const history = useHistory();
    const location = useLocation<{appointmentId: string}>();
    const appointmentId = parseInt(location?.state?.appointmentId);

    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);

    const handleGoBack = () => {
        //if (appointmentId) dispatch(fetchOccupationalMedicine({appointmentId})); // TODO 
        window.close();
    };

    return (
        <Box className={classes.orderInfoRoot}>
            <img src={FinishScheduleImage} />
            <Box className={classes.orderInfoHeaderText}>
                {t('occupationalMedicineHasBeenCreated')}
            </Box>

            <Box className={classes.thanksGivingText}>
                {t('goBackToAppointment')}
            </Box>
            <Button
                variant={'contained'}
                color={'secondary'}
                style={{fontWeight: 'bold'}}
                onClick={handleGoBack}
            >
                {commonT('goBack')}
            </Button>
        </Box>
    );
};

export default OccupationalMedicineFilledPage;