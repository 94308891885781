import React from 'react';
import {LANGUAGES} from '../../../../const/lang';
import {MenuItem, Select} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {SelectInputProps} from '@material-ui/core/Select/SelectInput';
import {makeStyles} from '@material-ui/styles';

interface LanguageSelectorProps {
    className?: string;
    onChangeLanguage?: SelectInputProps['onChange'];
}

const useStyles = makeStyles(() => ({
    root: {
        padding: 5,
    },
}));

const LanguageSelector = ({
    className,
    onChangeLanguage,
}: LanguageSelectorProps) => {
    const {i18n} = useTranslation();
    const currentLanguage = i18n?.language.split('-')[0];
    const classes = useStyles();
    return (
        <Select
            value={currentLanguage}
            style={{width: 50}}
            className={className}
            onChange={onChangeLanguage}
            classes={{root: classes.root}}
        >
            {LANGUAGES.map(lang => (
                <MenuItem key={lang.value} value={lang.value}>
                    {lang.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default LanguageSelector;
