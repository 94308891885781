import {
    Box,
    Button,
    Grid,
    InputLabel,
    MenuItem,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {
    MicNone,
    MicOffOutlined,
    VideocamOffOutlined,
    VideocamOutlined,
} from '@material-ui/icons';
import {CustomSelect} from '../..';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectAudioInputDevices,
    selectAudioInputOn,
    selectAudioOutputDevices,
    selectSelectedAudioInputDeviceId,
    selectSelectedAudioOutputDeviceId,
    selectSelectedVideoInputDeviceId,
    selectVideoInputDevices,
    selectVideoInputOn,
    selectVideoStream,
} from '../../../store/consultationSlice';
import CustomButton from '../../common/Button/Button';
import useStyles from './SettingsStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import React from 'react';
import {CustomInputChangeEvent} from '../../../types/common';
import {useVideoStreamParams} from '../../../hooks/conversation/useVideoStreamParams';

interface Props {
    approve?: () => void;
    handleVideoTurnOnOff?: () => void;
    handleAudioTurnOnOff?: () => void;
    handleSelectVideoInput?: (e: CustomInputChangeEvent) => void;
    handleSelectAudioInput?: (e: CustomInputChangeEvent) => void;
    handleSelectAudioOutput?: (e: CustomInputChangeEvent) => void;
    inAppointment?: boolean;
    error?: string;
}

const Settings = ({
    approve,
    handleVideoTurnOnOff,
    handleAudioTurnOnOff,
    handleSelectVideoInput,
    handleSelectAudioInput,
    handleSelectAudioOutput,
    inAppointment,
    error,
}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);
    const videoStream = useAppSelector(selectVideoStream);
    const selectedAudioInputDeviceId = useAppSelector(
        selectSelectedAudioInputDeviceId,
    );
    const selectedVideoInputDeviceId = useAppSelector(
        selectSelectedVideoInputDeviceId,
    );
    const audioInputDevices = useAppSelector(selectAudioInputDevices);
    const videoInputDevices = useAppSelector(selectVideoInputDevices);
    const audioOutputDevices = useAppSelector(selectAudioOutputDevices);
    const selectedAudioOutputDeviceId = useAppSelector(
        selectSelectedAudioOutputDeviceId,
    );
    const videoInputOn = useAppSelector(selectVideoInputOn);
    const audioInputOn = useAppSelector(selectAudioInputOn);

    const {aspectRatio: videoStreamAspectRatio} =
        useVideoStreamParams(videoStream);

    const mapAudioInputDevices =
        audioInputDevices &&
        audioInputDevices.map(item => (
            <MenuItem value={item.deviceId} key={item.label}>
                {item.label}
            </MenuItem>
        ));

    const mapAudioOutputDevices =
        audioOutputDevices &&
        audioOutputDevices.map(item => (
            <MenuItem value={item.deviceId} key={item.label}>
                {item.label}
            </MenuItem>
        ));

    const mapVideoDevices =
        videoInputDevices &&
        videoInputDevices.map(item => (
            <MenuItem value={item.deviceId} key={item.label}>
                {item.label}
            </MenuItem>
        ));

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
                {handleSelectVideoInput &&
                    (videoInputOn ? (
                        <video
                            ref={video => {
                                if (video) {
                                    video.srcObject = videoStream;
                                }
                            }}
                            style={{
                                aspectRatio: videoStreamAspectRatio?.toString(),
                            }}
                            playsInline
                            autoPlay
                            muted={true}
                            className={classes.video}
                        />
                    ) : (
                        <Box className={classes.disabledVideo}>
                            <VideocamOffOutlined />
                        </Box>
                    ))}

                {handleSelectVideoInput && !inAppointment && (
                    <Box className={classes.underCameraSettingsWrapper}>
                        <Button
                            onClick={handleAudioTurnOnOff}
                            className={classes.switchButton}
                        >
                            <Box className={classes.switchButtonContent}>
                                {audioInputOn ? (
                                    <MicNone color="secondary" />
                                ) : (
                                    <MicOffOutlined color="disabled" />
                                )}
                                {t('sound')}
                            </Box>
                        </Button>
                        <Box className={classes.verticalLine} />
                        <Button
                            onClick={handleVideoTurnOnOff}
                            className={classes.switchButton}
                        >
                            <Box className={classes.switchButtonContent}>
                                {videoInputOn ? (
                                    <VideocamOutlined color="secondary" />
                                ) : (
                                    <VideocamOffOutlined color="disabled" />
                                )}
                                {t('camera')}
                            </Box>
                        </Button>
                    </Box>
                )}
                {!isDesktop && (
                    <CustomButton
                        onClick={approve}
                        variant="contained"
                        color="secondary"
                        style={{width: '100%', marginTop: 10}}
                    >
                        {inAppointment ? t('apply') : t('startAppointment')}
                    </CustomButton>
                )}
            </Grid>
            <Grid item className={classes.settingsWrapper}>
                {handleSelectVideoInput && (
                    <Box>
                        <InputLabel
                            id="cameraSelectLabel"
                            className={classes.label}
                        >
                            {t('camera')}
                        </InputLabel>
                        <CustomSelect
                            labelId="cameraSelectLabel"
                            value={selectedVideoInputDeviceId || ''}
                            onChange={handleSelectVideoInput}
                            className={classes.select}
                        >
                            {mapVideoDevices}
                        </CustomSelect>
                    </Box>
                )}

                {handleSelectAudioInput && (
                    <Box>
                        <InputLabel
                            id="microphoneSelectLabel"
                            className={classes.label}
                        >
                            {t('microphone')}
                        </InputLabel>
                        <CustomSelect
                            labelId="microphoneSelectLabel"
                            value={selectedAudioInputDeviceId || ''}
                            onChange={handleSelectAudioInput}
                            className={classes.select}
                        >
                            {mapAudioInputDevices}
                        </CustomSelect>
                    </Box>
                )}

                {handleSelectAudioOutput && (
                    <Box>
                        <InputLabel
                            id="speakerSelectLabel"
                            className={classes.label}
                        >
                            {t('speaker')}
                        </InputLabel>
                        <CustomSelect
                            labelId="speakerSelectLabel"
                            value={selectedAudioOutputDeviceId || ''}
                            onChange={handleSelectAudioOutput}
                            className={classes.select}
                        >
                            {mapAudioOutputDevices}
                        </CustomSelect>
                    </Box>
                )}

                <CustomButton
                    onClick={approve}
                    variant="contained"
                    color="secondary"
                >
                    {inAppointment ? t('apply') : t('startAppointment')}
                </CustomButton>
                {error && <Box className={classes.error}>{error}</Box>}
            </Grid>
        </Grid>
    );
};

export default Settings;
