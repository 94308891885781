import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import useStyles from './FooterStyles';
import useLandingPageUrl from '../../../hooks/useLandingPageUrl';
import {Container} from '../../index';
import ExternalLink from '../../common/ExternalLink/ExternalLink';
import {Path} from '../../../utils/landingPaths';

type MenuItem = {
    label: string;
    value: Path;
};

const menuItems: MenuItem[] = [
    {
        label: 'contact',
        value: 'CONTACT',
    },
    {
        label: 'organizationalRegulations',
        value: 'ORGANIZATIONAL_REGULATIONS',
    },
    {
        label: 'termsAndconditions',
        value: 'TERMS_AND_CONDITIONS',
    },
    {
        label: 'privacyPolicy',
        value: 'PRIVACY_POLICY',
    },
];

const Footer = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const {getRoute} = useLandingPageUrl();

    return (
        <footer>
            <Container
                className={classes.footer}
                mdDesktop
                disableVerticalPadding
            >
                <Box>
                    <Box className={classes.copyright}>© Global Teleclinic</Box>
                    <Box className={classes.medicalEntity}>
                        {t('medicalEntity')}
                    </Box>
                </Box>
                <Grid container className={classes.menu}>
                    {menuItems.map(item => (
                        <ExternalLink
                            to={getRoute(item.value)}
                            className={classes.link}
                            key={item.value}
                        >
                            {t(item.label)}
                        </ExternalLink>
                    ))}
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;
