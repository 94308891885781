import useStyles from './AdditionalDetailsEditableStyles';
import useStylesCommon from '../common/AdditionalDetailsCommonStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Box, Button, TextField} from '@material-ui/core';
import {Appointment} from '../../../../types/appointments';
import React, {useState} from 'react';
import Dropzone from '../Dropzone/Dropzone';
import {
    fetchAppointmentDetails,
    setAdditionalPatientDetails,
} from '../../../../store/appointmentDetailsSlice';
import {useAppDispatch} from '../../../../hooks/customReduxHooks';
import {Loading} from '../../../index';
import {isObject} from 'lodash-es';
import {refineString, stringValuesDiffers} from '../../../../utils/string';

interface Props {
    appointment: Appointment;
}

const AdditionalDetailsEditable = ({appointment}: Props) => {
    const [reasonsInputValue, setReasonsInputValue] = useState(
        appointment.patientReasons,
    );
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const classes = useStyles();
    const classesCommon = useStylesCommon();

    const saveChangesBtnDisabled =
        !isObject(appointment) ||
        !stringValuesDiffers(appointment.patientReasons, reasonsInputValue);

    const saveChangesHandler = async () => {
        if (
            stringValuesDiffers(appointment.patientReasons, reasonsInputValue)
        ) {
            setLoading(true);
            await dispatch(
                setAdditionalPatientDetails({
                    appointmentId: appointment.id,
                    patientReasons: refineString(reasonsInputValue),
                }),
            );
            await dispatch(
                fetchAppointmentDetails({
                    appointmentId: appointment.id,
                    fetchSilently: true,
                }),
            );
            setLoading(false);
        }
    };

    return (
        <>
            <h4 className={classesCommon.detailsHeader}>
                {t('whatAreTheReasons')}?
            </h4>
            <label
                className={classes.conditionsInputLabel}
                htmlFor="conditions-input"
            >
                {t('typeSymptoms')}:
            </label>
            <TextField
                id="conditions-input"
                variant="outlined"
                fullWidth
                defaultValue={
                    appointment.patientReasons ? appointment.patientReasons : ''
                }
                onChange={event => setReasonsInputValue(event.target.value)}
            />
            <Dropzone
                className={classes.dropzone}
                appointmentId={appointment.id}
            />
            <Box>
                <Button
                    className={classes.saveBtn}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={saveChangesHandler}
                    disabled={saveChangesBtnDisabled}
                >
                    {t('saveChanges').toUpperCase()}
                </Button>
            </Box>
            <Loading loading={loading} withBackdrop />
        </>
    );
};

export default AdditionalDetailsEditable;
