import {FC} from "react";
import {
    makeStyles,
    createStyles,
    TextField,
    TextFieldProps,
    Theme,
    useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            height: 48,
            backgroundColor: 'white',
        },
    }),
);

const CustomTextField: FC<TextFieldProps> = (props) => {
    const {
        fullWidth,
        className,
        ...otherProps
    } = props;

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const classes = useStyles();

    return (
        <TextField
            InputProps={{
                className: clsx(
                    [
                        classes.container,
                        className
                    ]
                ),
            }}
            variant="outlined"
            fullWidth={fullWidth || !isDesktop}
            {...otherProps}

        />
    );
};

export default CustomTextField;
