import {memo} from 'react';
import {Box, createStyles, List, makeStyles, Theme} from '@material-ui/core';
import {TEXT_SECONDARY, WHITE} from '../../../../const/colors';
import {useLaboratoryAddressesContext} from '../../../../hooks/diagnostic/laboratoryAddresses/useLaboratoryAddressesContext';
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 1,
            padding: 16,
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                marginTop: 0,
                padding: 24,
            }
        },
        laboratoryAddress: {
            fontSize: 18,
            lineHeight: '24px',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
                lineHeight: '32px',
            }
        },
        laboratoryNameAndContact: {
            fontSize: 16,
            lineHeight: '24px',
        },
        diagnosticServiceList: {
            display: 'list',
            marginTop: 16,
            listStyleType: 'disc',
            color: TEXT_SECONDARY,
            fontSize: 16,
            lineHeight: '24px',
            paddingLeft: 28,
        },
    }),
);

const LaboratoryDetails = memo(function MedicalSearchFilters() {
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);

    const {
        selectedLaboratoryAddress: {
            address: laboratoryAddress,
            city: laboratoryCity,
            laboratoryName,
            contactPhoneNumber,
            contactEmail,
        },
        diagnosticServicePrices,
    } = useLaboratoryAddressesContext();

    return (
        <Box className={classes.root}>
            <Box className={classes.laboratoryAddress}>
                {`${laboratoryAddress}, ${laboratoryCity}`}
            </Box>
            <Box className={classes.laboratoryNameAndContact}>
                {laboratoryName}
                <Box className="white-space-nowrap">
                    Tel:{' '}
                    {
                        contactPhoneNumber
                            ? contactPhoneNumber.replace(/(\+48)(\d*)/, '$1 $2')
                            : tCommon('noInformation')
                    },
                </Box>
                <Box
                    className="white-space-nowrap"
                    component={'span'}
                >
                    e-mail: {contactEmail || tCommon('noInformation')}
                </Box>
            </Box>
            <List className={classes.diagnosticServiceList}>
                {
                    diagnosticServicePrices.map((diagnosticServicePrice) => (
                        <li key={diagnosticServicePrice.diagnosticServiceId}>
                            {diagnosticServicePrice.diagnosticServiceName}
                        </li>
                    ))
                }
            </List>
        </Box>
    )
})

export default LaboratoryDetails;