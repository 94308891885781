import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import {useHistory} from 'react-router';
import {WHITE} from '../../../../const/colors';
import {Button} from '../../../index';
import MedicTags from '../MedicTags/MedicTags';
import React from 'react';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {
    selectAppointment,
    selectSelectedMedic,
    selectSelectedMedicalServiceId,
} from '../../../../store/referrals/makePatientReferralSlice';
import referralsProvider from '../../../../services/referralsProvider';
import {PATIENT_REFERRAL_SUCCESSFULLY_MADE_PAGE} from '../../../../const/routes';
import {isSuccessStatusCode} from '../../../../utils/http';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import MedicCardWithDetails from '../../../common/MedicCardWithDetails/MedicCardWithDetails';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            margin: '1px 0 0',
            padding: 30,
            borderRadius: 4,
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                margin: '24px 47px 24px 23px',
            },
        },
        button: {
            marginTop: 23,
            width: '100%',
        },
        medicTagsWrapper: {
            marginTop: 16,
        },
    }),
);

const MedicDetails = () => {
    const classes = useStyles();
    const history = useHistory();
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);
    const appointment = useAppSelector(selectAppointment);
    const selectedMedicalServiceId = useAppSelector(
        selectSelectedMedicalServiceId,
    );
    const selectedMedic = useAppSelector(selectSelectedMedic);

    const makeReferralButtonClickHandler = async () => {
        const response = await referralsProvider.createPatientReferral({
            toMedicalServiceId: selectedMedicalServiceId,
            ToMedicId: selectedMedic.id,
            sourceAppointmentId: appointment.id,
        });

        if (isSuccessStatusCode(response.status)) {
            history.replace({
                pathname: PATIENT_REFERRAL_SUCCESSFULLY_MADE_PAGE,
                search:
                    `?patientFirstName=${
                        appointment.child?.firstName ??
                        appointment.patient.firstName
                    }` +
                    `&patientSurname=${
                        appointment.child?.surname ??
                        appointment.patient.surname
                    }`,
            });
        }
    };

    return (
        <Box className={classes.container}>
            <MedicCardWithDetails
                header={`${selectedMedic.title} ${selectedMedic.firstName} ${selectedMedic.surname}`}
                subheader={selectedMedic.specialties.join(', ')}
                reviewRate={selectedMedic.reviewRate}
                reviewCount={selectedMedic.reviewCount}
                description={selectedMedic.bio}
                photoUrl={selectedMedic.photoUrl}
                noPadding
            />
            <Box className={classes.medicTagsWrapper}>
                <MedicTags />
            </Box>
            <Button
                onClick={makeReferralButtonClickHandler}
                className={classes.button}
                variant={'contained'}
                color={'secondary'}
            >
                {tReferrals('makeReferral').toUpperCase()}
            </Button>
        </Box>
    );
};

export default MedicDetails;
