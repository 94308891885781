import {Box, CircularProgress} from '@material-ui/core';
import {Save} from '@material-ui/icons';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import useStyles from './SaveIconButtonStyles';
import i18nNamespaces from '../../../../const/i18nNamespaces';

interface Props {
    disabled?: boolean;
    loading?: boolean;
    onClick: () => void;
    label?: string;
}

const SaveIconButton = ({disabled, loading, onClick, label}: Props) => {
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    return (
        <Box
            className={clsx(classes.container, {
                [classes.disabled]: disabled || loading,
            })}
            onClick={() => !disabled && onClick()}
        >
            <Save className={classes.saveIcon} />
            {label || tCommon('save')?.toUpperCase()}
            {loading && (
                <CircularProgress size={26} className={classes.progress} />
            )}
        </Box>
    );
};

export default SaveIconButton;
