import {Box, LinearProgress, Link} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {
    MEDIC_PROFILE_PAGE,
    MEDIC_SETTINGS,
    NEW_PRESCRIPTION_PAGE,
} from '../../../../const/routes';
import {useEffect} from 'react';
import PrescriptionsListItem from '../../../prescription/PrescriptionsListItem/PrescriptionsListItem';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';
import {
    fetchPrescriptions,
    selectPrescriptions,
    selectPrescriptionsStatus,
} from '../../../../store/prescriptionSlice';
import useStyles from './PrescriptionsListStyles';
import useCertificateThumbprint from '../../../../hooks/certificate/useCertificateThumbprint';
import {selectAuthUserData} from '../../../../store/auth';
import {Loading} from '../../../index';
import {useHistory} from 'react-router';

type PrescriptionListProps = {
    appointmentId: number;
};

const PrescriptionsList = ({appointmentId}: PrescriptionListProps) => {
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectPrescriptionsStatus);
    const prescriptions = useAppSelector(selectPrescriptions);
    const classes = useStyles();
    const userData = useAppSelector(selectAuthUserData);
    const history = useHistory();
    const {loading: thumbprintLoading, thumbprint} = useCertificateThumbprint(
        userData?.userId,
    );

    const getPrescriptions = () =>
        dispatch(fetchPrescriptions({appointmentId}));

    useEffect(() => {
        getPrescriptions();

        // Fetch prescriptions on tab focus
        window.addEventListener('focus', getPrescriptions);

        return () => {
            window.removeEventListener('focus', getPrescriptions);
        };
    }, []);

    const handleClickIssuePrescription = () => {
        // Open page in new tab
        const win = window.open(
            `${NEW_PRESCRIPTION_PAGE}/${appointmentId}`,
            '_blank',
        );
        win.focus();
    };

    return (
        <>
            <h4 className={classes.header}>{t('prescriptions')}</h4>
            <Loading loading={thumbprintLoading} withBackdrop />
            {status === 'loading' && <LinearProgress color={'secondary'} />}
            <Box className={classes.prescriptionsList}>
                {prescriptions?.map((prescriptionSet, idx) => (
                    <PrescriptionsListItem
                        index={idx}
                        key={idx}
                        prescriptionSet={prescriptionSet}
                    />
                ))}
            </Box>
            {thumbprint ? (
                <Link
                    color={'secondary'}
                    style={{cursor: 'pointer'}}
                    onClick={handleClickIssuePrescription}
                >
                    {t('issueAPrescription')}
                </Link>
            ) : (
                <Link
                    color={'secondary'}
                    className={classes.loadCertificateLink}
                    onClick={() =>
                        history.push(MEDIC_SETTINGS + MEDIC_PROFILE_PAGE)
                    }
                >
                    {t('loadCertificate')}
                </Link>
            )}
        </>
    );
};

export default PrescriptionsList;
