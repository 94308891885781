import {createStyles, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            marginLeft: 30,
            marginRight: 30,
        },
    }),
);

export default useStyles;
