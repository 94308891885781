
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { GRAY } from '../../../const/colors';

const textsCommonStyles = {
    size: 16,
    lineHeight: '24px',
}

const useStyles = makeStyles((theme:Theme) =>
    createStyles({
        card:{
            padding: theme.breakpoints.up('sm') ? '24px 0' : 16,
                borderBottom: `1px solid ${theme.palette.grey['300']}`,
                boxShadow: 'none',
                marginLeft: `${theme.spacing(3)}px`,
                marginRight: `${theme.spacing(3)}px`,
        },
        photo: {
            width: '70px',
            height: '70px',
        },
        type: {
            ...textsCommonStyles,
            fontWeight: 'bold',
        },
        laboratoryAddress: {
            ...textsCommonStyles,
        },
        patientName: {
            ...textsCommonStyles,
        },
        status:{
            ...textsCommonStyles,
            color:GRAY
        }
    }),
    );

export default useStyles;
