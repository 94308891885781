import React from 'react';
import {Modal, Box, ModalProps, IconButton} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import useStyles from './CustomModalStyles';

interface CustomModalProps extends ModalProps {
    onCloseButtonClick?: () => void;
}

const CustomModal = ({onCloseButtonClick, ...props}: CustomModalProps) => {
    const classes = useStyles();
    return (
        <Modal {...props}>
            <Box className={classes.container}>
                {onCloseButtonClick && (
                    <IconButton
                        className={classes.closeButton}
                        onClick={onCloseButtonClick}
                    >
                        <Close />
                    </IconButton>
                )}
                {props.children}
            </Box>
        </Modal>
    );
};

export default CustomModal;
