import {http, rawHttp} from '../../services/http';
import {
    getOccupationalMedicineDataEndpoint,
    getOccupationalMedicineStateEndpoint,
} from './appointmentsEndpoints';
import {OccupationalMedicineData} from '../../types/appointments';

class AppointmentsApi {
    getOccupationalMedicineState(appointmentId: string) {
        return rawHttp.get<{filledByPatient: boolean}>(
            getOccupationalMedicineStateEndpoint(appointmentId),
        );
    }

    getOccupationalMedicineData(appointmentId: string) {
        return rawHttp.get<OccupationalMedicineData>(
            getOccupationalMedicineDataEndpoint(appointmentId),
        );
    }
}

export const appointmentsApi = new AppointmentsApi();
