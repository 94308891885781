import {makeStyles, Theme} from "@material-ui/core";
import {MAGENTA, WHITE} from "../../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    goBackWrapper: {
        height: 81,
        marginTop: '2px',
        padding: `0 ${theme.spacing(1)}px`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: WHITE,
        [theme.breakpoints.up('md')]: {
            marginLeft: `${theme.spacing(3)}px`,
            padding: '0',
        },
    },
    appointmentDetailsWrapper: {
        marginTop: '8px',
    },
    actionButtons: {
        marginTop: '2px',
        padding: '16px',
        backgroundColor: WHITE,
    },
    actionButton: {
        width: '100%',
        lineHeight: 1.35,
    },
    actionButtonPrimary: {
        color: MAGENTA,
        border: `1px solid ${MAGENTA}`,
        marginBottom: '16px',
    },
}));

export default useStyles;