import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: 1000,
            paddingTop: 15,
        },
        buttonRow: {marginTop: 20},
        input: {marginTop: 10},
        error: {
            marginTop: 10,
            color: 'red',
        },
        [theme.breakpoints.up('sm')]: {
            buttonRow: {marginTop: 24, justifyContent: 'flex-end'},
            addButton: {width: 300},
        },
    }),
);

export default useStyles;
