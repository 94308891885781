import {VIDEO_TOKEN, CHAT_TOKEN, JOIN_CHAT, CALL_TOKEN} from './endpoints';
import {rawHttp} from './http';

class ConversationService {
    getAudioInputDevices = () => navigator.mediaDevices.enumerateDevices();

    getVideoToken(consultationId: string) {
        if (consultationId === 'test_token') {
            return rawHttp.get(
                `api/appointments/${consultationId}/video-chat-token-test`,
            );
        } // temporary solution
        return rawHttp.get(VIDEO_TOKEN(consultationId));
    }

    getChatToken(consultationId: string) {
        if (consultationId === 'test_token') {
            return rawHttp.get(
                `api/appointments/${consultationId}/chat-token-test`,
            );
        } // temporary solution
        return rawHttp.get(CHAT_TOKEN(consultationId));
    }

    postChatConversation(consultationId: string) {
        if (consultationId === 'test_token') {
            return rawHttp.post(`api/appointments/${consultationId}/chat-test`);
        } // temporary solution
        return rawHttp.post(JOIN_CHAT(consultationId));
    }

    getCallToken(consultationId: string) {
        if (consultationId === 'test_token') {
            return rawHttp.get(
                `api/appointments/${consultationId}/call-token-test`,
            );
        } // temporary solution
        return rawHttp.get(CALL_TOKEN(consultationId));
    }

    getCallStatus(callSid: string) {
        return rawHttp.get(`/api/appointments/calls/`+callSid);
    }
}

export default new ConversationService();
