import {Backdrop, CircularProgress} from '@material-ui/core';
import React from 'react';
import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

type Props = {
    loading?: boolean;
    size?: number;
    withBackdrop?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.secondary.main,
    },
}));

const Loading = ({loading, size, withBackdrop}: Props) => {
    const classes = useStyles();
    return withBackdrop ? (
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    ) : (
        loading && <CircularProgress color="secondary" size={size} />
    );
};

export default Loading;
