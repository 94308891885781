import {rawHttp} from "./http";
import {ICD10, ICD10_SIMPLE} from "./endpoints";
import {ICD10Code} from "../types/icd10";

interface ICD10CodeDto {
    code: string;
    textPl: string;
    textEn: string;
}

const icd10CodesMapper = (icd10CodeDtosAsJson: string): ICD10Code[] => {
    const icd10CodeDtos = JSON.parse(icd10CodeDtosAsJson) as ICD10CodeDto[];
    return icd10CodeDtos.map((icd10CodeDto) => {
        const {code, textPl, textEn} = icd10CodeDto;
        return {
            code,
            text: {
                pl: textPl,
                en: textEn
            }
        }
    });
}

export const getIcd10Codes = (query: string) =>
    rawHttp.get<ICD10Code[]>(
        ICD10,
        {
            params: {query},
            transformResponse: [icd10CodesMapper]
        }
    );

export const getSimpleIcd10Codes = (query: string) =>
    rawHttp.get<ICD10Code[]>(
        ICD10_SIMPLE,
        {
            params: {query},
            transformResponse: [icd10CodesMapper]
        }
    );