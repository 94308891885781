import {useEffect} from 'react';
import ConversationProvider from '../services/ConversationProvider';
import {Conversation} from '@twilio/conversations/lib/conversation';
import {consultationErrorHandler} from '../utils/error';
import Client from '@twilio/conversations';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../const/i18nNamespaces';
import {AxiosError} from 'axios';

const useChatConversation = (
    client: Client,
    setLoading: (loading: boolean) => void,
    setError: (error: string) => void,
    consultationId: string,
    setConversation: (conversation: Conversation) => void,
): string | null => {
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);

    useEffect(() => {
        if (client) {
            setLoading(true);
            ConversationProvider.postChatConversation(consultationId)
                .then(result => {
                    client
                        .getConversationBySid(result.data)
                        .then((currentConversation: Conversation) => {
                            setConversation(currentConversation);
                        });
                })
                .catch(gotApiError)
                .finally(() => {
                    setLoading(false);
                });
        }

        return () => {
            if (client) client.removeAllListeners();
        };
    }, [client, consultationId]);

    const gotApiError = (error: AxiosError) => {
        setError(t(consultationErrorHandler(error.response.data[0])));
    };

    return null;
};

export default useChatConversation;
