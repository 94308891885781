import {memo} from 'react';
import {createStyles, List, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            marginTop: 7,
        }
    }),
);

interface Props {
    children: JSX.Element | JSX.Element[];
}

const TileList = memo(function TileList({children}: Props) {
    const classes = useStyles();
    return (
        <List className={classes.root} disablePadding={true}>
            {children}
        </List>
    )
});

export default TileList;
