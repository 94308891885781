import {
    Box,
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    SvgIcon,
} from '@material-ui/core';
import {ChangeEvent, ReactNode, useState} from 'react';
import {formatPrice} from '../../../../utils/price';
import useStyles from './AppointmentPatientPurchaseOptionWrapperStyles';

type AppointmentPatientPurchaseOptionWrapperProps = {
    children: ReactNode;
    id: string;
    price?: number;
    disable?: boolean;
};

const AppointmentPatientPurchaseOptionWrapper = ({
    children,
    id,
    price,
    disable,
}: AppointmentPatientPurchaseOptionWrapperProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Divider className={classes.divider} />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className={classes.formControlLabelContainer}
            >
                <FormControlLabel
                    value={id}
                    control={
                        <Radio className={classes.radio} disabled={disable} />
                    }
                    label={children}
                    className={classes.formControlLabel}
                    disabled={disable}
                />
                {!disable && (
                    <Box className={classes.price}>{formatPrice(price)} zł</Box>
                )}
            </Grid>
        </Box>
    );
};

export default AppointmentPatientPurchaseOptionWrapper;
