import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {isArray} from 'lodash-es';
import {
    deletePatientReferralForMedicalTests,
    getPatientReferralsForMedicalTests,
    PatientDiagnosticOrderHeadersDto
} from '../../../services/diagnostic/orderHeadersProvider';
import referralsProvider from '../../../services/referralsProvider';
import filesDownloadProvider from '../../../services/filesDownloadProvider';
import axios from 'axios';

export interface ReferralsForMedicalTestsState {
    readonly referrals: PatientDiagnosticOrderHeadersDto[];
    readonly loading: boolean;
    readonly error: string | null;
    readonly downloadError: string | null;
    readonly fetchReferrals: () => void;
    readonly deleteReferral: (referralId: string) => void;
    readonly downloadPdf: (referralId: string) => void;
    readonly cleanError: () => void;
}

const useReferralsForMedicalTests = () => {
    // Fetch
    const [referrals, setReferrals] = useState<PatientDiagnosticOrderHeadersDto[]>([]);
    const [referralsLoading, setReferralsLoading] = useState(false);
    const [referralsError, setReferralsError] = useState<string>(null);

    // Delete
    const [deleteReferralLoading, setDeleteReferralLoading] = useState(false);
    const [deleteReferralError, setDeleteReferralsError] = useState<string>(null);
    const [downloadError, setDownloadError] = useState<string>(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);

    const fetchReferrals = useCallback(async () => {
        setReferralsLoading(true);
        try {
            const response = await getPatientReferralsForMedicalTests();
            if (!isArray(response.data)) {
                throw new Error();
            }
            setReferrals(response.data);
            setReferralsError(null);
        } catch {
            setReferrals([]);
            setReferralsError(tErrors('fetchingDataFromServerError'));
        } finally {
            setReferralsLoading(false);
        }
    }, []);

    const deleteReferral = useCallback(async (referralId: string) => {
        try {
            setDeleteReferralLoading(true);
            await deletePatientReferralForMedicalTests(referralId);
            // fetchReferrals has it's own exception handling, don't need to await
            fetchReferrals();
        } catch {
            setDeleteReferralsError(tErrors('sendingDataToServerError'));
        } finally {
            setDeleteReferralLoading(false);
        }
    }, [fetchReferrals]);

    const downloadPdf = async (id:string) =>{
        try{
            setReferralsLoading(true);
            const url = (await referralsProvider.getDiagnosticOrderReferralPdfUrl(id)).data;
            if(url!==null){
                filesDownloadProvider.downloadFileFromUrl(
                    url,
                    tCommon('referral')+".pdf"
                );
            }
        }
        catch(error){
            if (axios.isAxiosError(error) &&
                error.response.status === 404)
                setDownloadError(tCommon('fileNotGenerated'));
        
            else
                setReferralsError(tErrors('downloadReferralError'))
            
        } finally {
            setReferralsLoading(false);
        }
    }

    const cleanError = () =>{
        setDownloadError(null);
    }

    const state = useMemo<ReferralsForMedicalTestsState>(
        () => ({
            fetchReferrals,
            referrals,
            deleteReferral,
            downloadPdf,
            cleanError,
            // common
            loading: referralsLoading || deleteReferralLoading,
            error: referralsError || deleteReferralError,
            downloadError: downloadError
        }),
        [
            // referrals fetch
            fetchReferrals,
            referrals,
            referralsLoading,
            referralsError,
            downloadError,
            // referral delete
            deleteReferral,
            downloadPdf,
            deleteReferralLoading,
            deleteReferralError,
        ]
    );

    return state;
}

export default useReferralsForMedicalTests;