import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './IndividualPackageStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

interface Props {
    id: number;
    title: string;
    image: string;
    description: string;
    onClick?: (id: number) => void;
    selected?: boolean;
    withMoreButton?: boolean;
}

const IndividualPackage = ({
    id,
    title,
    image,
    description,
    onClick,
    selected,
    withMoreButton,
}: Props) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.container}
        >
            <Box
                className={clsx(classes.wrapper, {
                    [classes.selected]: selected,
                })}
                onClick={() => onClick && onClick(id)}
            >
                <Box className={classes.title}>{title}</Box>
                <img src={image} alt="" className={classes.image} />
                <Box className={classes.description}>{description}</Box>
                {withMoreButton &&
                    (isDesktop ? (
                        <Box className={classes.moreButton}>{t('seeMore')}</Box>
                    ) : (
                        <Box
                            className={classes.moreButton}
                            style={{
                                color: selected && 'white',
                                textDecoration: selected && 'underline',
                            }}
                        >
                            {selected
                                ? t('collapseDetails')
                                : t('expandDetails')}
                        </Box>
                    ))}
            </Box>
            {selected && isDesktop && <Box className={classes.arrowDown} />}
        </Grid>
    );
};

export default IndividualPackage;
