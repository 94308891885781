import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {desktop} from '../../../const/sizes';
import clsx from 'clsx';
import {ReactNode} from 'react';

type FormGroupProps = {
    children: ReactNode;
    className?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        [theme.breakpoints.up('sm')]: {
            formGroup: {
                width: 'auto',
                marginRight: desktop.contentSmallerSpacing,
            },
        },
    }),
);

const FormGroup = ({children, className}: FormGroupProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={clsx(classes.formGroup, className)}
        >
            {children}
        </Grid>
    );
};

export default FormGroup;
