import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import {ExtendedScopeOfTestingDto, ExtendedScopeOfTestingItemDto} from "../../../types/occupationalMedicine";
import { MutableRefObject, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Button, Grid, Link } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import useStyles from "./ExtendedScopeOfTestingComponentStyles";
import { useParams } from "react-router";
import { fetchSection13Data } from "../../../store/occupationalMedicineSlice";
import ExtendedScopeOfTestingItem from "./ExtendedScopeOfTestingItem";
import ExtendedScopeOfTestingCreateItem from "./ExtendedScopeOfTestingCreateItem";

const SECTION = 13;

export type Props = {
    currentExpanded:number
    data:ExtendedScopeOfTestingDto;
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void
    onReturn: () => void;
    refSection:MutableRefObject<any>
}

const ExtendedScopeOfTestingComponent = ({currentExpanded, data, onSubmitSection, refSection, onReturn}:Props) => {

    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const classes = useOccupationalMedicineStyles();
    const customClasses = useStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isAddOrEditMode, setIsAddOrEditMode] = useState<boolean>(false);
    const [dataToEdit, setDataToEdit] = useState<ExtendedScopeOfTestingItemDto>(null);
    const [indexToEdit, setIndexToEdit] = useState<number>(null);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);

    const onSubmit = (newItem: ExtendedScopeOfTestingItemDto, stayOnCurrent=false) => 
        {
            if (newItem===null){
                if(data===null)
                    onSubmitSection(JSON.stringify({items: []}), fetchSection13Data({id:appointmentId}), SECTION, stayOnCurrent);   
                else
                    onSubmitSection(JSON.stringify(data), fetchSection13Data({id:appointmentId}), SECTION, stayOnCurrent);   
            }

            //if editing
            else if (isNotNullOrUndefined(indexToEdit)){
                const updatedItems = [...data.items.slice(0, indexToEdit), ... data.items.slice(indexToEdit+1)]
                const resultItems = [...updatedItems, newItem];
                onSubmitSection(JSON.stringify({items: resultItems}),fetchSection13Data({id:appointmentId}), SECTION,stayOnCurrent);
            }
            //if new data
            else{
                if (data!== null){
                    const updatedItems= [
                        ...data.items,
                        newItem,  
                    ];
                    onSubmitSection(JSON.stringify({items: updatedItems}), fetchSection13Data({id:appointmentId}), SECTION,stayOnCurrent);
                }
                else{
                    const item = [newItem];
                    onSubmitSection(JSON.stringify({items: item}), fetchSection13Data({id:appointmentId}), SECTION,stayOnCurrent);              
                }
            }
            setDataToEdit(null);
            setIndexToEdit(null);
            setIsAddOrEditMode(false);
        }
        
        const onCancel = () => {
            setIsAddOrEditMode(false);
            setDataToEdit(null);
            setIndexToEdit(null);
        }
    
        const onDeletePosition = (index:number) =>{
            const updatedItems = [...data.items.slice(0, index), ... data.items.slice(index+1)]
            onSubmitSection(JSON.stringify({items: updatedItems}),fetchSection13Data({id:appointmentId}), SECTION,true);
        }
    
        const onEditPosition = (index: number, data:ExtendedScopeOfTestingItemDto) => {
            setIndexToEdit(index);
            setDataToEdit(data);
            setIsAddOrEditMode(true);
        }


    return ( 
    
        <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >

        <Box className={classes.titleContainer}>
            <h4 className={classes.sectionHeader}>
                {t('extendedScopeOftesting')}
            </h4>

            {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
        </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.detailsContainer}>
        {!isAddOrEditMode &&
            <Grid container spacing={1}>
                {
                    data && data.items.map((v, i) => (
                        <Grid item xs={6} key={i}>
                            <ExtendedScopeOfTestingItem 
                                index={i}
                                data={v}
                                onDelete={onDeletePosition}
                                onEdit={onEditPosition}/>
                        </Grid>
                    ))
                }
                <Grid item xs={12}>
                    <Link                                                    
                        className={customClasses.link}
                        onClick={() => {setIsAddOrEditMode(true)}}
                        >
                         {t('addPosition')}
                           
                    </Link>  
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <Button
                            onClick={onReturn}
                            className={classes.buttonReturn}
                            variant={'outlined'}
                            color={'secondary'}>
                            {t('return')}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => {onSubmit(null, false)}}
                            className={classes.buttonNext}
                            variant={'contained'}
                            color={'secondary'}>
                            {t('next')}
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        }             
        {isAddOrEditMode && <ExtendedScopeOfTestingCreateItem 
            data={dataToEdit} 
            onSubmitSection={onSubmit}
            onCancel={onCancel}/>}
    </AccordionDetails>
</Accordion>

)
};

export default ExtendedScopeOfTestingComponent;