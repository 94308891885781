import {EVALUATION, EVALUATION_FORM} from './endpoints';
import {http} from './http';
import {EvaluationDto} from '../types/evaluation';

class evaluationProvider {
    async getEvaluationForm(appointmentId: string) {
        return (await http.get<EvaluationDto>(EVALUATION_FORM(appointmentId)))
            .data;
    }

    async sendEvaluation(evaluationData: EvaluationDto, appointmentId: string) {
        return (
            await http.post<EvaluationDto>(
                EVALUATION(appointmentId),
                evaluationData,
            )
        ).data;
    }
}
export default new evaluationProvider();
