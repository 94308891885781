import {Box, TextField, Typography} from '@material-ui/core';
import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {Button, Loading} from '../../components';
import {EMAIL_VERIFICATION_PAGE} from '../../const/routes';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    selectAuthStatus,
    selectAuthUserData,
    selectPhoneNumberConfirmed,
    verifySMSCode,
} from '../../store/auth';
import useStyles from './PhoneVerificationPageStyles';
import AccountProvider from '../../services/accountProvider';
import useQuery from '../../hooks/useQuery';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {PHONE_NUMBER_CONFIRMATION} from '../../const/auth';
type LocationState = {
    email: string;
};

const PhoneVerificationPage = () => {
    const {t} = useTranslation(i18nNamespaces.SETTINGS);
    const dispatch = useAppDispatch();
    const authUserData = useAppSelector(selectAuthUserData);
    const status = useAppSelector(selectAuthStatus);
    const [error, setError] = useState<string>('');
    const location = useLocation<LocationState>();
    const history = useHistory();
    const [code, setCode] = useState<string>('');
    const query = useQuery();
    const returnUrl = query.get('returnUrl');
    const encodedReturnUrl = '?returnUrl=' + encodeURIComponent(returnUrl);

    useEffect(() => {
        const {email} = location.state;
        if (authUserData?.role !== PHONE_NUMBER_CONFIRMATION) {
            if (returnUrl && returnUrl !== 'null') {
                history.push({
                    pathname: EMAIL_VERIFICATION_PAGE,
                    state: {email},
                    search: encodedReturnUrl,
                });
            } else {
                history.push(EMAIL_VERIFICATION_PAGE, {email});
            }
        }
    }, [authUserData, history, location, returnUrl, encodedReturnUrl]);

    useEffect(() => {
        if (status === 'failed') setError(t('invalidCodeTryAgain'));
    }, [status]);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const {email} = location.state;
        setError('');
        dispatch(verifySMSCode({email, code}));
    };

    const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
        setError('');
    };

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Loading loading={status === 'loading'} withBackdrop />
            <Box
                py={3}
                justifyContent="center"
                alignItems="center"
                display={'flex'}
                flexDirection="column"
            >
                <Typography variant={'h3'}>
                    {t('phoneNumberVerification')}
                </Typography>
                <Box component="span" pt={2} pb={4}>
                    {t('smsWithVerificationCodeWasSend')}
                </Box>
                <form onSubmit={onSubmit}>
                    <Box mb={2}>
                        <TextField
                            className={classes.input}
                            label={t('enterCodeFromSMS')}
                            type="text"
                            id="secondName"
                            variant="outlined"
                            value={code}
                            onChange={onCodeChange}
                            autoComplete="off"
                            error={!!error}
                            helperText={error && error}
                            autoFocus
                        />
                    </Box>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        className={classes.button}
                        disabled={!code}
                    >
                        {t('next')}
                    </Button>
                </form>
            </Box>
        </div>
    );
};

export default PhoneVerificationPage;
