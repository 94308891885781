import {
    EmptyTopBar,
    Loading,
    SelectButtons,
    TwoColumnLayout,
} from '../../components';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {Box, Button, Grid, Snackbar, useMediaQuery, useTheme} from '@material-ui/core';
import useStyles from './NewSickLeavePageStyles';
import React, {useEffect, useState} from 'react';

import {useHistory, useParams} from 'react-router';

import {SelectButtonOption} from '../../components/common/SelectButtons/SelectButtons';
import {
    AttachFileOutlined,
    CreateOutlined,
    HistoryOutlined,
    LocalHospitalOutlined,
    PersonOutlined,
} from '@material-ui/icons';
import NewSickLeaveForm from '../../components/sickLeave/NewSickleaveForm/NewSickLeaveForm';
import { AppointmentDetailsForPrescriptionDto } from '../../types/appointments';
import AppointmentsProvider from '../../services/appointmentsProvider';
import { AZLADto, ValidationResultDto, ZLADto, ZLAHistoryDto, ZUSZLADto } from '../../types/sickLeave';
import kedZLAApi from '../../api/zusZLA/kedZLAApi';
import NewSickLeaveDraft from '../../components/sickLeave/NewSickLeaveDraft/NewSickLeaveDraft';
import SickLeaveHistory from '../../components/sickLeave/SickLeaveHistory/SickLeaveHistory';
import CancelZLAForm from '../../components/sickLeave/CanelZLAForm/CancelZlaForm';
import { CustomAlert } from '../../components/common/feedback';
import { Alert } from '@material-ui/lab';
import sessionApi from '../../api/zusZLA/sessionApi';
import { SICK_LEAVE_CANCELLED_PAGE, SICK_LEAVE_ISSUED_PAGE } from '../../const/routes';

const tabs: SelectButtonOption[] = [
    {
        value: 'SICK_LEAVE',
        label: 'sickLeave',
        icon: <LocalHospitalOutlined />,
    },
    {
        value: 'SICK_LEAVE_HISTORY',
        label: 'sickLeaveHistory',
        icon: <HistoryOutlined />,
    },
];

const NewSickLeavePage = () => {
    const {t} = useTranslation(i18nNamespaces.L4);
    const classes = useStyles();
    const params = useParams<{appointmentId: string}>();
    const appointmentId = parseInt(params?.appointmentId);

    const [loading, setLoading] = useState<boolean>(false);
    const [appointmentDetails, setAppointmentDetails] =
        useState<AppointmentDetailsForPrescriptionDto | null>(null);
    const [draft, setDraft] = useState<ZUSZLADto>(null);
    const [draftToPost, setDraftToPost] = useState<ZLADto>(null);
    const [selectedTab, setSelectedTab] = useState<SelectButtonOption>(tabs[0]);
    const [zlaToCancel, setZlaToCancel] = useState<ZLAHistoryDto>(null);
    const [error , setError] = useState<string>(null);
    const [validation, setValidation] = useState<ValidationResultDto>(null);
    const history = useHistory();
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const tabsWithTranslations = tabs?.map(tab => ({
        ...tab,
        label: t(tab.label),
    }));

    const childTitle = appointmentDetails!==null && appointmentDetails.child!==null ?
         `${t('sickLeaveForTakingCare')} ${appointmentDetails?.child.firstName} ${appointmentDetails?.child.surname}`: "";
        
    const topbarTitle = !loading && `${t('newSickLeaveFor')} ${
        appointmentDetails?.patient.firstName
    } ${appointmentDetails?.patient.surname} ${childTitle}` ;

    const topbatMobileTitle = selectedTab.value==="SICK_LEAVE"
        ? `${t('newSickLeaveFor')} ${
            appointmentDetails?.patient.firstName
        } ${appointmentDetails?.patient.surname} ${childTitle}`  
        : `${t('historyOfSickLeavesFor')} ${appointmentDetails?.patient.firstName} ${appointmentDetails?.patient.surname}`

    const logIn = async () =>{
        try {
            const {data} = await sessionApi.LogIn();
            if(!data.isSuccess)
                setError(`${t('logInError')} ${data.message}`);
        } catch (error) {
            setError(t('logInError'));
        } finally {
        }
    };

    const logOut = async () =>{
        try {
            const {data} = await sessionApi.LogOut();
        
        } catch (error) {
            console.error(t('logOutError'));
        } finally {
        }
    };

    const fetchAppointmentDetails = async () =>{
        try {
            
            const {data} =
                await AppointmentsProvider.getAppointmentDetailsForPrescription(
                    appointmentId.toString(),
                );
            setAppointmentDetails(data);
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            await logIn();
            await fetchAppointmentDetails();
            setLoading(false);
        })();
        // return () =>{
        //     logOut();
        // }
    }, []);

    const handleSubmitSickLeaveForm = async (draft: ZLADto) => {  
        try{
            setLoading(true);
            setDraftToPost(draft);
            const {data} =  await kedZLAApi.validateDraft(draft);
            
            if(!data.result.validationResult.isValid){
                const errorMessage = `${t('zlaNotValid')} 
                    ${data.result.validationResult.validationDetails.map((l) => `${l.code}: ${l.description}  (${l.localization})`).join(". ")}` 
                setError(errorMessage);
                setValidation(data.result.validationResult);
            }
            
            setDraft(data.result.zlaDraft);
            setLoading(true);
        } catch (error) {
            setError(t('zlaValidationErrorOccured'))
            console.error(error);
        } finally {
            setLoading(false);
        }
   };

   const handleOnDraftDelete = () =>{
        setDraft(null);
        setDraftToPost(null);
   }

   const handleOnZlaCancel = (zla:ZLAHistoryDto) =>{
        setZlaToCancel(zla);
   }

   const handleOnUndoCancel = () =>{
        setZlaToCancel(null);
   }

   const closeAlert = () =>{
        setError(null);
   }
   const handleOnSignAndCancel = async (seriesAndNumber: string, zla: AZLADto) =>{
        try{
            setLoading(true);
            const {data} = await kedZLAApi.checkIfCancelIsPossible(seriesAndNumber);
            if(data.result.isPossible){
                const {data} = await kedZLAApi.cancelZLA(seriesAndNumber, zla);
                if(data.isSuccess)
                {
                    logOut();
                    history.push(SICK_LEAVE_CANCELLED_PAGE, {appointmentId});
                }
                
                else{
                    setError(`${t("zlaCancelErrorOccured")} ${data.message}`);
                }
            }
            else{
                setError(t("cancelIsNotPossible"));
            }
        }
        catch{
            setError(t("zlaCancelErrorOccured"));
        }
        finally{
            setLoading(false);
        }
   }


   const handleOnSignAndCreate = async () =>{
        try{
            setLoading(true);
            const {data} =  await kedZLAApi.generateZLA(draftToPost);

            if (data?.isSuccess)
            {
                logOut();
                history.push(SICK_LEAVE_ISSUED_PAGE, {appointmentId});
            }
            else{
                setError(`${t('zlaGenerateErrorOccured')} ${data.message}`);
            }
        } catch (error) {
            setError(t('zlaGenerateErrorOccured'))
        } finally {
            setLoading(false);
        }
   }
   
   const renderLeftTab = (tab: SelectButtonOption) => {
    switch (tab.value) {
        case 'SICK_LEAVE':
            return (
                <>
                    {appointmentDetails && 
                        (<NewSickLeaveForm
                            onSubmit={handleSubmitSickLeaveForm}
                            appointment={appointmentDetails}
                            disabled={draft!==null}
                        />)}
                </>
            );
        case 'SICK_LEAVE_HISTORY':
            return (
                <SickLeaveHistory handleOnCancel={handleOnZlaCancel} 
                    disabled={zlaToCancel!==null}
                    isuredPesel={appointmentDetails.patient.pesel}
                    setError={setError}
                    />
            );
        }
    };

    const renderRightTab= (tab: SelectButtonOption) => {
        switch (tab.value) {
            case 'SICK_LEAVE':
                return (
                    <>
                       {draft !== null && (
                        <Box className={classes.card}>
                            <NewSickLeaveDraft 
                                draft={draft} 
                                onDelete={handleOnDraftDelete}
                                disable={validation && validation.isValid===false}
                                handleOnSignAndCreate={handleOnSignAndCreate}
                                />      
                        </Box>
                    )} 
                    </>
                );
            case 'SICK_LEAVE_HISTORY':
                return (
                    <>
                        {zlaToCancel &&
                            (<Box className={classes.card}>
                                {<CancelZLAForm 
                                    zlaToCancel={zlaToCancel}
                                    handleOnDelete={handleOnUndoCancel}
                                    onSubmit={handleOnSignAndCancel}
                                    appointment={appointmentDetails}/>}
                            </Box>)} 
                    </>
                );
            }
    };

    return (
        isMobile ? (
            <Grid >
                {!loading &&
                    <Grid item className={classes.titleMobile}>
                        {topbatMobileTitle}
                </Grid>}
                {(draft===null && zlaToCancel === null) &&
                    <Grid item className={classes.buttonSelectMobile}>
                        <SelectButtons
                            value={selectedTab}
                            options={tabsWithTranslations}
                            onChange={setSelectedTab}
                            containerStyles={classes.tabsContainer}
                            buttonStyles={classes.selectButton}
                        />
                    </Grid>
                }
               {!loading && draft===null && zlaToCancel === null && (
                    <Grid item className={classes.containerMobile}>
                        {renderLeftTab(selectedTab)}
                    </Grid>
               )} 
               {!loading && (draft !==null || zlaToCancel !==null) && (
                    <Grid item >
                        {renderRightTab(selectedTab)}
                    </Grid>
               )}
            </Grid>
        ) : (
            <TwoColumnLayout>
                <Snackbar
                    open={!!error}
                >
                    <Alert 
                        onClose={closeAlert} 
                        severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                
                <Loading withBackdrop loading={loading} />
                <TwoColumnLayout.Header>
                    <EmptyTopBar title={topbarTitle} />
                </TwoColumnLayout.Header>
                <TwoColumnLayout.Main>
                    <TwoColumnLayout.LeftColumn>
                            <Box className={classes.formContainer}>
                                    <SelectButtons
                                    value={selectedTab}
                                    options={tabsWithTranslations}
                                    onChange={setSelectedTab}
                                    containerStyles={classes.tabsContainer}
                                    buttonStyles={classes.selectButton}
                                />
                                {renderLeftTab(selectedTab)}                       
                            </Box>                  
                        
                    </TwoColumnLayout.LeftColumn>
                    <TwoColumnLayout.RightColumn>
                        {renderRightTab(selectedTab)}        
                    </TwoColumnLayout.RightColumn>
                </TwoColumnLayout.Main>
            </TwoColumnLayout>
        )
    );
};
export default NewSickLeavePage;
