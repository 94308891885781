import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {BACKGROUND_SECONDARY} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container:{
            paddingBottom:56
        },
        title: {marginTop: 32, fontSize: 36, paddingLeft: 16, paddingRight: 16},
        subscriptionsDescription: {
            maxWidth: 820,
            marginTop: 56,
            marginBottom: 80,
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16,
        },
        underTableCaption: {
            marginTop: 24,
            marginBottom: 16,
        },
        tableCaption: {
            borderTop: `8px solid ${BACKGROUND_SECONDARY}`,
            padding: 16,
            marginTop:36
        },
        [theme.breakpoints.up('sm')]: {
            title: {
                fontSize: 56,
            },
            subscriptionsDescription: {
                fontSize: 20,
            },
            tableCaption: {
                borderTop: 'none',
                padding: 0,
            },
        },
    }),
);

export default useStyles;
