import {useEffect, useState} from 'react';
import SearchFormWidget from '../../components/forms/SearchForm/Widget/SearchFormWidget';
import {useAppDispatch} from '../../hooks/customReduxHooks';
import {initializeOptions} from '../../store/timeSlotsSearchSlice';

const AppointmentSearchWidgetPagePL = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(initializeOptions());
    }, [dispatch]);

    return <SearchFormWidget />;
};
export default AppointmentSearchWidgetPagePL;
