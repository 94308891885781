import { Box, Button, FormGroup, Grid, MenuItem } from "@material-ui/core";
import { PatientPersonalDataDto } from "../../../types/occupationalMedicine"
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { FormEvent, MutableRefObject, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../hooks/customReduxHooks";
import CustomSelectField from "../../common/CustomSelectField/CustomSelectField";
import { Accordion, AccordionDetails, AccordionSummary } from "../../common/Accordion/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CheckCircleOutline } from "@material-ui/icons";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import * as yup from 'yup';
import { isNotNullOrUndefined } from "../../../utils/lang";
import { fetchSection1Data, updateOccupationalMedicineData } from "../../../store/occupationalMedicineSlice";
import { useParams } from "react-router";

const REQUIRED_STRING = 'requiredField';
const SECTION = 1;

export type Props = {
    currentExpanded:number
    data:PatientPersonalDataDto;
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void;
    refSection:MutableRefObject<any>
}
const PatientPersonalDataComponent  = ({
    currentExpanded, data, onSubmitSection, refSection
    }:Props) =>{

    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const dispatch = useAppDispatch();
    const {appointmentId} = useParams<{appointmentId: string}>();
    
    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data) && data.isDraft!==true)
            setIsSaved(true);
    }, [data]);

    
    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values), fetchSection1Data({id:appointmentId}), SECTION);
    }

    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {             
                patientName: data?.patientName ?? '',
                patientSurname: data?.patientSurname ?? '',
                patientPesel: data?.patientPesel ?? '',
                patientStreet:  data?.patientStreet ?? '',
                patientHouseNumber: data?.patientHouseNumber ?? '',
                patientApartmentNumber: data?.patientApartmentNumber ?? '',
                patientPostcode:  data?.patientPostcode ?? '',
                patientCity: data?.patientCity ?? '',
                patientProfession:  data?.patientProfession ?? '',
                
            },
            validationSchema: yup.object({
                patientName: yup.string().required(commonT(REQUIRED_STRING)),
                patientSurname: yup.string().required(commonT(REQUIRED_STRING)),
                patientPesel: yup.string().required(commonT(REQUIRED_STRING)),
                patientStreet: yup.string().required(commonT(REQUIRED_STRING)),
                patientHouseNumber: yup.string().required(commonT(REQUIRED_STRING)),
                patientPostcode: yup.string().required(commonT(REQUIRED_STRING)),
                patientCity: yup.string().required(commonT(REQUIRED_STRING)),
                patientProfession: yup.string().required(commonT(REQUIRED_STRING)),
            }),
            onSubmit,
        }); 



    return (
        <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">

                <Box className={classes.titleContainer}>
                    <h4 className={classes.sectionHeader}>
                        {t('personalDataHeader')}
                    </h4>

                    {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.detailsContainer}>
                <Box>
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={4}>
                            <FormGroup >
                                <Label htmlFor="name">{t("firstName")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="patientName"
                                    name="patientName"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patientName}
                                    helperText={touched.patientName && t(errors.patientName)}
                                    error={touched.patientName && !!errors.patientName}
                                    />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <Label htmlFor="surname">{t("surname")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="patientSurname"
                                    name="patientSurname"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patientSurname}
                                    helperText={touched.patientSurname && t(errors.patientSurname)}
                                    error={touched.patientSurname && !!errors.patientSurname}
                                    />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup >
                                <Label htmlFor="pesel">PESEL *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="patientPesel"
                                    name="patientPesel"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patientPesel}
                                    helperText={touched.patientPesel && t(errors.patientPesel)}
                                    error={touched.patientPesel && !!errors.patientPesel}
                                    />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                {t('patientAddress')}
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup >
                                <Label htmlFor="street">{t("street")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="patientStreet"
                                    name="patientStreet"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patientStreet}
                                    helperText={touched.patientStreet && t(errors.patientStreet)}
                                    error={touched.patientStreet && !!errors.patientStreet}
                                    />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup >
                                <Label htmlFor="houseNumber">{t("houseNumber")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="patientHouseNumber"
                                    name="patientHouseNumber"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patientHouseNumber}
                                    helperText={touched.patientHouseNumber && t(errors.patientHouseNumber)}
                                    error={touched.patientHouseNumber && !!errors.patientHouseNumber}
                                    />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup >
                                <Label htmlFor="apartmentNumber">{t("apartmentNumber")} </Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="patientApartmentNumber"
                                    name="patientApartmentNumber"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patientApartmentNumber}
                                    />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup >
                                <Label htmlFor="postcode">{t("postcode")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="patientPostcode"
                                    name="patientPostcode"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patientPostcode}
                                    helperText={touched.patientPostcode && t(errors.patientPostcode)}
                                    error={touched.patientPostcode && !!errors.patientPostcode}
                                    />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup >
                                <Label htmlFor="city">{t("city")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="patientCity"
                                    name="patientCity"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patientCity}
                                    helperText={touched.patientCity && t(errors.patientCity)}
                                    error={touched.patientCity && !!errors.patientCity}
                                    />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={8}>
                        <FormGroup >
                            <Box className={classes.titleContainer + ' ' + classes.sectionHeader + ' '+ classes.label}>
                                {t('profession')}*
                            </Box>
           
                            <CustomTextField 
                                fullWidth 
                                id="patientProfession"
                                name="patientProfession"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.patientProfession}
                                helperText={touched.patientProfession && t(errors.patientProfession)}
                                error={touched.patientProfession && !!errors.patientProfession}
                                />
                        </FormGroup>
                        </Grid>
                    
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button type="submit"
                                className={classes.buttonNext}
                                variant={'contained'}
                                color={'secondary'}>
                                {t('next')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
       
            
        </form>
    )
}

export default PatientPersonalDataComponent