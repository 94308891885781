import {makeStyles, Theme} from '@material-ui/core';
import {DoctorPhoto} from '../../assets';
import { LIGHT_GRAY } from '../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    timeSlotsSearchWrapper: {
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 56,
    },
    headerContentWrapper: {
        marginTop: 30,
    },
    title: {
        paddingBottom: 24,
        fontSize: 36,
    },
    underTitle: {
        fontSize: 16,
        maxWidth: 520,
    },
    doctorImage: {
        height: `calc(100vh - 80px)`,
        background: `url(${DoctorPhoto}) no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 3,
        [theme.breakpoints.down('xs')]: {
            height: `50vh`,
            width: '100vw',
            marginLeft: -16,
            borderRadius: 0,
            marginTop: 16,
        },
    },
    subscriptionsWrapper: {
        marginLeft: 0,
        marginRight: 0,
    },
    section:{
        marginTop:36
    },
    sectionSubTitle:{
        fontSize: 20,
    },
    sectionDescription:{
        fontSize:16 
    },
    buttonContainer:{
        marginTop:16
    },
    button:{
        marginBottom:16,
        width:'100%',      
    },

    [theme.breakpoints.up('sm')]: {
        timeSlotsSearchWrapper: {
            marginLeft: 47,
            marginRight: 47,
        },
        headerContentWrapper: {
            marginTop: 60,
        },
        title: {
            fontSize: 56,
            paddingBottom: 56,
            maxWidth: 624,
        },
        underTitle: {
            fontSize: 20,
            marginBottom: 40,
            maxWidth: 520,
        },
        subscriptionsWrapper: {
            marginLeft: 47,
            marginRight: 47,
        },
        button:{
        },
    },
}));

export default useStyles;
