import React, {ChangeEvent} from 'react';
import {
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField,
    Grid,
    Box,
    useMediaQuery,
    Theme,
} from '@material-ui/core';
import {Button} from '../../..';
import {Autocomplete, Skeleton} from '@material-ui/lab';
import DateTimeSelect from '../DateTimeSelect/DateTimeSelect';
import {MEDICAL_SERVICE, SPECIALTY} from '../../../../const/event';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';
import {useHistory} from 'react-router';
import {TIME_SLOTS_PAGE} from '../../../../const/routes';
import {DateTime} from 'luxon';
import {Service, Specialty} from '../../../../types/timeSlot';
import {
    selectCurrentDateTime,
    selectedDateTimeSpecified,
    selectedOptionIdSpecified,
    selectedTimeSlotTypeSpecified,
    selectEndDateTime,
    selectSelectedOption,
    selectSelectedOptionId,
    selectServicesOptions,
    selectSpecialtiesOptions,
    selectStartDateTime,
    selectTimeSlotsSearchStatus,
    selectTimeSlotsSelectedType,
} from '../../../../store/timeSlotsSearchSlice';
import {parseTimeSlotQuery} from '../../../../utils/timeSlots';
import {isNotNullOrUndefined} from '../../../../utils/lang';

const SearchFormWidget = () => {
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const servicesOptions = useAppSelector(selectServicesOptions);
    const specialtiesOptions = useAppSelector(selectSpecialtiesOptions);
    const selectedType = useAppSelector(selectTimeSlotsSelectedType);
    const status = useAppSelector(selectTimeSlotsSearchStatus);
    const selectedOptionId = useAppSelector(selectSelectedOptionId);
    const selectedOption = useAppSelector(selectSelectedOption);
    const startDateTime = useAppSelector(selectStartDateTime);
    const endDateTime = useAppSelector(selectEndDateTime);
    const currentDateTime = useAppSelector(selectCurrentDateTime);
    const dispatch = useAppDispatch();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const history = useHistory();

    const onChangeSelect = (
        _: ChangeEvent<Record<string, unknown>>,
        optionId: string,
    ) => {
        const id = isNotNullOrUndefined(optionId)
            ? parseInt(optionId.split('_')[0])
            : null;
        dispatch(selectedOptionIdSpecified({optionId: id}));
    };

    const getOptionsIdsByType =
        selectedType === MEDICAL_SERVICE
            ? servicesOptions.map(
                  (option: Service) => option.id + '_' + selectedType,
              )
            : specialtiesOptions.map(
                  (option: Specialty) => option.id + '_' + selectedType,
              );

    const getOptionLabel = (optionId: string) => {
        const id = isNotNullOrUndefined(optionId)
            ? parseInt(optionId.split('_')[0])
            : null;
        const option =
            selectedType === MEDICAL_SERVICE
                ? servicesOptions.find((option: Service) => option.id === id)
                : specialtiesOptions.find(
                      (option: Specialty) => option.id === id,
                  );

        return isNotNullOrUndefined(option) ? option.name : null;
    };

    const onChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        dispatch(selectedTimeSlotTypeSpecified({selectedTimeSlotType: value}));
    };

    const onChangeDateTime = (selectedInterval: {
        startDateTime: DateTime;
        endDateTime: DateTime;
    }) => {
        dispatch(selectedDateTimeSpecified(selectedInterval));
    };

    const onSubmit = () => {
        if (isNotNullOrUndefined(selectedOption)) {
            const selectedMedicalServiceId = selectedOption.id;

            const search = parseTimeSlotQuery({
                medicalServiceId: selectedMedicalServiceId,
                startDateTime,
                endDateTime,
            });
            const url = TIME_SLOTS_PAGE + search;
            history.push(url);
        }
    };

    return (
        <Box>
            <RadioGroup
                aria-label="select"
                name="consultationSettings"
                value={selectedType}
                onChange={onChangeType}
            >
                <Box my={1}>
                    <FormControlLabel
                        value={MEDICAL_SERVICE}
                        control={<Radio />}
                        label={isDesktop ? t('selectService') : t('service')}
                    />
                    <FormControlLabel
                        value={SPECIALTY}
                        control={<Radio />}
                        label={
                            isDesktop
                                ? t('selectDoctorSpecialty')
                                : t('specialty')
                        }
                    />
                </Box>
            </RadioGroup>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {status === 'loaded' ? (
                        <Autocomplete
                            id="specialtiesSearchCombo"
                            options={getOptionsIdsByType}
                            getOptionLabel={getOptionLabel}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={
                                        selectedType === MEDICAL_SERVICE
                                            ? t('selectTypeOfService')
                                            : t('selectTypeOfSpecialty')
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {padding: '0 11px'},
                                    }}
                                    variant="outlined"
                                />
                            )}
                            onChange={onChangeSelect}
                            value={selectedOptionId + '_' + selectedType}
                        />
                    ) : (
                        <Skeleton variant="rect" width={'100%'} height={48} />
                    )}
                </Grid>
                <Grid item sm={8} style={{width: '100%'}}>
                    <DateTimeSelect
                        currentDateTime={currentDateTime}
                        onChangeDateTime={onChangeDateTime}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        onClick={onSubmit}
                        disabled={status !== 'loaded'}
                        fullWidth
                    >
                        <Box fontSize={16} fontWeight="bold">
                            {t('search')}
                        </Box>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SearchFormWidget;
