import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '16px',
            [theme.breakpoints.up('sm')]: {
                padding: '25px 0',
            }
        },
        button:{
            width:'100%',
            [theme.breakpoints.up('sm')]: {
                    width:'auto'
            }
    
        },
       
    }),
);

export default useStyles;
