import {Grid} from '@material-ui/core';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {selectMedicCalendarEntryIds} from '../../../store/medicCalendarSlice';
import MedicCalendarItem from '../MedicCalendarItem/MedicCalendarItem';
import useStyles from './MedicCalendarListStyles';

type MedicCalendarListProps = {
    setRemovingTimeSlot: (removing: boolean) => void;
    setMessage: (message: string) => void;
};

const MedicCalendarList = ({
    setRemovingTimeSlot,
    setMessage,
}: MedicCalendarListProps) => {
    const medicCalendarEntryIds = useAppSelector(selectMedicCalendarEntryIds);

    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.listWrapper}
        >
            {medicCalendarEntryIds.map(medicCalendarEntryId => (
                <MedicCalendarItem
                    key={medicCalendarEntryId}
                    medicCalendarEntryId={medicCalendarEntryId as string}
                    setRemovingTimeSlot={setRemovingTimeSlot}
                    setMessage={setMessage}
                />
            ))}
        </Grid>
    );
};

export default MedicCalendarList;
