import {createStyles, makeStyles} from '@material-ui/core';
import {mobile} from '../../const/sizes';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            padding: mobile.contentLargerSpacing,
        },
        buttonTile: {
            backgroundColor: 'white',
            borderRadius: 3,
            height: 56,
            textAlign: 'center',
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'space-between',
            padding: 16,
            marginBottom: 8
        },
    }),
);

export default useStyles;
