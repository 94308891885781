import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {LIGHT_GRAY_BORDER} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            border: '1px solid',
            borderColor: LIGHT_GRAY_BORDER,
            minHeight: 336,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 3,
            cursor: 'pointer',
        },
        selected: {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            border: '1px solid',
            borderColor: theme.palette.secondary.main,
        },
        title: {
            margin: '24px 0',
            fontWeight: 'bold',
            fontSize: 20,
        },
        description: {
            textAlign: 'center',
            marginTop: 25,
        },
        image: {
            width: '100%',
        },
        moreButton: {
            color: theme.palette.secondary.main,
            cursor: 'pointer',
            marginTop: 15,
            marginBottom: 24,
        },
        arrowDown: {
            width: 0,
            height: 0,
            borderLeft: '27.5px solid transparent',
            borderRight: '27.5px solid transparent',
            borderTop: '40px solid',
            borderTopColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.up('sm')]: {
            container: {
                marginBottom: 0,
                paddingLeft: 13,
                paddingRight: 13,
            },
            wrapper: {
                maxWidth: 408,
            },
        },
    }),
);

export default useStyles;
