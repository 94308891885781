import {Box, Divider, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AvailabilityTemplateDay, Confirmation, Loading} from '../../components';
import CustomButton from '../../components/common/Button/Button';
import {DayOfWeek} from '../../const/dayOfWeek';
import i18nNamespaces from '../../const/i18nNamespaces';
import {LoadingStatus} from '../../const/loadingStatus';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    confirmDayScheduleTemplatesSaving,
    fetchDayScheduleTemplates,
    saveDayScheduleTemplates,
    selectDayScheduleTemplatesStatus,
    selectDaysOfWeek,
    selectEffectiveDate,
    selectServiceDuration,
} from '../../store/dayScheduleTemplatesSlice';
import useStyles from './AvailabilityTemplateStyles';
import {CustomAlert} from "../../components/common/feedback";
import configurationProvider from '../../services/configurationProvider';
import axios from 'axios';

const AvailabilityTemplate = () => {
    const dispatch = useAppDispatch();
 
    const fetchMedicalServiceDefaultDurationData = async () => {
        try{
            setLoading(true);
            const result = await configurationProvider.getMedicalServiceDefaultDuration();
            setServiceDuration(result.data);
        }
        catch(error){
            if (axios.isAxiosError(error) &&
                error.response.status === 404)
                return;
            console.error(error);
        }
        finally{
            setLoading(false);
        }
    };

    const defaultServiceDuration = useAppSelector(selectServiceDuration);

    useEffect(() => {
        setServiceDuration(defaultServiceDuration);
        fetchMedicalServiceDefaultDurationData();

        dispatch(fetchDayScheduleTemplates());
    }, [dispatch, defaultServiceDuration]);

    const handleSaveChanges = () => {
        dispatch(saveDayScheduleTemplates());
    };

    const handleConfirmation = () => {
        dispatch(confirmDayScheduleTemplatesSaving());
    };

    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.AVAILABILITY_TEMPLATE);
    const {t: medicCalendarT} = useTranslation(i18nNamespaces.MEDIC_CALENDAR);

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const classes = useStyles();

    const [serviceDuration, setServiceDuration] = useState<number>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const status = useAppSelector(selectDayScheduleTemplatesStatus);
    const effectiveDate = useAppSelector(selectEffectiveDate);
    const daysOfWeek = useAppSelector(selectDaysOfWeek);

    const changesFromText = `${t(
        'changesWillTakeEffectFrom',
    )} ${effectiveDate?.toLocaleString()}`;

    const showAvailabilityTemplateDays = (
        ['saving', 'saved', 'loaded'] as LoadingStatus[]
    ).includes(status);

    return (
        <Box className={classes.availabilityTemplateContainer}>
            <Loading
                withBackdrop={true}
                loading={status === 'loading' || status === 'saving' || loading}
            />
            {showAvailabilityTemplateDays && (
                <>
                    {!isDesktop && (
                        <Box className={classes.changesFromWrapper}>
                            <Box className={classes.changesFromTitle}>
                                {commonT('availabilityTemplate')}
                            </Box>
                            <Box className={classes.changesFromContent}>
                                {changesFromText}
                            </Box>
                        </Box>
                    )}
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        className={classes.availabilityTemplateDaysContainer}
                    >
                        
                        {serviceDuration && daysOfWeek.map(dayOfWeek => (
                            <Box
                                key={dayOfWeek}
                                className={
                                    classes.availabilityTemplateDayWrapper
                                }
                            >
                                
                                <AvailabilityTemplateDay
                                    dayOfWeek={dayOfWeek as DayOfWeek}
                                    serviceDuration={serviceDuration}
                                />
                            </Box>
                        ))}
                    </Grid>
                    {isDesktop && <Divider className={classes.divider} />}
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        className={classes.availabilityTemplateSummaryWrapper}
                    >
                        {isDesktop && (
                            <Box className={classes.effectiveDate}>
                                {changesFromText}
                            </Box>
                        )}
                        <CustomButton
                            variant="contained"
                            color="secondary"
                            className={classes.saveChanges}
                            onClick={handleSaveChanges}
                        >
                            {commonT('saveChanges')}
                        </CustomButton>
                    </Grid>
                </>
            )}

            {status === 'failed' &&
                <CustomAlert
                    severity={'error'}
                    message={medicCalendarT('timeSlotsGeneratorIsBusy')}
                />
            }
            <Confirmation
                open={status === 'saved'}
                onClose={handleConfirmation}
                message={t('availabilityTemplateSaved')}
            />
        </Box>
    );
};

export default AvailabilityTemplate;
