import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabsBar: {
            padding: '16px',
            [theme.breakpoints.up('md')]: {
                padding: '16px 47px',
                backgroundColor: theme.palette.primary.main,
            },
        },
        selectButtonsWrapperReducedWidth: {
            maxWidth: 'calc(100% - 30px)'
        },
        tabsContainer: {
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            flexDirection: 'row',
        },
        selectButton: {
            width: 150,
            marginRight: 8,
            [theme.breakpoints.up('md')]: {
                marginRight: 20,
                marginBottom: 0,
            }
        },
        closeIconButton: {
            color: WHITE,
            marginTop: '8px',
            width: '22px',
            height: '22px',
            cursor: 'pointer',
        }
    }),
);

export default useStyles;
