import {memo} from 'react';
import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {CustomButton} from '../../../../components';
import {useLaboratoryAddressesContext} from '../../../../hooks/diagnostic/laboratoryAddresses/useLaboratoryAddressesContext';
import {useDiagnosticRegionsContext} from '../../../../hooks/diagnostic/orderHeaders/useOrderMedicalTestContext';
import useQuery from '../../../../hooks/useQuery';
import {MEDICAL_TESTS_ORDER_PAYMENT_PAGE, MEDICAL_TESTS_SUCCESSFULLY_ORDERED_WITHOUT_PAYMENT_PAGE} from '../../../../const/routes';
import {WHITE} from '../../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 8,
            padding: 16,
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                padding: 24,
            }
        },
        costOfMedicalTest: {
            fontSize: 16,
            lineHeight: '24px',
        },
        costOfMedicalTestValue: {
            fontSize: 20,
            lineHeight: '24px',
            fontWeight: 'bold',
        },
        button: {
            marginTop: 16,
            [theme.breakpoints.up('md')]: {
                marginTop: 26,
            }
        }
    }),
);

const OrderMedicalTests = memo(function MedicalSearchFilters() {
    const PRICE_SUM_INIT = 0;

    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.REFERRALS);
    const history = useHistory();
    const query = useQuery();

    const referralId = query.get('referralId');
    const {diagnosticServicePrices, selectedLaboratoryAddress} = useLaboratoryAddressesContext();
    const {makeOrder} = useDiagnosticRegionsContext();
    
    const medicalTestTotalPrice = selectedLaboratoryAddress.amountToPay;

    const orderMedicalTestsBtnHandler = async () => {
        try {
            await makeOrder(referralId, selectedLaboratoryAddress.id);
            
            if (medicalTestTotalPrice>0)
                history.replace( {
                    pathname: MEDICAL_TESTS_ORDER_PAYMENT_PAGE,
                    search: `referralId=${referralId}`,
                })
            else
                history.push(MEDICAL_TESTS_SUCCESSFULLY_ORDERED_WITHOUT_PAYMENT_PAGE)

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box className={classes.root}>
            <Grid
                container
                wrap={'nowrap'}
                justifyContent={'space-between'}
            >
                <Box
                    className={classes.costOfMedicalTest}
                    component={'span'}
                >
                    {t('costOfMedicalTests')}
                </Box>
                <Box
                    className={classes.costOfMedicalTestValue}
                    component={'span'}
                >
                    {medicalTestTotalPrice}zł
                </Box>
            </Grid>
            <CustomButton
                className={classes.button}
                fullWidth
                variant="contained"
                color="secondary"
                onClick={orderMedicalTestsBtnHandler}
            >
                {t('orderMedicalTests')}
            </CustomButton>
        </Box>
    )
})

export default OrderMedicalTests;