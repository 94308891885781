import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {LIGHT_GRAY, GRAY} from '../../../const/colors';
import {desktop} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            margin:13,
            height:'336px',
            width: '408px',
            border: `1px solid ${LIGHT_GRAY}`,
            borderRadius: '3px',
            backgroundColor: 'transparent',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            marginTop:16,
            fontSize: '20px',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            height:60,
            textAlign:'left'
            
        },
        multiLineContentEllipsis: {
            marginTop:16,
            fontSize: '1rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 7,
            '-webkit-box-orient': 'vertical',
        },
        pagingWrapper: {
            padding: `${desktop.contentSmallerSpacing}`,
        },
        link:{
            color: theme.palette.secondary.main,
            cursor: 'pointer',
            marginTop: 16,
        }
    }),
);

export default useStyles;
