import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { desktop, mobile } from '../../const/sizes';
import { WHITE } from '../../const/colors';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: mobile.contentLargerSpacing,   
            backgroundColor:WHITE,
            marginTop:'24px',
            
        },
        pagingWrapper: {
            padding: `${desktop.contentSmallerSpacing}`,
        },
        boxTitle: {
            fontWeight: 'bold',
            padding: '16px',
            fontSize: '18px',
            borderBottom:'1px solid #e0e0e0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        
        [theme.breakpoints.up('sm')]: {
            container: {
                margin: `24px ${desktop.contentSmallerSpacing} ${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
                padding:24,
            },
            boxTitle:{
                padding: '0 0 24px 0',
                fontSize: '20px',
            }
        },
       
    }),
);

export default useStyles;
