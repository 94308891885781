import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {LIGHT_GRAY, GRAY} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            padding: '16px 0',
            marginBottom: 24,
        },
        packageName: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 24,
        },
        editCardDataButton: {
            marginBottom: 16,
        },
        horizontalPadding: {
            paddingLeft: 16,
            paddingRight: 16,
        },
        horizontalLine: {
            width: '100%',
            height: 1,
            backgroundColor: LIGHT_GRAY,
            marginTop: 20,
        },
        optionButton: {
            marginTop: 8,
        },
        subscriptionOptions: {
            marginTop: 20,
            display: 'flex',
        },
        paymentCardForm: {
            margin: '20px 0',
        },
        buyerDataForm: {
            paddingTop: 20,
        },
        [theme.breakpoints.up('sm')]: {
            cancelSubscriptionButton: {
                marginLeft: 68,
            },
            optionButton: {
                marginLeft: 18,
                marginTop: 0,
            },
        },
        grayDot: {
            color: GRAY,
        },

        paymentInfo: {
            flexGrow: 1,
        },
    }),
);

export default useStyles;
