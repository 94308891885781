import {makeStyles, Theme} from "@material-ui/core";
import {BACKGROUND_SECONDARY, LIGHT_GRAY, WHITE} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: WHITE,
        paddingBottom: 70,
    },
    listItem: {
        borderBottom: '1px solid ' + LIGHT_GRAY,
        paddingLeft: 24,
        paddingRight: 24,
    },
    uploadFileButton: {
        backgroundColor: BACKGROUND_SECONDARY,
        color: theme.palette.text.secondary,
        borderRadius: 3,
        width: 'calc(100% - 32px)',
        height: 48,
        marginLeft: 16,
        position: 'fixed',
        bottom: 16,
        paddingLeft: 14,
        paddingRight: 14,
    },
    input: {
        display: 'hidden',
    },
}));

export default useStyles;
