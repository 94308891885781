import {useHistory} from 'react-router';
import {useEffect} from 'react';

const useScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, []);
};

export default useScrollToTop;
