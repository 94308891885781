import {
    CaseReducer,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {LoadingStatus} from '../const/loadingStatus';
import {RootState} from './store';
import {QuestionnaireDto, QuestionnaireFieldEntry} from '../types/appointments';
import appointmentsProvider from '../services/appointmentsProvider';
import mapQuestionnaire from './mappers/mapQuestionnaire';

const sliceName = 'questionnaire';

const initialState = {
    status: 'idle' as LoadingStatus,
    reasons: '' as string,
    bodyHeight: null as number,
    bodyWeight: null as number,
    age: null as number,
    updateRequired: false as boolean,
    questionnaireFieldEntries: [] as QuestionnaireFieldEntry[],
};

export type QuestionnaireSliceState = typeof initialState;

export const fetchQuestionnaire = createAsyncThunk<
    QuestionnaireDto,
    {appointmentId: string},
    {state: RootState}
>(`${sliceName}/fetchQuestionnaire`, async ({appointmentId}) => {
    const questionnaire = await appointmentsProvider.getQuestionnaire(
        appointmentId,
    );
    return mapQuestionnaire(questionnaire);
});

export const postQuestionnaire = createAsyncThunk<
    void,
    {appointmentId: string},
    {state: RootState}
>(`${sliceName}/postQuestionnaire`, async ({appointmentId}, thunkAPI) => {
    const {
        bodyHeight,
        bodyWeight,
        reasons,
        updateRequired,
        questionnaireFieldEntries,
    } = thunkAPI.getState().questionnaire;
    await appointmentsProvider.setPatientAppointmentAdditionalDetails(
        appointmentId,
        reasons,
    );
    await appointmentsProvider.addQuestionnaireAnswers(appointmentId, {
        bodyHeight,
        bodyWeight,
        updateRequired,
        questionnaireFieldEntries,
    });
});

const questionnaireFieldAnswerSpecifiedReducer: CaseReducer<
    QuestionnaireSliceState,
    PayloadAction<{
        id: number;
        group: number;
        detailsValue?: string;
        value?: boolean;
    }>
> = (state, action) => {
    const {id, group, detailsValue, value} = action.payload;
    state.questionnaireFieldEntries = state.questionnaireFieldEntries.map(
        entry => {
            const {questionnaireFieldDefinition: field} = entry;
            return field.id === id && field.group === group
                ? {
                      ...entry,
                      value,
                      detailsValue: detailsValue,
                  }
                : entry;
        },
    );
};

const consultationReasonsSpecifiedReducer: CaseReducer<
    QuestionnaireSliceState,
    PayloadAction<string>
> = (state, action) => {
    state.reasons = action.payload;
};

const basicAnswersSpecifiedReducer: CaseReducer<
    QuestionnaireSliceState,
    PayloadAction<{
        bodyHeight: number;
        bodyWeight: number;
    }>
> = (state, action) => {
    const {bodyHeight, bodyWeight} = action.payload;
    state.bodyHeight = bodyHeight;
    state.bodyWeight = bodyWeight;
};

const statusSpecifiedReducer: CaseReducer<
    QuestionnaireSliceState,
    PayloadAction<LoadingStatus>
> = (state, action) => {
    state.status = action.payload;
};

const questionnaireSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        basicAnswersSpecified: basicAnswersSpecifiedReducer,
        questionnaireFieldAnswerSpecified:
            questionnaireFieldAnswerSpecifiedReducer,
        consultationReasonsSpecified: consultationReasonsSpecifiedReducer,
        statusSpecified: statusSpecifiedReducer,
    },
    extraReducers: builder => {
        builder
            .addCase(fetchQuestionnaire.fulfilled, (state, action) => {
                state.bodyHeight = action.payload.bodyHeight;
                state.bodyWeight = action.payload.bodyWeight;
                state.age = action.payload.age;
                state.updateRequired = action.payload.updateRequired;
                state.questionnaireFieldEntries =
                    action.payload.questionnaireFieldEntries;
                state.status = 'loaded';
            })
            .addCase(fetchQuestionnaire.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchQuestionnaire.rejected, state => {
                state.status = 'failed';
            });
        builder
            .addCase(postQuestionnaire.fulfilled, state => {
                state.status = 'saved';
            })
            .addCase(postQuestionnaire.pending, state => {
                state.status = 'loading';
            })
            .addCase(postQuestionnaire.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const actions = questionnaireSlice.actions;

export const selectQuestionnaireBodyMetric = (state: RootState) => ({
    bodyHeight: state.questionnaire.bodyHeight,
    bodyWeight: state.questionnaire.bodyWeight,
});
export const selectQuestionnaireFieldEntries = (state: RootState) =>
    state.questionnaire.questionnaireFieldEntries;
export const selectConsultationReasons = (state: RootState) =>
    state.questionnaire.reasons;
export const selectQuestionnaireStatus = (state: RootState) =>
    state.questionnaire.status;

export const {
    basicAnswersSpecified,
    questionnaireFieldAnswerSpecified,
    consultationReasonsSpecified,
    statusSpecified,
} = actions;

export default questionnaireSlice.reducer;
