import * as yup from 'yup';
import {BuyerDataType} from '../const/buyerDataType';

const REQUIRED_FIELD = 'requiredField';

const buyerDataValidationSchema = yup.object().shape({
    type: yup
        .string()
        .oneOf([
            'Company' as BuyerDataType,
            'IndividualPerson' as BuyerDataType,
        ]),
    name: yup.string().when('type', {
        is: 'IndividualPerson' as BuyerDataType,
        then: yup.string().required(REQUIRED_FIELD),
    }),
    surname: yup.string().when('type', {
        is: 'IndividualPerson' as BuyerDataType,
        then: yup.string().required(REQUIRED_FIELD),
    }),
    companyName: yup.string().when('type', {
        is: 'Company' as BuyerDataType,
        then: yup.string().required(REQUIRED_FIELD),
    }),
    nip: yup.string().when('type', {
        is: 'Company' as BuyerDataType,
        then: yup.string().required(REQUIRED_FIELD),
    }),
    streetName: yup.string().required(REQUIRED_FIELD),
    houseNumber: yup.string().required(REQUIRED_FIELD),
    apartmentNumber: yup.string(),
    postcode: yup.string().required(REQUIRED_FIELD),
    cityName: yup.string().required(REQUIRED_FIELD),
});

export default buyerDataValidationSchema;
