import React from 'react';
import useStyles from './AddPersonToPackageFormStyles';
import {CustomTextField, FormGroup, Label} from '../../index';
import {useFormik} from 'formik';
import addAdultToPackageSchema from '../../../validators/addAdultToPackage';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import CustomButton from '../../common/Button/Button';
import {MemberDto} from '../../../types/patientPackages';

interface AddPersonToPackageFormProps {
    onSubmit: (data: MemberDto) => void;
    isChild?: boolean;
    error?: string;
}

const AddPersonToPackageForm = ({
    onSubmit,
    isChild,
    error,
}: AddPersonToPackageFormProps) => {
    const classes = useStyles();
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);
    const {t} = useTranslation(i18nNamespaces.PATIENT_PACKAGES);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {
                name: '',
                surname: '',
                pesel: '',
            },
            validationSchema: addAdultToPackageSchema,
            onSubmit,
        });

    return (
        <form className={classes.container} onSubmit={handleSubmit}>
            <Box style={{fontWeight: 'bold', marginBottom: 15}}>
                {!isChild && t('addAdultToPackage')}
            </Box>
            <Grid container justifyContent={'space-between'}>
                <FormGroup className={classes.input}>
                    <Label htmlFor="name">{authT('name')}</Label>
                    <CustomTextField
                        id="name"
                        name="name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        helperText={touched.name && authT(errors.name)}
                        error={touched.name && !!errors.name}
                    />
                </FormGroup>
                <FormGroup className={classes.input}>
                    <Label htmlFor="surname">{authT('surname')}</Label>
                    <CustomTextField
                        id="surname"
                        name="surname"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.surname}
                        helperText={touched.surname && authT(errors.surname)}
                        error={touched.surname && !!errors.surname}
                    />
                </FormGroup>
                <FormGroup className={classes.input}>
                    <Label htmlFor="pesel">{t('personalNumber')}</Label>
                    <CustomTextField
                        id="pesel"
                        name="pesel"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pesel}
                        helperText={touched.pesel && authT(errors.pesel)}
                        error={touched.pesel && !!errors.pesel}
                    />
                </FormGroup>
            </Grid>
            {error && (
                <Grid item className={classes.error}>
                    {error}
                </Grid>
            )}
            <Grid item container className={classes.buttonRow}>
                <FormGroup>
                    <CustomButton
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth={!isDesktop}
                        className={classes.addButton}
                    >
                        {t('add')}
                    </CustomButton>
                </FormGroup>
            </Grid>
        </form>
    );
};

export default AddPersonToPackageForm;
