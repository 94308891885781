import {Box} from '@material-ui/core';
import {useAppDispatch} from '../../../../hooks/customReduxHooks';
import useStyles from './AdditionalDetailsStyles';
import AdditionalDetailsEditable from '../AdditionalDetailsEditable/AdditionalDetailsEditable';
import AdditionalDetailsNotEditable from '../AdditionalDetailsNotEditable/AdditionalDetailsNotEditable';
import {useEffect} from 'react';
import {getAllAttachmentsMetaData} from '../../../../store/appointmentAttachmentsSlice';
import {
    Appointment,
    AppointmentGroupEnum,
    AppointmentStateEnum,
} from '../../../../types/appointments';
import OccupationalMedicineDetails from '../OccupationalMedicineDetails/OccupationalMedicineDetails';
import OccupationalMedicineDetailsNotEditable from '../OccupationalMedicineDetailsNotEditable/OccupationalMedicineDetailsNotEditable';

interface Props {
    appointment: Appointment;
}

const AdditionalDetails = ({appointment}: Props) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const appointmentState = appointment.state.state;
    const displayAdditionalDetailsEditable =
        appointmentState === AppointmentStateEnum.Planned ||
        appointmentState === AppointmentStateEnum.InProgress;
    const isOccupationalMedicine =
        appointment.appointmentGroup ===
        AppointmentGroupEnum.OccupationalMedicineAppointment;

    useEffect(() => {
        dispatch(
            getAllAttachmentsMetaData({
                appointmentId: appointment.id,
                fetchSilently: false,
            }),
        );
        const intervalId = setInterval(() => {
            dispatch(
                getAllAttachmentsMetaData({
                    appointmentId: appointment.id,
                    fetchSilently: true,
                }),
            );
        }, 30000);
        return () => {
            clearInterval(intervalId);
        };
    }, [dispatch, appointment.id]);

    return (
        <Box className={classes.container}>
            {isOccupationalMedicine ? (
                <>
                    {displayAdditionalDetailsEditable ? (
                        <OccupationalMedicineDetails
                            appointment={appointment}
                        />
                    ) : (
                        <OccupationalMedicineDetailsNotEditable
                            appointment={appointment}
                        />
                    )}
                </>
            ) : (
                <>
                    {displayAdditionalDetailsEditable ? (
                        <AdditionalDetailsEditable appointment={appointment} />
                    ) : (
                        <AdditionalDetailsNotEditable
                            appointment={appointment}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default AdditionalDetails;
