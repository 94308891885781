import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {mobile} from '../../const/sizes';
import { WHITE } from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container:{
            backgroundColor: WHITE,
            marginBottom: mobile.contentSmallerSpacing,
            padding:'32px 47px'
        },
       
        title: {
            fontSize: 36,
            fontWeight: 'bold',
        },
        image:{
            width: 150,
        },
        appointmentDetails:{
            padding: `50px 0 0 0`,
        }
    }),
);

export default useStyles;
