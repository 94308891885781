import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import medicsProvider from '../services/medicsProvider';
import {MedicDto} from '../types/timeSlot';
import {RootState} from './store';
import {Tag} from '../types/medic';
import {TranslationsDto} from '../types/translations';

type MedicProfileDataStatus = 'idle' | 'loading' | 'failed';
const sliceName = 'medicProfile';

interface MedicProfileState {
    medicDetails: MedicDto | undefined;
    medicDetailsStatus: MedicProfileDataStatus;
    tags: Tag[] | null;
    tagsStatus: MedicProfileDataStatus;
    medicTags: Tag[];
    medicTagsStatus: MedicProfileDataStatus;
    newMedicPicture: File | null;
    newMedicStamp: File | null;
    newMedicTags: Tag[] | null;
    saveEnabled: boolean;
}

const initialState: MedicProfileState = {
    medicDetails: undefined,
    medicDetailsStatus: 'idle',
    tags: null,
    tagsStatus: 'idle',
    medicTags: [],
    medicTagsStatus: 'idle',
    newMedicPicture: null,
    newMedicStamp: null,
    newMedicTags: null,
    saveEnabled: false,
};

export const fetchMedicDetails = createAsyncThunk<MedicDto, void, unknown>(
    `${sliceName}/fetchMedicDetails`,
    async () => {
        return await medicsProvider.getCurrentMedic();
    },
);

export const fetchTags = createAsyncThunk<Tag[], void, unknown>(
    `${sliceName}/fetchTags`,
    async () => {
        return await medicsProvider.fetchTags();
    },
);

export const fetchMedicTags = createAsyncThunk<Tag[], void, unknown>(
    `${sliceName}/fetchMedicTags`,
    async () => {
        return await medicsProvider.fetchMedicTags();
    },
);

const medicProfileSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setNewMedicPicture: (state, action: PayloadAction<File | null>) => {
            state.newMedicPicture = action.payload;
        },
        setNewMedicStamp: (state, action: PayloadAction<File | null>) => {
            state.newMedicStamp = action.payload;
        },
        updateMedicBio: (state, action: PayloadAction<string | null>) => {
            state.medicDetails.bio = action.payload;
        },
        setNewMedicTags: (state, action: PayloadAction<Tag[] | null>) => {
            state.newMedicTags = action.payload;
        },
        updateTranslation: (
            state,
            action: PayloadAction<{
                languageCode: string;
                languageId: number;
                value: string;
                key: string;
            }>,
        ) => {
            const {languageCode, languageId, value, key} = action.payload;

            if (
                state.medicDetails.translations.find(
                    x => x.languageId === languageId && x.key === key,
                )
            ) {
                state.medicDetails.translations =
                    state.medicDetails.translations.map(x => {
                        if (x.languageId === languageId && x.key === key)
                            return {...x, value: value};
                        else return x;
                    });
            } else
                state.medicDetails.translations = [
                    ...state.medicDetails.translations,
                    {
                        languageCode: languageCode,
                        languageId: languageId,
                        value: value,
                        key: key,
                    },
                ];
        },
        updateSaveEnabled: (state, action: PayloadAction<boolean | null>) => {
            state.saveEnabled = action.payload;
        },
        resetMedicProfileState: () => initialState,
    },
    extraReducers: builder => {
        builder
            .addCase(fetchMedicDetails.pending, state => {
                state.medicDetailsStatus = 'loading';
            })
            .addCase(fetchMedicDetails.fulfilled, (state, action) => {
                state.medicDetails = action.payload;
                state.medicDetailsStatus = 'idle';
            })
            .addCase(fetchTags.pending, state => {
                state.tagsStatus = 'loading';
            })
            .addCase(fetchTags.fulfilled, (state, action) => {
                state.tags = action.payload;
                state.tagsStatus = 'idle';
            })
            .addCase(fetchMedicTags.pending, state => {
                state.medicTagsStatus = 'loading';
            })
            .addCase(fetchMedicTags.fulfilled, (state, action) => {
                state.medicTags = action.payload;
                state.medicTagsStatus = 'idle';
            });
    },
});

export const {
    setNewMedicPicture,
    setNewMedicStamp,
    updateMedicBio,
    updateTranslation,
    updateSaveEnabled,
    setNewMedicTags,
    resetMedicProfileState,
} = medicProfileSlice.actions;

export const selectMedicDetails = (state: RootState) =>
    state.medicProfile.medicDetails;

export const selectMedicDetailsStatus = (state: RootState) =>
    state.medicProfile.medicDetailsStatus;

export const selectTags = (state: RootState) => state.medicProfile.tags;

export const selectTagsStatus = (state: RootState) =>
    state.medicProfile.tagsStatus;

export const selectMedicTags = (state: RootState) =>
    state.medicProfile.medicTags;

export const selectMedicTagsStatus = (state: RootState) =>
    state.medicProfile.medicTagsStatus;

export const selectNewMedicTags = (state: RootState) =>
    state.medicProfile.newMedicTags;

export const selectNewMedicPicture = (state: RootState) =>
    state.medicProfile.newMedicPicture;

export const selectNewMedicStamp = (state: RootState) =>
    state.medicProfile.newMedicStamp; 

export const selectMedicTranslations = (state: RootState) =>
    state.medicProfile.medicDetails?.translations || [];

export const selectSaveEnabled = (state: RootState) =>
    state.medicProfile.saveEnabled;

export default medicProfileSlice.reducer;
