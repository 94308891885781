import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';
import {
    CalendarToday,
    EditOutlined,
    InsertDriveFileOutlined,
    SettingsOutlined,
} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import {
    NavLink,
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';
import {AvailabilityTemplate, MedicAccountDataPage, MedicProfilePage, MedicWorkingTimeSettlementsPage} from '..';
import {AuthRoute, SideMenuWrapper} from '../../components';
import {MEDIC} from '../../const/auth';
import i18nNamespaces from '../../const/i18nNamespaces';
import {ACCOUNT_DATA_PAGE, AVAILABILITY_TEMPLATE, MEDIC_PROFILE_PAGE, MEDIC_WORKING_TIME_SETTLEMENTS} from '../../const/routes';
import useStyles from './MedicSettingsPageStyles';

const MedicSettingsPage = () => {
    const match = useRouteMatch();
    const classes = useStyles();

    const {t} = useTranslation(i18nNamespaces.COMMON);

    return (
        <SideMenuWrapper
            menu={
                <List className={classes.menu}>
                    <NavLink
                        to={`${match.url}${AVAILABILITY_TEMPLATE}`}
                        className={classes.menuLink}
                        activeClassName={classes.menuActiveLink}
                    >
                        <ListItem button className={classes.menuItem}>
                            <ListItemIcon className={classes.menuButton}>
                                <CalendarToday className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText disableTypography={true}>
                                {t('availabilityTemplate')}
                            </ListItemText>
                        </ListItem>
                    </NavLink>
                    <NavLink
                        to={`${match.url}${MEDIC_PROFILE_PAGE}`}
                        className={classes.menuLink}
                        activeClassName={classes.menuActiveLink}
                    >
                        <ListItem button className={classes.menuItem}>
                            <ListItemIcon className={classes.menuButton}>
                                <EditOutlined className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText disableTypography={true}>
                                {t('myProfile')}
                            </ListItemText>
                        </ListItem>
                    </NavLink>
                    <NavLink
                        to={`${match.url}${ACCOUNT_DATA_PAGE}`}
                        className={classes.menuLink}
                        activeClassName={classes.menuActiveLink}
                    >
                        <ListItem button className={classes.menuItem}>
                            <ListItemIcon className={classes.menuButton}>
                                <SettingsOutlined
                                    className={classes.menuIcon}
                                />
                            </ListItemIcon>
                            <ListItemText disableTypography={true}>
                                {t('accountDetails')}
                            </ListItemText>
                        </ListItem>
                    </NavLink>
                    <NavLink
                        to={`${match.url}${MEDIC_WORKING_TIME_SETTLEMENTS}`}
                        className={classes.menuLink}
                        activeClassName={classes.menuActiveLink}
                    >
                        <ListItem button className={classes.menuItem}>
                            <ListItemIcon className={classes.menuButton}>
                                <InsertDriveFileOutlined
                                    className={classes.menuIcon}
                                />
                            </ListItemIcon>
                            <ListItemText disableTypography={true}>
                                {t('workingTimeSettlements')}
                            </ListItemText>
                        </ListItem>
                    </NavLink>
                </List>
            }
        >
            <Switch>
                <AuthRoute
                    exact
                    path={`${match.url}${AVAILABILITY_TEMPLATE}`}
                    roles={[MEDIC]}
                >
                    <AvailabilityTemplate />
                </AuthRoute>

                <AuthRoute
                    exact
                    path={`${match.url}${MEDIC_PROFILE_PAGE}`}
                    roles={[MEDIC]}
                >
                    <MedicProfilePage />
                </AuthRoute>

                <AuthRoute 
                    exact 
                    path={`${match.url}${ACCOUNT_DATA_PAGE}`} 
                    roles={[MEDIC]}>
                    <MedicAccountDataPage/>
                </AuthRoute>

                <AuthRoute
                    exact
                    path={`${match.url}${MEDIC_WORKING_TIME_SETTLEMENTS}`}
                    roles={[MEDIC]}
                >
                    <MedicWorkingTimeSettlementsPage />
                </AuthRoute>

                <Route path={`${match.url}`}>
                    <Redirect to={`${match.url}${AVAILABILITY_TEMPLATE}`} />
                </Route>
            </Switch>
        </SideMenuWrapper>
    );
};

export default MedicSettingsPage;
