import { useTranslation } from "react-i18next";
import i18nNamespaces from "../const/i18nNamespaces";

const useOnDropRejectMessage = () => {
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);


    const handleOnDropRejectMessage = (
        rejectedFile: File,
        acceptedFiles: string[],
        maxFileSize: number)=>
    {
        if (rejectedFile.size > maxFileSize) {
            return tCommon('dragDropAttachmentSizeToBigRejected', {
                fileName: rejectedFile.name,
                maxSize: maxFileSize,
            });
        } else
            return tCommon('dragDropAttachmentRejected', {
                fileName: rejectedFile.name,
            });
    }
   
    return handleOnDropRejectMessage;
};

export default useOnDropRejectMessage;