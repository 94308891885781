import React, {memo} from 'react';
import {createStyles, IconButton, makeStyles} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import clsx from 'clsx';
import {TopBar} from '../../index';
import {BLACK} from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        closeIconButton: {
            transform: 'translateX(12px)',
        },
        closeIcon: {
            color: BLACK,
            width: 22,
            height: 22,
        }
    }),
);

interface Props {
    onCloseIconClick?: () => void;
}

const LogoAndCloseTopBar = memo(function LogoAndCloseTopBar({onCloseIconClick}: Props) {
    const classes = useStyles();
    return (
        <TopBar>
            <IconButton
                className={
                    clsx(classes.closeIconButton, {
                        ['hidden']: !onCloseIconClick
                    })
                }
                onClick={onCloseIconClick}
            >
                <Close className={classes.closeIcon} />
            </IconButton>
        </TopBar>
    )
})

export default LogoAndCloseTopBar;