import { createStyles, makeStyles } from "@material-ui/core";
import { LIGHT_DISABLED } from "../../../const/colors";


const useStyles = makeStyles(() =>
        createStyles({
            medicalPackageName: {
                fontSize: 16,
                fontWeight: 'bold',
                lineHeight: '24px',
            },
            medicalPackageNameDisabled: {
                fontSize: 16,
                fontWeight: 'bold',
                lineHeight: '24px',
                color: LIGHT_DISABLED
            },
            medicalPackageAddDeleteIcon: {
                width: 23,
            },
        }),
    );

export default useStyles;
