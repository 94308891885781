import {Box, Grid} from '@material-ui/core';
import {RemoveCircleOutline} from '@material-ui/icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import configurationProvider from '../../../services/configurationProvider';
import {
    changeTimeSlotTemplateDateTimes,
    removeTimeSlotTemplate,
    selectServiceDuration,
} from '../../../store/dayScheduleTemplatesSlice';
import {TimeSlotTemplate} from '../../../types/dayScheduleTemplates';
import TimeOfDay from '../../../utils/timeOfDay';
import Loading from '../../common/Loading/Loading';
import TimeSlotInput from '../../common/TimeSlotInput/TimeSlotInput';
import useStyles from './AvailabilityTemplateTimeSlotStyles';

type AvailabilityTemplateTimeSlotProps = {
    timeSlotTemplate: TimeSlotTemplate;
    serviceDuration: number;
};

const AvailabilityTemplateTimeSlot = ({
    timeSlotTemplate,serviceDuration
}: AvailabilityTemplateTimeSlotProps) => {
    const dispatch = useAppDispatch();

    const handleTimeSlotTemplateTimeChange = (
        time: TimeOfDay,
        isStartDateTime: boolean,
    ) => {
        dispatch(
            changeTimeSlotTemplateDateTimes({
                timeSlotTemplateId: timeSlotTemplate.id,
                dateTimes: {
                    startTime: isStartDateTime && time,
                    endTime: !isStartDateTime && time,
                },
            }),
        );
    };

    const handleTimeSlotTemplateStartDateTimeChange = (time: TimeOfDay) =>
        handleTimeSlotTemplateTimeChange(time, true);

    const handleTimeSlotTemplateEndDateTimeChange = (time: TimeOfDay) =>
        handleTimeSlotTemplateTimeChange(time, false);

    const removeTimeSlotTemplateCallback = () => {
        dispatch(removeTimeSlotTemplate(timeSlotTemplate.id));
    };

    const classes = useStyles();

    const earliestStartTime = timeSlotTemplate.earliestStartTime;
    const latestStartTime = timeSlotTemplate.endTime.minus(serviceDuration);
    const earliestEndTime = timeSlotTemplate.startTime.plus(serviceDuration);
    const latestEndDate = TimeOfDay.getLatestTime();

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <TimeSlotInput
                value={timeSlotTemplate.startTime}
                onChange={handleTimeSlotTemplateStartDateTimeChange}
                disabled={!timeSlotTemplate.canEdit}
                earliestDateTime={earliestStartTime}
                latestDateTime={latestStartTime}
                className={classes.timeSlotInput}
                serviceDuration={serviceDuration}
            />
            <Box className={classes.timeSlotSeparator}>-</Box>
            <TimeSlotInput
                value={timeSlotTemplate.endTime}
                onChange={handleTimeSlotTemplateEndDateTimeChange}
                disabled={!timeSlotTemplate.canEdit}
                earliestDateTime={earliestEndTime}
                latestDateTime={latestEndDate}
                className={classes.timeSlotInput}
                serviceDuration={serviceDuration}
            />
            <Box className={classes.removeTimeSlotWrapper}>
                <RemoveCircleOutline
                    className={classes.removeTimeSlot}
                    onClick={removeTimeSlotTemplateCallback}
                />
            </Box>
        </Grid>
    );
};

export default AvailabilityTemplateTimeSlot;
