import {Avatar, Box, Grid, useMediaQuery, useTheme} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./HistoricalAppointmentStyles";
import {Appointment, Attachment} from "../../../../types/appointments";
import {Accordion, AccordionSummary, AccordionDetails, AppointmentTimeDetails, UploadedItem} from '../../../index';
import AppointmentNotes from "../AppointmentNotes/AppointmentNotes";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import {getFileIcon} from "../../../../utils/file";
import {useDownloadAsync} from "../../../../hooks/appointmentFileUpload";

interface Props {
    appointment: Appointment;
    attachments: Attachment[];
}

const HistoricalAppointment = ({appointment, attachments}: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const downloadFileAsync = useDownloadAsync();
    return (
        <div className={classes.container}>
            {
                isMobile && (
                    <Box className={classes.appointmentTimeDetailsBarWrapper}>
                        <AppointmentTimeDetails
                            isNow={false}
                            isInTheFuture={false}
                            date={appointment.startDate}
                        />
                    </Box>
                )
            }
            <Accordion square defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {
                            !isMobile && (
                                <Box className={classes.appointmentTimeDetailsSquareWrapper}>
                                    <AppointmentTimeDetails
                                        square
                                        isNow={false}
                                        isInTheFuture={false}
                                        date={appointment.startDate}
                                    />
                                </Box>
                            )
                        }
                        <Avatar
                            alt={appointment.medic.surname}
                            src={appointment.medic.photoUrl}
                            className={classes.medicAvatar}
                        />
                        <Grid container direction="column" className={classes.medicDetails}>
                            <span className={classes.medicName}>
                                {appointment.medic?.title}{' '}
                                {appointment.medic?.firstName}{' '}
                                {appointment.medic?.surname}
                            </span>
                            <span>{appointment.medicalService?.name}</span>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.accordionDetailsContent}>
                        <AppointmentNotes
                            patientReasons={appointment.patientReasons}
                            drugMedicalRecommendations={appointment.drugMedicalRecommendations}
                            referralMedicalRecommendations={appointment.referralMedicalRecommendations}
                            otherMedicalRecommendations={appointment.otherMedicalRecommendations}
                            medicalInterview={appointment.medicalInterview}
                        />
                        {
                            attachments && !!attachments.length && (
                                <Box className={classes.attachmentsContainer}>
                                    <h4 className={classes.attachmentsHeader}>{t('files')}</h4>
                                    <Box
                                        className={classes.attachments}
                                    >
                                        {
                                            attachments.map(attachment => (
                                                <Box
                                                    key={attachment.id}
                                                    className={classes.attachmentsItem}
                                                    onClick={() => downloadFileAsync(appointment.id, `${attachment.id}`)}
                                                >
                                                    <UploadedItem
                                                        fileName={attachment.fileName}
                                                        fileOwner={attachment.person}
                                                        fileIcon={getFileIcon(attachment.contentType, attachment.thumbnail)}
                                                        fileDateCreated={attachment.uploadDate.toFormat("dd'.'MM'.'yyyy")}
                                                        fileTimeCreated={attachment.uploadDate.toFormat("HH':'mm")}
                                                        removeFileEnabled={false}
                                                    />
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            )
                        }
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default HistoricalAppointment;