import {Button} from '@material-ui/core';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {AppointmentStateEnum} from '../../../../types/appointments';
import {EVALUATION_PAGE} from '../../../../const/routes';
import {canCancelAppointmentWithoutRefund} from '../../../../utils/appointment';

interface ButtonProps {
    children: string;
    onClick?: () => void;
}

interface ComponentProps {
    appointmentId: string;
    appointmentState: AppointmentStateEnum;
    canCancelWithRefund: boolean;
    className: string;
    cancelAppointmentClick: () => void;
    bookedByTheAgent:boolean;
}

const CancelOrEvaluateAppointmentButton = ({
    appointmentId,
    appointmentState,
    canCancelWithRefund,
    cancelAppointmentClick,
    className,
    bookedByTheAgent
}: ComponentProps) => {
    const {t: tAppointmentDetails} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const history = useHistory();

    const canCancelWithoutRefund =
        canCancelAppointmentWithoutRefund(appointmentState, canCancelWithRefund);

    const getButtonProps = (): ButtonProps | null => {
        if ((canCancelWithRefund || canCancelWithoutRefund)) {
            const text = canCancelWithRefund
                ? tAppointmentDetails('cancelAppointmentWithRefund')
                : canCancelWithoutRefund
                    ? tAppointmentDetails('cancelAppointment')
                    : null;
            return  {
                children: text,
                onClick: cancelAppointmentClick,
            };
        } else if (appointmentState === AppointmentStateEnum.Completed) {
            return {
                children: tAppointmentDetails('rateMedic'),
                onClick: () => history.push(
                    `${EVALUATION_PAGE}/${appointmentId}`
                ),
            };
        }

        return null;
    };
    const buttonProps = getButtonProps();

    return buttonProps ? (
        <Button
            {...buttonProps}
            className={className}
            variant={'outlined'}
        />
    ) : null;
}

export default CancelOrEvaluateAppointmentButton;