import {PATIENT_MEDICS, REMOVE_PATIENT_MEDIC} from './endpoints';
import {PatientMedic} from '../types/patientMedics';
import {http} from './http';

class PatientMedicsProvider {
    async getPatientMedics() {
        return (await http.get<PatientMedic[]>(PATIENT_MEDICS)).data;
    }
    async removePatientMedic(medicId: string, medicalServiceId:number) {
        return (await http.delete(REMOVE_PATIENT_MEDIC(medicId, medicalServiceId))).data;
    }
}

export default new PatientMedicsProvider();
