import {createContext, useContext} from 'react';
import {Appointment} from '../../types/appointments';

interface AppointmentContextValue {
    appointment: Appointment | null,
    appointmentLoading: boolean,
    appointmentError: string,
}

export const AppointmentContext = createContext<AppointmentContextValue | null>(undefined);

const useAppointmentContext = () => {
    const context = useContext(AppointmentContext);
    if (context === undefined) {
        throw new Error('useAppointmentContext was used outside of its Provider');
    }
    return context;
};

export default useAppointmentContext;