import React from 'react';
import {Layout} from '../components';

const withLayout = <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>,
) =>
    class WithLayout extends React.Component<P> {
        render() {
            const {...props} = this.props;

            return (
                <Layout>
                    <Component {...(props as P)} />
                </Layout>
            );
        }
    };

export default withLayout;
