import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {mobile} from '../../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        menuButton: {
            marginLeft: 20,
            textDecoration: 'none',
            height: 24,
            display: 'flex',
            alignItems: 'center',
        },
        isActive: {
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
        },
        mobileMenuHeader: {
            color: theme.palette.grey[600],
            padding: mobile.contentLargerSpacing,
        },
        mobileMenuLink: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
        },
        mobileMenuIcon: {
            color: 'inherit',
        },
    }),
);

export default useStyles;
