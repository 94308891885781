import {http, rawHttp} from '../http';
import {DIAGNOSTIC_ORDER_HEADERS, GET_APPOINTMENTS_REFERRALS, GET_MEDICAL_TEST_PDF_RESULT, GET_PAP_TEST_COLLECTED_FROM} from '../endpoints';
import {BuyerDataDto} from '../../types/buyerData';
import {DateTime} from 'luxon';
import { MedicalPdfResults } from '../../types/referrals';
import { string } from 'yup';

export interface DiagnosticOrderLinesDto {
    orderNbr: number;
    price: number;
    purchasePrice: number;
    commissionFee: number;
    diagnosticServiceId: number;
    diagnosticServiceName: string;
}

export interface PatientDiagnosticOrderHeadersDto {
    id: string;
    type: string;
    patientName: string;
    patientSecondName: string;
    patientSurname: string;
    diagnosticOrderLines: DiagnosticOrderLinesDto[];
    medicalPackage: MedicalPackageDto | null;
    availableInPdfForm:boolean;
}

export interface PatientGroupMedicaPackageAssignmentDto {
    patientGroupAssignmentId:number,
    packageName: string
}

export interface MedicalPackageDto {
    id: number,
    name: string,
    type: string
}

export interface PatientOrderedDiagnosticOrderHeadersDto {
    id: string;
    type: string;
    status: string;
    laboratoryAddress: string;
    laboratoryCity: string;
    zipCode:string;
    contactPhoneNumber: string;
    contactEmail:string;
    patientName: string;
    patientSecondName: string;
    patientSurname: string;
    orderDateTime: string;
    orderExternalId: string;
    diagnosticOrderHeaderId: string;
    medicalPackageName: string;
    orderedDiagnosticServices: PatientOrderedDiagnosticServicesDto[];
}

export interface PatientOrderedDiagnosticOrderHeaders {
    id: string;
    type: string;
    status: string;
    laboratoryAddress: string;
    laboratoryCity: string;
    zipCode:string;
    contactPhoneNumber: string;
    contactEmail:string;
    patientName: string;
    patientSecondName: string;
    patientSurname: string;
    orderDateTime: DateTime;
    medicalPackageName: string;
}

export interface PatientDiagnosticOrdersReferralsDto {
    DiagnosticOrderHeaderId: string;
    type: string;
    medicalPackage:string;
    medicName :string;
    medicalServiceName:string;
    createDate: string;
    resultStorageUrls: FileDto[];
    diagnosticServices: AppointmentReferralDiagnosticServiceDto[];
    diagnosticOrderResultStatus:string;
}

export interface PatientOrderedDiagnosticServicesDto {
    id:number;
    diagnosticServiceName: string;
}


export function getPatientReferralsForMedicalTests() {
    return rawHttp.get<PatientDiagnosticOrderHeadersDto[]>(DIAGNOSTIC_ORDER_HEADERS);
}

export interface DiagnosticOrderHeadersDto {
    Type: string,
    AppointmentId: number,
    PatientId: string,
    DiagnosticOrderLines: Array<{
        DiagnosticServiceId: number,
        PapTestCollectedFrom: PapTestCollectedFromDto
    }>,
    MedicalPackageId: number | null,
}

export function createPatientReferralForMedicalTests(data: DiagnosticOrderHeadersDto) {
    return rawHttp.post<void>(DIAGNOSTIC_ORDER_HEADERS, data);
}

export interface MedicalTestsOrderDetailsDto {
    patientName: string;
    patientSecondName: string;
    patientSurname: string;
    childName: string;
    childSecondName: string;
    childSurname: string;
    laboratoryAddress: string;
    laboratoryCity: string;
    amountToPay: number;
    diagnosticServiceLilnes: DiagnosticOrderLinesDto[];
}

export function getMedicalTestsOrderDetails(referralId: string) {
    return rawHttp.get<MedicalTestsOrderDetailsDto>(`${DIAGNOSTIC_ORDER_HEADERS}/${referralId}`);
}


export function makeMedicalTestsOrder(referralId: string, laboratoryId: number) {
    return rawHttp.put<void>(`${DIAGNOSTIC_ORDER_HEADERS}/${referralId}/laboratory-addresses/${laboratoryId}`);
}

export function deletePatientReferralForMedicalTests(testId: string) {
    return rawHttp.delete(`${DIAGNOSTIC_ORDER_HEADERS}/${testId}`);
}

export function sendPaymentDetailsAndGetUrlToPayU(referralId: string, data: BuyerDataDto) {
    return rawHttp.post<{paymentUrl: string}>(`${DIAGNOSTIC_ORDER_HEADERS}/${referralId}/payment`, data);
}
export function getMedicalTestsPdfResults(id: string){
    return rawHttp.get<MedicalPdfResults[]>(GET_MEDICAL_TEST_PDF_RESULT(id));
}

export interface AppointmentReferralDto {
    id: string;
    type: string;
    medicalPackage: string;
    createDate: string;
    diagnosticOrderResultStatus: string;
    diagnosticServices: AppointmentReferralDiagnosticServiceDto[];
    resultStorageUrls: FileDto[];
}
export interface FileDto{
    storageId: string;
    url:string;
}

export interface AppointmentReferralDiagnosticServiceDto{
    name: string;
    
}

export interface PapTestDetailsDto{
    lastMenstruationDate: Date
    childBirthNum: number;
    additionalInfo: string;
    papTestCollectedFrom: PapTestCollectedFromDto | null;
}

export interface PapTestCollectedFromDto{
    collectedFrom: CollectedFromDto[];
    other: string;
}

export interface CollectedFromDto{
    name: string;
    isSelected: boolean;
}

export function getPapTestCollectedFrom(){
    return rawHttp.get<PapTestCollectedFromDto>(GET_PAP_TEST_COLLECTED_FROM);
}
