import React from 'react';
import useStyles from './EvaluationFinishPageStyles';
import {EmptyTopBar} from '../../components';
import {Box, Grid} from '@material-ui/core';
import Footer from '../../components/Layout/Footer/Footer';
import {EvaluationFinishImage} from '../../assets';
import CustomButton from '../../components/common/Button/Button';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {useHistory} from 'react-router';
import {INDIVIDUAL_PACKAGES_PAGE, USER_ACCOUNT_PAGE} from '../../const/routes';
import useLandingPageUrl from '../../hooks/useLandingPageUrl';

const EvaluationFinishPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.EVALUATION);
    const history = useHistory();
    const {getRoute} = useLandingPageUrl();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={classes.container}
        >
            <EmptyTopBar onCancel={() => history.push(USER_ACCOUNT_PAGE)} />
            <Grid
                container
                direction={'column'}
                alignItems={'center'}
                className={classes.pageContent}
            >
                <Box className={classes.title}>{t('areYouSatisfied')}</Box>
                <Box className={classes.underTitle}>
                    {t('checkOurIndividualPackages')}
                </Box>
                <img
                    src={EvaluationFinishImage}
                    alt={'Evaluation finish'}
                    className={classes.image}
                />
                <CustomButton
                    variant={'contained'}
                    color={'secondary'}
                    className={classes.button}
                    onClick={() =>
                        (window.location.href = getRoute('INDIVIDUAL_PACKAGES'))
                    }
                    fullWidth
                >
                    {t('seeSubscriptions')}
                </CustomButton>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default EvaluationFinishPage;
