import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store/store';
import {AuthProvider} from './components';
import history from './utils/history';
import {HelmetProvider} from 'react-helmet-async';
import {gtmService} from './services/gtm/gtmService';
import sentryService from './services/sentryService';
import {DevSupport} from '@react-buddy/ide-toolbox';
import {ComponentPreviews, useInitial} from './dev';

gtmService.initialize();
sentryService.initialize();

ReactDOM.render(
    // <React.StrictMode>
    <Router history={history}>
        <Provider store={store}>
            <AuthProvider>
                <HelmetProvider>
                    <DevSupport
                        ComponentPreviews={ComponentPreviews}
                        useInitialHook={useInitial}
                    >
                        <App />
                    </DevSupport>
                </HelmetProvider>
            </AuthProvider>
        </Provider>
    </Router>,
    // </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals();
