import {Box, Link} from '@material-ui/core';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {REFRESH_TIME} from '../../../const/commonConstants';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchUpcomingAppointments,
    selectUpcomingAppointmentIds,
} from '../../../store/patientCalendar/upcomingAppointmentsSlice';
import useStyles from '../appointmentsCalendarSectionStyles';
import UpcomingAppointment from '../UpcomingAppointment/UpcomingAppointment';
import useLandingPageUrl from '../../../hooks/useLandingPageUrl';
import configuration from '../../../configuration';
import {APPOINTMENT_SEARCH_WIDGET_PAGE} from '../../../const/routes';

const UpcomingAppointments = () => {
    const dispatch = useAppDispatch();
    const upcomingAppointmentIds = useAppSelector(selectUpcomingAppointmentIds);
    const {getRoute} = useLandingPageUrl();
    const isDev = configuration?.environment === 'Development';

    useEffect(() => {
        dispatch(
            fetchUpcomingAppointments({
                fetchSilently: false,
            }),
        );
        const intervalId = setInterval(() => {
            dispatch(
                fetchUpcomingAppointments({
                    fetchSilently: true,
                }),
            );
        }, REFRESH_TIME);

        return () => {
            clearInterval(intervalId);
        };
    }, [dispatch]);

    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);

    const classes = useStyles();

    return (
        <Box className={classes.appointmentWrapper}>
            <Box>
                <Box className={classes.boxTitle}>
                    {t('upcomingAppointments')}
                    <Link
                        className={classes.rightSideLink}
                        href={
                            isDev
                                ? APPOINTMENT_SEARCH_WIDGET_PAGE
                                : getRoute('SEARCH_FOR_A_DOCTOR')
                        }
                    >
                        {t('bookANewAppointment')}
                    </Link>
                </Box>
            </Box>
            {upcomingAppointmentIds.map(appointmentId => (
                <UpcomingAppointment
                    key={appointmentId}
                    upcomingAppointmentId={appointmentId as number}
                />
            ))}
        </Box>
    );
};

export default UpcomingAppointments;
