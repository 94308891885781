import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
    questionnaireDataList: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 0 8px 0',
    },
    questionnaireDataListItem: {
        flex: '1 0 50%',
        padding: '0 0 8px 0',
        [theme.breakpoints.up('md')]: {
            flex: '0 0 auto',
            width: 'auto',
            padding: '0 16px 8px 0',
        }
    },
    questionnaireDataListCategory: {
        fontWeight: 'bold',
        display: 'inline-block',
        marginRight: '8px',
    },
    questionnaireDataListCategoryNoWrap: {
        fontWeight: 'bold',
        display: 'inline-block',
        marginRight: '8px',
        whiteSpace:'nowrap'
    },
    questionnaireAnswersList: {
        padding: 0,
    },
    questionnaireAnswerListItem: {
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        padding: '16px 0',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    questionnaireAnswerContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        }
    },
    questionnaireDetailsAnswerContainer: {
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'column',
    },
    questionText: {
        margin: '0 0 8px',
        fontSize: '16px',
        [theme.breakpoints.up('md')]: {
            margin: '0 8px 0 0',
        }
    },
    detailsAnswer: {
        marginTop: '2px',
        [theme.breakpoints.up('md')]: {
            marginTop: '6px',
        }
    },

    copyIcon:{
        height:20
    }
}));

export default useStyles;
