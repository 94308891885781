import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '16px 0',
        },
        iconContainer:{
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'center'
        },
        header:{
            fontSize:'20px',
        },
        title:{
            fontWeight:'bold',
            marginRight:'10px'
        }
        
    }),
);

export default useStyles;
