import {ReactNode} from 'react';
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import {TEXT_PRIMARY} from '../../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeaderRoot: {
            color: TEXT_PRIMARY,
            fontSize: 18,
            lineHeight: '24px',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                fontSize: 20,
                lineHeight: '32px',
            },
        },
    }),
);

interface Props {
    children: ReactNode;
}

const SectionHeader = ({children}: Props) => {
    const classes = useStyles();
    return (
        <Box className={classes.sectionHeaderRoot}>
            {children}
        </Box>
    )
};

export default SectionHeader;