import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {BACKGROUND_PRIMARY} from "../../../../const/colors";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        borderTop: `8px solid ${BACKGROUND_PRIMARY}`,
        padding: '16px',
        [theme.breakpoints.up('md')]: {
            margin: '0 47px',
            padding: '16px 0',
            borderTop: `1px solid ${BACKGROUND_PRIMARY}`
        },
    },
    appointmentTimeDetailsBarWrapper: {
        marginBottom: '24px',
    },
    appointmentTimeDetailsSquareWrapper: {
        marginRight: '24px',
    },
    medicAvatar: {
        width: '70px',
        height: '70px',
        [theme.breakpoints.up('md')]: {
            width: '88px',
            height: '88px',
        }
    },
    medicDetails: {
        width: 'auto',
        marginLeft: '16px',
        [theme.breakpoints.up('md')]: {
            marginLeft: '24px',
        }
    },
    medicName: {
        fontSize: '16px',
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            fontSize: '18px',
        }
    },
    accordionDetailsContent: {
        paddingBottom: '8px',
    },
    attachmentsContainer: {
        marginTop: '24px',
    },
    attachmentsHeader: {
        margin: 0,
    },
    attachments: {
        padding: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    },
    attachmentsItem: {
        padding: 0,
        margin: '16px 0 0',
        [theme.breakpoints.up('md')]: {
            margin: '8px 32px 0 0',
        },
    },
}));

export default useStyles;