import {useEffect, useState} from 'react';
import appointmentsProvider from '../services/appointmentsProvider';
import mapAppointment from '../store/mappers/mapAppointment';
import {Appointment} from '../types/appointments';

const useAppointmentInfo = (appointmentId: string) => {
    const [appointmentInfo, setAppointmentInfo] = useState<Appointment>(null);

    useEffect(() => {
        appointmentsProvider
            .getAppointment(appointmentId)
            .then(mapAppointment)
            .then(setAppointmentInfo);
    }, []);

    return appointmentInfo;
};

export default useAppointmentInfo;
