import React, {useState, ChangeEvent, useEffect} from 'react';
import VideoParticipant from '../ParticipantVideo/ParticipantVideo';
import {Room} from 'twilio-video';
import useStyles from './VideoRoomMobileStyles';
import {AccessTime} from '@material-ui/icons';
import {ChatConversation, TabPanel} from '../../../';
import {Badge, Box, Tab, Tabs} from '@material-ui/core';
import {
    PARTICIPANT_HUNG_UP,
    WAITING_FOR_CONNECTION,
} from '../../../../const/conversation';
import clsx from 'clsx';
import ConversationTopBar from '../../topBar/ConversationTopBar';
import {MuteIcon} from '../../../../assets';
import AttachmentConversation from '../../chat/AttachmentConversation/AttachmentsConversation';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Conversation} from '@twilio/conversations/lib/conversation';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../../../store/auth';
import {MEDIC} from '../../../../const/auth';
import useChatEventListener from '../../../../hooks/conversation/useChatEventListener';
import ConsultationInfo from '../../ConsultationInfo/ConsultationInfo';
import {selectAppointmentDetails} from '../../../../store/consultationSlice';
import {useInnerHeightProperty} from '../../../../hooks/useInnerHeightProperty';

interface Props {
    handleHangout: () => void;
    handleVideoTurnOnOff: () => void;
    handleAudioTurnOnOff: () => void;
    videoInputOn?: boolean;
    audioInputOn?: boolean;
    room: Room;
    roomState: string;
    remoteParticipants: JSX.Element[];
    loading?: boolean;
    conversation: Conversation;
    error?: string;
    setError: (error: string) => void;
}

const VideoRoomMobile = ({
    handleHangout,
    handleAudioTurnOnOff,
    handleVideoTurnOnOff,
    videoInputOn,
    audioInputOn,
    room,
    roomState,
    remoteParticipants,
    conversation,
    loading,
    error,
    setError,
}: Props) => {
    const classes = useStyles();
    const [tab, setTab] = useState<number>(0);
    const consultationDetails = useAppSelector(selectAppointmentDetails);
    const authUserData = useAppSelector(selectAuthUserData);
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);

    const {refreshDocumentVhProperty} = useInnerHeightProperty();

    const {messageCounter, resetCounter} = useChatEventListener({
        conversation,
        chatOpen: tab === 1,
    });

    const handleChangeTab = (
        event: ChangeEvent<Record<string, unknown>>,
        newTab: number,
    ) => {
        setTab(newTab);
        refreshDocumentVhProperty();
    };

    useEffect(() => {
        if (tab === 1) resetCounter(); // Reset counter when user is on Chat tab
    }, [tab]);

    useEffect(() => {
        messageCounter > 0 && setTab(1); // Change tab for Chat
    }, [messageCounter]);

    return (
        <div>
            <ConversationTopBar
                handleSwitchVideo={handleVideoTurnOnOff}
                handleSwitchMic={handleAudioTurnOnOff}
                handleHangout={handleHangout}
                videoInputOn={videoInputOn}
                audioInputOn={audioInputOn}
            />
            <Box textAlign={'center'} style={{background: 'white'}}>
                <ConsultationInfo
                    consultation={consultationDetails}
                    inConsultationView
                />
            </Box>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                className={classes.tabs}
                aria-label="tabs"
                variant="fullWidth"
            >
                <Tab label={t('video')} />
                <Tab
                    label={
                        <Badge
                            badgeContent={messageCounter}
                            color={'secondary'}
                        >
                            {t('chat')}
                        </Badge>
                    }
                />
                <Tab
                    label={
                        authUserData.role === MEDIC
                            ? t('notesAndFiles')
                            : t('files')
                    }
                />
            </Tabs>
            <TabPanel value={tab} index={0}>
                {!audioInputOn && (
                    <img
                        src={MuteIcon}
                        alt="mute"
                        aria-label="mute"
                        className={classes.muteIcon}
                    />
                )}
                <VideoParticipant
                    participant={room.localParticipant}
                    className={clsx(classes.video, classes.mirrorImage)}
                />
                {roomState === WAITING_FOR_CONNECTION ||
                roomState === PARTICIPANT_HUNG_UP ? (
                    <Box className={classes.statusScreen}>
                        Oczekiwanie na połączenie...
                        <AccessTime style={{marginTop: 26}} />
                    </Box>
                ) : (
                    remoteParticipants
                )}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ChatConversation
                    conversation={conversation}
                    error={error}
                    setError={setError}
                    loading={loading}
                />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <AttachmentConversation
                    mobileView
                    uploadFileOpen
                    conversation={conversation}
                    error={error}
                    setError={setError}
                    loading={loading}
                />
            </TabPanel>
        </div>
    );
};

export default VideoRoomMobile;
