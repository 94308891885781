import {Box, Snackbar, useMediaQuery, useTheme} from '@material-ui/core';
import {AccessTime} from '@material-ui/icons';
import {Call} from '@twilio/voice-sdk';
import React, {useCallback, useEffect, useState} from 'react';
import {
    DISCONNECTED,
    WAITING_FOR_ANSWERING,
    WAITING_FOR_CONNECTION,
} from '../../../../const/conversation';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';
import ConversationTopBar from '../../topBar/ConversationTopBar';
import useStyles from './CallConversationStyles';
import {
    callErrorSpecified,
    callStatusSpecified,
    selectAppointmentDetails,
    selectAudioInputDevices,
    selectAudioOutputDevices,
    selectCallStatus,
} from '../../../../store/consultationSlice';
import {
    AppointmentFileUploadDesktop,
    AppointmentFileUploadMobile,
    CustomModal,
    MedicAppointmentDetailsAboutPatientTab,
    MedicAppointmentDetailsTabs,
} from '../../..';
import Settings from '../../settings/Settings';
import {callConnectionErrorHandler} from '../../../../utils/error';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Alert} from '@material-ui/lab';
import {CustomInputChangeEvent} from '../../../../types/common';
import {selectAttachmentsMetaData} from '../../../../store/appointmentAttachmentsSlice';
import {
    useGetFileUploadViewModelItems,
    useHandleUploadAsync,
} from '../../../../hooks/appointmentFileUpload';
import {UploadedFileComponentProps} from '../../../../types/fileUpload';
import ConversationProvider from '../../../../services/ConversationProvider';
import axios from 'axios';

interface Props {
    consultationId: string;
    call: Call;
    handleHangout: () => void;
    handleUpdate: () => void;
    setShowSettings: (value: boolean) => void;
    handleAudioTurnOnOff: () => void;
    handleSelectAudioInput: (e: CustomInputChangeEvent) => void;
    handleSelectAudioOutput: (e: CustomInputChangeEvent) => void;
    showSettings: boolean;
    audioInputOn: boolean;
    setAudioDevices: () => void;
}

enum CallingStatus {
    Undefined = 'Undefined',
    Ringing = 'Ringing',
    Answered = 'Answered',
    Completed = 'Completed',
}

const CallConversation = ({
    consultationId,
    call,
    handleHangout,
    handleUpdate,
    setShowSettings,
    handleAudioTurnOnOff,
    handleSelectAudioInput,
    handleSelectAudioOutput,
    showSettings,
    audioInputOn,
    setAudioDevices
}: Props) => {
    const consultationDetails = useAppSelector(selectAppointmentDetails);
    const attachmentsMetaData = useAppSelector(selectAttachmentsMetaData);
    const fileUploadViewModelItems: UploadedFileComponentProps[] =
        useGetFileUploadViewModelItems(attachmentsMetaData, consultationId);
    const handleUploadAsync = useHandleUploadAsync();

    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);

    const audioInputDevices = useAppSelector(selectAudioInputDevices);
    const audioOutputDevices = useAppSelector(selectAudioOutputDevices);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [error, setError] = useState<string>(null);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectCallStatus);
    const [callingStatus, setCallingStatus] = useState<CallingStatus | null>(
        null,
    );

    const gotError = useCallback(
        (error: Error) => {
            dispatch(
                callErrorSpecified(t(callConnectionErrorHandler(error.name))),
            );
        },
        [t, dispatch],
    );

    const handleUpload = (files: File[]) => {
        handleUploadAsync(consultationDetails.id, files);
    };

    useEffect(() => {
        if (call) {
            call.on('accept', () => {
                dispatch(callStatusSpecified(WAITING_FOR_ANSWERING));
                setAudioDevices();
            });
            call.on('disconnect', () => {
                dispatch(callStatusSpecified(DISCONNECTED));
                handleHangout();
            });
            call.on('cancel', () => {
                // connection is cancelled and status is 'closed'
                dispatch(callStatusSpecified(DISCONNECTED));
            });
            call.on('error', error => {
                dispatch(callStatusSpecified(DISCONNECTED));
                gotError(error);
            });
            call.on('reject', () => {
                //connection was rejected nad status is 'closed'
                dispatch(callStatusSpecified(DISCONNECTED));
            });
        }
        return () => {
            call.removeAllListeners();
        };
    }, [call, gotError, dispatch]);

    // Update calling status by CallSid from backend every second
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (call) {
            interval = setInterval(async () => {
                try {
                    const {data} = await ConversationProvider.getCallStatus(
                        call.parameters.CallSid,
                    );
                    setCallingStatus(data);
                } catch (error) {
                    if (
                        axios.isAxiosError(error) &&
                        error.response.status === 404
                    )
                        return;
                    console.error(error);
                    clearInterval(interval);
                }
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [call]);

    return (
        <div>
            <ConversationTopBar
                handleHangout={handleHangout}
                handleSettings={() => setShowSettings(true)}
                handleSwitchMic={handleAudioTurnOnOff}
                audioInputOn={audioInputOn}           
            />

            {status === WAITING_FOR_CONNECTION && (
                <Box className={classes.statusScreen}>
                    Oczekiwanie na połączenie...
                    <AccessTime style={{marginTop: 26}} />
                </Box>
            )}
            {callingStatus === CallingStatus.Ringing && (
                <Box className={classes.statusScreen}>
                    Dzwoni ...
                    <AccessTime style={{marginTop: 26}} />
                </Box>
            )}
            {status === DISCONNECTED && (
                <Box className={classes.statusScreen}>
                    Połączenie zakończone
                    <AccessTime style={{marginTop: 26}} />
                </Box>
            )}
            {callingStatus === CallingStatus.Answered && (
                <MedicAppointmentDetailsTabs
                    openDefaultValue={'NOTES'}
                    appointment={consultationDetails}
                    source={'chat'}
                    aboutPatientTabRender={() => (
                        <MedicAppointmentDetailsAboutPatientTab
                            appointment={consultationDetails}
                        />
                    )}
                    filesTabRender={() =>
                        isMobile ? (
                            <AppointmentFileUploadMobile
                                filesProps={fileUploadViewModelItems}
                                fileUploadHandler={handleUpload}
                                containerStyles={{
                                    minHeight: 'calc(100vh - 112px)',
                                }}
                            />
                        ) : (
                            <AppointmentFileUploadDesktop
                                filesProps={fileUploadViewModelItems}
                                fileUploadHandler={handleUpload}
                                showHeader={false}
                            />
                        )
                    }
                />
            )}

            <CustomModal
                open={showSettings}
                onClose={() => setShowSettings(false)}
                onCloseButtonClick={() => setShowSettings(false)}
            >
                {audioInputDevices && audioOutputDevices && (
                    <Settings
                        approve={handleUpdate}
                        handleSelectAudioInput={handleSelectAudioInput}
                        handleSelectAudioOutput={handleSelectAudioOutput}
                        handleAudioTurnOnOff={handleAudioTurnOnOff}
                        inAppointment={true}
                    />
                )}
            </CustomModal>
            <Snackbar
                open={!!error}
                autoHideDuration={5000}
                onClose={() => setError('')}
            >
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default CallConversation;
