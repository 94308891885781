import {makeStyles} from "@material-ui/core";
import { RED } from "../../../const/colors";

const useStyles = makeStyles(() => ({

    fileList: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0,
        marginBottom: '32px',
    },
    fileListItem: {
        flex: '1 0 50%',
        padding: 0,
    },
    info:{
        paddingLeft:8
    },
    loadingContainer:{
        textAlign:'center'
    }

}));

export default useStyles;