import {memo} from 'react';
import {Box, createStyles, Grid, Hidden, makeStyles, Theme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Container} from '../../../../components';
import {WHITE} from '../../../../const/colors';
import {WomanWithPhone} from '../../../../assets';
import {MedicalTestsOrderDetailsDto} from '../../../../services/diagnostic/orderHeadersProvider';
import {getUserName} from "../../../../utils/user";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orderDetailsRoot: {
            backgroundColor: WHITE,
        },
        headerText: {
            fontSize: 36,
            lineHeight: '48px',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                fontSize: 56,
                lineHeight: '64px',
            }
        },
        paymentImage: {
            width: 150,
        },
        orderDetails: {
            marginTop: 56,
            fontSize: 16,
            lineHeight: '24px',
            [theme.breakpoints.up('md')]: {
                fontSize: 20,
            }
        },
    }),
);

interface Props {
    orderDetails: MedicalTestsOrderDetailsDto;
}

const Header = memo(function MedicalSearchFilters({orderDetails}: Props) {
    const classes = useStyles();
    const {t: tPayments} = useTranslation(i18nNamespaces.TIME_SLOTS_PAYMENT);
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);
    const {t: tAppointment} = useTranslation(i18nNamespaces.APPOINTMENT);
    const diagnosticServiceLines = orderDetails?.diagnosticServiceLilnes;
    return (
        <Box className={classes.orderDetailsRoot}>
            <Container mdDesktop>
                <Grid container>
                    <Grid item xs>
                        <Box className={classes.headerText}>{tPayments('payment')}</Box>
                        <Box className={classes.orderDetails}>
                            <Grid container>
                                <Grid item style={{marginRight: '0.5em'}}>
                                    {tReferrals('medicalTest')}:
                                </Grid>
                                {
                                    diagnosticServiceLines?.map((diagnosticServiceLine) => (
                                        <Grid
                                            item
                                            key={diagnosticServiceLine.diagnosticServiceId}
                                            style={{
                                                marginRight: '0.5em'
                                            }}
                                        >
                                            {diagnosticServiceLine.diagnosticServiceName}
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            {orderDetails && `${orderDetails.laboratoryAddress}, ${orderDetails.laboratoryCity}`}
                            <br />
                            <span style={{whiteSpace: 'nowrap'}}>
                                {tAppointment('patient')}:{' '}
                                {orderDetails && getUserName(orderDetails.patientName, orderDetails.patientSurname)}
                            </span>
                        </Box>
                    </Grid>
                    <Grid item xs="auto">
                        <Hidden smDown>
                            <img
                                src={WomanWithPhone}
                                alt="Payment image"
                                className={classes.paymentImage}
                            />
                        </Hidden>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
});

export default Header;