import {useHistory} from 'react-router';
import {Grid, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {
    AppointmentSettleStatusLabel,
    AppointmentStateLabel,
    Button,
    GoBackIconButton,
} from '../../../index';
import useStyles from './DetailsHeaderDesktopStyles';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {
    Appointment,
    AppointmentStateEnum,
} from '../../../../types/appointments';
import {MEDIC_CALENDAR} from '../../../../const/routes';
import React, {ReactNode} from 'react';
import {getRelativeDay} from '../../../../utils/date';

interface Props {
    appointment: Appointment;
    joinAppointmentButton: ReactNode;
    endAppointmentAheadOfTimeButton: ReactNode;
    settleAppointmentButton: ReactNode;
    onCancelAppointment: () => void;
}

const DetailsHeaderDesktop = ({
    appointment,
    joinAppointmentButton,
    endAppointmentAheadOfTimeButton,
    settleAppointmentButton,
    onCancelAppointment,
}: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const appointmentState = appointment.state.state;

    return (
        <Grid container className={classes.headerContainer}>
            <Box className={classes.headerContainerLeftItem}>
                <GoBackIconButton
                    onClick={() => history.push(MEDIC_CALENDAR)}
                />
                <Box>
                    <Box className={classes.patientNameAndStatusesContainer}>
                        <h3 className={classes.patientName}>
                            {appointment.child !== null
                                ? `${appointment.child.firstName} ${appointment.child.surname}`
                                : `${appointment.patient.firstName} ${appointment.patient.surname}`}
                        </h3>
                        <AppointmentStateLabel
                            state={appointmentState}
                            className={classes.stateLabel}
                        />
                        {
                            <AppointmentSettleStatusLabel
                                isSettled={appointment.state.isSettled}
                                className={classes.stateLabel}
                            />
                        }
                    </Box>
                    <Box className={classes.appointmentDetails}>
                        {appointment.medicalService.name}
                        <span className={classes.detailsSeparationDot} />
                        {appointment && getRelativeDay(appointment.startDate)}
                        {` ${appointment.startDate.toFormat(
                            "HH':'mm",
                        )} - ${appointment.endDate.toFormat("HH':'mm")}`}
                    </Box>
                </Box>
            </Box>
            <Box className={classes.headerContainerRightItem}>
                {
                    appointmentState === AppointmentStateEnum.Planned && (
                        <Button
                            className={`${classes.actionButton}`}
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={onCancelAppointment}
                        >
                            {t('cancelAppointment')}
                        </Button>
                    )
                }
                {endAppointmentAheadOfTimeButton}
                {joinAppointmentButton}
                {settleAppointmentButton}
            </Box>
        </Grid>
    );
};

export default DetailsHeaderDesktop;
