import { Box, Button, TextField, Typography } from '@material-ui/core';
import { Loading } from '../../components';
import useStyles from './VoucherPageStyles';
import i18nNamespaces from '../../const/i18nNamespaces';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { USER_ACCOUNT_PAGE } from '../../const/routes';
import { isNotNullOrUndefined } from '../../utils/lang';
import voucherApi from '../../api/vouchers/voucherApi';
import { AxiosError } from 'axios';



const VoucherPage = () => {

    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const classes = useStyles();
    const [code, setCode] = useState<string>('');

    const {t} = useTranslation(i18nNamespaces.SETTINGS);

    const onSubmit =  async (e: FormEvent) => {
        e.preventDefault();

        // setLoading(true);
        try{

            const isSuccess = await voucherApi.addVoucher({voucherCode:code});
           
            if(isSuccess)
                history.push(USER_ACCOUNT_PAGE);
            else
                setError(t('VoucherActivationError'));

        }
        catch (error) {
            handleError(error as AxiosError);
        }
    };

    const handleError = (error: AxiosError) => {
        setError(t(error.response.data[0]));
        setLoading(false);
    };

    const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
        setError('');
    };

    return (
        <div className={classes.container}>
            <Loading loading={loading} withBackdrop />
            <Box
                py={3}
                justifyContent="center"
                alignItems="center"
                display={'flex'}
                flexDirection="column"
            >
                <Typography variant={'h3'}>
                    {t('enterVoucherCode')}
                </Typography>
                <Box component="span" pt={2} pb={4}>
                    {t('enterReceivedCodeToActivatePackage')}
                </Box>
                <form onSubmit={onSubmit}>
                    <Box mb={2}>
                        <TextField
                            className={classes.input}
                            size={'small'}
                            label={t('code')}
                            type="text"
                            id="emailCode"
                            variant="outlined"
                            value={code}
                            onChange={onCodeChange}
                            autoComplete="off"
                            error={!!error}
                            helperText={error && error}
                            autoFocus
                        />
                    </Box>
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        className={classes.button}
                        disabled={!code}
                    >
                        {t('next')}
                    </Button>
                </form>
            </Box>
        </div>
    );
};

export default VoucherPage;
