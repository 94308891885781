import {
    PatientAppointmentDetails,
    PatientAppointmentAdditionalDetails,
} from '../../components';
import {IconButton} from '@material-ui/core';
import {Grid, Box} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    clearAppointmentDetailsState,
    fetchAppointmentDetails,
    selectAppointmentDetails,
    selectAppointmentStatus,
    synchronizeAppointmentDetails,
} from '../../store/appointmentDetailsSlice';
import {Loading} from '../../components';
import {GoBackIcon} from '../../assets';
import {useHistory} from 'react-router';
import {APPOINTMENT_CALENDAR} from '../../const/routes';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import useStyles from './PatientAppointmentDetailsPageStyles';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {
    clearAttachmentsMetaData,
    selectAllAttachmentsMetaDataStatus,
} from '../../store/appointmentAttachmentsSlice';

const PatientAppointmentDetailsPage = () => {
    const history = useHistory();
    const {id: appointmentId} = useParams<{id: string}>();
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const appointment = useAppSelector(selectAppointmentDetails);
    const medicalAppointmentStatus = useAppSelector(selectAppointmentStatus);
    const allAttachmentsMetaDataStatus = useAppSelector(
        selectAllAttachmentsMetaDataStatus,
    );
    const loading =
        medicalAppointmentStatus === 'loading' ||
        allAttachmentsMetaDataStatus === 'loading';

    useEffect(() => {
        dispatch(
            fetchAppointmentDetails({
                appointmentId: appointmentId,
                fetchSilently: false,
            }),
        );
        const intervalId = setInterval(() => {
            dispatch(
                synchronizeAppointmentDetails({
                    appointmentId: parseInt(appointmentId),
                }),
            );
        }, 30000);
        return () => {
            clearInterval(intervalId);
            dispatch(clearAppointmentDetailsState());
            dispatch(clearAttachmentsMetaData());
        };
    }, [dispatch, appointmentId]);

    return (
        <Box>
            <Loading withBackdrop={true} loading={loading} />
            <Grid container>
                <Grid item md={6} xs={12} className={classes.pageLeftColumn}>
                    <Box
                        className={classes.goBackWrapper}
                        onClick={() =>
                            history.push(`/account/${APPOINTMENT_CALENDAR}`)
                        }
                    >
                        <IconButton className={classes.goBackButton}>
                            <img src={GoBackIcon} alt="Go back" />
                        </IconButton>
                        {t('appointmentDetails')}
                    </Box>
                    {appointment && (
                        <PatientAppointmentDetails appointment={appointment} />
                    )}
                </Grid>
                <Grid item md={6} xs={12}>
                    {appointment && (
                        <PatientAppointmentAdditionalDetails
                            appointment={appointment}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default PatientAppointmentDetailsPage;
