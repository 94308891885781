import {useEffect, useState} from 'react';
import {Conversation} from '@twilio/conversations/lib/conversation';
import {useAppSelector} from '../customReduxHooks';
import {selectAuthUserData} from '../../store/auth';

type UseChatEventListenerProps = {
    conversation: Conversation;
    chatOpen: boolean;
};

const useChatEventListener = ({
    conversation,
    chatOpen,
}: UseChatEventListenerProps) => {
    const [messageCounter, setMessageCounter] = useState(0);
    const authUserData = useAppSelector(selectAuthUserData);

    const isUserTheSender = (author: string) => {
        return authUserData ? author === authUserData.userId : false;
    };

    const resetCounter = () => {
        setMessageCounter(0);
    };

    useEffect(() => {
        if (conversation) {
            // any is used for access to message.state
            conversation.on('messageAdded', (message: any) => {
                if (!isUserTheSender(message.state.author) && !chatOpen)
                    setMessageCounter(prev => prev + 1);
            });

            return () => {
                conversation.removeAllListeners();
                resetCounter();
            };
        }
    }, [conversation, chatOpen]);

    return {messageCounter, resetCounter};
};

export default useChatEventListener;
