import {IconButton, makeStyles} from '@material-ui/core';
import {GoBackIcon} from '../../../assets';
import {BACKGROUND_SECONDARY} from '../../../const/colors';
import {memo} from "react";

const useStyles = makeStyles(() => ({
    goBackButton: {
        backgroundColor: BACKGROUND_SECONDARY,
        width: 40,
        height: 40,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        marginRight: 17,
    }
}));

interface Props {
    onClick?: () => void
}

const GoBackIconButton = memo(function GoBackIconButton({onClick}: Props) {
    const classes = useStyles();
    return (
        <IconButton
            className={classes.goBackButton}
            onClick={onClick}
        >
            <img src={GoBackIcon} alt="Go back" />
        </IconButton>
    );
});

export default GoBackIconButton;