import {
    Box,
    Divider,
    Grid,
    Hidden, Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import {ArrowForwardIos, RemoveCircleOutline} from '@material-ui/icons';
import {DateTime} from 'luxon';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {WHITE} from '../../../const/colors';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchMedicCalendarEntries,
    selectMedicCalendarEntryById,
} from '../../../store/medicCalendarSlice';
import useStyles from './MedicCalendarItemStyles';
import {ERROR, MEDIC_APPOINTMENT_DETAILS_PAGE} from '../../../const/routes';
import AppointmentStateLabel from '../../common/Appointment/AppointmentStateLabel/AppointmentStateLabel';
import {AppointmentSettleStatusLabel} from '../../index';
import timeSlotsProvider from '../../../services/timeSlotsProvider';
import {tokenResponseRefreshTime} from '../../../const/commonConstants';
import axios from 'axios';
import {removeTimeSlotErrorHandler} from '../../../utils/error';
import {isNotNullOrUndefined} from "../../../utils/lang"

type MedicCalendarItemProps = {
    medicCalendarEntryId: string;
    setRemovingTimeSlot: (removing: boolean) => void;
    setMessage: (message: string) => void;
};

const MedicCalendarItem = ({
    medicCalendarEntryId,
    setRemovingTimeSlot,
    setMessage,
}: MedicCalendarItemProps) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isLargeDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'))
    const medicCalendarEntry = useAppSelector(state =>
        selectMedicCalendarEntryById(state, medicCalendarEntryId),
    );


    const {t} = useTranslation(i18nNamespaces.MEDIC_CALENDAR);

    const theme = useTheme();

    const currentDateTime = DateTime.now();

    const isAppointment = !!medicCalendarEntry.appointmentId;
    const entryStartDateTime = medicCalendarEntry.startDate;
    const entryEndDateTime = medicCalendarEntry.endDate;
    const isInTheFuture = entryStartDateTime > currentDateTime;
    const isToday =
        entryStartDateTime.day === currentDateTime.day &&
        entryStartDateTime.month === currentDateTime.month &&
        entryStartDateTime.year === currentDateTime.year;
    const isNow =
        entryStartDateTime <= currentDateTime &&
        entryEndDateTime >= currentDateTime;
    const isPaid = medicCalendarEntry.paymentStatus === "Paid";

    const renderAppointmentStatuses = () => {
        return (
            <Box className={classes.statusesContainer}>
                <AppointmentStateLabel
                    state={medicCalendarEntry.appointmentState.state}
                />
                {medicCalendarEntry.appointmentState !== null && (
                    <AppointmentSettleStatusLabel
                        className={classes.appointmentSettleStatusLabel}
                        isSettled={
                            medicCalendarEntry.appointmentState.isSettled
                        }
                    />
                )}
            </Box>
        );
    };


    const renderAppointmentReservedStatus = () =>{
        return (
            <Box className={classes.statusesContainer}>
                <Box className={classes.bookedLabel}>
                    {t('bookedAndPaymentIsProgress')}
                </Box>
            </Box>
        );
    }

    const entryClickHandler = () => {
        if (isAppointment) {
            history.push(
                `${MEDIC_APPOINTMENT_DETAILS_PAGE}/${medicCalendarEntry.appointmentId}`,
            );
        }
    };

    const removeTimeSlotHandler = async () => {
        setRemovingTimeSlot(true);
        const token = await timeSlotsProvider.sendDeleteMedicTimeSlotRequest(
            medicCalendarEntryId,
        );
        // The time slot removal is implemented asynchronously on the back-end side.
        // We need to keep querying the server until we know that the operation has finished.
        const intervalId = setInterval(async () => {
            try {
                await timeSlotsProvider.retrieveDeleteMedicTimeSlotResult(
                    token,
                );

                clearInterval(intervalId);
                setRemovingTimeSlot(false);
                dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    if (e.response.status === 400) {
                        clearInterval(intervalId);
                        setRemovingTimeSlot(false);
                        dispatch(
                            fetchMedicCalendarEntries({fetchSilently: false}),
                        );

                        const errorCode = e.response.data[0] as string;
                        setMessage(t(removeTimeSlotErrorHandler(errorCode)));
                    } else if (e.response.status !== 404) {
                        clearInterval(intervalId);
                        setRemovingTimeSlot(false);
                        history.push(ERROR);
                    }
                } else {
                    clearInterval(intervalId);
                    setRemovingTimeSlot(false);
                    history.push(ERROR);
                }
            }
        }, tokenResponseRefreshTime);
    };

    const minute =
        (entryStartDateTime.minute < 10 ? '0' : '') + entryStartDateTime.minute;

    const headerLabel = (
        isNow ? t('now') : isToday ? t('today') : ''
    ).toUpperCase();

    const classes = useStyles();

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.calendarEntryWrapper}
            >
                <Grid item className={classes.calendarEntryHeaderWrapper}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.calendarEntryHeaderContainer}
                        style={{
                            backgroundColor: isNow
                                ? theme.palette.secondary.main
                                : isInTheFuture
                                ? theme.palette.grey[300]
                                : !isAppointment
                                ? theme.palette.grey[300]
                                : theme.palette.primary.main,
                        }}
                    >
                        <Hidden smUp>
                            <Box
                                className={classes.calendarEntryHeaderLabel}
                                style={{
                                    color: isInTheFuture ? null : WHITE,
                                }}
                            >
                                {headerLabel}
                            </Box>
                        </Hidden>
                        <Box
                            className={classes.calendarEntryHeaderTime}
                            style={{
                                color: !isAppointment
                                    ? theme.palette.grey[600]
                                    : isInTheFuture
                                    ? theme.palette.secondary.main
                                    : WHITE,
                            }}
                        >
                            {entryStartDateTime.hour}:{minute}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item className={classes.calendarEntryContentWrapper}>
                    {isAppointment ? (
                        <Grid container direction="row" alignItems="center">
                            <Grid
                                item
                                className={classes.calendarEntryPatientGridItem}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Box
                                        className={classes.calendarEntryPatient}
                                    >
                                        {medicCalendarEntry.patient.firstName}{' '}
                                        {medicCalendarEntry.patient.surname}{' '}
                                        {isNotNullOrUndefined(medicCalendarEntry.patient.age) 
                                            ? `(${medicCalendarEntry.patient.age} ${t('yearsOld')})`
                                            : null 
                                            
                                        }
                                    </Box>
                                    <Box>
                                        {medicCalendarEntry.medicalService.name}
                                        {medicCalendarEntry.referralCreator && (
                                        <span>
                                            {`  (${t('referralFrom')}: ${medicCalendarEntry.referralCreator.firstName} ${medicCalendarEntry.referralCreator.surname})`}
                                        </span>
                                    )}
                                    </Box>
                                </Grid>
                            </Grid>
                            {isAppointment && isLargeDesktop && (
                                (isPaid ?  
                                <Grid item>{renderAppointmentStatuses()}</Grid> :
                                  <Grid item>{renderAppointmentReservedStatus()}</Grid>)
                               
                            )}
                            {isPaid && 
                                <Grid item>
                                    <Box>
                                        <ArrowForwardIos
                                            onClick={entryClickHandler}
                                            style={{
                                                cursor: 'pointer',
                                                color: isNow
                                                    ? theme.palette.secondary.main
                                                    : theme.palette.grey[300],
                                            }}
                                        />
                                    </Box>
                                </Grid>}
                            
                        </Grid>
                    ) : (
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.noArrangedAppointment}
                        >
                            <Box>{t('noArrangedAppointment')}</Box>
                            <Box
                                className={classes.removeTimeSlot}
                                onClick={removeTimeSlotHandler}
                            >
                                <RemoveCircleOutline />
                            </Box>
                        </Grid>
                    )}
                </Grid>
                {isAppointment &&
                    !isLargeDesktop &&
                    (isPaid ? renderAppointmentStatuses() : renderAppointmentReservedStatus())}
                <Grid item className={classes.separatorWrapper}>
                    <Divider variant="fullWidth" />
                </Grid>
            </Grid>
        </>
    );
};

export default MedicCalendarItem;
