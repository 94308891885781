import {Box, Grid} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import useStyles from './MedicPictureChangeTileMobileStyles';
import useTileStyles from '../../common/tileCommonStyles'
import i18nNamespaces from "../../../../const/i18nNamespaces";
import MedicPicture from "../MedicPicture/MedicPicture";
import EditPictureActionButton from "../EditPictureActionButton/EditPictureActionButton";

const MedicPictureChangeTileMobile = () => {
    const classes = useStyles();
    const tileClasses = useTileStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);

    return (
        <Grid
            container
            className={tileClasses.root}
        >
            <Grid
                item
                className={classes.leftContainer}
            >
                <h3 className={tileClasses.headerText}>
                    {commonT('pictureOfYou')}
                </h3>
                <Box className={classes.actionButtonsWrapper}>
                    <EditPictureActionButton />
                </Box>
            </Grid>
            <Grid item>
                <MedicPicture />
            </Grid>
        </Grid>
    );
}

export default MedicPictureChangeTileMobile;