import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import appointmentsProvider from '../../services/appointmentsProvider';
import {Service} from '../../types/timeSlot';
import medicsProvider from '../../services/medicsProvider';
import {PatientReferralMedic, Tag} from '../../types/medic';
import {Appointment} from '../../types/appointments';
import mapAppointment from '../mappers/mapAppointment';
import {referralApi} from '../../api/referral/referralApi';

type FetchStatus = 'idle' | 'loading' | 'failed';

export interface PatientReferralState {
    appointment: Appointment | null;
    appointmentStatus: FetchStatus;
    medicalServices: Service[];
    medicalServicesStatus: FetchStatus;
    tags: Tag[];
    tagsStatus: FetchStatus;
    selectedMedicalServiceId: number | null;
    selectedTags: Tag[];
    medics: PatientReferralMedic[];
    medicsStatus: FetchStatus;
    selectedMedic: PatientReferralMedic | null;
}

const initialState: PatientReferralState = {
    appointment: null,
    appointmentStatus: 'idle',
    medicalServices: [],
    medicalServicesStatus: 'idle',
    tags: [],
    tagsStatus: 'idle',
    selectedMedicalServiceId: null,
    selectedTags: [],
    medics: [],
    medicsStatus: 'idle',
    selectedMedic: null,
};

export const fetchAppointment = createAsyncThunk(
    'patientReferral/fetchAppointment',
    async (id: number) => {
        const appointment = await appointmentsProvider.getAppointment(
            `${id}`,
            false,
        );
        return mapAppointment(appointment);
    },
);

export const fetchMedicalServices = createAsyncThunk(
    'patientReferral/fetchMedicalServices',
    async () => {
        const {data} = await referralApi.getReferralAdditionalData();
        return data?.medicalServices;
    },
);

export const fetchTags = createAsyncThunk(
    'patientReferral/fetchTags',
    async () => {
        return medicsProvider.fetchTags();
    },
);

export const fetchMedics = createAsyncThunk<
    PatientReferralMedic[],
    {medicalServiceId: number; tagIds: number[]},
    unknown
>('patientReferral/fetchMedics', async ({medicalServiceId, tagIds}) => {
    return await medicsProvider.fetchMedicsByMedicalServiceAndTags(
        medicalServiceId,
        tagIds,
    );
});

export const makePatientReferralSlice = createSlice({
    name: 'patientReferral',
    initialState,
    reducers: {
        resetMedics: state => {
            state.medics = initialState.medics;
            state.selectedMedic = initialState.selectedMedic;
        },
        resetTags: state => {
            state.selectedTags = initialState.selectedTags;
        },
        setSelectedMedicalServiceId: (
            state,
            action: PayloadAction<number | null>,
        ) => {
            state.selectedMedicalServiceId = action.payload;
        },
        setSelectedTags: (state, action: PayloadAction<Tag[]>) => {
            state.selectedTags = action.payload;
        },
        selectDefaultMedic: state => {
            state.selectedMedic = state.medics[0] || null;
        },
        selectMedic: (state, action: PayloadAction<string>) => {
            state.selectedMedic = state.medics.find(
                medic => medic.id === action.payload,
            );
        },
        unselectMedic: state => {
            state.selectedMedic = null;
        },
        resetState: () => initialState,
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAppointment.pending, state => {
                state.appointmentStatus = 'loading';
            })
            .addCase(fetchAppointment.fulfilled, (state, action) => {
                state.appointmentStatus = 'idle';
                state.appointment = action.payload;
            });

        builder
            .addCase(fetchMedicalServices.pending, state => {
                state.medicalServicesStatus = 'loading';
            })
            .addCase(fetchMedicalServices.fulfilled, (state, action) => {
                state.medicalServicesStatus = 'idle';
                state.medicalServices = action.payload;
            });

        builder
            .addCase(fetchTags.pending, state => {
                state.tagsStatus = 'loading';
            })
            .addCase(fetchTags.fulfilled, (state, action) => {
                state.tagsStatus = 'idle';
                state.tags = action.payload;
            });

        builder
            .addCase(fetchMedics.pending, state => {
                state.medicsStatus = 'loading';
            })
            .addCase(fetchMedics.fulfilled, (state, action) => {
                state.medicsStatus = 'idle';
                state.medics = action.payload;
            });
    },
});

export const {
    resetMedics,
    resetTags,
    setSelectedMedicalServiceId,
    setSelectedTags,
    selectDefaultMedic,
    selectMedic,
    unselectMedic,
    resetState,
} = makePatientReferralSlice.actions;

export const selectAppointment = (state: RootState) =>
    state.makePatientReferral.appointment;

export const selectAppointmentStatus = (state: RootState) =>
    state.makePatientReferral.appointmentStatus;

export const selectMedicalServices = (state: RootState) =>
    state.makePatientReferral.medicalServices;

export const selectMedicalServicesStatus = (state: RootState) =>
    state.makePatientReferral.medicalServicesStatus;

export const selectTags = (state: RootState) => state.makePatientReferral.tags;

export const selectTagsStatus = (state: RootState) =>
    state.makePatientReferral.tagsStatus;

export const selectSelectedMedicalServiceId = (state: RootState) =>
    state.makePatientReferral.selectedMedicalServiceId;

export const selectSelectedTags = (state: RootState) =>
    state.makePatientReferral.selectedTags;

export const selectSelectedMedic = (state: RootState) =>
    state.makePatientReferral.selectedMedic;

export const selectMedics = (state: RootState) =>
    state.makePatientReferral.medics;

export const selectMedicsStatus = (state: RootState) =>
    state.makePatientReferral.medicsStatus;

export default makePatientReferralSlice.reducer;
