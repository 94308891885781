import {makeStyles, Theme} from '@material-ui/core';
import {LIGHT_GRAY, PRIMARY, WHITE} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    video: {
        margin: 0,
        height: 'auto',
        width: 341,
        display: 'block',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        transform: `rotateY(180deg)`,
        [theme.breakpoints.down('md')]: {
            width: 200,
        },
    },
    disabledVideo: {
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: PRIMARY,
        width: 341,
        height: 256,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: LIGHT_GRAY,
    },
    switchButton: {
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        height: 104,
        width: '100%',
        cursor: 'pointer',
        textTransform: 'none',
    },
    switchButtonContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    underCameraSettingsWrapper: {
        backgroundColor: WHITE,
        height: 104,
        display: 'flex',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        alignItems: 'center',
    },
    settingsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 400,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    verticalLine: {
        width: 1,
        height: 88,
        backgroundColor: LIGHT_GRAY,
    },
    label: {
        marginBottom: 8,
    },
    select: {
        width: '100%',
        marginBottom: 24,
    },
    error: {
        color: 'red',
        paddingTop: 32,
    },
}));

export default useStyles;
