import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {GRAY, MAGENTA, WHITE} from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            backgroundColor: WHITE,
            paddingLeft: 30,
            paddingRight: 30,
        },
        title: {marginTop: 32, fontSize: 36, paddingLeft: 16, paddingRight: 16},
        description: {
            maxWidth: 820,
            marginTop: 56,
            marginBottom: 80,
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16,
        },

        information: {
            maxWidth: 820,
            marginTop: 56,
            marginBottom: 80,
            fontSize: 14,
            paddingLeft: 16,
            paddingRight: 16,
            color: GRAY,
        },
        buyButton: {
            marginTop: '40px',
            width: '328px',
        },
        signInButton: {
            width: '328px',
            marginTop: '16px',
            fontWeight: 'bold',
            color: MAGENTA,
            backgroundColor: WHITE,
            borderColor: MAGENTA,
            border: '1px solid',
        },
    }),
);

export default useStyles;
