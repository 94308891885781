import {createStyles, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        paymentCardForm: {
            margin: '20px 0',
        },
        buyerDataForm: {
            paddingTop: 20,
        },
    }),
);

export default useStyles;
