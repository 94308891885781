import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {NoTimeSlotsImage} from '../../assets';
import i18nNamespaces from '../../const/i18nNamespaces';
import useStyles from './EmptyTimeSlotsPageStyles';
import useLandingPageUrl from '../../hooks/useLandingPageUrl';
import {PhoneNumberField} from '../../components';
import {useFormik} from 'formik';
import * as yup from 'yup';
import timeSlotsProvider from '../../services/timeSlotsProvider';
import {NewTabLink} from '../../components/common/navigation';
import {TERMS_AND_CONDITIONS_PAGE} from '../../const/routes';
import {isValidPhoneNumber} from 'react-phone-number-input';

type FormValues = {
    phoneNumber: string;
    acceptPolicy: boolean;
};

type LocationState = {
    medicalServiceId: number;
    startDate?: string;
    endDate?: string;
};

const REQUIRED_STRING = 'requiredField';

const EmptyTimeSlotsPage = () => {
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useStyles();
    const {getCurrentUrl} = useLandingPageUrl();
    const {state} = useLocation<LocationState>();
    const [formSent, setFormSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const formik = useFormik({
        initialValues: {
            phoneNumber: '',
            acceptPolicy: false,
        },
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .test('isValidNumber', 'invalidNumber', value =>
                    isValidPhoneNumber(value),
                )
                .min(12, 'tooShortPhoneNumber')
                .required(commonT(REQUIRED_STRING)),
            acceptPolicy: yup.boolean().oneOf([true], commonT(REQUIRED_STRING)),
        }),
        onSubmit: async (values: FormValues) => {
            if (!values.acceptPolicy) return;
            try {
                setLoading(true);
                await timeSlotsProvider.savePatientContactData({
                    phoneNumber: values.phoneNumber,
                    medicalServiceId: state?.medicalServiceId,
                    startDate: state?.startDate,
                    endDate: state?.endDate,
                });
                setFormSent(true);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
    });

    const handlePhoneNumberChange = (value: string) =>
        formik.handleChange({
            target: {
                value,
                id: 'phoneNumber',
                name: 'phoneNumber',
            },
        });

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.errorWrapper}
        >
            <Box className={classes.errorTitle}>
                {t('noAvailableSpecialists')}
            </Box>
            <Box style={{display: 'flex', alignItems: 'center'}}>
                <img
                    src={NoTimeSlotsImage}
                    alt={t('error')}
                    className={classes.errorImage}
                />
            </Box>
            {!formSent ? (
                <>
                    <Box className={classes.errorContent}>
                        {t('noAvailableSpecialistsDescription')}
                    </Box>
                    <form
                        className={classes.contactInfo}
                        onSubmit={formik.handleSubmit}
                    >
                        <PhoneNumberField
                            className={classes.phoneNumber}
                            onChange={handlePhoneNumberChange}
                            id="phoneNumber"
                            name="phoneNumber"
                            onBlur={formik.handleBlur}
                            value={formik.values.phoneNumber}
                            helperText={
                                formik.touched.phoneNumber &&
                                t(formik.errors.phoneNumber)
                            }
                            error={
                                formik.touched.phoneNumber &&
                                !!formik.errors.phoneNumber
                            }
                            defaultCountry="PL"
                            fullWidth={!isDesktop}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="acceptPolicy"
                                    name="acceptPolicy"
                                    checked={formik.values.acceptPolicy}
                                    onChange={formik.handleChange}
                                />
                            }
                            label={
                                <Box
                                    className={classes.privacyPolicyDeclaration}
                                >
                                    <Trans
                                        t={t}
                                        i18nKey="privacyPolicyDeclaration"
                                        components={[
                                            <NewTabLink
                                                key={'0'}
                                                href={TERMS_AND_CONDITIONS_PAGE}
                                                className={
                                                    classes.termsAndConditionLink
                                                }
                                            />,
                                        ]}
                                    />
                                </Box>
                            }
                        />
                        <FormHelperText
                            error={
                                formik.touched.acceptPolicy &&
                                !!formik.errors.acceptPolicy
                            }
                        >
                            {formik.touched.acceptPolicy &&
                                t(formik.errors.acceptPolicy)}
                        </FormHelperText>
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            className={classes.errorButton}
                            disableElevation
                            type={'submit'}
                            style={{marginTop: 18}}
                            disabled={loading}
                        >
                            {t('send')}
                        </Button>
                    </form>
                </>
            ) : (
                <Box className={classes.errorContent}>
                    {t('thankYouForContact')}
                </Box>
            )}

            <Button
                color={'secondary'}
                className={classes.errorButton}
                disableElevation
                onClick={() => {
                    window.location.href = getCurrentUrl();
                }}
            >
                {t('chooseOtherMedicalService')}
            </Button>
        </Grid>
    );
};

export default EmptyTimeSlotsPage;
