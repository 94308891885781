import {
    Box,
    Grid,
    Hidden,
    Modal,
    Snackbar,
    Typography,
    useTheme,
} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchMedicCalendarEntries,
    historicalFlagSpecified,
    nextSelectedDateSpecified,
    previousSelectedDateSpecified,
    selectMedicCalendarHistoricalFlag,
    selectMedicCalendarSelectedDate,
    selectNumberOfMedicCalendarAppointments,
} from '../../../store/medicCalendarSlice';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {DateTime} from 'luxon';
import getMonthName from '../../../utils/getMonthName';
import {
    ArrowBackIos,
    ArrowForwardIos,
    Delete,
    History,
    Notifications,
} from '@material-ui/icons';
import getDateWithoutTime from '../../../utils/getDateWithoutTime';
import useStyles from './MedicCalendarHeaderStyles';
import {Button, CustomModal, Loading} from '../..';
import {RED, WHITE} from '../../../const/colors';
import {useHistory} from 'react-router';
import {MEDIC_CALENDAR_DATE_SELECTION} from '../../../const/routes';
import {useState} from 'react';
import timeslotsApi from '../../../api/timeslots/timeslotsApi';
import {Alert} from '@material-ui/lab';
import {TIME_SLOT_RESERVED} from '../../../const/error';
import {deleteMedicTimeslotsErrorHandler} from '../../../utils/error';

const MedicCalendarHeader = () => {
    const history = useHistory();

    const dispatch = useAppDispatch();

    const selectedDate = useAppSelector(selectMedicCalendarSelectedDate);

    const numberOfAppointments = useAppSelector(
        selectNumberOfMedicCalendarAppointments,
    );

    const historical = useAppSelector(selectMedicCalendarHistoricalFlag);

    const selectDate = () => {
        history.push(MEDIC_CALENDAR_DATE_SELECTION);
    };

    const nextDateHandler = () => {
        dispatch(nextSelectedDateSpecified());
        dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
    };

    const previousDateHandler = () => {
        dispatch(previousSelectedDateSpecified());
        dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
    };

    const historicalFlagHandler = (historical: boolean) => {
        dispatch(historicalFlagSpecified(historical));
        dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
    };

    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.MEDIC_CALENDAR);

    const theme = useTheme();

    const currentDate = getDateWithoutTime(DateTime.now());
    const isToday = +selectedDate === +currentDate;
    const isTomorrow = +selectedDate === +currentDate.plus({days: 1});
    const isInThePast = selectedDate < currentDate;
    const isInTheFuture = selectedDate > currentDate;

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [warning, setWarning] = useState<string>('');

    const formattedDate = `${
        isToday
            ? `${commonT('today')}:`
            : isTomorrow
            ? `${commonT('tomorrow')}:`
            : ''
    } ${selectedDate.day} ${getMonthName(commonT, selectedDate.month)}`;

    const classes = useStyles();

    const onDelete = async ({all}: {all: boolean}) => {
        try {
            setModalOpen(false);
            setLoading(true);
            await timeslotsApi.deleteTimeslots(selectedDate.toISODate(), all);
        } catch (error) {
            console.error(error);
            if (Array.isArray(error)) {
                setWarning(t(deleteMedicTimeslotsErrorHandler(error[0])));
            }
        } finally {
            dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
            setLoading(false);
        }
    };

    return (
        <>
            <Loading loading={loading} withBackdrop />
            <Snackbar
                open={!!warning}
                autoHideDuration={8000}
                onClose={() => setWarning('')}
            >
                <Alert onClose={() => setWarning('')} severity="warning">
                    {warning}
                </Alert>
            </Snackbar>
            <CustomModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onCloseButtonClick={() => setModalOpen(false)}
            >
                <>
                    <Box
                        textAlign={'center'}
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            marginBottom: 8,
                            marginTop: 15,
                        }}
                    >
                        {t('timeSlotsDeletingHeader')}
                    </Box>
                    <Box
                        textAlign={'center'}
                        style={{width: 300, marginBottom: 20}}
                    >
                        {t('timeSlotsDeletingInfo')}
                    </Box>
                    <Grid
                        container
                        direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Button
                            variant={'outlined'}
                            style={{
                                width: '80%',
                                borderColor: RED,
                                marginBottom: 8,
                                color: RED,
                            }}
                            onClick={() => onDelete({all: true})}
                        >
                            {t('deleteAll')}
                        </Button>
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            style={{width: '80%'}}
                            onClick={() => onDelete({all: false})}
                        >
                            {t('deleteEmpty')}
                        </Button>
                    </Grid>
                </>
            </CustomModal>
            <Hidden smUp>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item className={classes.dateWrapper}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="stretch"
                        >
                            <Box>
                                <ArrowBackIos
                                    color="secondary"
                                    onClick={previousDateHandler}
                                />
                            </Box>
                            <Hidden smUp>
                                <Box
                                    className={classes.currentDate}
                                    onClick={selectDate}
                                >
                                    {formattedDate}
                                </Box>
                            </Hidden>
                            <Hidden smDown>
                                <Box className={classes.currentDate}>
                                    {formattedDate}
                                </Box>
                            </Hidden>
                            <Box>
                                <ArrowForwardIos
                                    color="secondary"
                                    onClick={nextDateHandler}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.calendarHeaderWrapper}
            >
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Hidden smDown>
                            <Box className={classes.currentDate}>
                                {formattedDate}
                            </Box>
                        </Hidden>
                        <Box className={classes.entriesSummary}>
                            <Hidden smUp>
                                {numberOfAppointments ? (
                                    <>
                                        {numberOfAppointments}{' '}
                                        {t('appointments')}
                                    </>
                                ) : (
                                    t('noAppointments')
                                )}
                            </Hidden>
                            <Hidden smDown>
                                {numberOfAppointments ? (
                                    <>
                                        {numberOfAppointments}{' '}
                                        {t('arrangedAppointments')}
                                    </>
                                ) : (
                                    t('noArrangedAppointments')
                                )}
                            </Hidden>
                        </Box>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            variant={'outlined'}
                            color={'default'}
                            disabled={historical}
                            style={{
                                color: historical
                                    ? theme.palette.grey[500]
                                    : theme.palette.secondary.main,
                            }}
                            onClick={() => setModalOpen(true)}
                        >
                            <Delete />
                        </Button>
                        <Button
                            variant={isInThePast ? 'contained' : 'outlined'}
                            disabled={isInThePast}
                            className={classes.leftMargin}
                            style={{
                                backgroundColor: historical ? null : WHITE,
                                color: isInThePast
                                    ? null
                                    : historical
                                    ? theme.palette.grey[500]
                                    : theme.palette.secondary.main,
                            }}
                            onClick={() => historicalFlagHandler(false)}
                        >
                            <Notifications />
                        </Button>
                        <Button
                            variant={isInTheFuture ? 'contained' : 'outlined'}
                            color={historical ? 'secondary' : 'default'}
                            disabled={isInTheFuture}
                            className={classes.leftMargin}
                            style={{
                                backgroundColor: historical ? WHITE : null,
                                color: isInTheFuture
                                    ? null
                                    : historical
                                    ? theme.palette.secondary.main
                                    : theme.palette.grey[500],
                            }}
                            onClick={() => historicalFlagHandler(true)}
                        >
                            <History />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MedicCalendarHeader;
