import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orderInfoRoot: {
            textAlign: 'center',
            height: 'calc(100vh - 200px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        orderInfoHeaderText: {
            fontSize: 36,
            lineHeight: '48px',
            fontWeight: 'bold',
            marginBottom: 30,
            marginTop: 30,
            [theme.breakpoints.up('md')]: {
                lineHeight: '40px',
            },
        },
        thanksGivingText: {
            marginBottom: 26,
            fontSize: 16,
            lineHeight: '24px',
        },
    }),
);

export default useStyles;
