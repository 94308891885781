import { MedicPackageDto } from "../../types/diagnosticServicePackage";
import { DiagnosticServiceDto } from "../../types/referrals";
import { DIAGNOSTIC_SERVICES_FOR_MEDIC_PACKAGE, MEDICAL_PACKAGES_FOR_PATIENT } from "../endpoints";
import { http } from "../http";


class MedicPackageProvider{

    async getMedicPackages(appointmentId:number){
        return (
            await http.get<MedicPackageDto[]>(
                MEDICAL_PACKAGES_FOR_PATIENT(appointmentId)
            )
        ).data;
    }

    async getPackageDiagnosticServices(packageId: number){
        return (
            await http.get<DiagnosticServiceDto[]>(
                DIAGNOSTIC_SERVICES_FOR_MEDIC_PACKAGE(packageId)
            )
        ).data;
    }
} 

export default new MedicPackageProvider();
