import {useEffect, useState} from 'react';
import {Client as ConversationsClient} from '@twilio/conversations';
import {Conversation} from '@twilio/conversations/lib/conversation';
import {useAppSelector} from './customReduxHooks';
import {selectServiceToken} from '../store/consultationSlice';
import {useParams} from 'react-router';

const useConversation = () => {
    const [client, setClient] = useState<ConversationsClient>(null);
    const [conversation, setConversation] = useState<Conversation>(null);
    const [loading, setLoading] = useState<boolean>(false); //UI - display activity indicator
    const [error, setError] = useState<string>(null);

    const token = useAppSelector(selectServiceToken);
    const {consultationId} = useParams<{consultationId: string}>();

    useEffect(() => {
        if (token) {
            setLoading(true);
            ConversationsClient.create(token)
                .then((twilioClient: ConversationsClient) => {
                    twilioClient.on(
                        'connectionStateChanged',
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        (state: string) => {
                            //TODO: ostylowanie, możliwe stany: 'connecting' | 'connected' | 'disconnecting' | 'disconnected' | 'denied'
                        },
                    );
                    setClient(twilioClient);
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        }
    }, [token]);

    return {
        client,
        setClient,
        setConversation,
        conversation,
        loading,
        setLoading,
        error,
        setError,
        consultationId,
    };
};

export default useConversation;
