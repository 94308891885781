import React, {memo, useCallback} from 'react';
import {Box, createStyles, Grid, Hidden, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {useHistory} from 'react-router';
import {ArrowForwardIos} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {CustomAvatar} from '../../index';
import {MedicalTestIcon} from '../../../assets';
import usePatientReferralsForMedicalTestsContext
    from '../../../hooks/referrals/patientMedicalTests/useReferralsForMedicalTestsContext';
import {ORDER_MEDICAL_TESTS_PAGE} from '../../../const/routes';
import {PatientDiagnosticOrderHeadersDto} from '../../../services/diagnostic/orderHeadersProvider';
import CalendarLink from "../AppointmentLink/CalendarLink";
import { MAGENTA, RED } from '../../../const/colors';
import { CustomAlert } from '../../common/feedback';

const textsCommonStyles = {
    size: 16,
    lineHeight: '24px',
}

const useStyles = makeStyles((theme) =>
    createStyles({
        medicalTestName: {
            ...textsCommonStyles,
            fontWeight: 'bold',
        },
        patientName: {
            ...textsCommonStyles,
        },
        arrowForward: {
            color: theme.palette.grey[300],
        },
        linkLabel:{
            color: MAGENTA,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        info:{
            color:RED
        }
    }),
);

interface Props {
    referral: PatientDiagnosticOrderHeadersDto;
}

const MedicalSearchFilters = memo(function MedicalSearchFilters({referral}: Props) {
    const ORDER_MEDICAL_TEST_PAGE = referral.medicalPackage!==null 
        ? `${ORDER_MEDICAL_TESTS_PAGE}?referralId=${referral.id}&medicalTestName=${referral.medicalPackage.name}`
        : `${ORDER_MEDICAL_TESTS_PAGE}?referralId=${referral.id}&medicalTestName=${referral.type}`;

    const classes = useStyles();
    const history = useHistory();
    const {deleteReferral, downloadPdf, downloadError, cleanError} = usePatientReferralsForMedicalTestsContext();
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

    const {t} = useTranslation(i18nNamespaces.REFERRALS);
    const {t: tMedicalTests} = useTranslation(i18nNamespaces.MEDICAL_TESTS);
    const {t: tAppointment} = useTranslation(i18nNamespaces.APPOINTMENT);
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);

    const deleteReferralHandler = useCallback(() => {
        deleteReferral(referral.id);
    }, [referral]);

    const downloadPdfHandler = useCallback(() =>{
        downloadPdf(referral.id);
    }, [referral]);

    return (
        <Grid
            container
            wrap={'nowrap'}
            alignItems="center"
            spacing={lgUp ? 2 : 1}
        >
             <CustomAlert
                message={downloadError}
                open={downloadError!==null} 
                severity={'error'}  
                onClose={cleanError}              
                />
            <Grid item xs="auto">
                <CustomAvatar
                    alt="medical tests icon"
                    src={MedicalTestIcon}
                />
            </Grid>
            <Grid item xs>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Box
                        className={classes.medicalTestName}
                        component={'span'}
                    >
                        {referral.medicalPackage!==null ? referral.medicalPackage.name : tMedicalTests(referral.type)}
                    </Box>

                    <Box
                        className={classes.patientName}
                        component={'span'}
                    >
                        {tAppointment('patient')}:{' '}
                        {referral.patientName}{' '}
                        {referral.patientSurname}
                    </Box>
                </Grid>
                {referral.availableInPdfForm  && <Box className={classes.info}>{tCommon('contactSignalIdunaToOrderDiagnostic')}</Box>}

            </Grid>
            <Hidden smDown>
                <Grid
                    item
                    xs="auto"
                    style={{
                        display: 'flex'
                    }}
                >
                    {referral.availableInPdfForm 
                    ?<label htmlFor='download'
                            onClick={downloadPdfHandler}
                            className = {classes.linkLabel}>
                        {tCommon('download')}
                    </label>
                    :<CalendarLink to={ORDER_MEDICAL_TEST_PAGE}>
                        {t('orderMedicalTests').replace(/\s/g, '\n')}
                    </CalendarLink> 
                    }
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid item xs="auto">
                    <ArrowForwardIos
                        className={classes.arrowForward}
                        style={{cursor: 'pointer'}}
                        onClick={
                            () => history.replace(ORDER_MEDICAL_TEST_PAGE)
                        }
                    />
                </Grid>
            </Hidden>
        </Grid>
    )
})

export default MedicalSearchFilters;