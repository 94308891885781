import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {GRAY, WHITE} from '../../../const/colors';
import {desktop, LARGE_DESKTOP_BREAKPOINT, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        calendarEntryWrapper: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
            marginBottom: mobile.contentSmallerSpacing,
        },
        calendarEntryHeaderWrapper: {
            width: '100%',
        },
        calendarEntryHeaderContainer: {
            borderRadius: '3px',
            padding: `${mobile.contentSmallerSpacing} ${mobile.contentLargerSpacing}`,
            marginBottom: mobile.contentLargerSpacing,
        },
        calendarEntryContentWrapper: {
            width: '100%',
        },
        calendarEntryHeaderLabel: {
            fontSize: mobile.defaultFontSize,
        },
        calendarEntryHeaderTime: {
            fontSize: mobile.defaultFontSize,
            fontWeight: 'bold',
        },
        calendarEntryPatient: {
            fontWeight: 'bold',
        },
        calendarEntryPatientGridItem: {
            flex: '1 0 auto',
        },
        noArrangedAppointment: {
            color: theme.palette.grey[600],
        },
        removeTimeSlot: {
            cursor: 'pointer',
        },
        statusesContainer: {
            margin: '12px 0 0',
            width: '100%',
        },
        appointmentSettleStatusLabel: {
            margin: '0 0 0 8px',
        },
        bookedLabel: {
            display: 'inline-block',
            fontSize: 14,
            lineHeight: '24px',
            color: GRAY
        },
        [theme.breakpoints.up('sm')]: {
            calendarEntryWrapper: {
                flexDirection: 'row',
                padding: `0 ${desktop.contentSmallerSpacing}`,
                justifyContent: 'space-between',
                marginBottom: 0,
            },
            calendarEntryHeaderWrapper: {
                width: 'auto',
            },
            calendarEntryHeaderContainer: {
                padding: `${mobile.contentLargerSpacing} ${desktop.contentSmallerSpacing}`,
                margin: 0,
                minWidth: 108,
                justifyContent: 'center',
            },
            calendarEntryContentWrapper: {
                width: 'auto',
                flexGrow: 1,
                paddingLeft: desktop.contentSmallerSpacing,
            },
            calendarEntryHeaderTime: {
                fontSize: '24px',
            },
            separatorWrapper: {
                width: '100%',
                padding: `${mobile.contentLargerSpacing} 0`,
            },
            statusesContainer: {
                margin: '4px 0 0 132px',
            },
        },
        [`@media ${LARGE_DESKTOP_BREAKPOINT}`]: {
            statusesContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                margin: `0 ${desktop.contentLargerSpacing} 0 0`,
                width: 'auto',
            },
            appointmentSettleStatusLabel: {
                margin: '4px 0 0 0',
            },
        },
    }),
);

export default useStyles;
