import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { AdditionalAuxiliaryTestsDto, AuxiliaryTestItemDto, AuxiliaryTestsDto, PhysicalTestsByMedicDto, SpecialistConsultationItemDto, SpecialistConsultationsDto } from "../../../types/occupationalMedicine";
import { MutableRefObject, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';
import { useFormik } from "formik";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Button, FormGroup, Grid, Link } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { useParams } from "react-router";
import { fetchSection13Data, fetchSection14Data } from "../../../store/occupationalMedicineSlice";
import { RenderCancelNextButton, RenderGridWithCheckBox, RenderGridWithCommonView, RenderGridWithCommonViewWithTitle, RenderGridWithRequiredCommonViewWithTitle, RenderReturnNextButtons } from "../CommonView";

const SECTION = 14;

export type Props = {
    currentExpanded:number
    data:AdditionalAuxiliaryTestsDto;
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void
    onReturn: () => void;
    refSection:MutableRefObject<any>
}

const AdditionalAuxiliaryTestsComponent = ({currentExpanded, data, onSubmitSection, refSection, onReturn}:Props) =>{
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);

    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values), fetchSection14Data({id:appointmentId}),SECTION);
    }
    
    const {values, touched, handleChange, handleBlur, errors, handleSubmit, setFieldValue} =
        useFormik({
            initialValues: {
                changingTheFrequencyOfPeriodicTests:{
                    occured: data?.changingTheFrequencyOfPeriodicTests.occured ?? false,
                    description: data?.changingTheFrequencyOfPeriodicTests.description ?? ''
                },
                diagnosis: data?.diagnosis ?? '',
                recommendation: data?.recommendation ?? '',
                primaryHealthCareUnitStreet: data?.primaryHealthCareUnitStreet ?? '',
                primaryHealthCareUnitHouseNumber: data?.primaryHealthCareUnitHouseNumber ?? '',
                primaryHealthCareUnitApartmentNumber: data?.primaryHealthCareUnitApartmentNumber ?? '',
                primaryHealthCareUnitCity: data?.primaryHealthCareUnitCity ?? '',
                primaryHealthCareUnitPostcode: data?.primaryHealthCareUnitPostcode ?? '',
                informationForPrimaryCareDoctor:data?.informationForPrimaryCareDoctor ?? '',
            },

            validationSchema: yup.object({
                
            }),
            onSubmit
        });

    return (
        <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >

                        <Box className={classes.titleContainer}>
                            <h4 className={classes.sectionHeader}>
                                {t('summaryByMedic')}
                            </h4>

                            {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsContainer}>
                        <Box>
                            <Grid container spacing={1} className={classes.gridContainer}>                                
                                
                                {RenderGridWithCheckBox(8, "changingTheFrequencyOfPeriodicTests.occured", values.changingTheFrequencyOfPeriodicTests.occured, 
                                    false, handleChange, classes, t('changingTheFrequencyOfPeriodicTests') )}
             
                                { 
                                    values.changingTheFrequencyOfPeriodicTests.occured && 
                                    RenderGridWithRequiredCommonViewWithTitle(12, "changingTheFrequencyOfPeriodicTests.description", values.changingTheFrequencyOfPeriodicTests.description,
                                        handleChange, handleBlur, classes, t('changingTheFrequencyOfPeriodicTestsReason'), 
                                        touched.changingTheFrequencyOfPeriodicTests?.description, errors.changingTheFrequencyOfPeriodicTests?.description, 
                                        t(errors.changingTheFrequencyOfPeriodicTests?.description))
                                }

                                {RenderGridWithCommonViewWithTitle(12, "diagnosis", values.diagnosis,
                                        handleChange, handleBlur, classes, t('diagnosis'))}

                                {RenderGridWithCommonViewWithTitle(12, "recommendation", values.recommendation,
                                        handleChange, handleBlur, classes, t('recommendation'))}

                                <Grid item xs={12}>
                                    <Box className={classes.titleContainer + ' ' + classes.sectionHeader + ' '+ classes.label}>
                                        {t('primaryHealthCareUnitAddress')} 
                                    </Box>
                                </Grid>

                                {RenderGridWithCommonView(4, "primaryHealthCareUnitStreet", values.primaryHealthCareUnitStreet,
                                        handleChange, handleBlur, classes, t('street'))}

                                {RenderGridWithCommonView(4, "primaryHealthCareUnitHouseNumber", values.primaryHealthCareUnitHouseNumber,
                                        handleChange, handleBlur, classes, t('houseNumber'))}
                                
                                {RenderGridWithCommonView(4, "primaryHealthCareUnitApartmentNumber", values.primaryHealthCareUnitApartmentNumber,
                                        handleChange, handleBlur, classes, t('apartmentNumber'))}
                                
                                {RenderGridWithCommonView(4, "primaryHealthCareUnitPostcode", values.primaryHealthCareUnitPostcode,
                                        handleChange, handleBlur, classes, t('postcode'))}
                                
                                {RenderGridWithCommonView(4, "primaryHealthCareUnitCity", values.primaryHealthCareUnitCity,
                                        handleChange, handleBlur, classes, t('city'))}                         

                                {RenderGridWithCommonViewWithTitle(12, "informationForPrimaryCareDoctor", values.informationForPrimaryCareDoctor,
                                        handleChange, handleBlur, classes, t('informationForPrimaryCareDoctor'))}                             
                            
                            
                            </Grid>
                            {RenderReturnNextButtons(classes, t, onReturn)}
                        </Box>      
                    </AccordionDetails>
                </Accordion>
        </form>
    
    )
}
export default  AdditionalAuxiliaryTestsComponent;