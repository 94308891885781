import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {PatientReferralDto} from '../../types/referrals';
import referralsProvider from '../../services/referralsProvider';
import {RootState} from '../store';

const sliceName = 'referrals-to-specialist';

const patientReferralsAdapter = createEntityAdapter<PatientReferralDto>({
    selectId: referral => referral.id
});

const initialState = patientReferralsAdapter.getInitialState({
    status: 'idle' as 'idle' | 'loading' | 'error'
});

export const fetchReferrals = createAsyncThunk(
    `${sliceName}/fetchReferrals`,
    async () => {
        const response = await referralsProvider.getPatientReferrals();
        return response.data;
    }
)

const referralsToSpecialistSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchReferrals.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReferrals.fulfilled, (state, action) => {
                state.status = 'idle';
                patientReferralsAdapter.setAll(state, action.payload);
            })
    }
});

export const {resetState} = referralsToSpecialistSlice.actions;

export const {
    selectIds: selectPatientReferralsIds,
    selectById: selectPatientReferralById,
} = patientReferralsAdapter.getSelectors<RootState>(
    state => state.patientReferralsToSpecialist,
);

export const selectPatientReferralsStatus = (state: RootState) =>
    state.patientReferralsToSpecialist.status;

export default referralsToSpecialistSlice.reducer;