import React from 'react';
import {
    Box,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
} from '@material-ui/core';
import {GoBackIcon} from '../../../assets';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            marginLeft: -12,
        },
    }),
);

interface GoBackTileProps {
    label?: string;
    onClick: () => void;
}

const GoBackTile = (props: GoBackTileProps) => {
    const {label, onClick} = props;
    const classes = useStyles();
    return (
        <Box className={classes.container} onClick={onClick}>
            <IconButton>
                <img src={GoBackIcon} alt="Go back" />
            </IconButton>
            <Box>{label}</Box>
        </Box>
    );
};

export default GoBackTile;
