import React, {useEffect} from 'react';
import useStyles from './IndividualPackagesTableMobileStyles';
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchIndividualPackages,
    selectIndividualPackagesIndividualPackages,
    selectSubscriptionGroups,
    selectIndividualPackagesStatus,
    selectSelectedBundle,
} from '../../../store/individualPackagesSlice';
import {Loading} from '../../index';
import {formatPrice, getCurrency} from '../../../utils/price';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    IndividualPackageDto,
    MedicalServiceGroupDto,
} from '../../../types/individualPackages';

type IndividualPackagesTableMobileProps = {
    onBuy: (individualPackageDraftId: number) => void;
};

const IndividualPackagesTableMobile = ({
    onBuy,
}: IndividualPackagesTableMobileProps) => {
    const theme = useTheme();
    const classes = useStyles();
    const subscriptionGroups = useAppSelector(selectSubscriptionGroups);
    const individualPackages = useAppSelector(
        selectIndividualPackagesIndividualPackages,
    );
    const selectedBundle = useAppSelector(selectSelectedBundle);
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectIndividualPackagesStatus);
    const {t, i18n} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);

    useEffect(() => {
        dispatch(
            fetchIndividualPackages({
                maxUsersCount: selectedBundle.maxUsersCount,
                childrenIncluded: selectedBundle.childrenIncluded,
            }),
        );
    }, [dispatch, selectedBundle]);

    const mapIndividualPackages = () =>
        subscriptionGroups.map(group => (
            <React.Fragment key={group.id}>
                <TableRow>
                    <TableCell className={classes.groupDescription} colSpan={2}>
                        {group.description}
                    </TableCell>
                </TableRow>
                <TableRow key={group.id}>
                    {individualPackages.map(
                        (individualPackage: IndividualPackageDto) => {
                            const individualPackageGroup =
                                individualPackage.medicalServiceGroups.find(
                                    (packageGroup: MedicalServiceGroupDto) =>
                                        packageGroup.id === group.id,
                                );
                            return (
                                <TableCell
                                    key={individualPackage.id}
                                    className={classes.boldedCell}
                                    style={{
                                        color:
                                            !individualPackageGroup &&
                                            theme.palette.text.secondary,
                                    }}
                                >
                                    {individualPackageGroup
                                        ? `- ${group.discount * 100}%`
                                        : '—'}
                                </TableCell>
                            );
                        },
                    )}
                </TableRow>
            </React.Fragment>
        ));

    return status !== 'loaded' ? (
        <Grid container justifyContent="center">
            <Loading loading />
        </Grid>
    ) : (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    {individualPackages.map(subscription => (
                        <TableCell
                            style={{textAlign: 'center', fontSize: 16}}
                            key={subscription.id}
                        >
                            {subscription.name}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {mapIndividualPackages()}
                <TableRow>
                    {individualPackages.map(subscription => (
                        <TableCell
                            className={classes.boldedCell}
                            style={{borderBottom: 'none'}}
                            key={subscription.id}
                        >
                            {formatPrice(subscription.price)}{' '}
                            {getCurrency(i18n.language)} / {t('monthly')}
                            {/*TODO: get country based price from backend*/}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    {individualPackages.map(subscription => (
                        <TableCell
                            style={{textAlign: 'center'}}
                            key={subscription.id}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.buyButton}
                                onClick={() => onBuy(subscription.draftId)}
                            >
                                {t('buy')}
                            </Button>
                        </TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default IndividualPackagesTableMobile;
