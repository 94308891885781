import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import useStyles from './MedicPictureChangeTileDesktopStyles';
import useTileStyles from '../../common/tileCommonStyles';
import MedicPicture from "../MedicPicture/MedicPicture";
import EditPictureActionButton from "../EditPictureActionButton/EditPictureActionButton";

const MedicPictureChangeTileDesktop = () => {
    const tileClasses = useTileStyles();
    const classes = useStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);

    return (
        <Box className={tileClasses.root}>
            <h3 className={tileClasses.headerText}>
                {commonT('pictureOfYou')}
            </h3>
            <Grid
                className={classes.contentContainer}
                container
            >
                <MedicPicture />
                <Box>
                    <Box className={classes.actionButtonsWrapper}>
                        <EditPictureActionButton />
                    </Box>
                </Box>
            </Grid>
        </Box>
    );
}

export default MedicPictureChangeTileDesktop;