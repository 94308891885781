import React from 'react';
import {Box, Grid, IconButton, useMediaQuery, useTheme} from '@material-ui/core';
import {ArrowForwardIos} from '@material-ui/icons';
import {CustomAvatar, RatingWithCount} from "../../../index";
import useStyles from './MedicCardStyles';
import {PatientReferralMedic} from "../../../../types/medic";
import {useAppDispatch, useAppSelector} from '../../../../hooks/customReduxHooks';
import {selectMedic, selectSelectedMedic} from '../../../../store/referrals/makePatientReferralSlice';

interface Props {
    medic: PatientReferralMedic;
}

const MedicCard = ({medic}: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const selectedMedic = useAppSelector(selectSelectedMedic);
    const isMedicSelected = isDesktop && medic === selectedMedic;
    const dispatch = useAppDispatch();

    const cardClickHandler = () => {
        dispatch(selectMedic(medic.id));
    }

    return (
        <Box className={classes.container}>
            <Grid
                container
                className={classes.layout}
                onClick={cardClickHandler}
            >
                <CustomAvatar
                    alt={`${medic.firstName} ${medic.surname} photo`}
                    src={medic.photoUrl}
                    isActive={isMedicSelected}
                />
                <Grid
                    item
                    className={classes.layoutSecondChild}
                >
                    <Box>
                        <Box fontWeight={'bold'}>
                            {`${medic.title} ${medic.firstName} ${medic.surname}`}
                        </Box>
                        <Box className={classes.specialties}>
                            {medic.specialties.join(', ')}
                        </Box>
                        <RatingWithCount
                            reviewRate={medic.reviewRate}
                            reviewCount={medic.reviewCount}
                        />
                    </Box>
                </Grid>
                <IconButton edge="end" aria-label="comments">
                    <ArrowForwardIos
                        color={
                            isMedicSelected
                                ? 'secondary'
                                : 'disabled'
                        }
                    />
                </IconButton>
            </Grid>
        </Box>
    );
};

export default MedicCard;
