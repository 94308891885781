import {Box, Grid, Link} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Error404Image} from '../../assets';
import i18nNamespaces from '../../const/i18nNamespaces';
import useStyles from './Error404PageStyles';

const Error404Page = () => {
    const {t} = useTranslation(i18nNamespaces.COMMON);

    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.errorWrapper}
        >
            <Box className={classes.errorTitle}>{t('404error')}</Box>
            <Link
                className={classes.errorContent}
                color={'secondary'}
                href={'/'}
            >
                {t('goBackToHomePage')}
            </Link>
            <Box>
                <img
                    src={Error404Image}
                    alt={t('error')}
                    className={classes.errorImage}
                />
            </Box>
        </Grid>
    );
};

export default Error404Page;
