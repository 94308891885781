import * as yup from 'yup';

const REQUIRED_STRING = 'requiredField';

const addAdultToPackageSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_STRING),
    surname: yup.string().required(REQUIRED_STRING),
    pesel: yup
        .string()
        .matches(/^[0-9]+$/, 'peselShouldContainOnlyNumbers')
        .length(11, 'lengthShouldBe11chars')
        .required(REQUIRED_STRING),
});

export default addAdultToPackageSchema;
