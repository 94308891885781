import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { enGB, pl } from 'date-fns/locale';
import {ReactNode} from 'react';
import { useTranslation } from 'react-i18next';

type PickersProviderProps = {
    children: ReactNode;
};

const PickersProvider = ({children}: PickersProviderProps) => {
    const {i18n} = useTranslation();

    let locale = enGB;

    switch(i18n.resolvedLanguage) {
        case 'pl':
            locale = pl;
            break;
    }

    return (
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            {children}
        </MuiPickersUtilsProvider>
    );
};

export default PickersProvider;
