import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            marginTop: 60,
        },
        boldedCell: {
            fontSize: 20,
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        tableFooterCell: {
            fontWeight: 'bold',
            fontSize: 20,
        },
        buyButton: {
            width: 191,
            fontWeight: 'bold',
        },
        magentaText: {
            color: theme.palette.text.secondary,
        },
    }),
);

export default useStyles;
