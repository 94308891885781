import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: mobile.contentLargerSpacing,
        },
        title: {
            fontSize: 36,
            fontWeight: 'bold',
            marginBottom: 30,
        },
        formField: {
            marginBottom: 24,
        },
        submitButton: {
            marginBottom: 24,
        },
        error: {
            color: 'red',
            marginBottom: 10,
        },
        [theme.breakpoints.up('sm')]: {
            container: {
                padding: desktop.contentLargerSpacing,
                width: '100%',
            },
            title: {
                fontSize: 56,
            },
            image: {
                width: 480,
            },
        },
    }),
);

export default useStyles;
