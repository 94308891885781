import {MemberDto, PatientPackagesDto} from '../types/patientPackages';
import {
    ADD_GROUP_MEMBER,
    ADD_SUBSCRIPTION_MEMBER,
    CANCEL_SUBSCRIPTION,
    PATIENT_PACKAGES,
    REMOVE_GROUP_MEMBER,
    REMOVE_SUBSCRIPTION_MEMBER,
    UPDATE_PAYMENT,
} from './endpoints';
import {http, rawHttp} from './http';
import {BuyerDataDto} from '../types/buyerData';
import {SubscriptionCreationResultDto} from '../types/subscriptions';

class PackagesProvider {
    async getPatientPackages() {
        return (await http.get<PatientPackagesDto>(PATIENT_PACKAGES)).data;
    }

    async addMemberToSubscription(subscriptionId: number, member: MemberDto) {
        return await rawHttp.post(
            ADD_SUBSCRIPTION_MEMBER(subscriptionId),
            member,
        );
    }

    async cancelSubscription(subscriptionId: number) {
        return await http.post(CANCEL_SUBSCRIPTION(subscriptionId));
    }

    async removeSubscriptionMember(subscriptionId: number, pesel: string) {
        return await rawHttp.delete(
            REMOVE_SUBSCRIPTION_MEMBER(subscriptionId, pesel),
        );
    }

    async addMemberToGroup(subscriptionId: number, member: MemberDto) {
        return await rawHttp.post(ADD_GROUP_MEMBER(subscriptionId), member);
    }

    async removeGroupMember(subscriptionId: number, pesel: string) {
        return await rawHttp.delete(REMOVE_GROUP_MEMBER(subscriptionId, pesel));
    }

    async updatePayment(
        subscriptionId: number,
        buyerData: BuyerDataDto,
        newCardToken: string,
    ) {
        return (
            await rawHttp.put<SubscriptionCreationResultDto>(
                UPDATE_PAYMENT(subscriptionId),
                {
                    BuyerData: buyerData,
                    NewCardToken: newCardToken,
                },
            )
        ).data;
    }
}

export default new PackagesProvider();
