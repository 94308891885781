import {DateTime} from 'luxon';
import getDateWithoutTime from './getDateWithoutTime';
import i18n from '../i18n/config';

const DAYS_IN_MAP = 8;
const INTERVALS = 12;
const STEP = 2;
const START_HOUR = 0;

export type Interval = {
    id: number;
    label: string;
    startHour: number;
    endHour: number;
};

const timeIntervals: Interval[] = [...new Array(INTERVALS)].map((_, idx) => ({
    id: idx,
    label: `${idx * STEP + START_HOUR}:00 - ${
        idx * STEP + START_HOUR + STEP
    }:00`,
    startHour: idx * STEP + START_HOUR,
    endHour: idx * STEP + START_HOUR + STEP,
}));

export const convertToDateTime = (date: string): DateTime =>
    DateTime.fromISO(date);

export const convertToUTC = (date: DateTime): string => date.toUTC().toISO();

export const consultationDays = (currentDate: DateTime) =>
    [...new Array(DAYS_IN_MAP)].map((_, idx) => {
        const lastHour = START_HOUR + INTERVALS * STEP;
        const days = currentDate.hour > lastHour ? idx + 1 : idx;
        const date = currentDate.plus({days});
        const label = getRelativeDay(date, 'dd.MM');
        return {
            id: idx,
            label,
            date,
        };
    });

export const getConsultationTimes = (
    currentDateTime: DateTime,
    date: DateTime,
) => {
    if (currentDateTime.day === date.day) {
        return timeIntervals.filter(
            interval => interval.endHour >= currentDateTime.hour + 1,
        );
    }
    return timeIntervals;
};

export const getTimeIntervals = (
    currentDateTime: DateTime,
    selectedDay: number,
) => {
    const selectedDayISO = consultationDays(currentDateTime)[selectedDay].date;
    return getConsultationTimes(currentDateTime, selectedDayISO);
};

export const getInterval = (selectedDay: DateTime, interval: Interval) => {
    const initialTime = {minute: 0, second: 0, millisecond: 0};
    const startDateTime = selectedDay.set({
        ...initialTime,
        hour: interval.startHour,
    });
    const endDateTime = selectedDay.set({
        ...initialTime,
        hour: interval.endHour,
    });
    return {
        startDateTime,
        endDateTime,
    };
};

export const getDateDifferenceFromNowInDays = (date: DateTime) => {
    const dateWithoutTime = getDateWithoutTime(date);
    const currentDateWithoutTime = getDateWithoutTime(DateTime.now());

    return dateWithoutTime.diff(currentDateWithoutTime, ["days"]).days
};

export const getRelativeDay = (date: DateTime, fmt?: string) => {
    const diffInDays = getDateDifferenceFromNowInDays(date);
    switch (diffInDays) {
        case 0:
            return i18n.t('today');
        case 1:
            return i18n.t('tomorrow');
        default:
            return fmt ? date.toFormat(fmt) : date.toLocaleString();
    }
};

export const getDayMonthFromISO = (date: string) =>
    DateTime.fromISO(date).toFormat('d.MM');

export const getTimeFromISO = (date: string) =>
    DateTime.fromISO(date).toFormat('t');

export const getLongTimeFromISO = (date: string) =>
    DateTime.fromISO(date).toFormat('HH:mm:ss');

export const getDateFromISO = (date: string) =>
    DateTime.fromISO(date).setLocale(i18n.language).toFormat(`d MMMM y`);

export const getShortDateFromISO = (date: string) =>
    DateTime.fromISO(date).setLocale(i18n.language).toFormat(`dd.MM.y`);

export const getDateTime = (date: DateTime, timeFmt?: string) =>
{
    if (timeFmt){
        return `${date
            .setLocale(i18n.language)
            .toFormat('dd MMMM')}, godz. ${date.toFormat(timeFmt)}`;
    }
    return `${date
        .setLocale(i18n.language)
        .toFormat('dd MMMM')}, godz. ${date.toFormat('t')}`;
}

export const isWithinTimeLimit = (
    dateTime: DateTime,
    limitDateTime: DateTime,
    minutes: number,
) => {
    const earliestDateTime = limitDateTime.minus({minutes: minutes});
    const latestDateTime = limitDateTime.plus({minutes: minutes});

    return dateTime >= earliestDateTime && dateTime <= latestDateTime;
};

export const getDateIntervalByCurrentDate = (currentDateTime: DateTime) => {
    const interval = getTimeIntervals(currentDateTime, 0)[0];
    const dateInterval = getInterval(
        consultationDays(currentDateTime)[0].date,
        interval,
    );
    return dateInterval;
};

export const compareDatesWithoutTime = (d1: DateTime, d2: DateTime) => {
    const haveSameCalendarYearMonthDay = d1.hasSame(d2, 'day');

    if (haveSameCalendarYearMonthDay) {
        return 0;
    }

    const d1GreaterThanD2 = d1.startOf('year') > d2.startOf('year')
        || d1.startOf('month') > d2.startOf('month')
        || d1.startOf('day') > d2.startOf('day');

    return d1GreaterThanD2 ? 1 : -1;
}

export const createDateWithZeroTime = (date: DateTime) =>
    DateTime.fromISO(date.toISODate());
