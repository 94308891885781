import {useEffect, useMemo, useState} from 'react';
import {Appointment, AppointmentStateEnum} from '../../types/appointments';

const usePostCancellationFetchAppointment = (
    appointment: Appointment,
    appointmentLoading: boolean,
    fetchAppointment: () => Promise<void>
) => {
    const [
        postCancellationAppointmentLoading,
        setPostCancellationAppointmentLoading
    ] = useState(false);

    useEffect(
        () => {
            // Sometimes fetched appointment after cancellation has not updated state,
            // so it needs to be refetched until state is updated
            if (!postCancellationAppointmentLoading || appointmentLoading) {
                return;
            }

            const doFetch = appointment?.state.state === AppointmentStateEnum.Planned
            const stopFetching = appointment?.state.state === AppointmentStateEnum.Canceled;

            (async () => {
                if (doFetch) {
                    await fetchAppointment();
                } else if (stopFetching) {
                    setPostCancellationAppointmentLoading(false);
                }
            })();
        },
        [
            appointment,
            appointmentLoading,
            postCancellationAppointmentLoading,
        ]
    );

    const value = useMemo(
        () => ({
            postCancellationAppointmentLoading,
            setPostCancellationAppointmentLoading
        }),
        [postCancellationAppointmentLoading]
    );

    return value;
}

export default usePostCancellationFetchAppointment;