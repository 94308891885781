import {
    MedicTimeSlotRangeDto,
    TimeSlotDto,
    TimeSlotReservationDto,
    TimeSlotReservationResultDto,
    TimeSlotsDto,
    TimeWindowDates,
} from '../types/timeSlot';
import {
    ADD_PATIENT_TO_QUEUE,
    CREATE_MEDIC_TIME_SLOTS,
    DELETE_MEDIC_TIME_SLOT_REQUEST,
    DELETE_MEDIC_TIME_SLOT_RESPONSE,
    PATIENT_CONTACT_DATA,
    TIME_SLOT,
    TIME_SLOTS_REQUESTS,
    TIME_SLOTS_RESERVED,
} from './endpoints';
import {http, rawHttp} from './http';

type PatientContactDataDto = {
    phoneNumber: string;
    medicalServiceId: number;
    startDate?: string;
    endDate?: string;
};

class TimeSlotsProvider {
    async getTimeSlot(timeSlotId: string) {
        return (await http.get<TimeSlotDto>(TIME_SLOT(timeSlotId))).data;
    }

    async getTimeSlots(token: string) {
        return (await http.get<TimeSlotsDto>(TIME_SLOTS_REQUESTS + `/${token}`))
            .data;
    }

    async reserveTimeSlot(
        timeSlotId: string,
        timeSlotReservation: TimeSlotReservationDto,
    ) {
        return (
            await http.put<string>(
                TIME_SLOTS_RESERVED(timeSlotId),
                timeSlotReservation,
            )
        ).data;
    }

    async getTimeSlotReservationResult(token: string) {
        return (
            await rawHttp.get<TimeSlotReservationResultDto>(
                TIME_SLOTS_REQUESTS + `/${token}`,
            )
        ).data;
    }

    async createMedicTimeSlots(medicTimeSlotRange: MedicTimeSlotRangeDto) {
        await rawHttp.post(CREATE_MEDIC_TIME_SLOTS, medicTimeSlotRange);
    }

    async sendDeleteMedicTimeSlotRequest(timeSlotId: string) {
        return (
            await http.post<string>(DELETE_MEDIC_TIME_SLOT_REQUEST(timeSlotId))
        ).data;
    }

    async retrieveDeleteMedicTimeSlotResult(token: string) {
        await rawHttp.get<string>(DELETE_MEDIC_TIME_SLOT_RESPONSE(token));
    }

    async addPatientToQueue(
        medicalServiceId: number,
        timeWindow: TimeWindowDates,
    ) {
        return await http.post(ADD_PATIENT_TO_QUEUE, {
            medicalServiceId,
            timeWindow,
        });
    }

    savePatientContactData(patientData: PatientContactDataDto) {
        return http.post(PATIENT_CONTACT_DATA, patientData);
    }
}

export default new TimeSlotsProvider();
