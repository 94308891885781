import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {Box, Grid, IconButton, Typography} from '@material-ui/core';
import useStyles from './TermsAndConditionsPageStyles';
import { PdfImage } from '../../assets';
import { docFileIcon } from '../../assets';
import { useEffect, useState } from 'react';
import filesDownloadProvider from '../../services/filesDownloadProvider';
import TermsAndConditionFilesProvider from '../../services/termsAndConditionFilesProvider';
import { Loading } from '../../components';
import { Helmet } from 'react-helmet-async';
import configuration from '../../configuration';


const TermsAndConditions = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const {t: tContent} = useTranslation(i18nNamespaces.FOOTER_CONTENT);
    const {t: tMetaDescription} = useTranslation(i18nNamespaces.META_DESCRIPTION);

    const [loading, setLoading] = useState(false);

    const generalProvisionsContent = tContent('generalProvisionsContent', {returnObjects:true}) as Array<string>;
    const generalProvisionsListContent = tContent('generalProvisionsListContent', {returnObjects:true}) as Array<string>;

    const technicalConditionContent = tContent('technicalConditionContent', {returnObjects:true}) as Array<string>;
    const technicalConditionList1Content = tContent('technicalConditionList1Content', {returnObjects:true}) as Array<string>;
    const technicalConditionContent2 = tContent('technicalConditionContent2', {returnObjects:true}) as Array<string>;
    const technicalConditionList2Content = tContent('technicalConditionList2Content', {returnObjects:true}) as Array<string>;
    const technicalConditionContent3 = tContent('technicalConditionContent3', {returnObjects:true}) as Array<string>;

    const contentOnPageContent = tContent('contentOnPageContent', {returnObjects:true}) as Array<string>;
    const contentOnPageListContent = tContent('contentOnPageListContent', {returnObjects:true}) as Array<string>;
    
    const useOfPageContent =  tContent('useOfPageContent', {returnObjects:true}) as Array<string>;
    const useOfPageListContent =  tContent('useOfPageListContent', {returnObjects:true}) as Array<string>;
    const useOfPageContent2 =  tContent('useOfPageContent2', {returnObjects:true}) as Array<string>;
    
    const generalMedicalServicesConditionContent = tContent('generalMedicalServicesConditionContent', {returnObjects:true}) as Array<string>;
    const generalMedicalServicesConditionListContent = tContent('generalMedicalServicesConditionListContent', {returnObjects:true}) as Array<string>;
    const generalMedicalServicesConditionContent2 = tContent('generalMedicalServicesConditionContent2', {returnObjects:true}) as Array<string>;
    const generalMedicalServicesConditionList2Content = tContent('generalMedicalServicesConditionList2Content', {returnObjects:true}) as Array<string>;
    const generalMedicalServicesConditionContent3 = tContent('generalMedicalServicesConditionContent3', {returnObjects:true}) as Array<string>;
      
    const paymentConditiontContent = tContent('paymentConditiontContent', {returnObjects:true}) as Array<string>;
    const paymentConditiontListContent = tContent('paymentConditiontListContent', {returnObjects:true}) as Array<string>;
    const paymentConditiontContent2 = tContent('paymentConditiontContent2', {returnObjects:true}) as Array<string>;

    const responsibilityContent = tContent('responsibilityContent', {returnObjects:true}) as Array<string>;
    const responsibilityListContent = tContent('responsibilityListContent', {returnObjects:true}) as Array<string>;
    const responsibilityContent2 = tContent('responsibilityContent2', {returnObjects:true}) as Array<string>;
    
    const withdrawalFromTheContractContent = tContent('withdrawalFromTheContractContent', {returnObjects:true}) as Array<string>;
    const withdrawalFromTheContractListContent = tContent('withdrawalFromTheContractListContent', {returnObjects:true}) as Array<string>;
    
    const termsAndConditionChangesContent = tContent('termsAndConditionChangesContent', {returnObjects:true}) as Array<string>;
    const termsAndConditionChangesListContent = tContent('termsAndConditionChangesListContent', {returnObjects:true}) as Array<string>;
    const termsAndConditionChangesContent2 = tContent('termsAndConditionChangesContent2', {returnObjects:true}) as Array<string>;

    const complaintsContent = tContent('complaintsContent', {returnObjects:true}) as Array<string>;

    const complaintsResultContent = tContent('complaintsResultContent', {returnObjects:true}) as Array<string>;

    const saleContent = tContent('saleContent', {returnObjects:true}) as Array<string>;

    const finalContent= tContent('finalContent', {returnObjects:true}) as Array<string>;
    
    const patientAccountContent = tContent('patientAccountContent', {returnObjects:true}) as Array<string>;
    const patientAccountListContent = tContent('patientAccountListContent', {returnObjects:true}) as Array<string>;
    const patientAccountContent2 = tContent('patientAccountContent2', {returnObjects:true}) as Array<string>;
    const patientAccountListContent2 = tContent('patientAccountListContent2', {returnObjects:true}) as Array<string>;
    const patientAccountContent3 = tContent('patientAccountContent3', {returnObjects:true}) as Array<string>;
    
    
    const subjectsListContent = tContent('subjectsListContent', {returnObjects:true}) as Array<string>;
    const platformContent= tContent('platformContent', {returnObjects:true}) as Array<string>;
    const servicesContent= tContent('servicesContent', {returnObjects:true}) as Array<string>;
    const servicesContentSublist= tContent('servicesContentSublist', {returnObjects:true}) as Array<string>;
    const legalActsContent= tContent('legalActsContent', {returnObjects:true}) as Array<string>;


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const downloadTermsAndConditionsPdf = async () => {
        setLoading(true);
        try {
            const {data: instruction} = await TermsAndConditionFilesProvider.getTermsAndConditionsPdf();     
            filesDownloadProvider.downloadFileFromUrl(
                instruction,
                `${tContent('termsAndConditionsTitle')}.pdf`
            )
        } catch (e) {
            console.error(
                e instanceof Error
                    ? e.message
                    : 'An error occurred while fetching instruction data'
            );
        } finally {
            setLoading(false);
        }
    }

    const downloadAttachmentPdf = async () =>{
        setLoading(true);
        try {
            const {data: instruction} = await TermsAndConditionFilesProvider.getWithdrawalFormPdf();     
            filesDownloadProvider.downloadFileFromUrl(
                instruction,
                `${tContent('form')}.pdf`
            )
        } catch (e) {
            console.error(
                e instanceof Error
                    ? e.message
                    : 'An error occurred while fetching instruction data'
            );
        } finally {
            setLoading(false);
        }
    }

    const downloadAttachmentDoc = async () =>{
        setLoading(true);
        try {
            const {data: instruction} = await TermsAndConditionFilesProvider.getWithdrawalFormDoc();     
            filesDownloadProvider.downloadFileFromUrl(
                instruction,
                `${tContent('form')}.docx`
            )
        } catch (e) {
            console.error(
                e instanceof Error
                    ? e.message
                    : 'An error occurred while fetching instruction data'
            );
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
        {configuration.environment ===  'Production' &&
        <Helmet>
            <title>{tMetaDescription('termsAndConditionPageTitle')}</title>
            <meta name='description' content={tMetaDescription('termsAndConditionPageDescription')} />
        </Helmet>}
        <Box className={classes.container}>
                <Box className={classes.title}>
                    {tContent('termsAndConditionsTitle')}
                </Box>
               

                {/* Słownik pojęć */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('dictionaryOfTermsTitle')}
                    </Box>
                
                    <Box className={classes.description}>
                        {tContent('dictionaryOfTermsContent')}
                    </Box>
                
                    <Box className={classes.desctiptionSubtitle}>
                        {tContent('subjectsSubtitle')}
                    </Box>
               
                    <Box className={classes.description}>
                       <ol type='1' className={classes.olSuperiorElement}>{subjectsListContent && subjectsListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.desctiptionSubtitle}>
                        {tContent('platformSubtitle')}
                    </Box>
                
               
                    <Box className={classes.description}>
                        <ol type='1' start={7} className={classes.olSuperiorElement}>{platformContent && platformContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
               
                    <Box className={classes.desctiptionSubtitle}>
                        {tContent('servicesTitle')}
                    </Box>
               
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement} start={10}>{servicesContent && servicesContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
               
                    <Box className={classes.description}>
                         <ol type='a' className={classes.liSubElement}>{servicesContentSublist && servicesContentSublist.map((d, i)=> <li key={i}>{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.desctiptionSubtitle}>
                        {tContent('legalActsTitle')}
                    </Box>
               
                    <Box className={classes.description}>
                    <ol type='1' start={19} className={classes.olSuperiorElement}>{legalActsContent && legalActsContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>

                {/* Postanowienia ogólne */}
                    <Box className={classes.desctiptionTitle}>
                        {tContent('generalProvisions')}
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{generalProvisionsContent && generalProvisionsContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
               
                    <Box className={classes.description}>
                        <ol type='a' className={classes.liSubElement}>{generalProvisionsListContent && generalProvisionsListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
               

                {/* Warunki techniczne */}
                
                    <Box className={classes.desctiptionTitle}>
                        {tContent('technicalConditionTitle')}
                    </Box>
               
                    <Box className={classes.description}>
                    <ol type='1' className={classes.olSuperiorElement}>{technicalConditionContent && technicalConditionContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='a' className={classes.liSubElement}> {technicalConditionList1Content && technicalConditionList1Content.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='1' className={classes.olSuperiorElement} start={2}>{technicalConditionContent2 && technicalConditionContent2.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
               
                    <Box className={classes.description}>
                    <ol type='a' className={classes.liSubElement}>{technicalConditionList2Content && technicalConditionList2Content.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='1' className={classes.olSuperiorElement} start={3}>{technicalConditionContent3 && technicalConditionContent3.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
               
                {/* Treści zamieszczane na Platformie */}
              
                    <Box className={classes.desctiptionTitle}>
                        {tContent('contentOnPageTitle')}
                    </Box>
               
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{contentOnPageContent && contentOnPageContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='a' className={classes.liSubElement}>{contentOnPageListContent && contentOnPageListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                
               
                {/* Konto pacjenta */}
                
                    <Box className={classes.desctiptionTitle}>
                        {tContent('patientAccountTitle')}
                    </Box>
            
                    <Box className={classes.description}>
                    <ol type='1' className={classes.olSuperiorElement}>{patientAccountContent && patientAccountContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='a' className={classes.liSubElement}>{patientAccountListContent && patientAccountListContent.map((d, i)=> <li key={i}>{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol start={4} type='1' className={classes.olSuperiorElement}>{patientAccountContent2 && patientAccountContent2.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
               
                    <Box className={classes.description}>
                        <ol type='a' className={classes.liSubElement}>{patientAccountListContent2 && patientAccountListContent2.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
               
                    <Box className={classes.description}>
                    <ol start={8} type='1' className={classes.olSuperiorElement}>{patientAccountContent3 && patientAccountContent3.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>

                {/* Korzystanie z Platformy  */}
                
                    <Box className={classes.desctiptionTitle}>
                        {tContent('useOfPageTitle')}
                    </Box>
               
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{useOfPageContent && useOfPageContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='a' className={classes.liSubElement}>{useOfPageListContent && useOfPageListContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='1' start={2} className={classes.olSuperiorElement}>{useOfPageContent2 && useOfPageContent2.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                {/* Ogólne warunki świadczenia Usług Medycznych i Usługi Badań Laboratoryjnych   */}
               
                    <Box className={classes.desctiptionTitle}>
                        {tContent('generalMedicalServicesConditionTitle')}
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{generalMedicalServicesConditionContent && generalMedicalServicesConditionContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='a' className={classes.liSubElement}>{generalMedicalServicesConditionListContent && generalMedicalServicesConditionListContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
               
                    <Box className={classes.description}>
                    <ol type='1' start={13} className={classes.olSuperiorElement}>{generalMedicalServicesConditionContent2 && generalMedicalServicesConditionContent2.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='a' className={classes.liSubElement}>{generalMedicalServicesConditionList2Content && generalMedicalServicesConditionList2Content.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='1' start={17} className={classes.olSuperiorElement}>{generalMedicalServicesConditionContent3 && generalMedicalServicesConditionContent3.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
               

                {/* Opłaty i metody płatności za Usługi Medyczne i Usługi Badań Laboratoryjnych  */}
                
                    <Box className={classes.desctiptionTitle}>
                        {tContent('paymentConditiontTitle')}
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='1' className={classes.olSuperiorElement}>{paymentConditiontContent && paymentConditiontContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='a' className={classes.liSubElement}>{paymentConditiontListContent && paymentConditiontListContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='1' start={4} className={classes.olSuperiorElement}>{paymentConditiontContent2 && paymentConditiontContent2.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                

                {/* Odpowiedzialność  */}
                
                    <Box className={classes.desctiptionTitle}>
                        {tContent('responsibilityTitle')}
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{responsibilityContent && responsibilityContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='a' className={classes.liSubElement}>{responsibilityListContent && responsibilityListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='1' start={2} className={classes.olSuperiorElement}>{responsibilityContent2 && responsibilityContent2.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                

                {/* Prawo do odstąpienia od Umowy przez Pacjenta będącego Konsumentem   */}
               
                    <Box className={classes.desctiptionTitle}>
                        {tContent('withdrawalFromTheContractTitle')}
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{withdrawalFromTheContractContent && withdrawalFromTheContractContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='a' className={classes.liSubElement}>{withdrawalFromTheContractListContent && withdrawalFromTheContractListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                {/* Reklamacje  */}
               
                    <Box className={classes.desctiptionTitle}>
                        {tContent('complaintsTitle')}
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='1' >{complaintsContent && complaintsContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                

                {/* Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń   */}
                
                    <Box className={classes.desctiptionTitle}>
                        {tContent('complaintsResultTitle')}
                    </Box>
                
                    <Box className={classes.description}>
                    <ol type='1' >{complaintsResultContent && complaintsResultContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                

                {/* Ochrona danych osobowych */}
                
                    <Box className={classes.desctiptionTitle}>
                        {tContent('privacyDataProtectionTitle')}
                    </Box>
               
                    <Box className={classes.description}>
                        {tContent('privacyDataProtectionContent')}
                    </Box>
                

                {/* Promocje */}
               
                    <Box className={classes.desctiptionTitle}>
                        {tContent('saleTitle')}
                    </Box>
               
                    <Box className={classes.description}>
                        <ol type='1' >{saleContent && saleContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                

                {/* Zmiana Regulaminu */}
               
                    <Box className={classes.desctiptionTitle}>
                        {tContent('termsAndConditionChangesTitle')}
                    </Box>
              
                    <Box className={classes.description}>
                        <ol type='1' className={classes.olSuperiorElement}>{termsAndConditionChangesContent && termsAndConditionChangesContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='a' className={classes.liSubElement}>{termsAndConditionChangesListContent && termsAndConditionChangesListContent.map((d, i)=> <li key={i}  >{d}</li>)}</ol>
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='1' start={3} className={classes.olSuperiorElement}>{termsAndConditionChangesContent2 && termsAndConditionChangesContent2.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
               
                {/* Postanowienia końcowe */}
                
                    <Box className={classes.desctiptionTitle}>
                        {tContent('finalTitle')}
                    </Box>
                
                    <Box className={classes.description}>
                        <ol type='1' >{finalContent && finalContent.map((d, i)=> <li key={i} >{d}</li>)}</ol>
                    </Box>
                
                

           
                    <Box className={classes.description}>
                        {tContent('downloadTermsAndConditions')}
                    <IconButton
                        onClick={() => downloadTermsAndConditionsPdf()}>
                        <img src={PdfImage} width={24} />
                    </IconButton>
                    </Box>
           
             
                    <hr/>
               
                    <Box className={classes.desctiptionTitle}>
                    {tContent('exampleWithdrawalForm')}    
                    </Box>
                
                    <Box className={classes.description}>
                    {tContent('downloadWithdrawalFormPdf')} 
                    <IconButton
                        onClick={() => downloadAttachmentPdf()}>
                        <img src={PdfImage} width={24} />
                    </IconButton>
                    </Box>
               
                    <Box className={classes.description}>
                    {tContent('downloadWithdrawalFormDoc')}  
                    <IconButton
                        onClick={() => downloadAttachmentDoc()}>
                        <img src={docFileIcon} width={24} />
                    </IconButton>
                    </Box>
               
            <Loading loading={loading} withBackdrop />
        </Box>
        </>
    );
};
export default TermsAndConditions;
