
import { makeStyles, createStyles, Theme} from "@material-ui/core";
import { WHITE, MAGENTA, GRAY} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container:{
            justifyContent: 'flex-end',
            alignItems:'center',
            backgroundColor: WHITE,
            paddingTop:16,
            [theme.breakpoints.up('md')]: {
                paddingTop: 24,
            }
            
        },
        buttonSelected:{
            backgroundColor: WHITE,
            borderColor: MAGENTA,
            border: '1px solid',
            minWidth:'83px',
            height:'40px',
            borderRadius:3,
            fontSize:16,
            color:MAGENTA,
            marginRight:24
        },
        button:{
            backgroundColor: WHITE,
            borderColor: GRAY,
            border: '1px solid',
            minWidth:'83px',
            height:'40px',
            borderRadius:3,
            fontSize:16,
            color:GRAY,
            marginRight:24
        },
        editIcon:{
            height: 20,
            width: 20,
        }
        
    }),
);

export default useStyles;
