import {getToken} from '../storage/storage';
import jwtDecode from 'jwt-decode';
import {DecodedAuthToken} from '../types/auth';
import {ADMIN, MEDIC, PATIENT, Role} from '../const/auth';
import {isArray, isEqual} from 'lodash-es';

export const getUserRoleFromToken = () => {
    const token = getToken();
    if (!token) return null;
    const decodedToken = jwtDecode<DecodedAuthToken>(token);
    return decodedToken?.role;
};

// Used to check if user has the exact roles as specified in parameter
export const hasUserRoles = (roles: Role[]) => {
    const roleFromToken = getUserRoleFromToken();
    return isArray(roleFromToken)
        ? isEqual(roleFromToken.sort(), roles.sort())
        : false;
};

export const isRoleAllowedToRefreshToken = (role: Role) =>
    role === MEDIC || role === PATIENT || role === ADMIN;
