import {makeStyles, Theme} from "@material-ui/core";
import {WHITE} from "../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: '8px 0 0',
        padding: 16,
        backgroundColor: WHITE,
        [theme.breakpoints.up('md')]: {
            margin: 24,
            padding: 24,
        }
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '24px',
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        }
    },
    yearSelect: {
        width: '100%',
        marginTop: 16,
        [theme.breakpoints.up('sm')]: {
            marginTop: 24,
            width: 276,
        }
    },
    listItem: {
        margin: '24px 0 0',
        padding: 0,
    },
    datePeriod: {
        marginLeft: '16px',
        fontSize: 14,
        lineHeight: '16px',
    }
}));

export default useStyles;