import * as Sentry from '@sentry/browser';
import configuration from '../configuration';
import {CaptureConsole, HttpClient} from '@sentry/integrations';

class SentryService {
    initialize() {
        Sentry.init({
            dsn: 'https://5a510db9b7f14ccea874de4a5554d3c6@o4505522637897728.ingest.sentry.io/4505522639994880',
            environment: configuration.environment,
            integrations: [
                new Sentry.BrowserTracing({
                    tracePropagationTargets: [window.location.origin],
                }),
                new Sentry.Replay({
                    networkDetailAllowUrls: [
                        window.location.origin,
                        configuration.baseURL,
                    ],
                    networkRequestHeaders: ['Cache-Control'],
                    networkResponseHeaders: ['Referrer-Policy'],
                }),
                new CaptureConsole({
                    levels: ['error'],
                }),
                new HttpClient({
                    failedRequestStatusCodes: [
                        [500, 599],
                        [400, 499],
                    ],
                    failedRequestTargets: [/.*/],
                }),
            ],
            sendDefaultPii: true,
            tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}

export default new SentryService();
