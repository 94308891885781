import React, {ChangeEvent, FormEvent, useEffect} from 'react';
import useStyles from './QuestionnaireFormStyles';
import {
    Box,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch, TextareaAutosize, TextField,
} from '@material-ui/core';
import {Button, CustomTextField, Label, Loading} from '../../index';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    basicAnswersSpecified,
    consultationReasonsSpecified,
    fetchQuestionnaire,
    postQuestionnaire,
    questionnaireFieldAnswerSpecified,
    selectConsultationReasons, selectQuestionnaireBodyMetric,
    selectQuestionnaireFieldEntries,
    selectQuestionnaireStatus,
    statusSpecified,
} from '../../../store/questionnaireSlice';
import {MAGENTA} from '../../../const/colors';
import {isEmpty} from 'lodash-es';
import {useHistory, useParams} from 'react-router';
import {USER_ACCOUNT_PAGE} from '../../../const/routes';
import useQuestionnaireForm from '../../../hooks/useQuestionnaireForm';

const QuestionnaireForm = () => {

    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.QUESTIONNAIRE);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();
    const questionnaireFieldEntries = useAppSelector(
        selectQuestionnaireFieldEntries,
    );
    const history = useHistory();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const reasons = useAppSelector(selectConsultationReasons);
    const status = useAppSelector(selectQuestionnaireStatus);
    const {bodyHeight, bodyWeight} = useAppSelector(selectQuestionnaireBodyMetric);

    useEffect(() => {
        status === 'saved' && history.push(USER_ACCOUNT_PAGE);
    }, [history, status]);

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (isEmpty(errors)) {
            dispatch(basicAnswersSpecified(values));
            dispatch(postQuestionnaire({appointmentId}));
        }
    };

    const {handleBlur, touched, errors, handleChange, values} =
        useQuestionnaireForm(bodyHeight, bodyWeight);

    useEffect(() => {
        dispatch(fetchQuestionnaire({appointmentId}));
        return () => {
            dispatch(statusSpecified('idle'));
        };
    }, [dispatch]);


    const onQuestionnaireFieldChange = (
        id: number,
        group: number,
        e: ChangeEvent<HTMLInputElement>,
    ) => {
        const {value, checked, type} = e.target;
        if (type === 'checkbox') {
            dispatch(
                questionnaireFieldAnswerSpecified({
                    id,
                    group,
                    value: checked,
                }),
            );
        } else {
            dispatch(
                questionnaireFieldAnswerSpecified({
                    id,
                    group,
                    value: true,
                    detailsValue: value,
                }),
           );
        }
    };

    const onChangeConsultationReasons = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(consultationReasonsSpecified(e.target.value));
    };

    const getMappedFieldEntries = (group: number) =>
        questionnaireFieldEntries
            .filter(entry => entry.questionnaireFieldDefinition.group === group)
            .map(
                ({
                    questionnaireFieldDefinition: field,
                    value,
                    detailsValue,
                }) => (
                    <Grid key={field.id} container direction="column">
                        <Grid
                            container
                            className={classes.fieldEntryContainer}
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={value || false}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>,
                                        ) =>
                                            onQuestionnaireFieldChange(
                                                field.id,
                                                field.group,
                                                e,
                                            )
                                        }
                                        color={'secondary'}
                                        name={'switch_' + field.id}
                                    />
                                }
                                label={
                                    <Box style={{fontSize: 16}}>
                                        {field.questionText}
                                    </Box>
                                }
                                className={classes.switchField}
                            />
                            {value ? (
                                <Box style={{color: MAGENTA}}>{commonT('yes').toUpperCase()}</Box>
                            ) : (
                                <Box>{commonT('no').toUpperCase()}</Box>
                            )}
                        </Grid>
                        {field.details && value && (
                            <Box className={classes.detailsTextWrapper}>
                                <Label htmlFor={'detailsText_' + field.id}>
                                    {field.detailsText}
                                </Label>
                                <CustomTextField
                                    id={'detailsText_' + field.id}
                                    name={'detailsText_' + field.id}
                                    type="text"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) =>
                                        onQuestionnaireFieldChange(
                                            field.id,
                                            field.group,
                                            e,
                                        )
                                    }
                                    value={detailsValue}
                                />
                            </Box>
                        )}
                    </Grid>
                ),
            );

    return (
        <form onSubmit={onSubmit}>
            <Loading loading={status === 'loading'} withBackdrop />
            <Grid
                container
                className={classes.commonSection}
                direction="column"
                justifyContent="space-between"
            >
                <Box className={classes.infoTitle}>
                    {t('reasonForMedicalConsultation')}
                </Box>
                <FormGroup style={{marginBottom: 24}}>
                    <Label htmlFor="reasons">{t('pleaseName')}:</Label>
                    <TextField
                        id="reasons"
                        name="reasons"
                        type="text"
                        multiline
                        minRows={3}
                        onChange={onChangeConsultationReasons}
                        value={reasons}
                        variant={'outlined'}
                        fullWidth
                        className={classes.multilineInput}
                    />
                </FormGroup>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                className={classes.commonSection}
            >
                <Box className={classes.infoTitle}>{t('healthInfoTitle')}</Box>
                <Box className={classes.sectionContent}>
                    {t('healthInfoContent')}
                </Box>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                className={classes.commonSection}
            >
                <Grid container>
                    <FormGroup
                        className={classes.formGroup}
                        style={{
                            marginRight: 24,
                            marginBottom: 24,
                        }}
                    >
                        <Label htmlFor="bodyHeight">
                            <span style={{fontWeight: 'bold'}}>
                                {t('height')}
                            </span>{' '}
                            (cm)
                        </Label>
                        <CustomTextField
                            id="bodyHeight"
                            name="bodyHeight"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bodyHeight}
                            helperText={
                                touched.bodyHeight && t(errors.bodyHeight)
                            }
                            error={touched.bodyHeight && !!errors.bodyHeight}
                        />
                    </FormGroup>

                    <FormGroup
                        className={classes.formGroup}
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Label htmlFor="bodyWeight">
                            <span style={{fontWeight: 'bold'}}>
                                {t('weight')}
                            </span>{' '}
                            (kg)
                        </Label>
                        <CustomTextField
                            id="bodyWeight"
                            name="bodyWeight"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bodyWeight}
                            helperText={
                                touched.bodyWeight && t(errors.bodyWeight)
                            }
                            error={touched.bodyWeight && !!errors.bodyWeight}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                className={classes.commonSection}
            >
                <Box className={classes.sectionContent}>
                    <span style={{fontWeight: 'bold'}}>
                        {t('doYouHaveAnyOfTheFollowingDiseases')}
                    </span>{' '}
                    {t('inParentheses')}
                    {getMappedFieldEntries(1)}
                </Box>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                className={classes.commonSection}
            >
                <Box className={classes.sectionContent}>
                    <span style={{fontWeight: 'bold'}}>
                        {t('takingMedicines')}
                    </span>
                    {getMappedFieldEntries(2)}
                </Box>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                className={classes.commonSection}
            >
                <Box className={classes.sectionContent}>
                    <span style={{fontWeight: 'bold'}}>
                        {t('surgicalProcedures')}
                    </span>
                    {getMappedFieldEntries(3)}
                </Box>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                className={classes.commonSection}
            >
                <Box className={classes.sectionContent}>
                    <span style={{fontWeight: 'bold'}}>
                        {t('questionsAboutWomen')}
                    </span>
                    {getMappedFieldEntries(4)}
                </Box>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                className={classes.commonSection}
            >
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    type={'submit'}
                    fullWidth
                    className={classes.submitButton}
                >
                    {commonT('next')}
                </Button>
            </Grid>
        </form>
    );
};

export default QuestionnaireForm;
