import {QuestionnaireDto} from '../../types/appointments';

const mapQuestionnaire = (questionnaire: QuestionnaireDto) => ({
    ...questionnaire,
    questionnaireFieldEntries: questionnaire.questionnaireFieldEntries.map(
        entry => ({
            ...entry,
            value:
                entry.questionnaireFieldDefinition.defaultValue || entry.value,
        }),
    ),
});

export default mapQuestionnaire;
