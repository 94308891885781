import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import {v4} from 'uuid';
import React from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchPrescriptionHistory,
    pageSpecified,
    selectCount,
    selectPage,
    selectPrescriptionHistory,
    selectSize,
    sizeSpecified,
} from '../../../store/prescriptionHistorySlice';
import {AppointmentDetailsForPrescriptionDto} from '../../../types/appointments';

type PrescriptionHistoryTableProps = {
    appointmentDetails: AppointmentDetailsForPrescriptionDto;
};

const useStyles = makeStyles(() =>
    createStyles({
        table: {
            minWidth: 400,
        },
    }),
);

const PrescriptionHistoryTable = ({
    appointmentDetails,
}: PrescriptionHistoryTableProps) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const dispatch = useAppDispatch();
    const page = useAppSelector(selectPage);
    const size = useAppSelector(selectSize);
    const count = useAppSelector(selectCount);
    const prescriptionHistory = useAppSelector(selectPrescriptionHistory);

    const handleChangePage = (_: unknown, newPage: number) => {
        dispatch(pageSpecified(newPage));
        dispatch(fetchPrescriptionHistory(appointmentDetails));
    };

    const handleChangeRowsPerPage = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        dispatch(sizeSpecified(parseInt(event.target.value, 10)));
        dispatch(fetchPrescriptionHistory(appointmentDetails));
    };

    return (
        <>
            <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    disableElevation
                    style={{marginBottom: 30}}
                    onClick={() =>
                        dispatch(fetchPrescriptionHistory(appointmentDetails))
                    }
                >
                    {t('refresh')}
                </Button>
            </Box>
            <TableContainer>
                <Table className={classes.table} size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('issueDate')}</TableCell>
                            <TableCell>{t('status')}</TableCell>
                            <TableCell align="left">{t('drugName')}</TableCell>
                            <TableCell align="right">
                                {t('drugCount')}
                            </TableCell>
                            <TableCell align="right">{t('packSize')}</TableCell>
                            <TableCell align="right">
                                {t('paymentRate')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prescriptionHistory?.map(prescription => (
                            <TableRow key={prescription.prescriptionKey + v4()}>
                                <TableCell component="th" scope="row">
                                    {new Date(
                                        prescription.createDateTime,
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell align="left">
                                    {prescription.prescriptionStatus}
                                </TableCell>
                                <TableCell align="left">
                                    {prescription.medicinalProductName}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.quantity}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.packageSize}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.paymentRate}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={size}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{style: {width: 'auto'}}}
            />
        </>
    );
};

export default PrescriptionHistoryTable;
