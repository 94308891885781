import {
    Box,
    Grid,
    IconButton,
    Modal,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {
    Button,
    Confirmation,
    Loading,
    MedicCalendarAddTimeSlots,
    MedicCalendarDateSelection,
    MedicCalendarHeader,
    MedicCalendarList,
    SideMenuWrapper,
} from '../../components';
import {REFRESH_TIME} from '../../const/commonConstants';
import i18nNamespaces from '../../const/i18nNamespaces';
import {MEDIC_CALENDAR_ADD_TIME_SLOT} from '../../const/routes';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import configurationProvider from '../../services/configurationProvider';
import {selectServiceDuration} from '../../store/dayScheduleTemplatesSlice';
import {
    fetchMedicCalendarEntries,
    selectMedicCalendarEntriesStatus,
    selectMedicCalendarSettingsStatus,
} from '../../store/medicCalendarSlice';
import useStyles from './MedicCalendarStyles';

const MedicCalendar = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [addTimeSlotsModalOpen, setAddTimeSlotsModalOpen] = useState(false);
    const [removingTimeSlot, setRemovingTimeSlot] = useState(false);
    const [message, setMessage] = useState<string>(undefined);
    const [serviceDuration, setServiceDuration] = useState<number>(null);
    const [defaultLoading, setLoading] = useState<boolean>(false);

    const fetchMedicalServiceDefaultDurationData = async () => {
        try {
            setLoading(true);
            const result =
                await configurationProvider.getMedicalServiceDefaultDuration();
            setServiceDuration(result.data);
        } catch (error) {
            if (axios.isAxiosError(error) && error?.response?.status === 404)
                return;
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const defaultServiceDuration = useAppSelector(selectServiceDuration);

    useEffect(() => {
        setServiceDuration(defaultServiceDuration);
        fetchMedicalServiceDefaultDurationData();
    }, []);

    useEffect(() => {
        dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
        const intervalId = setInterval(() => {
            dispatch(fetchMedicCalendarEntries({fetchSilently: true}));
        }, REFRESH_TIME);

        return () => {
            clearInterval(intervalId);
        };
    }, [dispatch]);

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const addTimeSlotsHandler = () => {
        if (isDesktop) {
            setAddTimeSlotsModalOpen(true);
        } else {
            history.push(MEDIC_CALENDAR_ADD_TIME_SLOT);
        }
    };

    const closeMessageHandler = () => {
        setMessage(null);
    };

    const entriesStatus = useAppSelector(selectMedicCalendarEntriesStatus);
    const settingsStatus = useAppSelector(selectMedicCalendarSettingsStatus);

    const timeSlotsAddedHandler = () => {
        setAddTimeSlotsModalOpen(false);
        dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
    };

    const loading =
        entriesStatus === 'loading' ||
        (isDesktop && settingsStatus === 'loading') ||
        removingTimeSlot ||
        defaultLoading;

    const {t} = useTranslation(i18nNamespaces.MEDIC_CALENDAR);

    const classes = useStyles();

    return (
        <>
            <Loading withBackdrop={true} loading={loading} />
            <Confirmation
                open={!!message}
                onClose={closeMessageHandler}
                message={message}
            />
            <SideMenuWrapper
                menu={
                    <MedicCalendarDateSelection
                        withConfirmationButton={false}
                        onAddTimeSlotsHandler={addTimeSlotsHandler}
                    />
                }
            >
                <Grid
                    container
                    direction="column"
                    alignContent="flex-start"
                    alignItems="stretch"
                    className={classes.medicCalendarContent}
                >
                    <MedicCalendarHeader />
                    <MedicCalendarList
                        setRemovingTimeSlot={setRemovingTimeSlot}
                        setMessage={setMessage}
                    />
                    {!isDesktop && (
                        <Box className={classes.addAppointmentContainer}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.addAppointmentButton}
                                onClick={addTimeSlotsHandler}
                            >
                                {t('addAppointment')}
                            </Button>
                        </Box>
                    )}
                </Grid>
            </SideMenuWrapper>

            <Modal open={addTimeSlotsModalOpen && isDesktop}>
                <Box className={classes.modalBackground}>
                    <Box className={classes.modalContent}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box className={classes.modalHeaderText}>
                                {t('addAppointment')}
                            </Box>
                            <IconButton
                                onClick={() => setAddTimeSlotsModalOpen(false)}
                            >
                                <Close />
                            </IconButton>
                        </Grid>
                        {serviceDuration && (
                            <MedicCalendarAddTimeSlots
                                onTimeSlotsAdded={timeSlotsAddedHandler}
                                serviceDuration={serviceDuration}
                            />
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default MedicCalendar;
