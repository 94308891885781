import {makeStyles, Theme} from '@material-ui/core';
import {
    CONVERSATION_BACKGROUND,
    LIGHT_GRAY,
    PRIMARY,
} from '../../../../const/colors';

const PARTICIPANT_VIDEO_HEIGHT = 'calc(calc(var(--vh, 1vh) * 100) - 169px)';

const useStyles = makeStyles((theme: Theme) => ({
    video: {
        position: 'fixed',
        right: 16,
        bottom: 16,
        height: 240,
        zIndex: 500,
        borderRadius: 3,
        [theme.breakpoints.down('md')]: {
            height: 97,
        },
    },
    mirrorImage: {
        transform: `rotateY(180deg)`,
    },
    remoteParticipantVideo: {
        zIndex: -600,
        height: PARTICIPANT_VIDEO_HEIGHT,
        position: 'fixed',
        transform: 'translate(-25%, 0)',
    },
    callEndButton: {
        width: 48,
        height: 48,
        display: 'flex',
        minHeight: 'auto',
        minWidth: 'auto',
    },
    statusScreen: {
        backgroundColor: CONVERSATION_BACKGROUND,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: 'white',
        height: PARTICIPANT_VIDEO_HEIGHT,
    },
    disabledVideo: {
        backgroundColor: PRIMARY,
        width: 341,
        height: 240,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        color: LIGHT_GRAY,
    },
    tabs: {
        backgroundColor: 'white',
    },
    muteIcon: {
        marginTop: 16,
        marginLeft: 16,
        zIndex: 200,
        position: 'absolute',
    },
}));

export default useStyles;
