import {withStyles} from '@material-ui/core/styles';
import {Rating} from '@material-ui/lab';
import {MAGENTA} from '../../../const/colors';

const CustomRating = withStyles({
    iconFilled: {
        color: MAGENTA,
    },
})(Rating);

export default CustomRating;
