import {
    Box,
    IconButton,
    Snackbar,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import Video, {
    LocalTrack,
    LocalVideoTrackPublication,
    Room,
} from 'twilio-video';
import {Loading} from '../../components';
import Settings from '../../components/conversations/settings/Settings';
import TopBar from '../../components/Layout/TopBar/TopBar';
import ConversationProvider from '../../services/ConversationProvider';
import {mediaErrorHandler, consultationErrorHandler} from '../../utils/error';
import useStyles from './VideoPageStyles';
import {isSupported} from 'twilio-video';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    audioInputDevicesSpecified,
    audioInputOnSpecified,
    audioOutputDevicesSpecified,
    roomSpecified,
    selectAppointmentDetails,
    selectAudioInputDevices,
    selectAudioInputOn,
    selectConsultationStatus,
    selectedAudioInputDeviceIdSpecified,
    selectedAudioOutputDeviceIdSpecified,
    selectedVideoInputDeviceIdSpecified,
    selectRoom,
    selectSelectedAudioInputDeviceId,
    selectSelectedVideoInputDeviceId,
    selectVideoInputDevices,
    selectVideoInputOn,
    selectVideoStream,
    serviceTokenSpecified,
    videoInputDevicesSpecified,
    videoInputOnSpecified,
    videoStreamSpecified,
} from '../../store/consultationSlice';
import {Close} from '@material-ui/icons';
import {getAppointmentDetailsPageUrlById} from '../../utils/appointment';
import VideoRoomContainer from '../../components/conversations/video/VideoRoomContainer/VideoRoomContainer';
import {AxiosError} from 'axios';
import {CustomInputChangeEvent} from '../../types/common';
import {
    useAllPatientDetailsForMedicLoading,
    useAppointmentDetailsFetchForOngoingConsultation,
    useHangoutAppointmentByStatus,
} from '../../hooks/appointmentDetailsHooks';
import {selectAuthUserData} from '../../store/auth';
import ConsultationInfo from '../../components/conversations/ConsultationInfo/ConsultationInfo';
import {removeMessagesAll} from '../../store/consultationMessagesSlice';

const VideoPage = () => {
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);
    const history = useHistory();
    const classes = useStyles();
    const {consultationId} = useParams<{consultationId: string}>();
    const dispatch = useAppDispatch();
    const consultationDetails = useAppSelector(selectAppointmentDetails);
    const consultationStatus = useAppSelector(selectConsultationStatus);
    const videoStream = useAppSelector(selectVideoStream);
    const videoInputOn = useAppSelector(selectVideoInputOn);
    const selectedAudioInputDeviceId = useAppSelector(
        selectSelectedAudioInputDeviceId,
    );
    const selectedVideoInputDeviceId = useAppSelector(
        selectSelectedVideoInputDeviceId,
    );
    const room = useAppSelector(selectRoom);
    const audioInputOn = useAppSelector(selectAudioInputOn);
    const audioInputDevices = useAppSelector(selectAudioInputDevices);
    const videoInputDevices = useAppSelector(selectVideoInputDevices);
    const authUserData = useAppSelector(selectAuthUserData);
    const [error, setError] = useState<string>(null);
    const [videoOperationsLoading, setVideoOperationsLoading] =
        useState<boolean>(false);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [previousTracks, setPreviousTracks] = useState<LocalTrack[]>(null);

    useAppointmentDetailsFetchForOngoingConsultation(
        consultationId,
        consultationDetails,
    );

    const allPatientDetailsForMedicLoading =
        useAllPatientDetailsForMedicLoading();

    const loading =
        videoOperationsLoading ||
        consultationStatus === 'loading' ||
        allPatientDetailsForMedicLoading;

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

    const handleAudioInputDevice = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const devices = mediaDevices.filter(x => x.kind === 'audioinput');
            dispatch(audioInputDevicesSpecified(devices));
        },
        [dispatch],
    );

    const handleAudioOutputDevice = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const devices = mediaDevices.filter(x => x.kind === 'audiooutput');
            dispatch(audioOutputDevicesSpecified(devices));
        },
        [dispatch],
    );

    const handleVideoInputDevice = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const devices = mediaDevices.filter(x => x.kind === 'videoinput');
            dispatch(videoInputDevicesSpecified(devices));
        },
        [dispatch],
    );

    const gotAudioStream = () => {
        return ConversationProvider.getAudioInputDevices();
    };

    const gotVideoStream = (stream: MediaStream) => {
        stream
            .getVideoTracks()
            .forEach(
                (track: MediaStreamTrack) => (track.enabled = videoInputOn),
            );

        dispatch(videoStreamSpecified(stream));
        return ConversationProvider.getAudioInputDevices();
    };

    const setupMediaDevices = () => {
        const constraints = {
            audio: {
                deviceId: selectedAudioInputDeviceId
                    ? {exact: selectedAudioInputDeviceId}
                    : undefined,
            },
            video: {
                deviceId: selectedVideoInputDeviceId
                    ? {exact: selectedVideoInputDeviceId}
                    : undefined,
            },
        };
        return navigator.mediaDevices.getUserMedia(constraints);
    };

    useEffect(() => {
        const mediaStream = setupMediaDevices();

        mediaStream
            .then(gotVideoStream)
            .then(handleVideoInputDevice)
            .catch(gotMediaSetupError);

        mediaStream
            .then(gotAudioStream)
            .then(handleAudioInputDevice)
            .catch(gotMediaSetupError);

        mediaStream
            .then(gotAudioStream)
            .then(handleAudioOutputDevice)
            .catch(gotMediaSetupError);

        return () => {
            mediaStream.then((stream: MediaStream) => {
                stream.getTracks().forEach((track: MediaStreamTrack) => {
                    track.stop();
                });
            });
        };
    }, []);

    useEffect(() => {
        const mediaStream = setupMediaDevices();
        return () => {
            mediaStream.then((stream: MediaStream) => {
                stream.getTracks().forEach((track: MediaStreamTrack) => {
                    track.stop();
                });
            });
        };
    }, [selectedAudioInputDeviceId, selectedVideoInputDeviceId]);

    const handleSelectVideoInput = (e: CustomInputChangeEvent) => {
        dispatch(selectedVideoInputDeviceIdSpecified(e.target.value as string));
    };

    const handleSelectAudioInput = (e: CustomInputChangeEvent) => {
        dispatch(selectedAudioInputDeviceIdSpecified(e.target.value as string));
    };

    const handleSelectAudioOutput = (e: CustomInputChangeEvent) => {
        dispatch(
            selectedAudioOutputDeviceIdSpecified(e.target.value as string),
        );
    };

    const handleVideoTurnOnOff = useCallback(() => {
        if (videoStream !== null) {
            videoStream.getVideoTracks().forEach((track: MediaStreamTrack) => {
                track.enabled = !videoInputOn;
            });

            if (room) {
                setupVideoLocalTrack(room, !videoInputOn);
            }
            dispatch(videoInputOnSpecified(!videoInputOn));
        }
    }, [dispatch, room, videoInputOn, videoStream]);

    const handleAudioTurnOnOff = () => {
        if (videoStream !== null) {
            if (room) {
                setupAudioLocalTrack(room, !audioInputOn);
            }
            dispatch(audioInputOnSpecified(!audioInputOn));
        }
    };

    const createLocalTracks = useCallback(() => {
        return Video.createLocalTracks({
            audio: {deviceId: selectedAudioInputDeviceId},
            video: {deviceId: selectedVideoInputDeviceId}, //width,height
        });
    }, [selectedAudioInputDeviceId, selectedVideoInputDeviceId]);

    const setupVideoLocalTrack = (room: Room, on: boolean) => {
        room.localParticipant.videoTracks.forEach(
            (trackPub: LocalVideoTrackPublication) => {
                if (on) {
                    trackPub.track.enable();
                } else {
                    trackPub.track.disable();
                }
            },
        );
    };

    const setupAudioLocalTrack = (room: Room, on: boolean) => {
        room.localParticipant.audioTracks.forEach(
            (trackPub: Video.LocalAudioTrackPublication) => {
                if (on) {
                    trackPub.track.enable();
                } else {
                    trackPub.track.disable();
                }
            },
        );
    };

    const gotMediaSetupError = (error: Error) => {
        setVideoOperationsLoading(false);
        setError(t(mediaErrorHandler(error.name)));
    };

    const gotApiError = (error: AxiosError) => {
        setVideoOperationsLoading(false);
        setError(t(consultationErrorHandler(error.response.data[0])));
    };

    const handleStart = useCallback(() => {
        setVideoOperationsLoading(true);
        if (!isSupported) {
            setError(mediaErrorHandler('VideoNotSupported'));
        } else {
            ConversationProvider.getVideoToken(consultationId)
                .then(res => {
                    createLocalTracks()
                        .then((tracks: LocalTrack[]) => {
                            setPreviousTracks(tracks);
                            dispatch(serviceTokenSpecified(res.data));
                            Video.connect(res.data, {tracks}).then(
                                (room: Room) => {
                                    setupVideoLocalTrack(room, videoInputOn);
                                    setupAudioLocalTrack(room, audioInputOn);
                                    dispatch(roomSpecified(room));
                                    setVideoOperationsLoading(false);
                                },
                            );
                        })
                        .catch(gotMediaSetupError);
                })
                .catch(gotApiError);
        }
    }, [audioInputOn, createLocalTracks, videoInputOn, dispatch]);

    const handleHangout = useCallback(() => {
        dispatch(removeMessagesAll());

        if (room) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            room.localParticipant.tracks.forEach((trackPub: any) => {
                trackPub.track.stop();
                trackPub.track.disable();
            });
            room.disconnect();
        }
        dispatch(roomSpecified(null));
        dispatch(serviceTokenSpecified(null));
        history.push(
            getAppointmentDetailsPageUrlById(
                consultationId,
                authUserData.role === 'Medic',
            ),
        );
    }, [dispatch, room]);

    //updates video and audio stream
    const handleUpdate = () => {
        createLocalTracks()
            .then(tracks => {
                tracks.forEach(track => {
                    if (track.kind === 'audio') {
                        if (!audioInputOn) track.disable();
                    }
                    if (track.kind === 'video') {
                        if (!videoInputOn) track.disable();
                    }
                    room.localParticipant.publishTrack(track);
                });
                previousTracks.forEach(track => {
                    room.localParticipant.tracks.forEach((trackPub: any) => {
                        if (trackPub.trackName == track.id) {
                            trackPub.track.stop();
                            trackPub.track.disable();
                        }
                    });

                    room.localParticipant.unpublishTrack(track);
                });
                setPreviousTracks(tracks);
            })
            .then(() => {
                setShowSettings(false);
            });
    };

    useHangoutAppointmentByStatus(consultationId, handleHangout);

    return (
        <div>
            {room ? (
                <VideoRoomContainer
                    onUpdate={handleUpdate}
                    showSettings={showSettings}
                    setShowSettings={setShowSettings}
                    handleSelectAudioInput={handleSelectAudioInput}
                    handleSelectAudioOutput={handleSelectAudioOutput}
                    handleHangout={handleHangout}
                    handleVideoTurnOnOff={handleVideoTurnOnOff}
                    handleAudioTurnOnOff={handleAudioTurnOnOff}
                    handleSelectVideoInput={handleSelectVideoInput}
                    mobileView={!isDesktop}
                />
            ) : (
                <>
                    <TopBar welcomeDisabled>
                        {isDesktop && consultationStatus === 'loaded' && (
                            <ConsultationInfo
                                consultation={consultationDetails}
                            />
                        )}
                        <IconButton onClick={() => history.goBack()}>
                            <Close />
                        </IconButton>
                    </TopBar>

                    <Box className={classes.settingsContainer}>
                        {audioInputDevices && videoInputDevices ? (
                            <>
                                {!isDesktop &&
                                    consultationStatus === 'loaded' && (
                                        <ConsultationInfo
                                            consultation={consultationDetails}
                                        />
                                    )}
                                <Settings
                                    approve={handleStart}
                                    handleSelectAudioInput={
                                        handleSelectAudioInput
                                    }
                                    handleSelectAudioOutput={
                                        handleSelectAudioOutput
                                    }
                                    handleSelectVideoInput={
                                        handleSelectVideoInput
                                    }
                                    handleVideoTurnOnOff={handleVideoTurnOnOff}
                                    handleAudioTurnOnOff={handleAudioTurnOnOff}
                                    inAppointment={
                                        !handleSelectAudioInput &&
                                        !handleSelectVideoInput
                                    }
                                />
                            </>
                        ) : (
                            <Loading loading withBackdrop />
                        )}
                    </Box>
                </>
            )}
            <Snackbar
                open={!!error}
                autoHideDuration={5000}
                onClose={() => setError('')}
            >
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Loading withBackdrop loading={loading} />
        </div>
    );
};
export default VideoPage;
