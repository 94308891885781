import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeSlotInput: {
            height: '40px',
            width: '96px',
            fontSize: '16px',
        },
        timeSlotSeparator: {
            display: 'inline-block',
            width: '24px',
            textAlign: 'center',
        },
        removeTimeSlotWrapper: {
            marginLeft: '24px',
        },
        removeTimeSlot: {
            width: 26,
            height: 26,
            color: theme.palette.grey[500],
            cursor: 'pointer',
        },
        [theme.breakpoints.up('sm')]: {
            removeTimeSlot: {
                width: 22,
                height: 22,
            },
        },
    }),
);

export default useStyles;
