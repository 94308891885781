import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { LoadingStatus } from '../../const/loadingStatus';
import { PatientOrderedDiagnosticOrderHeaders } from '../../services/diagnostic/orderHeadersProvider';
import referralsProvider from '../../services/referralsProvider';
import mapOrderedDiagnosticOrderHeader from '../mappers/mapOrderedDiagnosticOrderHeader';
import { RootState } from '../store';

const sliceName = 'orderedDiagnosticOrderHeaders';

const orderedDiagnosticOrderHeadersAdapter = createEntityAdapter<PatientOrderedDiagnosticOrderHeaders>({
    selectId: orderedDiagnosticOrderHeaders => orderedDiagnosticOrderHeaders.id,
});

const initialState = orderedDiagnosticOrderHeadersAdapter.getInitialState({
    status: 'idle' as LoadingStatus,
    page: 0,
    size: 10,
    count: 0,
});

export type OrderedDiagnosticOrderHeadersSliceState = typeof initialState;

export const fetchOrderedDiagnosticOrderHeaders = createAsyncThunk<
    {
        orderedDiagnosticOrderHeaders: PatientOrderedDiagnosticOrderHeaders[];
        page: number;
        count: number;
    },
    string,
    {
        state: RootState;
    }
>(`${sliceName}/fetchOrderedDiagnosticOrderHeaders`, async (childId, thunkApi) => {
    const {page, size} = selectOrderedDiagnosticOrderHeaders(thunkApi.getState());
    const {
        page: resultPage,
        results,
        count,
    } = await referralsProvider.getOrderedMedicalTests(
        childId, 
        page,
        size
    )

    return {
        orderedDiagnosticOrderHeaders: results.map(mapOrderedDiagnosticOrderHeader),
        page: resultPage,
        count,
    };
});

const orderedDiagnosticOrderHeadersSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        pageSpecified(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        sizeSpecified(state, action: PayloadAction<number>) {
            state.size = action.payload;
        },
        clear(state) {
            state.page = initialState.page;
            state.size = initialState.size;
            state.count = initialState.count;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOrderedDiagnosticOrderHeaders.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchOrderedDiagnosticOrderHeaders.fulfilled, (state, action) => {
                state.status = 'loaded';

                state.page = action.payload.page;
                state.count = action.payload.count;
                orderedDiagnosticOrderHeadersAdapter.setAll(state, action.payload.orderedDiagnosticOrderHeaders);
            })
            .addCase(fetchOrderedDiagnosticOrderHeaders.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const {pageSpecified} = orderedDiagnosticOrderHeadersSlice.actions;
export const {sizeSpecified} = orderedDiagnosticOrderHeadersSlice.actions;

export const selectOrderedDiagnosticOrderHeaders = (state: RootState) => state.orderedDiagnosticOrderHeaders;

export const selectOrderedDiagnosticOrderHeadersStatus = (state: RootState) =>
    selectOrderedDiagnosticOrderHeaders(state).status;
export const selectOrderedDiagnosticOrderHeadersPage = (state: RootState) =>
    selectOrderedDiagnosticOrderHeaders(state).page;
export const selectOrderedDiagnosticOrderHeadersSize = (state: RootState) =>
    selectOrderedDiagnosticOrderHeaders(state).size;
export const selectOrderedDiagnosticOrderHeadersCount = (state: RootState) =>
    selectOrderedDiagnosticOrderHeaders(state).count;

export const {selectIds: selectOrderedDiagnosticOrderHeadersIds, selectById: selectOrderedDiagnosticOrderHeaderById} =
    orderedDiagnosticOrderHeadersAdapter.getSelectors<RootState>(state => selectOrderedDiagnosticOrderHeaders(state));

export default orderedDiagnosticOrderHeadersSlice.reducer;
