import {ChangeEvent} from 'react';
import {Autocomplete} from '@material-ui/lab';
import {xorBy} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';
import {
    selectSelectedMedicalServiceId,
    selectSelectedTags,
    selectTags,
    setSelectedTags,
} from '../../../../store/referrals/makePatientReferralSlice';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Tag} from '../../../../types/medic';
import {isNotNullOrUndefined} from '../../../../utils/lang';
import CustomChip from '../../../common/CustomChip/CustomChip';
import {TextField} from '@material-ui/core';

interface Props {
    fetchMedicAsync: (medicalServiceId: number, tagIds: number[]) => void;
}

const TagSelection = ({fetchMedicAsync}: Props) => {
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const tags = useAppSelector(selectTags);
    const dispatch = useAppDispatch();
    const selectedMedicalServiceId = useAppSelector(
        selectSelectedMedicalServiceId,
    );
    const selectedTags = useAppSelector(selectSelectedTags);

    const tagsSelectionChangeHandler = (
        _: ChangeEvent<unknown>,
        newSelectedTags: (Tag | string)[],
    ) => {
        const diff = xorBy(selectedTags, newSelectedTags as Tag[], 'id');
        if (diff.length) {
            dispatch(setSelectedTags(newSelectedTags as Tag[]));
            fetchMedicAsync(
                selectedMedicalServiceId,
                (newSelectedTags as Tag[]).map(tag => tag.id),
            );
        }
    };

    return (
        <Autocomplete
            multiple
            disabled={!isNotNullOrUndefined(selectedMedicalServiceId)}
            options={tags}
            value={selectedTags}
            getOptionLabel={tag => tag.name}
            onChange={tagsSelectionChangeHandler}
            renderInput={params => (
                <TextField
                    multiline
                    {...params}
                    label={tCommon('searchMedicByTag')}
                    variant="outlined"
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <CustomChip
                        key={option.id}
                        label={option.name}
                        {...getTagProps({index})}
                    />
                ))
            }
        />
    );
};

export default TagSelection;
