import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {GRAY, MAGENTA, WHITE} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                minHeight: `calc(100vh - 128px)`,
            },
            paddingLeft: 30,
            paddingRight: 30,
        },
        title: {
            marginTop: 32,
            fontSize: 36,
            paddingLeft: 16,
            paddingRight: 16,
            maxWidth: 820,
        },
        description: {
            maxWidth: 820,
            marginTop: 56,
            marginBottom: 80,
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16,
        },

        information: {
            maxWidth: 820,
            marginTop: 56,
            marginBottom: 80,
            fontSize: 14,
            paddingLeft: 16,
            paddingRight: 16,
            color: GRAY,
        },
        buyButton: {
            marginTop: '40px',
            width: 191,
            fontWeight: 'bold',
        },
        signInButton: {
            marginTop: '40px',
            width: 191,
            fontWeight: 'bold',
            marginLeft: '16px',
            color: MAGENTA,
            backgroundColor: WHITE,
            borderColor: MAGENTA,
            border: '1px solid',
        },
    }),
);

export default useStyles;
