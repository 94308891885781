import {useAppSelector} from '../../../hooks/customReduxHooks';
import MedicalTestItem from '../medicalTestItem/MedicalTestItem';
import { selectOrderedDiagnosticOrderHeaderById } from '../../../store/referrals/orderedDiagnosticOrderHeaderSlice';

type FinishedMedicalProps = {
    finishedDiagnosticServiceOrderId: string;
};

const FinishedMedicalTest = ({
    finishedDiagnosticServiceOrderId,
}: FinishedMedicalProps) => {

    const orderedDiagnosticOrderHeader = useAppSelector(state =>
        selectOrderedDiagnosticOrderHeaderById(state, finishedDiagnosticServiceOrderId),
    );

    return <MedicalTestItem orderedDiagnosticOrderHeader={orderedDiagnosticOrderHeader}/>;
};

export default FinishedMedicalTest;
