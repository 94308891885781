import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {AddCircle, ArrowBackIos, ArrowForwardIos} from '@material-ui/icons';
import {DatePicker} from '@material-ui/pickers';
import {DateTime} from 'luxon';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Button, Loading} from '../..';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {MEDIC_CALENDAR} from '../../../const/routes';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchMedicCalendarEntries,
    fetchMedicCalendarSettings,
    nextSelectedDateSpecified,
    previousSelectedDateSpecified,
    selectedDateSpecified,
    selectMedicCalendarMaxDate,
    selectMedicCalendarMinDate,
    selectMedicCalendarSelectedDate,
    selectMedicCalendarSettingsStatus,
} from '../../../store/medicCalendarSlice';
import {getRelativeDay} from '../../../utils/date';
import useStyles from './MedicCalendarDateSelectionStyles';

type MedicCalendarDateSelectionProps = {
    withConfirmationButton: boolean;
    onAddTimeSlotsHandler: () => void;
};

const MedicCalendarDateSelection = ({
    // Seems like withConfirmationButton is used only for testing purposes,
    // with default value set to true, only when visiting /medic-calendar-date-selection page,
    // which looks like testing page
    // TODO: To check if mentioned testing like page can be removed along with the parameter
    withConfirmationButton = true,
    onAddTimeSlotsHandler,
}: MedicCalendarDateSelectionProps) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const storeSelectedDate = useAppSelector(selectMedicCalendarSelectedDate);
    const [localSelectedDate, setLocalSelectedDate] = useState(
        storeSelectedDate.plus({}),
    );
    const selectedDate = withConfirmationButton
        ? localSelectedDate
        : storeSelectedDate;
    const settingsStatus = useAppSelector(selectMedicCalendarSettingsStatus);
    const minDate = useAppSelector(selectMedicCalendarMinDate);
    const maxDate = useAppSelector(selectMedicCalendarMaxDate);

    useEffect(() => {
        dispatch(fetchMedicCalendarSettings());
    }, [dispatch]);

    const dateChangeHandler = (date: Date) => {
        const dateTime = DateTime.fromJSDate(date);

        if (withConfirmationButton) { // See comments above about this parameter
            setLocalSelectedDate(dateTime);
        } else {
            dispatch(selectedDateSpecified(dateTime));
            dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
        }
    };

    const previousDayAvailable = selectedDate > minDate;
    const nextDayAvailable = selectedDate < maxDate;

    const nextDateHandler = () => {
        if (!nextDayAvailable) {
            return;
        }

        if (withConfirmationButton) { // See comments above about this parameter
            setLocalSelectedDate(selectedDate.plus({days: 1}));
        } else {
            dispatch(nextSelectedDateSpecified());
            dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
        }
    };

    const previousDateHandler = () => {
        if (!previousDayAvailable) {
            return;
        }

        if (withConfirmationButton) { // See comments above about this parameter
            setLocalSelectedDate(selectedDate.minus({days: 1}));
        } else {
            dispatch(previousSelectedDateSpecified());
            dispatch(fetchMedicCalendarEntries({fetchSilently: false}));
        }
    };

    const dateConfirmationHandler = () => {
        dispatch(selectedDateSpecified(selectedDate));

        history.push(MEDIC_CALENDAR);
    };

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const loading = !isDesktop && settingsStatus === 'loading';
    const {t} = useTranslation(i18nNamespaces.MEDIC_CALENDAR);
    const classes = useStyles();

    return (
        <>
            <Loading withBackdrop={true} loading={loading} />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.dateSelectionWrapper}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    className={classes.selectedDateWrapper}
                >
                    <Box>
                        <ArrowBackIos
                            color={
                                previousDayAvailable ? 'secondary' : 'disabled'
                            }
                            onClick={previousDateHandler}
                        />
                    </Box>
                    <Box className={classes.selectedDate}>
                        {getRelativeDay(selectedDate)}
                    </Box>
                    <Box>
                        <ArrowForwardIos
                            color={nextDayAvailable ? 'secondary' : 'disabled'}
                            onClick={nextDateHandler}
                        />
                    </Box>
                </Grid>
                <Box className={classes.datePickerWrapper}>
                    <DatePicker
                        variant="static"
                        ToolbarComponent={() => <></>}
                        value={selectedDate.toJSDate()}
                        minDate={minDate?.toJSDate()}
                        maxDate={maxDate?.toJSDate()}
                        onChange={dateChangeHandler}
                    />
                </Box>
                {withConfirmationButton && (
                    <Grid item className={classes.dateSelectionButtonWrapper}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.dateSelectionButton}
                            onClick={dateConfirmationHandler}
                        >
                            {t('chooseDate')}
                        </Button>
                    </Grid>
                )}
                {isDesktop && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.addAppointmentsSection}
                        onClick={onAddTimeSlotsHandler}
                    >
                        <AddCircle className={classes.addAppointmentIcon} />
                        <Box className={classes.addAppointmentText}>
                            {t('addAppointment')}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default MedicCalendarDateSelection;
