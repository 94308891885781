
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { GRAY, TEXT_SECONDARY, WHITE } from '../../../const/colors';

const useStyles = makeStyles((theme:Theme) =>
    createStyles({
        section:{
            backgroundColor: WHITE,
            margin: '25px',
            padding:'24px',
        },
        header:{
            fontSize:'24px',
            fontWeight: 700,
        },
        title:{
            fontSize:'16px',
            fontWeight: 400,
        },
        diagnosticServiceList: {
            display: 'list',
            listStyleType: 'disc',
            color: TEXT_SECONDARY,
            fontSize: 16,
            lineHeight: '24px',
            paddingLeft: 28,
            paddingTop:0,
        },
    }),

);

export default useStyles;
