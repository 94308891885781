import isUndefined from 'lodash-es/isUndefined';
import isNull from 'lodash-es/isNull';
import {LANGUAGES} from '../const/lang';

export const isNotNullOrUndefined = (value: unknown) => {
    return isNotUndefined(value) && isNotNull(value);
};

export const isNotUndefined = (value: unknown) => {
    return !isUndefined(value);
};

export const isNotNull = (value: unknown) => {
    return !isNull(value);
};

export const checkIfLanguageExists = (lang: string) =>
    LANGUAGES.some(val => val.value.includes(lang));

export const getPreparedLang = (lang: string) => lang.slice(0, 2).toLowerCase();

export const IsNumberNotNan = (value:number)=>{
    return !Number.isNaN(value);
}
