import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        commonSection: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
            marginBottom: mobile.contentSmallerSpacing,
        },
        switchField: {
            fontSize: 10,
        },
        fieldEntryContainer: {
            flexWrap: 'nowrap',
        },
        infoTitle: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 15,
        },
        multilineInput: {
            '& .MuiInputBase-root': {
                height: 'auto !important',
            },
        },
        [theme.breakpoints.up('sm')]: {
            page: {
                minHeight: '100vh',
            },
            pageContent: {
                flex: 1,
            },
            paymentSection: {
                padding: `32px ${desktop.contentLargerSpacing}`,
            },
            paymentTitleContainer: {
                marginBottom: 56,
            },
            paymentImage: {
                width: 150,
            },
            paymentTitle: {
                fontSize: 56,
                fontWeight: 'bold',
            },
            timeSlotDetails: {
                fontSize: 20,
            },
            commonSection: {
                padding: `32px ${desktop.contentLargerSpacing}`,
            },
            sectionContent: {
                width: 840,
            },
            infoTitle: {
                fontSize: 20,
                fontWeight: 'bold',
                marginBottom: 15,
            },
            switchField: {
                fontSize: 10,
            },
            submitButton: {
                width: 406,
            },
            detailsTextWrapper: {
                marginTop: 26,
            },
        },
    }),
);

export default useStyles;
