import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {BACKGROUND_PRIMARY, GRAY, MAGENTA} from "../../../../const/colors";
const textsCommonStyles = {
    size: 16,
    lineHeight: '24px',
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    photo: {
        width: '70px',
        height: '70px',
    },type: {
        ...textsCommonStyles,
        fontWeight: 'bold',
    },
    text: {
        ...textsCommonStyles,
    },
    
    link: {
        margin: '0',
        color: theme.palette.secondary.main,
        fontSize: 16,
        lineHeight: '24px',
        textAlign: 'end',
        textDecoration: 'none',
        overflowWrap: 'anywhere',
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('md')]: {
            marginRight:'16px'
        },
    },
    status:{
        ...textsCommonStyles,
        color:GRAY
    }
    
}));

export default useStyles;