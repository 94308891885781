import {makeStyles, Theme} from '@material-ui/core';
import {BLACK, GRAY} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    fileDetailsRemoveIcon: {
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: BLACK,
        [theme.breakpoints.up('md')]: {
            height: '18px',
            width: '18px',
        },
        cursor: 'pointer',
    },
    closeIcon: {
        width: '14px',
        height: '14px',
        color: 'white',
        [theme.breakpoints.up('md')]: {
            width: '12px',
            height: '12px',
        },
    },
    fileName: {
        fontSize: '14px',
        lineHeight: 1,
    },
    fileInfo: {
        marginTop: '8px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        fontSize: '14px',
        color: GRAY,
    },
    grayDot: {
        margin: '0 6px',
        height: '3px',
        width: '3px',
        borderRadius: '50%',
        backgroundColor: GRAY,
    },
    disabled: {
        filter: 'grayscale(1)',
        opacity: 0.5,
    },
}));

export default useStyles;
