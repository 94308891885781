import {Box, Grid, List, ListItem} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import subscriptionPaymentHistoryProvider from '../../../services/individualPackageSubscriptionWithPaymentsProvider';
import {IndividualPackageSubscriptionDto} from '../../../types/patientPackages';
import {SubscriptionTransactionDto} from '../../../types/subscriptionTransaction';
import {getLongTimeFromISO, getShortDateFromISO} from '../../../utils/date';
import Loading from '../../common/Loading/Loading';
import useStyles from './IndividualPackageSubscriptionPaymentHistoryStyles';

interface Props {
    subscription: IndividualPackageSubscriptionDto;
}

const IndividualPackageSubscriptionPaymentHistory = ({subscription}: Props) => {
    const {t} = useTranslation(i18nNamespaces.PATIENT_PACKAGES);
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>();
    const [paymentHistory, setPaymentHistory] = useState<
        SubscriptionTransactionDto[]
    >([]);

    useEffect(() => {
        setLoading(true);
        subscriptionPaymentHistoryProvider
            .getSubscriptionPaymentHistory(subscription.id)
            .then(setPaymentHistory)
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <Box className={classes.container}>
                <Box className={classes.horizontalPadding}>
                    <Box className={classes.historyTitle}>
                        {t('paymentHistory')}
                    </Box>
                    <List>
                        {paymentHistory.map((history, i) => (
                            <ListItem key={i} className={classes.list}>
                                <Grid container spacing={2}>
                                    <Grid item className={classes.dateLabel}>
                                        {getShortDateFromISO(history.date)}
                                    </Grid>
                                    <Grid item className={classes.dateLabel}>
                                        {getLongTimeFromISO(history.date)}
                                    </Grid>
                                    <Grid item>-{history.price} PLN</Grid>
                                    <Grid
                                        item
                                        className={
                                            history.status === 'PaidError'
                                                ? classes.errorLabel
                                                : ''
                                        }
                                    >
                                        {t(history.status)}
                                    </Grid>
                                    {history.isRefunded && 
                                        <Grid item>
                                            {`(${t('transactionRefunded')} 
                                            ${getShortDateFromISO(history.refundDateTime)} 
                                            ${getLongTimeFromISO(history.refundDateTime)})`}
                                        </Grid>
                                    }
                                    
                                    
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Loading loading={loading} withBackdrop />
            </Box>
        </>
    );
};

export default IndividualPackageSubscriptionPaymentHistory;
