import {FC, useCallback} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {DropzoneAreaBase, FileObject} from 'material-ui-dropzone';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    BACKGROUND_SECONDARY,
    GRAY,
    TEXT_SECONDARY,
} from '../../../const/colors';

const useStyles = makeStyles(() => ({
    dropzone: {
        height: '80px',
        minHeight: 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: BACKGROUND_SECONDARY,
    },
    dropzoneText: {
        color: TEXT_SECONDARY,
        fontSize: '16px',
        marginTop: '105px',
        marginLeft: '10px',
    },
    message: {
        marginTop: 8,
        color: GRAY,
    },
}));

interface DropZoneProps {
    disabled?: boolean;
    text: string;
    onAdd: (newFiles: File[]) => void;
    getDropRejectMessage: (
        rejectedFile: File,
        acceptedFiles: string[],
        maxFileSize: number,
    ) => string;
    className?: string;
    acceptedFiles?: string[];
}

const CustomDropzone: FC<DropZoneProps> = props => {
    const {disabled, onAdd, text, getDropRejectMessage} = props;
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const defaultPreferredFormats = '.pdf, .jpg, .jpeg, .doc, .docx.';

    const newFilesAddedHandler = useCallback((newFileObjects: FileObject[]) => {
        const newFiles = newFileObjects.map(
            newFileObject => newFileObject.file,
        );
        onAdd(newFiles);
    }, []);

    return (
        <Box className={props.className}>
            <DropzoneAreaBase
                fileObjects={[]}
                filesLimit={999}
                maxFileSize={20000000}
                dropzoneText={text}
                dropzoneProps={{
                    disabled: !!disabled,
                }}
                showPreviews={false}
                showPreviewsInDropzone={false}
                clearOnUnmount={true}
                showAlerts={false}
                onAdd={newFilesAddedHandler}
                dropzoneClass={classes.dropzone}
                dropzoneParagraphClass={classes.dropzoneText}
                getDropRejectMessage={getDropRejectMessage}
                acceptedFiles={props.acceptedFiles}
            />
            <Box className={classes.message}>
                {tCommon('maximumUploadFileSize')} - 20MB,{' '}
                {tCommon('preferredFormats').toLowerCase()}:{' '}
                {props.acceptedFiles
                    ? props.acceptedFiles.join(', ')
                    : defaultPreferredFormats}
            </Box>
        </Box>
    );
};

export default CustomDropzone;
