
import { makeStyles, createStyles} from "@material-ui/core";
import { WHITE, MAGENTA, GRAY} from "../../../const/colors";

const useStyles = makeStyles(() =>
    createStyles({
        container:{
            justifyContent: 'flex-end',
            justifyItems:'center',
            alignItems:'center',
            paddingTop: '24px'
        },
        buttonSelected:{
            backgroundColor: WHITE,
            borderColor: MAGENTA,
            border: '1px solid',
            minWidth:'83px',
            height:'40px',
            borderRadius:3,
            fontSize:16,
            color:MAGENTA
        },
        button:{
            backgroundColor: WHITE,
            borderColor: GRAY,
            border: '1px solid',
            minWidth:'83px',
            height:'40px',
            borderRadius:3,
            fontSize:16,
            color:GRAY
        },
        editIcon:{
            height: 20,
            width: 20,
        }
        
    }),
);

export default useStyles;
