import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import {WHITE} from '../../../const/colors';
import Footer from '../Footer/Footer';
import {LARGE_DESKTOP_BREAKPOINT} from '../../../const/sizes';
import TopBarRegular from "../TopBar/TopBar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageLayout: {
            minHeight: '100vh',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
        pageLayoutHeader: {
            zIndex: 1,
        },
        pageLayoutMain: {
            flexDirection: 'column',
            flexWrap: 'nowrap',
            flex: '1 0 auto',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        },
        pageLayoutLeftColumn: {
            flex: '0 0 auto',
            [theme.breakpoints.up('md')]: {
                padding: '0 20px 25px 25px',
                flex: '0 0 50%',
                minHeight: 'calc(100vh - 128px)',
                backgroundColor: WHITE,
            },
            [`@media ${LARGE_DESKTOP_BREAKPOINT}`]: {
                padding: '0 25px 25px 47px',
            },
        },
        pageLayoutRightColumn: {
            flex: '0 0 auto',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
                padding: '25px 25px 25px 20px',
            },
            [`@media ${LARGE_DESKTOP_BREAKPOINT}`]: {
                padding: '25px 47px 25px 25px',
            },
        },
        rightColumnMobileScreenBottomSticky: {
            display: 'flex',
            flex: '1 0 auto',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'stretch',
        },
    }),
);

interface CommonProps {
    children: JSX.Element | JSX.Element[];
    withoutFooter?: boolean;
}

const TwoColumnLayout = ({children, withoutFooter}: CommonProps) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.pageLayout}>
            {children}
            {!withoutFooter && <Footer />}
        </Grid>
    );
};

/*
 * bellow compound components pattern applied
 */

TwoColumnLayout.Header = function Header({children}: CommonProps) {
    const classes = useStyles();
    return <Box className={classes.pageLayoutHeader}>{children}</Box>;
};

TwoColumnLayout.Main = function Main({children}: CommonProps) {
    const classes = useStyles();
    return (
        <Grid className={classes.pageLayoutMain} container>
            {children}
        </Grid>
    );
};

TwoColumnLayout.LeftColumn = function LeftColumn({children}: CommonProps) {
    const classes = useStyles();
    return <Box className={classes.pageLayoutLeftColumn}>{children}</Box>;
};

interface RightColumnProps extends CommonProps {
    mobileScreenBottomSticky?: boolean;
}

TwoColumnLayout.RightColumn = function RightColumn({
    children,
    mobileScreenBottomSticky,
}: RightColumnProps) {
    const classes = useStyles();
    const isDesktopViewBreakPoint = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('md'),
    );
    return (
        <Box
            className={clsx(classes.pageLayoutRightColumn, {
                [classes.rightColumnMobileScreenBottomSticky]:
                    mobileScreenBottomSticky && !isDesktopViewBreakPoint,
            })}
        >
            {children}
        </Box>
    );
};

export default TwoColumnLayout;
