import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    picture: {
        width: 70,
        height: 70,
        borderRadius: '50%',
        [theme.breakpoints.up('sm')]: {
            width: 84,
            height: 84,
        }
    }
}));

export default useStyles;