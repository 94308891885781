import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    modalContentContainer: {
        maxWidth: 360,
    },
    modalHeaderText: {
        fontSize: 20,
        textAlign: 'center',
        lineHeight: '24px',
        fontWeight: 'bold',
    },
    modalText: {
        marginTop: 16,
        textAlign: 'center',
        fontSize: 16,
        lineHeight: '24px',
    },
    modalButton: {
        marginTop: 16,
    },
}));

export default useStyles;
