import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useFormik} from 'formik';
import {Box, Button, Grid, TextField, useTheme} from '@material-ui/core';
import useStyles from './NewPrescriptionFormStyles';
import prescriptionsApi from '../../../api/prescriptions/prescriptionsApi';
import {
    MedicationPackDto,
    MedicinalProductDto,
    Prescription,
} from '../../../types/prescription';
import {KeyboardDatePicker} from '@material-ui/pickers';
import * as yup from 'yup';
import {insuranceDeclarationApi} from '../../../api/insuranceDeclaration/insuranceDeclarationApi';
import MedicinalProductAutocomplete from '../MedicinalProductAutocomplete/MedicinalProductAutocomplete';
import MedicationPackSelect from '../MedicationPackSelect/MedicationPackSelect';
import ReimbursementSelect from '../ReimbursementSelect/ReimbursementSelect';
import {PatientInsuranceDeclarationDto} from '../../../types/insuranceDeclaration';

type NewPrescriptionFormProps = {
    onSubmit: (prescription: Prescription) => void;
    patientId: string;
};

const REQUIRED_STRING = 'requiredField';
const MIN_ONE = 'minOne';
const DECLARATION_REFRESH_INTERVAL = 10000;

const NewPrescriptionForm = ({
    onSubmit,
    patientId,
}: NewPrescriptionFormProps) => {
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useStyles();

    const [medicationPacks, setMedicationPacks] = useState<MedicationPackDto[]>(
        [],
    );
    const [patientInsuranceDeclaration, setPatientInsuranceDeclaration] =
        useState<PatientInsuranceDeclarationDto | null>(null);

    const formik = useFormik({
        initialValues: {
            medicinalProduct: null as MedicinalProductDto,
            medicationPackId: '' as number | string,
            paymentLevel: '0' as string,
            effectiveDate: new Date(),
            quantity: 1,
            usageRecommendation: '',
        },
        validationSchema: yup.object({
            medicinalProduct: yup
                .object()
                .nullable()
                .required(commonT(REQUIRED_STRING)),
            medicationPackId: yup
                .number()
                .nullable()
                .required(commonT(REQUIRED_STRING)),
            effectiveDate: yup.date().required(commonT(REQUIRED_STRING)),
            quantity: yup
                .number()
                .min(1, t(MIN_ONE))
                .required(commonT(REQUIRED_STRING)),
            usageRecommendation: yup
                .string()
                .required(commonT(REQUIRED_STRING)),
        }),
        onSubmit: values => {
            if (!values.medicinalProduct || !values.medicationPackId) return;
            onSubmit({
                medicinalProduct: values.medicinalProduct,
                medicationPack: medicationPacks.find(
                    pack => pack.id === values.medicationPackId,
                ),
                quantity: values.quantity,
                usageRecommendation: values.usageRecommendation,
                effectiveDate: values.effectiveDate.toISOString(),
                reimbursement:
                    values.paymentLevel === '0'
                        ? {paymentLevel: null, paymentLevelDisplayValue: '100%'}
                        : medicationPackReimbursements.find(
                              pack => pack.paymentLevel === values.paymentLevel,
                          ),
            });
            formik.setFieldValue('medicinalProduct', '');
            formik.resetForm();
        },
    });

    const medicationPackReimbursements = useMemo(
        () =>
            medicationPacks?.find(
                pack => pack.id === formik.values?.medicationPackId,
            )?.medicationPackReimbursements,
        [medicationPacks, formik.values?.medicationPackId],
    );

    const fetchInsuranceDeclaration = useCallback(async () => {
        try {
            const {data} =
                await insuranceDeclarationApi.getInsuranceDeclaration(
                    patientId,
                );
            if (!data.isSuccess) return;
            setPatientInsuranceDeclaration(data?.result);
        } catch (err) {
            console.error(err);
        }
    }, [patientId]);

    useEffect(() => {
        if (!patientId) return undefined;
        fetchInsuranceDeclaration();
        const intervalId = setInterval(() => {
            fetchInsuranceDeclaration();
        }, DECLARATION_REFRESH_INTERVAL);
        return () => clearInterval(intervalId);
    }, [patientId]);

    useEffect(() => {
        if (!formik.values.medicinalProduct) return undefined;
        (async () => {
            try {
                const {data} = await prescriptionsApi.getMedicationPackList(
                    formik.values.medicinalProduct?.id,
                );
                setMedicationPacks(data.result);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [formik.values.medicinalProduct]);

    const handleChangeMedicationPack = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        formik.setFieldValue('medicationPackId', event.target.value);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                    <MedicinalProductAutocomplete
                        onChange={newValue =>
                            formik.setFieldValue('medicinalProduct', newValue)
                        }
                        medicinalProduct={formik.values.medicinalProduct}
                        error={
                            formik.touched.medicinalProduct &&
                            Boolean(formik.errors.medicinalProduct)
                        }
                        helperText={
                            formik.touched.medicinalProduct &&
                            formik.errors.medicinalProduct
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <MedicationPackSelect
                        medicationPacks={medicationPacks}
                        medicationPackId={formik.values.medicationPackId}
                        onChange={handleChangeMedicationPack}
                        error={Boolean(
                            formik.touched.medicationPackId &&
                                formik.errors.medicationPackId,
                        )}
                        helperText={formik.errors.medicationPackId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        name="effectiveDate"
                        id="effectiveDate"
                        label={t('effectiveDate')}
                        value={formik.values.effectiveDate}
                        onChange={value =>
                            formik.setFieldValue('effectiveDate', value, true)
                        }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        fullWidth
                        minDate={new Date()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="number"
                        label={t('quantity')}
                        variant="outlined"
                        name="quantity"
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        InputProps={{inputProps: {min: 1}}}
                        helperText={
                            formik.touched.quantity && formik.errors.quantity
                        }
                        error={Boolean(
                            formik.touched.quantity && formik.errors.quantity,
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="text"
                        label={t('usageRecommendation')}
                        variant="outlined"
                        name="usageRecommendation"
                        value={formik.values.usageRecommendation}
                        onChange={formik.handleChange}
                        helperText={
                            formik.touched.usageRecommendation &&
                            formik.errors.usageRecommendation
                        }
                        error={Boolean(
                            formik.touched.usageRecommendation &&
                                formik.errors.usageRecommendation,
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReimbursementSelect
                        medicationPackReimbursements={
                            medicationPackReimbursements
                        }
                        paymentLevel={formik.values.paymentLevel}
                        onChange={formik.handleChange}
                        error={Boolean(
                            formik.touched.paymentLevel &&
                                formik.errors.paymentLevel,
                        )}
                        helperText={
                            formik.touched.paymentLevel &&
                            formik.errors.paymentLevel
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    {patientInsuranceDeclaration === null && (
                        <Box style={{fontWeight: 'bold'}}>
                            {t('patientDidNotSubmitDeclaration')}
                        </Box>
                    )}
                    {patientInsuranceDeclaration?.hasPublicInsurance ===
                        false && (
                        <Box style={{fontWeight: 'bold'}}>
                            {t('patientIsNotInsured')}
                        </Box>
                    )}
                    {patientInsuranceDeclaration?.hasPublicInsurance && (
                        <Box style={{fontWeight: 'bold'}}>
                            {t('patientIsInsuredInBranchNo')}{' '}
                            {patientInsuranceDeclaration?.nfzRegionalBranchCode}
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} style={{textAlign: 'right'}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        style={{fontWeight: 'bold', padding: '0 25px'}}
                    >
                        {t('add')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default NewPrescriptionForm;
