import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import prescriptionsApi from '../api/prescriptions/prescriptionsApi';
import {RootState} from './store';
import { IssuedZLADto, ZLADto } from '../types/sickLeave';
import kedZLAApi from '../api/zusZLA/kedZLAApi';

const sliceName = 'SickLeaveSlice';
type SickLeaveStatusType = 'idle' | 'loading' | 'failed';

const initialState = {
    sickLeaves: [] as IssuedZLADto[],
    status: 'idle' as SickLeaveStatusType,
};

export const fetchSickLeaves = createAsyncThunk<
IssuedZLADto[],
    {appointmentId: number},
    unknown
>(`${sliceName}/fetchSickLeaves`, async ({appointmentId}) => {
    const {data} = await kedZLAApi.getSickLeavesByAppointmentId(
        appointmentId,
    );
    return data?.result;
});

const sickLeaveSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSickLeaves.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchSickLeaves.fulfilled, (state, action) => {
                state.status = 'idle';
                state.sickLeaves = action.payload;
            })
            .addCase(fetchSickLeaves.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const selectSickLeaves = (state: RootState) =>
    state.sickeLeave.sickLeaves;

export const selectSickLeavesStatus = (state: RootState) =>
    state.sickeLeave.status;

export default sickLeaveSlice.reducer;
