import { useTranslation } from "react-i18next";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { Box, Grid, IconButton } from "@material-ui/core";
import i18nNamespaces from "../../../const/i18nNamespaces";
import {  ExtendedScopeOfTestingItemDto } from "../../../types/occupationalMedicine";
import { getShortDateFromISO } from "../../../utils/date";
import {Close, Edit} from '@material-ui/icons'
import useStyles from "./ExtendedScopeOfTestingComponentStyles";


const REQUIRED_STRING = 'requiredField';

export type Props = {
    index:number,
    data: ExtendedScopeOfTestingItemDto,
    onDelete: (index:number) => void,
    onEdit: (index: number, data:ExtendedScopeOfTestingItemDto) => void
}
const ExtendedScopeOfTestingItem = ({
    index,
    data,
    onDelete,
    onEdit
}:Props) =>{const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
const classes = useOccupationalMedicineStyles();
const customClasses = useStyles();

return (
        <Box className={customClasses.border}>             
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={4}>
                    <Box>
                        {data.type}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        {data.explanation}
                    </Box>      
                </Grid>
                <Grid item xs={4} className={customClasses.actionIconContainer}>
                    <Box className={customClasses.closeButtonCntainer}>
                        <IconButton onClick={() => {onDelete(index)}} 
                        className={customClasses.iconButton}>
                            <Close className={customClasses.closeButton}/>
                        </IconButton>
                        
                    </Box>
                    <Box className={customClasses.editButtonContainer}>
                        <IconButton onClick={() => {onEdit(index, data)}}
                        className={customClasses.iconButton}>
                            <Edit/>
                        </IconButton>
                    </Box>
                </Grid>
            </Grid> 
        </Box>
)
};
export default ExtendedScopeOfTestingItem;