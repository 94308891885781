import {useEffect, useState} from 'react';
import {Box, Grid, useMediaQuery, useTheme} from '@material-ui/core';
import {xorBy} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import medicsProvider from '../../services/medicsProvider';
import MedicPictureChangeTileMobile from '../../components/medicProfile/medicPhoto/MedicPictureChangeTileMobile/MedicPictureChangeTileMobile';
import MedicDescription from '../../components/medicProfile/MedicDescription/MedicDescription';
import useStyles from './MedicProfilePageStyles';
import MedicPictureChangeTileDesktop from '../../components/medicProfile/medicPhoto/MedicPictureChangeTileDesktop/MedicPictureChangeTileDesktop';
import {CustomButton, Loading} from '../../components/index';
import i18nNamespaces from '../../const/i18nNamespaces';
import {
    fetchMedicDetails,
    fetchMedicTags,
    resetMedicProfileState,
    selectMedicDetails,
    selectMedicDetailsStatus,
    selectMedicTags,
    selectMedicTagsStatus,
    selectNewMedicPicture,
    selectNewMedicStamp,
    selectNewMedicTags,
    selectSaveEnabled,
    setNewMedicPicture,
    setNewMedicStamp,
    setNewMedicTags,
} from '../../store/medicProfileSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import MedicTags from '../../components/medicProfile/medicTags/MedicTags/MedicTags';
import {isNotNull} from '../../utils/lang';
import LanguageCardSelector from '../../components/common/LanguageCardSelector/LanguageCardSelector';
import languageProvider from '../../services/languageProvider';
import {LanguageDto} from '../../types/language';
import DigitalCertificate from '../../components/medicProfile/digitalCertificate/DigitalCertificate/DigitalCertificate';
import StampDesktop from '../../components/medicProfile/medicStamp/StampDesktop';
import StampMobile from '../../components/medicProfile/medicStamp/StampMobile';

const MedicProfilePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const desktopView = useMediaQuery(theme.breakpoints.up('sm'));
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();

    const newMedicPicture = useAppSelector(selectNewMedicPicture);
    const newMedicStamp = useAppSelector(selectNewMedicStamp);
    const medicTags = useAppSelector(selectMedicTags);
    const newMedicTags = useAppSelector(selectNewMedicTags);

    const medicsTagsEdited =
        isNotNull(newMedicTags) &&
        !!xorBy(medicTags, newMedicTags, 'id').length;

    const medicDetailsStatus = useAppSelector(selectMedicDetailsStatus);
    const medicTagsStatus = useAppSelector(selectMedicTagsStatus);

    const [saveChangesLoading, setSaveChangesLoading] = useState(false);
    const loading =
        medicDetailsStatus === 'loading' ||
        medicTagsStatus === 'loading' ||
        saveChangesLoading;

    const [languages, setLanguages] = useState<LanguageDto[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageDto>(null);

    const saveEnabled = useAppSelector(selectSaveEnabled);
    const medicDetails = useAppSelector(selectMedicDetails);

    const loadMedicDetails = () => {
        dispatch(fetchMedicDetails());
    };

    useEffect(() => {
        loadMedicDetails();
        return () => {
            dispatch(resetMedicProfileState());
        };
    }, [dispatch]);

    const saveChangesHandler = async () => {
        setSaveChangesLoading(true);
        const updateActions = [];

        updateActions.push(
            medicsProvider.updateCurrentMedic({
                bio: medicDetails.bio,
                translations: medicDetails.translations.filter(
                    x => x.value !== null && x.value !== '',
                ),
            }),
        );

        if (newMedicPicture) {
            const formData = new FormData();
            formData.append('File', newMedicPicture, newMedicPicture.name);
            const {id} = await medicsProvider.addMedicPhoto(formData);
            updateActions.push(medicsProvider.setMainPhoto(id));
        }
        if(newMedicStamp){
            const formData = new FormData();
            formData.append('File', newMedicStamp, newMedicStamp.name);
            await medicsProvider.addMedicStamp(formData);
        }
        if (medicsTagsEdited) {
            updateActions.push(
                medicsProvider.updateMedicTags({
                    tagsIds: newMedicTags.map(mt => mt.id),
                }),
            );
        }

        try {
            await Promise.all(updateActions);
            await loadMedicDetails();
            await dispatch(fetchMedicTags());
            if (newMedicPicture) {
                dispatch(setNewMedicPicture(null));
            }
            if (newMedicStamp) {
                dispatch(setNewMedicStamp(null));
            }
            if (medicsTagsEdited) {
                dispatch(setNewMedicTags(null));
            }
        } finally {
            setSaveChangesLoading(false);
        }
    };

    const getLanguages = async () => {
        try {
            const data = await languageProvider.getLanguages();
            if (data.length > 0)
                setSelectedLanguage(data.find(x => x.code == 'PL'));
            setLanguages(data);
        } catch {}
    };

    useEffect(() => {
        getLanguages();
    }, []);

    const handleSelectLanguage = (language: LanguageDto) => {
        setSelectedLanguage(language);
    };

    return (
        <Box className={classes.root}>
            <Loading withBackdrop={true} loading={loading} />
            <Box className={classes.tileWrapper}>
                {desktopView ? (
                    <MedicPictureChangeTileDesktop />
                ) : (
                    <MedicPictureChangeTileMobile />
                )}
            </Box>
            <Box className={classes.tileWrapper}>
                {languages && (
                    <LanguageCardSelector
                        languages={languages}
                        selectedLanguage={selectedLanguage}
                        handleSelectLanguage={handleSelectLanguage}
                    />
                )}

                {languages && (
                    <MedicDescription selectedLanguage={selectedLanguage} />
                )}
            </Box>
            <Box className={classes.tileWrapper}>
                <MedicTags />
            </Box>
            <Box className={classes.tileWrapper}>
                <DigitalCertificate />
            </Box>
            <Box className={classes.tileWrapper}>
            {desktopView ? (
                    <StampDesktop />
                ) : (
                    <StampMobile />
                )}
            </Box>
            <Grid className={classes.saveButtonContainer} container>
                <CustomButton
                    className={classes.saveChangesButton}
                    variant="contained"
                    color="secondary"
                    onClick={saveChangesHandler}
                    disabled={!saveEnabled}
                >
                    {commonT('saveChanges')}
                </CustomButton>
            </Grid>
        </Box>
    );
};

export default MedicProfilePage;
