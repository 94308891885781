import React, {ChangeEvent, memo, useCallback} from 'react';
import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Snackbar,
    TextField,
    Theme,
} from '@material-ui/core';
import {Alert, Autocomplete} from '@material-ui/lab';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {WHITE} from '../../../const/colors';
import {ConfirmOrRejectModal, Loading} from '../../index';
import useMedicalTestsCategories from '../../../hooks/referrals/useMedicalTestCategories';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    clearSelectedMedicalTests,
    selectSelectedMedicalTests,
    selectSelectedMedicalTestsCategory,
    setMedicalTestNameFilter,
    setMedicalTestsSelectedCategory,
} from '../../../store/referrals/medicalTestsSlice';
import {debounce} from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 1,
            padding: 16,
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                marginTop: 25,
                padding: 0,
                backgroundColor: 'transparent',
            },
        },
    }),
);

const MedicalSearchFilters = memo(function MedicalSearchFilters() {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.REFERRALS);

    const selectedCategory = useAppSelector(selectSelectedMedicalTestsCategory);
    const selectedMedicalTests = useAppSelector(selectSelectedMedicalTests);
    const dispatch = useAppDispatch();

    const {
        categories,
        categoriesLoading,
        categoriesError,
        categoryChangeModalOpen,
        setCategoryChangeModalOpen,
        onModalConfirmCategoryName,
        setOnModalConfirmCategoryName,
    } = useMedicalTestsCategories();

    const categorySelectionChangeHandler = useCallback(
        (_: ChangeEvent<unknown>, selectedValue: string | null) => {
            if (selectedMedicalTests.length) {
                setOnModalConfirmCategoryName(selectedValue);
                setCategoryChangeModalOpen(true);
            } else {
                dispatch(setMedicalTestsSelectedCategory(selectedValue));
            }
        },
        [
            selectedMedicalTests,
            setOnModalConfirmCategoryName,
            setCategoryChangeModalOpen,
            dispatch,
        ],
    );

    const handleDebounceIpnut = (search: string) => {
        dispatch(setMedicalTestNameFilter(search));
    };

    const debounceInput = useCallback(debounce(handleDebounceIpnut, 1000), []);

    const nameFilterValueChangeHandler = (
        event: ChangeEvent<HTMLInputElement>,
    ) => {
        debounceInput(event.target.value || null);
    };

    const categoryChangeConfirmed = useCallback(() => {
        dispatch(clearSelectedMedicalTests());
        dispatch(setMedicalTestsSelectedCategory(onModalConfirmCategoryName));
        setOnModalConfirmCategoryName(null);
        setCategoryChangeModalOpen(false);
    }, [
        dispatch,
        onModalConfirmCategoryName,
        setOnModalConfirmCategoryName,
        setCategoryChangeModalOpen,
    ]);

    const categoryChangeRejected = useCallback(() => {
        setOnModalConfirmCategoryName(null);
        setCategoryChangeModalOpen(false);
    }, [setOnModalConfirmCategoryName, setCategoryChangeModalOpen]);

    return (
        <Box className={classes.root}>
            {!categoriesError ? (
                <>
                    <Autocomplete
                        options={categories}
                        value={selectedCategory}
                        getOptionLabel={t}
                        onChange={categorySelectionChangeHandler}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t('selectMedicalTestCategory')}
                                variant="outlined"
                            />
                        )}
                    />
                    <TextField
                        label={t('searchByMedicalTestName')}
                        onChange={nameFilterValueChangeHandler}
                        variant="outlined"
                        fullWidth
                        style={{marginTop: 16}}
                    />
                    <ConfirmOrRejectModal
                        open={categoryChangeModalOpen}
                        primaryText={t(
                            'categoryChangeWhenTestsAlreadyAddedToReferral',
                        )}
                        onConfirm={categoryChangeConfirmed}
                        onReject={categoryChangeRejected}
                    />
                </>
            ) : (
                <Snackbar open={true}>
                    <Alert severity="error">{categoriesError}</Alert>
                </Snackbar>
            )}
            <Loading withBackdrop loading={categoriesLoading} />
        </Box>
    );
});

export default MedicalSearchFilters;
