import isString from 'lodash-es/isString';

export const capitalizeFirstLetter = (text: string) =>
    text.charAt(0).toUpperCase() + text.slice(1);

export const hasMinimumLength = (minLength: number, text?: string) => {
    return isString(text)
        ? text.trim().length >= minLength
        : false;
}

export const splitTextBySpaces = (text: string) =>
    text.split(' ').filter(w => w.length)

export const includesAllWords = (text: string, filterText: string) => {
    const textLowerCase = text.toLowerCase();

    const filterTextWords = splitTextBySpaces(filterText)
        .map(w => w.toLowerCase());

    return filterTextWords
        .filter(
            wordLowerCase => textLowerCase.includes(wordLowerCase)
        )
        .length === filterTextWords.length;
}

export const refineString = (value: string | null | undefined) => {
    return value?.trim() || null;
}

export const stringValuesDiffers = (value1: string | null | undefined, value2: string | null | undefined) => {
    const refinedString1 = refineString(value1);
    const refinedString2 = refineString(value2);
    return refinedString1 !== refinedString2;
}