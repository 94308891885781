import {memo, ReactNode} from 'react';
import {createStyles, ListItem, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {WHITE} from '../../../const/colors';

interface Props {
    disablePaddings?: boolean;
    children: ReactNode | ReactNode[];
}

const useStyles = makeStyles(() =>
    createStyles({
        tileListItem: {
            padding: 16,
            marginTop: 1,
            backgroundColor: WHITE,
            '&:first-child': {
                marginTop: 0,
            },
        },
        disabledPaddings: {
            padding: 0
        }
    }),
);

const TileListItem = memo(function TileListItem({disablePaddings, children}: Props) {
    const classes = useStyles();
    return (
        <ListItem
            className={
                clsx(classes.tileListItem, {
                    [classes.disabledPaddings]: disablePaddings
                })
            }
            disableGutters={true}
        >
            {children}
        </ListItem>
    );
});

export default TileListItem;