import {Box} from '@material-ui/core';
import {useEffect} from 'react';
import {useHistory} from 'react-router';
import {Loading, RegisterForm} from '../../components';
import {
    EMAIL_VERIFICATION_PAGE,
    PHONE_VERIFICATION_PAGE,
} from '../../const/routes';
import {RegisterUserFormData} from '../../types/Login';
import useStyles from './RegisterPageStyles';
import i18nNamespaces from '../../const/i18nNamespaces';
import {useTranslation} from 'react-i18next';
import useQuery from '../../hooks/useQuery';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    selectAuthEmail,
    selectAuthError,
    selectAuthStatus,
    signUp,
} from '../../store/auth';

const RegisterPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const history = useHistory();
    const query = useQuery();
    const returnUrl = query.get('returnUrl');
    const encodedReturnUrl = '?returnUrl=' + encodeURIComponent(returnUrl);
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectAuthStatus);
    const authEmail = useAppSelector(selectAuthEmail);
    const error = useAppSelector(selectAuthError);
    const isChildrenRequired = query.get('isChildrenRequired') === 'true';
    const onSignUp = (values: RegisterUserFormData) => {
        dispatch(signUp(values));
    };

    useEffect(() => {
        if (status === 'loaded') {
            if (returnUrl) {
                history.push({
                    pathname: PHONE_VERIFICATION_PAGE,
                    state: {email: authEmail},
                    search: encodedReturnUrl,
                });
            } else {
                history.push({
                    pathname: PHONE_VERIFICATION_PAGE,
                    state: {email: authEmail},
                });
            }
        }
    }, [status, returnUrl]);

    return (
        <>
            <Loading loading={status === 'loading'} withBackdrop />
            <Box className={classes.container}>
                <Box className={classes.header}>
                    {t('enterRegistrationData')}
                </Box>
                <RegisterForm
                    onSubmit={onSignUp}
                    error={error}
                    isChildrenRequired={isChildrenRequired}
                />
            </Box>
        </>
    );
};

export default RegisterPage;
