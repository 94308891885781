import { ZLAService } from "../../services/ZLAService";
import { P1Response } from "../../types/p1Service";
import { PayerDto } from "../../types/sickLeave";
import { getSessionIdEndpoint, deleteSessionIdEndpoint } from "./sessionApiEndpoints";


class SessionApi {

    LogIn() {
        return ZLAService.get<P1Response<string>>(
            getSessionIdEndpoint(),
        );
    }

    LogOut() {
        return ZLAService.delete<P1Response<string>>(
            deleteSessionIdEndpoint(),{ withCredentials: true }
        );
    }
}

export default new SessionApi();
