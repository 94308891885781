import {Box, Grid, IconButton, List, ListItem} from "@material-ui/core";
import {QuestionnaireDetails as QuestionnaireDetailsType} from "../../../../types/appointments";
import useStyles from "./QuestionnaireDetailsStyles";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import {isNotNullOrUndefined} from "../../../../utils/lang";
import {FileCopyOutlined} from '@material-ui/icons';
import {CopyToClipboard } from 'react-copy-to-clipboard';
import { CustomAlert } from "../../../common/feedback";
import { useState } from "react";

interface Props {
    questionnaireDetails: QuestionnaireDetailsType
}

const QuestionnaireDetails = ({questionnaireDetails}: Props) => {
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const {t: tQuestionnaire} = useTranslation(i18nNamespaces.QUESTIONNAIRE);
    const [alertVisible, setAlertVisible] = useState<boolean>(false);

    const pesel = questionnaireDetails && (isNotNullOrUndefined(questionnaireDetails?.childPesel) ? 
        questionnaireDetails?.childPesel :  questionnaireDetails.patientPesel);

    const parentPesel = questionnaireDetails && (isNotNullOrUndefined(questionnaireDetails?.childPesel) ? 
        questionnaireDetails?.patientPesel :  null);

    const phoneNumber = questionnaireDetails && (isNotNullOrUndefined(questionnaireDetails?.phoneNumber) ? 
        questionnaireDetails?.phoneNumber :  null);
        
    const getPatientGender = (gender: number) => {
        switch (gender) {
            case 0: return tQuestionnaire('woman')
            case 1: return tQuestionnaire('man')
            default: return tQuestionnaire('wrongValue')
        }
    }

    const onCopyToClipboard = () =>{
        setAlertVisible(true);
    }
    const onAlertClose = ()=>{
        setAlertVisible(false);
    }

    return (
        <>
            {
                questionnaireDetails ? (
                    <List className={classes.questionnaireDataList}>
                        <ListItem className={classes.questionnaireDataListItem}>
                                            <span className={classes.questionnaireDataListCategory}>
                                                {tCommon('bodyHeight')}:
                                             </span>
                            {
                                questionnaireDetails?.bodyHeight
                                    ? questionnaireDetails.bodyHeight + ' cm'
                                    : tCommon('noInformation')
                            }
                        </ListItem>
                        <ListItem className={classes.questionnaireDataListItem}>
                                            <span className={classes.questionnaireDataListCategory}>
                                                {tCommon('bodyWeight')}:
                                            </span>
                            {
                                questionnaireDetails?.bodyWeight
                                    ? questionnaireDetails.bodyWeight + ' kg'
                                    : tCommon('noInformation')
                            }
                        </ListItem>
                        <ListItem className={classes.questionnaireDataListItem}>
                                            <span className={classes.questionnaireDataListCategory}>
                                                {tCommon('gender')}:
                                            </span>
                            {
                                isNotNullOrUndefined(questionnaireDetails?.gender)
                                    ? getPatientGender(questionnaireDetails.gender)
                                    : tCommon('noInformation')
                            }
                        </ListItem>
                        <ListItem className={classes.questionnaireDataListItem}>
                                            <span className={classes.questionnaireDataListCategory}>
                                                {tCommon('age')}:
                                            </span>
                            {
                                questionnaireDetails?.age
                                    ? questionnaireDetails?.age + ` ${tCommon('ageYears')}`
                                    : tCommon('noInformation')
                            }
                        </ListItem>
                        <ListItem className={classes.questionnaireDataListItem}>
                                            <span className={classes.questionnaireDataListCategory}>
                                                Pesel:
                                            </span>
                            
                                <Grid container 
                                    alignItems="center">
                                    <Grid item>{pesel
                                        ? pesel
                                        : tCommon('noInformation')}
                                    </Grid>
                                    <Grid item>
                                        {pesel && 
                                            <CopyToClipboard text={pesel}
                                                onCopy={onCopyToClipboard}>
                                                <IconButton>
                                                    <FileCopyOutlined className={classes.copyIcon}/>
                                                </IconButton>
                                            </CopyToClipboard>
                                        }
                                    </Grid> 
                                </Grid>                            
                        </ListItem>
                        {
                            isNotNullOrUndefined(parentPesel) && 
                            <ListItem className={classes.questionnaireDataListItem}>
                                    <span className={classes.questionnaireDataListCategoryNoWrap}>
                                        {tQuestionnaire('parentPesel')}:
                                    </span>

                                <Grid container 
                                    alignItems="center">
                                    <Grid item>{parentPesel
                                        ? parentPesel
                                        : tCommon('noInformation')}
                                    </Grid>
                                    <Grid item>
                                        {parentPesel && 
                                            <CopyToClipboard text={parentPesel}
                                                onCopy={onCopyToClipboard}>
                                                <IconButton>
                                                    <FileCopyOutlined className={classes.copyIcon}/>
                                                </IconButton>
                                            </CopyToClipboard>
                                        }
                                    </Grid> 
                                </Grid>   
                        </ListItem>
                        }
                        <ListItem className={classes.questionnaireDataListItem}>
                                            <span className={classes.questionnaireDataListCategory}>
                                                {tCommon('phone')}:
                                            </span>
                            
                                <Grid container 
                                    alignItems="center">
                                    <Grid item>{phoneNumber
                                        ? phoneNumber
                                        : tCommon('noInformation')}
                                    </Grid>
                                    <Grid item>
                                        {phoneNumber && 
                                            <CopyToClipboard text={phoneNumber}
                                                onCopy={onCopyToClipboard}>
                                                <IconButton>
                                                    <FileCopyOutlined className={classes.copyIcon}/>
                                                </IconButton>
                                            </CopyToClipboard>
                                        }
                                    </Grid> 
                                </Grid>                            
                        </ListItem>
                    </List>
                ) : tCommon('noInformation')
            }
            {
                questionnaireDetails?.questionnaireAnswers && (
                    <List className={classes.questionnaireAnswersList}>
                        {
                            questionnaireDetails.questionnaireAnswers
                                .map((questionnaireAnswer, i) => (
                                    <ListItem key={i} className={classes.questionnaireAnswerListItem}>
                                        <Box className={classes.questionnaireAnswerContainer}>
                                            <h5 className={classes.questionText}>
                                                {questionnaireAnswer.questionText}
                                            </h5>
                                            {
                                                isNotNullOrUndefined(questionnaireAnswer.value)
                                                    ? questionnaireAnswer.value
                                                        ? tCommon('yes')
                                                        : tCommon('no')
                                                    : tCommon('noInformation')
                                            }
                                        </Box>
                                        {
                                            questionnaireAnswer?.detailsQuestionText && (
                                                <Box className={classes.questionnaireDetailsAnswerContainer}>
                                                    <h5 className={classes.questionText}>
                                                        {questionnaireAnswer.detailsQuestionText}
                                                    </h5>
                                                    <span className={classes.detailsAnswer}>
                                                        {
                                                            questionnaireAnswer.detailsValue || tCommon('noInformation')
                                                        }
                                                    </span>
                                                </Box>
                                            )
                                        }
                                    </ListItem>
                                ))
                        }
                    </List>
                )
            }
            {alertVisible && <CustomAlert
                disableAutoHide={false}
                severity="success"
                message={tCommon('copied')}
                onClose={onAlertClose}
            />}
        </>
    )
}

export default QuestionnaireDetails;