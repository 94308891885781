export const LARGE_DESKTOP_BREAKPOINT = '(min-width: 1280px)';
export const HD_READY_DESKTOP_BREAKPOINT = '(min-width: 1366px)';

export const desktop = {
    contentLargerSpacing: '47px',
    contentSmallerSpacing: '24px',
};

export const mobile = {
    contentLargerSpacing: '16px',
    contentSmallerSpacing: '8px',
    defaultFontSize: '16px',
};
