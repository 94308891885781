import React, {useEffect} from 'react';
import useStyles from './IndividualPackagesTableStyles';
import {
    Box,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchIndividualPackages,
    selectIndividualPackagesIndividualPackages,
    selectSubscriptionGroups,
    selectIndividualPackagesStatus,
    selectSelectedBundle,
} from '../../../store/individualPackagesSlice';
import {Loading} from '../../index';
import {formatPrice, getCurrency} from '../../../utils/price';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    IndividualPackageDto,
    MedicalServiceGroupDto,
} from '../../../types/individualPackages';
import clsx from 'clsx';
import { getLanguage, getTranslation } from '../../../utils/translation';
import i18n from '../../../i18n/config';


type IndividualPackagesTableProps = {
    onBuy: (individualPackageDraftId: number) => void;
};

const IndividualPackagesTable = ({onBuy}: IndividualPackagesTableProps) => {
    const currentLanguage = getLanguage(i18n?.language);
    const classes = useStyles();
    const subscriptionGroups = useAppSelector(selectSubscriptionGroups);
    const individualPackages = useAppSelector(
        selectIndividualPackagesIndividualPackages,
    );
    const selectedBundle = useAppSelector(selectSelectedBundle);
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectIndividualPackagesStatus);
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);

    useEffect(() => {
        dispatch(
            fetchIndividualPackages({
                maxUsersCount: selectedBundle.maxUsersCount,
                childrenIncluded: selectedBundle.childrenIncluded,
            }),
        );
    }, [dispatch, selectedBundle]);

    const mapTableRows = () =>
        subscriptionGroups.map(group => (
            <TableRow key={group.id}>
                <TableCell>
                    {getTranslation(group.description, group.translations, currentLanguage, "description")}
                </TableCell>
                {individualPackages.map(
                    (individualPackage: IndividualPackageDto) => {
                        const individualPackageGroup =
                            individualPackage.medicalServiceGroups.find(
                                (packageGroup: MedicalServiceGroupDto) =>
                                    packageGroup.id === group.id,
                            );
                        return (
                            <TableCell key={individualPackage.id}>
                                <Box
                                    className={clsx(classes.boldedCell, {
                                        [classes.magentaText]:
                                            !individualPackageGroup,
                                    })}
                                >
                                    {individualPackageGroup
                                        ? `-${
                                              individualPackageGroup.discount *
                                              100
                                          }%`
                                        : '—'}
                                </Box>
                            </TableCell>
                        );
                    },
                )}
            </TableRow>
        ));

    return status !== 'loaded' ? (
        <Grid container justifyContent="center">
            <Loading loading />
        </Grid>
    ) : (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>{t(selectedBundle?.title)}</TableCell>
                    {individualPackages.map(subscription => (
                        <TableCell
                            style={{textAlign: 'center'}}
                            key={subscription.id}
                        >
                            {getTranslation(subscription.name, subscription.translations, currentLanguage, "name")}

                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {mapTableRows()}
                <TableRow>
                    <TableCell
                        className={classes.tableFooterCell}
                        style={{borderBottom: 'none'}}
                    >
                        {t('price')}
                    </TableCell>
                    {individualPackages.map(subscription => (
                        <TableCell
                            key={subscription.id}
                            style={{borderBottom: 'none'}}
                        >
                            <Box className={classes.boldedCell}>
                                {formatPrice(subscription.price)}{' '}
                                zł / {t('monthly')}
                                {/*TODO: get country based price from backend*/}
                            </Box>
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell />
                    {individualPackages.map(subscription => (
                        <TableCell
                            style={{textAlign: 'center'}}
                            key={subscription.id}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.buyButton}
                                onClick={() => onBuy(subscription.draftId)}
                            >
                                {t('buy')}
                            </Button>
                        </TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default IndividualPackagesTable;
