import {makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: '8px',
        padding: '16px',
        backgroundColor: WHITE,
        borderRadius: 3,
        [theme.breakpoints.up('md')]: {
            margin: '24px 47px 24px 24px',
        },
    },
}));

export default useStyles;
