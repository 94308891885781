import {useCallback, useMemo, useState} from 'react';
import {isArray} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {DiagnosticRegionDto, getDiagnosticRegions} from '../../../services/diagnostic/regionsProvider';

export interface DiagnosticRegionsState {
    readonly regions: DiagnosticRegionDto[];
    readonly regionsLoading: boolean;
    readonly regionsError: string;
    readonly selectedRegion: DiagnosticRegionDto | null;
    readonly fetchRegions: () => void;
    readonly setSelectedRegion: (region: DiagnosticRegionDto) => void;
}

const useDiagnosticRegions = () => {
    const [regions, setRegions] = useState<DiagnosticRegionDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(null);

    const [selectedRegion, setSelectedRegion] = useState<DiagnosticRegionDto>(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const fetchRegions = useCallback(async() => {
        setLoading(true);
        try {
            const response = await getDiagnosticRegions();
            if (!isArray(response.data)) {
                throw new Error();
            }
            setRegions(response.data);
            setError(null);
        } catch {
            setRegions([]);
            setError(tErrors('fetchingDataFromServerError'))
        } finally {
            setSelectedRegion(null);
            setLoading(false);
        }
    }, []);

    const value = useMemo(
        () => ({
            regions,
            selectedRegion,
            regionsLoading: loading,
            regionsError: error,
            error,
            // methods
            fetchRegions,
            setSelectedRegion
        })
        , [regions, loading, error, selectedRegion]
    );

    return value;
}

export default useDiagnosticRegions;