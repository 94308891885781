import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { AuxiliaryTestItemDto, AuxiliaryTestsDto, PhysicalTestsByMedicDto } from "../../../types/occupationalMedicine";
import { useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid } from "@material-ui/core";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { RenderCancelNextButton, RenderGridItemKeyboardDatePicker, RenderGridItemWithRequiredKeyboardDatePicker } from "../CommonView";
import { DateTime } from "luxon";

const REQUIRED_STRING = 'requiredField';

export type Props = {
    data:AuxiliaryTestItemDto;
    onCancel: () => void;
    onSubmitSection: (newPlace: AuxiliaryTestItemDto, stayOnCurrent:boolean) =>void;
}

const AuxiliaryTestsCreateItem = ({onCancel, data, onSubmitSection}:Props) =>{
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    
    
    const onSubmit = () => {
        onSubmitSection(values, true);
    }

    const {values, touched, handleChange, handleBlur, errors, handleSubmit, setFieldValue} =
        useFormik({
            initialValues: {             
                type:data?.type ?? "",
                referralToMedicalTestDate:data?.referralToMedicalTestDate ?? null as DateTime,
                medicalTestDate:data?.medicalTestDate ?? null as DateTime,
                results:data?.results ?? ""
                
            },
            validationSchema: yup.object({
                type: yup.string().required(REQUIRED_STRING),
                referralToMedicalTestDate: yup.date().nullable().required(REQUIRED_STRING)
            }),
            onSubmit,
        }); 


    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={8}>
                        <FormGroup >
                                <Label htmlFor="type">{t("testType")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="type"
                                    name="type"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.type}
                                    helperText={touched.type && t(errors.type)}
                                    error={touched.type && !!errors.type}
                                    />
                            </FormGroup>
                    </Grid>
                    <Grid item xs={4}></Grid>

                    {RenderGridItemWithRequiredKeyboardDatePicker(classes, handleBlur,"referralToMedicalTestDate", t('referralDate'),
                                     values.referralToMedicalTestDate, setFieldValue, touched.referralToMedicalTestDate, 
                                     errors.referralToMedicalTestDate, t(errors.referralToMedicalTestDate as string)  )}
                    
                    {RenderGridItemKeyboardDatePicker(classes, handleBlur,"medicalTestDate", t('medicalTestDate'),
                                     values.medicalTestDate, setFieldValue )}
                   
                    <Grid item xs={4}></Grid>
                    
                    <Grid item xs={8}>
                        <FormGroup >
                                <Label htmlFor="medicalTestResults">{t("medicalTestResults")} </Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="results"
                                    name="results"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.results}
                                    />
                            </FormGroup>
                    </Grid>
                </Grid>
                {RenderCancelNextButton(classes, t, onCancel)}
            </Box>
        </form>
    )
}
export default  AuxiliaryTestsCreateItem;