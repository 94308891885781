import React, {ChangeEvent, memo, useCallback} from 'react';
import {Box, createStyles, makeStyles, TextField, Theme} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {useDiagnosticRegionsContext} from '../../../../hooks/diagnostic/regions/useDiagnosticRegionContext';
import {DiagnosticRegionDto} from '../../../../services/diagnostic/regionsProvider';
import {WHITE} from '../../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 1,
            padding: 16,
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                marginTop: 0,
                padding: '24px 0',
                backgroundColor: 'transparent',
            }
        },
        selectLocalizationText: {
            fontSize: 16,
            lineHeight: '24px',
        },
        autocomplete: {
            marginTop: 16,
        }
    }),
);

const RegionSelection = memo(function MedicalSearchFilters() {
    const classes = useStyles();
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);
    const {regions, selectedRegion, setSelectedRegion} = useDiagnosticRegionsContext();

    const tagsSelectionChangeHandler = useCallback(
        (_: ChangeEvent<unknown>, selectedValue: DiagnosticRegionDto | string) => {
            setSelectedRegion(selectedValue as DiagnosticRegionDto || null)
        },
        []
    );

    return (
        <Box className={classes.root}>
            <Box className={classes.selectLocalizationText}>
                {tReferrals('selectRegionToDoMedicalTests')}
            </Box>
            <Autocomplete
                className={classes.autocomplete}
                options={regions}
                value={selectedRegion}
                getOptionLabel={region => region.name}
                onChange={tagsSelectionChangeHandler}
                renderInput={params =>
                    <TextField
                        {...params}
                        label={tReferrals('selectLocalization')}
                        variant="outlined"
                    />
                }
            />
        </Box>
    )
})

export default RegionSelection;