import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageLayout: {
            minHeight: '100vh',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
        pageLayoutMain: {
            flexDirection: 'column',
            flexWrap: 'nowrap',
            flex: '1 0 auto',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        },
        pageLayoutLeftColumnDesktop: {
            flex: '0 0 50%',
            minHeight: 'calc(100vh - 128px)',
            backgroundColor: WHITE,
        },
        pageLayoutRightColumnDesktop: {
            flex: '0 0 50%',
        },
    }),
);

export default useStyles;
