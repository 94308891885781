const namespaces = {
    COMMON: 'common',
    APPOINTMENT: 'appointment',
    AVAILABILITY_TEMPLATE: 'availabilityTemplate',
    MEDIC_CALENDAR: 'medicCalendar',
    CONVERSATIONS: 'conversations',
    AUTH: 'auth',
    TIME_SLOTS: 'timeSlots',
    INDIVIDUAL_PACKAGES: 'individualPackages',
    TIME_SLOTS_PAYMENT: 'timeSlotsPayment',
    APPOINTMENT_PAYMENT_CONTINUE: 'appointmentPaymentContinue',
    APPOINTMENT_DETAILS: 'appointmentDetails',
    SUBSCRIPTION_PAYMENT: 'subscriptionPayment',
    SUBSCRIPTION_PAYMENT_CONTINUE: 'subscriptionPaymentContinue',
    QUESTIONNAIRE: 'questionnaire',
    PATIENT_PACKAGES: 'patientPackages',
    EVALUATION: 'evaluation',
    REFERRALS: 'referrals',
    ERRORS: 'errors',
    ICD: 'icd',
    PAYU: 'payU',
    ARTICLES: 'articles',
    DISCOUNT_CODE: 'discountCode',
    SETTINGS: 'settings',
    FOOTER_CONTENT: 'footerContent',
    MEDICAL_TESTS: 'medicalTests',
    HELP: 'help',
    META_DESCRIPTION: 'metaDescription',
    LANDING_PAGE: 'landingPage',
    MEDIC: 'medic',
    PRESCRIPTIONS: 'prescriptions',
    L4:'l4',
    ACCOUNT:'account',
    OCCUPATIONAL_MEDICINE:'occupationalMedicine'
};

export default namespaces;
