import React from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: string | number;
    value: string | number;
    className?: string;
}

const TabPanel = ({children, value, index, ...other}: TabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

export default TabPanel;
