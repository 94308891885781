import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop} from '../../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            marginTop: desktop.contentSmallerSpacing,
        },
    }),
);

export default useStyles;
