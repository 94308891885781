import { Trans, useTranslation } from "react-i18next";
import { PatientPlaceOfWorkOrOfStudyDto } from "../../../types/occupationalMedicine";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem } from "@material-ui/core";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import Label from "../../common/Label/Label";
import CustomSelectField from "../../common/CustomSelectField/CustomSelectField";
import { Accordion, AccordionDetails, AccordionSummary } from "../../common/Accordion/Accordion";
import { CheckCircleOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateTimeSelect from "../../forms/SearchForm/DateTimeSelect/DateTimeSelect";
import { useFormik } from "formik";
import * as yup from 'yup';
import { DateTime } from "luxon";
import { useAppDispatch, useAppSelector } from "../../../hooks/customReduxHooks";
import { fetchSection2Data, updateOccupationalMedicineData } from "../../../store/occupationalMedicineSlice";
import { MutableRefObject, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import { RenderGridItemWithRequiredKeyboardDatePicker, RenderReturnNextButtons, RenderGridWithCheckBox, RenderGridItemKeyboardDatePicker } from "../CommonView";
import { useParams } from "react-router";
import useAppointmentInfo from "../../../hooks/useAppointmentInfo";
import { selectAuthUserData } from "../../../store/auth";
import { MEDIC } from "../../../const/auth";

const REQUIRED_STRING = 'requiredField';
const SECTION = 2;


export type Props = {
    currentExpanded:number
    data: PatientPlaceOfWorkOrOfStudyDto,
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void
    onReturn: () => void;
    refSection:MutableRefObject<any>;
}
const PatientPlaceOfWorkOrOfStudyComponent = ({
    currentExpanded, data, onSubmitSection, refSection, onReturn
}:Props) =>{

    
    const authUserData = useAppSelector(selectAuthUserData);
    const isMedic = authUserData?.role === MEDIC;

    
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const dispatch = useAppDispatch();
    
    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data) && data.isDraft!==true)
            setIsSaved(true);
    }, [data]);

        
    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values), fetchSection2Data({id:appointmentId}), SECTION);
    }


    const {values, touched, handleChange, handleBlur, errors, handleSubmit, setFieldValue} =
        useFormik({
            initialValues: {
                patientPlaceOfWorkOrOfStudyName:data?.patientPlaceOfWorkOrOfStudyName  ?? '',
                patientPlaceOfWorkOrOfStudyStreet:data?.patientPlaceOfWorkOrOfStudyStreet  ?? '',
                patientPlaceOfWorkOrOfStudyHouseNumber:data?.patientPlaceOfWorkOrOfStudyHouseNumber  ?? '',
                patientPlaceOfWorkOrOfStudyApartmentNumber:data?.patientPlaceOfWorkOrOfStudyApartmentNumber  ?? '',
                patientPlaceOfWorkOrOfStudyPostcode:data?.patientPlaceOfWorkOrOfStudyPostcode  ?? '',
                patientPlaceOfWorkOrOfStudyCity:data?.patientPlaceOfWorkOrOfStudyCity  ?? '',
                jobPositionOrFieldOfStudy:data?.jobPositionOrFieldOfStudy  ?? '',
                hasReferralFromEmployer:data?.hasReferralFromEmployer ?? false,
                informationAboutHarmfulFactors:data?.informationAboutHarmfulFactors ?? false,
                resultsOfmeasurementsOfHarmfulFactors:data?.resultsOfmeasurementsOfHarmfulFactors ?? false,
                informationAboutBurdensomeFactors:data?.informationAboutBurdensomeFactors ?? false,
                cardSubmisionDate:data?.cardSubmisionDate ?? null as DateTime,
                examinationDate:data?.examinationDate ?? null as DateTime,
            },

            validationSchema: yup.object({
                patientPlaceOfWorkOrOfStudyName: yup.string().required(commonT(REQUIRED_STRING)),
                patientPlaceOfWorkOrOfStudyStreet: yup.string().required(commonT(REQUIRED_STRING)),
                patientPlaceOfWorkOrOfStudyHouseNumber: yup.string().required(commonT(REQUIRED_STRING)),
                patientPlaceOfWorkOrOfStudyCity: yup.string().required(commonT(REQUIRED_STRING)),
                patientPlaceOfWorkOrOfStudyPostcode: yup.string().required(commonT(REQUIRED_STRING)),
                jobPositionOrFieldOfStudy: yup.string().required(commonT(REQUIRED_STRING)),
            }),
            onSubmit,
        });



    return (
        <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >

                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('workOrStudyPlaceHeader')}
                        </h4>

                        {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.detailsContainer}>
                    <Box>
                        <Grid container spacing={1} className={classes.gridContainer}>
                            <Grid item xs={8}>
                                <FormGroup >
                                    <Label htmlFor="patientPlaceOfWorkOrOfStudyName">{t("name")} *</Label>
                                    <CustomTextField 
                                        fullWidth 
                                        id="patientPlaceOfWorkOrOfStudyName"
                                        name="patientPlaceOfWorkOrOfStudyName"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.patientPlaceOfWorkOrOfStudyName}
                                        helperText={touched.patientPlaceOfWorkOrOfStudyName && t(errors.patientPlaceOfWorkOrOfStudyName)}
                                        error={touched.patientPlaceOfWorkOrOfStudyName && !!errors.patientPlaceOfWorkOrOfStudyName}
                                        />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={12}> 
                                <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                        {t('address')} 
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup >
                                    <Label htmlFor="patientPlaceOfWorkOrOfStudyStreet">{t("street")} *</Label>
                                    <CustomTextField 
                                        fullWidth 
                                        id="patientPlaceOfWorkOrOfStudyStreet"
                                        name="patientPlaceOfWorkOrOfStudyStreet"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.patientPlaceOfWorkOrOfStudyStreet}
                                        helperText={touched.patientPlaceOfWorkOrOfStudyStreet && t(errors.patientPlaceOfWorkOrOfStudyStreet)}
                                        error={touched.patientPlaceOfWorkOrOfStudyStreet && !!errors.patientPlaceOfWorkOrOfStudyStreet}
                                        />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup >
                                    <Label htmlFor="patientPlaceOfWorkOrOfStudyHouseNumber">{t("houseNumber")} *</Label>
                                    <CustomTextField 
                                        fullWidth 
                                        id="patientPlaceOfWorkOrOfStudyHouseNumber"
                                        name="patientPlaceOfWorkOrOfStudyHouseNumber"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.patientPlaceOfWorkOrOfStudyHouseNumber}
                                        helperText={touched.patientPlaceOfWorkOrOfStudyHouseNumber && t(errors.patientPlaceOfWorkOrOfStudyHouseNumber)}
                                        error={touched.patientPlaceOfWorkOrOfStudyHouseNumber && !!errors.patientPlaceOfWorkOrOfStudyHouseNumber}
                                        />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup >
                                    <Label htmlFor="patientPlaceOfWorkOrOfStudyApartmentNumber">{t("apartmentNumber")}</Label>
                                    <CustomTextField 
                                        fullWidth 
                                        id="patientPlaceOfWorkOrOfStudyApartmentNumber"
                                        name="patientPlaceOfWorkOrOfStudyApartmentNumber"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.patientPlaceOfWorkOrOfStudyApartmentNumber}
                                        helperText={touched.patientPlaceOfWorkOrOfStudyApartmentNumber && t(errors.patientPlaceOfWorkOrOfStudyApartmentNumber)}
                                        error={touched.patientPlaceOfWorkOrOfStudyApartmentNumber && !!errors.patientPlaceOfWorkOrOfStudyApartmentNumber}
                                        />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup >
                                    <Label htmlFor="patientPlaceOfWorkOrOfStudyPostcode">{t("postcode")} *</Label>
                                    <CustomTextField 
                                        fullWidth 
                                        id="patientPlaceOfWorkOrOfStudyPostcode"
                                        name="patientPlaceOfWorkOrOfStudyPostcode"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.patientPlaceOfWorkOrOfStudyPostcode}
                                        helperText={touched.patientPlaceOfWorkOrOfStudyPostcode && t(errors.patientPlaceOfWorkOrOfStudyPostcode)}
                                        error={touched.patientPlaceOfWorkOrOfStudyPostcode && !!errors.patientPlaceOfWorkOrOfStudyPostcode}
                                        />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup >
                                    <Label htmlFor="patientPlaceOfWorkOrOfStudyCity">{t("city")} *</Label>
                                    <CustomTextField 
                                        fullWidth 
                                        id="patientPlaceOfWorkOrOfStudyCity"
                                        name="patientPlaceOfWorkOrOfStudyCity"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.patientPlaceOfWorkOrOfStudyCity}
                                        helperText={touched.patientPlaceOfWorkOrOfStudyCity && t(errors.patientPlaceOfWorkOrOfStudyCity)}
                                        error={touched.patientPlaceOfWorkOrOfStudyCity && !!errors.patientPlaceOfWorkOrOfStudyCity}
                                        />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={8}>
                                <FormGroup >
                                    <Box className={classes.titleContainer + ' ' + classes.sectionHeader + ' '+ classes.label}>
                                        {t('jobPositionOrFieldOfStudy')} *
                                    </Box>
                                   
                                    <CustomTextField 
                                        fullWidth 
                                        id="jobPositionOrFieldOfStudy"
                                        name="jobPositionOrFieldOfStudy"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.jobPositionOrFieldOfStudy}
                                        helperText={touched.jobPositionOrFieldOfStudy && t(errors.jobPositionOrFieldOfStudy)}
                                        error={touched.jobPositionOrFieldOfStudy && !!errors.jobPositionOrFieldOfStudy}
                                        />
                                </FormGroup>
                            </Grid>

                            {RenderGridWithCheckBox
                                (12, "hasReferralFromEmployer", values.hasReferralFromEmployer, false, 
                                handleChange, classes, t('hasReferralFromEmployer') )}

                            {RenderGridWithCheckBox
                                (12, "informationAboutHarmfulFactors", values.informationAboutHarmfulFactors, false, 
                                handleChange, classes, t('informationAboutHarmfulFactors') )}

                            {RenderGridWithCheckBox
                                (12, "resultsOfmeasurementsOfHarmfulFactors", values.resultsOfmeasurementsOfHarmfulFactors, false, 
                                handleChange, classes, t('resultsOfmeasurementsOfHarmfulFactors') )}
                         
                            {RenderGridWithCheckBox
                                (12, "informationAboutBurdensomeFactors", values.informationAboutBurdensomeFactors, false, 
                                handleChange, classes, t('informationAboutBurdensomeFactors') )}
                            
                            {isMedic && RenderGridItemKeyboardDatePicker(classes, handleBlur,"cardSubmisionDate", t('cardSubmisionDate'),
                                     values.cardSubmisionDate, setFieldValue)}
    
                            {isMedic && RenderGridItemKeyboardDatePicker(classes, handleBlur,"examinationDate", t('examinationDate'),
                                     values.examinationDate, setFieldValue)}

                        </Grid>                      
                    </Box>
                    {RenderReturnNextButtons(classes, t, onReturn)}
                </AccordionDetails>
            </Accordion>
        </form>
    )
};

export default PatientPlaceOfWorkOrOfStudyComponent;