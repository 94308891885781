import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {
    BACKGROUND_SECONDARY,
    LIGHT_GRAY,
    TEXT_SECONDARY,
} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {cursor: 'pointer'},
    extraDetailsTitle: {
        marginTop: 16,
        fontSize: 14,
        fontWeight: 'bold',
        color: TEXT_SECONDARY,
    },
    goBackWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 90,
        padding: `0 ${theme.spacing(3)}px`,
        borderBottom: `1px solid ${LIGHT_GRAY}`,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            borderBottom: `9px solid ${LIGHT_GRAY}`,
            padding: `0 ${theme.spacing(1)}px`,
        },
    },
    goBackButton: {
        backgroundColor: BACKGROUND_SECONDARY,
        width: 40,
        height: 40,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        marginRight: 17,
    },

    preferredTerm: {
        color: theme.palette.text.secondary,
        fontSize: 14,
    },
    timeBox: {
        width: 80,
        height: 45,
        marginLeft: 24,
        marginRight: 24,
        borderRadius: 2,
    },
}));

export default useStyles;
