import {makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';

export default makeStyles((theme: Theme) => ({
    container: {
        margin: mobile.contentLargerSpacing,
        marginTop: 0,
    },
    header: {
        fontSize: 36,
        fontWeight: 'bold',
        paddingTop: mobile.contentLargerSpacing,
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            margin: desktop.contentLargerSpacing,
            marginTop: 0,
        },
        header: {
            fontSize: 56,
            padding: '32px 0',
        },
    },
}));
