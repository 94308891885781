import {Attachment, AttachmentDto} from "../../types/appointments";
import {DateTime} from "luxon";

export const mapAttachments = (attachments: AttachmentDto[]): Attachment[] => {
    return attachments.map(attachment => {
        return {
            ...attachment,
            uploadDate: DateTime.fromISO(attachment.uploadDate)
        };
    })
}