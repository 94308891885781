import { Grid, IconButton, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { LanguageDto } from "../../../types/language";
import useStyles from "./LanguageCardSelectorStyles";


interface Props {
    languages: LanguageDto[];
    selectedLanguage: LanguageDto;
    handleSelectLanguage: (language: LanguageDto) => void;
}

const LanguageCardSelector = ({languages, selectedLanguage, handleSelectLanguage} : Props)  =>{
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <Grid 
            container 
            direction={isMobile ? 'column' : 'row' }
            className={classes.container}>

                {languages.map((language, idx) => (
                    <Grid item key={idx}>
                        <IconButton
                            className=
                                {selectedLanguage.id === language.id ? classes.buttonSelected : classes.button}
                                onClick={() => handleSelectLanguage(language)}>
                            {language.code}
                        </IconButton>
                    </Grid>
                ))}
        </Grid>)
}

export default LanguageCardSelector;