import {createStyles, makeStyles, useTheme, useMediaQuery, Box} from '@material-ui/core';
import Layout from "../../components/Layout/Layout";
import LogoAndCloseTopBar from "../../components/Layout/LogoAndCloseTopBar/LogoAndCloseTopBar";
import {PatientReferralMadeInfo, ReferPatientToSpecialistTopBar} from "../../components";
import useQuery from "../../hooks/useQuery";
import {WHITE} from "../../const/colors";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../const/i18nNamespaces";

const useStyles = makeStyles(() =>
    createStyles({
        contentMain: {
            minHeight: 'calc(100vh - 128px)',
            backgroundColor: WHITE,
        }
    }),
);

const PatientReferralSuccessfullyMadePage = () => {
    const classes = useStyles();
    const query = useQuery();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);

    const patientFirstName = query.get('patientFirstName');
    const patientSurnameName = query.get('patientSurname');

    return (
        <Layout
            topBar={
                isDesktop
                    ? (
                        <ReferPatientToSpecialistTopBar
                            referralForText={tReferrals('referralFor')}
                            firstName={patientFirstName}
                            surname={patientSurnameName}
                        />
                    )
                    : <LogoAndCloseTopBar />
            }
        >
            <Box className={classes.contentMain}>
                <PatientReferralMadeInfo />
            </Box>
        </Layout>
    )
}

export default PatientReferralSuccessfullyMadePage;