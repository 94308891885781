import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {CustomPasswordField, FormGroup, Label, Loading} from '../../components';
import {
    IDENTITY_CONFIRMATION_NEEDED_PAGE,
    MEDIC_CALENDAR,
    USER_ACCOUNT_PAGE,
} from '../../const/routes';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    selectAuthStatus,
    selectAuthUserData,
    selectFirstTimeLogin,
    forcedPasswordChange,
    signOut,
} from '../../store/auth';
import useStyles from './ForcedPasswordChangePageStyles';
import useQuery from '../../hooks/useQuery';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {FORCED_PASSWORD_CHANGE, MEDIC, PATIENT} from '../../const/auth';
import PasswordStrengthBar from 'react-password-strength-bar';
import CustomButton from '../../components/common/Button/Button';
import {useFormik} from 'formik';
import {forcedPasswordChangeSchema} from '../../validators/forgotPassword';

type LocationState = {
    email: string;
};

type PasswordChangeFormValues = {
    newPassword: string;
    repeatNewPassword: string;
};

const ForcedPasswordChangePage = () => {
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const dispatch = useAppDispatch();
    const firstTimeLogin = useAppSelector(selectFirstTimeLogin);
    const status = useAppSelector(selectAuthStatus);
    const [error, setError] = useState<string>('');

    const location = useLocation<LocationState>();
    const history = useHistory();
    const classes = useStyles();
    const query = useQuery();
    const returnUrl = query.get('returnUrl');
    const authUserData = useAppSelector(selectAuthUserData);

    useEffect(() => {
        if (authUserData?.role !== FORCED_PASSWORD_CHANGE) {
            if (returnUrl && returnUrl !== 'null') {
                // User has not confirmed identity
                if (
                    authUserData?.role !== MEDIC &&
                    authUserData?.role !== PATIENT
                ) {
                    dispatch(signOut());
                    return history.push(IDENTITY_CONFIRMATION_NEEDED_PAGE, {
                        afterRegistration: true,
                    });
                }
                history.push(returnUrl);
            } else {
                if (authUserData?.role === MEDIC)
                    return history.push(MEDIC_CALENDAR);

                if (authUserData?.role === PATIENT)
                    return history.push(USER_ACCOUNT_PAGE);

                dispatch(signOut());
                history.push(IDENTITY_CONFIRMATION_NEEDED_PAGE, {
                    afterRegistration: true,
                });
            }
        }
    }, [location, history, firstTimeLogin, returnUrl]);

    useEffect(() => {
        if (status === 'failed') setError(t('defaultError'));
    }, [status]);

    const onSubmit = (values: PasswordChangeFormValues) => {
        const {email} = location.state;
        setError('');
        dispatch(
            forcedPasswordChange({email, newPassword: values.newPassword}),
        );
    };

    const {values, handleChange, handleBlur, touched, errors, handleSubmit} =
        useFormik({
            initialValues: {
                newPassword: '',
                repeatNewPassword: '',
            },
            validationSchema: forcedPasswordChangeSchema,
            validateOnBlur: true,
            onSubmit,
        });

    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <Loading loading={status === 'loading'} withBackdrop />
            <Box className={classes.title}>{t('forcedPasswordChange')}</Box>
            <Grid container direction={isDesktop ? 'row' : 'column'}>
                <Grid item>
                    <FormGroup className={classes.formField}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.passwordLabelContainer}
                        >
                            <Box>
                                <Label htmlFor="newPassword">
                                    {t('newPasswordWithMinChars')}
                                </Label>
                            </Box>
                            <Box
                                className={classes.passwordStrengthBarContainer}
                            >
                                <PasswordStrengthBar
                                    password={values.newPassword}
                                    scoreWords={[]}
                                />
                            </Box>
                        </Grid>
                        <CustomPasswordField
                            id="newPassword"
                            name="newPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            helperText={
                                touched.newPassword && t(errors.newPassword)
                            }
                            error={touched.newPassword && !!errors.newPassword}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                        <Label htmlFor="repeatNewPassword">
                            {t('repeatNewPassword')}
                        </Label>
                        <CustomPasswordField
                            id="repeatNewPassword"
                            name="repeatNewPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.repeatNewPassword}
                            helperText={
                                touched.repeatNewPassword &&
                                t(errors.repeatNewPassword)
                            }
                            error={
                                touched.repeatNewPassword &&
                                !!errors.repeatNewPassword
                            }
                        />
                    </FormGroup>
                    <Box className={classes.error}>{t(error)}</Box>
                    <CustomButton
                        variant={'contained'}
                        color={'secondary'}
                        className={classes.submitButton}
                        type={'submit'}
                        fullWidth={!isDesktop}
                    >
                        {commonT('next')}
                    </CustomButton>
                </Grid>
            </Grid>
        </form>
    );
};

export default ForcedPasswordChangePage;
