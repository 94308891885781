import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import {LoadingStatus} from '../../const/loadingStatus';
import {Appointment} from '../../types/appointments';
import AppointmentsProvider from '../../services/appointmentsProvider';
import mapAppointment from '../mappers/mapAppointment';
import {RootState} from '../store';

const sliceName = 'upcoming-appointments';

const upcomingAppointmentsAdapter = createEntityAdapter<Appointment>({
    selectId: appointment => appointment.id,
    sortComparer: (first, second) =>
        first.startDate < second.startDate
            ? -1
            : first.startDate > second.startDate
            ? 1
            : 0,
});

const initialState = upcomingAppointmentsAdapter.getInitialState({
    status: 'idle' as LoadingStatus,
    updatingAppointment: {
        id: null as string,
        status: 'idle' as 'idle' | 'loading' | 'failed',
    }
});

export const fetchUpcomingAppointments =
    createAsyncThunk<Appointment[], {fetchSilently: boolean}, unknown>(
        `${sliceName}/fetchUpcomingAppointments`, async () => {
            const upcomingAppointments =
                await AppointmentsProvider.getUpcomingAppointments();

            return upcomingAppointments.map(mapAppointment);
        }
    );

export const fetchUpcomingAppointmentById =
    createAsyncThunk<Appointment, {id: string}, {state: RootState}>(
        `${sliceName}/fetchUpcomingAppointmentById`, async ({id}) => {
            const appointment = await AppointmentsProvider.getAppointment(id);
            return mapAppointment(appointment);
        }
    );

const upcomingAppointmentsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchUpcomingAppointments.pending, (state, action) => {
                state.status = action.meta.arg.fetchSilently
                    ? state.status
                    : 'loading';
            })
            .addCase(fetchUpcomingAppointments.fulfilled, (state, action) => {
                state.status = 'loaded';

                upcomingAppointmentsAdapter.setAll(state, action.payload);
            })
            .addCase(fetchUpcomingAppointments.rejected, state => {
                state.status = 'failed';
            });

        builder
            .addCase(fetchUpcomingAppointmentById.pending, (state, action) => {
                state.updatingAppointment = {
                    id: action.meta.arg.id,
                    status: 'loading'
                };
            })
            .addCase(fetchUpcomingAppointmentById.fulfilled, (state, action) => {
                upcomingAppointmentsAdapter.updateOne(state, {
                    id: action.meta.arg.id,
                    changes: action.payload
                });
                state.updatingAppointment = {
                    id: null,
                    status: 'idle'
                };
            })
            .addCase(fetchUpcomingAppointmentById.rejected, (state) => {
                state.updatingAppointment = {
                    id: null,
                    status: 'failed'
                };
            });
    },
});

export const selectUpcomingAppointments = (state: RootState) =>
    state.upcomingAppointments;

export const selectUpcomingAppointmentsStatus = (state: RootState) =>
    selectUpcomingAppointments(state).status;

export const selectUpdatingAppointment = (state: RootState) =>
    selectUpcomingAppointments(state).updatingAppointment;

export const {
    selectIds: selectUpcomingAppointmentIds,
    selectById: selectUpcomingAppointmentById,

} = upcomingAppointmentsAdapter.getSelectors<RootState>(
    state => state.upcomingAppointments,
);

export default upcomingAppointmentsSlice.reducer;
