import { rawHttp } from "../../services/http";
import { Voucher } from "../../types/voucher";
import { postVoucher } from "./voucherEndpoints";

class VoucherApi {
    async addVoucher(voucherData: Voucher) {
        return await rawHttp.post<boolean>(postVoucher, voucherData);
    }
}
export default new VoucherApi();

