import React from 'react';
import {Box, makeStyles, Theme} from '@material-ui/core';
import {mobile} from '../../../const/sizes';
import {DoctorsImage} from '../../../assets';

const useStyles = makeStyles((theme: Theme) => ({
    pageContent: {
        padding: `0 ${mobile.contentLargerSpacing}`,
    },
    image: {
        marginTop: 32,
        marginBottom: 120,
        width: '100%',
        height: 379,
    },
    [theme.breakpoints.up('md')]: {
        pageContent: {
            padding: 0,
        },
    },
}));

interface EmptyAccountPageProps {
    label: string;
}

const EmptyAccountPage = ({label}: EmptyAccountPageProps) => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.pageContent}>{label}</Box>
            <img className={classes.image} src={DoctorsImage} alt={'Medics'} />
        </>
    );
};

export default EmptyAccountPage;
