import {createStyles, makeStyles} from '@material-ui/core';
import { LIGHT_GRAY, MAGENTA } from '../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            marginBottom: 64,
            
        },
        title: {fontSize: 40},
        description: {
            marginTop: 14,
            marginBottom: 48,
            maxWidth: 900,
            textAlign: 'center',
        },
        logo:{
            height:100,
            marginTop:36
        },
        medicDetails:{
            maxWidth:1000
        },
    }),
);

export default useStyles;
