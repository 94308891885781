import {Box, Button, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import useStyles from './SickLeavesPageStyles';
import { useCallback, useEffect, useState } from 'react';
import { IssuedZLADto, ZLAHistoryDto } from '../../types/sickLeave';
import PatientSickLeaveListItem from '../../components/sickLeave/PatientSickLeaveListItem/PatientSickLeaveListItem';
import { Loading, Paging } from '../../components';
import kedZLAApi from '../../api/zusZLA/kedZLAApi';
import { da } from 'date-fns/locale';
import { useHistory } from 'react-router';
import { SICK_LEAVES } from '../../const/routes';

const SIZE = 10;

const SickLeavePage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.L4);

    const [sickLeaves, setSickLeaves] = useState<IssuedZLADto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [count, setCount] = useState<number>(0);

    const fetchSickLeaves = async (page: number) =>{
        setLoading(true);
        try{
            const {data} = await kedZLAApi.getSickLeaves(page, SIZE);
            if(data.isSuccess){
                setSickLeaves(data.result.results);
                setCount (data.result.count);
            }
        }catch{

        }
        finally{
            setLoading(false);
        }
    };

    useEffect(()=>{
        fetchSickLeaves(page);
    }, [])


    const pageChangeHandler = (pageIndex: number) => {
        setPage(pageIndex);
        fetchSickLeaves(pageIndex);
    };

   
    
    return (
        <Box>
            <Box className={classes.container}>
                <Loading loading={loading} withBackdrop/>
                <Box className={classes.boxTitle}>
                    {t('issuedSickLeaves')}
                </Box>
                {
                    sickLeaves && sickLeaves.map(x =>(
                        <PatientSickLeaveListItem 
                            reditectToDetailsEnabled={true}
                            key={x.seriesAndNumber} sickLeave={x}/>
                    ))
                }

<Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.pagingWrapper}
                >
                    <Paging
                        page={page}
                        size={SIZE}
                        count={count}
                        onChange={pageChangeHandler}
                    />
                </Grid>

            </Box>
            
            
        </Box>
    );
};

export default SickLeavePage;
