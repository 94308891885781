import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import Footer from './Footer/Footer';
import TopBarRegular from './TopBar/TopBar';

interface Props {
    topBar?: JSX.Element;
    children: JSX.Element | JSX.Element[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        layoutContent: {
            minHeight: `calc(100vh - 146px)`,
        },
        [theme.breakpoints.up('sm')]: {
            layoutContent: {
                minHeight: `calc(100vh - 128px)`,
            },
        },
    }),
);

const Layout = ({topBar, children}: Props) => {
    const classes = useStyles();

    return (
        <div>
            {topBar || <TopBarRegular />}
            <Box className={classes.layoutContent}>{children}</Box>
            <Footer />
        </div>
    );
};

export default Layout;
