import * as yup from 'yup';

const WRONG_VALUE = 'wrongValue';
const MUST_BE_NUMBER='mustBeNumber';

const questionnaireSchema = yup.object().shape({
    bodyHeight: yup.number().typeError(MUST_BE_NUMBER).moreThan(0, WRONG_VALUE),
    bodyWeight: yup.number().typeError(MUST_BE_NUMBER).moreThan(0, WRONG_VALUE),
    gender: yup.number().typeError(MUST_BE_NUMBER).oneOf([0, 1], WRONG_VALUE),
    age: yup.number().typeError(MUST_BE_NUMBER),
});

export default questionnaireSchema;
