import React, {ReactNode} from 'react';
import {Box, Grid, GridProps} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            color: theme.palette.secondary.main,
            cursor: 'pointer',
        },
        label: {
            marginLeft: 10,
        },
    }),
);

interface IconButtonWithTextProps {
    label: string;
    onClick?: () => void;
    icon: ReactNode;
    className?: string;
    style?: GridProps['style'];
}

const IconButtonWithText = ({
    label,
    onClick,
    icon,
    className,
    style,
}: IconButtonWithTextProps) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            onClick={onClick}
            className={clsx(classes.container, className)}
            style={style}
        >
            {icon}
            <Box className={classes.label}>{label}</Box>
        </Grid>
    );
};

export default IconButtonWithText;
