import { GENERAL_PRACTITIONER_APPOINTMENT_ID, MEDICAL_SERVICE_DEFAULT_DURATION, PEDIATRIC_APPOINTMENT_ID, PRESCRIPTION_APPOINTMENT_ID, SICK_LEAVE_APPOINTMENT_ID } from "./endpoints";
import { rawHttp } from "./http";


class configurationProvider {
    async getMedicalServiceDefaultDuration(){
        return await rawHttp.get<number>(MEDICAL_SERVICE_DEFAULT_DURATION);
    }

    async getPrescriptionAppointmentId(){
        return await rawHttp.get<number>(PRESCRIPTION_APPOINTMENT_ID);
    }

    async getSickLeaveAppointmentId(){
        return await rawHttp.get<number>(SICK_LEAVE_APPOINTMENT_ID);
    }

    async getGeneralPractitionerAppointmentId(){
        return await rawHttp.get<number>(GENERAL_PRACTITIONER_APPOINTMENT_ID);
    }

    async getGeneralPractitionerMedicalServiceId(){
        return await rawHttp.get<number>(PEDIATRIC_APPOINTMENT_ID);
    }

}

export default new configurationProvider();