import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {ReactNode} from 'react';
import {desktop, mobile} from '../../const/sizes';
import EmptyTopBar from './EmptyTopBar/EmptyTopBar';
import Footer from './Footer/Footer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        layout: {
            minHeight: '100vh',
        },
        layoutContent: {
            flex: 1,
            padding: `0 ${mobile.contentLargerSpacing}`,
        },
        [theme.breakpoints.up('sm')]: {
            layoutContent: {
                padding: `0 ${desktop.contentLargerSpacing}`,
            },
        },
    }),
);

type EmptyLayoutProps = {
    children: ReactNode;
};

const EmptyLayout = ({children}: EmptyLayoutProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={classes.layout}
        >
            <EmptyTopBar />
            <Box className={classes.layoutContent}>{children}</Box>
            <Footer />
        </Grid>
    );
};

export default EmptyLayout;
