import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {GRAY, LIGHT_DISABLED} from "../../../../const/colors";

const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        margin: '0',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            lineHeight: '32px',
        },
    },
    textField: {
        marginTop: '32px',
    },
    actionIconButtonsMobile: {
        marginTop: '27px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    actionIconButtonsMobileWithAttachment: {
        justifyContent: 'space-between',
    },
    actionButtonsDesktop: {
        display: 'flex',
        flex: '0 0 auto',
    },
    attachPrescriptionIconButton: {
        display: 'flex',
        alignItems: 'center',
        color: GRAY,
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            marginRight: '27px',
        },
    },
    clipIcon: {
        marginRight: '7px',
    },
    prescriptions: {
        padding: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '32px',
        [theme.breakpoints.up('md')]: {
            marginTop: '16px',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    },
    prescriptionsItem: {
        padding: 0,
        margin: '8px 0 0',
        [theme.breakpoints.up('md')]: {
            margin: '0 16px 0 0',
        },
    },
    disabled: {
        color: LIGHT_DISABLED,
        cursor: 'default',
    },
}));

export default useStyles;