import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';
import {LIGHT_GRAY} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: mobile.contentLargerSpacing,
        },
        childrenTile: {
            backgroundColor: 'white',
            borderRadius: 3,
            marginTop: 6,
        },
        childrenTileHeader: {
            padding: 16,
            borderBottom: `1px solid ${LIGHT_GRAY}`,
            fontWeight: 'bold',
            fontSize: 18,
        },
        childrenTileContent: {
            padding: 16,
        },
        buttonTile: {
            backgroundColor: 'white',
            borderRadius: 3,
            height: 56,
            textAlign: 'center',
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'space-between',
            padding: 16,
            marginBottom: 8,
            cursor: 'pointer',
            alignItems: 'center',
        },
        [theme.breakpoints.up('md')]: {
            container: {
                paddingLeft: desktop.contentLargerSpacing,
                paddingRight: desktop.contentLargerSpacing,
            },
        },
    }),
);

export default useStyles;
