import { Box, Grid } from "@material-ui/core";
import useStyles from "./FinishedMedicalTestsStyles";
import useAppointmentsStyles from '../../appointmentsCalendar/appointmentsCalendarSectionStyles';
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import Paging from "../../common/Paging/Paging";
import { useAppDispatch, useAppSelector } from "../../../hooks/customReduxHooks";
import {  fetchOrderedDiagnosticOrderHeaders, pageSpecified, selectOrderedDiagnosticOrderHeadersCount, selectOrderedDiagnosticOrderHeadersIds, selectOrderedDiagnosticOrderHeadersPage, selectOrderedDiagnosticOrderHeadersSize } from "../../../store/referrals/orderedDiagnosticOrderHeaderSlice";
import {useEffect, useState} from 'react';
import FinishedMedicalTest from "../finishedMedicalTest/FinishedMedicalTest";
import { selectAuthUserData } from "../../../store/auth";
import { selectSelectedChildId } from "../../../store/childSelectionSlice";
import Loading from "../../common/Loading/Loading";


const FinishedMedicalTests = () =>{

    const dispatch = useAppDispatch();

    const finishedOrderHeaderIds = useAppSelector(selectOrderedDiagnosticOrderHeadersIds);
    const finishedOrderHeaderPage = useAppSelector(
        selectOrderedDiagnosticOrderHeadersPage,
    );
    const finishedOrderHeaderSize = useAppSelector(
        selectOrderedDiagnosticOrderHeadersSize,
    );
    const finishedOrderHeaderCount = useAppSelector(
        selectOrderedDiagnosticOrderHeadersCount,
    );

    const selectedChildId = useAppSelector(selectSelectedChildId);

    const [loading, setLoading] = useState<boolean>(false);
   

    useEffect(() => {
        (async () => {
            setLoading(true);
            await dispatch(fetchOrderedDiagnosticOrderHeaders(selectedChildId ? selectedChildId.toString() : null)); 
            setLoading(false);
        })();
    }, [dispatch, setLoading, selectedChildId]);

    const pageChangeHandler = (page: number) => {
        dispatch(pageSpecified(page));
        dispatch(fetchOrderedDiagnosticOrderHeaders(null));
    };


    const appointmentsClasses = useAppointmentsStyles();
    const classes = useStyles();

    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);
    
    return (
        <Box className={appointmentsClasses.appointmentWrapper}>
            <Loading loading={loading} withBackdrop />
            <Box className={appointmentsClasses.boxTitle}>
                {t('finishedMedicalTests')}
            </Box>
            {finishedOrderHeaderIds.map(orderHeaderId => (
                <FinishedMedicalTest
                    key={orderHeaderId}
                    finishedDiagnosticServiceOrderId = {orderHeaderId as string}
                />
            ))}
            {finishedOrderHeaderSize < finishedOrderHeaderCount && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.pagingWrapper}
                >
                    <Paging
                        page={finishedOrderHeaderPage}
                        size={finishedOrderHeaderSize}
                        count={finishedOrderHeaderCount}
                        onChange={pageChangeHandler}
                    />
                </Grid>
            )}
        </Box>
    );
}

export default FinishedMedicalTests;