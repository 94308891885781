import {IconButton, Snackbar, SnackbarProps} from '@material-ui/core';
import {Close} from '@material-ui/icons';

export type ConfirmationProps = {
    open: boolean;
    onClose: SnackbarProps['onClose'];
    message: SnackbarProps['message'];
};

const Confirmation = ({open, onClose, message}: ConfirmationProps) => (
    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        message={message}
        action={
            <>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => onClose(null, null)}
                >
                    <Close fontSize="small" />
                </IconButton>
            </>
        }
    />
);

export default Confirmation;
