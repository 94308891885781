import React, {memo, useCallback, useEffect, useState} from 'react';
import {Box, createStyles, IconButton, makeStyles, Snackbar, Theme} from '@material-ui/core';
import {DeleteOutline} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {WHITE} from '../../../const/colors';
import {CustomButton, Loading} from '../../index';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    clearSelectedMedicalPackage,
    clearSelectedMedicalTests,
    clearSelectedPapTestCollectedFrom,
    removeSelectedMedicalTestById,
    selectSelectedMedicalPackage,
    selectSelectedMedicalTests,
    selectSelectedMedicalTestsCategory,
    selectSelectedPapTestAdditionalInfo,
    selectSelectedPapTestChildbirthNumber,
    selectSelectedPapTestCollectedFrom,
    selectSelectedPapTestLastMenstruationDate
} from '../../../store/referrals/medicalTestsSlice';
import useAppointmentContext from '../../../hooks/appointment/useAppointmentContext';
import useCreateReferralForMedicalTests from '../../../hooks/referrals/useCreateReferralForMedicalTests';
import {Alert} from "@material-ui/lab";
import { fetchAppointmentReferrals } from '../../../store/referrals/appointmentReferralSlice';
import { PapTestDetailsDto } from '../../../services/diagnostic/orderHeadersProvider';
import ReferralForMedicalPapTestSummary from './ReferralForMedicalPapTestSummary';
import { PAP_TEST_CODE, PAP_TEST_TYPE } from '../../../const/papTest';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 44,
            padding: 16,
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                marginTop: 0,
                padding: 25,
            }
        },
        headerText: {
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
                lineHeight: '32px',
            },
        },
        selectedMedicalTests: {
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: '24px',
        },
        selectedOrNotMedicalTestsText: {
            marginTop: 10
        },
        selectedMedicalTestsItem: {
            '&:not(:first-child)': {
                marginTop: 8,
            }
        },
        selectedMedicalTestsItemContent: {
            display: 'flex',
            alignItems: 'center',
        },
        deleteIconButton: {
            padding: 0,
            marginLeft: 7,
        },
        deleteIcon: {
            width: 23,
        },
        makeReferral: {
            marginTop: 16,
            [theme.breakpoints.up('md')]: {
                marginTop: 24,
            }
        },
    }),
);

interface Props {
    showMedicalTests: boolean;
    setShowMedicalTests: (showMedicalTests:boolean)=>void;
}


const ReferralForMedicalTestsSummary = memo(function MedicalTestsSummary({showMedicalTests, setShowMedicalTests}:Props) {   
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.REFERRALS);

    const [validate, setValidate] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [makePapReferral, setMakePapReferral] = useState<boolean>(false);

    const { appointment } = useAppointmentContext();
    const {
        createReferralForMedicalTests,
        createReferralForMedicalTestsLoading,
        createReferralForMedicalTestsError
    } = useCreateReferralForMedicalTests();

    const dispatch = useAppDispatch();
    const selectedMedicalTests = useAppSelector(selectSelectedMedicalTests);
    const selectedMedicalTestsCategory = useAppSelector(selectSelectedMedicalTestsCategory);
    const selectedMedicalTestsPackage = useAppSelector(selectSelectedMedicalPackage);
    const selectedPapTestCollectedFrom = useAppSelector(selectSelectedPapTestCollectedFrom);
    const selectedPapTestLastMenstruationDate = useAppSelector(selectSelectedPapTestLastMenstruationDate);
    const selectedPapTestChildbirthNumber = useAppSelector(selectSelectedPapTestChildbirthNumber);
    const selectedPapTestAdditionalInfo = useAppSelector(selectSelectedPapTestAdditionalInfo);
    

    const selectedGroupOfMedicalTests = useAppSelector(
        selectSelectedMedicalTestsCategory,
    );


    const isPapTest = selectedGroupOfMedicalTests 
        && selectedGroupOfMedicalTests===PAP_TEST_TYPE 
        && selectedMedicalTests.length > 0
        && selectedMedicalTests[0].code===PAP_TEST_CODE

    const removeMedicalTest = (id: number) => {
        dispatch(removeSelectedMedicalTestById(id))
        dispatch(clearSelectedPapTestCollectedFrom());
        //TODO
    };

    const removeMedicalPackage = () =>{
        dispatch(clearSelectedMedicalTests());
        dispatch(clearSelectedMedicalPackage());
    }

    const makeReferral = useCallback(() => {     
            createReferralForMedicalTests({
                appointment,
                selectedMedicalTestsCategory,
                selectedMedicalTests,
                selectedMedicalTestsPackage,
                selectedPapTestCollectedFrom,
                selectedPapTestLastMenstruationDate,
                selectedPapTestChildbirthNumber,
                selectedPapTestAdditionalInfo
            }).then(
                ()=>dispatch(fetchAppointmentReferrals({appointmentIdAsString: appointment.id})));

            
    }, [
        appointment,
        selectedMedicalTestsCategory,
        selectedMedicalTests,
        selectedMedicalTestsPackage,
        selectedPapTestCollectedFrom,
        selectedPapTestLastMenstruationDate,
        selectedPapTestChildbirthNumber,
        selectedPapTestAdditionalInfo,
        createReferralForMedicalTests,
        dispatch
    ]);


    useEffect(()=>{
        if(makePapReferral && isValid)
        {
            makeReferral();
        
            setMakePapReferral(false);
            setIsValid(false);
            setValidate(false);
        }
    }, [makePapReferral, isValid])

    const makeReferralButtonClickHandler = () => {     
        if(isPapTest){
            setMakePapReferral(true);
            setValidate(true);
        }
        else
            makeReferral();
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.headerText}>
                {showMedicalTests ?  t(selectedMedicalTestsCategory) :
                 (selectedMedicalTestsPackage ? selectedMedicalTestsPackage.name : t('selectPackage') )}

                {
                    selectedMedicalTestsPackage && 
                        <IconButton
                            className={classes.deleteIconButton}
                            onClick={() => removeMedicalPackage()}
                        >
                            <DeleteOutline
                                className={classes.deleteIcon}
                                color="secondary"
                            />
                        </IconButton>
                } 
            </Box>
            <Box className={classes.selectedMedicalTests}>
                <Box className={classes.selectedOrNotMedicalTestsText}>
                    {
                        selectedMedicalTests.length
                            ? `${t('selectedMedicalTests')}:`
                            : (showMedicalTests ?  t('selectAtLeastOneMedicalTestToMakeReferral') : t('selectOneMedicalTestPackageToMakeReferral'))
                    }      
                </Box>
                {
                    selectedMedicalTests.length !== 0 && (
                        <ul>
                            {
                                selectedMedicalTests.map(selectedMedicalTest => (
                                    <li
                                        key={selectedMedicalTest.id}
                                        className={classes.selectedMedicalTestsItem}
                                    >
                                        <Box className={classes.selectedMedicalTestsItemContent}>
                                            {selectedMedicalTest.name}
                                            {showMedicalTests && 
                                            <IconButton
                                                className={classes.deleteIconButton}
                                                onClick={() => removeMedicalTest(selectedMedicalTest.id)}
                                            >
                                                <DeleteOutline
                                                    className={classes.deleteIcon}
                                                    color="secondary"
                                                />
                                            </IconButton>}
                                        </Box>
                                    </li>
                                ))
                            }
                        </ul>
                    )
                }
            </Box>


            {isPapTest
                && <ReferralForMedicalPapTestSummary validate={validate} setValidate ={setValidate} setIsValid={setIsValid}/>}



            <CustomButton
                className={classes.makeReferral}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={!selectedMedicalTests.length || !appointment}
                onClick={makeReferralButtonClickHandler}
            >
                {t('makeReferral')}
            </CustomButton>
            {
                createReferralForMedicalTestsError && (
                    <Snackbar
                        open={true}
                    >
                        <Alert severity="error">
                            {createReferralForMedicalTestsError}
                        </Alert>
                    </Snackbar>
                )
            }

            <Loading withBackdrop loading={createReferralForMedicalTestsLoading} />
        </Box>
    );
});

export default ReferralForMedicalTestsSummary;