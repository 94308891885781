import {RegisterUserDto} from '../types/Login';
import {ACCOUNT, EVENTS_HTTP, INITIALIZE} from './endpoints';
import {rawHttp} from './http';

// Deprecated. Please add new request handlers to respective providers.
class HTTPService {
    getInitialization = () => rawHttp.get(INITIALIZE).then(res => res.data);

    getEvents = (MedicalService: number, StartDate: string, EndDate: string) =>
        rawHttp.get(EVENTS_HTTP, {
            params: {MedicalService, StartDate, EndDate},
        });

    register = (userData: RegisterUserDto) => rawHttp.put(ACCOUNT, userData);

    getUser = () => rawHttp.get(ACCOUNT);
}

export default new HTTPService();
