export enum MediaWarnings {
    AudioInputSelectInBrowserNotSupported,
}

export const mediaWarningHandler = (name: MediaWarnings) => {
    switch (name) {
        case MediaWarnings.AudioInputSelectInBrowserNotSupported:
            return 'audioInputSelectInBrowserNotSupported';
    }
};
