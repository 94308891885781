import {CaseReducer, PayloadAction, createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import { NFZInsuranceDeclarationStatusDto, PersonalDataStatusDto } from '../../types/patient';
import patientProvider from '../../services/patientProvider';
import { P1Response } from '../../types/p1Service';

type PatientPersonalDataStatus = 'idle' | 'loading' | 'failed';
const sliceName = 'patientAddressStatus';

interface PatientPersonalDataStatusDto{
    personalData: PersonalDataStatusDto,
    personalDataStatus: PatientPersonalDataStatus;
    nFZInsuranceDeclaration:NFZInsuranceDeclarationStatusDto
    nFZInsuranceDeclarationStatus: PatientPersonalDataStatus;
}

const initialState: PatientPersonalDataStatusDto = {
    personalData: null,
    personalDataStatus:'idle',
    nFZInsuranceDeclaration:null,
    nFZInsuranceDeclarationStatus:'idle'
};



export const fetchPatientPersonalData= createAsyncThunk<PersonalDataStatusDto, {fetchSilently: boolean}, unknown>(
    `${sliceName}/fetchPatientPersonalData`,
    async () => {
        return await patientProvider.getPatientPersonalDataStatus()
    },
);

export const fetchPatientNFZInsuranceDeclaration = createAsyncThunk<NFZInsuranceDeclarationStatusDto, {fetchSilently: boolean}, unknown>(
    `${sliceName}/fetchPatientNFZInsuranceDeclaration`,
    async () => {
        return await patientProvider.getNFZInsuranceDeclarationStatus();
    },
);

const patientPersonalDataSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setNFZInsuranceDeclaration: (state, action: PayloadAction<NFZInsuranceDeclarationStatusDto | null>) => {
            state.nFZInsuranceDeclaration = action.payload;
        },
        setAddress: (state, action: PayloadAction<PersonalDataStatusDto | null>) => {
            state.personalData = action.payload;
        }, 
    },
    extraReducers: builder => {
        builder
            .addCase(fetchPatientPersonalData.pending, (state, action) => {
                state.personalDataStatus = action.meta.arg.fetchSilently
                    ? state.personalDataStatus
                    : 'loading';
            })
            .addCase(fetchPatientPersonalData.fulfilled, (state, action) => {
                state.personalData= action.payload;
                state.personalDataStatus = 'idle';
            })
            .addCase(fetchPatientNFZInsuranceDeclaration.pending, (state, action) => {
                state.nFZInsuranceDeclarationStatus = action.meta.arg.fetchSilently
                    ? state.nFZInsuranceDeclarationStatus
                    : 'loading';
            })
            .addCase(fetchPatientNFZInsuranceDeclaration.fulfilled, (state, action) => {
                state.nFZInsuranceDeclaration = action.payload;
                state.nFZInsuranceDeclarationStatus = 'idle';
            })
    },
});

export const actions = patientPersonalDataSlice.actions;

export const {
    setNFZInsuranceDeclaration,
    setAddress,
} = actions;

export const selectHasAddress = (state: RootState) =>
    state.patientPersonalDataStatus.personalData?.hasAddress;

export const selectIsIdentityConfirmed = (state: RootState) =>
    state.patientPersonalDataStatus.personalData?.isIdentityConfirmed;

export const selectHasNFZInsuranceDeclaration = (state: RootState) =>
    state.patientPersonalDataStatus.nFZInsuranceDeclaration?.hasInsuranceDeclaration;

export const selectPatientPersonalStatus = (state: RootState) =>
    state.patientPersonalDataStatus.personalDataStatus;

export const selectHasNFZInsuranceDeclarationStatus= (state: RootState) =>
    state.patientPersonalDataStatus.nFZInsuranceDeclarationStatus;

export default patientPersonalDataSlice.reducer;