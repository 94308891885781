import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import {PatientOccupationalMedicineReferralDto} from '../../types/referrals';
import referralsProvider from '../../services/referralsProvider';
import {RootState} from '../store';

const sliceName = 'referrals-to-occupational-medicine';

const patientOccupationalMedicineReferralsAdapter =
    createEntityAdapter<PatientOccupationalMedicineReferralDto>({
        selectId: referral => referral.id,
    });

const initialState =
    patientOccupationalMedicineReferralsAdapter.getInitialState({
        status: 'idle' as 'idle' | 'loading' | 'error',
    });

export const fetchReferrals = createAsyncThunk(
    `${sliceName}/fetchReferrals`,
    async () => {
        const response =
            await referralsProvider.getOccupationalMedicineReferrals();
        return response.data?.results;
    },
);

const referralsToOccupationalMedicineSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchReferrals.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchReferrals.fulfilled, (state, action) => {
                state.status = 'idle';
                patientOccupationalMedicineReferralsAdapter.setAll(
                    state,
                    action.payload,
                );
            });
    },
});

export const {resetState} = referralsToOccupationalMedicineSlice.actions;

export const {
    selectIds: selectPatientOccupationalMedicineReferralsIds,
    selectById: selectPatientOccupationalMedicineReferralById,
} = patientOccupationalMedicineReferralsAdapter.getSelectors<RootState>(
    state => state.referralsToOccupationalMedicine,
);

export const selectPatientOccupationalMedicineReferralsStatus = (
    state: RootState,
) => state.referralsToOccupationalMedicine.status;

export default referralsToOccupationalMedicineSlice.reducer;
