import {
    Box,
    Grid,
    RadioGroup,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {PHONE_VERIFICATION_PAGE, REGISTER_PAGE} from '../../const/routes';
import CreateAccount from './CreateAccount/CreateAccount';
import Login from './Login/Login';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {
    selectAuth,
    selectAuthUserData,
    signIn,
    signOut,
} from '../../store/auth';
import {Loading} from '../../components';
import {selectMedicalServiceId} from '../../store/timeSlotsSlice';
import {
    selectEndDateTime,
    selectStartDateTime,
} from '../../store/timeSlotsSearchSlice';
import useQuery from '../../hooks/useQuery';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import accountProvider from '../../services/accountProvider';
import {postFormToLocation} from '../../utils/postFormToLocation';
import {Helmet} from 'react-helmet-async';
import configuration from '../../configuration';
import {isNotNullOrUndefined} from '../../utils/lang';
import * as Sentry from '@sentry/browser';

export type LoginType = 'CREATE_ACCOUNT' | 'LOGIN';

const LoginPage = () => {
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const {t: tMetaDescription} = useTranslation(
        i18nNamespaces.META_DESCRIPTION,
    );
    const dispatch = useAppDispatch();
    const authState = useAppSelector(selectAuth);
    const authUserData = useAppSelector(selectAuthUserData);
    const medicalServiceId = useAppSelector(selectMedicalServiceId);
    const startDate = useAppSelector(selectStartDateTime);
    const endDate = useAppSelector(selectEndDateTime);
    const query = useQuery();
    const loginTypeFromQuery = query.get('login_type');
    const [loginType, setLoginType] = useState(
        loginTypeFromQuery || ('CREATE_ACCOUNT' as LoginType),
    );
    const [mojeIDProcessing, setMojeIDProcessing] = useState(false);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const returnUrl = query.get('returnUrl');
    const encodedReturnUrl = '?returnUrl=' + encodeURIComponent(returnUrl);
    const origin = query.get('origin');

    // if (!userHasRoles) query.delete('returnUrl');

    useEffect(() => {
        try {
            if (isNotNullOrUndefined(location.state)) {
                setLoginType(location.state as LoginType);
            }
        } catch (error) {
            setLoginType('CREATE_ACCOUNT' as LoginType);
        }
    }, []);

    useEffect(() => {
        // The user clicked on the link in the email
        if (origin === 'email') {
            history.push({
                pathname: location.pathname,
                search: encodedReturnUrl,
            });
            dispatch(signOut());
            return;
        }
        if (authState.token) {
            // The user was recognized and signed in with password
            if (returnUrl) {
                return history.push({
                    pathname: PHONE_VERIFICATION_PAGE,
                    state: {email: authState.email},
                    search: encodedReturnUrl,
                });
            } else {
                return history.push(PHONE_VERIFICATION_PAGE, {
                    email: authState.email,
                });
            }
        }
    }, [
        query,
        authState,
        authUserData,
        history,
        startDate,
        endDate,
        medicalServiceId,
        returnUrl,
        encodedReturnUrl,
    ]);

    const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setLoginType(value as LoginType);
    };

    const signInHandler = (values: {email: string; password: string}) => {
        dispatch(signIn({email: values.email, password: values.password}));
        Sentry.setUser({email: values.email});
    };

    const alternativeSignUpHandler = () => {
        if (returnUrl) {
            history.push({
                pathname: REGISTER_PAGE,
                search: encodedReturnUrl,
            });
        } else {
            history.push(REGISTER_PAGE);
        }
    };

    const mojeIDHandler = async () => {
        setMojeIDProcessing(true);

        const {url, parameterName, parameterValue} =
            await accountProvider.getIdentityConfirmationRequestData();

        const formData = new Map<string, string>();
        formData.set(parameterName, parameterValue);

        postFormToLocation(url, formData);
    };

    return (
        <div>
            {configuration.environment === 'Production' && (
                <Helmet>
                    <title>{tMetaDescription('logInPageTitle')}</title>
                    <meta
                        name="description"
                        content={tMetaDescription('logInPageDescription')}
                    />
                </Helmet>
            )}
            <Box p={isDesktop ? 3 : 1}>
                <Typography variant={isDesktop ? 'h1' : 'h3'}>
                    {t('loginOrRegister')}
                </Typography>
            </Box>
            <Box p={isDesktop ? 2 : 0}>
                <RadioGroup
                    aria-label="Typ logowania"
                    name="loginType"
                    value={loginType}
                    onChange={onChangeType}
                >
                    <Grid container>
                        <Grid item xs={12} md={6} style={{flexGrow: 1}}>
                            <CreateAccount
                                selected={loginType === 'CREATE_ACCOUNT'}
                                onClickAlternativeSignUp={
                                    alternativeSignUpHandler
                                }
                                onClickMojeID={mojeIDHandler}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{flexGrow: 1}}>
                            <Login
                                selected={loginType === 'LOGIN'}
                                error={authState.errorReason}
                                onSubmit={signInHandler}
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
            </Box>
            <Loading
                withBackdrop={true}
                loading={authState.status === 'loading' || mojeIDProcessing}
            />
        </div>
    );
};

export default LoginPage;
