import React, {FC, SetStateAction, memo, useState} from 'react';
import {
    Box,
    CircularProgress,
    createStyles,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {ICD10Code, ICD10CodeTranslated} from '../../../types/icd10';
import { mapICD10ForAutocomplete} from '../../../utils/icd10';
import { Autocomplete } from '@material-ui/lab';
import { FormikErrors } from 'formik';

const POPUP_ICD10_CODE_AND_NAME_SEPARATOR = '-';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            marginTop: 32,
        },
        searchButton: {
            [theme.breakpoints.up('sm')]: {
                padding: '12px 53px',
            },
        },
    }),
);

interface ICD10AutocompleteSearchProps {
    iCD10Codes?: ICD10Code[];
    optionSelected:ICD10CodeTranslated;
    onChange: (_:any, newValue:any)=>void;
    error:boolean;
    helperText:FormikErrors<ICD10CodeTranslated>;
    setICD10CodesInputValue:(value:string) => void;
    iCD10CodesLoading:boolean
    disabled?:boolean
}

const ICD10AutocompleteSearch: FC<ICD10AutocompleteSearchProps> = props => {
    const 
    {
        iCD10Codes, 
        optionSelected, 
        onChange, 
        error, 
        helperText, 
        setICD10CodesInputValue, 
        iCD10CodesLoading,
        disabled=false
    } = props;


    const classes = useStyles();
    const {i18n} = useTranslation();
    const {t} = useTranslation(i18nNamespaces.L4);


    
    const handleChangeICD10Input = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setICD10CodesInputValue(event.target.value);
    };

    return (
        <Autocomplete
            noOptionsText={t('enterTwoCharactersAtLeast')}
            loadingText={t('loading')}
            disabled={disabled}
            loading={iCD10CodesLoading}
            options={iCD10Codes?.map(v => 
                {
                    return mapICD10ForAutocomplete(v, i18n.language)
                }
                
            )}
            getOptionSelected={option =>
                option.code === optionSelected.code
            }
            getOptionLabel={option => option.code ? `${option.code} ${POPUP_ICD10_CODE_AND_NAME_SEPARATOR} ${option.value}` : ""}
            value={props.optionSelected}
            onChange={(_, newValue) => { onChange(_,newValue)
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    onChange={handleChangeICD10Input}
                    label={t('icd10')}
                    variant="outlined"
                    error={ error
                    }
                    helperText={ helperText
                    }
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {iCD10CodesLoading ? (
                                    <CircularProgress
                                        color="secondary"
                                        size={20}
                                        style={{marginRight: 10}}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default memo(ICD10AutocompleteSearch);
