import {makeStyles} from "@material-ui/core";
import {GRAY} from "../../../../const/colors";

const useStyles = makeStyles(() => ({
    iconButtonWithText: {
        color: GRAY,
    },
    editIcon: {
        height: 18,
        width: 18,
    },
}));

export default useStyles;