import {rawHttp} from '../http';
import {DIAGNOSTIC_ORDER_HEADERS} from '../endpoints';

export interface GetLaboratoryAddressesParams {
    referralId: string;
    regionId: number;
}

export interface LaboratoryAddressDto {
    address: string;
    amountToPay: number;
    city: string;
    contactEmail: string;
    contactPhoneNumber: string;
    diagnosticServicePriceLists: unknown[];
    id: number
    laboratoryName: string;
    regionId: number;
    zipCode: string;
}

export interface DiagnosticServicePriceDto {
    commissionPercentage: number;
    diagnosticServiceId: number;
    diagnosticServiceName: string;
    listPrice: number;
    purchasePrice: number;
    regionId: number;

}

export function getLaboratoryAddresses({referralId, regionId}: GetLaboratoryAddressesParams) {
    return rawHttp.get<{
        laboratoryAddresses: LaboratoryAddressDto[],
        diagnosticServicePriceLists: DiagnosticServicePriceDto[]
    }>(
        `${DIAGNOSTIC_ORDER_HEADERS}/${referralId}/regions/${regionId}/laboratory-addresses`
    );
}