import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import clsx from 'clsx';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {
    Button,
    CustomDatePicker,
    Label,
    Loading,
    MobileSubheader,
    TimeSlotInput,
} from '../..';
import i18nNamespaces from '../../../const/i18nNamespaces';
import timeSlotsProvider from '../../../services/timeSlotsProvider';
import TimeOfDay from '../../../utils/timeOfDay';
import useStyles from './MedicCalendarAddTimeSlotsStyles';
import {useAddMedicTimeSlotsDates} from '../../../hooks/timeSlots/useAddMedicTimeSlotsDates';
import {CustomAlert} from '../../common/feedback';
import axios from 'axios';
import {ERROR} from '../../../const/routes';

type MedicCalendarAddTimeSlotsProps = {
    onTimeSlotsAdded: () => void;
    serviceDuration: number;
};

const MedicCalendarAddTimeSlots = ({
    onTimeSlotsAdded,
    serviceDuration,
}: MedicCalendarAddTimeSlotsProps) => {
    const [startTime, setStartTime] = useState<TimeOfDay>(
        TimeOfDay.getClosestHour(),
    );
    const [endTime, setEndTime] = useState<TimeOfDay>(
        TimeOfDay.getLatestTime(),
    );
    const [createMedicTimeSlotsLoading, setCreateMedicTimeSlotsLoading] =
        useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const {t} = useTranslation(i18nNamespaces.MEDIC_CALENDAR);
    const addTimeSlotsDates = useAddMedicTimeSlotsDates();

    const earliestPossibleTimeOfDay = useMemo(
        () => TimeOfDay.getEarliestTime(),
        [],
    );

    const latestPossibleTimeOfDay = useMemo(
        () => TimeOfDay.getLatestTime(),
        [],
    );

    if (!addTimeSlotsDates) {
        return null;
    }

    const {
        medicCalendarDatesLoading,
        startDate,
        minStartDate,
        maxStartDate,
        endDate,
        minEndDate,
        maxEndDate,
        setSelectedStartDateFromJsDate,
        setSelectedEndDateFromJsDate,
    } = addTimeSlotsDates;

    const loading = medicCalendarDatesLoading || createMedicTimeSlotsLoading;

    const onBackHandler = () => {
        history.goBack();
    };

    const addNewTimeSlotsHandler = async () => {
        setCreateMedicTimeSlotsLoading(true);
        try {
            await timeSlotsProvider.createMedicTimeSlots({
                startDate: startDate.toISODate(),
                endDate: endDate.toISODate(),
                startTime: +startTime,
                endTime: +endTime,
            });
            onTimeSlotsAdded();
        } catch (err) {
            if (axios.isAxiosError(err)) {
                console.error(err.response.data[0]);
                if (err.response.data[0] === 'TimeSlotsGeneratorIsBusy')
                    setError(t('timeSlotsGeneratorIsBusy'));
                else history.push(ERROR);
            }
            console.error(err);
        } finally {
            setCreateMedicTimeSlotsLoading(false);
        }
    };


    return (
        <>
            <Loading withBackdrop={true} loading={loading} />
            {!!error && (
                <CustomAlert
                    severity="error"
                    message={error}
                    onClose={() => setError('')}
                />
            )}
            <Box>
                <Box className={classes.header}>
                    {!isDesktop && (
                        <MobileSubheader
                            onBack={onBackHandler}
                            text={t('addAppointment')}
                        />
                    )}
                </Box>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    className={classes.formContainer}
                >
                    <Box className={classes.fromSection}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Box
                                className={clsx(
                                    classes.formGroup,
                                    classes.formDate,
                                )}
                            >
                                <Label htmlFor="startDate">
                                    {t('fromDate')}
                                </Label>
                                <CustomDatePicker
                                    name="startDate"
                                    variant={isDesktop ? 'inline' : 'dialog'}
                                    ToolbarComponent={() => <></>}
                                    value={startDate.toJSDate()}
                                    minDate={minStartDate.toJSDate()}
                                    maxDate={maxStartDate.toJSDate()}
                                    onChange={setSelectedStartDateFromJsDate}
                                    className={classes.formInput}
                                />
                            </Box>
                            <Box
                                className={clsx(
                                    classes.formGroup,
                                    classes.formTime,
                                )}
                            >
                                <Label htmlFor="startTime">
                                    {t('fromTime')}
                                </Label>
                                <TimeSlotInput
                                    name="startTime"
                                    value={startTime}
                                    onChange={value => setStartTime(value)}
                                    disabled={false}
                                    earliestDateTime={earliestPossibleTimeOfDay}
                                    latestDateTime={endTime}
                                    className={classes.timeSlotInput}
                                    fullWidth={true}
                                    serviceDuration={serviceDuration}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box className={classes.toSection}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Box
                                className={clsx(
                                    classes.formGroup,
                                    classes.formDate,
                                )}
                            >
                                <Label htmlFor="endDate">{t('toDate')}</Label>
                                <CustomDatePicker
                                    name="endDate"
                                    variant={isDesktop ? 'inline' : 'dialog'}
                                    ToolbarComponent={() => <></>}
                                    value={endDate.toJSDate()}
                                    minDate={minEndDate.toJSDate()}
                                    maxDate={maxEndDate.toJSDate()}
                                    onChange={setSelectedEndDateFromJsDate}
                                    className={classes.formInput}
                                />
                            </Box>
                            <Box
                                className={clsx(
                                    classes.formGroup,
                                    classes.formTime,
                                )}
                            >
                                <Label htmlFor="endTime">{t('toTime')}</Label>
                                <TimeSlotInput
                                    name="endTime"
                                    value={endTime}
                                    onChange={value => setEndTime(value)}
                                    disabled={false}
                                    earliestDateTime={startTime}
                                    latestDateTime={latestPossibleTimeOfDay}
                                    className={classes.timeSlotInput}
                                    fullWidth={true}
                                    serviceDuration={serviceDuration}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box className={classes.saveButtonContainer}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.saveButton}
                            onClick={addNewTimeSlotsHandler}
                        >
                            {t('save')}
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </>
    );
};

export default MedicCalendarAddTimeSlots;
