import React, {useState} from 'react';
import useStyles from './ChangePasswordPageStyles';
import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {CustomPasswordField, FormGroup, Label, Loading} from '../../components';
import PasswordStrengthBar from 'react-password-strength-bar';
import CustomButton from '../../components/common/Button/Button';
import {PASSWORD_CHANGE_FINISH_PAGE} from '../../const/routes';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {useFormik} from 'formik';
import {changePasswordSchema} from '../../validators/forgotPassword';
import accountProvider from '../../services/accountProvider';
import axios, {AxiosError} from 'axios';
import {changePasswordErrorHandler} from '../../utils/error';
import {useHistory} from 'react-router';
import {ForgetPasswordImage} from '../../assets';

type PasswordChangeFormValues = {
    oldPassword: string;
    newPassword: string;
    repeatNewPassword: string;
};

const ChangePasswordPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const handleError = (error: AxiosError | unknown) => {
        console.error(error);
        axios.isAxiosError(error) &&
            setError(changePasswordErrorHandler(error.response.data[0]));
    };

    const onSubmit = async (values: PasswordChangeFormValues) => {
        try {
            setLoading(true);
            await accountProvider.changePassword(
                values.oldPassword,
                values.newPassword,
            );
            history.push(PASSWORD_CHANGE_FINISH_PAGE);
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };
    const {values, handleChange, handleBlur, touched, errors, handleSubmit} =
        useFormik({
            initialValues: {
                oldPassword: '',
                newPassword: '',
                repeatNewPassword: '',
            },
            validationSchema: changePasswordSchema,
            validateOnBlur: true,
            onSubmit,
        });

    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <Loading loading={loading} withBackdrop />
            <Box className={classes.title}>{t('passwordChange')}</Box>
            <Grid container direction={isDesktop ? 'row' : 'column'}>
                <Grid item>
                    <FormGroup className={classes.formField}>
                        <Label htmlFor="oldPassword">{t('oldPassword')}</Label>
                        <CustomPasswordField
                            id="oldPassword"
                            name="oldPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.oldPassword}
                            helperText={
                                touched.oldPassword && t(errors.oldPassword)
                            }
                            error={touched.oldPassword && !!errors.oldPassword}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.passwordLabelContainer}
                        >
                            <Box>
                                <Label htmlFor="newPassword">
                                    {t('newPasswordWithMinChars')}
                                </Label>
                            </Box>
                            <Box
                                className={classes.passwordStrengthBarContainer}
                            >
                                <PasswordStrengthBar
                                    password={values.newPassword}
                                    scoreWords={[]}
                                />
                            </Box>
                        </Grid>
                        <CustomPasswordField
                            id="newPassword"
                            name="newPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            helperText={
                                touched.newPassword && t(errors.newPassword)
                            }
                            error={touched.newPassword && !!errors.newPassword}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                        <Label htmlFor="repeatNewPassword">
                            {t('repeatNewPassword')}
                        </Label>
                        <CustomPasswordField
                            id="repeatNewPassword"
                            name="repeatNewPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.repeatNewPassword}
                            helperText={
                                touched.repeatNewPassword &&
                                t(errors.repeatNewPassword)
                            }
                            error={
                                touched.repeatNewPassword &&
                                !!errors.repeatNewPassword
                            }
                        />
                    </FormGroup>
                    <Box className={classes.error}>{t(error)}</Box>
                    <CustomButton
                        variant={'contained'}
                        color={'secondary'}
                        className={classes.submitButton}
                        type={'submit'}
                        fullWidth={!isDesktop}
                    >
                        {commonT('next')}
                    </CustomButton>
                </Grid>
                <Grid item container xs justifyContent={'center'}>
                    <img
                        className={classes.image}
                        src={ForgetPasswordImage}
                        alt={'Forgot password'}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default ChangePasswordPage;
