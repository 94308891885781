
export const isPdf = (contentType: string) =>
    contentType === "application/pdf"

export const isMsword = (contentType: string) =>
    contentType === "application/msword"
    || contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

export const isText = (contentType: string) =>
    contentType.startsWith("text/")

export const isImage = (contentType: string) =>
    contentType.startsWith("image/")

export const isDefault =  (contentType: string) =>
    !isImage(contentType) && !isText(contentType) && !isPdf(contentType) && !isMsword(contentType)
