import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';
import {MAGENTA} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appointmentWrapper: {
            marginTop: mobile.contentLargerSpacing,
            backgroundColor: 'white',
        },
        boxTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            padding: mobile.contentLargerSpacing,
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        rightSideLink: {
            fontSize: 16,
            fontWeight: 'normal',
            color: MAGENTA,
            cursor: 'pointer',
        },
        [theme.breakpoints.up('sm')]: {
            appointmentWrapper: {
                marginTop: desktop.contentSmallerSpacing,
                padding: '0 16px',
            },
            boxTitle: {
                fontSize: '20px',
                padding: `${desktop.contentSmallerSpacing} 0`,
                borderBottom: `1px solid ${theme.palette.grey['300']}`,
            },
        },
        [theme.breakpoints.up('lg')]: {
            padding: `0 ${desktop.contentSmallerSpacing}`,
        },
    }),
);

export default useStyles;
