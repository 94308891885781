import {ChangeEvent, useRef, useState} from "react";
import {Box} from "@material-ui/core";
import {EditOutlined} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import MedicPictureCropModal from "../MedicPictureCropModal/MedicPictureCropModal";
import useStyles from './EditPictureActionButtonStyles';
import {useAppDispatch} from "../../../../hooks/customReduxHooks";
import {setNewMedicPicture} from "../../../../store/medicProfileSlice";
import {IconButtonWithText} from "../../../index";

const EditPictureActionButton = () => {
    const classes = useStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();

    const inputFileRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File>(null);

    const pictureEditClickHandler = () => {
        inputFileRef.current.click();
    }

    const fileSelectedHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
            setModalOpen(true);
        }
    }

    const closeModal = () => {
        setModalOpen(false);
        setSelectedFile(null);
    }

    const updateMedicPicture = async (imageFile: File) => {
        closeModal();
        await dispatch(setNewMedicPicture(imageFile));
    }

    return (
        <Box>
            <IconButtonWithText
                className={classes.iconButtonWithText}
                icon={
                    <EditOutlined
                        className={classes.editIcon}
                    />
                }
                label={commonT('change').toUpperCase()}
                onClick={pictureEditClickHandler}
            />
            {
                <input
                    type={'file'}
                    hidden
                    ref={inputFileRef}
                    onChange={fileSelectedHandler}
                    onClick={(event)=> {
                        // fix for not calling onChange, when the same file was selected two times in a row
                        (event.target as HTMLInputElement).value = null
                    }}
                />
            }
            {
                (modalOpen && selectedFile) && (
                    <MedicPictureCropModal
                        imageFile={selectedFile}
                        imageFileChanged={updateMedicPicture}
                        close={closeModal}
                    />
                )
            }
        </Box>
    )
}

export default EditPictureActionButton;