import {makeStyles} from '@material-ui/styles';
import { desktop } from '../../../const/sizes';
import { Theme } from '@material-ui/core';
import { RED, WHITE } from '../../../const/colors';

const useStyles = makeStyles((theme:Theme) => ({
    container:{
        marginTop: desktop.contentLargerSpacing,
        paddingBottom: desktop.contentSmallerSpacing,
        display:'flex',
        flexWrap:'wrap',
        [theme.breakpoints.down('sm')]: {
            marginTop: desktop.contentSmallerSpacing,
        },
    },
    button:{
        width:'100%',
        marginTop:'29px',  
        fontWeight: 'bold',
        height:'48px',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
            marginTop:'16px'
        },
    },
    input:{
        width:'100%',
    },
    buttonContainer:{
        display:'flex',
        alignItems:'center',
    },

    sectionHeader: {
        margin: '0',
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            lineHeight: '32px',
        },
    },
    section: {
        padding: '16px',
        backgroundColor: WHITE,
    },
    mojeIDLogo: {
        width: '100px',
        height: '32px',
        marginLeft:'16px',
    },
    gridItem:{
        display:'flex',
        alignItems:'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems:'center',
        },
    },
    titleContainer:{
        display:'flex',
        alignItems:'center',
    },
    alertIcon:{
        color:RED,
        marginLeft:'16px'
    },
    error:{
        color:'#f44336'
    }
}));

export default useStyles;
