import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {LoadingStatus} from '../const/loadingStatus';
import articlesProvider from '../services/articlesProvider';
import {KnowledgeBaseDto} from '../types/articles';
import mapArticles from './mappers/mapArticles';
import {RootState} from './store';

const sliceName = 'articles';

const articlesAdapter = createEntityAdapter<KnowledgeBaseDto>({
    selectId: article => article.id,
});

const initialState = articlesAdapter.getInitialState({
    status: 'idle' as LoadingStatus,
    page: 0,
    size: 12,
    count: 0,
});

export type AtriclesSliceState = typeof initialState;

export const fetchArticles = createAsyncThunk<
    {
        articles: KnowledgeBaseDto[];
        page: number;
        count: number;
    },
    void,
    {
        state: RootState;
    }
>(`${sliceName}/fetchArticles`, async (_, thunkApi) => {
    const {page, size} = selectArticles(thunkApi.getState());
    const {
        page: resultPage,
        results,
        count,
    } = await articlesProvider.getList(page, size);

    return {
        articles: results.map(mapArticles),
        page: resultPage,
        count,
    };
});

const articlesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        pageSpecified(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        sizeSpecified(state, action: PayloadAction<number>) {
            state.size = action.payload;
        },
        clearArticles(state) {
            state.page = initialState.page;
            state.size = initialState.size;
            state.count = initialState.count;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchArticles.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchArticles.fulfilled, (state, action) => {
                state.status = 'loaded';

                state.page = action.payload.page;
                state.count = action.payload.count;
                articlesAdapter.setAll(state, action.payload.articles);
            })
            .addCase(fetchArticles.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const {pageSpecified} = articlesSlice.actions;
export const {sizeSpecified} = articlesSlice.actions;
export const {clearArticles} = articlesSlice.actions;

export const selectArticles = (state: RootState) => state.articles;

export const selectArticlesStatus = (state: RootState) =>
    selectArticles(state).status;
export const selectArticlesPage = (state: RootState) =>
    selectArticles(state).page;
export const selectArticlesSize = (state: RootState) =>
    selectArticles(state).size;
export const selectArticlesCount = (state: RootState) =>
    selectArticles(state).count;

export const {selectIds: selectArticlesIds, selectById: selectArticleById} =
    articlesAdapter.getSelectors<RootState>(state => selectArticles(state));

export default articlesSlice.reducer;
