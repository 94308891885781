import {rawHttp} from '../http';
import {DIAGNOSTIC_REGIONS} from '../endpoints';

export interface DiagnosticRegionDto {
    regionId: 1;
    name: string;
}

export function getDiagnosticRegions() {
    return rawHttp.get<DiagnosticRegionDto[]>(DIAGNOSTIC_REGIONS);
}