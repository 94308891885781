import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Grid, List, ListItem, Typography} from '@material-ui/core';
import {
    Appointment,
    AppointmentStateEnum,
    Attachment,
} from '../../../types/appointments';
import {Link} from 'react-router-dom';
import useStyles from './OccupationalMedicineTabStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {Loading, UploadedItem} from '../../index';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectOMAttachmentsFromMedicMetaData,
    selectOMAttachmentsFromPatientMetaData,
} from '../../../store/appointmentAttachmentsSlice';
import {getFileIcon} from '../../../utils/file';
import {useDownloadAsync} from '../../../hooks/appointmentFileUpload';
import {PageSectionCard} from '../../common/layout';
import {ICD10Search} from '../../common/search';
import appointmentsProvider from '../../../services/appointmentsProvider';
import {checkBothSidesConnectedToOngoingAppointment} from '../../../utils/appointment';
import {Color} from '@material-ui/lab/Alert/Alert';
import {CustomAlert} from '../../common/feedback';
import {
    fetchAppointmentDetails as fetchAppointmentDetailsForMedic,
    fetchOccupationalMedicineData,
    selectOccupationalMedicineData,
} from '../../../store/appointmentDetailsSlice';
import {fetchAppointmentDetails as fetchAppointmentDetailsForConsultation} from '../../../store/consultationSlice';
import {
    PATIENT_REFERRAL_FOR_MEDICAL_TESTS_PAGE,
    PATIENT_REFERRAL_TO_MEDIC_PAGE,
} from '../../../const/routes';
import {MAGENTA} from '../../../const/colors';
import {v4} from 'uuid';

type OccupationalMedicineTabProps = {
    appointment: Appointment;
    source: 'medicDetails' | 'chat';
};

const OccupationalMedicineTab = ({
    appointment,
    source,
}: OccupationalMedicineTabProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const {t: tIcd} = useTranslation(i18nNamespaces.ICD);
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);
    const attachmentsFromPatient = useAppSelector(
        selectOMAttachmentsFromPatientMetaData,
    );
    const attachmentsFromMedic = useAppSelector(
        selectOMAttachmentsFromMedicMetaData,
    );
    const downloadFileAsync = useDownloadAsync();
    const occupationalMedicineData = useAppSelector(
        selectOccupationalMedicineData,
    );

    const [icd10CodeSaving, setIcd10CodeSaving] = useState(false);
    const [alertInfo, setAlertInfo] =
        useState<{severity: Color; message: string}>(null);

    const isAppointmentSettled = appointment?.state.isSettled;
    const preventiveExaminationCardCanBeFilled = useMemo(
        () => occupationalMedicineData && !appointment?.state.isSettled,
        [appointment],
    );

    const icd10SearchEnabled =
        !!appointment &&
        !isAppointmentSettled &&
        (checkBothSidesConnectedToOngoingAppointment(appointment) ||
            appointment.state.state === AppointmentStateEnum.Completed);

    const mapAttachment = (attachment: Attachment) => ({
        fileItemKey: attachment.id,
        fileItemClickHandler: () =>
            downloadFileAsync(appointment.id, `${attachment.id}`),
        fileName: attachment.fileName,
        fileIcon: getFileIcon(attachment.contentType, attachment.thumbnail),
        fileOwner: attachment.person,
        fileDateCreated: attachment.uploadDate.toFormat("dd'.'MM'.'yyyy"),
        fileTimeCreated: attachment.uploadDate.toFormat("HH':'mm"),
        downloadDateTimeByPatient: attachment.downloadDateTimeByPatient,
    });

    const fetchAppointment = useCallback(async () => {
        if (source === 'medicDetails') {
            await dispatch(
                fetchAppointmentDetailsForMedic({
                    appointmentId: appointment.id,
                    fetchSilently: true,
                }),
            );
        } else if (source === 'chat') {
            await dispatch(
                fetchAppointmentDetailsForConsultation({
                    appointmentId: appointment.id,
                    fetchSilently: true,
                }),
            );
        } else {
            throw new Error('Not supported source');
        }
    }, [source, appointment.id]);

    const icd10CodeSelectedHandler = useCallback(
        async (icd10Code: string) => {
            setIcd10CodeSaving(true);
            try {
                await appointmentsProvider.updateAppointmentIcd10Code(
                    appointment.id,
                    icd10Code,
                );
                setAlertInfo({
                    severity: 'success',
                    message: tIcd('saveIcd10Success'),
                });
            } catch (e) {
                setAlertInfo({
                    severity: 'error',
                    message: tIcd('saveIcd10Error'),
                });
                console.error(e);
            } finally {
                await fetchAppointment();
                setIcd10CodeSaving(false);
            }
        },
        [appointment.id, fetchAppointment],
    );

    const getOccupationalMedicineData = () =>
        dispatch(
            fetchOccupationalMedicineData({appointmentId: appointment?.id}),
        );

    useEffect(() => {
        getOccupationalMedicineData();
        // Fetch prescriptions on tab focus
        window.addEventListener('focus', getOccupationalMedicineData);

        return () => {
            window.removeEventListener('focus', getOccupationalMedicineData);
        };
    }, []);

    useEffect(() => {
        dispatch(
            fetchOccupationalMedicineData({appointmentId: appointment?.id}),
        );
    }, [dispatch, appointment]);

    return (
        <>
            <PageSectionCard style={{marginBottom: 8}}>
                <PageSectionCard.Header>
                    <Typography
                        variant={'h5'}
                        style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            lineHeight: '32px',
                        }}
                    >
                        {tIcd('addICD10code')}
                    </Typography>
                </PageSectionCard.Header>
                <PageSectionCard.Content>
                    <ICD10Search
                        defaultIcd10Code={appointment?.icd10Code}
                        onIcd10CodeSelected={icd10CodeSelectedHandler}
                        disabled={!icd10SearchEnabled && !!appointment}
                    />
                </PageSectionCard.Content>
            </PageSectionCard>
            <Box className={classes.container} style={{marginBottom: 8}}>
                {preventiveExaminationCardCanBeFilled && (
                    <>
                        <h4 style={{fontSize: 18}}>
                            {t('preventiveExaminationCard')}:
                        </h4>
                        <Box className={classes.fillLink}>
                            <Link
                                to={`/occupational-medicine/${appointment?.id}`}
                                target="_blank"
                            >
                                {occupationalMedicineData?.filledByMedic
                                    ? t('edit')
                                    : t('fill')}
                            </Link>
                        </Box>
                    </>
                )}
                <h4 style={{fontSize: 18}}>{t('fromPatient')}:</h4>
                <List>
                    {attachmentsFromPatient.length > 0 ? (
                        attachmentsFromPatient
                            .map(mapAttachment)
                            .map((fileProps, i) => (
                                <ListItem
                                    // className={classes.fileListItem}
                                    key={fileProps.fileItemKey ?? i}
                                    onClick={fileProps.fileItemClickHandler}
                                >
                                    <UploadedItem
                                        fileName={fileProps.fileName}
                                        fileIcon={fileProps.fileIcon}
                                        fileOwner={fileProps.fileOwner}
                                        fileDateCreated={
                                            fileProps.fileDateCreated
                                        }
                                        fileTimeCreated={
                                            fileProps.fileTimeCreated
                                        }
                                        removeFileEnabled={false}
                                    />
                                </ListItem>
                            ))
                    ) : (
                        <Box>{t('noData')}</Box>
                    )}
                </List>
                <h4 style={{fontSize: 18}}>{t('fromMedic')}:</h4>
                <List>
                    {attachmentsFromMedic.length > 0 ? (
                        attachmentsFromMedic
                            .map(mapAttachment)
                            .map((fileProps, i) => (
                                <ListItem
                                    key={fileProps.fileItemKey ?? i}
                                    onClick={fileProps.fileItemClickHandler}
                                >
                                    <UploadedItem
                                        fileName={fileProps.fileName}
                                        fileIcon={fileProps.fileIcon}
                                        fileOwner={fileProps.fileOwner}
                                        fileDateCreated={
                                            fileProps.fileDateCreated
                                        }
                                        fileTimeCreated={
                                            fileProps.fileTimeCreated
                                        }
                                        removeFileEnabled={false}
                                        patientDownloadDate={
                                            fileProps.downloadDateTimeByPatient
                                        }
                                    />
                                </ListItem>
                            ))
                    ) : (
                        <Box>{t('noData')}</Box>
                    )}
                </List>
            </Box>
            <PageSectionCard
                style={{
                    marginBottom: 8,
                }}
            >
                <PageSectionCard.Content
                    style={{display: 'flex', flexDirection: 'column'}}
                >
                    <Grid container spacing={1}>
                        <Grid item alignItems={'center'} xs={12}>
                            <Link
                                to={`${PATIENT_REFERRAL_TO_MEDIC_PAGE}/?appointmentId=${appointment?.id}`}
                                target={'_blank'}
                                style={{color: MAGENTA}}
                            >
                                {tReferrals('makeReferralToSpecialist')}
                            </Link>
                        </Grid>
                        <Grid item alignItems={'center'} xs={12}>
                            <Link
                                to={`${PATIENT_REFERRAL_FOR_MEDICAL_TESTS_PAGE}/?appointmentId=${appointment?.id}`}
                                target={'_blank'}
                                style={{color: MAGENTA}}
                            >
                                {tReferrals('makeReferralForMedicalTests')}
                            </Link>
                        </Grid>
                    </Grid>
                </PageSectionCard.Content>
            </PageSectionCard>
            <Loading loading={icd10CodeSaving} withBackdrop={true} />
            {alertInfo && <CustomAlert {...alertInfo} />}
        </>
    );
};

export default OccupationalMedicineTab;
