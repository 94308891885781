import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    detailsHeader: {
        margin: '0',
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            lineHeight: '32px',
        }
    },
    filesList: {
        padding: 0,
    },
    filesListItem: {
        padding: 0,
        width: '375px',
    },
    filesListItemNext: {
        marginTop: '10px',
    }
}));

export default useStyles;