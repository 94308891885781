import {
    Box,
    Grid,
    Link,
    List,
    ListItem,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import appointmentReferralsProvider from '../../../services/diagnostic/appointmentReferralsProvider';
import {AppointmentReferralDto} from '../../../services/diagnostic/orderHeadersProvider';
import {
    fetchAppointmentReferrals,
    selectAppointmentReferrals,
} from '../../../store/referrals/appointmentReferralSlice';
import {getLongTimeFromISO, getShortDateFromISO} from '../../../utils/date';
import {isNotNullOrUndefined} from '../../../utils/lang';
import useStyles from './ReferralsListTabStyles';
import { PdfImage } from '../../../assets';
import filesDownloadProvider from '../../../services/filesDownloadProvider';
import Loading from '../../common/Loading/Loading';

interface Props {
    appointmentIdAsString: string;
}

const ReferralsListTab = ({appointmentIdAsString}: Props) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);

    const referrals = useAppSelector(selectAppointmentReferrals);

    useEffect(() => {
        dispatch(fetchAppointmentReferrals({appointmentIdAsString}));
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(fetchAppointmentReferrals({appointmentIdAsString}));
        }, 10000);

        return () => clearInterval(timer);
    }, [dispatch]);

    const openResult = async (url:string) => {
        const win = window.open(
            url,
            '_blank',
        );
        win.focus();
    };

    return (
        <Box>
            {isMobile ? (
                <h4 className={classes.header}>{t('orderedReferrals')}</h4>
            ) : (
                <Grid
                    container
                    wrap={'nowrap'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                >
                    <h4 className={classes.header}>{t('orderedReferrals')}</h4>
                </Grid>
            )}
            <Box>
                {isMobile ? (
                    <List>
                        {referrals &&
                            referrals.map((item, i) => (
                                <ListItem
                                    key={item.id}
                                    className={classes.listItem}
                                >
                                    <Box>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                className={classes.dateLabel}
                                            >
                                                {getShortDateFromISO(
                                                    item.createDate,
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.dateLabel}
                                            >
                                                {getLongTimeFromISO(
                                                    item.createDate,
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column">
                                            <Grid>
                                                {isNotNullOrUndefined(
                                                    item.medicalPackage,
                                                )
                                                    ? item.medicalPackage
                                                    : t(item.type)}
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.dateLabel}
                                            >
                                                {item.diagnosticServices
                                                    .map(d => d.name)
                                                    .join(', ')}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </ListItem>
                            ))}
                    </List>
                ) : (
                    <List>
                        {referrals &&
                            referrals.map((item, i) => (
                                <ListItem
                                    key={item.id}
                                    className={classes.listItem}
                                >
                                    <Grid container direction={'row'} alignItems='center'>
                                        <Grid
                                            item
                                            xs={1}
                                            className={classes.dateLabel}
                                        >
                                            {getShortDateFromISO(
                                                item.createDate,
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={classes.dateLabel}
                                        >
                                            {getLongTimeFromISO(
                                                item.createDate,
                                            )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {isNotNullOrUndefined(
                                                item.medicalPackage,
                                            )
                                                ? item.medicalPackage
                                                : t(item.type)}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={7}
                                            className={classes.dateLabel}
                                        >
                                            <Grid container direction='row' alignItems='center'>
                                                {item.diagnosticServices
                                                    .map(d => d.name)
                                                    .join(', ')}
                                            
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Grid container direction='column'>
                                            {item.resultStorageUrls.length>0 ? item.resultStorageUrls.map((res, index) =>(
                                                    <Link 
                                                    key={res.storageId}                               
                                                        className={classes.link}
                                                        onClick={() => openResult(res.url)}
                                                        >
                                                        {item.resultStorageUrls.length==1 ? t('seeTheResults') : `${t('seeTheResults')} ${t('part')} ${i+1}` }
                                                        
                                                    </Link>                                             
                                                    )):
                                                    <Box className={classes.status} display={'flex'} justifyContent="flex-end">
                                                        {t(item.diagnosticOrderResultStatus)}
                                                    </Box>
                                                    }        
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                    </List>
                )}
            </Box>
        </Box>
    );
};
export default ReferralsListTab;
