import {DiagnosticServiceDto} from '../../types/referrals';

const mapDiagnosticService = (diagnosticServiceDto: DiagnosticServiceDto) =>
    ({
        id: diagnosticServiceDto.id,
        code: diagnosticServiceDto.code,
        name: diagnosticServiceDto.name,
        type: diagnosticServiceDto.type,
    } as DiagnosticServiceDto);

export default mapDiagnosticService;
