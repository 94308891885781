import {useCallback, useMemo, useState} from 'react';
import {isObject} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import medicsProvider from '../../services/medicsProvider';
import {MedicDto} from '../../types/timeSlot';

const useMedic = () => {
    const [medic, setMedic] = useState<MedicDto>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const fetchMedic = useCallback(async (medicId: string) => {
        setLoading(true);
        setError(null);
        try {
            const {data: medicDto} = await medicsProvider.getMedicById(medicId);
            if (!isObject(medicDto)) {
                throw new Error('Medic not found');
            }
            setMedic(medicDto);
        } catch (e) {
            console.error(
                e instanceof Error
                    ? e.message
                    : 'An error occurred while fetching medic data'
            );
            setError(tErrors('sendingDataToServerError'));
            setMedic(null);
        } finally {
            setLoading(false);
        }
    }, []);

    const state = useMemo(
        () => ({
            medic,
            medicLoading: loading,
            medicLoadingError: error,
            fetchMedic,
        }),
        [
            medic,
            loading,
            error,
            fetchMedic,
        ]
    );

    return state;
}

export default useMedic;