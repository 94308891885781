import { LanguageDto } from "../types/language";
import { LANGUAGES } from "./endpoints";
import { http } from "./http";


class LanguageProvider {

    async getLanguages() {
        return (
            await http.get<LanguageDto[]>(
                LANGUAGES,
            )
        ).data;
    }

}


export default new LanguageProvider();
