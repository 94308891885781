import {
    AuthorizePatientDataAccessDto,
    PatientDataAccessAppointmentDataDto,
} from '../../types/patientDataAccess';
import {P1Service} from '../../services/P1Service';
import {
    APPLY_PATIENT_DATA_ACCESS,
    AUTHORIZE_PATIENT_DATA_ACCESS,
} from './patientDataAccessEndpoints';

class PatientDataAccessApi {
    applyForPatientDataAccess(
        appointmentData: PatientDataAccessAppointmentDataDto,
    ) {
        return P1Service.post(APPLY_PATIENT_DATA_ACCESS, appointmentData);
    }

    authorizePatientDataAccess(patientData: AuthorizePatientDataAccessDto) {
        return P1Service.post(AUTHORIZE_PATIENT_DATA_ACCESS, patientData);
    }
}

export default new PatientDataAccessApi();
