import React, {ChangeEvent, useState} from 'react';
import {Box, Button, Link} from '@material-ui/core';
import useStyles from './DigitalCertificateStyles';
import useTileStyles from '../../common/tileCommonStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {CustomDropzone} from '../../../common/inputs';
import useOnDropRejectMessage from '../../../../hooks/useOnDropRejectMessage';
import {CustomTextField, Label, Loading, UploadedItem} from '../../../index';
import certificateApi from '../../../../api/certificate/certificateApi';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../../../store/auth';
import {Alert} from '@material-ui/lab';
import useCertificateThumbprint from '../../../../hooks/certificate/useCertificateThumbprint';

const DigitalCertificate = () => {
    const classes = useStyles();
    const tileClasses = useTileStyles();
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const handleOnDropRejectMessage = useOnDropRejectMessage();
    const userData = useAppSelector(selectAuthUserData);
    const [file, setFile] = useState<File | null>(null);
    const [password, setPassword] = useState<string>('');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>(
        'success',
    );
    const [loading, setLoading] = useState<boolean>(false);

    const {
        loading: thumbprintLoading,
        thumbprint,
        fetchThumbprint,
    } = useCertificateThumbprint(userData?.userId);

    const handleDeleteCertificate = async () => {
        try {
            setLoading(true);
            setAlertMessage('');
            const {data} = await certificateApi.deleteCertificate(
                userData.userId,
            );
            if (!data?.isSuccess) throw new Error(data?.message);
            await fetchThumbprint();
            setAlertSeverity('success');
            setAlertMessage(t('certificateHasBeenDeletedFromServer'));
        } catch (error) {
            setAlertSeverity('error');
            setAlertMessage(t('thereWasAnErrorWhileDeletingCertificate'));
            console.error('Certificate delete error: ', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLoadCertificate = async () => {
        try {
            setLoading(true);
            setAlertMessage('');
            const formData = new FormData();
            formData.append('File', file, file.name);
            formData.append('Password', password);
            const {data} = await certificateApi.loadCertificate(
                userData.userId,
                formData,
            );
            if (!data?.isSuccess) {
                throw new Error(data.message);
            }
            setAlertSeverity('success');
            setAlertMessage(t('certificateLoaded'));
            await fetchThumbprint();
            setFile(null);
        } catch (error) {
            console.error('Certificate load error: ', error);
            setAlertSeverity('error');
            setAlertMessage(t('thereWasAnErrorWhileLoadingCertificate'));
        } finally {
            setLoading(false);
        }
    };

    const handleUploadCertificate = (files: File[]) => {
        setAlertMessage('');
        setFile(files[0]);
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setAlertMessage('');
        setPassword(event?.target?.value);
    };

    return (
        <div className={tileClasses.root}>
            <Loading loading={loading || thumbprintLoading} withBackdrop />
            <h3 className={tileClasses.headerText}>{t('certificate')}</h3>
            {alertMessage !== '' && (
                <Alert
                    severity={alertSeverity}
                    style={{marginTop: 20}}
                    onClose={() => setAlertMessage('')}
                >
                    {alertMessage}
                </Alert>
            )}
            {thumbprint ? (
                <Box style={{marginTop: 20}}>
                    {t('loaded')}: {thumbprint} (
                    <Link
                        color={'secondary'}
                        onClick={handleDeleteCertificate}
                        style={{cursor: 'pointer'}}
                    >
                        {t('deleteFromServer')}
                    </Link>
                    )
                </Box>
            ) : (
                <Box style={{marginTop: 20}}>
                    {!file && (
                        <CustomDropzone
                            text={t('fileWithCertificate')}
                            onAdd={handleUploadCertificate}
                            getDropRejectMessage={handleOnDropRejectMessage}
                            className={classes.dropzone}
                            acceptedFiles={['.p12', '.pfx']}
                        />
                    )}
                    {file && (
                        <Box className={classes.alignBottom}>
                            <UploadedItem
                                fileName={file?.name}
                                removeFileEnabled
                                removeFileHandler={() => setFile(null)}
                            />
                            <Box style={{marginLeft: 30}}>
                                <Label htmlFor={'certificatePassword'}>
                                    {t('certificatePassword')}
                                </Label>
                                <CustomTextField
                                    id="certificatePassword"
                                    name="certificatePassword"
                                    type="password"
                                    autoComplete={'new-password'}
                                    onChange={handleChangePassword}
                                />
                            </Box>
                            <Button
                                variant={'contained'}
                                style={{marginLeft: 30, fontWeight: 'bold'}}
                                color={'secondary'}
                                onClick={handleLoadCertificate}
                                disabled={password === ''}
                            >
                                {t('load')}
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
};

export default DigitalCertificate;
