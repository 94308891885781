import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { ExtendedScopeOfTestingItemDto } from "../../../types/occupationalMedicine";
import { useFormik } from "formik";
import * as yup from 'yup';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid } from "@material-ui/core";
import { RenderCancelNextButton, RenderGridWithCommonViewRequired } from "../CommonView";

const REQUIRED_STRING = 'requiredField';

export type Props = {
    data: ExtendedScopeOfTestingItemDto,
    onSubmitSection: (newItem: ExtendedScopeOfTestingItemDto, stayOnCurrent:boolean) =>void
    onCancel : () => void;
}
const ExtendedScopeOfTestingCreateItem = ({
    data, onSubmitSection, onCancel
}:Props) =>{
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    
    const onSubmit = () => {
        onSubmitSection(values, true);
    }

    const {values, touched, handleChange, handleBlur, errors, handleSubmit, setFieldValue} =
    useFormik({
        initialValues: {
            type: data?.type ?? '',
            explanation: data?.explanation ?? ''
        },

        validationSchema: yup.object({
            type:yup.string().required(commonT(REQUIRED_STRING)),
            explanation:yup.string().required(commonT(REQUIRED_STRING)),

        }),
        onSubmit
    });
    
    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <Grid container spacing={1} className={classes.gridContainer}>
                    {RenderGridWithCommonViewRequired(12, "type", values.type, handleChange, handleBlur,
                         classes, t('testType'), touched.type, errors.type, t(errors.type))}
                    {RenderGridWithCommonViewRequired(12, "explanation", values.explanation, handleChange, handleBlur, 
                        classes, t('explanation'), touched.explanation, errors.explanation, t(errors.explanation))}
                </Grid>
                {RenderCancelNextButton(classes, t, onCancel)}
            </Box>
        </form>
    )
}
export default ExtendedScopeOfTestingCreateItem;