import {useFormik} from 'formik';
import { isNotNullOrUndefined } from '../utils/lang';
import questionnaireSchema from '../validators/questionnaire';

const useQuestionnaireForm = (bodyHeight:number, bodyWeight:number, onSubmit?: () => void) =>
    useFormik({
        initialValues: {
            bodyHeight: isNotNullOrUndefined(bodyHeight) ? bodyHeight : 0,
            bodyWeight: isNotNullOrUndefined(bodyWeight) ? bodyWeight : 0,
        },
        validationSchema: questionnaireSchema,
        validateOnBlur: true,
        onSubmit,
        enableReinitialize:true,
    });

export default useQuestionnaireForm;
