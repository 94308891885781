import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fileInputLabel: {
            border: '1px solid #ccc',
            display: 'inline-block',
            padding: '6px 12px',
            cursor: 'pointer',
            width: 300,
            height: 300,
        },
        fileInput: {
            display: 'none',
        },
        dropzone: {
            maxWidth: 500,
        },
        alignBottom: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
        },
    }),
);

export default useStyles;
