import * as yup from 'yup';
import {isValidPhoneNumber} from 'react-phone-number-input';

const REQUIRED_STRING = 'requiredField';

export const changePhoneNumberSchema = yup.object().shape({
    phoneNumber: yup
        .string()
        .min(12, 'tooShortPhoneNumber')
        .test('isValidNumber', 'invalidNumber', value =>
            isValidPhoneNumber(value),
        )
        .required(REQUIRED_STRING),
});
