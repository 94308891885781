import React from 'react';
import {
    Box,
    Button,
    createStyles,
    Grid,
    makeStyles,
    Theme,
} from '@material-ui/core';
import {FinishScheduleImage} from '../../../assets';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {TEXT_PRIMARY} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 16,
            paddingTop: 40,
            [theme.breakpoints.up('sm')]: {
                padding: '80px 49px 64px',
            },
            [theme.breakpoints.up('md')]: {
                padding: '100px 49px 64px',
            },
            [theme.breakpoints.up('lg')]: {
                padding: '130px 49px 64px',
            },
        },
        gridContainer: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        headerText: {
            fontSize: 36,
            lineHeight: '48px',
            color: TEXT_PRIMARY,
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                lineHeight: '40px',
            },
        },
        text: {
            marginTop: 24,
            fontSize: 16,
            lineHeight: '24px',
            color: TEXT_PRIMARY,
            [theme.breakpoints.up('md')]: {
                marginTop: 32,
            },
        },
        image: {
            marginTop: 16,
            width: '100%',
            maxWidth: 320,
            [theme.breakpoints.up('md')]: {
                marginTop: 40,
            },
        },
        button: {
            marginTop: 24,
        },
    }),
);

const ReferralMadeInfo = () => {
    const classes = useStyles();
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);
    const {t} = useTranslation(i18nNamespaces.COMMON);

    const onReturnButtonClick = () => {
        window.close();
    };

    return (
        <Box className={classes.container}>
            <Grid className={classes.gridContainer} container>
                <Box className={classes.headerText}>
                    {tReferrals('referralHasBeenMade')}
                </Box>
                <Box className={classes.text}>
                    {tReferrals('referralWillBeDisplayedOnMainPageOfTheUser')}
                </Box>
                <img
                    src={FinishScheduleImage}
                    alt="Referral made image"
                    className={classes.image}
                />

                <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={onReturnButtonClick}
                    className={classes.button}
                >
                    {t('goBack')}
                </Button>
            </Grid>
        </Box>
    );
};

export default ReferralMadeInfo;
