import {ChangeEvent} from 'react';
import {Box, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import useStyles from './AboutPatientTabStyles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paging,
} from '../../index';
import i18nNamespaces from '../../../const/i18nNamespaces';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Appointment, AppointmentGroupEnum} from '../../../types/appointments';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {selectQuestionnaireDetails} from '../../../store/questionnaireDetailsSlice';
import QuestionnaireDetails from './QuestionnaireDetails/QuestionnaireDetails';
import {AppointmentsHistoryAccordionSummary} from './AppointmentsHistoryAccordion/AppointmentsHistoryAccordion';
import HistoricalAppointment from './HistoricalAppointment/HistoricalAppointment';
import {
    fetchPageOfUserHistoricalAppointments,
    selectHistoricalAppointments,
    selectHistoricalAppointmentsCount,
    selectHistoricalAppointmentsFilterSelectedId,
    selectHistoricalAppointmentsFilterTypes,
    selectHistoricalAppointmentsPage,
    selectHistoricalAppointmentsPageSize,
    setHistoricalAppointmentsFilterSelectedIndex,
} from '../../../store/userAppointmentsHistorySlice';
import DiagnosticOrders from './DiagnosticOrders/DiagnosticOrders';
import {isNotNullOrUndefined} from '../../../utils/lang';

interface Props {
    appointment?: Appointment;
}

const ALL_MEDICAL_SERVICES_FILTER_TYPE_ID = -1;

const AboutPatientTab = ({appointment}: Props) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const questionnaireDetails = useAppSelector(selectQuestionnaireDetails);
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const historicalAppointments = useAppSelector(selectHistoricalAppointments);
    const surveyDataVisible =
        appointment.appointmentGroup !==
        AppointmentGroupEnum.OccupationalMedicineAppointment;
    const historicalAppointmentsPage = useAppSelector(
        selectHistoricalAppointmentsPage,
    );
    const historicalAppointmentsCount = useAppSelector(
        selectHistoricalAppointmentsCount,
    );
    const historicalAppointmentsPageSize = useAppSelector(
        selectHistoricalAppointmentsPageSize,
    );
    const historicalAppointmentsFilterTypes = useAppSelector(
        selectHistoricalAppointmentsFilterTypes,
    );
    const historicalAppointmentsFilterSelectedId = useAppSelector(
        selectHistoricalAppointmentsFilterSelectedId,
    );

    const getFilterOptions = (): number[] => {
        return [
            ALL_MEDICAL_SERVICES_FILTER_TYPE_ID,
            ...historicalAppointmentsFilterTypes.map(h => h.id),
        ];
    };

    const getFilterOptionsLabels = (option: number): string => {
        if (option === ALL_MEDICAL_SERVICES_FILTER_TYPE_ID) {
            // TODO: find a way to override values on app language change
            // Cannot use translation, as AutoComplete component breaks, when input values change,
            return 'Wszystkie usługi';
        }
        return historicalAppointmentsFilterTypes.find(f => f.id === option)
            .name;
    };

    const getSelectedFilterTypeId = (): number => {
        const isAllMedicalServicesSelected =
            historicalAppointmentsFilterSelectedId === null;
        return isAllMedicalServicesSelected
            ? ALL_MEDICAL_SERVICES_FILTER_TYPE_ID
            : historicalAppointmentsFilterSelectedId;
    };

    const filterTypeChangeHandler = (
        _: ChangeEvent<Record<string, unknown>>,
        selectedFilterId: string | number,
    ) => {
        const medicalServiceId =
            selectedFilterId === ALL_MEDICAL_SERVICES_FILTER_TYPE_ID
                ? null
                : selectedFilterId;
        dispatch(
            setHistoricalAppointmentsFilterSelectedIndex(
                medicalServiceId as number,
            ),
        );
        dispatch(
            fetchPageOfUserHistoricalAppointments({
                page: 0,
                userId: appointment.patient.userId,
                childId: appointment.child?.childId,
                appointmentId: appointment.id,
                medicalServiceId: medicalServiceId as number,
            }),
        );
    };

    const pageChangeHandler = (pageIndex: number) => {
        dispatch(
            fetchPageOfUserHistoricalAppointments({
                page: pageIndex,
                userId: appointment.patient.userId,
                childId: appointment.child?.childId,
                appointmentId: appointment.id,
                medicalServiceId: historicalAppointmentsFilterSelectedId,
            }),
        );
    };

    return (
        <Box>
            {surveyDataVisible && (
                <Box className={classes.section}>
                    <Accordion square defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h4 className={classes.sectionHeader}>
                                {t('whatAreTheReasons')}?
                            </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className={classes.sectionContent}>
                                {appointment?.patientReasons ||
                                    tCommon('noInformation')}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
            {surveyDataVisible && (
                <Box
                    className={clsx(classes.section, classes.sectionTopOffset)}
                >
                    <Accordion square defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h4 className={classes.sectionHeader}>
                                {t('patientHealthCondition')}
                            </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className={classes.sectionContent}>
                                <QuestionnaireDetails
                                    questionnaireDetails={questionnaireDetails}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
            <Box
                className={clsx({
                    [classes.sectionTopOffset]: surveyDataVisible,
                })}
            >
                <Accordion
                    square
                    defaultExpanded={true}
                    style={{padding: 0, margin: 0}}
                >
                    <AppointmentsHistoryAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Box
                            className={
                                classes.consultationsHistorySectionHeaderBox
                            }
                        >
                            <h4 className={classes.sectionHeader}>
                                {t('consultationsHistory')}
                            </h4>
                            <Box
                                className={classes.autocompleteWrapper}
                                onClick={event => event.stopPropagation()}
                            >
                                <Autocomplete
                                    disableClearable
                                    options={getFilterOptions()}
                                    getOptionLabel={getFilterOptionsLabels}
                                    value={getSelectedFilterTypeId()}
                                    onChange={filterTypeChangeHandler}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={tCommon(
                                                'selectTypeOfService',
                                            )}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    </AppointmentsHistoryAccordionSummary>
                    <AccordionDetails>
                        <Box className={classes.historicalAppointmentsContent}>
                            {historicalAppointments?.length ? (
                                <>
                                    {historicalAppointments.map(
                                        historicalAppointment => (
                                            <HistoricalAppointment
                                                key={
                                                    historicalAppointment
                                                        .appointment.id
                                                }
                                                appointment={
                                                    historicalAppointment.appointment
                                                }
                                                attachments={
                                                    historicalAppointment.attachmentsMetaData
                                                }
                                            />
                                        ),
                                    )}
                                    <Box
                                        className={
                                            classes.showMoreHistoricalAppointments
                                        }
                                    >
                                        <Paging
                                            page={historicalAppointmentsPage}
                                            size={
                                                historicalAppointmentsPageSize
                                            }
                                            count={historicalAppointmentsCount}
                                            onChange={pageChangeHandler}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    className={
                                        classes.historicalAppointmentsNotFound
                                    }
                                >
                                    {t('noHistoricalAppointmentsFound')}
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box className={clsx(classes.section, classes.sectionTopOffset)}>
                <Accordion square defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h4 className={classes.sectionHeader}>
                            {t('patientDiagnosticOrders')}
                        </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box className={classes.referralContainer}>
                            <DiagnosticOrders
                                patientId={appointment.patient.userId}
                                childId={
                                    isNotNullOrUndefined(appointment.child)
                                        ? appointment.child.childId
                                        : null
                                }
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};

export default AboutPatientTab;
