import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Card, Grid, IconButton, Typography} from '@material-ui/core';
import {Close, InsertDriveFile} from '@material-ui/icons';
import useStyles from './UploadedItemStyles';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {DateTime} from 'luxon';
import clsx from 'clsx';

export interface UploadedItemProps {
    fileName: string;
    fileIcon?: string;
    fileOwner?: string;
    fileDateCreated?: string;
    fileTimeCreated?: string;
    patientDownloadDate?: string;
    onClick?: () => void;
    removeFileEnabled: boolean;
    removeFileHandler?: () => void;
    disabled?: boolean;
}

const UploadedItem: FC<UploadedItemProps> = props => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);

    const {
        fileName,
        fileIcon,
        fileOwner,
        fileDateCreated,
        fileTimeCreated,
        onClick,
        removeFileEnabled,
        removeFileHandler,
        patientDownloadDate,
        disabled,
    } = props;

    const clickHandler = useCallback(() => {
        onClick();
    }, []);

    return (
        <Card
            style={{
                position: 'relative',
                paddingTop: 8,
                paddingLeft: 8,
                boxShadow: 'none',
            }}
        >
            {removeFileEnabled && (
                <Box
                    className={classes.fileDetailsRemoveIcon}
                    onClick={e => {
                        removeFileHandler();
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <IconButton>
                        <Close className={classes.closeIcon} />
                    </IconButton>
                </Box>
            )}
            <Grid
                container
                spacing={1}
                title={t('clickToDownload')}
                style={{
                    cursor: !disabled && 'pointer',
                }}
                onClick={onclick ? clickHandler : null}
            >
                <Grid item xs="auto">
                    <Box
                        style={{
                            width: '40px',
                        }}
                    >
                        {fileIcon ? (
                            <img
                                src={fileIcon}
                                alt="file icon"
                                className={clsx({
                                    [classes.disabled]: disabled,
                                })}
                            />
                        ) : (
                            <InsertDriveFile fontSize={'large'} />
                        )}
                    </Box>
                </Grid>

                <Grid item xs>
                    <Box
                        style={{
                            cursor: !disabled && 'pointer',
                        }}
                        className={clsx({
                            [classes.disabled]: disabled,
                        })}
                    >
                        <Typography variant="h5" className={classes.fileName}>
                            {fileName}
                        </Typography>
                        <Box className={classes.fileInfo}>
                            {fileOwner && (
                                <>
                                    {`${t('from')}: ${fileOwner}`}
                                    <span className={classes.grayDot} />
                                </>
                            )}
                            {fileTimeCreated && (
                                <>
                                    {fileTimeCreated}
                                    <span className={classes.grayDot} />
                                </>
                            )}
                            {fileDateCreated}
                            {patientDownloadDate && (
                                <>
                                    <span className={classes.grayDot} />
                                    {t('downloadedByPatient')}:{' '}
                                    {DateTime.fromISO(
                                        patientDownloadDate,
                                    ).toFormat("dd'.'MM'.'yyyy, HH:mm")}
                                </>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default UploadedItem;
