import { Box, Grid, IconButton } from "@material-ui/core";
import useStyles from "./SickLeaveDetailsPageStyles";
import { useHistory, useParams } from "react-router";
import { Loading } from "../../components";
import { useEffect, useState } from "react";
import { GoBackIcon } from "../../assets";
import i18nNamespaces from "../../const/i18nNamespaces";
import { useTranslation } from "react-i18next";
import { SICK_LEAVES, USER_ACCOUNT_PAGE } from "../../const/routes";
import kedZLAApi from "../../api/zusZLA/kedZLAApi";
import { IssuedZLADto } from "../../types/sickLeave";
import PatientSickLeaveListItem from "../../components/sickLeave/PatientSickLeaveListItem/PatientSickLeaveListItem";
import SickLeaveAppointmentDetails from "../../components/sickLeave/SickLeaveAppointmentDetails/SickLeaveAppointmentDetails";



const SickLeaveDetailsPage = () =>{
    const classes = useStyles();
    const params = useParams<{seriesAndNumber: string}>();
    const seriesAndNumber = params?.seriesAndNumber;

    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const {t} = useTranslation(i18nNamespaces.L4);

    const [sickLeave, setSickLeave] = useState<IssuedZLADto>(null);

    const fetchSickLeaveDetails = async ()=>{
        try{
            setLoading(true);
            const {data} = await kedZLAApi.getSickLeave(seriesAndNumber);
            if(data.isSuccess)
            setSickLeave(data.result);
        }
        catch{

        }finally{
            setLoading(false);
        }
    }

    useEffect(()=>{
        fetchSickLeaveDetails();
    }, []);

    return (
        <Box>
            <Loading withBackdrop={true} loading={loading} />

            <Grid container>
                <Grid item md={6} xs={12} className={classes.pageLeftColumn}>
                    <Box
                            className={classes.goBackWrapper}
                            onClick={
                                () => history.push(`${USER_ACCOUNT_PAGE}${SICK_LEAVES}`)
                            }>
                            <IconButton className={classes.goBackButton}>
                                <img src={GoBackIcon} alt="Go back" />
                            </IconButton>
                            {t('details')}
                        </Box>
                        <Box className={classes.sickLeaveItem}>
                            {sickLeave && <PatientSickLeaveListItem sickLeave={sickLeave} reditectToDetailsEnabled={false}/>}
                        </Box>                      
                </Grid>

                <Grid item md={6} xs={12}>
                    {sickLeave && <SickLeaveAppointmentDetails appointmentId={sickLeave.appointmentId}/>}
                  
                </Grid>

            </Grid>
        </Box>
    )
};

export default SickLeaveDetailsPage;