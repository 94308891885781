import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import useStyles from './EvaluationPageStyles';
import {
    Avatar,
    Box,
    Grid,
    TextField,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import Footer from '../../components/Layout/Footer/Footer';
import {Loading, EmptyTopBar, CustomRating} from '../../components';
import useAppointmentInfo from '../../hooks/useAppointmentInfo';
import {useParams} from 'react-router-dom';
import {getAppointmentTypes, getMedicFullName} from '../../utils/appointment';
import {getDateTime} from '../../utils/date';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {EvaluationDto, EvaluationFieldEntry} from '../../types/evaluation';
import evaluationProvider from '../../services/evaluationProvider';
import CustomButton from '../../components/common/Button/Button';
import {EVALUATION_FINISH, USER_ACCOUNT_PAGE} from '../../const/routes';
import {useHistory} from 'react-router';

const EvaluationPage = () => {
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const {appointmentId} = useParams<{appointmentId: string}>();
    const appointmentInfo = useAppointmentInfo(appointmentId);
    const history = useHistory();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.EVALUATION);
    const [evaluationData, setEvaluationData] = useState<EvaluationDto>();
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        setLoading(true);
        evaluationProvider
            .getEvaluationForm(appointmentId)
            .then(setEvaluationData)
            .finally(() => setLoading(false));
    }, []);

    const getAppointmentTypeInfo = () =>
        getAppointmentTypes(commonT)
            .find(type => type.value === appointmentInfo.type)
            .label.toLowerCase();

    const onChangeField = (id: number, value: number) => {
        setEvaluationData(data => ({
            ...data,
            evaluationFieldEntries: data.evaluationFieldEntries.map(entry =>
                entry.evaluationFieldDefinition.id === id
                    ? {
                          ...entry,
                          value: value === null ? 0 : value,
                      }
                    : entry,
            ),
        }));
    };

    const onChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
        const comment = e.target.value;
        setEvaluationData(data => ({
            ...data,
            comment,
        }));
    };

    const onSend = () => {
        setLoading(true);
        evaluationProvider
            .sendEvaluation(evaluationData, appointmentId)
            .then(() => history.push(EVALUATION_FINISH))
            .finally(() => setLoading(false));
    };

    const isFormFilled = useCallback(
        () =>
            !evaluationData.evaluationFieldEntries.some(
                entry => entry.value === 0,
            ),
        [evaluationData],
    );
    const mapEvaluationFieldEntries = () =>
        evaluationData.evaluationFieldEntries.map(
            ({
                evaluationFieldDefinition: field,
                value,
            }: EvaluationFieldEntry) => (
                <Box className={classes.section} key={field.id}>
                    <Grid
                        container
                        justifyContent={'space-between'}
                        direction={isDesktop ? 'row' : 'column'}
                        alignItems={isDesktop ? 'center' : 'flex-start'}
                        className={classes.fieldEntry}
                    >
                        <span className={classes.fieldLabel}>
                            {field.questionText}
                        </span>
                        <CustomRating
                            name={`field_${field.id}`}
                            size={'medium'}
                            value={value}
                            onChange={(_, value) =>
                                onChangeField(field.id, value)
                            }
                        />
                    </Grid>
                </Box>
            ),
        );

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={classes.container}
        >
            <Loading withBackdrop loading={loading} />

            <EmptyTopBar onCancel={() => history.push(USER_ACCOUNT_PAGE)} />

            <Box className={classes.pageContent}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    className={classes.section}
                >
                    <Grid
                        item
                        container
                        className={classes.titleContainer}
                        direction={'column'}
                    >
                        <Box className={classes.title}>
                            {t('evaluateAppointment')}
                        </Box>
                        {appointmentInfo && (
                            <>
                                <Box>
                                    {getMedicFullName(appointmentInfo?.medic)}
                                </Box>
                                <Box>
                                    {getDateTime(appointmentInfo?.startDate)}
                                    {', '}
                                    {getAppointmentTypeInfo()}
                                </Box>
                            </>
                        )}
                    </Grid>
                    {appointmentInfo && (
                        <Avatar
                            src={appointmentInfo.medic.photoUrl}
                            className={classes.medicPhoto}
                            alt="Medic photo"
                        />
                    )}
                </Grid>
                {evaluationData && (
                    <>
                        {mapEvaluationFieldEntries()}
                        <Box className={classes.section}>
                            <Box className={classes.fieldLabel}>
                                {t('addComment')}
                            </Box>
                            <TextField
                                id="comment"
                                name="comment"
                                type="text"
                                onChange={onChangeComment}
                                value={evaluationData.comment}
                                variant={'outlined'}
                                className={classes.commentField}
                                fullWidth
                            />
                        </Box>
                        <Grid
                            container
                            className={classes.section}
                            justifyContent={'center'}
                        >
                            <CustomButton
                                variant={'contained'}
                                color={'secondary'}
                                className={classes.sendButton}
                                onClick={onSend}
                                fullWidth
                                disabled={!isFormFilled()}
                            >
                                {t('send')}
                            </CustomButton>
                        </Grid>
                    </>
                )}
            </Box>
            <Footer />
        </Grid>
    );
};

export default EvaluationPage;
