import {useEffect} from "react";
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import {useAppDispatch} from "../../../../hooks/customReduxHooks";
import AddTag from "../AddTags/AddTag";
import AddedTags from "../AddedTags/AddedTags";
import useStyles from './MedicTagsStyles';
import useTileStyles from "../../common/tileCommonStyles";
import {fetchMedicTags} from "../../../../store/medicProfileSlice";

const MedicTags = () => {
    const classes = useStyles();
    const tileClasses = useTileStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchMedicTags());
    }, [dispatch]);

    return (
        <div className={tileClasses.root}>
            <h3 className={tileClasses.headerText}>
                {commonT('tags')}
            </h3>
            <Box className={classes.addTagWrapper}>
                <AddTag />
            </Box>
            <Box className={classes.addedTagsWrapper}>
                <AddedTags />
            </Box>
        </div>
    )
}

export default MedicTags;