
import useStyles from "./MedicaltestFilesStyles";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { Box, Grid } from "@material-ui/core";
import MedicalTestPdfResult from "../medicalTestPdfResult/MedicalTestPdfResult";


const MedicaltestFiles = () =>{
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);
    
    return (
        <Box>
            <Grid  
                className={classes.section}
                direction={'column'}
                spacing={1}>
                    <Grid item className={classes.header}>
                        {t('medicalTestResults')}
                    </Grid>   
                    <MedicalTestPdfResult/>
            </Grid>
        </Box>
    )
}

export default MedicaltestFiles;