import {
    NewQBCardSubscriptionDto, NewResellerSubscriptionDto,
    NewSubscriptionDto,
    ResellerCodeValidationDto,
    SubscriptionCreationResultDto,
} from '../types/subscriptions';
import {
    INDIVIDUAL_PACKAGES_SUBSCRIPTIONS,
    PAYMENT_STATUS,
    RESELLER_CODE_VALIDATION,
} from './endpoints';
import {http, rawHttp} from './http';

class SubscriptionsProvider {
    async createSubscription(
        individualPackageDraftId: number,
        newSubscription: NewSubscriptionDto | NewQBCardSubscriptionDto | NewResellerSubscriptionDto,
    ) {
        return (
            await rawHttp.post<SubscriptionCreationResultDto>(
                INDIVIDUAL_PACKAGES_SUBSCRIPTIONS(individualPackageDraftId),
                newSubscription,
            )
        ).data;
    }

    async createQBCardSubscription(
        individualPackageDraftId: number,
        newSubscription: NewQBCardSubscriptionDto,
    ) {
        return (
            await rawHttp.post<SubscriptionCreationResultDto>(
                INDIVIDUAL_PACKAGES_SUBSCRIPTIONS(individualPackageDraftId),
                newSubscription,
            )
        ).data;
    }

    async validateResellerCode(
        individualPackageId: number,
        resellerCode: string,
    ) {
        return rawHttp.post<ResellerCodeValidationDto>(
            RESELLER_CODE_VALIDATION,
            {
                individualPackageId,
                resellerCode,
            },
        );
    }

    async getPaymentStatus(subscriptionId:string){
        return rawHttp.get<string>(PAYMENT_STATUS(subscriptionId));
    }
}

export default new SubscriptionsProvider();
