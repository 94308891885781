import {useHistory} from 'react-router';
import {Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Button, GoBackIconButton} from '../../../index';
import useStyles from './DetailsHeaderMobileStyles';
import {MEDIC_CALENDAR} from '../../../../const/routes';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import AppointmentDataTileMobile from '../AppointmentDataTileMobile/AppointmentDataTileMobile';
import {
    Appointment,
    AppointmentStateEnum,
} from '../../../../types/appointments';
import React, {ReactNode} from "react";

interface Props {
    appointment: Appointment;
    joinAppointmentButton: ReactNode;
    endAppointmentAheadOfTimeButton: ReactNode;
    settleAppointmentButton: ReactNode;
    onCancelAppointment: () => void;
}

const DetailsHeaderMobile = ({
    appointment,
    joinAppointmentButton,
    endAppointmentAheadOfTimeButton,
    settleAppointmentButton,
    onCancelAppointment,
}: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const appointmentState = appointment.state.state;

    return (
        <Box>
            <Box
                className={classes.goBackWrapper}
                onClick={() => history.push(MEDIC_CALENDAR)}
            >
                <GoBackIconButton />
                {t('appointmentDetails')}
            </Box>
            {appointment && (
                <>
                    <Box className={classes.appointmentDetailsWrapper}>
                        <AppointmentDataTileMobile appointment={appointment} />
                    </Box>
                    <Box className={classes.actionButtons}>
                        {
                            appointmentState === AppointmentStateEnum.Planned && (
                                <Button
                                    className={`${classes.actionButton} ${classes.actionButtonPrimary}`}
                                    variant={'outlined'}
                                    color={'secondary'}
                                    onClick={onCancelAppointment}
                                >
                                    {t('cancelAppointment')}
                                </Button>
                            )
                        }
                        {endAppointmentAheadOfTimeButton}
                        {joinAppointmentButton}
                        {settleAppointmentButton}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default DetailsHeaderMobile;
