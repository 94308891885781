import {useCallback, useEffect, useState} from 'react';
import certificateApi from '../../api/certificate/certificateApi';

const useCertificateThumbprint = (medicId: string) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [thumbprint, setThumbprint] = useState<string>('');

    const fetchThumbprint = useCallback(async () => {
        setLoading(true);
        try {
            const {data} = await certificateApi.getCertificateThumbprint(
                medicId,
            );
            if (data?.isSuccess) return setThumbprint(data.result);
            setThumbprint('');
        } catch (error) {
            console.error('Certificate thumbprint loading error: ', error);
            setThumbprint('');
        } finally {
            setLoading(false);
        }
    }, [thumbprint]);

    useEffect(() => {
        fetchThumbprint();
    }, []);

    return {loading, thumbprint, fetchThumbprint};
};

export default useCertificateThumbprint;
