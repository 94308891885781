import React, {useEffect, useState} from 'react';
import {Box, Button, Grid} from '@material-ui/core';
import {CustomTextField, FormGroup, Label, Loading} from '../../components';
import useStyles from './ChangeAddressPageStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {useFormik} from 'formik';
import * as yup from 'yup';
import accountProvider from '../../services/accountProvider';
import {useHistory} from 'react-router';

type ChangeAddressFormValues = {
    streetName: string;
    houseNumber: string;
    apartmentNumber: string;
    postcode: string;
    cityName: string;
};

const REQUIRED_STRING = 'requiredField';

const ChangeAddressPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const [loading, setLoading] = useState<boolean>(false);

    const {
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setValues,
        handleSubmit,
    } = useFormik({
        initialValues: {
            streetName: '',
            houseNumber: '',
            apartmentNumber: '',
            postcode: '',
            cityName: '',
        },
        validationSchema: yup.object({
            streetName: yup.string().required(t(REQUIRED_STRING)),
            houseNumber: yup.string().required(t(REQUIRED_STRING)),
            postcode: yup.string().required(t(REQUIRED_STRING)),
            cityName: yup.string().required(t(REQUIRED_STRING)),
        }),
        onSubmit: async (values: ChangeAddressFormValues) => {
            try {
                setLoading(true);
                await accountProvider.updateAddressData(values);
                history.goBack();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        (() => {
            setLoading(true);
            accountProvider
                .getAddressData()
                .then(({data}) => {
                    setValues(data);
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        })();
    }, []);

    return (
        <form className={classes.container} onSubmit={handleSubmit}>
            <Box className={classes.title}>{t('addressChange')}</Box>
            <Loading loading={loading} withBackdrop />
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.formSubsection}
            >
                <FormGroup className={classes.formGroup}>
                    <Label htmlFor="streetName">{t('street')}*</Label>
                    <CustomTextField
                        id="streetName"
                        name="streetName"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.streetName}
                        helperText={touched.streetName && t(errors.streetName)}
                        error={touched.streetName && !!errors.streetName}
                    />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                    <Label htmlFor="houseNumber">{t('houseNumber')}*</Label>
                    <CustomTextField
                        id="houseNumber"
                        name="houseNumber"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.houseNumber}
                        helperText={
                            touched.houseNumber && t(errors.houseNumber)
                        }
                        error={touched.houseNumber && !!errors.houseNumber}
                        className={classes.numberInput}
                    />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                    <Label htmlFor="apartmentNumber">
                        {t('apartmentNumber')}
                    </Label>
                    <CustomTextField
                        id="apartmentNumber"
                        name="apartmentNumber"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.apartmentNumber}
                        helperText={
                            touched.apartmentNumber && t(errors.apartmentNumber)
                        }
                        error={
                            touched.apartmentNumber && !!errors.apartmentNumber
                        }
                        className={classes.apartmentInput}
                    />
                </FormGroup>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormGroup className={classes.formGroup}>
                    <Label htmlFor="postcode">{t('postcode')}*</Label>
                    <CustomTextField
                        id="postcode"
                        name="postcode"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postcode}
                        helperText={touched.postcode && t(errors.postcode)}
                        error={touched.postcode && !!errors.postcode}
                        className={classes.codeInput}
                    />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                    <Label htmlFor="cityName">{t('cityName')}*</Label>
                    <CustomTextField
                        id="cityName"
                        name="cityName"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cityName}
                        helperText={touched.cityName && t(errors.cityName)}
                        error={touched.cityName && !!errors.cityName}
                    />
                </FormGroup>
            </Grid>
            <Grid container direction={'row'} style={{marginTop: 50}}>
                <Button
                    type={'submit'}
                    variant={'contained'}
                    color={'secondary'}
                    style={{fontWeight: 'bold'}}
                >
                    {t('changeData')}
                </Button>
                <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => history.goBack()}
                    style={{marginLeft: 20}}
                >
                    {commonT('goBack')}
                </Button>
            </Grid>
        </form>
    );
};

export default ChangeAddressPage;
