import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { WHITE } from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            backgroundColor: 'white',
            padding: 20,
            borderRadius: 5,
        },
        formContainer: {
            marginTop: 50,
        },
        tabsContainer: {
            // display: 'flex',
            justifyContent: 'flex-start',
        },
        selectButton: {
            marginRight: 20,
        },
        [theme.breakpoints.down('md')]: {
            formContainer: {
                backgroundColor: 'white',
                padding: '20px 10px',
                marginTop: 0,
            },
            card:{
                margin:'16px'
            }
           
        },
        titleMobile:{
            backgroundColor:WHITE,
            padding:'16px',
            fontWeight:'bold',
        },
        buttonSelectMobile:{
            padding:'16px'
        },
        containerMobile:{
            backgroundColor:WHITE,
        },

    }),
);

export default useStyles;
