import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {MAGENTA} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorWrapper: {
            height: '100%',
            marginTop: 20,
            marginBottom: 40,
        },
        errorTitle: {
            fontSize: '36px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        errorContent: {
            fontSize: '1rem',
            margin: '40px 20px',
            textAlign: 'center',
        },
        errorImage: {
            width: '60%',
            marginTop: 36,
            margin: '0 auto',
        },
        contactInfo: {
            fontSize: 18,
            marginBottom: 18,
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        privacyPolicyDeclaration: {
            fontSize: 12,
            width: 265,
            marginTop: 18,
        },
        errorButton: {
            width: 300,
            fontWeight: 'bold',
        },
        termsAndConditionLink: {
            color: MAGENTA,
        },
        [theme.breakpoints.up('sm')]: {
            errorWrapper: {
                marginTop: 88,
            },
            errorContent: {
                fontSize: '1rem',
                marginTop: 32,
                marginBottom: 44,
                maxWidth: '45%',
            },
            errorImage: {
                width: 300,
                marginBottom: 36,
            },
            privacyPolicyDeclaration: {
                width: 400,
            },
        },
    }),
);

export default useStyles;
