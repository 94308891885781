import {memo} from 'react';
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import MedicalTestsOrderedInfo from './components/MedicalTestsOrderedInfo';
import MedicalTestsOrderedNavButton from "./components/MedicalTestsOrderedNavButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageRoot: {
            padding: 16,
            minHeight: 'calc(100vh - 128px)',
            backgroundColor: WHITE,
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                padding: 47,
            }
        }
    }),
);

const MedicalTestsSuccessfullyOrderedPage = memo(function MedicalSearchFilters() {
    const classes = useStyles();
    return (
        <Box className={classes.pageRoot}>
            <MedicalTestsOrderedInfo />
            <MedicalTestsOrderedNavButton />
        </Box>
    );
});

export default MedicalTestsSuccessfullyOrderedPage;