import {NavLink, NavLinkProps} from 'react-router-dom';
import useStyles from './CustomLinkStyles';
import clsx from "clsx";


const CustomLink = ({...props}: NavLinkProps) => {
    const classes = useStyles();

    return (
        <NavLink className={clsx([classes.menuButton, props.className])} {...props}>
            {props.children}
        </NavLink>
    );
};

export default CustomLink;
