import { FactorsHarmfulAndBurdensomeToHealthDto, OccupationalMedicineDto, OccupationalMedicineRequestBodyDto, PatientPersonalDataDto, PatientPlaceOfWorkDto, PatientPlaceOfWorkHistoryDto, PatientPlaceOfWorkOrOfStudyDto, PatientProfessionalExperienceDto, PatientStatementBodyDto, PatientStatementDto, PatientSummaryDto, PhisicalTestDto } from "../types/occupationalMedicine";
import { OCCUPATIONAL_MEDICINE, APPOINTMENT_OCCUPATIONAL_MEDICINE, APPOINTMENT_OCCUPATIONAL_MEDICINE_DATA } from "./endpoints";
import { http } from "./http";

class OccupationalMedicineProvider {

 
    async getSection<T>(id: string, section: number) {
        return (
            await http.get<T>(APPOINTMENT_OCCUPATIONAL_MEDICINE(id), {
                params:{
                    section:section
                }
            })
        );
    }


    async putSection(id: string, data:OccupationalMedicineRequestBodyDto) {
        return (
            await http.put<string>(APPOINTMENT_OCCUPATIONAL_MEDICINE(id), data)
        );
    }

    async postSection(id: string, data:PatientStatementBodyDto) {
        return (
            await http.post<string>(APPOINTMENT_OCCUPATIONAL_MEDICINE(id), data)
        );
    }

    async get(id:string){
        return (
            await http.get<OccupationalMedicineDto>(APPOINTMENT_OCCUPATIONAL_MEDICINE_DATA(id), {
            })
        );
    }

    async postFull(id: string, data: OccupationalMedicineRequestBodyDto) {
        return (
            await http.post<string>(OCCUPATIONAL_MEDICINE(id), data)
        );
    }
}

export default new OccupationalMedicineProvider();