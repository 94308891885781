import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Theme,
} from '@material-ui/core';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    IssuePrescriptionDto,
    PrescriptionInPrescriptionSet,
    PrescriptionSetDto,
} from '../../../types/prescription';
import {TFunction, useTranslation} from 'react-i18next';
import {GetApp} from '@material-ui/icons';
import {Loading} from '../../index';
import {useState} from 'react';
import prescriptionsApi from '../../../api/prescriptions/prescriptionsApi';
import filesDownloadProvider from '../../../services/filesDownloadProvider';
import {getPrescriptionSetFileName} from '../../../utils/prescription';
import { IssuedZLADto } from '../../../types/sickLeave';
import { DateTime } from 'luxon';
import { GRAY, MAGENTA } from '../../../const/colors';

type SickLeavesListItemListItemProps = {
    sickLeave: IssuedZLADto;
    index: number;
    downloadable?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        details: {
            display: 'flex',
            flexDirection: 'column',
        },

        statusText:{
            color:GRAY
        }
    }),
);

const SickLeaveHeader = ({
    sickLeave,
    index,
    t,
}: {
    sickLeave: IssuedZLADto;
    index: number;
    t: TFunction;
}) => (
    <Grid container direction='row'>
        <Grid item xs={6}>
            {index + 1}. {t('issueDate')}:{' '}
            {new Date(sickLeave.createdDateTime).toLocaleDateString('pl')}
        </Grid>
        {sickLeave.isCancelled && ( 
        <Grid item  xs={6} style={{color:GRAY, fontWeight:'normal', display:'flex', justifyContent:'flex-end'}}>
            {t('cancelled')}
        </Grid>)}
       
    </Grid>
);

const SickLeaveDetails = ({
    sickLeave,
    index,
    t,
}: {
    sickLeave: IssuedZLADto;
    index: number;
    t: TFunction;
}) => (
    <Grid container direction='column'>
        <Grid item container direction="row">
            <Box style={{fontWeight:'bold', marginRight:'10px'}}>
                {t("seriesAndNumber")}: 
            </Box>
            <Box>{sickLeave.seriesAndNumber} </Box>
        </Grid>
        <Grid item container direction="row">
            <Box style={{fontWeight:'bold', marginRight:'10px'}}>
                {t("from")}: 
            </Box>
            <Box>{DateTime.fromISO(sickLeave.unableToWorkFromDate).toFormat("dd'.'MM'.'yyyy")} </Box>
        </Grid>
        <Grid item container direction="row">
            <Box style={{fontWeight:'bold', marginRight:'10px'}}>
                {t("to")}: 
            </Box>
            <Box>{DateTime.fromISO(sickLeave.unableToWorkToDate).toFormat("dd'.'MM'.'yyyy")} </Box>
        </Grid>
        <Grid item container direction="row">
            <Box style={{fontWeight:'bold', marginRight:'10px'}}>
                {t("icd10")}: 
            </Box>
            <Box>{sickLeave.icD10} </Box>
        </Grid>
           
    </Grid>
);
const SickLeavesListItem = ({
    sickLeave,
    index,
    downloadable,
}: SickLeavesListItemListItemProps) => {
    const {t} = useTranslation(i18nNamespaces.L4);
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <>
            <Loading withBackdrop loading={loading} />
            <Accordion>
                <AccordionSummary style={{fontWeight: 'bold'}}>
                    <SickLeaveHeader
                        sickLeave={sickLeave}
                        index={index}
                        t={t}
                    />  
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.details}>
                            <SickLeaveDetails
                                key={index + sickLeave.seriesAndNumber}
                                sickLeave={sickLeave}
                                index={index}
                                t={t}
                            />
                        
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SickLeavesListItem;
