import { MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { useAppDispatch } from "../../../hooks/customReduxHooks";
import { isNotNullOrUndefined } from "../../../utils/lang";

import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18nNamespaces from "../../../const/i18nNamespaces";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import { FactorsHarmfulAndBurdensomeToHealthDto } from "../../../types/occupationalMedicine";
import { useFormik } from "formik";
import * as yup from 'yup';
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { RenderReturnNextButtons } from "../CommonView";
import { useParams } from "react-router";
import { fetchSection5Data } from "../../../store/occupationalMedicineSlice";

const SECTION = 5

export type Props = {
    data: FactorsHarmfulAndBurdensomeToHealthDto,
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void;
    currentExpanded:number
    onReturn: () => void;
    refSection:MutableRefObject<any>
}
const HarmfulFactorsComponent = ({
    data, onSubmitSection, currentExpanded, onReturn, refSection
}:Props) =>{
    const {appointmentId} = useParams<{appointmentId: string}>();
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();

    const dispatch = useAppDispatch();

    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);
    
    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values), fetchSection5Data({id:appointmentId}), 5);
    }

    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {
                description:data?.description ?? '',
                consistentWithTheReferral:data?.consistentWithTheReferral ?? false
            },

            validationSchema: yup.object({
            }),
            onSubmit
        });


    
    return (
        <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >

                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('harmfulFactors')}
                        </h4>

                        {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.detailsContainer}>
                    <Box>
                        <Grid container spacing={1} className={classes.gridContainer}>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <Label htmlFor="factorsHarmfulAndBurdensomeToHealthTitle">{t("factorsHarmfulAndBurdensomeToHealthTitle")}</Label>
                                    <CustomTextField 
                                            fullWidth 
                                            id="description"
                                            name="description"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                            />
                                </FormGroup>
                            </Grid>
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="consistentWithTheReferral"
                                                name="consistentWithTheReferral"
                                                checked={values.consistentWithTheReferral}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={
                                            <Box className={classes.text}>
                                                {t('consistentWithTheReferral')}
                                            </Box>
                                        }  />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        {RenderReturnNextButtons(classes, t, onReturn)}
                    </Box>

                </AccordionDetails>
            </Accordion>
        </form>
    )
};

export default HarmfulFactorsComponent;