import {createStyles, Grid, Hidden, makeStyles} from '@material-ui/core';
import React from 'react';
import {WHITE} from '../../../const/colors';

type SideMenuWrapperProps = {
    menu: React.ReactNode;
    content?: React.ReactNode;
    children?: React.ReactNode;
};

const sideMenuSize = '347px';

const useStyles = makeStyles(() =>
    createStyles({
        sideMenuContainer: {
            background: WHITE,
            width: sideMenuSize,
            minHeight: 'calc(100vh - 128px);',
        },
    }),
);

const SideMenuWrapper = ({menu, content, children}: SideMenuWrapperProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
        >
            <Grid item xs>
                {children || content}
            </Grid>
            <Hidden smDown>
                <Grid item className={classes.sideMenuContainer}>
                    {menu}
                </Grid>
            </Hidden>
        </Grid>
    );
};

export default SideMenuWrapper;
