import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchUpcomingAppointmentById,
    selectUpcomingAppointmentById,
    selectUpdatingAppointment,
} from '../../../store/patientCalendar/upcomingAppointmentsSlice';
import AppointmentItem from '../AppointmentItem/AppointmentItem';
import {ERROR, FIND_TIME_SLOTS} from '../../../const/routes';
import {ConfirmOrRejectModal, Loading} from '../../index';
import React, {useCallback, useEffect, useState} from 'react';
import {AppointmentStateEnum} from '../../../types/appointments';
import {v4} from 'uuid';
import {useCancelAppointmentDetailsText} from '../../../hooks/appointment/useCancelAppointmentDetailsText';
import {Box} from '@material-ui/core';
import useCancelAppointment from '../../../hooks/appointment/useCancelAppointment';
import {useHistory} from 'react-router';
import usePostCancellationFetchAppointment from '../../../hooks/appointment/usePostCancellationFetchAppointment';
import {displayUnpaidLabel} from '../../../utils/appointment';
import appointmentsProvider from '../../../services/appointmentsProvider';
import CustomAlert from '../../common/feedback/CustomAlert';

type UpcomingAppointmentProps = {
    upcomingAppointmentId: number;
};

const UpcomingAppointment = ({
    upcomingAppointmentId,
}: UpcomingAppointmentProps) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const [displayAlertOnPostpone, setDisplayAlertOnPostpone] = useState<boolean>(false);

    const upcomingAppointment = useAppSelector(state =>
        selectUpcomingAppointmentById(state, upcomingAppointmentId),
    );

    const {
        id: updatingUpcomingAppointmentId,
        status: updatingUpcomingAppointmentStatus,
    } = useAppSelector(selectUpdatingAppointment);

    const {
        canCancelWithRefund,
        paymentStatus,
        state: {state: upcomingAppointmentState},
    } = upcomingAppointment;

    const {
        cancelAppointmentModalOpen,
        openCancelAppointmentConfirmModal,
        closeCancelAppointmentConfirmModal,
        cancelAppointmentLoading,
        cancelAppointmentAction,
        cancelAppointmentError,
    } = useCancelAppointment();

    const cancelDetailsText = useCancelAppointmentDetailsText(
        upcomingAppointmentState,
        canCancelWithRefund,
    );

    const appointmentStatePlanned =
        upcomingAppointmentState === AppointmentStateEnum.Planned;
    const upcomingAppointmentLoading =
        updatingUpcomingAppointmentStatus === 'loading' &&
        updatingUpcomingAppointmentId === `${upcomingAppointmentId}`;

    const appointmentFetch = useCallback(async () => {
        dispatch(
            fetchUpcomingAppointmentById({
                id: `${upcomingAppointmentId}`,
            }),
        );
    }, [upcomingAppointmentId]);

    const {
        postCancellationAppointmentLoading,
        setPostCancellationAppointmentLoading,
    } = usePostCancellationFetchAppointment(
        upcomingAppointment,
        upcomingAppointmentLoading,
        appointmentFetch,
    );

    const payAgain = () => {
        appointmentsProvider.payAgain(upcomingAppointmentId).then(result => {
            window.location.href = result.paymentUrl;
        });
    };

    const error =
        cancelAppointmentError ||
        updatingUpcomingAppointmentStatus === 'failed';
    const displayLoader =
        cancelAppointmentLoading ||
        upcomingAppointmentLoading ||
        postCancellationAppointmentLoading;

    useEffect(() => {
        if (error) {
            history.replace(ERROR);
        }
    }, [error]);

    const openAlertOnPostpone = () =>{
        setDisplayAlertOnPostpone(true);
    }

    const links = [
        {
            id: v4(),
            display: displayUnpaidLabel(paymentStatus),
            label: tCommon('makePayment'),
            onClick: payAgain,
        },
        {
            id: v4(),
            display:
                !displayUnpaidLabel(paymentStatus) && appointmentStatePlanned,
            label: t('cancel'),
            onClick: openCancelAppointmentConfirmModal,
        },
        {
            id: v4(),
            display:
                !displayUnpaidLabel(paymentStatus) && appointmentStatePlanned,
            label: t('postpone'),
            URL: FIND_TIME_SLOTS + `/?appointmentId=${upcomingAppointmentId}`,
        },
    ];

    const linksWithConstraints = [
        {
            id: v4(),
            display: displayUnpaidLabel(paymentStatus),
            label: tCommon('makePayment'),
            onClick: payAgain,
        },
        {
            id: v4(),
            display:
                !displayUnpaidLabel(paymentStatus) && appointmentStatePlanned,
            label: t('cancel'),
            onClick: openCancelAppointmentConfirmModal,
        },
        {
            id: v4(),
            display:
                !displayUnpaidLabel(paymentStatus) && appointmentStatePlanned,
            label: t('postpone'),
            onClick: openAlertOnPostpone
        },
    ]
    

    const cancelAppointmentHandler = useCallback(async () => {
        await cancelAppointmentAction(`${upcomingAppointmentId}`);
        setPostCancellationAppointmentLoading(true);
    }, [
        upcomingAppointmentId,
        cancelAppointmentAction,
        setPostCancellationAppointmentLoading,
    ]);

    return (
        <Box>
            <Loading loading={displayLoader} withBackdrop />
            {
                <>
                    <AppointmentItem
                        appointment={upcomingAppointment}
                        links={upcomingAppointment.bookedByAgent ? linksWithConstraints : links}
                        canMoveToDetails={!displayUnpaidLabel(paymentStatus)}
                    />
                    <ConfirmOrRejectModal
                        open={cancelAppointmentModalOpen}
                        primaryText={t(
                            'pleaseConfirmYouWantToCancelTheAppointment',
                        )}
                        secondaryText={cancelDetailsText}
                        confirmButtonText={tCommon('confirm')}
                        onConfirm={cancelAppointmentHandler}
                        onReject={closeCancelAppointmentConfirmModal}
                    />
                    <CustomAlert 
                         open={displayAlertOnPostpone}
                         severity="warning" 
                         message={t('alertOnPostpone')} 
                         onClose={() => setDisplayAlertOnPostpone(false)}/>
                    
                </>
            }
        </Box>
    );
};

export default UpcomingAppointment;
