import {useCallback, useContext, useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import medicsProvider from '../../services/medicsProvider';
import {MedicCalendarUTCDates} from '../../types/medic';
import {PL_TIMEZONE} from '../../const/timeZone';
import {createDateWithZeroTime} from '../../utils/date';
import {AlertContext} from '../../contexts/AlertContext';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';

export const useAddMedicTimeSlotsDates = () => {
    const [
        medicCalendarPolishTimeZoneMidnightDates,
        setMedicCalendarPolishTimeZoneMidnightDates,
    ] = useState<MedicCalendarUTCDates | null>(null);
    const [selectedStartDate, setSelectedStartDate] = useState<DateTime | null>(
        null,
    );
    const [selectedEndDate, setselectedEndDate] = useState<DateTime | null>(
        null,
    );
    const [medicCalendarDatesLoading, setMedicCalendarDatesLoading] =
        useState(false);
    const openAlert = useContext(AlertContext);
    const {t: tTimeSlots} = useTranslation(i18nNamespaces.TIME_SLOTS);

    useEffect(() => {
        (async () => {
            setMedicCalendarDatesLoading(true);
            try {
                const {data: dates} =
                    await medicsProvider.getMedicCalendarUTCDatesConvertedFromPolishTimeZoneMidnightTimeDates();
                setMedicCalendarPolishTimeZoneMidnightDates({
                    firstDayOfMedicCalendar:
                        dates.firstDayOfMedicCalendar.setZone(PL_TIMEZONE),
                    lastDayOfMedicCalendar:
                        dates.lastDayOfMedicCalendar.setZone(PL_TIMEZONE),
                });
            } catch (e) {
                openAlert({
                    severity: 'error',
                    message: tTimeSlots(
                        'AnErrorOccurredWhileFetchingMedicCalendarDates',
                    ),
                });
                console.error(e);
            } finally {
                setMedicCalendarDatesLoading(false);
            }
        })();
    }, []);

    const setSelectedStartDateFromJsDate = useCallback((dateZonePl: Date) => {
        setSelectedStartDate(
            createDateWithZeroTime(DateTime.fromJSDate(dateZonePl)),
        );
    }, []);

    const setSelectedEndDateFromJsDate = useCallback((dateZonePl: Date) => {
        setselectedEndDate(
            createDateWithZeroTime(DateTime.fromJSDate(dateZonePl)),
        );
    }, []);

    if (!medicCalendarPolishTimeZoneMidnightDates) {
        return null;
    }

    const currentPolishTimeZoneMidnightDate = createDateWithZeroTime(
        DateTime.now().setZone(PL_TIMEZONE),
    );

    const firstDayOfMedicCalendar =
        medicCalendarPolishTimeZoneMidnightDates.firstDayOfMedicCalendar;
    const lastDayOfMedicCalendar =
        medicCalendarPolishTimeZoneMidnightDates.lastDayOfMedicCalendar;

    const minStartDate =
        firstDayOfMedicCalendar > currentPolishTimeZoneMidnightDate
            ? firstDayOfMedicCalendar
            : currentPolishTimeZoneMidnightDate;
    const startDate = selectedStartDate || minStartDate;
    const maxStartDate = selectedEndDate || lastDayOfMedicCalendar;

    const minEndDate = selectedStartDate || minStartDate;
    const endDate = selectedEndDate || minStartDate;

    return {
        medicCalendarDatesLoading,
        startDate,
        minStartDate,
        maxStartDate,
        endDate,
        minEndDate,
        maxEndDate: lastDayOfMedicCalendar,
        setSelectedStartDateFromJsDate,
        setSelectedEndDateFromJsDate,
    };
};
