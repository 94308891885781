import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import useTileStyles from '../common/tileCommonStyles';
import useStyles from './StampDesktopStyles';
import StampPicture from './stampPicture/StampPicture';
import EditStampActionButton from './editStampActionButton/EditStampActionButton';

const StampDesktop = () =>{

    const tileClasses = useTileStyles();
    const classes = useStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);

    return (
        <Box className={tileClasses.root}>
            <h3 className={tileClasses.headerText}>
                {commonT('yourStamp')}
            </h3>
            <Grid
                className={classes.contentContainer}
                container
            >
                <StampPicture />
                <Box>
                    <Box className={classes.actionButtonsWrapper}>
                        <EditStampActionButton />
                    </Box>
                </Box>
            </Grid>
        </Box>
    );
}
export default StampDesktop;