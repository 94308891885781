import { Accordion, AccordionDetails, Box, Grid, IconButton } from "@material-ui/core";
import { ZLAHistoryDto, ZUSZLADto } from "../../../types/sickLeave";
import useStyles from "./SickLeaveHistoryStyles";
import { Delete } from "@material-ui/icons";
import isuredApi from "../../../api/isured/isuredApi";
import { useEffect, useState } from "react";
import SickLeaveTab from "../SickLeaveTab/SickLeaveTab";
import Paging from "../../common/Paging/Paging";
import Loading from "../../common/Loading/Loading";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";

type Props = {
    handleOnCancel: (zla: ZLAHistoryDto) => void;
    disabled:boolean;
    isuredPesel:string;
    setError: (newValue:string) =>void
};

const PAGE_SIZE = 5
const SickLeaveHistory = ({handleOnCancel, disabled, isuredPesel, setError}:Props) => 
{
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.L4);

    const [zLAs, setZLAs] = useState<ZLAHistoryDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    
    const fetchHistory = async (currentPage:number) => {
        try {
            setLoading(true);
            const {data} = await isuredApi.getZLAs(isuredPesel, currentPage, PAGE_SIZE);
            if (data.isSuccess)
            {
                setZLAs(data.result.results);
                setCount(data.result.count);
            }
            else{
                setError(`${t("zlaFetchHistoryErrorOccured")} ${data.message}`);
            }

        } catch (error) {
            setError(t("zlaFetchHistoryErrorOccured"));
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        fetchHistory(page);
    }, []);


    const pageChangeHandler = (pageIndex: number) => {
        setPage(pageIndex);
        fetchHistory(pageIndex);
    };

    return (
        <Box className={classes.sectionContent}>
            <Loading loading={loading} withBackdrop />
            {zLAs && zLAs.map(zla => (
                <SickLeaveTab 
                    key={`${zla.series}${zla.number}`} 
                    zla={zla}
                    handleOnCancel={handleOnCancel}
                    disabled={disabled}/>
            ))}
            <Box
                className={
                    classes.showMoreHistorical
                }
            >
                <Paging
                    page={page}
                    size={PAGE_SIZE}
                    count={count}
                    onChange={pageChangeHandler}
                />
            </Box>
        </Box>
    )
};

export default SickLeaveHistory;