import {makeStyles, Theme} from '@material-ui/core';
import {LIGHT_GRAY} from '../../../const/colors';
import {mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: `24px ${mobile.contentLargerSpacing}`,
        borderBottom: `1px solid ${LIGHT_GRAY}`,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    medicData: {
        marginLeft: 24,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    medicPhoto: {
        width: 88,
        height: 88,
    },
    medicName: {
        fontWeight: 'bold',
    },
    removeButton: {
        width: 20,
        height: 20,
        cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
        container: {padding: '24px 82px 24px 0'},
    },
}));

export default useStyles;
