import {DateTime} from 'luxon';
import {
    MedicCalendarEntry,
    MedicCalendarEntryDto,
} from '../../types/medicCalendarEntry';

const mapMedicCalendarEntry = (
    medicCalendarEntryDto: MedicCalendarEntryDto,
): MedicCalendarEntry => ({
    id:
        medicCalendarEntryDto.appointmentId?.toString() ??
        medicCalendarEntryDto.timeSlotId,
    appointmentId: medicCalendarEntryDto.appointmentId,
    timeSlotId: medicCalendarEntryDto.timeSlotId,
    startDate: DateTime.fromISO(medicCalendarEntryDto.startDate),
    endDate: DateTime.fromISO(medicCalendarEntryDto.endDate),
    patient: medicCalendarEntryDto.child || medicCalendarEntryDto.patient,
    medicalService: medicCalendarEntryDto.medicalService,
    type: medicCalendarEntryDto.type,
    appointmentState: medicCalendarEntryDto.appointmentState,
    paymentStatus:medicCalendarEntryDto.paymentStatus,
    referralCreator:medicCalendarEntryDto.referralCreator,
});

export default mapMedicCalendarEntry;
