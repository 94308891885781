import {
    createStyles,
    makeStyles,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {DatePicker, DatePickerProps} from '@material-ui/pickers';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            height: 48,
            backgroundColor: 'white',
            border: '1px solid #E2E2E2',
            borderRadius: 3,
            padding: '0 14px',
        },
    }),
);

const CustomDatePicker = ({className, ...props}: DatePickerProps) => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const classes = useStyles();

    return (
        <DatePicker
            InputProps={{
                className: clsx([classes.container, className]),
                disableUnderline: true,
            }}
            {...props}
            fullWidth={!isDesktop}
        />
    );
};

export default CustomDatePicker;
