import {P1Service, rawP1Service} from '../../services/P1Service';
import {getCertificateEndpoint} from './certificateEndpoints';
import {P1Response} from '../../types/p1Service';

class CertificateApi {
    loadCertificate(medicId: string, formData: FormData) {
        return rawP1Service.put<P1Response<string>>(
            getCertificateEndpoint(medicId),
            formData,
        );
    }

    deleteCertificate(medicId: string) {
        return rawP1Service.delete(getCertificateEndpoint(medicId));
    }

    getCertificateThumbprint(medicId: string) {
        return rawP1Service.get<P1Response<string>>(
            getCertificateEndpoint(medicId),
        );
    }
}

export default new CertificateApi();
