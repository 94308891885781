import React from 'react';
import {Box, Button, Divider, Link, Typography} from '@material-ui/core';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useTranslation} from 'react-i18next';
import {AppointmentDetailsForPrescriptionDto} from '../../../types/appointments';
import {Loading} from '../../index';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    applyForPatientDataAccess,
    authorizePatientDataAccess,
    fetchPrescriptionHistory,
    selectCodeSent,
    selectErrorText,
    selectPrescriptionHistory,
    selectStatus,
} from '../../../store/prescriptionHistorySlice';
import AccessCodeInput from '../AccessCodeInput/AccessCodeInput';
import {isFulfilled} from '@reduxjs/toolkit';
import PrescriptionHistoryTable from '../PrescriptionHistoryTable/PrescriptionHistoryTable';

interface PrescriptionHistoryProps {
    appointmentDetails: AppointmentDetailsForPrescriptionDto;
}

const PrescriptionHistory = ({
    appointmentDetails,
}: PrescriptionHistoryProps) => {
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectStatus);
    const codeSent = useAppSelector(selectCodeSent);
    const loading = status === 'loading';
    const errorText = useAppSelector(selectErrorText);
    const prescriptionHistory = useAppSelector(selectPrescriptionHistory);
    const handleUseCode = async () => {
        const authorize = await dispatch(
            authorizePatientDataAccess(appointmentDetails),
        );
        if (isFulfilled(authorize))
            dispatch(fetchPrescriptionHistory(appointmentDetails));
    };

    const handleSendCode = () => {
        dispatch(applyForPatientDataAccess(appointmentDetails));
    };

    return (
        <>
            <Loading loading={loading} withBackdrop />
            <h2>{t('accessAuthorization')}</h2>
            <Box style={{marginBottom: 30}}>
                <Link
                    style={{cursor: 'pointer'}}
                    color={'secondary'}
                    onClick={handleSendCode}
                >
                    {t('sendCode')}
                </Link>
            </Box>
            {codeSent && (
                <AccessCodeInput
                    onSubmit={handleUseCode}
                    disabled={loading}
                    errorText={errorText}
                />
            )}
            <Divider style={{height: 1, margin: '30px 0'}} />
            {prescriptionHistory !== null &&
                (prescriptionHistory?.length > 0 ? (
                    <PrescriptionHistoryTable
                        appointmentDetails={appointmentDetails}
                    />
                ) : (
                    <Typography
                        variant={'h5'}
                        style={{textAlign: 'center', marginBottom: 10}}
                    >
                        {t('thereAreNoPrescriptionsInHistory')}
                    </Typography>
                ))}
        </>
    );
};

export default PrescriptionHistory;
