import {ReactNode} from 'react';
import configuration from '../../../../configuration';
import {
    PayUSecureFormsContext,
    PayUSecureFormsContextType,
} from '../payUSecureFormsContext';

type PayUSecureFormsProviderProps = {
    children: ReactNode;
};

const PayUSecureFormsProvider = ({children}: PayUSecureFormsProviderProps) => {
    const formOptions: payu.SecureFormOptions = {
        cardIcon: false,
        style: {
            basic: {
                fontSize: '16px',
                fontColor: '#212121',
            },
        },
        placeholder: {
            cvv: '',
            number: '',
        },
    };

    const payuSdkForms = PayU(configuration.payUPosId, {
        dev: configuration.environment === 'Development',
    });

    const secureForms = payuSdkForms.secureForms();

    const secureFormsContextValue: PayUSecureFormsContextType = {
        forms: {
            number: secureForms.add('number', formOptions),
            date: secureForms.add('date', formOptions),
            cvv: secureForms.add('cvv', formOptions),
        },
        tokenize: payuSdkForms.tokenize,
        sendCvv: (redirectUrl: string) =>
            payuSdkForms.sendCvv(payuSdkForms.extractRefReqId(redirectUrl)),
    };

    return (
        <PayUSecureFormsContext.Provider value={secureFormsContextValue}>
            {children}
        </PayUSecureFormsContext.Provider>
    );
};

export default PayUSecureFormsProvider;
