import {FormControl, MenuItem, Select} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import configurationProvider from '../../../services/configurationProvider';
import {selectServiceDuration} from '../../../store/dayScheduleTemplatesSlice';
import TimeOfDay from '../../../utils/timeOfDay';
import Loading from '../Loading/Loading';

const generateMenuItemsFromTimeValues = (
    earliestTime: TimeOfDay,
    latestTime: TimeOfDay,
    serviceDuration: number,
) =>
    [...Array((+latestTime - +earliestTime) / serviceDuration + 1).keys()].map(
        index => {
            
            const time = earliestTime.plus(index * serviceDuration);

            return (
                <MenuItem key={+time} value={+time}>
                    {time.toString()}
                </MenuItem>
            );
        },
    );

type AvailabilityTemplateTimeSlotInputProps = {
    name?: string;
    value: TimeOfDay;
    onChange: (value: TimeOfDay) => void;
    disabled: boolean;
    earliestDateTime: TimeOfDay;
    latestDateTime: TimeOfDay;
    className?: string;
    fullWidth?: boolean;
    serviceDuration:number;
};

const TimeSlotInput = ({
    name,
    value,
    onChange,
    disabled,
    earliestDateTime,
    latestDateTime,
    className,
    fullWidth,
    serviceDuration
}: AvailabilityTemplateTimeSlotInputProps) => {

    const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
        onChange(new TimeOfDay(event.target.value as number));
    };

    return (
        <FormControl variant="outlined" fullWidth={fullWidth}>
            <Select
                name={name}
                value={+value}
                onChange={handleChange}
                className={className}
                disabled={disabled}
            >
                {generateMenuItemsFromTimeValues(
                    earliestDateTime,
                    latestDateTime,
                    serviceDuration,
                )}
            </Select>
        </FormControl>
    );
};

export default TimeSlotInput;
