import { Avatar, Box, Card, Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { IssuedZLADto } from "../../../types/sickLeave";
import useStyles from "./PatientSickLeaveListItemStyles";
import { LARGE_DESKTOP_BREAKPOINT } from "../../../const/sizes";
import AppointmentTimeDetails from "../../common/Appointment/AppointmentTimeDetails/AppointmentTimeDetails";
import { DateTime } from "luxon";
import { ArrowForwardIos, LocalHospitalOutlined } from "@material-ui/icons";
import { MAGENTA } from "../../../const/colors";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import DateItem from "../DateItem/DateItem";
import { useCallback } from "react";
import { SICK_LEAVES } from "../../../const/routes";
import { useHistory } from "react-router";

type Props = {
    sickLeave : IssuedZLADto;
    reditectToDetailsEnabled: boolean;
}

const PatientSickLeaveListItem = ({sickLeave, reditectToDetailsEnabled}:Props) => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();

    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(LARGE_DESKTOP_BREAKPOINT);
    const {t} = useTranslation(i18nNamespaces.L4);
    
    const sickLeaveClickHandler = useCallback(() => {
        history.push(`${SICK_LEAVES}/${sickLeave.seriesAndNumber}`);
    }, [sickLeave.seriesAndNumber]);

    
    return (
        <Card
            className={classes.container}
        >
            <Grid
                 container
                 wrap={smUp ? 'nowrap' : 'wrap'}
                 alignItems="center"
                 spacing={lgUp ? 2 : 1}
                 style={{
                     cursor: !mdUp ? 'pointer' : 'default',
                 }}
            >
                <Grid item xs={12} sm="auto">
                    <AppointmentTimeDetails
                            square={smUp}
                            isNow={false}
                            isInTheFuture={false}
                            date={DateTime.fromISO(sickLeave.createdDateTime)}
                        />
                </Grid>
                <Grid item xs={12} sm>
                    <Grid
                        container
                        wrap={'nowrap'}
                        alignItems="center"
                        spacing={lgUp ? 2 : 1}
                    >
                        <Grid item xs="auto">
                            <Avatar  alt={'photo'} className={classes.avatar}>
                                 <LocalHospitalOutlined htmlColor={MAGENTA}/>
                            </Avatar>      
                        </Grid>
                        <Grid item xs>
                        <Grid
                            container
                            direction="column"
                            style={{
                                overflowWrap: 'anywhere',
                            }}
                        >
                            <Box className={classes.type}>
                                {t('code')}: {sickLeave.icD10}
                            </Box>
                            <Box className={classes.details}>
                                {t('from')}: {DateTime.fromISO(sickLeave.unableToWorkFromDate).toFormat("dd'.'MM'.'yyyy")}
                            </Box>
                            <Box className={classes.details}>
                                {t('to')}: {DateTime.fromISO(sickLeave.unableToWorkFromDate).toFormat("dd'.'MM'.'yyyy")}
                            </Box>
                            
                        </Grid>
                        </Grid>

                        {reditectToDetailsEnabled &&
                        <Grid item xs="auto">
                            <ArrowForwardIos
                                className={classes.arrowIcon}
                                onClick={sickLeaveClickHandler}
                                style={{
                                    color: theme.palette.grey[300],
                                }}
                            />
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
};

export default PatientSickLeaveListItem;