import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingTop: desktop.contentLargerSpacing,
            padding: mobile.contentLargerSpacing,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        [theme.breakpoints.up('md')]: {
            container: {
                padding: desktop.contentLargerSpacing,
            },
        },
    }),
);

export default useStyles;
