import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';

const useConfirmationActions = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [actionLoadingError, setActionLoadingError] = useState<string>(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const actionHandler = useCallback(
         async (actionCallback: () => Promise<void>) => {
            setActionLoading(true);
            setActionLoadingError(null);
            setModalOpen(false);
            try {
                await actionCallback();
            } catch (e) {
                const errorMessage = e instanceof Error && e.message
                    ? e.message
                    : tErrors('unexpectedErrorOccurred');
                console.error(errorMessage);
                setActionLoadingError(errorMessage);
            } finally {
                setActionLoading(false);
            }
        },
        []
    );

    const openModal = useCallback(() => {
        setModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const state = useMemo(
        () => ({
            modalOpen,
            openModal,
            closeModal,
            actionLoading,
            actionLoadingError,
            actionHandler,
        }),
        [
            modalOpen,
            openModal,
            closeModal,
            actionLoading,
            actionLoadingError,
            actionHandler,
        ]
    );

    return state;
}

export default useConfirmationActions;