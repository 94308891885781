import {CSSProperties, useEffect} from 'react';
import {PayUSecureFormType} from '../payUSecureFormsContext';
import usePayUSecureForms from '../usePayUSecureForms';

const payUIdPrefix = 'payu-card-';

type PayUSecureFormProps = {
    className?: string;
    formType: PayUSecureFormType;
    onBlur?: () => void;
    onChange?: (body: payu.SecureFormChangeResponse) => void;
    onFocus?: () => void;
    style?: CSSProperties;
};

export type PayUSpecificSecureFormProps = Omit<PayUSecureFormProps, 'formType'>;

const PayUSecureForm = ({
    className,
    formType,
    onBlur,
    onChange,
    onFocus,
    style,
}: PayUSecureFormProps) => {
    const secureForms = usePayUSecureForms();

    const id = payUIdPrefix + formType;

    useEffect(() => {
        secureForms?.forms[formType].render('#' + id);

        if (onBlur) {
            secureForms?.forms[formType].on('blur', onBlur);
        }

        if (onChange) {
            secureForms?.forms[formType].on('change', onChange);
        }

        if (onFocus) {
            secureForms?.forms[formType].on('focus', onFocus);
        }

        return () => {
            secureForms?.forms[formType].remove();
            secureForms?.forms[formType].clear();
        }
    }, []);

    return <div id={id} className={className} style={style}></div>;
};

export default PayUSecureForm;
