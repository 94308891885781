import * as yup from 'yup';

const REQUIRED_STRING = 'requiredField';
const MIN_ZERO = 'minZero';

const papTestAdditionalInfoSchema = yup.object().shape({
    lastMenstruationDate: yup.date().nullable().required(REQUIRED_STRING),
    childBirthNum: yup.number().nullable().min(0, MIN_ZERO).required(REQUIRED_STRING),
    additionalInfo: yup.string()
});

export default papTestAdditionalInfoSchema;
