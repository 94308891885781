import React, {ReactNode} from 'react';
import useStyles from './ExternalLinkStyles';
import clsx from 'clsx';

interface ExternalLinkProps {
    children?: ReactNode;
    to?: string;
    className?: string;
}

const ExternalLink = (props: ExternalLinkProps) => {
    const classes = useStyles();
    return (
        <a
            className={clsx([classes.menuButton, props.className])}
            href={props.to}
        >
            {props.children}
        </a>
    );
};

export default ExternalLink;
