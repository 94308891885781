import {BuyerDataType} from '../const/buyerDataType';
import {BuyerDataDto} from '../types/buyerData';
import buyerDataValidationSchema from '../validators/buyerDataValidationSchema';
import {FormikErrors, FormikState, FormikTouched, useFormik} from 'formik';
import {ChangeEvent, FormEvent, FocusEvent} from 'react';
/* eslint @typescript-eslint/no-explicit-any: 0 */
export type BuyerDataFormConfig = {
    handleSubmit: (e?: FormEvent<HTMLFormElement>) => void;
    handleChange: {
        (e: ChangeEvent<HTMLInputElement>): void;
        <T_1 = string | ChangeEvent<HTMLInputElement>>(
            field: T_1,
        ): T_1 extends ChangeEvent<HTMLInputElement>
            ? void
            : (e: string | ChangeEvent<HTMLInputElement>) => void;
    };
    handleBlur: {
        (e: FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => Promise<void> | Promise<FormikErrors<BuyerDataDto>>;
    resetForm: (nextState?: Partial<FormikState<BuyerDataDto>>) => void;
    values: BuyerDataDto;
    errors: FormikErrors<BuyerDataDto>;
    touched: FormikTouched<BuyerDataDto>;
};

export const useBuyerDataFrom = (
    onSubmit: (buyerData: BuyerDataDto) => void,
) => {
    return useFormik<BuyerDataDto>({
        initialValues: {
            apartmentNumber: '',
            cityName: '',
            companyName: '',
            houseNumber: '',
            name: '',
            nip: '',
            postcode: '',
            streetName: '',
            surname: '',
            type: 'IndividualPerson' as BuyerDataType,
        },
        validationSchema: buyerDataValidationSchema,
        onSubmit,
    }) as BuyerDataFormConfig;
};
