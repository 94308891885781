import {createContext, useContext} from 'react';
import {DiagnosticRegionsState} from './useDiagnosticRegions';

export const DiagnosticRegionsContext = createContext<DiagnosticRegionsState>(undefined);

export const useDiagnosticRegionsContext = () => {
    const context = useContext(DiagnosticRegionsContext);

    // if `undefined`, then throw an error
    if (context === undefined) {
        throw new Error("DiagnosticRegionsContext was used outside of its Provider");
    }

    return context;
};