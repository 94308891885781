import React, {useEffect, useState} from 'react';
import {Loading, ReplaceAppointment} from '../../components';
import useQuery from '../../hooks/useQuery';
import {parseInt} from 'lodash-es';
import {MedicalServiceDto} from '../../types/timeSlot';
import medicalServiceProvider from '../../services/medicalServiceProvider';
import {Box} from '@material-ui/core';
import {APPOINTMENT_CALENDAR, USER_ACCOUNT_PAGE} from '../../const/routes';
import {useHistory} from 'react-router';

const FindOccupationalMedicineTimeSlotsPage = () => {
    const [medicalService, setMedicalService] =
        useState<MedicalServiceDto>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const query = useQuery();
    const medicalServiceId = parseInt(query.get('medicalServiceId'));
    const occupationalMedicineReferralId = parseInt(
        query.get('occupationalMedicineReferralId'),
    );
    const history = useHistory();

    const handleError = () => {
        history.replace({
            pathname: USER_ACCOUNT_PAGE + APPOINTMENT_CALENDAR,
            search: '?error=noReferralFound',
        });
    };

    const fetchMedicalService = async () => {
        try {
            setLoading(true);
            const medicalServiceData =
                await medicalServiceProvider.getMedicalService(
                    medicalServiceId,
                );
            setMedicalService(medicalServiceData);
        } catch (error) {
            console.error(error);
            handleError();
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMedicalService();
    }, []);

    if (!medicalServiceId || !occupationalMedicineReferralId) {
        handleError();
    }

    return (
        <Box>
            {medicalService && (
                <ReplaceAppointment
                    medicalService={medicalService}
                    currentDateTime={new Date().toISOString()}
                    occupationalMedicineReferralId={
                        occupationalMedicineReferralId
                    }
                />
            )}
            <Loading withBackdrop loading={loading} />
        </Box>
    );
};

export default FindOccupationalMedicineTimeSlotsPage;
