import { TERMS_AND_CONDITIONS_FILE, WITHDRAWAL_FORM_PDF_FILE, WITHDRAWAL_FORM_PDF_DOC } from './endpoints';
import {rawHttp} from './http';


class TermsAndConditionFilesProvider{
    async getTermsAndConditionsPdf(){
        return rawHttp.get<string>(`${TERMS_AND_CONDITIONS_FILE}`);
    }

    async getWithdrawalFormPdf(){
        return rawHttp.get<string>(`${WITHDRAWAL_FORM_PDF_FILE}`);
    }

    async getWithdrawalFormDoc(){
        return rawHttp.get<string>(`${WITHDRAWAL_FORM_PDF_DOC}`);
    }
}

export default new TermsAndConditionFilesProvider();