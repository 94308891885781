import React from 'react';
import {List, ListItem} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useStyles from './AppointmentNotesStyles';
import i18nNamespaces from '../../../../const/i18nNamespaces';

interface Props {
    patientReasons: string | null;
    drugMedicalRecommendations: string | null;
    referralMedicalRecommendations: string | null;
    otherMedicalRecommendations: string | null;
    medicalInterview: string | null;
}

const AppointmentNotes = ({
    patientReasons,
    drugMedicalRecommendations,
    referralMedicalRecommendations,
    otherMedicalRecommendations,
    medicalInterview,
}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    return (
        <List>
            <ListItem className={classes.listItem}>
                <h4 className={classes.header}>{t('reasonsProvided')} ?</h4>
                <p className={classes.content}>
                    {patientReasons || tCommon('noInformation')}
                </p>
            </ListItem>
            <ListItem className={classes.listItem}>
                <h4 className={classes.header}>{t('interviewWithPatient')}</h4>
                <p className={classes.content}>
                    {medicalInterview || tCommon('noInformation')}
                </p>
            </ListItem>
            <ListItem className={classes.listItem}>
                <h4 className={classes.header}>
                    {t('drugMedicalRecommendations')}
                </h4>
                <p className={classes.content}>
                    {drugMedicalRecommendations || tCommon('noInformation')}
                </p>
            </ListItem>
            <ListItem className={classes.listItem}>
                <h4 className={classes.header}>
                    {t('referralMedicalRecommendations')}
                </h4>
                <p className={classes.content}>
                    {referralMedicalRecommendations || tCommon('noInformation')}
                </p>
            </ListItem>
            <ListItem className={classes.listItem}>
                <h4 className={classes.header}>
                    {t('otherMedicalRecommendations')}
                </h4>
                <p className={classes.content}>
                    {otherMedicalRecommendations || tCommon('noInformation')}
                </p>
            </ListItem>
        </List>
    );
};

export default AppointmentNotes;
