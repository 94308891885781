import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import {WHITE} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';
import CustomButton from '../../common/Button/Button';

type PaymentSubmitProps = {
    submitText: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formSection: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
            marginBottom: mobile.contentSmallerSpacing,
        },
        submitButton: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            formSection: {
                padding: `${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
            },
            submitButton: {
                width: 406,
            },
            submitContainer: {
                textAlign: 'center',
            },
        },
    }),
);

const PaymentSubmit = ({submitText}: PaymentSubmitProps) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.formSection, classes.submitContainer)}>
            <CustomButton
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submitButton}
            >
                {submitText}
            </CustomButton>
        </Box>
    );
};

export default PaymentSubmit;
