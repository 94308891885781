import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../const/colors';
import {desktop, mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100vh',
        },
        pageContent: {
            flex: 1,
        },
        titleContainer: {
            marginBottom: desktop.contentLargerSpacing,
            width: '70%',
        },
        title: {
            fontSize: 36,
            fontWeight: 'bold',
        },
        section: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
            marginBottom: mobile.contentSmallerSpacing,
        },
        fieldLabel: {
            fontWeight: 'bold',
            marginBottom: 20,
        },
        medicPhoto: {
            width: 70,
            height: 70,
        },
        [theme.breakpoints.up('sm')]: {
            titleContainer: {
                marginBottom: 56,
                flexGrow: 1,
            },
            title: {
                fontSize: 56,
                fontWeight: 'bold',
            },
            medicPhoto: {
                width: 'auto',
                height: 166,
            },
            section: {
                padding: `32px ${desktop.contentLargerSpacing}`,
            },
            fieldEntry: {
                maxWidth: 700,
            },
            fieldLabel: {
                marginBottom: 0,
                fontSize: 20,
            },
            commentField: {
                marginTop: 24,
                maxWidth: 840,
            },
            sendButton: {
                maxWidth: 406,
                fontWeight: 'bold',
            },
        },
    }),
);

export default useStyles;
