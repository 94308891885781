import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    Attachment,
    AttachmentTypeEnum,
    UploadAttachmentData,
} from '../types/appointments';
import AppointmentsProvider from '../services/appointmentsProvider';
import {RootState} from './store';
import {mapAttachments} from './mappers/mapAttachment';
import {
    isAttachmentFromMedicInOccupationalMedicine,
    isAttachmentFromOccupationalMedicine,
    isAttachmentFromPatientInOccupationalMedicine,
} from '../utils/attachment';

const sliceName = 'appointmentAttachmentsSlice';

type statusType = 'idle' | 'loading' | 'failed';

interface AppointmentAttachmentsState {
    attachmentsMetaData: Attachment[];
    attachmentsMetaDataStatus: statusType;
    prescriptionsMetaData: Attachment[];
    prescriptionsMetaDataStatus: statusType;
    allAttachmentsMetaData: Attachment[];
    allAttachmentsMetaDataStatus: statusType;
}

const initialState: AppointmentAttachmentsState = {
    attachmentsMetaData: [],
    attachmentsMetaDataStatus: 'idle',
    prescriptionsMetaData: [],
    prescriptionsMetaDataStatus: 'idle',
    allAttachmentsMetaData: [],
    allAttachmentsMetaDataStatus: 'idle',
};

export const getAttachmentsMetaData = createAsyncThunk<
    Attachment[],
    {appointmentId: string; fetchSilently: boolean},
    unknown
>(`${sliceName}/getAttachmentsMetaData`, async ({appointmentId}) => {
    const data =
        await AppointmentsProvider.getAttachmentsMetaDataWithoutPrescriptions(
            appointmentId,
        );
    return mapAttachments(data);
});

export const getPrescriptionsMetaData = createAsyncThunk<
    Attachment[],
    {appointmentId: string; fetchSilently: boolean},
    unknown
>(`${sliceName}/getPrescriptionsMetaData`, async ({appointmentId}) => {
    const data = await AppointmentsProvider.getPrescriptionsMetaData(
        appointmentId,
    );
    return mapAttachments(data);
});

export const getAllAttachmentsMetaData = createAsyncThunk<
    Attachment[],
    {appointmentId: string; fetchSilently: boolean},
    unknown
>(`${sliceName}/getAllAttachmentsMetaData`, async ({appointmentId}) => {
    const data = await AppointmentsProvider.getAllAttachmentsMetaData(
        appointmentId,
    );
    return mapAttachments(data);
});

export const uploadAttachments = createAsyncThunk<
    void,
    {appointmentId: string; uploadData: UploadAttachmentData},
    unknown
>(`${sliceName}/uploadAttachments`, async ({appointmentId, uploadData}) => {
    await AppointmentsProvider.uploadAttachments(appointmentId, uploadData);
});

export const uploadPrescription = createAsyncThunk<
    void,
    {appointmentId: string; prescriptionUploadData: UploadAttachmentData},
    unknown
>(
    `${sliceName}/uploadPrescriptions`,
    async ({appointmentId, prescriptionUploadData}, thunkAPI) => {
        await AppointmentsProvider.uploadAttachments(
            appointmentId,
            prescriptionUploadData,
        );
        await thunkAPI.dispatch(
            getPrescriptionsMetaData({appointmentId, fetchSilently: true}),
        );
    },
);

export const removeAttachment = createAsyncThunk<
    void,
    {appointmentId: string; attachmentId: string},
    unknown
>(`${sliceName}/removeAttachment`, async ({appointmentId, attachmentId}) => {
    await AppointmentsProvider.removeAttachment(appointmentId, attachmentId);
});

export const removePrescription = createAsyncThunk<
    void,
    {appointmentId: string; prescriptionId: string},
    unknown
>(
    `${sliceName}/removePrescription`,
    async ({appointmentId, prescriptionId}, thunkAPI) => {
        await AppointmentsProvider.removeAttachment(
            appointmentId,
            prescriptionId,
        );
        await thunkAPI.dispatch(
            getPrescriptionsMetaData({appointmentId, fetchSilently: true}),
        );
    },
);

export const downloadAttachment = createAsyncThunk<
    Attachment,
    {appointmentId: string; attachmentId: string},
    unknown
>(`${sliceName}/downloadAttachment`, async ({appointmentId, attachmentId}) => {
    return await AppointmentsProvider.downloadAttachment(
        appointmentId,
        attachmentId,
    );
});

const appointmentAttachmentsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        clearAttachmentsMetaData: state => {
            state.attachmentsMetaData = [];
        },
        clearPrescriptionsMetaData: state => {
            state.prescriptionsMetaData = [];
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAttachmentsMetaData.pending, (state, action) => {
                state.attachmentsMetaDataStatus = action.meta.arg.fetchSilently
                    ? state.attachmentsMetaDataStatus
                    : 'loading';
            })
            .addCase(getAttachmentsMetaData.fulfilled, (state, action) => {
                state.attachmentsMetaData = action.payload;
                state.attachmentsMetaDataStatus = 'idle';
            })
            .addCase(getPrescriptionsMetaData.pending, (state, action) => {
                state.prescriptionsMetaDataStatus = action.meta.arg
                    .fetchSilently
                    ? state.prescriptionsMetaDataStatus
                    : 'loading';
            })
            .addCase(getPrescriptionsMetaData.fulfilled, (state, action) => {
                state.prescriptionsMetaData = action.payload;
                state.prescriptionsMetaDataStatus = 'idle';
            })
            .addCase(getAllAttachmentsMetaData.pending, (state, action) => {
                state.allAttachmentsMetaDataStatus = action.meta.arg
                    .fetchSilently
                    ? state.allAttachmentsMetaDataStatus
                    : 'loading';
            })
            .addCase(getAllAttachmentsMetaData.fulfilled, (state, action) => {
                state.allAttachmentsMetaData = action.payload;
                state.allAttachmentsMetaDataStatus = 'idle';
            });
    },
});

export const {clearAttachmentsMetaData, clearPrescriptionsMetaData} =
    appointmentAttachmentsSlice.actions;

export const selectAttachmentsMetaData = (state: RootState) =>
    state.appointmentAttachments.attachmentsMetaData;
export const selectAttachmentsMetaDataStatus = (state: RootState) =>
    state.appointmentAttachments.attachmentsMetaDataStatus;

export const selectPrescriptionsMetaData = (state: RootState) =>
    state.appointmentAttachments.prescriptionsMetaData;
export const selectPrescriptionsMetaDataStatus = (state: RootState) =>
    state.appointmentAttachments.prescriptionsMetaDataStatus;

export const selectAllAttachmentsMetaData = (state: RootState) =>
    state.appointmentAttachments.allAttachmentsMetaData;
export const selectAllAttachmentsMetaDataStatus = (state: RootState) =>
    state.appointmentAttachments.allAttachmentsMetaDataStatus;

export const selectOccupationalMedicineAttachmentsMetaData = (
    state: RootState,
) =>
    state.appointmentAttachments.attachmentsMetaData.filter(
        isAttachmentFromOccupationalMedicine,
    );

export const selectOMAttachmentsFromPatientMetaData = (state: RootState) =>
    state.appointmentAttachments.attachmentsMetaData.filter(
        isAttachmentFromPatientInOccupationalMedicine,
    );

export const selectOMAttachmentsFromMedicMetaData = (state: RootState) =>
    state.appointmentAttachments.attachmentsMetaData.filter(
        isAttachmentFromMedicInOccupationalMedicine,
    );
export const selectIsOMReferralInAttachments = (state: RootState) =>
    state.appointmentAttachments.allAttachmentsMetaData.some(
        attachment =>
            attachment.attachmentType === AttachmentTypeEnum.OMReferral,
    );

export const selectIsOMHealthDeclarationInAttachments = (state: RootState) =>
    state.appointmentAttachments.allAttachmentsMetaData.some(
        attachment =>
            attachment.attachmentType ===
            AttachmentTypeEnum.OMHealthDeclaration,
    );

export const selectOMPreventiveExaminationCardAttachmentsMetaData = (
    state: RootState,
) =>
    state.appointmentAttachments.allAttachmentsMetaData.filter(
        attachment =>
            attachment.attachmentType ===
            AttachmentTypeEnum.OMPreventiveExaminationCard,
    );

export const selectOMCertificateAttachmentsMetaData = (state: RootState) =>
    state.appointmentAttachments.allAttachmentsMetaData.filter(
        attachment =>
            attachment.attachmentType === AttachmentTypeEnum.OMCertificate,
    );

export default appointmentAttachmentsSlice.reducer;
