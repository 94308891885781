import {ChangeEvent, useEffect, useState} from 'react';
import {Box, Grid, TextField, useMediaQuery, useTheme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import useStyles from './MedicDescriptionStyles';
import useTileStyles from '../common/tileCommonStyles';
import {hasMinimumLength} from '../../../utils/string';
import {isNotNullOrUndefined, isNotUndefined} from '../../../utils/lang';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectMedicDetails, 
    updateMedicBio, 
    updateSaveEnabled, 
    updateTranslation,
} from '../../../store/medicProfileSlice';
import clsx from 'clsx';
import { LanguageDto } from '../../../types/language';
import { defaultDatabaseLanguage, getTranslation } from '../../../utils/translation';

const DESCRIPTION_MIN_LENGTH = 50;

interface Props{
    selectedLanguage?: LanguageDto;
}

const MedicDescription = ({selectedLanguage} : Props) => {
    const classes = useStyles();
    const tileClasses = useTileStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.MEDIC);
    const dispatch = useAppDispatch();

    const medicDetails = useAppSelector(selectMedicDetails);
    const medicDetailsInitialized = isNotUndefined(medicDetails);
    
    const [textFieldsInitialized, setTextFieldsInitialized] = useState<boolean>(false);
    

    const [descriptionTextFieldValue, setDescriptionTextFieldValue] =
        useState('');
    const [descriptionTextFieldTouched, setDescriptionTextFieldTouched] =
        useState(false);
    const [educationTextFieldValue, setEducationTextFieldValue] =
        useState('')
    const [experienceTextFieldValue, setExperienceTextFieldValue] =
        useState('')
    const [healsDiseasesTextFieldValue, setHealsDiseasesTextFieldValue] =
        useState('')
    const [treatsPatientsInLanguagesTextFieldValue, setTreatsPatientsInLanguagesTextFieldValue] =
        useState('')
    const [treatsPatientsOfTheAgeTextFielsValue, setTreatsPatientsOfTheAgeTextFieldValue] = 
        useState('')

    const setTextFieldValue  = (defaultValue:string, key: string) => {
        const translation = medicDetails.translations.find(x=> 
            x.key=== key && x.languageCode === selectedLanguage.code);

        if(isNotNullOrUndefined(translation)){
            return translation.value;
        }
        
        else{
            if (selectedLanguage.code !== defaultDatabaseLanguage)
                return '';
            
            else
                return defaultValue;
        }    
    }


    useEffect(() => {
        if (medicDetailsInitialized && selectedLanguage) {
                const bio = setTextFieldValue(medicDetails?.bio, "bio");    
                setDescriptionTextFieldValue(bio);
                dispatch(updateTranslation({languageCode: selectedLanguage.code, languageId: selectedLanguage.id, value: bio, key: "bio"}));

                setDescriptionTextFieldTouched(true);
                
                if (selectedLanguage.code === defaultDatabaseLanguage)
                    dispatch(updateSaveEnabled(descriptionHasMinimumLength(bio)));
                
        }
    }, [medicDetailsInitialized, selectedLanguage]);

    const descriptionHasMinimumLength = (value: string) =>
        hasMinimumLength(DESCRIPTION_MIN_LENGTH, value);

    const handleDescriptionValueChange = (
        event: ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const newValue = event.target.value;
        setDescriptionTextFieldValue(newValue);

        if (!descriptionTextFieldTouched) {
            setDescriptionTextFieldTouched(true);
        }

        if (selectedLanguage.code === defaultDatabaseLanguage)
            dispatch(updateMedicBio(newValue.trim()));
        dispatch(updateTranslation({languageCode: selectedLanguage.code, languageId: selectedLanguage.id, value: newValue.trim(), key: "bio"}));
       
        if (selectedLanguage.code === defaultDatabaseLanguage)
            dispatch(updateSaveEnabled(descriptionHasMinimumLength(newValue.trim())));
    };

    const displayNotEnoughCharactersMessage = () =>
        descriptionTextFieldTouched &&
        !descriptionHasMinimumLength(descriptionTextFieldValue) &&
        selectedLanguage.code === defaultDatabaseLanguage;

    const getNumberOfCharactersTyped = () => {
        return `[${
            descriptionTextFieldValue.trim().length
        }/${DESCRIPTION_MIN_LENGTH}]`;
    };

    return (
        <Box className={tileClasses.root}>
            <Grid container alignItems={'center'}>
                <h3
                    className={clsx(tileClasses.headerText, classes.headerText)}
                >
                    {commonT('descriptionOfYou')}
                </h3>
                {displayNotEnoughCharactersMessage() && (
                    <Box
                        className={classes.notEnoughNumberOfCharacters}
                        component={'span'}
                    >
                        {`${commonT(
                            'notEnoughNumberOfCharacters',
                        )} ${getNumberOfCharactersTyped()}`}
                    </Box>
                )}

            </Grid>
            {
                <TextField
                    className={classes.textField}
                    value={descriptionTextFieldValue || ''}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={isMobile ? 13 : 7}
                    onChange={handleDescriptionValueChange}
                    InputProps={{
                        style: {
                            height: 'auto',
                        },                      
                    }}
                    error={!descriptionHasMinimumLength(descriptionTextFieldValue) && selectedLanguage && selectedLanguage.code === "PL"}
                    
                />
            }

        </Box>
    );
};

export default MedicDescription;
