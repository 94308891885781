import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../const/colors';
import {desktop, mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {minHeight: '100vh'},
        pageContent: {
            backgroundColor: WHITE,
            flex: 1,
            padding: mobile.contentLargerSpacing,
        },
        image: {
            marginBottom: 48,
        },
        title: {fontSize: 36, fontWeight: 'bold', marginBottom: 40},
        underTitle: {marginBottom: 24},
        [theme.breakpoints.up('sm')]: {
            pageContent: {
                padding: desktop.contentLargerSpacing,
                textAlign: 'center',
            },
            image: {
                height: 264,
            },
            underTitle: {marginBottom: 32},
            button: {
                fontWeight: 'bold',
                maxWidth: 278,
            },
        },
    }),
);

export default useStyles;
