import {createStyles, makeStyles} from '@material-ui/core';
import {PRIMARY, WHITE} from '../../../../const/colors';

const useStyles = makeStyles((theme) =>
    createStyles({
        headerContainer: {
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '2px',
            backgroundColor: WHITE,
            padding: 16,
            [theme.breakpoints.up('md')]: {
                padding: '24px 47px',
            }
        },
        headerContainerLeftItem: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10,
            marginRight: 50,
        },
        headerContainerRightItem: {
            display: 'flex',
        },
        patientNameAndStatusesContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        patientName: {
            margin: '0 24px 0 0',
            fontSize: '24px',
            lineHeight: '32px',
        },
        stateLabel: {
            '&:not(:last-child)': {
                marginRight: 8,
            }
        },
        appointmentDetails: {
            margin: '8px 0 0',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            color: PRIMARY,
        },
        detailsSeparationDot: {
            margin: '0 6px',
            height: '3px',
            width: '3px',
            borderRadius: '50%',
            backgroundColor: PRIMARY,
        },
        actionButton: {
            padding: '0 48px',
            lineHeight: 1.35,
        },
    }),
);

export default useStyles;
