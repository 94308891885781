import {ICD10Code, ICD10CodeTranslated} from '../types/icd10';
import {checkIfLanguageExists, getPreparedLang} from './lang';

export const getIcd10CodeNameForProvidedLanguage = (
    icd10Code: ICD10Code,
    language: string,
) => {
    const lang = getPreparedLang(language);

    if (!checkIfLanguageExists(lang)) {
        return icd10Code.text['pl'];
    }

    return icd10Code.text[lang as keyof typeof icd10Code.text];
};

export const mapICD10ForAutocomplete = (
    icd10Code: ICD10Code,
    language:string
) => ({...icd10Code, value: getIcd10CodeNameForProvidedLanguage(icd10Code, language)});
