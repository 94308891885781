import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    sendPaymentDetailsAndGetUrlToPayU
} from '../../../services/diagnostic/orderHeadersProvider';
import {BuyerDataDto} from '../../../types/buyerData';

export interface OrderMedicalTestPayment {
    readonly sendPaymentDetailsLoading: boolean;
    readonly sendPaymentDetailsError: string;
    readonly sendPaymentDetails: (referralId: string, buyerData: BuyerDataDto) => Promise<string>;
}

const useOrderMedicalTestPayment = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(null);

    const {t: tErrors} = useTranslation(i18nNamespaces.ERRORS);

    const sendPaymentDetails = useCallback( async (referralId: string, buyerData: BuyerDataDto) => {
        setLoading(true);
        try {
            const {data} = await sendPaymentDetailsAndGetUrlToPayU(referralId, buyerData);
            if (!data.paymentUrl.startsWith('https')) {
                throw new Error('sendingDataToServerError');
            }
            return data.paymentUrl;
        } catch (error) {
            setError(tErrors('sendingDataToServerError'));
            throw error;
        } finally {
            setLoading(false);
        }
    }, []);

    const state = useMemo<OrderMedicalTestPayment>(
        () => ({
            sendPaymentDetailsLoading: loading,
            sendPaymentDetailsError: error,
            sendPaymentDetails
        }),
        [loading, error, sendPaymentDetails]
    );

    return state;
}

export default useOrderMedicalTestPayment;