import {makeStyles, Theme} from "@material-ui/core";
import {WHITE} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 16,
        backgroundColor: WHITE,
        [theme.breakpoints.up('md')]: {
            padding: 24,
        }
    },
    headerText: {
        fontSize: 18,
        lineHeight: '24px',
        margin: 0,
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        }
    },
}));

export default useStyles;
