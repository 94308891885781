import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {BACKGROUND_PRIMARY, WHITE} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section: {
            padding: '16px',
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                padding: '16px 47px',
            },
        },
        sectionTopOffset: {
            marginTop: '16px',
            [theme.breakpoints.up('md')]: {
                marginTop: '8px',
            },
        },
        sectionBottomOffset: {
            border: '1px solid red',
            marginBottom: '16px',
            [theme.breakpoints.up('md')]: {
                marginBottom: '8px',
            },
        },
        consultationsHistorySectionHeaderBox: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        },
        sectionHeader: {
            margin: '0',
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 700,
            [theme.breakpoints.up('md')]: {
                fontSize: '20px',
                lineHeight: '32px',
            },
        },
        sectionContent: {
            width: '100%',
            paddingTop: '16px',
            borderTop: '1px solid rgba(0, 0, 0, .125)',
            marginTop: '1px',
        },
        autocompleteWrapper: {
            margin: '24px 0 0 0',
            [theme.breakpoints.up('md')]: {
                margin: '0 15px 0 15px',
                width: '400px',
            },
            [theme.breakpoints.up('lg')]: {
                margin: '0 75px 0 0',
            },
        },
        historicalAppointmentsContent: {
            marginTop: '1px',
            width: '100%',
        },
        referralContainer: {
            borderTop: `8px solid ${BACKGROUND_PRIMARY}`,
            [theme.breakpoints.up('md')]: {
                marginTop: '1px',
                width: '100%',
                borderTop: `1px solid ${BACKGROUND_PRIMARY}`,
            },
        },
        showMoreHistoricalAppointments: {
            borderTop: `8px solid ${BACKGROUND_PRIMARY}`,
            padding: '16px 0',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                borderTop: `1px solid ${BACKGROUND_PRIMARY}`,
                padding: '21px 0',
                margin: '0 47px',
            },
        },
        historicalAppointmentsNotFound: {
            borderTop: `1px solid ${BACKGROUND_PRIMARY}`,
            margin: '0 16px',
            padding: '16px 0',
            [theme.breakpoints.up('md')]: {
                margin: '0 47px',
            },
        },
    }),
);

export default useStyles;
