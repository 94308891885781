import * as yup from 'yup';
import {PASSWORD_REGEX} from './Register';

const REQUIRED_STRING = 'requiredField';
const MIN_CHARS_REQUIRED_STRING = 'minCharsRequired';

export const forgotPasswordEmailSchema = yup.object().shape({
    email: yup.string().email('wrongEmail').required(REQUIRED_STRING),
});

export const forgotPasswordSchema = yup.object().shape({
    code: yup.string().required(REQUIRED_STRING),
    password: yup
        .string()
        .min(6, MIN_CHARS_REQUIRED_STRING)
        .required(REQUIRED_STRING)
        .matches(PASSWORD_REGEX, 'passwordRules'),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'passwordsMustBeTheSame')
        .required(REQUIRED_STRING),
});

export const changePasswordSchema = yup.object().shape({
    oldPassword: yup
        .string()
        .min(6, MIN_CHARS_REQUIRED_STRING)
        .required(REQUIRED_STRING)
        .matches(PASSWORD_REGEX, 'passwordRules'),
    newPassword: yup
        .string()
        .min(6, MIN_CHARS_REQUIRED_STRING)
        .required(REQUIRED_STRING)
        .matches(PASSWORD_REGEX, 'passwordRules'),
    repeatNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'passwordsMustBeTheSame')
        .required(REQUIRED_STRING),
});

export const forcedPasswordChangeSchema = yup.object().shape({
    newPassword: yup
        .string()
        .min(6, MIN_CHARS_REQUIRED_STRING)
        .required(REQUIRED_STRING)
        .matches(PASSWORD_REGEX, 'passwordRules'),
    repeatNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'passwordsMustBeTheSame')
        .required(REQUIRED_STRING),
});
