import {Box, Button, Grid} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {CustomModal} from '../../components';
import {MEDIC_CALENDAR, USER_ACCOUNT_PAGE} from '../../const/routes';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {medicAsPatientSpecified, selectMedicAsPatient} from '../../store/auth';
import useStyles from './SelectRolePageStyles';
import i18nNamespaces from '../../const/i18nNamespaces';
import {setMedicAsPatient} from '../../storage/storage';

const SelectRolePage = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const medicAsPatient = useAppSelector(selectMedicAsPatient);
    const {t} = useTranslation(i18nNamespaces.COMMON);

    useEffect(() => {
        if (medicAsPatient !== null) {
            medicAsPatient
                ? history.push(USER_ACCOUNT_PAGE)
                : history.push(MEDIC_CALENDAR);
        }
    }, [history, medicAsPatient]);

    const onSelectRole = (medicAsPatient: boolean) => {
        dispatch(medicAsPatientSpecified(medicAsPatient));
        setMedicAsPatient(medicAsPatient);
    };

    return (
        <Box>
            <CustomModal open>
                <Grid container direction="column" alignItems="center">
                    <Box className={classes.title}>{t('selectRoleTitle')}</Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        className={classes.button}
                        onClick={() => onSelectRole(false)}
                    >
                        {t('asAMedic')}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        disableElevation
                        className={classes.button}
                        onClick={() => onSelectRole(true)}
                    >
                        {t('asAPatient')}
                    </Button>
                </Grid>
            </CustomModal>
        </Box>
    );
};

export default SelectRolePage;
