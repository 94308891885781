import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Appointment, Attachment} from '../types/appointments';
import AppointmentsProvider from '../services/appointmentsProvider';
import mapAppointment from './mappers/mapAppointment';
import {RootState} from './store';
import {mapAttachments} from "./mappers/mapAttachment";

const sliceName = 'userAppointmentsHistory';

interface HistoricalAppointment {
    appointment: Appointment;
    attachmentsMetaData: Attachment[];
}

interface AppointmentDetailsState {
    appointments: HistoricalAppointment[];
    appointmentsPage: number | null;
    appointmentsCount: number | null;
    appointmentsPageSize: number;
    status: 'idle' | 'loading' | 'failed';
    medicalServicesAsFilterTypes: {name: string; id: number}[];
    medicalServicesAsFilterTypesSelectedId: number | null;
    medicalServicesAsFilterTypesStatus: 'idle' | 'loading' | 'failed',
}

const initialState: AppointmentDetailsState = {
    appointments: [] as HistoricalAppointment[],
    appointmentsPage: null,
    appointmentsCount: null,
    appointmentsPageSize: 4,
    status: 'idle',
    medicalServicesAsFilterTypes: [],
    medicalServicesAsFilterTypesSelectedId: null,
    medicalServicesAsFilterTypesStatus: 'idle',
};

export const fetchHistoricalAppointmentsFilterTypes =
    createAsyncThunk<{name: string; id: number}[], void, unknown>(
        `${sliceName}/fetchFilterTypes`,
        async () => {
            const medicalServices = await AppointmentsProvider.getMedicalServices();
            const filterTypes = medicalServices.map(medicalService => ({
                id: medicalService.id,
                name: medicalService.name,
            }));
            return filterTypes;
        }
    )

interface FetchHistoricalAppointmentsProps {
    page: number;
    userId: string;
    childId?: number;
    appointmentId: string;
    medicalServiceId?: number;
}

export const fetchPageOfUserHistoricalAppointments =
    createAsyncThunk<
        {count: number, page: number, historicalAppointments: HistoricalAppointment[]},
        FetchHistoricalAppointmentsProps,
        {state: RootState}
    >(
        `${sliceName}/fetchPageOfUserHistoricalAppointments`,
        async (props, thunkAPI) => {
            const pageSize = thunkAPI.getState().userAppointmentsHistory.appointmentsPageSize;
            const response = await AppointmentsProvider
                    .getPageOfUserHistoricalAppointments({...props, pageSize})

            const historicalAppointments = response.results.map(
                async (appointmentDto) => {
                    const attachmentsMetaData = await AppointmentsProvider.getAllAttachmentsMetaData(appointmentDto.id)
                    return {
                        appointment: mapAppointment(appointmentDto),
                        attachmentsMetaData: mapAttachments(attachmentsMetaData)
                    }
                }
            );

            return {
                count: response.count,
                page: response.page,
                historicalAppointments: await Promise.all(historicalAppointments),
            }
        }
    )

const userAppointmentsHistorySlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        clearHistoricalAppointments: state => {
            state.appointments = [];
            state.appointmentsPage = null;
            state.appointmentsCount = null;
        },
        setHistoricalAppointmentsFilterSelectedIndex: (state, action: PayloadAction<number | null>) => {
            state.medicalServicesAsFilterTypesSelectedId = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchPageOfUserHistoricalAppointments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPageOfUserHistoricalAppointments.fulfilled, (state, action) => {
                state.appointments = action.payload.historicalAppointments;
                state.appointmentsPage = action.payload.page;
                state.appointmentsCount = action.payload.count;
                state.status = 'idle';
            })
            .addCase(fetchHistoricalAppointmentsFilterTypes.pending, (state) => {
                state.medicalServicesAsFilterTypesStatus = 'loading';
            })
            .addCase(fetchHistoricalAppointmentsFilterTypes.fulfilled, (state, action) => {
                state.medicalServicesAsFilterTypes = action.payload;
                state.medicalServicesAsFilterTypesStatus = 'idle';
            })
    },
});

export const {clearHistoricalAppointments, setHistoricalAppointmentsFilterSelectedIndex} =
    userAppointmentsHistorySlice.actions;

export const selectHistoricalAppointments = (state: RootState) =>
    state.userAppointmentsHistory.appointments;

export const selectHistoricalAppointmentsStatus = (state: RootState) =>
    state.userAppointmentsHistory.status;

export const selectHistoricalAppointmentsPage = (state: RootState) =>
    state.userAppointmentsHistory.appointmentsPage

export const selectHistoricalAppointmentsCount = (state: RootState) =>
    state.userAppointmentsHistory.appointmentsCount;

export const selectHistoricalAppointmentsPageSize = (state: RootState) =>
    state.userAppointmentsHistory.appointmentsPageSize;

export const selectHistoricalAppointmentsFilterTypes = (state: RootState) =>
    state.userAppointmentsHistory.medicalServicesAsFilterTypes;

export const selectHistoricalAppointmentsFilterTypesStatus = (state: RootState) =>
    state.userAppointmentsHistory.medicalServicesAsFilterTypesStatus;

export const selectHistoricalAppointmentsFilterSelectedId = (state: RootState) =>
    state.userAppointmentsHistory.medicalServicesAsFilterTypesSelectedId;

export default userAppointmentsHistorySlice.reducer;
