
import * as yup from 'yup';

const REQUIRED_STRING = 'requiredField';


const additionalPhysicalTestsValidationSchema = (commonT:any) => yup.object({
    skin:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    skull:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    lymphNodes:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    nose:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    oropharyngealCavity:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    neck:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    chest:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    lungs:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    cardiovascularSystem:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    abdominalCavity:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    urinaryGenitalSystem:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    locomotorSystem:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    theNervousSystem:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    }),
    mentalCondition:yup.object().shape({
        pathologyDescription: yup.string().when('isPathology', {
            is:true,
            then: yup.string().required(commonT(REQUIRED_STRING)),
        })
    })
});

export default additionalPhysicalTestsValidationSchema;