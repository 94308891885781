import {FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useParams} from 'react-router';
import {
    PaymentPage,
    SubscriptionPaymentCard,
    SubscriptionPaymentPrice,
} from '../../components';
import {usePayUSecureForms} from '../../components/common/payUSecureForms';
import i18nNamespaces from '../../const/i18nNamespaces';
import {
    ERROR,
    SUBSCRIPTION_PAYMENT_CONTINUE,
    SUBSCRIPTION_PAYMENT_FAILED_PAGE,
} from '../../const/routes';
import {useBuyerDataFrom} from '../../hooks/useBuyerDataFrom';
import useQuery from '../../hooks/useQuery';
import individualPackagesProvider from '../../services/individualPackagesProvider';
import subscriptionsProvider from '../../services/subscriptionsProvider';
import {BuyerDataDto} from '../../types/buyerData';
import {IndividualPackageDto} from '../../types/individualPackages';
import {makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../const/colors';
import {desktop, mobile} from '../../const/sizes';
import {ResellerCodeValidationDto} from '../../types/subscriptions';
import axios from 'axios';
import {gtmService} from '../../services/gtm/gtmService';
import {useAppSelector} from '../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../store/auth';

const useStyles = makeStyles((theme: Theme) => ({
    cardSection: {
        backgroundColor: WHITE,
        padding: mobile.contentLargerSpacing,
        marginBottom: mobile.contentSmallerSpacing,
    },
    [theme.breakpoints.up('sm')]: {
        cardSection: {
            padding: `${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
        },
    },
}));

const SubscriptionPaymentPage = () => {
    const classes = useStyles();
    const authUserData = useAppSelector(selectAuthUserData);
    const {individualPackageDraftId: individualPackageDraftIdParam} =
        useParams<{individualPackageDraftId: string}>();
    const individualPackageDraftId = parseInt(individualPackageDraftIdParam);

    const query = useQuery();
    const cancelUrl = query.get('cancelUrl');

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [individualPackage, setIndividualPackage] =
        useState<IndividualPackageDto>(null);
    const [requiredCheckForced, setRequiredCheckForced] =
        useState<boolean>(false);
    const [resellerData, setResellerData] =
        useState<ResellerCodeValidationDto | null>(null);
    const [resellerCode, setResellerCode] = useState<string | null>(null);
    const [resellerCodeError, setResellerCodeError] = useState<string | null>(
        null,
    );

    useEffect(() => {
        setIsLoading(true);
        individualPackagesProvider
            .getIndividualPackageInstance(individualPackageDraftId)
            .then(individualPackageInstance => {
                setIndividualPackage(individualPackageInstance);

                setIsLoading(false);
            });
    }, [setIsLoading, individualPackageDraftId]);

    const onCancel = () => {
        if (cancelUrl) {
            history.push(decodeURIComponent(cancelUrl));
        } else {
            history.push('/');
        }
    };

    const triggerConversionTag = () => {
        gtmService.triggerSubscriptionConversion(
            individualPackage.id,
            individualPackage.price,
        );
    };

    const {tokenize, sendCvv} = usePayUSecureForms();

    const createSubscription = async (buyerData: BuyerDataDto) => {
        try {
            setIsLoading(true);

            // Tokenize the payment card.
            const tokenizationResult = await tokenize('MULTI');

            if (tokenizationResult.status !== 'SUCCESS') {
                setIsLoading(false);

                return;
            }

            const {
                body: {token},
            } = tokenizationResult;

            // Create a subscription and send a payment order to PayU.

            const {subscriptionId, paymentContinuation} =
                await subscriptionsProvider.createSubscription(
                    individualPackageDraftId,
                    {buyerData, cardToken: token, resellerCode},
                );

            const paymentContinuePath = generatePath(
                SUBSCRIPTION_PAYMENT_CONTINUE,
                {subscriptionId},
            );

            // If there is no paymentContinuation, then the payment is finished.
            if (!paymentContinuation) {
                history.push(paymentContinuePath);

                setIsLoading(false);

                return;
            }

            const {type, paymentUrl} = paymentContinuation;

            // Go to the payment URL in case an additional 3DS verification is required.
            if (type == 'CONTINUE_3DS') {
                window.location.href = paymentUrl;
            }
            // Retrieve the card CVV code if it is required by PayU.
            else if (type == 'CONTINUE_CVV') {
                const cvvResult = await sendCvv(paymentUrl);

                if (cvvResult.status == 'SUCCESS') {
                    history.push(paymentContinuePath);
                } else {
                    history.push(paymentUrl + '?error=CVV_FAILURE');
                }
            }
            // Payment continuation with an unknown type. Go to the error page.
            else {
                history.push(ERROR);
            }

            triggerConversionTag();
        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
                history.push({
                    pathname: SUBSCRIPTION_PAYMENT_FAILED_PAGE,
                    search: `?reason=${error?.response?.data[0]}`,
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmit = (buyerData: BuyerDataDto) => createSubscription(buyerData);

    const buyerDataFormConfig = useBuyerDataFrom(onSubmit);

    const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
        // Force payment card form fields validation.
        setRequiredCheckForced(true);
        // Run standard Formik processing.
        buyerDataFormConfig.handleSubmit(e);
    };

    const handleUseResellerCode = async (code: string) => {
        try {
            const {data} = await subscriptionsProvider.validateResellerCode(
                individualPackageDraftId,
                code,
            );
            setResellerData(data);
            setResellerCode(code);
        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
                setResellerCodeError(error.response.data);
            }
        }
    };

    const {t} = useTranslation(i18nNamespaces.SUBSCRIPTION_PAYMENT);

    const subscriptionPrice = individualPackage && (
        <SubscriptionPaymentPrice
            individualPackageName={individualPackage.name}
            price={individualPackage.price}
        />
    );

    const paymentCardSection = (
        <SubscriptionPaymentCard
            requiredCheckForced={requiredCheckForced}
            className={classes.cardSection}
        />
    );

    return (
        <>
            <PaymentPage
                additionalSection={paymentCardSection}
                buyerDataFormConfig={buyerDataFormConfig}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                onCancel={onCancel}
                paymentInfo={t('subscriptionInfo')}
                priceSection={subscriptionPrice}
                title={t('paymentTitle')}
                submitText={t('pay')}
                isResellerCodeRequired={
                    individualPackage !== null
                        ? individualPackage.isResellerCodeRequired
                        : true
                }
                resellerData={resellerData}
                onUseResellerCode={handleUseResellerCode}
                resellerCode={resellerCode}
                resellerCodeError={resellerCodeError}
            />
        </>
    );
};

export default SubscriptionPaymentPage;
