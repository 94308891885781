import {Box, Grid} from '@material-ui/core';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Paging} from '../..';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchFinishedAppointments,
    pageSpecified,
    selectFinishedAppointmentIds,
    selectFinishedAppointmentsCount,
    selectFinishedAppointmentsPage,
    selectFinishedAppointmentsSize,
} from '../../../store/patientCalendar/finishedAppointmentsSlice';
import useAppointmentsStyles from '../appointmentsCalendarSectionStyles';
import FinishedAppointment from '../FinishedAppointment/FinishedAppointment';
import useStyles from './FinishedAppointmentsStyles';
import {fetchUpcomingAppointments} from "../../../store/patientCalendar/upcomingAppointmentsSlice";
import {REFRESH_TIME} from "../../../const/commonConstants";

const FinishedAppointments = () => {
    const dispatch = useAppDispatch();

    const finishedAppointmentIds = useAppSelector(selectFinishedAppointmentIds);
    const finishedAppointmentsPage = useAppSelector(
        selectFinishedAppointmentsPage,
    );
    const finishedAppointmentsSize = useAppSelector(
        selectFinishedAppointmentsSize,
    );
    const finishedAppointmentsCount = useAppSelector(
        selectFinishedAppointmentsCount,
    );

    useEffect(() => {
        dispatch(fetchFinishedAppointments({fetchSilently: true}));
        const intervalId = setInterval(
            () => {
                dispatch(
                    fetchFinishedAppointments({fetchSilently: true})
                );
            },
            REFRESH_TIME
        );

        return () => {
            clearInterval(intervalId);
        };
    }, [dispatch]);

    const pageChangeHandler = (page: number) => {
        dispatch(pageSpecified(page));
        dispatch(fetchFinishedAppointments({fetchSilently: false}));
    };

    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);

    const appointmentsClasses = useAppointmentsStyles();
    const classes = useStyles();

    return (
        <Box className={appointmentsClasses.appointmentWrapper}>
            <Box className={appointmentsClasses.boxTitle}>
                {t('finishedAppointments')}
            </Box>
            {finishedAppointmentIds.map(appointmentId => (
                <FinishedAppointment
                    key={appointmentId}
                    finishedAppointmentId={appointmentId as number}
                />
            ))}
            {finishedAppointmentsSize < finishedAppointmentsCount && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.pagingWrapper}
                >
                    <Paging
                        page={finishedAppointmentsPage}
                        size={finishedAppointmentsSize}
                        count={finishedAppointmentsCount}
                        onChange={pageChangeHandler}
                    />
                </Grid>
            )}
        </Box>
    );
};

export default FinishedAppointments;
