import React from 'react';
import {createStyles, FormGroup, makeStyles, Theme} from '@material-ui/core';
import {Label, PhoneNumberField} from '../../../index';
import {t} from 'i18next';
import {desktop} from '../../../../const/sizes';
import {FormikProps} from 'formik';
import {Props} from 'react-phone-number-input';

const useStyles = makeStyles(() =>
    createStyles({
        formGroup: {
            marginTop: desktop.contentSmallerSpacing,
        },
    }),
);

interface FormikPhoneNumberFieldProps extends Omit<Props, 'onChange'> {
    form: FormikProps<any>;
    label: string;
    fieldName: string;
    fullWidth?: boolean;
}

const FormikPhoneNumberField = ({
    form,
    label,
    fieldName,
    fullWidth,
    ...props
}: FormikPhoneNumberFieldProps) => {
    const classes = useStyles();

    const onChange = (value: string) =>
        form.handleChange({
            target: {
                value,
                id: fieldName,
                name: fieldName,
            },
        });

    return (
        <FormGroup className={classes.formGroup}>
            <Label htmlFor={fieldName}>{label}</Label>
            <PhoneNumberField
                id={fieldName}
                name={fieldName}
                onChange={onChange}
                onBlur={form.handleBlur}
                value={props.value || form.values[fieldName]}
                helperText={form.touched[fieldName] && form.errors[fieldName]}
                error={form.touched[fieldName] && !!form.errors[fieldName]}
                defaultCountry="PL"
                fullWidth={fullWidth}
                {...props}
            />
        </FormGroup>
    );
};

export default FormikPhoneNumberField;
