import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {WHITE} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        marginBottom: '16px',
    },
    sectionWrapper: {
        padding: '16px',
        backgroundColor: WHITE,
        '&:not(:first-child)': {
            marginTop: '8px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '16px 47px'
        },
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    linkOffsetWrapper: {
        margin: '24px 0 0',
        [theme.breakpoints.up('md')]: {
            margin: '0 0 0 58px'
        },
    },
}));

export default useStyles;