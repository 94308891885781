import React, {memo, useCallback, useEffect} from 'react';
import {Box, Snackbar, Theme, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Alert} from '@material-ui/lab';
import i18nNamespaces from '../../../const/i18nNamespaces';
import Layout from '../../../components/Layout/Layout';
import Header from './components/Header';
import {Container, Loading, LogoAndCloseTopBar} from '../../../components';
import InvoiceInformationCollect from './components/InvoiceInformationCollect';
import SectionHeader from './components/SectionHeader';
import {MAGENTA, WHITE} from '../../../const/colors';
import useMedicalTestOrderDetails from '../../../hooks/diagnostic/orderHeaders/useMedicalTestOrderDetails';
import useQuery from '../../../hooks/useQuery';
import useOrderMedicalTestPayment from '../../../hooks/diagnostic/orderHeaders/useOrderMedicalTestPayment';
import {BuyerDataDto} from '../../../types/buyerData';

const MedicalTestsOrderPaymentPage = memo(function MedicalSearchFilters() {
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);

    const query = useQuery();
    const referralId = query.get('referralId');

    const {
        orderDetailsLoading,
        orderDetailsError,
        orderDetails,
        fetchMedicalTestsOrderDetails
    } = useMedicalTestOrderDetails();
    const {sendPaymentDetails, sendPaymentDetailsLoading, sendPaymentDetailsError} = useOrderMedicalTestPayment();

    const loading = orderDetailsLoading || sendPaymentDetailsLoading;
    const error = orderDetailsError || sendPaymentDetailsError;

    const onSubmitHandler = useCallback(async (buyerData: BuyerDataDto) => {
        try {
            const paymentUrl = await sendPaymentDetails(referralId, buyerData);
            window.location.href = paymentUrl;
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchMedicalTestsOrderDetails(referralId);
    }, []);

    return (
        <Layout
            topBar={
                !mdUp && <LogoAndCloseTopBar />
            }
        >
            <Header orderDetails={orderDetails} />
            {
                !error ? (
                    <>
                        <Box style={{marginTop: 8, backgroundColor: WHITE}}>
                            <Container mdDesktop>
                                <SectionHeader>
                                    {`${tReferrals('costOfMedicalTests')}: `}
                                    <Box component={'span'} style={{color: MAGENTA}}>                                      
                                        {orderDetails?.amountToPay}zł
                                    </Box>
                                </SectionHeader>
                            </Container>
                        </Box>
                        <InvoiceInformationCollect onSubmit={onSubmitHandler} />
                    </>
                ) : (
                    <Snackbar
                        open={true}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                )
            }
            <Loading withBackdrop={true} loading={loading} />
        </Layout>
    );
});

export default MedicalTestsOrderPaymentPage;