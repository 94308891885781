import {Avatar, AvatarProps, Box, createStyles, IconButton, makeStyles, Theme} from '@material-ui/core';
import React from "react";
import clsx from "clsx";
import {Close} from "@material-ui/icons";
import {BLACK} from "../../../const/colors";

interface Props extends AvatarProps {
    isActive?: boolean;
    closeHandler?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        avatar: {
            width: 70,
            height: 70,
            [theme.breakpoints.up('sm')]: {
                width: 88,
                height: 88,
            }
        },
        selectedAvatar: {
            border: '2px solid',
            borderColor: theme.palette.secondary.main,
        },
        closeIconButton: {
            width: 'auto',
            height: 'auto',
            padding: 4,
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: '50%',
            backgroundColor: BLACK,
            cursor: 'pointer',
            zIndex: 1,
            '&:hover': {
                backgroundColor: BLACK,
            },
            [theme.breakpoints.up('md')]: {
                padding: 3,
                top: 2,
                left: 2,
            }
        },
        closeIcon: {
            width: '14px',
            height: '14px',
            color: 'white',
            [theme.breakpoints.up('md')]: {
                width: '12px',
                height: '12px',
            },
        },
    }),
);

const CustomAvatar = React.memo(function UserPhoto({isActive, closeHandler, ...avatarProps}: Props) {
    const {className: avatarPropsClassName} = avatarProps;
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {
                closeHandler && (
                    <IconButton
                        className={classes.closeIconButton}
                        onClick={closeHandler}
                    >
                        <Close className={classes.closeIcon} />
                    </IconButton>
                )
            }
            <Avatar
                {...avatarProps}
                className={clsx(
                    avatarPropsClassName,
                    classes.avatar,
                    {
                        [classes.selectedAvatar]: isActive,
                    }
                )}
                
            />
        </Box>
    )
});

export default CustomAvatar;