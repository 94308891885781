import {Box, Grid} from '@material-ui/core';
import {Button, CustomModal} from '../../index';
import useStyles from './AddPatientToQueueOnPageLeaveModalStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import timeSlotsProvider from '../../../services/timeSlotsProvider';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {selectMedicalServiceId} from '../../../store/timeSlotsSlice';
import {
    selectEndDateTime,
    selectStartDateTime,
} from '../../../store/timeSlotsSearchSlice';
import {isSuccessStatusCode} from '../../../utils/http';

interface Props {
    open: boolean;
    goBackToPreviousPage: () => void;
}

const AddPatientToQueueOnPageLeaveModal = ({
    open,
    goBackToPreviousPage,
}: Props) => {
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const {t: tTimeSlots} = useTranslation(i18nNamespaces.TIME_SLOTS);
    const medicalServiceId = useAppSelector(selectMedicalServiceId);
    const startDateTime = useAppSelector(selectStartDateTime);
    const endDateTime = useAppSelector(selectEndDateTime);

    const addPatientToQueue = async () => {
        const startDateTimeISO = startDateTime.toUTC().toISO();
        const endDateTimeISO = endDateTime.toUTC().toISO();

        const response = await timeSlotsProvider.addPatientToQueue(
            medicalServiceId,
            {
                startDate: startDateTimeISO,
                endDate: endDateTimeISO,
            },
        );
        if (isSuccessStatusCode(response.status)) {
            // Without checking the status, in case of backend error, error page is replaced with previous page,
            // as promise resolves, even when status code is different than 2xx.
            goBackToPreviousPage();
        }
    };

    return (
        <CustomModal open={open}>
            <Box className={classes.modalContentContainer}>
                <Grid container direction={'column'} wrap={'nowrap'}>
                    <Box className={classes.modalHeaderText}>
                        {tTimeSlots('couldNotFindTimeSlot')}
                    </Box>
                    <Box className={classes.modalText}>
                        {tTimeSlots(
                            'weCanInformYouWhenTimeSlotBecomesAvailable',
                        )}
                    </Box>
                    <Button
                        className={classes.modalButton}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={addPatientToQueue}
                    >
                        {tCommon('letMeKnow')}
                    </Button>
                    <Button
                        variant={'outlined'}
                        className={classes.modalButton}
                        onClick={goBackToPreviousPage}
                    >
                        {tCommon('noThankYou')}
                    </Button>
                </Grid>
            </Box>
        </CustomModal>
    );
};

export default AddPatientToQueueOnPageLeaveModal;
