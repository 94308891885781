import {createStyles, ListItem, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import clsx from 'clsx';
import {LIGHT_GRAY} from '../../../../const/colors';
import {isNotNullOrUndefined} from '../../../../utils/lang';

interface Props {
    mobileTopOffset?: number;
    itemDisableGutters?: boolean;
    children: JSX.Element;
}

const useStyles = makeStyles(() =>
    createStyles({
        cardListItem: {
            padding: '25px 16px',
            '&:first-child': {
                borderTop: `1px solid ${LIGHT_GRAY}`
            },
        },
        cardListItemDisableGutters: {
            padding: '25px 0',
        }
    }),
);

const CardListItem = ({mobileTopOffset, itemDisableGutters, children}: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreenUp = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <ListItem
            disableGutters={true}
            className={
                clsx(classes.cardListItem, {
                    [classes.cardListItemDisableGutters]: itemDisableGutters
                })
            }
            style={{
                borderBottom: !isSmallScreenUp && isNotNullOrUndefined(mobileTopOffset)
                    ? `${mobileTopOffset}px solid ${LIGHT_GRAY}`
                    : `1px solid ${LIGHT_GRAY}`
            }}
        >
            {children}
        </ListItem>
    );
}

export default CardListItem;