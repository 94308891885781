import {useContext} from 'react';
import {
    PayUSecureFormsContext,
    PayUSecureFormsContextType,
} from './payUSecureFormsContext';

const usePayUSecureForms = (): PayUSecureFormsContextType =>
    useContext(PayUSecureFormsContext);

export default usePayUSecureForms;
