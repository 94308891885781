import React from 'react';
import {Box, useTheme} from '@material-ui/core';

type FormMessageProps = {
    severity?: 'error' | 'warning';
    message: string;
    mt?: number | string;
    mb?: number | string;
};
const FormMessage = ({message, severity, mt, mb}: FormMessageProps) => {
    const {palette} = useTheme();
    const color = severity ? palette[severity].main : palette.error.main;
    return (
        message && (
            <Box color={color} mt={mt} mb={mb}>
                {message}
            </Box>
        )
    );
};

export default FormMessage;
