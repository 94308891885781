import { MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { useAppDispatch } from "../../../hooks/customReduxHooks";
import { isNotNullOrUndefined } from "../../../utils/lang";

import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18nNamespaces from "../../../const/i18nNamespaces";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import { useFormik } from "formik";
import * as yup from 'yup';
import { PhisicalTestDto } from "../../../types/occupationalMedicine";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { RenderReturnNextButtons } from "../CommonView";
import { useParams } from "react-router";
import { fetchSection6Data } from "../../../store/occupationalMedicineSlice";
import subjectiveExaminationsValidationsSchema from "./SubjectiveExaminationsValidationsSchema";

const SECTION = 6;

export type Props = {
    data: PhisicalTestDto,
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void
    currentExpanded:number
    onReturn: () => void;
    refSection:MutableRefObject<any>
}
const SubjectiveExaminationsComponent = ({
    data, onSubmitSection, currentExpanded, onReturn, refSection
}:Props) =>{

    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const dispatch = useAppDispatch();
    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);

    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values), fetchSection6Data({id:appointmentId}),6);
    }
    
    const {values, touched, handleChange, handleBlur, errors, handleSubmit, setFieldValue} =
    useFormik({
        initialValues: {
            complaints: data?.complaints ?? '',
            skullInjuries: {
                occured:data?.skullInjuries.occured ?? false,
                description: data?.skullInjuries.description ?? ''
            },
            musculoskeletalsystemInjuries: {
                occured:data?.musculoskeletalsystemInjuries.occured ?? false,
                description: data?.musculoskeletalsystemInjuries.description ?? ''
            },
            fainting:{
                occured:data?.fainting.occured ?? false,
                description: data?.fainting.description ?? ''
            },
            epilepsy:{
                occured:data?.epilepsy.occured ?? false,
                description: data?.epilepsy.description ?? ''
            },
            otherdiseasesOfTheNervousSystem:{
                occured:data?.otherdiseasesOfTheNervousSystem.occured ?? false,
                description: data?.otherdiseasesOfTheNervousSystem.description ?? ''
            },
            mentalDisorders:{
                occured:data?.mentalDisorders.occured ?? false,
                description: data?.mentalDisorders.description ?? ''
            },
            diabetes:{
                occured:data?.diabetes.occured ?? false,
                description: data?.diabetes.description ?? ''
            },
            hearingDiseases:{
                occured:data?.hearingDiseases.occured ?? false,
                description: data?.hearingDiseases.description ?? ''
            },
            eyeDiseases:{
                occured:data?.eyeDiseases.occured ?? false,
                description: data?.eyeDiseases.description ?? ''
            },
            diseasesOfTheHematopoieticSystem:{
                occured:data?.diseasesOfTheHematopoieticSystem.occured ?? false,
                description: data?.diseasesOfTheHematopoieticSystem.description ?? ''
            },
            cardiovascularDisease:{
                occured:data?.cardiovascularDisease.occured ?? false,
                description: data?.cardiovascularDisease.description ?? ''
            },
            respiratorySystemDiseases:{
                occured:data?.respiratorySystemDiseases.occured ?? false,
                description: data?.respiratorySystemDiseases.description ?? ''
            },
            digestiveSystemDiseases:{
                occured:data?.digestiveSystemDiseases.occured ?? false,
                description: data?.digestiveSystemDiseases.description ?? ''
            },
            diseasesOfTheGenitourinarySystem:{
                occured:data?.diseasesOfTheGenitourinarySystem.occured ?? false,
                description: data?.diseasesOfTheGenitourinarySystem.description
            },
            diseasesOfTheMusculoskeletalSystem:{
                occured:data?.diseasesOfTheMusculoskeletalSystem.occured ?? false,
                description: data?.diseasesOfTheMusculoskeletalSystem.description ?? ''
            },
            skinDiseasesAllergies:{
                occured:data?.skinDiseasesAllergies.occured ?? false,
                description: data?.skinDiseasesAllergies.description ?? ''
            },
            infectiousParasiticDiseases:{
                occured:data?.infectiousParasiticDiseases.occured ?? false,
                description: data?.infectiousParasiticDiseases.description ?? ''
            },
            gynecologicalAndObstetricInterview:{
                occured:data?.gynecologicalAndObstetricInterview.occured ?? false,
                description: data?.gynecologicalAndObstetricInterview.description ?? ''
            },
            familyHistory:{
                occured:data?.familyHistory.occured ?? false,
                description: data?.familyHistory.description ?? ''
            },
            otherHealthProblems:{
                occured:data?.otherHealthProblems.occured ?? false,
                description: data?.otherHealthProblems.description ?? ''
            },
            smoking:{
                occured:data?.smoking.occured ?? false,
                inThePast:data?.smoking.inThePast ?? '',
                currently:data?.smoking.currently ?? ''
            },
            
            otherStimulants:{
                occured:data?.otherStimulants.occured ?? false,
                description: data?.otherStimulants.description ?? ''
            },
        },

        validationSchema: subjectiveExaminationsValidationsSchema(commonT),
        onSubmit,
    });

  
    const renderGridItemWithCheckBox = (idAndName:string, value:boolean, translation:string) =>
        (
            <Grid item xs={8}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={idAndName}
                                name={idAndName}
                                checked={value}
                                onChange={handleChange}
                            />
                        }
                        label={
                            <Box className={classes.text}>
                                {t(translation)}
                            </Box>
                        }    
                        />
                </FormGroup>
            </Grid>               
        )

    const renderGridItemWithTextField = (idAndName:string, value:string, translation:string, touched:any, errors:any) =>(
            <Grid item xs={8}>
                <FormGroup>
                    <Label htmlFor={translation}>{t(translation)} *</Label>
                    <CustomTextField 
                            fullWidth 
                            id={idAndName}
                            name={idAndName}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={value}
                            helperText={touched && t(errors)}
                            error={touched && !!errors}
                            />
                </FormGroup>
            </Grid>
            
        )
        
    return (
        <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >

                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('subjectiveExaminations')}
                        </h4>

                        {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.detailsContainer}>
                    <Box>
                        <Grid container spacing={1} className={classes.gridContainer}>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <Label htmlFor="complaints">{t("complaints")}</Label>
                                    <CustomTextField 
                                            fullWidth 
                                            id="complaints"
                                            name="complaints"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.complaints}
                                        />
                                </FormGroup>
                            </Grid>

                             {renderGridItemWithCheckBox("skullInjuries.occured", 
                                values.skullInjuries.occured, "skullInjuries")}  
                            <Grid item xs={4}></Grid>
                            {values.skullInjuries.occured
                                 && renderGridItemWithTextField("skullInjuries.description", 
                                 values.skullInjuries.description, "description",
                                 touched.skullInjuries?.description, errors.skullInjuries?.description)} 

                            {renderGridItemWithCheckBox("musculoskeletalsystemInjuries.occured", 
                                values.musculoskeletalsystemInjuries.occured, "musculoskeletalsystemInjuries")}  
                            <Grid item xs={4}></Grid>
                            {values.musculoskeletalsystemInjuries.occured
                                 && renderGridItemWithTextField("musculoskeletalsystemInjuries.description", 
                                 values.musculoskeletalsystemInjuries.description, "description",
                                 touched.musculoskeletalsystemInjuries?.description, errors.musculoskeletalsystemInjuries?.description)} 

                            {renderGridItemWithCheckBox("fainting.occured", 
                                values.fainting.occured, "fainting")}  
                            <Grid item xs={4}></Grid>
                            {values.fainting.occured
                                 && renderGridItemWithTextField("fainting.description", 
                                 values.fainting.description, "description",
                                 touched.fainting?.description, errors.fainting?.description)} 

                            {renderGridItemWithCheckBox("epilepsy.occured", 
                                values.epilepsy.occured, "epilepsy")}  
                            <Grid item xs={4}></Grid>
                            {values.epilepsy.occured
                                 && renderGridItemWithTextField("epilepsy.description", 
                                 values.epilepsy.description, "description",
                                 touched.epilepsy?.description, errors.epilepsy?.description)} 

                            {renderGridItemWithCheckBox("otherdiseasesOfTheNervousSystem.occured", 
                                values.otherdiseasesOfTheNervousSystem.occured, "otherdiseasesOfTheNervousSystem")}  
                            <Grid item xs={4}></Grid>
                            {values.otherdiseasesOfTheNervousSystem.occured
                                 && renderGridItemWithTextField("otherdiseasesOfTheNervousSystem.description", 
                                 values.otherdiseasesOfTheNervousSystem.description, "description",
                                 touched.otherdiseasesOfTheNervousSystem?.description, errors.otherdiseasesOfTheNervousSystem?.description)}   

                            {renderGridItemWithCheckBox("mentalDisorders.occured", 
                                values.mentalDisorders.occured, "mentalDisorders")}  
                            <Grid item xs={4}></Grid>
                            {values.mentalDisorders.occured
                                 && renderGridItemWithTextField("mentalDisorders.description", 
                                 values.mentalDisorders.description, "description",
                                 touched.mentalDisorders?.description, errors.mentalDisorders?.description)}      

                            {renderGridItemWithCheckBox("diabetes.occured", 
                                values.diabetes.occured, "diabetes")}  
                            <Grid item xs={4}></Grid>
                            {values.diabetes.occured
                                 && renderGridItemWithTextField("diabetes.description", 
                                 values.diabetes.description, "description",
                                 touched.diabetes?.description, errors.diabetes?.description)} 

                            {renderGridItemWithCheckBox("hearingDiseases.occured", 
                                values.hearingDiseases.occured, "hearingDiseases")}  
                            <Grid item xs={4}></Grid>
                            {values.hearingDiseases.occured
                                 && renderGridItemWithTextField("hearingDiseases.description", 
                                 values.hearingDiseases.description, "description",
                                 touched.hearingDiseases?.description, errors.hearingDiseases?.description)}  

                            {renderGridItemWithCheckBox("eyeDiseases.occured", 
                                values.eyeDiseases.occured, "eyeDiseases")}  
                            <Grid item xs={4}></Grid>
                            {values.eyeDiseases.occured
                                 && renderGridItemWithTextField("eyeDiseases.description", 
                                 values.eyeDiseases.description, "description",
                                 touched.eyeDiseases?.description, errors.eyeDiseases?.description)}

                            {renderGridItemWithCheckBox("diseasesOfTheHematopoieticSystem.occured", 
                                values.diseasesOfTheHematopoieticSystem.occured, "diseasesOfTheHematopoieticSystem")}  
                            <Grid item xs={4}></Grid>
                            {values.diseasesOfTheHematopoieticSystem.occured
                                 && renderGridItemWithTextField("diseasesOfTheHematopoieticSystem.description", 
                                 values.diseasesOfTheHematopoieticSystem.description, "description",
                                 touched.diseasesOfTheHematopoieticSystem?.description, errors.diseasesOfTheHematopoieticSystem?.description)}

                            {renderGridItemWithCheckBox("cardiovascularDisease.occured", 
                                values.cardiovascularDisease.occured, "cardiovascularDisease")}  
                            <Grid item xs={4}></Grid>
                            {values.cardiovascularDisease.occured
                                 && renderGridItemWithTextField("cardiovascularDisease.description", 
                                 values.cardiovascularDisease.description, "description",
                                 touched.cardiovascularDisease?.description, errors.cardiovascularDisease?.description)}

                            {renderGridItemWithCheckBox("respiratorySystemDiseases.occured", 
                                values.respiratorySystemDiseases.occured, "respiratorySystemDiseases")}  
                            <Grid item xs={4}></Grid>
                            {values.respiratorySystemDiseases.occured
                                 && renderGridItemWithTextField("respiratorySystemDiseases.description", 
                                 values.respiratorySystemDiseases.description, "description",
                                 touched.respiratorySystemDiseases?.description, errors.respiratorySystemDiseases?.description)}

                            {renderGridItemWithCheckBox("digestiveSystemDiseases.occured", 
                                values.digestiveSystemDiseases.occured, "digestiveSystemDiseases")}  
                            <Grid item xs={4}></Grid>
                            {values.digestiveSystemDiseases.occured
                                 && renderGridItemWithTextField("digestiveSystemDiseases.description", 
                                 values.digestiveSystemDiseases.description, "description",
                                 touched.digestiveSystemDiseases?.description, errors.digestiveSystemDiseases?.description)} 

                            {renderGridItemWithCheckBox("diseasesOfTheGenitourinarySystem.occured", 
                                values.diseasesOfTheGenitourinarySystem.occured, "diseasesOfTheGenitourinarySystem")}  
                            <Grid item xs={4}></Grid>
                            {values.diseasesOfTheGenitourinarySystem.occured
                                 && renderGridItemWithTextField("diseasesOfTheGenitourinarySystem.description", 
                                 values.diseasesOfTheGenitourinarySystem.description, "description",
                                 touched.diseasesOfTheGenitourinarySystem?.description, errors.diseasesOfTheGenitourinarySystem?.description)}

                            {renderGridItemWithCheckBox("diseasesOfTheMusculoskeletalSystem.occured", 
                                values.diseasesOfTheMusculoskeletalSystem.occured, "diseasesOfTheMusculoskeletalSystem")}  
                            <Grid item xs={4}></Grid>
                            {values.diseasesOfTheMusculoskeletalSystem.occured
                                 && renderGridItemWithTextField("diseasesOfTheMusculoskeletalSystem.description", 
                                 values.diseasesOfTheMusculoskeletalSystem.description, "diseasesOfTheMusculoskeletalSystem",
                                 touched.diseasesOfTheMusculoskeletalSystem?.description, errors.diseasesOfTheMusculoskeletalSystem?.description)} 

                            {renderGridItemWithCheckBox("skinDiseasesAllergies.occured", 
                                values.skinDiseasesAllergies.occured, "skinDiseasesAllergies")}  
                            <Grid item xs={4}></Grid>
                            {values.skinDiseasesAllergies.occured
                                 && renderGridItemWithTextField("skinDiseasesAllergies.description", 
                                 values.skinDiseasesAllergies.description, "description",
                                 touched.skinDiseasesAllergies?.description, errors.skinDiseasesAllergies?.description)}

                            {renderGridItemWithCheckBox("infectiousParasiticDiseases.occured", 
                                values.infectiousParasiticDiseases.occured, "infectiousParasiticDiseases")}  
                            <Grid item xs={4}></Grid>
                            {values.infectiousParasiticDiseases.occured
                                 && renderGridItemWithTextField("infectiousParasiticDiseases.description", 
                                 values.infectiousParasiticDiseases.description, "description",
                                 touched.infectiousParasiticDiseases?.description, errors.infectiousParasiticDiseases?.description)} 

                            {renderGridItemWithCheckBox("gynecologicalAndObstetricInterview.occured", 
                                values.gynecologicalAndObstetricInterview.occured, "gynecologicalAndObstetricInterview")}  
                            <Grid item xs={4}></Grid>
                            {values.gynecologicalAndObstetricInterview.occured
                                 && renderGridItemWithTextField("gynecologicalAndObstetricInterview.description", 
                                 values.gynecologicalAndObstetricInterview.description, "description",
                                 touched.gynecologicalAndObstetricInterview?.description, errors.gynecologicalAndObstetricInterview?.description)}  

                            {renderGridItemWithCheckBox("familyHistory.occured", 
                                values.familyHistory.occured, "familyHistory")}  
                            <Grid item xs={4}></Grid>
                            {values.familyHistory.occured
                                 && renderGridItemWithTextField("familyHistory.description", 
                                 values.familyHistory.description, "description",
                                 touched.familyHistory?.description, errors.familyHistory?.description)} 

                            {renderGridItemWithCheckBox("otherHealthProblems.occured", 
                                values.otherHealthProblems.occured, "otherHealthProblems")}  
                            <Grid item xs={4}></Grid>
                            {values.otherHealthProblems.occured
                                 && renderGridItemWithTextField("otherHealthProblems.description", 
                                 values.otherHealthProblems.description, "description",
                                 touched.otherHealthProblems?.description, errors.otherHealthProblems?.description)}  


                            {renderGridItemWithCheckBox("smoking.occured", 
                                                            values.smoking.occured, "smoking")} 
                            <Grid item xs={4}></Grid>
                            
                            {values.smoking.occured && <Grid item xs={4}>
                                <FormGroup>
                                    <Label htmlFor={'inThePast'}>{t('inThePast')} *</Label>
                                    <CustomTextField 
                                            fullWidth 
                                            id="smoking.inThePast"
                                            name="smoking.inThePast"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.smoking.inThePast}
                                            helperText={touched.smoking?.inThePast && t(errors.smoking?.inThePast)}
                                            error={touched.smoking?.inThePast && !!errors.smoking?.inThePast}
                                            />
                                </FormGroup>
                            </Grid>}
                            
                            {values.smoking.occured && <Grid item xs={4}>
                                <FormGroup>
                                    <Label htmlFor={"currently"}>{t("currently")} *</Label>
                                    <CustomTextField 
                                            fullWidth 
                                            id="smoking.currently"
                                            name="smoking.currently"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.smoking.currently}
                                            helperText={touched.smoking?.currently && t(errors.smoking?.currently)}
                                            error={touched.smoking?.currently && !!errors.smoking?.currently}
                                            />
                                </FormGroup>
                            </Grid>}


                            {renderGridItemWithCheckBox("otherStimulants.occured", 
                                values.otherStimulants.occured, "otherStimulants")}  
                            <Grid item xs={4}></Grid>
                            
                            {values.otherStimulants.occured
                                 && renderGridItemWithTextField("otherStimulants.description", 
                                 values.otherStimulants.description, "description",
                                 touched.otherStimulants?.description, errors.otherStimulants?.description)}   

                        </Grid>
                        {RenderReturnNextButtons(classes, t, onReturn)}
                    </Box>

                </AccordionDetails>
            </Accordion>
        </form>
    )
};

export default SubjectiveExaminationsComponent;