import {Box, Button, FormGroup, InputAdornment} from '@material-ui/core';
import CustomTextField from '../../common/CustomTextField/CustomTextField';
import useStyles from './ResellerCodeStyles';
import {Add} from '@material-ui/icons';
import {CSSProperties, Ref, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

type ResellerCodeProps = {
    onUseResellerCode: (code: string) => void;
    errorText: string;
    loading: boolean;
    style?: CSSProperties;
    inputRef?: Ref<any>
};

const ResellerCode = ({
    onUseResellerCode,
    errorText,
    loading,
    style,
    inputRef
}: ResellerCodeProps) => {
    const classes = useStyles();

    const {t} = useTranslation(i18nNamespaces.DISCOUNT_CODE);
    const [resellerCode, setResellerCode] = useState<string>(null);

    const updateResellerCode = (code: string) => {
        setResellerCode(code);
    };

    return (
        <Box style={style}>
            <FormGroup>
                <CustomTextField
                    disabled={loading}
                    id="code"
                    name="code"
                    type="text"
                    onChange={v => updateResellerCode(v.target.value)}
                    error={errorText !== null}
                    helperText={t(errorText)}
                    placeholder={t('iHaveCode')}
                    inputRef={inputRef}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={classes.adorment}
                            >
                                <Button
                                    className={classes.button}
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() =>
                                        onUseResellerCode(resellerCode)
                                    }
                                >
                                    <Add className={classes.icon} />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormGroup>
        </Box>
    );
};
export default ResellerCode;
