import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, IconButton, Slider} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import Cropper from 'react-easy-crop';
import Resizer from 'react-image-file-resizer';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {CustomModal} from '../../../index';
import useStyles from './MedicPictureCropModalStyles'
import {convertCanvasToImageBlob} from "../../../../utils/file";

const MIN_ZOOM = 1;
const MAX_ZOOM = 5;
const DEFAULT_ZOOM = 2;

interface CropCordsData {
    width: number;
    height: number;
    x: number;
    y: number;
}

interface Props {
    imageFile: File;
    imageFileChanged: (imageFile: File) => void;
    close: () => void;
    isRound?:boolean
}

const CROPPED_IMAGE_MIN_SIZE = 300;
const CROPPED_IMAGE_MAX_SIZE = 600;

const MedicPictureCropModal = ({imageFile, imageFileChanged, close, isRound=true}: Props) => {
    const classes = useStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);

    const [image, setImage] = useState<string>();
    const [cropImageOngoing, setCropImageOngoing] = useState(false);

    const [crop, setCrop] = useState({x: 0, y: 0});
    const [croppedArea, setCroppedArea] = useState<CropCordsData>();
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);

    const croppedAreaToSmall = croppedArea?.width < CROPPED_IMAGE_MIN_SIZE
        && croppedArea?.height < CROPPED_IMAGE_MIN_SIZE;

    const doneButtonDisabled = cropImageOngoing || croppedAreaToSmall;

    const onCropComplete = useCallback((_, croppedAreaPixels: CropCordsData) => {
        setCroppedArea(croppedAreaPixels)
    }, []);

    const handleFileChange = () => {
        const reader = new FileReader();
        reader.addEventListener('load', () => setImage(reader.result as string));
        reader.readAsDataURL(imageFile);
    }

    const resizeFile = (file: File, size: number) => {
        return new Promise<File>((resolve) => {
            Resizer.imageFileResizer(
                file,
                size,
                size,
                'JPEG',
                100,
                0,
                (file) => {
                    resolve(file as File);
                },
                'file',
            );
        });
    }

    const cropImageHandler = async () => {
        setCropImageOngoing(true);

        const img = new Image();
        img.src = image;

        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = croppedArea.width;
        canvas.height = croppedArea.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, croppedArea.x, croppedArea.y, croppedArea.width, croppedArea.height,
            0, 0, croppedArea.width, croppedArea.height);

        const croppedImageFile = await convertCanvasToImageBlob(canvas, imageFile.name);
        const resizedImageFile = await resizeFile(croppedImageFile, CROPPED_IMAGE_MAX_SIZE);

        setCropImageOngoing(false);
        imageFileChanged(resizedImageFile);
    }

    useEffect(() => {
        handleFileChange();
    }, [imageFile]);

    return (
        <CustomModal open>
            <Box className={classes.modalContent}>
                <IconButton
                    className={classes.closeIcon}
                    onClick={close}
                >
                    <Close />
                </IconButton>
                <Grid
                    container
                    justifyContent={'center'}
                    direction={'column'}
                    wrap={'nowrap'}
                >
                    <Box className={isRound ? classes.cropperWrapper : classes.cropperWrapperRectange}>
                        <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            minZoom={MIN_ZOOM}
                            maxZoom={MAX_ZOOM}
                            aspect={1}
                            cropShape={isRound ? 'round' : 'rect'}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            showGrid={false}
                            cropSize={{ width: isRound ? 300 : 600, height: isRound ? 300 : 200}}
                        />
                    </Box>
                    {
                        croppedAreaToSmall && (
                            <Box
                                className={classes.croppedAreaToSmall}
                                component={'span'}
                            >
                                {commonT('croppedAreaToSmall')}
                            </Box>
                        )
                    }
                    <Box className={classes.zoomSliderContainer}>
                        <Box component={'span'}>
                            Zoom: {zoom.toFixed(1)}x
                        </Box>
                        <Slider
                            value={zoom}
                            min={MIN_ZOOM}
                            max={MAX_ZOOM}
                            step={0.2}
                            onChange={(_, newValue) => setZoom(newValue as number)}
                            aria-labelledby="continuous-slider"
                        />
                    </Box>
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        className={classes.cropDoneButton}
                        onClick={cropImageHandler}
                        disabled={doneButtonDisabled}
                    >
                        {commonT('done')}
                    </Button>
                </Grid>
            </Box>
        </CustomModal>
    )
}

export default MedicPictureCropModal;