export const addZeros = (number: number) =>
    number < 10 ? `0${number}` : number;

export const formatPrice = (price: number) => {
    return price !== undefined ? price.toFixed(2).replace('.', ',') : '0,00';
};

export const getCurrency = (lang: string) => {
    switch (lang) {
        case 'pl-PL':
            return 'zł';
        case 'en-EN':
            return '\u20AC';
    }
};
