import { Avatar, Box, Card, Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { MedicalTestIcon } from "../../../assets";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { MEDICAL_TEST_RESULT_PAGE } from "../../../const/routes";
import { LARGE_DESKTOP_BREAKPOINT } from "../../../const/sizes";
import { PatientOrderedDiagnosticOrderHeaders } from "../../../services/diagnostic/orderHeadersProvider";
import { getRelativeDay } from "../../../utils/date";
import { isNotNullOrUndefined } from "../../../utils/lang";
import AppointmentTimeDetails from "../../common/Appointment/AppointmentTimeDetails/AppointmentTimeDetails";
import useStyles from "./MedicaltestItemStyles";


type MedicalTestItemProps = {
    orderedDiagnosticOrderHeader: PatientOrderedDiagnosticOrderHeaders;
};

const MedicalTestItem = ({orderedDiagnosticOrderHeader}: MedicalTestItemProps) =>{
    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();
    
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(LARGE_DESKTOP_BREAKPOINT);

    const orderDateTime = orderedDiagnosticOrderHeader.orderDateTime;
    
    const {t: tAppointment} = useTranslation(i18nNamespaces.APPOINTMENT);
    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);


    const medicaltestItemClickHandler = useCallback(() => {
        history.push(`${MEDICAL_TEST_RESULT_PAGE}/${orderedDiagnosticOrderHeader.id}`);
    }, [orderedDiagnosticOrderHeader.id]);

    return (
        <Card
            style={{
                padding: smUp ? '24px 0' : 16,
                borderBottom: `1px solid ${theme.palette.grey['300']}`,
                boxShadow: 'none',
            }}
        >
            <Grid
                container
                wrap={smUp ? 'nowrap' : 'wrap'}
                alignItems="center"
                onClick={!mdUp ? medicaltestItemClickHandler : null}
                spacing={lgUp ? 2 : 1}
                style={{
                    cursor: !mdUp ? 'pointer' : 'default',
                }}
            >
                <Grid item xs={12} sm="auto">
                    <AppointmentTimeDetails
                        date={orderDateTime}
                        isNow={false}
                        isInTheFuture={false}
                        square={smUp}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Grid
                        container
                        wrap={'nowrap'}
                        alignItems="center"
                        spacing={lgUp ? 2 : 1}
                    >
                        <Grid item xs="auto">
                            <Avatar
                                alt={'photo'}
                                src={MedicalTestIcon}
                                className={classes.photo}
                            />
                        </Grid>
                        <Grid item xs>
                        <Grid
                            container
                            direction="column"
                            style={{
                                overflowWrap: 'anywhere',
                            }}
                        >
                            <Box className={classes.type}>
                                { isNotNullOrUndefined(orderedDiagnosticOrderHeader.medicalPackageName)
                                    ? orderedDiagnosticOrderHeader.medicalPackageName 
                                    : t(orderedDiagnosticOrderHeader.type)}
                            </Box>
                            <Box className={classes.laboratoryAddress}>
                                {orderedDiagnosticOrderHeader.laboratoryAddress},
                                {' '}{orderedDiagnosticOrderHeader.zipCode}
                                {' '}{orderedDiagnosticOrderHeader.laboratoryCity}
                            </Box>
                            <Box className={classes.laboratoryAddress}>
                                Tel: {orderedDiagnosticOrderHeader.contactPhoneNumber}
                                {orderedDiagnosticOrderHeader.contactEmail && 
                                (<span>,{' '}e-mail:{' '}{orderedDiagnosticOrderHeader.contactEmail}</span>)}
                                
                            </Box>
                            <Box className={classes.patientName}>
                                {tAppointment('patient')}:{' '}
                                {orderedDiagnosticOrderHeader.patientName}{' '}
                                {orderedDiagnosticOrderHeader.patientSurname}
                            </Box>
                        </Grid>
                        </Grid>
                        {lgUp && (
                            <Grid item lg="auto" className={classes.status}>
                                {t(orderedDiagnosticOrderHeader.status)}
                            </Grid>
                        )}
                        
                        <Grid item xs="auto">
                            <ArrowForwardIos
                                className={classes.arrowIcon}
                                onClick={mdUp ? medicaltestItemClickHandler : null}
                                style={{
                                    color: theme.palette.grey[300],
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default MedicalTestItem;