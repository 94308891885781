import {Box, Button, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {HowItWorksStep2} from '../../assets';
import i18nNamespaces from '../../const/i18nNamespaces';
import {YOUR_BUNDLES} from '../../const/routes';
import useStyles from './SubscriptionPaymentFailedPageStyles';
import useQuery from '../../hooks/useQuery';

const SubscriptionPaymentFailedPage = () => {
    const {t} = useTranslation(i18nNamespaces.SUBSCRIPTION_PAYMENT);
    const history = useHistory();
    const classes = useStyles();
    const query = useQuery();
    const reason = query.get('reason');

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.errorWrapper}
        >
            <Box className={classes.errorTitle}>
                {t('subscriptionPaymentFailed')}
            </Box>
            <Box className={classes.errorContent}>
                {reason
                    ? t(reason)
                    : t('thereWasAnErrorWhileProcessingThePayment')}
            </Box>
            <Box>
                <img
                    src={HowItWorksStep2}
                    alt={t('error')}
                    className={classes.errorImage}
                />
            </Box>
            <Button
                className={classes.refreshButton}
                variant={'contained'}
                color={'secondary'}
                onClick={() => history.push('/account' + YOUR_BUNDLES)}
            >
                {t('yourPackages')}
            </Button>
        </Grid>
    );
};

export default SubscriptionPaymentFailedPage;
