import { MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { useAppDispatch, useAppSelector } from "../../../hooks/customReduxHooks";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';

import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18nNamespaces from "../../../const/i18nNamespaces";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import { useFormik } from "formik";
import { PatientStatementDto } from "../../../types/occupationalMedicine";
import { selectIsQuestionnaireFilledForPatient } from "../../../store/occupationalMedicineSlice";
import { selectAuthUserData } from "../../../store/auth";
import { MEDIC } from "../../../const/auth";

const REQUIRED_CONSENT = 'requiredConsent';
const SECTION = 8; 

export type Props = {
    data: PatientStatementDto,
    onSubmitSection: (values: PatientStatementDto) =>void,
    currentExpanded:number
    onReturn: () => void;
    onNext:() => void;
    refSection:MutableRefObject<any>
}
const PatientStatementComponent = ({
    data, onSubmitSection, currentExpanded, onReturn, onNext, refSection
}:Props) =>{

    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:authT} = useTranslation(i18nNamespaces.AUTH);
    const classes = useOccupationalMedicineStyles();

    const authUserData = useAppSelector(selectAuthUserData);
    const isMedic = authUserData?.role === MEDIC;

    const areOtherSeciontsFilled = useAppSelector(selectIsQuestionnaireFilledForPatient);

    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);
    
    
    const onSubmit = () => {
        onSubmitSection(values);
    }
    
    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {
                confirmed: data?.confirmed ?? false,  
            },

            validationSchema: yup.object({
                confirmed:yup.boolean().oneOf([true], authT(REQUIRED_CONSENT))
            }),
            onSubmit
        });

    
    return (
        <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >

                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('statement')}
                        </h4>

                        {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.detailsContainer}>
                    <Box>
                        <Grid container spacing={1} className={classes.gridContainer}>
                            <Grid item xs={12}>
                            
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="confirmed"
                                            name="confirmed"
                                            checked={values.confirmed}
                                            onChange={handleChange}
                                            disabled={isMedic}
                                        />
                                    }
                                    label={
                                        <Box className={classes.text}>
                                            {t('statementDescription')} *
                                        </Box>
                                    }    
                                    />
                            </FormGroup>
                            <FormHelperText
                                    error={
                                        touched.confirmed && !!errors.confirmed
                                    }
                                >
                                    {touched.confirmed && t(errors.confirmed)}
                            </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.buttonReturn}
                                    variant={'outlined'}
                                    color={'secondary'}
                                    onClick={onReturn}
                                    >
                                    {t('return')}
                                </Button>
                            </Grid>
                            
                            
                            <Grid item xs={6}>
                            {!isMedic?
                              <Button
                                    type="submit"
                                    className={classes.buttonNext}
                                    variant={'contained'}
                                    color={'secondary'}
                                    disabled={!areOtherSeciontsFilled}>
                                    {t('sendQuestionnaire')}
                                </Button>
                                :
                                <Button
                                    className={classes.buttonNext}
                                    variant={'contained'}
                                    color={'secondary'}
                                    onClick={onNext}>
                                    {t('next')}
                                    
                                </Button>
                            }
    
                            </Grid>
                        </Grid>
                    </Box>

                </AccordionDetails>
            </Accordion>
        </form>
    )
};

export default PatientStatementComponent;