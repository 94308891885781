import {
    CaseReducer,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import {DayOfWeek} from '../const/dayOfWeek';
import {LoadingStatus} from '../const/loadingStatus';
import dayScheduleTemplatesProvider from '../services/dayScheduleTemplatesProvider';
import medicsProvider from '../services/medicsProvider';
import {
    mapFromServerDayScheduleTemplate,
    mapToFirstEffectiveDate,
    mapToServerDayScheduleTemplate,
} from './mappers/dayScheduleTemplateMappers';
import {RootState} from './store';
import {v4} from 'uuid';
import {
    DayScheduleTemplate,
    TimeSlotTemplate,
} from '../types/dayScheduleTemplates';
import TimeOfDay from '../utils/timeOfDay';
import {
    compareDayScheduleTemplates,
    processDayScheduleTemplate,
} from '../services/dayScheduleTemplateOperations';

const sliceName = 'day-schedule-templates';

const dayScheduleTemplatesAdapter = createEntityAdapter<DayScheduleTemplate>({
    selectId: dayScheduleTemplate => dayScheduleTemplate.dayOfWeek,
    sortComparer: compareDayScheduleTemplates,
});

const initialState = dayScheduleTemplatesAdapter.getInitialState({
    effectiveDate: null as DateTime,
    serviceDuration: 15,
    status: 'idle' as LoadingStatus,
});

export type DayScheduleTemplateSliceState = typeof initialState;

export const fetchDayScheduleTemplates = createAsyncThunk<
    {
        serviceDuration: number;
        startDate: string;
        dayScheduleTemplates: DayScheduleTemplate[];
    },
    void,
    {
        state: RootState;
    }
>(`${sliceName}/fetchDayScheduleTemplates`, async () => {
    const medic = await medicsProvider.getCurrentMedic();
    const dayScheduleTemplates = (
        await dayScheduleTemplatesProvider.getDayScheduleTemplates()
    ).map(dayScheduleTemplate =>
        mapFromServerDayScheduleTemplate(dayScheduleTemplate),
    );
    return {
        serviceDuration: medic.medicalServiceDuration,
        startDate: medic.startDate,
        dayScheduleTemplates: dayScheduleTemplates
    };
});

export const saveDayScheduleTemplates = createAsyncThunk<
    void,
    void,
    {state: RootState}
>(`${sliceName}/saveDayScheduleTemplates`, async (_, thunkApi) => {
    await dayScheduleTemplatesProvider.saveDayScheduleTemplates(
        Object.values(thunkApi.getState().availabilityTemplate.entities).map(
            mapToServerDayScheduleTemplate,
        ),
    );
});

const addTimeSlotTemplateReducer: CaseReducer<
    DayScheduleTemplateSliceState,
    PayloadAction<DayOfWeek>
> = (state, action) => {
    const serviceDuration = state.serviceDuration;
    const dayScheduleTemplate = state.entities[action.payload];
    const timeSlotTemplates = dayScheduleTemplate.timeSlotTemplates;

    const newTimeSlotTemplate = {
        id: v4(),
        startTime: TimeOfDay.getEarliestTime(),
        endTime: TimeOfDay.getLatestTime(),
    } as TimeSlotTemplate;

    if (timeSlotTemplates.length > 0) {
        const lastTimeSlotTemplate =
            timeSlotTemplates[timeSlotTemplates.length - 1];
        newTimeSlotTemplate.startTime = new TimeOfDay(
            +lastTimeSlotTemplate.endTime,
        );
    }

    timeSlotTemplates.push(newTimeSlotTemplate);

    processDayScheduleTemplate(
        dayScheduleTemplate as DayScheduleTemplate,
        serviceDuration,
    );
};

const removeTimeSlotTemplateReducer: CaseReducer<
    DayScheduleTemplateSliceState,
    PayloadAction<string>
> = (state, action) => {
    const serviceDuration = state.serviceDuration;

    const dayScheduleTemplate = Object.values(state.entities).find(
        dayScheduleTemplate =>
            dayScheduleTemplate.timeSlotTemplates.some(
                timeSlotTemplate => timeSlotTemplate.id === action.payload,
            ),
    );

    dayScheduleTemplate.timeSlotTemplates =
        dayScheduleTemplate.timeSlotTemplates.filter(
            timeSlotTemplate => timeSlotTemplate.id !== action.payload,
        );

    processDayScheduleTemplate(
        dayScheduleTemplate as DayScheduleTemplate,
        serviceDuration,
    );
};

const changeTimeSlotTemplateDateTimesReducer: CaseReducer<
    DayScheduleTemplateSliceState,
    PayloadAction<{
        timeSlotTemplateId: string;
        dateTimes: {
            startTime: TimeOfDay;
            endTime: TimeOfDay;
        };
    }>
> = (state, action) => {
    const serviceDuration = state.serviceDuration;

    const dayScheduleTemplate = Object.values(state.entities).find(
        dayScheduleTemplate =>
            dayScheduleTemplate.timeSlotTemplates.some(
                timeSlotTemplate =>
                    timeSlotTemplate.id === action.payload.timeSlotTemplateId,
            ),
    );

    const timeSlotTemplate = dayScheduleTemplate.timeSlotTemplates.find(
        timeSlotTemplate =>
            timeSlotTemplate.id === action.payload.timeSlotTemplateId,
    );

    timeSlotTemplate.startTime =
        action.payload.dateTimes.startTime || timeSlotTemplate.startTime;

    timeSlotTemplate.endTime =
        action.payload.dateTimes.endTime || timeSlotTemplate.endTime;

    processDayScheduleTemplate(
        dayScheduleTemplate as DayScheduleTemplate,
        serviceDuration,
    );
};

const confirmDayScheduleTemplatesSavingReducer: CaseReducer<
    DayScheduleTemplateSliceState
> = state => {
    state.status = 'loaded';
};

const dayScheduleTemplatesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addTimeSlotTemplate: addTimeSlotTemplateReducer,
        removeTimeSlotTemplate: removeTimeSlotTemplateReducer,
        changeTimeSlotTemplateDateTimes: changeTimeSlotTemplateDateTimesReducer,
        confirmDayScheduleTemplatesSaving:
            confirmDayScheduleTemplatesSavingReducer,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDayScheduleTemplates.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchDayScheduleTemplates.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.effectiveDate = mapToFirstEffectiveDate(
                    action.payload.dayScheduleTemplates,
                    action.payload.startDate,
                );
                state.serviceDuration = action.payload.serviceDuration;

                let dayScheduleTemplates = action.payload.dayScheduleTemplates;

                if (dayScheduleTemplates.length === 0) {
                    dayScheduleTemplates = [
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                        'Sunday',
                    ].map(
                        dayOfWeek =>
                            ({
                                dayOfWeek: dayOfWeek,
                                canAddNewSlotTemplate: false,
                                timeSlotTemplates: [],
                            } as DayScheduleTemplate),
                    );
                }

                dayScheduleTemplates.forEach(dayScheduleTemplate =>
                    processDayScheduleTemplate(
                        dayScheduleTemplate,
                        state.serviceDuration,
                    ),
                );

                dayScheduleTemplatesAdapter.setAll(
                    state as DayScheduleTemplateSliceState,
                    dayScheduleTemplates,
                );
            })
            .addCase(fetchDayScheduleTemplates.rejected, state => {
                state.status = 'failed';
            })
            .addCase(saveDayScheduleTemplates.pending, state => {
                state.status = 'saving';
            })
            .addCase(saveDayScheduleTemplates.fulfilled, state => {
                state.status = 'saved';
            })
            .addCase(saveDayScheduleTemplates.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const {
    addTimeSlotTemplate,
    removeTimeSlotTemplate,
    changeTimeSlotTemplateDateTimes,
    confirmDayScheduleTemplatesSaving,
} = dayScheduleTemplatesSlice.actions;

export const {
    selectIds: selectDaysOfWeek,
    selectById: selectDayScheduleTemplateByDayOfWeek,
} = dayScheduleTemplatesAdapter.getSelectors<RootState>(
    state => state.availabilityTemplate,
);

export const selectEffectiveDate = (state: RootState) =>
    state.availabilityTemplate.effectiveDate;
export const selectServiceDuration = (state: RootState) =>
    state.availabilityTemplate.serviceDuration;
export const selectDayScheduleTemplatesStatus = (state: RootState) =>
    state.availabilityTemplate.status;

export default dayScheduleTemplatesSlice.reducer;
