import {
    Box,
    FormControlLabel,
    Radio,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import {LoginImage} from '../../../assets';
import {LoginForm} from '../../../components';
import {AuthErrorReason} from '../../../types/auth';
import {LoginType} from '../LoginPage';
import useStyles from './LoginStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

type Props = {
    selected?: boolean;
    onSubmit: (values: {email: string; password: string}) => void;
    error?: AuthErrorReason;
};

const Login = ({selected, onSubmit, error}: Props) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const {t} = useTranslation(i18nNamespaces.AUTH);

    const radioWrapperStyle = clsx(classes.radioWrapper, {
        [classes.selectedRadioWrapper]: selected,
    });

    return (
        <Box m={1}>
            <Box px={isDesktop ? '25px' : '17px'} className={radioWrapperStyle}>
                <FormControlLabel
                    className={classes.formControlWrapper}
                    value={'LOGIN' as LoginType}
                    control={<Radio className={classes.radio} />}
                    aria-label={t('login')}
                    label={
                        <Box className={classes.radioText}>{t('login')}</Box>
                    }
                />
            </Box>
            <Box
                mt={0.5}
                p={isDesktop ? 2 : 1}
                className={clsx(classes.optionContent, {
                    [classes.contentBackground]: selected,
                })}
            >
                {selected ? (
                    <LoginForm onSubmit={onSubmit} error={error} />
                ) : (
                    <img src={LoginImage} alt="Logowanie" />
                )}
            </Box>
        </Box>
    );
};

export default Login;
