import {
    CaseReducer,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {LoadingStatus} from '../const/loadingStatus';
import individualPackagesProvider from '../services/individualPackagesProvider';
import {
    IndividualPackageDto,
    MedicalServiceGroup,
} from '../types/individualPackages';
import {getIndividualPackagesGroups} from './mappers/mapIndividualPackages';
import {RootState} from './store';
import {bundles} from '../const/individualPackages';

const sliceName = 'individualPackages';

const subscriptionsAdapter = createEntityAdapter<IndividualPackageDto>({
    selectId: individualPackage => individualPackage.id,
});

const initialState = subscriptionsAdapter.getInitialState({
    selectedBundleId: 0 as number,
    individualPackagesGroups: null as MedicalServiceGroup[],
    individualPackages: null as IndividualPackageDto[],
    status: 'idle' as LoadingStatus,
});

export type IndividualPackagesSliceState = typeof initialState;

export const fetchIndividualPackages = createAsyncThunk<
    {
        individualPackages: IndividualPackageDto[];
        individualPackagesGroups: MedicalServiceGroup[];
    },
    {maxUsersCount: number; childrenIncluded?: boolean},
    {state: RootState}
>(
    `${sliceName}/fetchIndividualPackages`,
    async ({maxUsersCount, childrenIncluded}) => {
        const {data} = await individualPackagesProvider.getIndividualPackages(
            maxUsersCount,
            childrenIncluded,
        );
        return {
            individualPackages: data,
            individualPackagesGroups: getIndividualPackagesGroups(data),
        };
    },
);

const selectedBundleIdSpecifiedReducer: CaseReducer<
    IndividualPackagesSliceState,
    PayloadAction<number>
> = (state, action) => {
    state.selectedBundleId = action.payload;
};

const individualPackagesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        selectedBundleIdSpecified: selectedBundleIdSpecifiedReducer,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchIndividualPackages.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchIndividualPackages.fulfilled, (state, action) => {
                const {individualPackages, individualPackagesGroups} =
                    action.payload;
                state.individualPackages = individualPackages;
                state.individualPackagesGroups = individualPackagesGroups;
                state.status = 'loaded';
            })
            .addCase(fetchIndividualPackages.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const selectIndividualPackages = (state: RootState) =>
    state.individualPackages;

export const selectSelectedBundleId = (state: RootState) =>
    selectIndividualPackages(state).selectedBundleId;
export const selectSubscriptionGroups = (state: RootState) =>
    selectIndividualPackages(state).individualPackagesGroups;
export const selectIndividualPackagesStatus = (state: RootState) =>
    selectIndividualPackages(state).status;
export const selectIndividualPackagesIndividualPackages = (state: RootState) =>
    selectIndividualPackages(state).individualPackages;

export const selectSelectedBundle = createSelector(
    [selectSelectedBundleId],
    selectedBundleId => bundles.find(bundle => bundle.id === selectedBundleId),
);

export const actions = individualPackagesSlice.actions;

export const {selectedBundleIdSpecified} = actions;

export default individualPackagesSlice.reducer;
