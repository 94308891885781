export const postFormToLocation = (
    url: string,
    formData: Map<string, string>,
) => {
    const form = document.createElement('form');
    form.style.display = 'none';
    form.method = 'post';
    form.action = url;

    for (const keyValue of formData) {
        const input = document.createElement('input');
        input.type = 'text';
        input.name = keyValue[0];
        input.value = keyValue[1];
        form.appendChild(input);
    }

    document.body.appendChild(form);

    form.submit();
};
