import { ZLAService, rawZLAService } from "../../services/ZLAService";
import { PaginatedResults } from "../../types/common";
import { P1Response } from "../../types/p1Service";
import { AZLADto, CancelReasonDto, CancellationDto, CancellationNotificationDto, IssuedZLADto, LinkedZlaDto, ZLADraftDto, ZLADto, ZUSZLADto } from "../../types/sickLeave";
import {  deleteZLA, getCodes, postDraft, getIfCancellationPossible, getLinkedZLAs, getZLAs, postZLA, getZLAsByAppointment, getZLA } from "./kedZLAApiEndpoints";

class KedZLAApi {

    validateDraft(draft : ZLADto) {
        return ZLAService.post<P1Response<ZLADraftDto>>(
            postDraft(), draft, { withCredentials: true }
        );
    }

    getCancelCode () {
        return ZLAService.get<P1Response<CancelReasonDto[]>>(
            getCodes(),{ withCredentials: true }
        );
    }

    checkIfCancelIsPossible(seriesAndNumber:string) {
        return ZLAService.get<P1Response<CancellationDto>>(
            getIfCancellationPossible(seriesAndNumber),{ withCredentials: true }
        );
    }

    cancelZLA(seriesAndNumber: string, azla: AZLADto){
        return ZLAService.delete<P1Response<CancellationNotificationDto>>(
            deleteZLA(seriesAndNumber), { withCredentials: true, data:azla }
        );
    }

    generateZLA(zla: ZLADto){
        return ZLAService.post<P1Response<string>>(
            postZLA(), zla, { withCredentials: true }
        );
    }

    getSickLeavesByAppointmentId(appointmentId: number){
        return rawZLAService.get<P1Response<IssuedZLADto[]>>(
            getZLAsByAppointment(appointmentId),
        );
    }

    getSickLeaves(page: number, size:number){
        return rawZLAService.get<P1Response<PaginatedResults<IssuedZLADto>>>(
            getZLAs(page, size),
        );
    }
    getLinkedZLA(seriesAndNumber: string){
        return rawZLAService.get<P1Response<LinkedZlaDto[]>>(
            getLinkedZLAs(seriesAndNumber),
        );
    }
    getSickLeave(seriesAndNumber:string){
        return rawZLAService.get<P1Response<IssuedZLADto>>(
            getZLA(seriesAndNumber),
        );
    }
}

export default new KedZLAApi();