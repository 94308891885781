import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { AuxiliaryTestsDto, PhysicalTestsByMedicDto, SpecialistConsultationItemDto } from "../../../types/occupationalMedicine";
import { useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid } from "@material-ui/core";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { RenderCancelNextButton, RenderGridItemKeyboardDatePicker, RenderGridItemWithRequiredKeyboardDatePicker } from "../CommonView";
import { DateTime } from "luxon";

const REQUIRED_STRING = 'requiredField';

export type Props = {
    onCancel: () => void;
    onSubmitSection: (newPlace: SpecialistConsultationItemDto, stayOnCurrent:boolean) =>void;
    data:SpecialistConsultationItemDto;
}

const SpecialistConsultationsCreateItem = ({onCancel, data, onSubmitSection}:Props) =>{
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    
    
    const onSubmit = () => {
        onSubmitSection(values, true);
    }

    const {values, touched, handleChange, handleBlur, errors, handleSubmit, setFieldValue} =
        useFormik({
            initialValues: {             
                referralToSpecialist:data?.referralToSpecialist ?? "",
                referralToSpecialistDate: data?.referralToSpecialistDate ?? null as DateTime,
                consultationDate:data?.consultationDate ?? null as DateTime,
                results:data?.results ?? "",
                            
            },
            validationSchema: yup.object({
                referralToSpecialist: yup.string().required(commonT(REQUIRED_STRING)),
                referralToSpecialistDate: yup.date().nullable().required(commonT(REQUIRED_STRING)),

            }),
            onSubmit,
        }); 


    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={8}>
                        <FormGroup >
                                <Label htmlFor="referralToSpecialist">{t("referralToSpecialist")} *</Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="referralToSpecialist"
                                    name="referralToSpecialist"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.referralToSpecialist}
                                    helperText={touched.referralToSpecialist && t(errors.referralToSpecialist)}
                                    error={touched.referralToSpecialist && !!errors.referralToSpecialist}
                                    />
                            </FormGroup>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    {RenderGridItemWithRequiredKeyboardDatePicker(classes, handleBlur,"referralToSpecialistDate", t('referralDate'),
                                     values.referralToSpecialistDate, setFieldValue, touched.referralToSpecialistDate, 
                                     errors.referralToSpecialistDate, t(errors.referralToSpecialistDate as string)  )}
                    
                    {RenderGridItemKeyboardDatePicker(classes, handleBlur,"consultationDate", t('consultationDate'),
                                     values.consultationDate, setFieldValue )}
                    
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8}>
                        <FormGroup >
                                <Label htmlFor="consultationResults">{t("consultationResults")} </Label>
                                <CustomTextField 
                                    fullWidth 
                                    id="results"
                                    name="results"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.results}
                                    />
                            </FormGroup>
                    </Grid>
                </Grid>
                {RenderCancelNextButton(classes, t, onCancel)}
            </Box>
        </form>
    )
}
export default  SpecialistConsultationsCreateItem;