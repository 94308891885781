import {Avatar, Box, Grid, useMediaQuery, useTheme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {WHITE} from '../../../../const/colors';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {getRelativeDay} from '../../../../utils/date';
import {
    Appointment,
    AppointmentStateEnum,
} from '../../../../types/appointments';
import useStyles from './MedicCardStyles';
import {AppointmentPaymentLabel, AppointmentStateLabel} from '../../../index';
import {HD_READY_DESKTOP_BREAKPOINT} from '../../../../const/sizes';
import {displayUnpaidLabel} from '../../../../utils/appointment';
import { getLanguage, getTranslation } from '../../../../utils/translation';
import i18n from '../../../../i18n/config';

interface Props {
    appointment: Appointment;
}

const MedicCard = ({appointment}: Props) => {
    const theme = useTheme();
    const classes = useStyles();
    const startDateTime = appointment.startDate;
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);
    const isHdReadyDesktop = useMediaQuery(HD_READY_DESKTOP_BREAKPOINT);

    const appointmentState = appointment.state.state;
    const isInTheFuture = appointmentState === AppointmentStateEnum.Planned;
    const isNow = appointmentState === AppointmentStateEnum.InProgress;
    const currentLanguage = getLanguage(i18n?.language);

    const renderAppointmentStatuses = () => {
        return (
            <Box className={classes.appointmentStatesContainer}>
                <AppointmentStateLabel
                    className={classes.appointmentState}
                    state={appointment.state.state}
                />
                {displayUnpaidLabel(appointment.paymentStatus) && (
                    <AppointmentPaymentLabel
                        paymentStatus={appointment.paymentStatus}
                    />
                )}
            </Box>
        );
    };

    return (
        <Box className={classes.container}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                className={classes.appointment}
            >
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.appointmentHeader}
                        style={{
                            backgroundColor: isNow
                                ? theme.palette.secondary.main
                                : theme.palette.grey[300],
                        }}
                    >
                        <Box
                            className={classes.appointmentHeaderDate}
                            style={{
                                color: isNow ? WHITE : '',
                            }}
                        >
                            {getRelativeDay(startDateTime)}
                        </Box>
                        <Box
                            className={classes.appointmentHeaderTime}
                            style={{
                                color: isNow
                                    ? WHITE
                                    : isInTheFuture
                                    ? theme.palette.secondary.main
                                    : theme.palette.grey[500],
                            }}
                        >
                            {startDateTime.toFormat('t')}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item className={classes.appointmentContentWrapper}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.appointmentContent}
                    >
                        <Avatar
                            alt={appointment.medic.surname}
                            src={appointment.medic.photoUrl}
                            className={classes.appointmentPhoto}
                        />
                        <Grid item className={classes.appointmentDetails}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Box className={classes.appointmentMedic}>
                                    {appointment.medic.title}{' '}
                                    {appointment.medic.firstName}{' '}
                                    {appointment.medic.surname}
                                </Box>
                                <Box>{getTranslation(appointment.medicalService.name, appointment.medicalService.translations, currentLanguage, "name")}</Box>
                                <Box>
                                    {t('patient')}:{' '}
                                    {appointment.child !== null
                                        ? appointment.child.firstName
                                        : appointment.patient.firstName}{' '}
                                    {appointment.child !== null
                                        ? appointment.child.surname
                                        : appointment.patient.surname}
                                </Box>
                            </Grid>
                        </Grid>
                        {isHdReadyDesktop && renderAppointmentStatuses()}
                    </Grid>
                </Grid>
            </Grid>
            {!isHdReadyDesktop && renderAppointmentStatuses()}
        </Box>
    );
};

export default MedicCard;
