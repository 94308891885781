import {createStyles, List, ListItem, makeStyles} from '@material-ui/core';
import CustomChip from "../../../common/CustomChip/CustomChip";
import {useAppSelector} from "../../../../hooks/customReduxHooks";
import {selectSelectedMedic} from "../../../../store/referrals/makePatientReferralSlice";

const useStyles = makeStyles(() =>
    createStyles({
        tagsList: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        tagsListItem: {
            width: 'auto',
            padding: 0,
            margin: '8px 8px 0 0',
        }
    }),
);

const MedicTags = () => {
    const classes = useStyles();
    const selectedMedic = useAppSelector(selectSelectedMedic);
    return (
        <List
            className={classes.tagsList}
            disablePadding={true}
        >
            {
                selectedMedic.tags.map((tag, index) => (
                    <ListItem
                        className={classes.tagsListItem}
                        key={index}
                        disableGutters={true}
                    >
                        <CustomChip label={tag} />
                    </ListItem>
                ))
            }
        </List>
    )
}

export default MedicTags;