import {Box, Divider, Grid, Theme} from '@material-ui/core';
import {AddCircle} from '@material-ui/icons';
import {useTheme} from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {DayOfWeek, mapToLocalDayOfWeekName} from '../../../const/dayOfWeek';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    addTimeSlotTemplate,
    selectDayScheduleTemplateByDayOfWeek,
} from '../../../store/dayScheduleTemplatesSlice';
import AvailabilityTemplateTimeSlot from '../AvailabilityTemplateTimeSlot/AvailabilityTemplateTimeSlot';
import useStyles from './AvailabilityTemplateDayStyles';

type AvailabilityTemplateDayProps = {
    dayOfWeek: DayOfWeek;
    serviceDuration:number;
};

const AvailabilityTemplateDay = ({dayOfWeek, serviceDuration}: AvailabilityTemplateDayProps) => {
    const dayScheduleTemplate = useAppSelector(state =>
        selectDayScheduleTemplateByDayOfWeek(state, dayOfWeek),
    );
    const dispatch = useAppDispatch();

    const addTimeSlot = () => {
        if (dayScheduleTemplate.canAddNewSlotTemplate) {
            dispatch(addTimeSlotTemplate(dayScheduleTemplate.dayOfWeek));
        }
    };

    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.AVAILABILITY_TEMPLATE);

    const theme = useTheme() as Theme;

    const classes = useStyles();

    const noTimeSlotsWrapperClasses = clsx(
        classes.noTimeSlotsWrapper,
        classes.timeSlotTemplateWrapper,
    );

    return (
        <Box className={classes.templateDayWrapper}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.dayOfWeekWrapper}
            >
                <Box className={classes.dayOfWeek}>
                    {mapToLocalDayOfWeekName(
                        commonT,
                        dayScheduleTemplate.dayOfWeek,
                    )}
                </Box>
                <AddCircle
                    className={classes.addTimeSlotTemplate}
                    style={{
                        color: dayScheduleTemplate.canAddNewSlotTemplate
                            ? theme.palette.secondary.main
                            : theme.palette.text.secondary,
                    }}
                    onClick={addTimeSlot}
                />
            </Grid>
            <Divider />
            {dayScheduleTemplate.timeSlotTemplates.length > 0 ? (
                dayScheduleTemplate.timeSlotTemplates.map(timeSlotTemplate => (
                    <React.Fragment key={timeSlotTemplate.id}>
                        <Box className={classes.timeSlotTemplateWrapper}>
                            <AvailabilityTemplateTimeSlot
                                timeSlotTemplate={timeSlotTemplate}
                                serviceDuration={serviceDuration}
                            />
                        </Box>
                        <Divider />
                    </React.Fragment>
                ))
            ) : (
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={noTimeSlotsWrapperClasses}
                >
                    <Box className={classes.noTimeSlots}>
                        {t('noAvailableHours')}
                    </Box>
                </Grid>
            )}
        </Box>
    );
};

export default AvailabilityTemplateDay;
