import {makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        margin: mobile.contentLargerSpacing,
        marginTop: 0,
    },
    header: {
        fontSize: 36,
        fontWeight: 'bold',
        padding: `${mobile.contentLargerSpacing} 0`,
    },
    submitButton:{
        width: '100%',
        marginTop:'24px'
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            margin: desktop.contentLargerSpacing,
            marginTop: 0,
        },
        header: {
            fontSize: 56,
            padding: '32px 0',
        },
        submitButton:{
            width: '300px',
            marginTop:'24px'
        },
    },
}));

export default useStyles;
