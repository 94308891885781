import React, {useContext, useEffect, useState} from 'react';
import useStyles from './AccountDataPageStyles';
import {Box, Grid, Link} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router';
import {
    CHANGE_ADDRESS_PAGE,
    CHANGE_PASSWORD_PAGE,
    CHANGE_PHONE_NUMBER_PAGE,
    DECLARATION_OF_PUBLIC_INSURANCE_PAGE,
} from '../../const/routes';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {PatientsChildDto} from '../../types/children';
import AccountProvider from '../../services/accountProvider';
import AddPersonToPackageForm from '../../components/forms/AddPersonToPackageForm/AddPersonToPackageForm';
import accountProvider from '../../services/accountProvider';
import {MemberDto} from '../../types/patientPackages';
import {IconButtonWithText, Loading} from '../../components';
import {RemoveCircleOutline} from '@material-ui/icons';
import {ArrowRightIcon} from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import {AlertContext} from '../../contexts/AlertContext';

type LocationState = {
    successMessage: string;
};
const AccountDataPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const {t: tSettings} = useTranslation(i18nNamespaces.SETTINGS);
    const [patientsChildren, setPatientsChildren] = useState<
        PatientsChildDto[]
    >([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const openAlert = useContext(AlertContext);

    const fetchPatientsChildren = async () => {
        const {data} = await AccountProvider.getPatientChildren();
        setPatientsChildren(data);
    };

    const handleAddChild = async (child: MemberDto) => {
        try {
            setLoading(true);
            setError('');
            await accountProvider.registerPatientChild(child);
            await fetchPatientsChildren();
        } catch (error) {
            console.error(error);
            setError(t('errorWhileAddChild'));
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveChild = async (childId: number) => {
        try {
            setLoading(true);
            setError('');
            await accountProvider.removeChild(childId);
            await fetchPatientsChildren();
        } catch (error) {
            console.error(error);
            setError(t('errorWhileRemoveChild'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!location.state) return;
        openAlert({
            severity: 'success',
            message: location.state.successMessage,
        });
        history.replace({...history.location, state: undefined});
    }, [location.state]);

    useEffect(() => {
        fetchPatientsChildren();
    }, []);

    const renderPatientsChildren = () =>
        patientsChildren.map(child => (
            <Grid
                container
                key={child.id}
                direction={'row'}
                alignItems={'center'}
            >
                <Grid item>
                    <span style={{fontWeight: 'bold'}}>
                        {child.name} {child.surname}・
                    </span>
                    PESEL: {child.pesel}
                </Grid>
                <Grid item>
                    <IconButtonWithText
                        style={{marginLeft: 10}}
                        label={t('remove')}
                        icon={<RemoveCircleOutline />}
                        onClick={() => handleRemoveChild(child.id)}
                    />
                </Grid>
            </Grid>
        ));

    return (
        <Grid container direction="column" className={classes.container}>
            <Loading loading={loading} withBackdrop />
            <Grid container spacing={2} style={{marginBottom: 10}}>
                <Grid item xs={12} md={6}>
                    <Link
                        onClick={() => history.push(CHANGE_PASSWORD_PAGE)}
                        className={classes.buttonTile}
                    >
                        {tSettings('changePassword')}
                        <ArrowRightIcon color={'secondary'} />
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Link
                        onClick={() => history.push(CHANGE_PHONE_NUMBER_PAGE)}
                        className={classes.buttonTile}
                    >
                        {tSettings('changePhone')}
                        <ArrowRightIcon color={'secondary'} />
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Link
                        onClick={() => history.push(CHANGE_ADDRESS_PAGE)}
                        className={classes.buttonTile}
                    >
                        {tSettings('changeAddress')}
                        <ArrowRightIcon color={'secondary'} />
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Link
                        onClick={() =>
                            history.push(DECLARATION_OF_PUBLIC_INSURANCE_PAGE)
                        }
                        className={classes.buttonTile}
                    >
                        {tSettings('insuranceDeclaration')}
                        <ArrowRightIcon color={'secondary'} />
                    </Link>
                </Grid>
            </Grid>
            <Box className={classes.childrenTile}>
                <Box className={classes.childrenTileHeader}>
                    {t('addChildrenAccountFrom5yo')}
                </Box>
                <Box className={classes.childrenTileContent}>
                    {renderPatientsChildren()}
                    <AddPersonToPackageForm
                        isChild
                        onSubmit={handleAddChild}
                        error={error}
                    />
                </Box>
            </Box>
        </Grid>
    );
};

export default AccountDataPage;
