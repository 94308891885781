import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {BLACK, RED} from '../../../../const/colors';
import {mobile} from '../../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: mobile.contentLargerSpacing,
        },
        divider: {
            marginBottom: mobile.contentLargerSpacing,
        },
        formControlLabelContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        formControlLabel: {
            flex: 1,
            margin: `0 ${mobile.contentSmallerSpacing} 0 0`,
            padding: 0,
        },
        radio: {
            marginRight: 12,
            width: 20,
            height: 20,
        },
        label: {
            fontSize: '1rem',
        },
        price: {
            fontSize: 20,
            fontWeight: 'bold',
            color: theme.palette.secondary.main,
        },
        priceBlack: {
            fontSize: 20,
            fontWeight: 'bold',
            color: BLACK,
        },
    }),
);

export default useStyles;
