import {useCallback, useEffect, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {MAGENTA, TEXT_SECONDARY} from '../../../const/colors';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

const DEFAULT_CHARACTERS_LENGTH = 200;

interface Props {
    text: string;
    maxCharactersLength?: number;
    className?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            margin: 0,
            fontSize: 14,
            lineHeight: '24px',
            color: TEXT_SECONDARY,
        },
        truncationToggleText: {
            color: MAGENTA,
            whiteSpace: 'nowrap',
            cursor: 'pointer',
        },
    }),
);

const TextClip = ({text, maxCharactersLength, className}: Props) => {
    const classes = useStyles();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const charactersLength = maxCharactersLength || DEFAULT_CHARACTERS_LENGTH;
    const [textTruncated, setTextTruncated] = useState(false);

    useEffect(() => {
        if (text.length > charactersLength) {
            !textTruncated && setTextTruncated(true);
        } else {
            textTruncated && setTextTruncated(false);
        }
    }, [text]);

    const clipTextToggle = useCallback(() => {
        return (
            <span
                className={classes.truncationToggleText}
                onClick={() => setTextTruncated(t => !t)}
            >
                {textTruncated
                    ? tCommon('showMore').toLowerCase()
                    : tCommon('showLess').toLowerCase()}
            </span>
        );
    }, [textTruncated]);

    return (
        <p className={clsx(classes.container, className)}>
            {textTruncated ? (
                <>
                    {`${text.substring(0, charactersLength)}... `}
                    {clipTextToggle()}
                </>
            ) : (
                <>
                    {text} {text.length > charactersLength && clipTextToggle()}
                </>
            )}
        </p>
    );
};

export default TextClip;
