import React, {ChangeEvent, FC, KeyboardEvent, memo, useEffect, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import {Autocomplete, AutocompleteRenderInputParams, FilterOptionsState} from "@material-ui/lab";
import CustomButton from "../Button/Button";
import CustomTextField from "../CustomTextField/CustomTextField";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import {Loading} from "../../index";
import {includesAllWords} from "../../../utils/string";
import {usePrevious} from "../../../hooks/usePrevious";

export const getStringWithoutUnnecessarySearchCharacters = (value: string) =>
    value.replace(/[^a-zA-Z0-9\s\p{L}.-]/gu, '');

export interface SearchPopupOption {
    id: string;
    label: string;
}

interface Props {
    className: string;
    disabled?: boolean;
    loading: boolean;
    searchDefaultValue?: SearchPopupOption;
    inputPlaceholder?: string;
    buttonClassName?: string;
    checkButtonDisabled?: (inputValue: string) => boolean;
    buttonText?: string;
    popupOptions: SearchPopupOption[];
    onSearchTriggered: (inputValue: string) => void;
    onOptionSelected: (optionSelected: SearchPopupOption) => void;
}

const CustomSearch: FC<Props> = (props) => {
    const {
        className,
        disabled,
        loading,
        searchDefaultValue,
        inputPlaceholder,
        buttonClassName,
        checkButtonDisabled,
        buttonText,
        popupOptions,
        onSearchTriggered,
        onOptionSelected,
    } = props;

    const [inputValue, setInputValue] = useState('');
    const [inputTouched, setInputTouched] = useState(false);

    const [popupOpen, setPopupOpen] = useState(false);
    const [popupTouched, setPopupTouched] = useState(false);

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const prevLoading = usePrevious<boolean>(loading);
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);

    const searchTermChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (!inputTouched) {
            setInputTouched(true);
        }

        if (checkButtonDisabled(value)) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }

        if (value?.length && popupTouched) {
            setPopupOpen(true);
        }

        setInputValue(value);
    }

    const buttonClickHandler = () => {
        onSearchTriggered(inputValue);
    }

    const filterOptions = (
        options: SearchPopupOption[],
        { inputValue }: FilterOptionsState<SearchPopupOption>
    ) => {
        return options.filter(
            ({label}) => includesAllWords(
                getStringWithoutUnnecessarySearchCharacters(label),
                getStringWithoutUnnecessarySearchCharacters(inputValue)
            )
        );
    }

    const handleInputKeyDown = (e: KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            onSearchTriggered(inputValue);
        }
    }

    const autocompleteChangeHandler = (_: ChangeEvent<unknown>, value: string | SearchPopupOption, reason: string) => {
        if (reason === 'select-option') {
            onOptionSelected(value as SearchPopupOption);
        }
    }

    const togglePopupOpen = () => {
        setPopupOpen((value) => !value);
    }

    useEffect(() => {
        const loadingCompleted = !loading && prevLoading;
        if (loadingCompleted) {
            setPopupOpen(true);
        }
        if (loadingCompleted && !popupTouched) {
            setPopupTouched(true);
        }
    }, [loading]);

    return (
        <Box className={className}>
            <Grid
                container
                spacing={1}
                alignItems="center"
            >
                <Grid item xs={12} sm>
                    <Autocomplete
                        disabled={disabled}
                        defaultValue={
                            searchDefaultValue
                                ? !inputTouched
                                    ? searchDefaultValue
                                    : {id: '', label: inputValue}
                                : {id: '', label: ''}
                        }
                        open={popupOpen}
                        onClose={() => setPopupOpen(false)}
                        options={popupOptions}
                        getOptionSelected={(option, value) => option.label === value.label}
                        getOptionLabel={option => option.label}
                        filterOptions={filterOptions}
                        loading={loading}
                        noOptionsText={tCommon('noOptionsToDisplay')}
                        disableClearable={true}
                        onChange={autocompleteChangeHandler}
                        openOnFocus={false}
                        clearOnBlur={false}
                        onBlur={() => setPopupOpen(false)}
                        renderInput={
                            (params: AutocompleteRenderInputParams) => (
                                <CustomTextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    placeholder={inputPlaceholder}
                                    onChange={searchTermChangeHandler}
                                    onKeyDown={handleInputKeyDown}
                                    onClick={() => popupTouched && togglePopupOpen()}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {
                                                    loading
                                                        ? <Loading loading={loading} size={20} />
                                                        : null
                                                }
                                            </>
                                        )
                                    }}
                                />
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm="auto">
                    <CustomButton
                        className={buttonClassName}
                        fullWidth
                        disabled={disabled || buttonDisabled}
                        variant="contained"
                        color="secondary"
                        onClick={buttonClickHandler}
                        onBlur={() => setPopupOpen(false)}
                    >
                        {buttonText || tCommon('search')}
                    </CustomButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default memo(CustomSearch);