import {makeStyles, Theme} from "@material-ui/core";
import { GRAY } from "../../../../const/colors";

const useStyles = makeStyles((theme: Theme) => ({
    picture: {
        width: 210,
        height: 70,
        color: GRAY,
        [theme.breakpoints.up('sm')]: {
            width: 180,
            height: 60,
        }
    },
    icon: {
        width: 80,
        height: 80,
        color: GRAY,
        [theme.breakpoints.up('sm')]: {
            width: 80,
            height: 80,
        }
    },
}));

export default useStyles;