import { Box, createStyles, makeStyles, Theme, FormGroup, Grid, useMediaQuery, TextField, Button, Switch, FormControlLabel } from "@material-ui/core";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { Formik, FormikProvider, useFormik } from "formik";
import { DateTime } from "luxon";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BLACK, WHITE } from "../../../const/colors";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { mobile } from "../../../const/sizes";
import { useAppDispatch, useAppSelector } from "../../../hooks/customReduxHooks";
import { DiagnosticOrderHeadersDto, getPapTestCollectedFrom, PapTestCollectedFromDto, PapTestDetailsDto } from "../../../services/diagnostic/orderHeadersProvider";
import { addSelectedPapTestAdditionalInfo, 
    addSelectedPapTestChildbirthNumber, 
    addSelectedPapTestLastMenstruationDate, 
    selectPapTestCollectedFrom, 
    selectSelectedPapTestAdditionalInfo, 
    selectSelectedPapTestChildbirthNumber, 
    selectSelectedPapTestCollectedFrom, 
    selectSelectedPapTestLastMenstruationDate, 
    setSelectedPapTestCollectedFrom,
    addSelectedPapTestFormOtherInfo} from "../../../store/referrals/medicalTestsSlice";
import { createDateWithZeroTime } from "../../../utils/date";
import TimeOfDay from "../../../utils/timeOfDay";
import papTestAdditionalInfoSchema from "../../../validators/papTestAdditionalInfoSchema";
import { CustomButton, CustomDatePicker, CustomTextField, Label, ReferralForMedicalTestsSummary} from '../../index';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                marginTop: 0,
  
            }
        },
        headerText: {
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: 'bold',
            paddingTop:'25px',
            paddingBottom:'25px',
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
                lineHeight: '32px',
            },
        },

    }),
);

type Props = {
    validate: boolean;
    setValidate : (validate:boolean) =>void;
    setIsValid:(isValid:boolean)=>void;
};


const ReferralForMedicalPapTestSummary = ({validate, setValidate, setIsValid}:Props) => {

    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.REFERRALS);
    const dispatch = useAppDispatch();
    const selectedPapTestCollectedFrom = useAppSelector(selectSelectedPapTestCollectedFrom);
    const selectedPapTestLastMenstruationDate = useAppSelector(selectSelectedPapTestLastMenstruationDate);
    const selectedPapTestChildbirthNumber= useAppSelector(selectSelectedPapTestChildbirthNumber);
    const selectedPapTestAdditionalInfo = useAppSelector(selectSelectedPapTestAdditionalInfo);

    const fetchCollectedFrom = async () => {
        const {data} = await getPapTestCollectedFrom();
        dispatch(setSelectedPapTestCollectedFrom(data));
    }

    useEffect(() => {
        fetchCollectedFrom();    
    }, []);

    const onChangeCollectedFrom = (i: number, e: ChangeEvent<HTMLInputElement>) =>{
        dispatch(selectPapTestCollectedFrom(i));
    }


    const formik =
        useFormik({
            initialValues: {
                lastMenstruationDate: selectedPapTestLastMenstruationDate,
                childBirthNum: selectedPapTestChildbirthNumber,
                additionalInfo: selectedPapTestAdditionalInfo,
                papTestCollectedFrom: selectedPapTestCollectedFrom
            },
            validationSchema: papTestAdditionalInfoSchema,
            onSubmit:null,
            enableReinitialize:true
        });


    useEffect(()=>{
        if(validate){
            formik.setFieldTouched("childBirthNum", true, true);
            formik.setFieldTouched("lastMenstruationDate", true, true);

            if(selectedPapTestLastMenstruationDate!==null && selectedPapTestChildbirthNumber!==null){            
                setIsValid(true);
            }

            setValidate(false);
        }

    }, [validate, selectedPapTestLastMenstruationDate, selectedPapTestChildbirthNumber]);

    return (
    <Box className={classes.root}>
            <Box className={classes.headerText}>
                {t('papTestForm')}
            </Box>
                <Grid 
                    container >
                    <FormGroup
                        style={{
                            marginRight: 24,
                            marginBottom: 24
                        }}>
                        <Label htmlFor="lastMenstruationDate">
                            <span style={{fontWeight: 'bold'}}>
                                {t('lastMenstruationDate')}
                            </span>
                        </Label>

                        <KeyboardDatePicker
                            autoOk
                            value={formik.values.lastMenstruationDate}
                            onChange={(v) => {dispatch(addSelectedPapTestLastMenstruationDate(v.toISOString()))}}
                            variant="inline"
                            format="dd.MM.yyyy"
                            inputVariant="outlined"
                            maxDate={Date.now()}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.lastMenstruationDate && t(formik.errors.lastMenstruationDate as string)
                            }
                            error={formik.touched.lastMenstruationDate && !!formik.errors.lastMenstruationDate}

                        />
                    </FormGroup>

                    <FormGroup
                        style={{
                            marginRight: 24,
                            marginBottom: 24,
                        }}>
                        <Label htmlFor="childBirthNum">
                            <span style={{fontWeight: 'bold'}}>
                                {t('childBirthNum')}
                            </span>
                        </Label>
                        <CustomTextField
                            id="childBirthNum"
                            name="childBirthNum"
                            type="number"
                            onChange={(v) => {dispatch(addSelectedPapTestChildbirthNumber(Number(v.target.value)))}}
                            onBlur={formik.handleBlur}
                            value={formik.values.childBirthNum}
                            helperText={
                                formik.touched.childBirthNum && t(formik.errors.childBirthNum as string)
                            }
                            error={formik.touched.childBirthNum && !!formik.errors.childBirthNum}
                        />
                    </FormGroup>
                </Grid>


                {formik.initialValues.papTestCollectedFrom &&
                <Box>
                        <span style={{fontWeight: 'bold'}}>
                            {t('collectedFrom')}
                        </span>{' '}
                        {formik.values.papTestCollectedFrom.collectedFrom.map((value, i) =>
                        (
                            <Grid key={i} container direction="column">
                                <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                direction="row">

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={value.isSelected}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) => onChangeCollectedFrom(i, e)
                                                }
                                                color={'secondary'}
                                                name={'switch_' + i}
                                            />
                                        }
                                        label={
                                            <Box style={{fontSize: 16}}>
                                                {t(value.name)}
                                            </Box>
                                        }
                                    />
                                </Grid>

                                {value.name == 'Other' && value.isSelected && <Box>
                                    <Label htmlFor={'other'}>
                                        {t('other')}
                                    </Label>
                                    <CustomTextField
                                        id={'other'}
                                        name={'other'}
                                        type="text"
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>,
                                        ) =>
                                            {dispatch(addSelectedPapTestFormOtherInfo(e.target.value))}
                                        }
                                        value={formik.values.papTestCollectedFrom.other}
                                    />
                                </Box>}
                            </Grid>
                        ))}
                        
                        <FormGroup
                            style={{
                                marginRight: 24,
                                marginBottom: 24,
                                marginTop:12
                            }}>
                            <Label htmlFor="additionalInfo">
                                <span style={{fontWeight: 'bold'}}>
                                    {t('additionalInfo')}
                                </span>
                            </Label>
                            <CustomTextField
                                id="additionalInfo"
                                name="additionalInfo"
                                type="text"
                                onChange={(v) => {dispatch(addSelectedPapTestAdditionalInfo(v.target.value))}}
                                onBlur={formik.handleBlur}
                                value={formik.values.additionalInfo}

                            />
                        </FormGroup>

                    </Box>
                }
            </Box>)
}

export default ReferralForMedicalPapTestSummary;