class FilesDownloadProvider {
    downloadFileFromUrl(url: string, downloadFilename: string) {
        fetch(url)
            .then(response => {
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = downloadFilename;
                    a.click();
                });
            })
            .catch(console.error);
    }

    downloadBase64File(
        contentType: string,
        base64Data: string,
        fileName: string,
    ) {
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:${contentType};base64,${base64Data}`;
        downloadLink.download = fileName;
        downloadLink.click();
    }
}

export default new FilesDownloadProvider();
