import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import MedicCardList from '../MedicCardList/MedicCardList';
import MedicFilters from '../MedicFilters/MedicFilters';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            margin: '1px 0 0',
            [theme.breakpoints.up('md')]: {
                margin: '25px 25px 25px 48px',
            },
        },
    }),
);

const MedicSearch = () => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <MedicFilters />
            <MedicCardList />
        </Box>
    );
};

export default MedicSearch;
