import {Attachment, AttachmentTypeEnum} from '../types/appointments';
import {
    downloadAttachment,
    getAttachmentsMetaData,
    removeAttachment,
    uploadAttachments,
} from '../store/appointmentAttachmentsSlice';
import FilesDownloadProvider from '../services/filesDownloadProvider';
import {useAppDispatch} from './customReduxHooks';
import {getFileIcon, toBase64} from '../utils/file';
import {useUserCheck} from './useUserCheck';

export const useHandleRemoveAsync = () => {
    const dispatch = useAppDispatch();
    return async (appointmentId: string, attachmentId: string) => {
        await dispatch(
            removeAttachment({
                appointmentId,
                attachmentId: attachmentId,
            }),
        );
        dispatch(getAttachmentsMetaData({appointmentId, fetchSilently: true}));
    };
};

export const useHandleUploadAsync = () => {
    const dispatch = useAppDispatch();
    return async (
        appointmentId: string,
        files: File[],
        attachmentType?: AttachmentTypeEnum,
    ) => {
        const filesUploadOngoing = files.map(async file => {
            const base64: string = await toBase64(file);
            return dispatch(
                uploadAttachments({
                    appointmentId,
                    uploadData: {
                        fileName: file.name,
                        isPrescription: false,
                        contentType: file.type,
                        content: base64,
                        attachmentType,
                    },
                }),
            );
        });
        await Promise.all(filesUploadOngoing);
        dispatch(getAttachmentsMetaData({appointmentId, fetchSilently: true}));
    };
};

export const useDownloadAsync = () => {
    const dispatch = useAppDispatch();
    return async (appointmentId: string, attachmentId: string) => {
        const attachment: Attachment = (
            await dispatch(
                downloadAttachment({
                    appointmentId: appointmentId,
                    attachmentId: attachmentId,
                }),
            )
        ).payload as Attachment;

        // Workaround for downloading pdf files on ios
        let contentType = attachment.contentType;
        if (attachment.contentType === 'application/pdf')
            contentType = 'application/jpg';
        else contentType = attachment.contentType;

        FilesDownloadProvider.downloadBase64File(
            contentType,
            attachment.content,
            attachment.fileName,
        );
    };
};

export const useGetFileUploadViewModelItems = (
    attachmentsMetaData: Attachment[],
    appointmentId: string,
) => {
    const downloadFileAsync = useDownloadAsync();
    const removeFileAsync = useHandleRemoveAsync();
    const isLoggedUser = useUserCheck();
    return attachmentsMetaData.map(attachmentMetaData => ({
        fileItemKey: attachmentMetaData.id,
        fileItemClickHandler: () =>
            downloadFileAsync(appointmentId, `${attachmentMetaData.id}`),
        fileName: attachmentMetaData.fileName,
        fileIcon: getFileIcon(
            attachmentMetaData.contentType,
            attachmentMetaData.thumbnail,
        ),
        fileOwner: attachmentMetaData.person,
        fileDateCreated:
            attachmentMetaData.uploadDate.toFormat("dd'.'MM'.'yyyy"),
        fileTimeCreated: attachmentMetaData.uploadDate.toFormat("HH':'mm"),
        removeFileEnabled: isLoggedUser(attachmentMetaData.personId),
        removeFileHandler: () =>
            removeFileAsync(appointmentId, `${attachmentMetaData.id}`),
    }));
};
