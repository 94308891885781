import {DayOfWeek} from '../const/dayOfWeek';
import {DayScheduleTemplate} from '../types/dayScheduleTemplates';
import TimeOfDay from '../utils/timeOfDay';

export const processDayScheduleTemplate = (
    dayScheduleTemplate: DayScheduleTemplate,
    serviceDuration: number,
) => {
    const timeSlotTemplates = dayScheduleTemplate.timeSlotTemplates;

    if (timeSlotTemplates.length === 0) {
        dayScheduleTemplate.canAddNewSlotTemplate = true;
        return;
    }

    // Only allow adding new time slot templates if there is still time left till the end of the day.
    const endTime = timeSlotTemplates[timeSlotTemplates.length - 1].endTime;
    dayScheduleTemplate.canAddNewSlotTemplate =
        !endTime.plus(serviceDuration).isExceeded;

    // All slot templates except the last should not be editable.
    const frozenTimeSlotTemplates = timeSlotTemplates.slice(
        0,
        timeSlotTemplates.length - 1,
    );
    frozenTimeSlotTemplates.forEach(
        timeSlotTemplate => (timeSlotTemplate.canEdit = false),
    );
    timeSlotTemplates[timeSlotTemplates.length - 1].canEdit = true;

    // Specify earliest start time for every time slot.
    timeSlotTemplates.forEach((timeSlotTemplate, index) => {
        if (index === 0) {
            timeSlotTemplate.earliestStartTime = TimeOfDay.getEarliestTime();
        } else {
            timeSlotTemplate.earliestStartTime =
                timeSlotTemplates[index - 1].endTime;
        }
    });
};

const dayOfWeekMapping = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
} as {[K in DayOfWeek]: number};

export const compareDayScheduleTemplates = (
    first: DayScheduleTemplate,
    second: DayScheduleTemplate,
) => dayOfWeekMapping[first.dayOfWeek] - dayOfWeekMapping[second.dayOfWeek];
