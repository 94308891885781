import {withStyles} from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { MAGENTA, WHITE } from "../../../const/colors";
import { Accordion } from "@material-ui/core";

export const SickLeaveHistoryAccordion = withStyles((theme) => ({
    root: {
        backgroundColor: WHITE,   
        position:'unset',
    },
    expanded: {},
}))(Accordion);