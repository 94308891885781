import i18n from '../i18n/config';
import {AppointmentConsultationType} from '../types/appointments';

export const formatAppointmentType = (
    appointmentType: AppointmentConsultationType,
) => {
    switch (appointmentType) {
        case 'Chat':
            return i18n.t('appointmentTypeChat');
        case 'Phone':
            return i18n.t('appointmentTypePhone');
        case 'Video':
            return i18n.t('appointmentTypeVideoChat');
    }
};
