import { Box, Grid, IconButton } from "@material-ui/core";
import { useState } from "react";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useStyles from "./MedicalTestCardSelectorStyles";

interface Props {
    showDefault:boolean,
    showDefaultCardView:() => void,
    showSecondCardView:() => void
}

const MedicalTestCardSelector = ({showDefault,showDefaultCardView, showSecondCardView}: Props)  =>{
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.REFERRALS);

    return (
        <Grid 
            container 
            direction={isDesktop ? 'row' : 'column'}
            className={classes.container}
            spacing={1}>
            <Grid item>{t('showListFor')}</Grid>
            <Grid item>
                <IconButton                    
                    className={showDefault ? classes.buttonSelected: classes.button}
                    onClick={showDefaultCardView}
                    >
                    {/* <AccountCircleOutlined 
                        className={classes.editIcon}/> */}
                    {t('medicalTests')}
                </IconButton>
            </Grid>

            <Grid item>
                <IconButton                    
                    className={!showDefault ? classes.buttonSelected: classes.button}
                    onClick={showSecondCardView}
                    >
                    {/* <AccountCircleOutlined 
                        className={classes.editIcon}/> */}
                    {t('packages')}
                </IconButton>
            </Grid>

        </Grid>)
}

export default MedicalTestCardSelector;

