import {
    IndividualPackageDto,
    MedicalServiceGroup,
} from '../../types/individualPackages';

export const getIndividualPackagesGroups = (
    packages: IndividualPackageDto[],
) => {
    const groups: MedicalServiceGroup[] = [];

    packages.forEach(bundle => {
        bundle.medicalServiceGroups.forEach(group => {
            const existingGroupIndex = groups.findIndex(
                (bundleGroup: MedicalServiceGroup) =>
                    bundleGroup.id === group.id,
            );
            const existingGroup = groups[existingGroupIndex];

            existingGroup
                ? (groups[existingGroupIndex] = {
                      ...existingGroup,
                      individualPackagesIds: [
                          ...existingGroup.individualPackagesIds,
                          bundle.id,
                      ],
                  })
                : groups.push({...group, individualPackagesIds: [bundle.id]});
        });
    });

    return groups;
};
