import useStyles from './AdditionalDetailsNotEditableStyles';
import useStylesCommon from '../common/AdditionalDetailsCommonStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Appointment} from '../../../../types/appointments';
import {Box, Divider, IconButton, List, ListItem} from '@material-ui/core';
import clsx from 'clsx';
import {Loading, UploadedItem} from '../../../index';
import React, {useEffect, useMemo, useState} from 'react';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';
import {selectAllAttachmentsMetaData} from '../../../../store/appointmentAttachmentsSlice';
import {useDownloadAsync} from '../../../../hooks/appointmentFileUpload';
import {getFileIcon} from '../../../../utils/file';
import {PdfImage} from '../../../../assets';
import appointmentsProvider from '../../../../services/appointmentsProvider';
import FilesDownloadProvider from '../../../../services/filesDownloadProvider';
import filesDownloadProvider from '../../../../services/filesDownloadProvider';
import {CustomAlert} from '../../../common/feedback';
import PrescriptionsListItem from '../../../prescription/PrescriptionsListItem/PrescriptionsListItem';
import {
    fetchPrescriptions,
    selectPrescriptions,
    selectPrescriptionsStatus,
} from '../../../../store/prescriptionSlice';

interface Props {
    appointment: Appointment;
}

const AdditionalDetailsNotEditable = ({appointment}: Props) => {
    const classes = useStyles();
    const classesCommon = useStylesCommon();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const downloadAsync = useDownloadAsync();
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const allAttachmentsMetaData = useAppSelector(selectAllAttachmentsMetaData);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch = useAppDispatch();
    const prescriptionsStatus = useAppSelector(selectPrescriptionsStatus);
    const prescriptions = useAppSelector(selectPrescriptions);

    const appointmentNotes = useMemo(
        () => [
            {
                header: `${t('reasonsProvided')} ?`,
                note: appointment?.patientReasons,
                download: false,
            },
            {
                header: `${t('interviewWithPatient')}`,
                note: appointment?.medicalInterview,
                download: true,
            },
            {
                header: `${t('drugMedicalRecommendations')}`,
                note: appointment?.drugMedicalRecommendations,
                download: true,
            },
            {
                header: `${t('referralMedicalRecommendations')}`,
                note: appointment?.referralMedicalRecommendations,
                download: true,
            },
            {
                header: `${t('otherMedicalRecommendations')}`,
                note: appointment?.otherMedicalRecommendations,
                download: true,
            },
        ],
        [appointment],
    );

    const downlaodMedicRecommendations = async () => {
        try {
            setErrorMessage('');
            setLoading(true);
            const {data} = await appointmentsProvider.getRecommendations(
                appointment.id,
            );

            filesDownloadProvider.downloadFileFromUrl(
                data,
                `${t('recommendations')}.pdf`,
            );
        } catch (error) {
            setErrorMessage(t('downloadRecommendationError'));
        } finally {
            setLoading(false);
        }
    };

    const downlaodChatTranscription = async () => {
        try {
            setErrorMessage('');
            setLoading(true);
            const {data} = await appointmentsProvider.getChatTranscript(
                appointment.id,
            );

            filesDownloadProvider.downloadFileFromUrl(
                data,
                `${t('chatTranscription')}.pdf`,
            );
        } catch (error) {
            setErrorMessage(t('downloadRecommendationError'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        dispatch(
            fetchPrescriptions({appointmentId: parseInt(appointment?.id)}),
        );
    }, []);

    return (
        <>
            <Loading
                loading={loading || prescriptionsStatus === 'loading'}
                withBackdrop
            />
            {errorMessage && (
                <CustomAlert severity="error" message={errorMessage} />
            )}
            {appointmentNotes.map((appointmentNote, index) => (
                <Box className={`${classes.reasonsContainer}`} key={index}>
                    <p className={classes.headerContainer}>
                        <h4 className={classesCommon.detailsHeader}>
                            {appointmentNote.header}
                        </h4>
                    </p>
                    <p className={classes.reasons}>
                        {appointmentNote.note || tCommon('noInformation')}
                    </p>
                </Box>
            ))}
            <Box style={{marginBottom: 20}}>
                <h4 className={`${classesCommon.detailsHeader}`}>
                    {t('prescriptions')}
                </h4>
                <Box className={classes.listWrapper}>
                    {prescriptions?.length > 0
                        ? prescriptions?.map((prescriptionSet, idx) => (
                              <PrescriptionsListItem
                                  index={idx}
                                  key={idx}
                                  prescriptionSet={prescriptionSet}
                                  downloadable
                              />
                          ))
                        : tCommon('noInformation')}
                </Box>
            </Box>
            <Box>
                <h4 className={`${classesCommon.detailsHeader}`}>
                    {t('files')}
                </h4>
                <Box className={classes.listWrapper}>
                    {allAttachmentsMetaData &&
                    allAttachmentsMetaData.length > 0 ? (
                        <List className={classesCommon.filesList}>
                            {allAttachmentsMetaData.map(
                                (attachmentMetaData, i) => (
                                    <ListItem
                                        className={clsx(
                                            classesCommon.filesListItem,
                                            {
                                                [classesCommon.filesListItemNext]:
                                                    i > 0,
                                            },
                                        )}
                                        onClick={() =>
                                            downloadAsync(
                                                appointment.id,
                                                `${attachmentMetaData.id}`,
                                            )
                                        }
                                        key={attachmentMetaData.id}
                                    >
                                        <UploadedItem
                                            fileName={
                                                attachmentMetaData.fileName
                                            }
                                            fileOwner={
                                                attachmentMetaData.person
                                            }
                                            fileIcon={getFileIcon(
                                                attachmentMetaData.contentType,
                                                attachmentMetaData.thumbnail,
                                            )}
                                            fileDateCreated={attachmentMetaData.uploadDate.toFormat(
                                                "dd'.'MM'.'yyyy",
                                            )}
                                            fileTimeCreated={attachmentMetaData.uploadDate.toFormat(
                                                "HH':'mm",
                                            )}
                                            removeFileEnabled={false}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    ) : (
                        <p>{tCommon('noFilesAttached')}</p>
                    )}
                </Box>
            </Box>

            <Box className={`${classes.reasonsContainer}`}>
                <p className={classes.headerContainer}>
                    <h4 className={`${classesCommon.detailsHeader}`}>
                        {t('downloadRecommendations')}
                    </h4>
                </p>

                {appointment.recommendationIsAvailable ? (
                    <Box
                        style={{
                            cursor: 'pointer',
                            padding: '8px',
                        }}
                        onClick={() => downlaodMedicRecommendations()}
                    >
                        <img src={PdfImage} width={40} />
                    </Box>
                ) : (
                    <p className={classes.reasons}>
                        {tCommon('recommendationWasNotGenerated')}
                    </p>
                )}
            </Box>

            {appointment.type !== 'Phone' && (
                <Box className={`${classes.reasonsContainer}`}>
                    <p className={classes.headerContainer}>
                        <h4 className={`${classesCommon.detailsHeader}`}>
                            {t('downloadChatTranscription')}
                        </h4>
                    </p>

                    {appointment.chatTranscriptionIsAvailable ? (
                        <Box
                            style={{
                                cursor: 'pointer',
                                padding: '8px',
                            }}
                            onClick={() => downlaodChatTranscription()}
                        >
                            <img src={PdfImage} width={40} />
                        </Box>
                    ) : (
                        <p className={classes.reasons}>
                            {tCommon('recommendationWasNotGenerated')}
                        </p>
                    )}
                </Box>
            )}
        </>
    );
};

export default AdditionalDetailsNotEditable;
