import {makeStyles, Theme} from '@material-ui/core';
import { BACKGROUND_PRIMARY, WHITE } from '../../const/colors';
import { desktop } from '../../const/sizes';

export default makeStyles((theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
        container: {
            margin: desktop.contentSmallerSpacing,
            marginLeft: desktop.contentLargerSpacing,
            padding: 0,
            paddingBottom: 24,
        },
    },
    container:{
        backgroundColor: WHITE,
        padding:24,
        marginTop:24,
        marginBottom:24
    },
    input: {
        width: 300,
    },
    button: {
        width: 300,
    },
}));
