import React, {FC, memo, useCallback, useState} from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {hasMinimumLength, splitTextBySpaces} from '../../../utils/string';
import {getIcd10Codes} from '../../../services/ICD10Provider';
import {ICD10Code} from '../../../types/icd10';
import CustomSearch, {
    getStringWithoutUnnecessarySearchCharacters,
    SearchPopupOption,
} from './CustomSearch';
import {GRAY} from '../../../const/colors';
import {CustomAlert} from '../feedback';
import {getIcd10CodeNameForProvidedLanguage} from '../../../utils/icd10';

const ICD10_SEARCH_TERM_MIN_LENGTH = 3;
const POPUP_ICD10_CODE_AND_NAME_SEPARATOR = '-';

const icd10SearchCodeRegex = new RegExp('^[a-zA-Z][0-9].*$', 'm');

const checkSearchButtonDisabled = (value: string) => {
    const isICD10SearchCode = icd10SearchCodeRegex.test(value);
    const hasMinCharactersLength = hasMinimumLength(
        ICD10_SEARCH_TERM_MIN_LENGTH,
        value,
    );
    return !(isICD10SearchCode || hasMinCharactersLength);
};

const createSearchOptionLabel = (icd10Code: ICD10Code, language: string) => {
    const icd10CodeName = getIcd10CodeNameForProvidedLanguage(
        icd10Code,
        language,
    );
    return `${icd10Code.code} ${POPUP_ICD10_CODE_AND_NAME_SEPARATOR} ${icd10CodeName}`;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            marginTop: 32,
        },
        searchButton: {
            [theme.breakpoints.up('sm')]: {
                padding: '12px 53px',
            },
        },
    }),
);

interface ICD10SearchProps {
    defaultIcd10Code?: ICD10Code;
    onIcd10CodeSelected: (icd10Code: string) => void;
    disabled?: boolean;
}

const ICD10Search: FC<ICD10SearchProps> = props => {
    const {defaultIcd10Code, onIcd10CodeSelected, disabled} = props;

    const [icd10PopupOptions, setIcd10PopupOptions] = useState<
        SearchPopupOption[]
    >([]);
    const [icd10CodesLoading, setIcd10CodesLoading] = useState(false);
    const [icd10CodesLoadingError, setIcd10CodesLoadingError] = useState('');

    const classes = useStyles();
    const {i18n} = useTranslation();
    const {t: tIcd} = useTranslation(i18nNamespaces.ICD);

    const searchDefaultValue = defaultIcd10Code
        ? {
              id: defaultIcd10Code.code,
              label: createSearchOptionLabel(defaultIcd10Code, i18n.language),
          }
        : null;

    const createSearchOptionsForSelectedLanguage = useCallback(
        (icd10Codes: ICD10Code[]) => {
            console.log(
                'createSearchOptionsForSelectedLanguage in ICD10Search',
                i18n.language,
            );
            setIcd10PopupOptions(
                icd10Codes.map(icd10Code => ({
                    id: icd10Code.code,
                    label: createSearchOptionLabel(icd10Code, i18n.language),
                })),
            );
        },
        [],
    );

    const searchTriggeredHandler = useCallback(async (searchTerm: string) => {
        const searchTermWithoutUnnecessaryCharacters =
            getStringWithoutUnnecessarySearchCharacters(searchTerm);

        const query = splitTextBySpaces(searchTermWithoutUnnecessaryCharacters)
            .filter(
                // removes standalone dash characters
                st => st !== POPUP_ICD10_CODE_AND_NAME_SEPARATOR,
            )
            .join(' ');

        setIcd10PopupOptions([]);
        setIcd10CodesLoading(true);
        setIcd10CodesLoadingError('');

        try {
            const {data: codes} = await getIcd10Codes(query);
            createSearchOptionsForSelectedLanguage(codes);
        } catch (e) {
            setIcd10CodesLoadingError(tIcd('icd10CodesFetchError'));
            console.error(e);
        } finally {
            setIcd10CodesLoading(false);
        }
    }, []);

    const closeIcd10CodesLoadingErrorAlert = useCallback(() => {
        setIcd10CodesLoadingError('');
    }, []);

    const icd10CodeSelectedHandler = useCallback(
        (icd10CodeOption: SearchPopupOption) => {
            onIcd10CodeSelected(icd10CodeOption.id);
        },
        [],
    );

    return (
        <Box>
            <CustomSearch
                className={classes.search}
                disabled={disabled}
                loading={icd10CodesLoading}
                searchDefaultValue={searchDefaultValue}
                buttonClassName={classes.searchButton}
                checkButtonDisabled={checkSearchButtonDisabled}
                popupOptions={icd10PopupOptions}
                onSearchTriggered={searchTriggeredHandler}
                onOptionSelected={icd10CodeSelectedHandler}
            />
            <Typography
                variant={'body2'}
                style={{
                    marginTop: 8,
                    color: GRAY,
                }}
            >
                {tIcd('minCharactersLengthOfICD10Search')}
            </Typography>
            {icd10CodesLoadingError && (
                <CustomAlert
                    severity="error"
                    message={icd10CodesLoadingError}
                    onClose={closeIcd10CodesLoadingErrorAlert}
                />
            )}
        </Box>
    );
};

export default memo(ICD10Search);
