import {
    CaseReducer,
    createSlice,
    PayloadAction,
    createSelector,
} from '@reduxjs/toolkit';
import {Message} from '@twilio/conversations/lib/message';
import {LoadingStatus} from '../const/loadingStatus';
import {RootState} from './store';

const initialState = {
    messages: [] as Message[],
    status: 'idle' as LoadingStatus,
};

export type MessagesSliceState = typeof initialState;

const addMessageReducer: CaseReducer<
    MessagesSliceState,
    PayloadAction<{message: Message}>
> = (state, action) => {
    const {message} = action.payload;
    if (message.type !== 'media') state.messages = [...state.messages, message];
};

const addMessagesReducer: CaseReducer<
    MessagesSliceState,
    PayloadAction<{messages: Message[]}>
> = (state, action) => {
    const {messages} = action.payload;

    state.messages = [
        ...state.messages,
        ...messages.filter(m => m.type !== 'media'),
    ];
};

const removeMessageReducer: CaseReducer<
    MessagesSliceState,
    PayloadAction<{message: Message}>
> = (state, action) => {
    const {message} = action.payload;
    if (message.type !== 'media')
        state.messages = state.messages.filter(m => m !== message);
};

const addPreviosMessagesReducer: CaseReducer<
    MessagesSliceState,
    PayloadAction<{messages: Message[]}>
> = (state, action) => {
    const {messages} = action.payload;
    state.messages = [
        ...messages.filter(m => m.type !== 'media'),
        ...state.messages,
    ];
};

const removeAllMessagesReducer: CaseReducer<
    MessagesSliceState,
    PayloadAction<Record<string, unknown>>
> = state => {
    state.messages = [];
};

const consultationStatusSpecifiedReducer: CaseReducer<
    MessagesSliceState,
    PayloadAction<LoadingStatus>
> = (state, action) => {
    state.status = action.payload;
};

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        addMessage: addMessageReducer,
        removeMessage: removeMessageReducer,
        addMessages: addMessagesReducer,
        addPreviosMessages: addPreviosMessagesReducer,
        removeMessagesAll: removeAllMessagesReducer,
        consultationStatusSpecified: consultationStatusSpecifiedReducer,
    },
});

export const selectMessages = (state: RootState) => state.messages;
export const selectStoreMessages = createSelector([selectMessages], state => {
    return state.messages;
});
export const selectMessagesStatus = (state: RootState) =>
    selectMessages(state).status;

export const {
    addMessage,
    removeMessage,
    addMessages,
    addPreviosMessages,
    removeMessagesAll,
    consultationStatusSpecified,
} = messagesSlice.actions;
export default messagesSlice.reducer;
