import { Box, Grid, Theme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../const/i18nNamespaces";
import useStyles from "./PhoneNumberChangedPageStyles";
import {FinishScheduleImage} from '../../assets';
import { LOGIN_PAGE, MEDIC_SETTINGS, USER_ACCOUNT_PAGE } from "../../const/routes";
import { useHistory } from "react-router";
import { useAppSelector } from "../../hooks/customReduxHooks";
import {selectAuthUserData} from '../../store/auth';
import { CustomButton } from "../../components";
import { MEDIC } from "../../const/auth";

const PhoneNumberChangedPage = () => {

    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.SETTINGS);
    const history = useHistory();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const authUserData = useAppSelector(selectAuthUserData);


    return (
        <Grid
            container
            className={classes.container}
            alignItems={'center'}
            direction={'column'}
        >
            <Box className={classes.title}>{t('phoneNumberHasBeenChanged')}</Box>
            <Box className={classes.subtitle}>
                {t('youCanUseNewPhoneNumberNow')}
            </Box>
            <img
                src={FinishScheduleImage}
                alt="Finish password change"
                className={classes.image}
            />
            <CustomButton
                color={'secondary'}
                variant={'contained'}
                fullWidth={!isDesktop}
                onClick={() =>
                    history.push(authUserData ?
                        (authUserData.role === MEDIC ? MEDIC_SETTINGS : USER_ACCOUNT_PAGE)
                         : LOGIN_PAGE)
                }
            >
                
                {authUserData ? t('goBackToAccount') : t('goToLogin')}
            </CustomButton>
        </Grid>
    );
}

export default PhoneNumberChangedPage