import {DateTime} from 'luxon';
import {TimeSlot, TimeSlotDto} from '../../types/timeSlot';

const mapTimeSlot = (timeSlotDto: TimeSlotDto) =>
    ({
        ...timeSlotDto,
        startDate: DateTime.fromISO(timeSlotDto.startDate),
        endDate: DateTime.fromISO(timeSlotDto.endDate),
    } as TimeSlot);

export default mapTimeSlot;
