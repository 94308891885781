import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            margin: '24px 24px 24px 47px',
        },
    },
    tileWrapper: {
        marginTop: 8,
        [theme.breakpoints.up('md')]: {
            '&:first-child': {
                marginTop: 0,
            }
        },
    },
    saveButtonContainer: {
        margin: '40px 0 0 0',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
            margin: '24px 0 40px 0',
        },
    },
    saveChangesButton: {
        width: '100%',
        borderRadius: 0,
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            borderRadius: 3,
            padding: '12px 88px',
        },
    },
}));

export default useStyles;