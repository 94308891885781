import {createStyles, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioWrapper: {
            border: '1px solid #dedede',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: 700,
            height: 72,
            borderRadius: 3,
            [theme.breakpoints.down('xs')]: {
                height: 56,
            },
        },
        formControlWrapper: {
            width: '100%',
            height: '100%',
        },
        radio: {marginRight: 8},
        selectedRadioWrapper: {
            backgroundColor: '#dedede',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        radioText: {
            fontSize: '20px',
            fontWeight: 700,
        },
        optionContent: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            borderBottomRightRadius: 3,
            borderBottomLeftRadius: 3,
        },
        contentBackground: {
            backgroundColor: '#dedede',
        },
        image: {
            width: '90%',
        },
    }),
);

export default useStyles;
