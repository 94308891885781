import useQuery from './useQuery';
import {useEffect} from 'react';
import i18next from 'i18next';
import {checkIfLanguageExists} from '../utils/lang';

const useLanguageFromParam = () => {
    const query = useQuery();
    const lang = query.get('lang');

    useEffect(() => {
        if (lang && checkIfLanguageExists(lang))
            i18next
                .changeLanguage(lang)
                .catch(err => console.error('Language error: ' + err));
    }, [lang]);

    return;
};

export default useLanguageFromParam;
