import {
    Badge,
    Box,
    Button,
    IconButton,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {
    AttachFile,
    CallEnd,
    ChatOutlined,
    Close,
    MicNoneOutlined,
    MicOffOutlined,
    SettingsOutlined,
    SpeakerNotesOffOutlined,
    VideocamOffOutlined,
    VideocamOutlined,
} from '@material-ui/icons';
import TopBar from '../../Layout/TopBar/TopBar';
import useStyles from './ConversationTopBarStyles';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectAppointmentDetails,
    selectConsultationStatus,
} from '../../../store/consultationSlice';
import ConsultationInfo from '../ConsultationInfo/ConsultationInfo';
import {MAGENTA} from '../../../const/colors';
import {useEffect, useRef} from 'react';

interface Props {
    handleSettings?: () => void;
    handleHangout?: () => void;
    handleSwitchVideo?: () => void;
    handleSwitchMic?: () => void;
    handleSwitchChat?: () => void;
    handleAttachFile?: () => void;
    chatOpen?: boolean;
    attachFileOpen?: boolean;
    videoInputOn?: boolean;
    audioInputOn?: boolean;
    chatConversation?: boolean;
    unseenMessages?: number;
}

const ConversationTopBar = ({
    handleSettings,
    handleHangout,
    handleSwitchVideo,
    handleSwitchMic,
    handleSwitchChat,
    handleAttachFile,
    chatOpen,
    attachFileOpen,
    videoInputOn,
    audioInputOn,
    chatConversation,
    unseenMessages,
}: Props) => {
    const classes = useStyles();
    const iconStyle = {fill: 'black', fontSize: 23};
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const consultationStatus = useAppSelector(selectConsultationStatus);
    const consultationDetails = useAppSelector(selectAppointmentDetails);

    const topBarElement: HTMLElement = document.querySelector(
        '.video-conversation-top-bar-ref .MuiAppBar-root',
    );

    useEffect(() => {
        if (topBarElement) {
            const topBarFixedPlaceholder: HTMLElement = document.querySelector(
                '.video-conversation-top-bar-ref .MuiAppBar-root ~ .MuiToolbar-gutters',
            );
            topBarFixedPlaceholder.style.height = `${topBarElement?.offsetHeight}px`;
        }
    }, [topBarElement?.offsetHeight]);

    return (
        <div className="video-conversation-top-bar-ref">
            <TopBar className={classes.topbar}>
                {isDesktop && consultationStatus === 'loaded' && (
                    <ConsultationInfo
                        consultation={consultationDetails}
                        inConsultationView
                    />
                )}
                <Box flexDirection="row" display="flex">
                    {isDesktop && handleSettings && (
                        <IconButton
                            style={{marginRight: 10}}
                            onClick={handleSettings}
                        >
                            <SettingsOutlined style={iconStyle} />
                        </IconButton>
                    )}
                    {handleSwitchVideo && (
                        <IconButton
                            style={{marginRight: 10}}
                            onClick={handleSwitchVideo}
                        >
                            {videoInputOn ? (
                                <VideocamOutlined style={iconStyle} />
                            ) : (
                                <VideocamOffOutlined style={iconStyle} />
                            )}
                        </IconButton>
                    )}
                    {handleSwitchMic && (
                        <IconButton
                            style={{marginRight: 10}}
                            onClick={handleSwitchMic}
                        >
                            {audioInputOn ? (
                                <MicNoneOutlined style={iconStyle} />
                            ) : (
                                <MicOffOutlined style={iconStyle} />
                            )}
                        </IconButton>
                    )}

                    {isDesktop && (
                        <>
                            {handleSwitchChat && (
                                <Badge
                                    color="secondary"
                                    overlap="circular"
                                    badgeContent={unseenMessages}
                                >
                                    <IconButton
                                        style={{marginRight: 10}}
                                        onClick={handleSwitchChat}
                                    >
                                        {chatOpen ? (
                                            <ChatOutlined style={iconStyle} />
                                        ) : (
                                            <SpeakerNotesOffOutlined
                                                style={iconStyle}
                                            />
                                        )}
                                    </IconButton>
                                </Badge>
                            )}

                            {handleAttachFile && (
                                <IconButton
                                    style={{marginRight: 10}}
                                    onClick={handleAttachFile}
                                >
                                    <AttachFile
                                        style={{
                                            ...iconStyle,
                                            fill: attachFileOpen
                                                ? MAGENTA
                                                : 'black',
                                        }}
                                    />
                                </IconButton>
                            )}
                        </>
                    )}
                    {handleHangout &&
                        (isDesktop ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.callEndButton}
                                onClick={handleHangout}
                                disableElevation
                            >
                                {chatConversation ? <Close /> : <CallEnd />}
                            </Button>
                        ) : (
                            <IconButton onClick={handleHangout}>
                                <Close />
                            </IconButton>
                        ))}
                </Box>
            </TopBar>
        </div>
    );
};

export default ConversationTopBar;
