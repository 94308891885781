import {KnowledgeBaseDto} from '../../types/articles';

const mapArticles = (articleDto: KnowledgeBaseDto) =>
    ({
        id: articleDto.id,
        title: articleDto.title,
        text: articleDto.text,
        creationDateTime: articleDto.creationDateTime,
    } as KnowledgeBaseDto);

export default mapArticles;
