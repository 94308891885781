class SafariAudioOutput implements MediaDeviceInfo {
    deviceId: string;
    groupId: string;
    kind: MediaDeviceKind;
    label: string;
    toJSON() {
        this.toJSON();
    }

    constructor() {
        this.deviceId = 'default';
        this.kind = 'audiooutput';
        this.label = 'Urządzenie wbudowane';
    }
}

class FirefoxAudioOutput implements MediaDeviceInfo {
    deviceId: string;
    groupId: string;
    kind: MediaDeviceKind;
    label: string;
    toJSON() {
        this.toJSON();
    }

    constructor() {
        this.deviceId = 'default';
        this.kind = 'audiooutput';
        this.label = 'Urządzenie wbudowane';
    }
}

class BrowerDefaultMediaDevicesProvider {
    getSafariAudioOutput() {
        return [new SafariAudioOutput()];
    }

    getFirefoxAudioOutput() {
        return [new FirefoxAudioOutput()];
    }
}

export default new BrowerDefaultMediaDevicesProvider();
