import {Theme, makeStyles} from "@material-ui/core";
import {GRAY, RED} from "../../../../const/colors";

const useStyles = makeStyles((theme:Theme) => ({
    modalContent: {
       paddingTop: 45,
    },
    cropperWrapper: {
        width: 300,
        height: 300,
        position: 'relative',
    },
    cropperWrapperRectange:{
        width: 600,
        height: 300,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: 320,
            height: 80,
        }
    },
    closeIcon: {
        position: 'absolute',
        top: 7,
        right: 0,
        color: GRAY,
    },
    cropDoneButton: {
        marginTop: 16,
    },
    zoomSliderContainer: {
        marginTop: 14,
    },
    croppedAreaToSmall: {
        marginTop: 14,
        maxWidth: 300,
        textAlign: 'center',
        color: RED,
    }
}));

export default useStyles;