import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { BLACK, GRAY, GREEN, LIGHT_GRAY, LIGHT_GRAY_BORDER, MAGENTA } from '../../../const/colors';
import { mobile } from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        border:{
            border:' 2px solid',
            borderColor: LIGHT_GRAY_BORDER,
            borderRadius:'16px',
            padding:'16px'
        },
        actionIconContainer:{
        },
        closeButtonCntainer:{
            display:'flex',
            justifyContent:'flex-end',    
            "& .MuiIconButton-root": {
                padding: '0',
                flex:'0 0 auto',      
            }   
        },
        closeButton:{
            height: '16px',
            width: '16px',
        },
        editButtonContainer:{
            display:'flex',
            justifyContent:'flex-end',
            height:'70%',
            alignItems:'end',
            "& .MuiIconButton-root": {
                padding: '0',
                flex:'0 0 auto',      
            }
        },
        iconButton:{
            color:BLACK
        },
        link: {
            margin: '0',
            color: theme.palette.secondary.main,
            fontSize: 16,
            lineHeight: '24px',
            textAlign: 'end',
            textDecoration: 'none',
            overflowWrap: 'anywhere',
            whiteSpace: 'pre-line',
        },    
    }),
);

export default useStyles;
