import {Box, createStyles, Grid, makeStyles} from '@material-ui/core';
import {LogoAndCloseTopBar, TopBar} from '../../index';
import {WHITE} from "../../../const/colors";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/customReduxHooks";
import {selectSelectedMedic, unselectMedic} from "../../../store/referrals/makePatientReferralSlice";

const useStyles = makeStyles(() =>
    createStyles({
        referralForPatientGoBack: {
            marginTop: 1,
            padding: '14px 16px',
            backgroundColor: WHITE,
        },
        referralForPatient: {
            fontSize: 18,
            lineHeight: '24px',
            fontWeight: 'bold',
        },
    }),
);

interface Props {
    referralForText: string,
    firstName?: string,
    surname?: string,
    onCloseIconClick?: () => void;
}

const HeaderMobile = ({referralForText, firstName, surname}: Props) => {
    const classes = useStyles();
    const medicSelected = useAppSelector(selectSelectedMedic);
    const dispatch = useAppDispatch();

    const closeClickHandler = () => {
        dispatch(unselectMedic());
    }

    return (
        <Box>
            {
                medicSelected
                    ? (
                        <LogoAndCloseTopBar onCloseIconClick={closeClickHandler} />
                    )
                    : <TopBar />
            }
            <Grid
                className={classes.referralForPatientGoBack}
                container
                alignItems={'center'}
                wrap={'nowrap'}
            >
                {/*GoBackIconButton will be used in referral details page which is not created yet*/}
                {/* !medicSelected && <GoBackIconButton /> */}
                <Box className={classes.referralForPatient}>
                    {`${referralForText} ${firstName} ${surname}`}
                </Box>
            </Grid>
        </Box>
    )
}

export default HeaderMobile;