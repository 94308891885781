import {Box, Button, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import { useHistory } from 'react-router';
import {ErrorImage} from '../../assets';
import i18nNamespaces from '../../const/i18nNamespaces';
import { LOGIN_PAGE } from '../../const/routes';
import useStyles from './ErrorPageStyles';

const ErrorPage = () => {
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const history = useHistory();
    
    const classes = useStyles();
    const redirectToMainPage = () =>{
        history.push(LOGIN_PAGE)
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.errorWrapper}
        >
            <Box className={classes.errorTitle}>{t('errorTile')}</Box>
            <Box className={classes.errorContent}>{t('errorContent')}</Box>
            <Box>
                <img
                    src={ErrorImage}
                    alt={t('error')}
                    className={classes.errorImage}
                />
            </Box>
            <Button
                className={classes.refreshButton}
                variant={'contained'}
                color={'secondary'}
                onClick={redirectToMainPage}>
                {t('refresh')}
            </Button>
        </Grid>
    );
};

export default ErrorPage;
