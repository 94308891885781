import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    list: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    listItem: {
        width: 'auto',
        padding: 0,
        margin: '8px 8px 0 0',
    },
}));

export default useStyles;
