import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    dropzone: {
        marginTop: '48px',
        [theme.breakpoints.up('md')]: {
            marginTop: '56px',
        },
    },
    conditionsInputLabel: {
        marginTop: '16px',
        display: 'block',
        marginBottom: '8px',
    },
    saveBtn: {
        marginTop: '24px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginTop: '40px',
        },
    },
}));

export default useStyles;
