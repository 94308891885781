import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
} from '@material-ui/core';
import {useFormik} from 'formik';
import PersonalDataComponent from '../../components/occupationalMedicine/I_patientPersonalData/PatientPersonalDataComponent';
import {
    PatientPersonalDataDto,
    PatientPlaceOfWorkOrOfStudyDto,
    PatientStatementDto,
} from '../../types/occupationalMedicine';
import PlaceOfWorkComponent from '../../components/occupationalMedicine/II_patientPlaceOfWorkOrOfStudyData/PatientPlaceOfWorkOrOfStudyComponent';
import PatientPersonalDataComponent from '../../components/occupationalMedicine/I_patientPersonalData/PatientPersonalDataComponent';
import PatientPlaceOfWorkOrOfStudyComponent from '../../components/occupationalMedicine/II_patientPlaceOfWorkOrOfStudyData/PatientPlaceOfWorkOrOfStudyComponent';
import CustomTextField from '../../components/common/CustomTextField/CustomTextField';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {useEffect, useRef, useState} from 'react';
import {
    fetchOccupationalMedicine,
    fetchSection10Data,
    fetchSection11Data,
    fetchSection12Data,
    fetchSection13Data,
    fetchSection14Data,
    fetchSection15Data,
    fetchSection1Data,
    fetchSection2Data,
    fetchSection3Data,
    fetchSection4Data,
    fetchSection5Data,
    fetchSection6Data,
    fetchSection7Data,
    fetchSection8Data,
    fetchSection9Data,
    saveOccupationalMedicineDataByMedic,
    saveOccupationalMedicineDataByPatient,
    selectIsAllLoadedForMedic,
    selectIsAllLoadedForPatient,
    selectOccupationalMedicine,
    selectSection1,
    selectSection10,
    selectSection10IsLoaded,
    selectSection11,
    selectSection11IsLoaded,
    selectSection12,
    selectSection12IsLoaded,
    selectSection13,
    selectSection13IsLoaded,
    selectSection14,
    selectSection14IsLoaded,
    selectSection15,
    selectSection15IsLoaded,
    selectSection1IsLoaded,
    selectSection2,
    selectSection2IsLoaded,
    selectSection3,
    selectSection3IsLoaded,
    selectSection4,
    selectSection4IsLoaded,
    selectSection5,
    selectSection5IsLoaded,
    selectSection6,
    selectSection6IsLoaded,
    selectSection7,
    selectSection7IsLoaded,
    selectSection8,
    selectSection8IsLoaded,
    selectSection9,
    selectSection9IsLoaded,
    updateOccupationalMedicineData,
} from '../../store/occupationalMedicineSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {EmptyTopBar, Loading} from '../../components';
import useStyles from './OccupationalMedicinePageStyles';
import {useHistory, useParams} from 'react-router';
import {
    USER_ACCOUNT_PAGE,
    PATIENT_APPOINTMENT_DETAILS_PAGE,
    OCCUPATIONAL_MEDICINE_PAGE_FILLED,
} from '../../const/routes';
import {WomanWithPhone} from '../../assets';
import PatientPlaceOfWorkComponent from '../../components/occupationalMedicine/III_patientPlaceOfWorkData/PatientPlaceOfWorkComponent';
import PatientProfessionalExperienceComponent from '../../components/occupationalMedicine/IV_patientProfessionalExperience/PatientProfessionalExperienceComponent';
import HarmfulFactorsComponent from '../../components/occupationalMedicine/V_harmfulFactors/HarmfulFactorsComponent';
import SubjectiveExaminationsComponent from '../../components/occupationalMedicine/VI_subjectiveExaminations/SubjectiveExaminationsComponent';
import PatientSummaryComponent from '../../components/occupationalMedicine/VII_summary/PatientSummaryComponent';

import {isNullOrUndefined} from 'util';
import {isNotNullOrUndefined} from '../../utils/lang';
import {getAppointmentTypes, getMedicFullName} from '../../utils/appointment';
import useAppointmentInfo from '../../hooks/useAppointmentInfo';
import {getDateTime} from '../../utils/date';
import {selectAuthUserData} from '../../store/auth';
import {MEDIC} from '../../const/auth';
import AuxiliaryTestsComponent from '../../components/occupationalMedicine/XI_auxiliaryTests/AuxiliaryTestsComponent';
import PatientStatementComponent from '../../components/occupationalMedicine/VIII_PatientStatement/PatientStatementComponent';
import PhysicalTests from '../../components/occupationalMedicine/IX_physicalTests/PhysicalTests';
import AdditionalPhysicalTestsComponent from '../../components/occupationalMedicine/X_additionalPhysicalTests/AdditionalPhysicalTestsComponent';
import SpecialistConsultationsComponent from '../../components/occupationalMedicine/XII_specialistConsultations/SpecialistConsultationsComponent';
import AdditionalAuxiliaryTestsComponent from '../../components/occupationalMedicine/XIV_additionalAuxiliaryTests/AdditionalAuxiliaryTestsComponent';
import DecisionComponent from '../../components/occupationalMedicine/XV_decision/DecisionComponent';
import ExtendedScopeOfTestingComponent from '../../components/occupationalMedicine/XIII_extendedScopeOfTesting/ExtendedScopeOfTestingComponent';
import axios from 'axios';
import PhysicalTestsByMedic from '../../components/occupationalMedicine/IX_physicalTests/PhysicalTests';

const OccupationalMedicinePage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const history = useHistory();
    const dispatch = useAppDispatch();

    const {appointmentId} = useParams<{appointmentId: string}>();

    const authUserData = useAppSelector(selectAuthUserData);
    const isMedic = authUserData?.role === MEDIC;

    const [defaultExtended, setDefaultExtended] = useState<number>(0);
    const appointmentDetails = useAppointmentInfo(appointmentId);

    const [error, setError] = useState<string>('');

    const getAppointmentTypeInfo = () =>
        getAppointmentTypes(commonT)
            .find(type => type.value === appointmentDetails.type)
            .label.toLowerCase();

    useEffect(() => {
        dispatch(fetchSection1Data({id: appointmentId}));
        dispatch(fetchSection2Data({id: appointmentId}));
        dispatch(fetchSection3Data({id: appointmentId}));
        dispatch(fetchSection4Data({id: appointmentId}));
        dispatch(fetchSection5Data({id: appointmentId}));
        dispatch(fetchSection6Data({id: appointmentId}));
        dispatch(fetchSection7Data({id: appointmentId}));
        dispatch(fetchSection8Data({id: appointmentId}));
        if (isMedic) {
            dispatch(fetchOccupationalMedicine({id: appointmentId}));
            dispatch(fetchSection9Data({id: appointmentId}));
            dispatch(fetchSection10Data({id: appointmentId}));
            dispatch(fetchSection11Data({id: appointmentId}));
            dispatch(fetchSection12Data({id: appointmentId}));
            dispatch(fetchSection13Data({id: appointmentId}));
            dispatch(fetchSection14Data({id: appointmentId}));
            dispatch(fetchSection15Data({id: appointmentId}));
        }
    }, []);

    const section1 = useAppSelector(selectSection1);
    const section2 = useAppSelector(selectSection2);
    const section3 = useAppSelector(selectSection3);
    const section4 = useAppSelector(selectSection4);
    const section5 = useAppSelector(selectSection5);
    const section6 = useAppSelector(selectSection6);
    const section7 = useAppSelector(selectSection7);
    const section8 = useAppSelector(selectSection8);

    const occupationalMedicine = isMedic
        ? useAppSelector(selectOccupationalMedicine)
        : null;
    const section9 = isMedic ? useAppSelector(selectSection9) : null;
    const section10 = isMedic ? useAppSelector(selectSection10) : null;
    const section11 = isMedic ? useAppSelector(selectSection11) : null;
    const section12 = isMedic ? useAppSelector(selectSection12) : null;
    const section13 = isMedic ? useAppSelector(selectSection13) : null;
    const section14 = isMedic ? useAppSelector(selectSection14) : null;
    const section15 = isMedic ? useAppSelector(selectSection15) : null;

    const isSection1Loaded = useAppSelector(selectSection1IsLoaded);
    const isSection2Loaded = useAppSelector(selectSection2IsLoaded);
    const isSection3Loaded = useAppSelector(selectSection3IsLoaded);
    const isSection4Loaded = useAppSelector(selectSection4IsLoaded);
    const isSection5Loaded = useAppSelector(selectSection5IsLoaded);
    const isSection6Loaded = useAppSelector(selectSection6IsLoaded);
    const isSection7Loaded = useAppSelector(selectSection7IsLoaded);
    const isSection8Loaded = useAppSelector(selectSection8IsLoaded);

    const isSection9Loaded = isMedic
        ? useAppSelector(selectSection9IsLoaded)
        : false;
    const isSection10Loaded = isMedic
        ? useAppSelector(selectSection10IsLoaded)
        : false;
    const isSection11Loaded = isMedic
        ? useAppSelector(selectSection11IsLoaded)
        : false;
    const isSection12Loaded = isMedic
        ? useAppSelector(selectSection12IsLoaded)
        : false;
    const isSection13Loaded = isMedic
        ? useAppSelector(selectSection13IsLoaded)
        : false;
    const isSection14Loaded = isMedic
        ? useAppSelector(selectSection14IsLoaded)
        : false;
    const isSection15Loaded = isMedic
        ? useAppSelector(selectSection15IsLoaded)
        : false;

    const isAllLoaded = isMedic
        ? useAppSelector(selectIsAllLoadedForMedic)
        : useAppSelector(selectIsAllLoadedForPatient);

    const findExtended = (): number => {
        const emptySections = [];
        if (isMedic) {
            emptySections.push(15);

            if (!isNotNullOrUndefined(section14)) emptySections.push(14);

            if (!isNotNullOrUndefined(section13)) emptySections.push(13);

            if (!isNotNullOrUndefined(section12)) emptySections.push(12);

            if (!isNotNullOrUndefined(section11)) emptySections.push(11);

            if (!isNotNullOrUndefined(section10)) emptySections.push(10);
            
            if (!isNotNullOrUndefined(section9)) {
                emptySections.push(9);
            }
        } else {
            emptySections.push(8);

            if (!isNotNullOrUndefined(section7)) emptySections.push(7);

            if (!isNotNullOrUndefined(section6)) emptySections.push(6);

            if (!isNotNullOrUndefined(section5)) emptySections.push(5);

            if (!isNotNullOrUndefined(section4)) emptySections.push(4);

            if (!isNotNullOrUndefined(section3)) emptySections.push(3);

            if (!isNotNullOrUndefined(section2) || section2?.isDraft ) 
                emptySections.push(2);

            if (!isNotNullOrUndefined(section1) || section1?.isDraft)
                emptySections.push(1);
        }      

        const next = emptySections.pop();
        scrollTo(next);
        return next;
    };


    useEffect(() => {
        if (isAllLoaded) {
            setDefaultExtended(findExtended());}
    }, [isAllLoaded, 
        section1, 
        section2, 
        section3, 
        section4, 
        section5, 
        section6, 
        section7, 
        section8,
        section9,
        section10,
        section11,
        section12, 
        section13, 
        section14, 
        section15]);

    const onSubmitSection = async (
        values: string,
        fetchDataFunction: any,
        currentSection: number,
        stayOnCurrent = false,
    ) => {
        if (!stayOnCurrent) {
            setDefaultExtended(currentSection + 1);
        }

        await dispatch(
            updateOccupationalMedicineData({
                id: appointmentId,
                form: {section: currentSection, data: values},
            }),
        );
        await dispatch(fetchDataFunction);
    };

    const onSubmitByPatient = async (values?: PatientStatementDto) => {
        try {
            await dispatch(
                saveOccupationalMedicineDataByPatient({
                    id: appointmentId,
                    form: {section: 8, data: values},
                }),
            );
            await dispatch(fetchSection8Data({id: appointmentId}));
            history.push(
                PATIENT_APPOINTMENT_DETAILS_PAGE + '/' + appointmentId,
            );
        } catch (error) {
            if (axios.isAxiosError(error))
                setError(t('saveError') + error.response?.data[0]);
        }
    };
    const onSubmitByMedic = async (values: string) => {
        try {
            await dispatch(
                saveOccupationalMedicineDataByMedic({
                    id: occupationalMedicine.id.toString(),
                    form: {section: 15, data: values},
                }),
            );
            history.push(OCCUPATIONAL_MEDICINE_PAGE_FILLED);
        } catch (error) {
            if (axios.isAxiosError(error))
                setError(t('saveError') + error.response?.data[0]);
        }
    };
    const refSection1 = useRef(null);
    const refSection2 = useRef(null);
    const refSection3 = useRef(null);
    const refSection4 = useRef(null);
    const refSection5 = useRef(null);
    const refSection6 = useRef(null);
    const refSection7 = useRef(null);
    const refSection8 = useRef(null);
    const refSection9 = useRef(null);
    const refSection10 = useRef(null);
    const refSection11 = useRef(null);
    const refSection12 = useRef(null);
    const refSection13 = useRef(null);
    const refSection14 = useRef(null);
    const refSection15 = useRef(null);

    const scrollTo = (defaultExtended:number)=>{
            if(defaultExtended===1)
            window.scrollTo({
                top: refSection1.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===1)
            window.scrollTo({
                top: refSection1.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===2)
            window.scrollTo({
                top: refSection2.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===3)
            window.scrollTo({
                top: refSection3.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===4)
            window.scrollTo({
                top: refSection4.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===5)
            window.scrollTo({
                top: refSection5.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===6)
            window.scrollTo({
                top: refSection6.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===7)
            window.scrollTo({
                top: refSection7.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===8)
            window.scrollTo({
                top: refSection8.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===9)
            window.scrollTo({
                top: refSection9.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===10)
            window.scrollTo({
                top: refSection10.current.offsetTop,
                behavior: "smooth"
                });
        if(defaultExtended===11)
            window.scrollTo({
                top: refSection11.current.offsetTop,
                behavior: "smooth"
                });       
        if(defaultExtended===12)
            window.scrollTo({
                top: refSection12.current.offsetTop,
                behavior: "smooth"
                });         
                
        if(defaultExtended===13)
            window.scrollTo({
                top: refSection13.current.offsetTop,
                behavior: "smooth"
                }); 
        if(defaultExtended===14)
            window.scrollTo({
                top: refSection14.current.offsetTop,
                behavior: "smooth"
                }); 
        if(defaultExtended===15)
            window.scrollTo({
                top: refSection5.current.offsetTop,
                behavior: "smooth"
                }); 
    }
 
    const onReturn = (defaultExtended:number) =>{
        setDefaultExtended(defaultExtended);
        scrollTo(defaultExtended);
    
}

    //TODO on error
    return (
        <>
            <Loading withBackdrop loading={!isAllLoaded} />

            <EmptyTopBar onCancel={() => history.push(USER_ACCOUNT_PAGE)} />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className={classes.container}
            >
                <Grid item xs={10}>
                    <Box className={classes.title}>{t('questionnaire')}</Box>

                    <Grid container direction="row">
                        <Grid
                            item
                            xs={6}
                            className={classes.appointmentDetails}
                        >
                            {t('occupationalMecidineTest')}
                            {appointmentDetails && (
                                <>
                                    <Box>
                                        {getMedicFullName(
                                            appointmentDetails?.medic,
                                        )}
                                    </Box>
                                    <Box>
                                        {getDateTime(
                                            appointmentDetails?.startDate,
                                        )}
                                        {', '}
                                        {getAppointmentTypeInfo()}
                                    </Box>
                                </>
                            )}
                        </Grid>
                        {isMedic && occupationalMedicine && (
                            <Grid
                                item
                                xs={6}
                                className={classes.appointmentDetails}
                            >
                                <Box>
                                    <Box>
                                        {t('typeOfPreventiveExamination')}:{' '}
                                        {t(
                                            occupationalMedicine.preventiveExaminationType,
                                        )}
                                    </Box>
                                    <Box>
                                        {t('otherPreventiveActivities')}: nd.
                                    </Box>
                                    <Box>
                                        {t('underCare')}:{' '}
                                        {t(
                                            occupationalMedicine.preventiveExaminationUnderCare,
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <img
                        src={WomanWithPhone}
                        alt="Payment image"
                        className={classes.image}
                    />
                </Grid>
            </Grid>
            {isSection1Loaded && (
                <PatientPersonalDataComponent
                    currentExpanded={defaultExtended}
                    data={section1}
                    onSubmitSection={onSubmitSection}
                    refSection={refSection1}
                />
            )}

            {isSection2Loaded  && (
                <PatientPlaceOfWorkOrOfStudyComponent
                    currentExpanded={defaultExtended}
                    data={section2}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(1)}
                    refSection={refSection2}
                />
            )}

            {isSection3Loaded && (
                <PatientPlaceOfWorkComponent
                    currentExpanded={defaultExtended}
                    data={section3}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(2)}
                    refSection={refSection3}
                />
            )}

            {isSection4Loaded && (
                <PatientProfessionalExperienceComponent
                    currentExpanded={defaultExtended}
                    data={section4}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(3)}
                    refSection={refSection4}
                />
            )}

            {isSection5Loaded && (
                <HarmfulFactorsComponent
                    currentExpanded={defaultExtended}
                    data={section5}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(4)}
                    refSection={refSection5}
                />
            )}

            {isSection6Loaded && (
                <SubjectiveExaminationsComponent
                    currentExpanded={defaultExtended}
                    data={section6}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(5)}
                    refSection={refSection6}
                />
            )}

            {isSection7Loaded && (
                <PatientSummaryComponent
                    currentExpanded={defaultExtended}
                    data={section7}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(6)}
                    refSection={refSection7}
                />
            )}

            {isSection8Loaded && (
                <PatientStatementComponent
                    currentExpanded={defaultExtended}
                    data={section8}
                    onSubmitSection={onSubmitByPatient}
                    onReturn={() => onReturn(7)}
                    onNext={() => 
                        {  
                            setDefaultExtended(9);
                            scrollTo(9);
                        } 
                    }
                    refSection={refSection8}
                />
            )}

            {isMedic && isSection9Loaded && (
                <PhysicalTestsByMedic
                    currentExpanded={defaultExtended}
                    data={section9}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(8)}
                    refSection={refSection9}
                />
            )}

            {isMedic && isSection10Loaded && (
                <AdditionalPhysicalTestsComponent
                    currentExpanded={defaultExtended}
                    data={section10}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(9)}
                    refSection={refSection10}
                />
            )}

            {isMedic && isSection11Loaded && (
                <AuxiliaryTestsComponent
                    currentExpanded={defaultExtended}
                    data={section11}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(10)}
                    refSection={refSection11}
                />
            )}

            {isMedic && isSection12Loaded && (
                <SpecialistConsultationsComponent
                    currentExpanded={defaultExtended}
                    data={section12}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(11)}
                    refSection={refSection12}
                />
            )}

            {isMedic && isSection13Loaded && (
                <ExtendedScopeOfTestingComponent
                    currentExpanded={defaultExtended}
                    data={section13}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(12)}
                    refSection={refSection13}
                />
            )}

            {isMedic && isSection14Loaded && (
                <AdditionalAuxiliaryTestsComponent
                    currentExpanded={defaultExtended}
                    data={section14}
                    onSubmitSection={onSubmitSection}
                    onReturn={() => onReturn(13)}
                    refSection={refSection14}
                />
            )}

            {isMedic && isSection15Loaded && (
                <DecisionComponent
                    currentExpanded={defaultExtended}
                    data={section15}
                    onSubmitSection={onSubmitByMedic}
                    onReturn={() => onReturn(14)}
                    refSection={refSection15}
                />
            )}

        </>
    );
};
export default OccupationalMedicinePage;
