import React, {memo, useEffect} from 'react';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Snackbar,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {
    CardList,
    CardListItem,
    Loading,
    Paging,
    TileList,
    TileListItem,
} from '../../index';
import useMedicalTests from '../../../hooks/referrals/useMedicalTests';
import {includesAllWords} from '../../../utils/string';
import MedicalTestCard from './MedicalTestCard';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectMedicalTestNameFilter,
    selectSelectedMedicalTestsCategory,
} from '../../../store/referrals/medicalTestsSlice';
import {desktop} from '../../../const/sizes';
import {
    fetchDiagnosticServices,
    pageSpecified,
    selectDiagnosticServiceCount,
    selectDiagnosticServiceSize,
    selectDiagnosticServicePage,
    searchSpecified,
} from '../../../store/referrals/diagnosticServicesSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchResultRoot: {
            [theme.breakpoints.up('md')]: {
                marginTop: 23,
            },
        },
        pagingWrapper: {
            padding: `${desktop.contentSmallerSpacing}`,
        },
    }),
);

const SearchResult = memo(function SearchResult() {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const isDesktopViewBreakPoint = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('md'),
    );

    const selectedGroupOfMedicalTests = useAppSelector(
        selectSelectedMedicalTestsCategory,
    );
    const nameOfMedicalTestFilter = useAppSelector(selectMedicalTestNameFilter);

    const {medicalTests, medicalTestsLoading} = useMedicalTests(
        selectedGroupOfMedicalTests,
    );

    const filteredMedicalTests = nameOfMedicalTestFilter
        ? medicalTests.filter(medicalTest =>
              includesAllWords(medicalTest.name, nameOfMedicalTestFilter),
          )
        : medicalTests;

    const medicalTestsSize = useAppSelector(selectDiagnosticServiceSize);
    const medicalTestsCount = useAppSelector(selectDiagnosticServiceCount);
    const medicalTestsPage = useAppSelector(selectDiagnosticServicePage);

    const pageChangeHandler = (page: number) => {
        dispatch(pageSpecified(page));
        dispatch(fetchDiagnosticServices(selectedGroupOfMedicalTests));
    };
    useEffect(() => {
        dispatch(pageSpecified(0));
        dispatch(fetchDiagnosticServices(selectedGroupOfMedicalTests));
    }, [selectedGroupOfMedicalTests]);

    useEffect(() => {
        dispatch(pageSpecified(0));
        dispatch(searchSpecified(nameOfMedicalTestFilter));
        dispatch(fetchDiagnosticServices(selectedGroupOfMedicalTests));
    }, [nameOfMedicalTestFilter]);
    return (
        <Box className={classes.searchResultRoot}>
            {isDesktopViewBreakPoint ? (
                <Box>
                    <CardList>
                        {filteredMedicalTests.map(medicalTest => (
                            <CardListItem
                                key={medicalTest.id}
                                itemDisableGutters={true}
                            >
                                <MedicalTestCard medicalTest={medicalTest} />
                            </CardListItem>
                        ))}
                    </CardList>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.pagingWrapper}
                    >
                        <Paging
                            page={medicalTestsPage}
                            size={medicalTestsSize}
                            count={medicalTestsCount}
                            onChange={pageChangeHandler}
                        />
                    </Grid>
                </Box>
            ) : (
                <TileList>
                    {filteredMedicalTests.map(medicalTest => (
                        <TileListItem key={medicalTest.id}>
                            <MedicalTestCard medicalTest={medicalTest} />
                        </TileListItem>
                    ))}
                </TileList>
            )}
            <Loading withBackdrop loading={medicalTestsLoading} />
        </Box>
    );
});

export default SearchResult;
