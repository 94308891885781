import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {LoadingStatus} from '../../const/loadingStatus';
import {RootState} from '../store';
import referralsProvider from '../../services/referralsProvider';
import mapDiagnosricService from '../mappers/mapDiagnosticService';
import {DiagnosticServiceDto} from '../../types/referrals';

const sliceName = 'diagnosticService';

const diagnosticServiceAdapter = createEntityAdapter<DiagnosticServiceDto>({
    selectId: diagnosticService => diagnosticService.id,
});

const initialState = diagnosticServiceAdapter.getInitialState({
    status: 'idle' as LoadingStatus,
    page: 0,
    size: 10,
    count: 0,
    search: '',
    diagnosticServices: [] as DiagnosticServiceDto[],
});

export type DiagnosticServiceSliceState = typeof initialState;

export const fetchDiagnosticServices = createAsyncThunk<
    {
        diagnosticServices: DiagnosticServiceDto[];
        page: number;
        count: number;
    },
    string,
    {
        state: RootState;
    }
>(`${sliceName}/fetchDiagnosticServices`, async (type, thunkApi) => {
    const {page, size, search} = selectDiagnosticServices(thunkApi.getState());
    const {
        page: resultPage,
        results,
        count,
    } = await referralsProvider.getMedicalTestByCategoryName(
        type,
        page,
        size,
        search,
    );

    return {
        diagnosticServices: results.map(mapDiagnosricService),
        page: resultPage,
        count,
    };
});

const diagnosticServicesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        pageSpecified(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        sizeSpecified(state, action: PayloadAction<number>) {
            state.size = action.payload;
        },
        searchSpecified(state, action: PayloadAction<string>) {
            state.search = action.payload;
        },
        clear(state) {
            state.page = initialState.page;
            state.size = initialState.size;
            state.count = initialState.count;
            state.search = initialState.search;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDiagnosticServices.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchDiagnosticServices.fulfilled, (state, action) => {
                state.status = 'loaded';

                state.page = action.payload.page;
                state.count = action.payload.count;
                state.diagnosticServices = action.payload.diagnosticServices;
            })
            .addCase(fetchDiagnosticServices.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const {pageSpecified, sizeSpecified, searchSpecified} =
    diagnosticServicesSlice.actions;

export const selectDiagnosticServices = (state: RootState) =>
    state.diagnosticServices;

export const selectDiagnosticServiceStatus = (state: RootState) =>
    selectDiagnosticServices(state).status;
export const selectDiagnosticServicePage = (state: RootState) =>
    selectDiagnosticServices(state).page;
export const selectDiagnosticServiceSize = (state: RootState) =>
    selectDiagnosticServices(state).size;
export const selectDiagnosticServiceCount = (state: RootState) =>
    selectDiagnosticServices(state).count;
export const selectDiagnosticServiceArray = (state: RootState) =>
    selectDiagnosticServices(state).diagnosticServices;

export const {
    selectIds: selectDiagnosticServiceIds,
    selectById: selectDiagnosticServiceById,
} = diagnosticServiceAdapter.getSelectors<RootState>(state =>
    selectDiagnosticServices(state),
);

export default diagnosticServicesSlice.reducer;
