import {Box, createStyles, Grid, makeStyles} from '@material-ui/core';
import {PersonOutline, WorkOutline} from '@material-ui/icons';
import clsx from 'clsx';
import {ReactNode, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomTextField, Loading} from '../..';
import {BuyerDataType} from '../../../const/buyerDataType';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {mobile} from '../../../const/sizes';
import CustomButton from '../../common/Button/Button';
import useStyles from './BuyerDataFormStyles';
import {BuyerDataFormConfig} from '../../../hooks/useBuyerDataFrom';
import FormGroup from '../../common/FormGroup/FormGroup';
import buyerDataProvider from '../../../services/buyerDataProvider'
import axios from 'axios';
type BuyerDataFormProps = {
    buyerDataFormConfig: BuyerDataFormConfig;
    className?: string;
};

const BuyerDataForm = ({
    buyerDataFormConfig,
    className,
}: BuyerDataFormProps) => {
    const {
        handleChange,
        handleBlur,
        setFieldValue,
        resetForm,
        values,
        errors,
        touched,
    } = buyerDataFormConfig;

    const [loading, setLoading] = useState<boolean>(false);

    const switchBuyerDataType = (type: BuyerDataType) => {
        resetForm();
        setFieldValue('type', type);
    };

    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS_PAYMENT);

    const classes = useStyles();

    const individualPersonRequiredIndicator =
        values.type === ('IndividualPerson' as BuyerDataType) && '*';

    const companyRequiredIndicator =
        values.type === ('Company' as BuyerDataType) && '*';

    const individualPersonButtonClasses = clsx(
        classes.buyerDataTypeButton,
        values.type !== ('IndividualPerson' as BuyerDataType) &&
            classes.disabledButton,
    );

    const companyButtonClasses = clsx(
        classes.buyerDataTypeButton,
        values.type !== ('Company' as BuyerDataType) && classes.disabledButton,
    );

    const fetchData = async () => {
        try{
            setLoading(true);
            const result = await buyerDataProvider.getDefaultByuerData();
            if (result.data){
                values.name = result.data.name;
                values.surname = result.data.surname;
                values.companyName = result.data.companyName;
                values.nip = result.data.nip;
                values.streetName = result.data.streetName;
                values.houseNumber = result.data.houseNumber;
                values.apartmentNumber =  result.data.apartmentNumber;
                values.postcode = result.data.postcode;
                values.cityName = result.data.cityName;
            }
        }
        catch(error){
            if (axios.isAxiosError(error) &&
                error.response.status === 404)
                return;
            console.error(error);
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Loading loading={loading} withBackdrop />
            <Box className={clsx([classes.formSection, className])}>
                <Box className={classes.formSectionHeader}>
                    {t('provideInvoiceData')}
                </Box>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.buyerDataType}
                >
                    <CustomButton
                        type="button"
                        variant="outlined"
                        color="secondary"
                        startIcon={<PersonOutline />}
                        className={individualPersonButtonClasses}
                        onClick={() => switchBuyerDataType('IndividualPerson')}
                    >
                        {t('invoiceIndividual')}
                    </CustomButton>
                    <CustomButton
                        type="button"
                        variant="outlined"
                        color="secondary"
                        startIcon={<WorkOutline />}
                        className={companyButtonClasses}
                        onClick={() => switchBuyerDataType('Company')}
                    >
                        {t('invoiceCompany')}
                    </CustomButton>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {values.type === ('IndividualPerson' as BuyerDataType) && (
                        <>
                            <FormGroup className={classes.formGroup}>
                                <Label htmlFor="name">
                                    {t('firstName')}
                                    {individualPersonRequiredIndicator}
                                </Label>
                                <CustomTextField
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    helperText={touched.name && t(errors.name)}
                                    error={touched.name && !!errors.name}
                                />
                            </FormGroup>
                            <FormGroup className={classes.formGroup}>
                                <Label htmlFor="surname">
                                    {t('lastName')}
                                    {individualPersonRequiredIndicator}
                                </Label>
                                <CustomTextField
                                    id="surname"
                                    name="surname"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.surname}
                                    helperText={
                                        touched.surname && t(errors.surname)
                                    }
                                    error={touched.surname && !!errors.surname}
                                />
                            </FormGroup>
                        </>
                    )}
                    {values.type === ('Company' as BuyerDataType) && (
                        <>
                            <FormGroup className={classes.formGroup}>
                                <Label htmlFor="companyName">
                                    {t('companyName')}
                                    {companyRequiredIndicator}
                                </Label>
                                <CustomTextField
                                    id="companyName"
                                    name="companyName"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.companyName}
                                    helperText={
                                        touched.companyName &&
                                        t(errors.companyName)
                                    }
                                    error={
                                        touched.companyName &&
                                        !!errors.companyName
                                    }
                                />
                            </FormGroup>
                            <FormGroup className={classes.formGroup}>
                                <Label htmlFor="nip">
                                    {t('nip')}
                                    {companyRequiredIndicator}
                                </Label>
                                <CustomTextField
                                    id="nip"
                                    name="nip"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.nip}
                                    helperText={touched.nip && t(errors.nip)}
                                    error={touched.nip && !!errors.nip}
                                />
                            </FormGroup>
                        </>
                    )}
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="streetName">{t('street')}*</Label>
                        <CustomTextField
                            id="streetName"
                            name="streetName"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.streetName}
                            helperText={
                                touched.streetName && t(errors.streetName)
                            }
                            error={touched.streetName && !!errors.streetName}
                        />
                    </FormGroup>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        className={classes.formSubsection}
                    >
                        <FormGroup
                            className={clsx(
                                classes.formGroup,
                                classes.numberFormGroup,
                            )}
                        >
                            <Label htmlFor="houseNumber">{t('number')}*</Label>
                            <CustomTextField
                                id="houseNumber"
                                name="houseNumber"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.houseNumber}
                                helperText={
                                    touched.houseNumber && t(errors.houseNumber)
                                }
                                error={
                                    touched.houseNumber && !!errors.houseNumber
                                }
                                className={classes.numberInput}
                            />
                        </FormGroup>
                        <FormGroup
                            className={clsx(
                                classes.formGroup,
                                classes.apartmentFormGroup,
                            )}
                        >
                            <Label htmlFor="apartmentNumber">
                                {t('apartment')}
                            </Label>
                            <CustomTextField
                                id="apartmentNumber"
                                name="apartmentNumber"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.apartmentNumber}
                                helperText={
                                    touched.apartmentNumber &&
                                    t(errors.apartmentNumber)
                                }
                                error={
                                    touched.apartmentNumber &&
                                    !!errors.apartmentNumber
                                }
                                className={classes.apartmentInput}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        className={classes.formSubsection}
                    >
                        <FormGroup
                            className={clsx(
                                classes.formGroup,
                                classes.codeFormGroup,
                            )}
                        >
                            <Label htmlFor="postcode">{t('code')}*</Label>
                            <CustomTextField
                                id="postcode"
                                name="postcode"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.postcode}
                                helperText={
                                    touched.postcode && t(errors.postcode)
                                }
                                error={touched.postcode && !!errors.postcode}
                                className={classes.codeInput}
                            />
                        </FormGroup>
                        <FormGroup
                            className={clsx(
                                classes.formGroup,
                                classes.cityFromGroup,
                            )}
                        >
                            <Label htmlFor="cityName">{t('city')}*</Label>
                            <CustomTextField
                                id="cityName"
                                name="cityName"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cityName}
                                helperText={
                                    touched.cityName && t(errors.cityName)
                                }
                                error={touched.cityName && !!errors.cityName}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

type LabelProps = {
    htmlFor: string;
    children: ReactNode;
};

const Label = ({htmlFor, children}: LabelProps) => {
    const useStyles = makeStyles(() =>
        createStyles({
            labelContainer: {
                marginBottom: mobile.contentSmallerSpacing,
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box className={classes.labelContainer}>
            <label htmlFor={htmlFor}>{children}</label>
        </Box>
    );
};

export default BuyerDataForm;
