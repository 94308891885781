import {PrescriptionSetDto} from '../types/prescription';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import prescriptionsApi from '../api/prescriptions/prescriptionsApi';
import {RootState} from './store';

const sliceName = 'prescriptionSlice';
type PrescriptionStatusType = 'idle' | 'loading' | 'failed';

const initialState = {
    prescriptions: [] as PrescriptionSetDto[],
    status: 'idle' as PrescriptionStatusType,
};

export const fetchPrescriptions = createAsyncThunk<
    PrescriptionSetDto[],
    {appointmentId: number},
    unknown
>(`${sliceName}/fetchPrescriptions`, async ({appointmentId}) => {
    try {
        const {data} = await prescriptionsApi.getPrescriptionsForAppointment(
            appointmentId,
        );
        return data?.result;
    } catch (err) {
        console.error(err);
    }
});

const prescriptionSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPrescriptions.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchPrescriptions.fulfilled, (state, action) => {
                state.status = 'idle';
                state.prescriptions = action.payload;
            })
            .addCase(fetchPrescriptions.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const selectPrescriptions = (state: RootState) =>
    state.prescription.prescriptions;

export const selectPrescriptionsStatus = (state: RootState) =>
    state.prescription.status;

export default prescriptionSlice.reducer;
