import {MedicinalProductDto, PrescriptionSetDto} from '../types/prescription';
import {AppointmentDetailsForPrescriptionDto} from '../types/appointments';
import {SelectedPrescription} from '../components/prescription/PrescriptionItem/PrescriptionItem';
import {TFunction} from 'react-i18next';

export const mapMedicinalProductForAutocomplete = (
    medicinalProduct: MedicinalProductDto,
) => ({...medicinalProduct, value: medicinalProduct.id});

export const mapAppointmentDetailsToPrescription = (
    appointmentDetails: AppointmentDetailsForPrescriptionDto,
) => {
    const {id, patient, medic} = appointmentDetails;
    const patientDetails = appointmentDetails.child || patient;
    return {
        appointmentId: id,
        patientId: patient.userId,
        medicId: medic.userId,
        patientName: patientDetails.firstName,
        patientSurname: patientDetails.surname,
        patientPesel: patientDetails.pesel,
        effectiveDateTime: new Date().toISOString(),
        patientHomeAddress: patient.homeAddress,
        medicNPWZ: medic.npwz,
        medicName: medic.firstName,
        medicSurname: medic.surname,
        medicBusinessRole: medic.businessRole,
    };
};

export const mapPrescriptionToIssuePrescription = (
    p: SelectedPrescription,
    order: number,
) => ({
    order: order + 1,
    quantity: p.quantity,
    effectiveDate: new Date(p.effectiveDate).toISOString().split('T')[0],
    medicinalProductId: p.medicinalProduct.id,
    medicationPackId: p.medicationPack.id,
    usageRecommendation: p.usageRecommendation,
    paymentLevel: p.reimbursement?.paymentLevel,
});

export const getPrescriptionSetFileName = (
    prescriptionSet: PrescriptionSetDto,
    t: TFunction,
) => {
    const effectiveDateTime = new Date(prescriptionSet.effectiveDateTime);
    const date = effectiveDateTime.toLocaleDateString();
    const time = effectiveDateTime.toLocaleTimeString();
    return `${t('prescription')}_${
        prescriptionSet.patientPesel
    }_${date}_${time}.pdf`;
};
