import {Conversation} from '@twilio/conversations/lib/conversation';
import React, {useEffect, useState} from 'react';

import {selectAuthUserData} from '../../../../store/auth';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';

import {Box, Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {
    MedicAppointmentDetailsTabs,
    MedicAppointmentDetailsAboutPatientTab,
    AppointmentFileUploadDesktop,
    AppointmentFileUploadMobile,
} from '../../../index';
import {MEDIC, PATIENT} from '../../../../const/auth';
import {selectAppointmentDetails} from '../../../../store/consultationSlice';
import {
    useDownloadAsync,
    useHandleRemoveAsync,
    useHandleUploadAsync,
} from '../../../../hooks/appointmentFileUpload';
import {useParams} from 'react-router-dom';
import {selectAttachmentsMetaData} from '../../../../store/appointmentAttachmentsSlice';
import {useUserCheck} from '../../../../hooks/useUserCheck';
import {getFileIcon} from '../../../../utils/file';
import {getAttachmentsMetaData} from '../../../../store/appointmentAttachmentsSlice';
import {AppointmentGroupEnum, Attachment} from '../../../../types/appointments';
import OccupationalMedicineDetails from '../../../patientAppointmentDetails/additionalDetails/OccupationalMedicineDetails/OccupationalMedicineDetails';
interface Props {
    conversation: Conversation;
    handleClose: () => void;
    mobileView?: boolean;
}

const AttachmentsContainer = ({
    conversation,
    handleClose,
    mobileView,
}: Props) => {
    const {consultationId} = useParams<{consultationId: string}>();
    const authUserData = useAppSelector(selectAuthUserData);
    const attachmentsMetaData = useAppSelector(selectAttachmentsMetaData);
    const appointmentDetails = useAppSelector(selectAppointmentDetails);
    const dispatch = useAppDispatch();
    const isLoggedUser = useUserCheck();

    const handleUploadAsync = useHandleUploadAsync();
    const downloadFileAsync = useDownloadAsync();
    const removeFileAsync = useHandleRemoveAsync();

    const [key, setKey] = useState<number>(0);
    const [error, setError] = useState<string>(null);

    useEffect(() => {
        if (conversation) {
            conversation.on('messageAdded', () => {
                dispatch(
                    getAttachmentsMetaData({
                        appointmentId: consultationId,
                        fetchSilently: true,
                    }),
                );
            });
        }
    }, [conversation, dispatch]);

    const getFileUploadItems = () => {
        return attachmentsMetaData.map(attachmentMetaData => ({
            fileItemKey: attachmentMetaData.id,
            fileItemClickHandler: () =>
                downloadFileAsync(consultationId, `${attachmentMetaData.id}`),
            fileName: attachmentMetaData.fileName,
            fileIcon: getFileIcon(
                attachmentMetaData.contentType,
                attachmentMetaData.thumbnail,
            ),
            fileOwner: attachmentMetaData.person,
            fileDateCreated:
                attachmentMetaData.uploadDate.toFormat("dd'.'MM'.'yyyy"),
            fileTimeCreated: attachmentMetaData.uploadDate.toFormat("HH':'mm"),
            removeFileEnabled: isLoggedUser(attachmentMetaData.personId),
            removeFileHandler: () =>
                removeFile(consultationId, attachmentMetaData),
        }));
    };

    const handleUpload = (files: File[]) => {
        handleUploadAsync(consultationId, files);
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const data = new FormData();
            data.append('file', new Blob([file], {type: file.type}), file.name);
            conversation.sendMessage(data).catch(console.error);
        }
        if (files.length > 0) setKey(key + 1);
    };

    const removeFile = (
        consultationId: string,
        attachmentMetaData: Attachment,
    ) => {
        removeFileAsync(consultationId, `${attachmentMetaData.id}`);

        //send message of type 'media' if attachment was deleted
        const data = new FormData();
        data.append(
            'file',
            new Blob(['attachment ${attachmentMetaData.id} deleted'], {
                type: 'text/plain',
            }),
        );
        conversation.sendMessage(data).catch(console.error);
    };

    const renderFileUpload = (showHeader: boolean) =>
        mobileView ? (
            <AppointmentFileUploadMobile
                filesProps={getFileUploadItems()}
                fileUploadHandler={handleUpload}
                containerStyles={{
                    minHeight: 'calc(100vh - 105px)',
                }}
            />
        ) : (
            <AppointmentFileUploadDesktop
                filesProps={getFileUploadItems()}
                fileUploadHandler={handleUpload}
                showHeader={showHeader}
                handleClose={handleClose}
            />
        );

    return (
        <Box>
            {authUserData.role === MEDIC && (
                <MedicAppointmentDetailsTabs
                    appointment={appointmentDetails}
                    source={'chat'}
                    aboutPatientTabRender={() => (
                        <MedicAppointmentDetailsAboutPatientTab
                            appointment={appointmentDetails}
                        />
                    )}
                    filesTabRender={() => renderFileUpload(false)}
                    handleClose={handleClose}
                    openDefaultValue={'NOTES'}
                />
            )}
            {authUserData.role === PATIENT &&
                (appointmentDetails?.appointmentGroup ===
                AppointmentGroupEnum.OccupationalMedicineAppointment ? (
                    <Box style={{padding: 32, backgroundColor: 'white'}}>
                        <OccupationalMedicineDetails
                            appointment={appointmentDetails}
                        />
                    </Box>
                ) : (
                    renderFileUpload(true)
                ))}

            <Snackbar
                open={!!error}
                autoHideDuration={5000}
                onClose={() => setError('')}
            >
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AttachmentsContainer;
