import {Box, Grid} from '@material-ui/core';
import useStyles from './SickLeaveAppointmentDetailsStyles';
import FinishedAppointment from '../../appointmentsCalendar/FinishedAppointment/FinishedAppointment';
import AppointmentItem from '../../appointmentsCalendar/AppointmentItem/AppointmentItem';
import {useEffect, useState} from 'react';
import {Appointment, AppointmentDto} from '../../../types/appointments';
import appointmentsProvider from '../../../services/appointmentsProvider';
import {DateTime} from 'luxon';
import AppointmentDtoItem from '../../appointmentsCalendar/AppointmentDtoItem/AppointmentDtoItem';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

type Props = {
    appointmentId: number;
};
const SickLeaveAppointmentDetails = ({appointmentId}: Props) => {
    const {t} = useTranslation(i18nNamespaces.L4);

    const classes = useStyles();
    const [appointment, setAppointment] = useState<AppointmentDto>(null);

    const fetchAppointmentDetails = async () => {
        try {
            const data = await appointmentsProvider.getAppointment(
                appointmentId.toString(),
            );
            setAppointment(data);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    useEffect(() => {
        fetchAppointmentDetails();
    }, []);

    return (
        <Box>
            <Grid className={classes.section} direction={'column'}>
                <Grid item className={classes.header}>
                    {t('appointment')}
                </Grid>
                <Grid item>
                    {appointment && (
                        <AppointmentDtoItem appointment={appointment} />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
export default SickLeaveAppointmentDetails;
