import {MedicalServicePurchaseOptionsDto} from '../types/medicalServicePurchaseOptions';
import {
    MedicalServiceDto,
    SimpleMedicalServiceItemDto,
} from '../types/timeSlot';
import {
    MEDICAL_SERVICE,
    MEDICAL_SERVICES_SIMPLE,
    MEDICAL_SERVICE_PURCHASE_OPTIONS,
    MEDICAL_SERVICE_PURCHASE_OPTIONS_WITH_DISCOUNT_CODE,
    MEDICAL_SERVICE_PURCHASE_OPTIONS_FOR_CHILD,
} from './endpoints';
import {http, rawHttp} from './http';

class MedicalServiceProvider {
    async getMedicalServicePurchaseOptions(
        medicalServiceId: number,
        {
            referralId,
            occupationalMedicineReferralId,
        }: {referralId?: number; occupationalMedicineReferralId?: number},
    ) {
        return (
            await http.get<MedicalServicePurchaseOptionsDto>(
                MEDICAL_SERVICE_PURCHASE_OPTIONS(medicalServiceId),
                {
                    params: {
                        referralId: referralId || null,
                        occupationalMedicineReferralId:
                            occupationalMedicineReferralId || null,
                    },
                },
            )
        ).data;
    }

    async getMedicalServicePurchaseOptionsForChild(
        medicalServiceId: number,
        childId: number | null,
        referralId: number | null,
    ) {
        return (
            await http.get<MedicalServicePurchaseOptionsDto>(
                MEDICAL_SERVICE_PURCHASE_OPTIONS_FOR_CHILD(
                    medicalServiceId,
                    childId,
                    referralId,
                ),
            )
        ).data;
    }

    async getMedicalServicePurchaseOptionsWithDiscount(
        medicalServiceId: number,
        discountCode: string,
    ) {
        return (
            await http.get<MedicalServicePurchaseOptionsDto>(
                MEDICAL_SERVICE_PURCHASE_OPTIONS_WITH_DISCOUNT_CODE(
                    medicalServiceId,
                    discountCode,
                ),
            )
        ).data;
    }

    async getMedicalService(medicalServiceId: number) {
        return (
            await http.get<MedicalServiceDto>(MEDICAL_SERVICE(medicalServiceId))
        ).data;
    }

    async getSimpleMedicalServices() {
        return rawHttp.get<SimpleMedicalServiceItemDto[]>(
            MEDICAL_SERVICES_SIMPLE,
        );
    }
}

export default new MedicalServiceProvider();
