import {
    FormControl,
    IconButton,
    InputAdornment,
    InputBaseProps,
    FormHelperText,
    OutlinedInput,
    FormLabel,
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import clsx from 'clsx';
import React, {useState} from 'react';
import useStyles from './InputStyles';
import {CustomInputChangeEvent} from '../../../types/common';

type Props = {
    onChange?: (e: CustomInputChangeEvent) => void;
    errorText?: string;
    helperText?: string;
    type?: string;
    label?: string;
} & InputBaseProps;

const CustomInput = ({
    onChange,
    errorText,
    helperText,
    type,
    className,
    label,
    ...props
}: Props) => {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const classes = useStyles();
    return (
        <FormControl variant={'filled'} style={{display: 'flex'}}>
            <FormLabel className={classes.label}>{label}</FormLabel>
            <OutlinedInput
                className={clsx(classes.container, className)}
                type={
                    !passwordVisible && type === 'password'
                        ? 'password'
                        : 'text'
                }
                onChange={onChange}
                error={!!errorText}
                endAdornment={
                    type === 'password' && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                    setPasswordVisible(!passwordVisible)
                                }
                                edge="end"
                            >
                                {passwordVisible ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    )
                }
                {...props}
            />
            <FormHelperText id="my-helper-text" error={!!errorText}>
                {errorText || helperText}
            </FormHelperText>
        </FormControl>
    );
};

export default CustomInput;
