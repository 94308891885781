
import { Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import useStyles from "./MedicalTestDiagnosticOrderSummaryStyles";


interface Props{
    diagnosticOrderHeaderdId: string,
    orderExtenralId:string
}

const MedicalTestDiagnosticOrderSummary = ({diagnosticOrderHeaderdId, orderExtenralId}: Props) =>{
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);
    
    return (
        <Box>
            <Grid  
                className={classes.section}
                direction={'column'}
                spacing={1}>
                <Grid item className={classes.header}>
                    {`${t('diagnosticOrder')} ${orderExtenralId}`}
                </Grid>
                <Grid item className={classes.title}>
                {`${t('diagnosricOrderId')}: ${diagnosticOrderHeaderdId}`}
                </Grid>
                
            </Grid>
        </Box>
    )
}

export default MedicalTestDiagnosticOrderSummary;