import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { LoadingStatus } from '../../const/loadingStatus';
import appointmentReferralsProvider from '../../services/diagnostic/appointmentReferralsProvider';
import { AppointmentReferralDto,  PatientOrderedDiagnosticOrderHeaders } from '../../services/diagnostic/orderHeadersProvider';
import referralsProvider from '../../services/referralsProvider';
import mapOrderedDiagnosticOrderHeader from '../mappers/mapOrderedDiagnosticOrderHeader';
import { RootState } from '../store';

const initialState = {
    appointmentReferrals: [] as AppointmentReferralDto[],
    status: 'idle' as LoadingStatus,
};

export type AppointmentReferralsSliceState = typeof initialState;
const sliceName = 'appointmentrefferals';


export const fetchAppointmentReferrals = createAsyncThunk<AppointmentReferralDto[],  {appointmentIdAsString: string}, unknown>(
    `${sliceName}/fetchAppointmentReferrals`, async ({appointmentIdAsString}) => {
        return await appointmentReferralsProvider.getAppointmentReferrals(appointmentIdAsString)
    }
);


const AppointmentReferralsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetAppointmentReferralsState: () => initialState
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAppointmentReferrals.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAppointmentReferrals.fulfilled, (state, action) => {
                state.appointmentReferrals = action.payload;
                state.status = 'idle';
            })
    },
});


export const selectAppointmentReferrals = (state: RootState) =>
    state.appointmentReferrals.appointmentReferrals;

export default AppointmentReferralsSlice.reducer;