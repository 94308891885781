import {createContext} from 'react';

export type PayUSecureFormType = Exclude<payu.secureFormType, 'card'>;

export type PayUSecureFormsContextType = {
    forms: {
        [k in PayUSecureFormType]: payu.SecureForm;
    };
    tokenize: (
        type?: payu.tokenType,
    ) => Promise<payu.TokenizeResultSuccess | payu.TokenizeResultError>;
    sendCvv(
        redirectUrl: string,
    ): Promise<payu.SendCvvResultSuccess | payu.SendCvvResultError>;
};

export const PayUSecureFormsContext =
    createContext<PayUSecureFormsContextType>(null);
