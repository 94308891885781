import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { BACKGROUND_PRIMARY, BACKGROUND_SECONDARY, GRAY, LIGHT_GRAY, WHITE } from '../../../const/colors';
import { desktop, mobile } from '../../../const/sizes';

const textsCommonStyles = {
    size: 16,
    lineHeight: '24px',
}
//padding: smUp ? '24px 0' : 16,
// /const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: `${desktop.contentSmallerSpacing}`,
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
            boxShadow: 'none',
        },
        [theme.breakpoints.up('md')]: {
            arrowIcon: {
                cursor: 'pointer',
            },
        },
        [theme.breakpoints.up('sm')]: {
            container: {
                padding: '24px 0',
            },
        },
        avatar: {
            width: '70px',
            height: '70px',
            backgroundColor:BACKGROUND_SECONDARY
        },
        type: {
            ...textsCommonStyles,
            fontWeight: 'bold',
        },
        details: {
            ...textsCommonStyles,
        },
        status:{
            ...textsCommonStyles,
            color:GRAY
        }
        
    }),
);

export default useStyles;
