import {Box, Divider} from '@material-ui/core';
import MedicCard from '../MedicCard/MedicCard'
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyles from './DetailsStyles';
import {Appointment} from "../../../../types/appointments";

interface Props {
    appointment: Appointment
}

const Details = ({appointment}: Props) => {
    const classes = useStyles();
    return (
        <Box>
            <MedicCard appointment={appointment} />
            <Divider className={classes.divider}/>
            <ActionButtons appointment={appointment} />
        </Box>
    );
}

export default Details;