import TagManager from 'react-gtm-module';

type GTMEvent = 'subscriptionConversion' | 'timeSlotConversion' | 'page_view';

const tagManagerArgs = {
    gtmId: 'GTM-PFRV3SM',
    events: {
        conversion: 'Conversion',
        page_view: 'page_view',
    },
};

class GTMService {
    initialize() {
        TagManager.initialize(tagManagerArgs);
    }

    triggerEvent(event: GTMEvent, data: Record<string, any>) {
        TagManager.dataLayer({
            dataLayer: {
                event,
                ...data,
            },
        });
    }

    triggerSubscriptionConversion(id: number, price: number) {
        this.triggerEvent('subscriptionConversion', {
            conversionValue: price,
            conversionCurrency: 'PLN',
            conversionTransactionId: `${new Date().toISOString()}_${id}`,
        });
    }

    triggerTimeSlotConversion(id: string, price: number) {
        this.triggerEvent('timeSlotConversion', {
            conversionValue: price,
            conversionCurrency: 'PLN',
            conversionTransactionId: id,
        });
    }

    triggerPageView(data: {
        page_path: string;
        page_search: string;
        page_hash: string;
    }) {
        this.triggerEvent('page_view', data);
    }
}

export const gtmService = new GTMService();
