import {Grid, List, ListItem, Box, useMediaQuery, Theme, Link} from '@material-ui/core';
import useStyles from "./FileUploadMobileStyles";
import {UploadedItem} from "../../index";
import {LIGHT_GRAY} from "../../../const/colors";
import React, {FC} from "react";
import {Publish} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import {UploadedFileComponentProps} from "../../../types/fileUpload";

interface Props {
    filesProps: UploadedFileComponentProps[];
    fileUploadHandler: (files: File[]) => void;
    disabled?: boolean;
    containerStyles?: {[key: string]: string}
}

const FileUploadMobile: FC<Props> = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);

    const {
        disabled,
        filesProps,
        fileUploadHandler,
        containerStyles
    } = props;

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        // FileList is not JavaScript array.
        // Operations like array.map on this collection throws error.
        const fileList: FileList = event.target.files;
        const fileArray: File[] = [];
        for (let i = 0; i < fileList.length; i++) {
            fileArray.push(fileList[i]);
        }
        fileUploadHandler(fileArray);
    }
    return (
        <Box
            className={classes.container}
            style={containerStyles}
        >
            <div
                style={{height: 1, width: '100%', backgroundColor: LIGHT_GRAY}}
            />
            <List>
                {
                    filesProps.map((fileProps, i) => (
                        <ListItem
                            className={classes.listItem}
                            key={fileProps.fileItemKey ?? i}
                            onClick={fileProps.fileItemClickHandler}
                        >
                                <UploadedItem
                                    fileName={fileProps.fileName}
                                    fileIcon={fileProps.fileIcon}
                                    fileOwner={fileProps.fileOwner}
                                    fileDateCreated={fileProps.fileDateCreated}
                                    fileTimeCreated={fileProps.fileTimeCreated}
                                    removeFileEnabled={fileProps.removeFileEnabled}
                                    removeFileHandler={fileProps.removeFileHandler}
                                />
                        </ListItem>
                    ))
                }
            </List>
            <div>
                <label htmlFor="file">
                    <Grid
                        container
                        className={classes.uploadFileButton}
                        direction={'row'}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <Publish style={{marginTop: 5}} />
                        </Grid>
                        <Grid item style={{marginLeft: 17}}>
                            {t('dragDropAttachmentMobile')}
                        </Grid>
                    </Grid>
                </label>
                <input
                    disabled={disabled}
                    id={'file'}
                    type="file"
                    name="file"
                    hidden
                    onChange={onChangeHandler}
                />
            </div>
        </Box>
    );
}

export default FileUploadMobile;
