import {
    AuthResultDto,
    ConfirmedIdentityDto,
    IdentityConfirmationRequestDataDto,
} from '../types/auth';
import {ConfirmIdentitySimplyDto, RegisterWithIdentityDto} from '../types/Login';
import {
    ACCOUNT,
    CHANGE_PASSWORD,
    CHANGE_PHONE_NUMBER,
    CONFIRMED_IDENTITY,
    EMAIL_CODE,
    IDENTITY_CONFIRMATION_REQUEST,
    PATIENTS_CHILDREN,
    REGISTER_WITH_CONFIRMED_IDENTITY,
    RESET_PASSWORD,
    SMS_CODE,
    PASSWORD,
    PATIENT_ADDRESS,
    CONFIRM_IDENTITY_SIMPLY,
    CONFIRMED_IDENTITY_WITH_MOJE_ID
} from './endpoints';
import {rawHttp} from './http';
import {PatientsChildDto} from '../types/children';
import {MemberDto} from '../types/patientPackages';
import {PatientHomeAddress} from '../types/patient';

class AccountProvider {
    async signIn(email: string, password: string) {
        return await rawHttp.post<AuthResultDto>(ACCOUNT, {email, password});
    }

    async refreshToken() {
        return (await rawHttp.get<AuthResultDto>(ACCOUNT)).data;
    }

    async sendEmailCode(email: string) {
        await rawHttp.get(EMAIL_CODE, {params: {email}});
    }

    async verifyEmailCode(email: string, code: string) {
        return (await rawHttp.post<AuthResultDto>(EMAIL_CODE, {email, code}))
            .data;
    }

    async sendSMSCode() {
        return rawHttp.get(SMS_CODE);
    }

    async sendSMSCodeToChangePhoneNumber(phone: string) {
        await rawHttp.put(CHANGE_PHONE_NUMBER, {phoneNumber: phone});
    }

    async verifySMSCode(email: string, code: string) {
        return (await rawHttp.post<AuthResultDto>(SMS_CODE, {email, code}))
            .data;
    }

    async forcedPasswordChange(email: string, newPassword: string) {
        return (await rawHttp.post<AuthResultDto>(PASSWORD, {newPassword}))
            .data;
    }

    async verifySMSCodeToChangePhoneNumber(phone: string, code: string) {
        return await await rawHttp.put(CHANGE_PHONE_NUMBER, {
            phoneNumber: phone,
            code: code,
        });
    }

    async getIdentityConfirmationRequestData() {
        return (
            await rawHttp.get<IdentityConfirmationRequestDataDto>(
                IDENTITY_CONFIRMATION_REQUEST,
            )
        ).data;
    }

    async getConfirmedIdentity() {
        return (await rawHttp.get<ConfirmedIdentityDto>(CONFIRMED_IDENTITY))
            .data;
    }

    async registerWithIdentity(userData: RegisterWithIdentityDto) {
        return rawHttp.post(REGISTER_WITH_CONFIRMED_IDENTITY, userData);
    }

    async confirmIdentityWithModeId() {
        return rawHttp.put(CONFIRMED_IDENTITY_WITH_MOJE_ID);
    }

    async acquireCodeForPasswordReset(email: string) {
        return await rawHttp.get(RESET_PASSWORD, {params: {email}});
    }

    async resetPassword(email: string, code: string, password: string) {
        return await rawHttp.post(RESET_PASSWORD, {email, code, password});
    }

    async changePassword(oldPassword: string, newPassword: string) {
        return await rawHttp.put(CHANGE_PASSWORD, {oldPassword, newPassword});
    }

    getPatientChildren() {
        return rawHttp.get<PatientsChildDto[]>(PATIENTS_CHILDREN);
    }

    registerPatientChild(child: MemberDto) {
        return rawHttp.post(PATIENTS_CHILDREN, child);
    }

    removeChild(childId: number) {
        return rawHttp.delete(`${PATIENTS_CHILDREN}/${childId}`);
    }

    getAddressData() {
        return rawHttp.get<PatientHomeAddress>(PATIENT_ADDRESS);
    }

    updateAddressData(addressData: PatientHomeAddress) {
        return rawHttp.put(PATIENT_ADDRESS, addressData);
    }

    async confirmIdentity(userData: ConfirmIdentitySimplyDto) {
        return rawHttp.post(CONFIRM_IDENTITY_SIMPLY, userData);
    }
}

export default new AccountProvider();
