import React, {ReactNode, useEffect, useState} from 'react';
import clsx from 'clsx';
import useStyles from './DetailsTabsStyles';
import {Box, Grid} from '@material-ui/core';
import {MedicAppointmentDetailsNotesTab, SelectButtons} from '../../index';
import {
    AttachFileOutlined,
    Close,
    CreateOutlined,
    PersonOutlined,
    WorkOutline,
} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {SelectButtonOption} from '../../common/SelectButtons/SelectButtons';
import {Appointment, AppointmentGroupEnum} from '../../../types/appointments';
import {NoteEditedValue} from '../NotesTab/NotesTab';
import {isNotNullOrUndefined} from '../../../utils/lang';
import {TFunction} from 'i18next';
import OccupationalMedicineTab from '../OccupationalMedicineTab/OccupationalMedicineTab';

const defaultTabs = (t: TFunction): SelectButtonOption[] => [
    {
        value: 'ABOUT_PATIENT',
        label: t('aboutPatient'),
        icon: <PersonOutlined />,
    },
    {
        value: 'NOTES',
        label: t('notes'),
        icon: <CreateOutlined />,
    },
    {
        value: 'FILES',
        label: t('files'),
        icon: <AttachFileOutlined />,
    },
];

const occupationalMedicineTabs = (t: TFunction) => [
    {
        value: 'ABOUT_PATIENT',
        label: t('aboutPatient'),
        icon: <PersonOutlined />,
    },
    {
        value: 'OCCUPATIONAL_MEDICINE',
        label: t('occupationalMedicine'),
        icon: <WorkOutline />,
    },
];

interface Props {
    aboutPatientTabRender: () => ReactNode;
    filesTabRender: () => ReactNode;
    appointment: Appointment;
    source: 'medicDetails' | 'chat';
    handleClose?: () => void;
    openDefaultValue?: string;
}

const DetailsTabs = ({
    aboutPatientTabRender,
    filesTabRender,
    appointment,
    source,
    handleClose,
    openDefaultValue,
}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);
    const tabs =
        appointment.appointmentGroup ===
        AppointmentGroupEnum.OccupationalMedicineAppointment
            ? occupationalMedicineTabs(t)
            : defaultTabs(t);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const [
        drugMedicalRecommendationsEdited,
        setDrugMedicalRecommendationsEdited,
    ] = useState<NoteEditedValue>();
    const [medicalInterviewEdited, setMedicalInterviewEdited] =
        useState<NoteEditedValue>();
    const [
        referralMedicalRecommendationsEdited,
        setReferralMedicalRecommendationsEdited,
    ] = useState<NoteEditedValue>();
    const [
        otherMedicalRecommendationsEdited,
        setOtherMedicalRecommendationsEdited,
    ] = useState<NoteEditedValue>();

    useEffect(() => {
        if (isNotNullOrUndefined(openDefaultValue))
            setSelectedTab(
                tabs.find(x => x.value === openDefaultValue) || tabs[0],
            );
    }, []);

    const renderTab = () => {
        switch (selectedTab.value) {
            case 'ABOUT_PATIENT':
                return aboutPatientTabRender();
            case 'NOTES':
                return (
                    <MedicAppointmentDetailsNotesTab
                        appointment={appointment}
                        source={source}
                        drugMedicalRecommendationsEdited={
                            drugMedicalRecommendationsEdited
                        }
                        setDrugMedicalRecommendationsEdited={
                            setDrugMedicalRecommendationsEdited
                        }
                        medicalInterviewEdited={medicalInterviewEdited}
                        setMedicalInterviewEdited={setMedicalInterviewEdited}
                        referralMedicalRecommendationsEdited={
                            referralMedicalRecommendationsEdited
                        }
                        setReferralMedicalRecommendationsEdited={
                            setReferralMedicalRecommendationsEdited
                        }
                        otherMedicalRecommendationsEdited={
                            otherMedicalRecommendationsEdited
                        }
                        setOtherMedicalRecommendationsEdited={
                            setOtherMedicalRecommendationsEdited
                        }
                    />
                );
            case 'OCCUPATIONAL_MEDICINE':
                return (
                    <OccupationalMedicineTab
                        appointment={appointment}
                        source={source}
                    />
                );
            case 'FILES':
                return filesTabRender();
            default:
                return '';
        }
    };

    return (
        <Box>
            <Box className={classes.tabsBar}>
                <Grid container justifyContent={'space-between'}>
                    <Box
                        className={clsx({
                            [classes.selectButtonsWrapperReducedWidth]:
                                handleClose,
                        })}
                    >
                        <SelectButtons
                            value={selectedTab}
                            options={tabs}
                            onChange={setSelectedTab}
                            containerStyles={classes.tabsContainer}
                            buttonStyles={classes.selectButton}
                        />
                    </Box>
                    {handleClose && (
                        <Close
                            className={classes.closeIconButton}
                            onClick={handleClose}
                        />
                    )}
                </Grid>
            </Box>
            {renderTab()}
        </Box>
    );
};

export default DetailsTabs;
