import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        settingsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 64px)',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: 'white',
                justifyContent: 'flex-start',
            },
        },
        settingsWrapper: {
            backgroundColor: WHITE,
            padding: '20px 24px',
            borderRadius: 3,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        participantName: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 24,
            marginBottom: 24,
        },
    }),
);

export default useStyles;
