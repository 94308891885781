import React, {useCallback, useEffect, useState} from 'react';
import useStyles from './PatientMedicsPageStyles';
import {Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import PatientMedicTile from '../../components/patientMedics/PatientMedicTile/PatientMedicTile';
import patientMedicsProvider from '../../services/patientMedicsProvider';
import {PatientMedic} from '../../types/patientMedics';
import {Loading} from '../../components';
import {isEmpty} from 'lodash-es';
import clsx from 'clsx';
import EmptyAccountPage from '../../components/common/EmptyAccountPage/EmptyAccountPage';

const PatientMedicsPage = () => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);
    const [loading, setLoading] = useState(false);
    const [patientMedics, setPatientMedics] = useState<PatientMedic[]>();

    const fetchPatientMedics = useCallback(async () => {
        setPatientMedics(await patientMedicsProvider.getPatientMedics());
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchPatientMedics();
        setLoading(false);
    }, []);

    const removeMedicHandler = async (medicId: string, medicalServiceId: number) => {
        setLoading(true);
        await patientMedicsProvider.removePatientMedic(medicId, medicalServiceId);
        await fetchPatientMedics();
        setLoading(false);
    };

    return (
        <Box className={classes.container}>
            <Loading loading={loading} withBackdrop />
            <Box className={classes.pageContent}>
                <Box
                    className={clsx(classes.title, {
                        [classes.bottomBorder]: !isEmpty(patientMedics),
                    })}
                >
                    {t('yourMedics')}
                </Box>
                {isEmpty(patientMedics) ? (
                    <EmptyAccountPage label={t('emptyPatientMedicsContent')} />
                ) : (
                    patientMedics?.map(medic => (
                        <PatientMedicTile
                            key={medic.medicId}
                            medic={medic}
                            onRemove={() => removeMedicHandler(medic.medicId, medic.medicalServiceId)}
                        />
                    ))
                )}
            </Box>
        </Box>
    );
};

export default PatientMedicsPage;
