import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {mobile} from '../../../../const/sizes';
import {getShortDateFromISO} from '../../../../utils/date';
import {isNotNullOrUndefined} from '../../../../utils/lang';
import AppointmentPatientPurchaseOptionWrapper from '../AppointmentPatientPurchaseOptionWrapper/AppointmentPatientPurchaseOptionWrapper';
import {ReactNode} from 'react';

type GroupPackagePurchaseOptionProps = {
    b2bClientName: string;
    groupPackageName: string;
    id: string;
    limit: number;
    price: number;
    validUntil: string;
    hideLimit: boolean;
    infoIcon?: ReactNode;
};

const PatientGroupPackagePurchaseOption = ({
    b2bClientName,
    groupPackageName,
    id,
    limit,
    price,
    validUntil,
    hideLimit,
    infoIcon,
}: GroupPackagePurchaseOptionProps) => {
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            label: {
                fontSize: '1rem',
            },
            b2bClient: {
                marginBottom: mobile.contentSmallerSpacing,
            },
            limit: {
                color: theme.palette.secondary.main,
                fontWeight: 'bold',
            },
            validUntil: {
                color: theme.palette.grey[600],
            },
            [theme.breakpoints.up('sm')]: {
                icon: {
                    marginLeft: mobile.contentSmallerSpacing,
                },
                limit: {
                    display: 'inline',
                    marginLeft: mobile.contentSmallerSpacing,
                },
                validUntil: {
                    display: 'inline',
                    marginLeft: mobile.contentSmallerSpacing,
                },
            },
        }),
    );

    const classes = useStyles();

    const hideLabel = hideLimit || !isNotNullOrUndefined(limit);

    return (
        <AppointmentPatientPurchaseOptionWrapper
            id={id}
            price={price}
            disable={limit === 0}
        >
            <Box className={classes.label}>
                <Box className={classes.b2bClient}>
                    {t('inGroupPackageFrom')} {b2bClientName}
                </Box>
                <Box display={'flex'}>
                    <Box>
                        {groupPackageName}
                        {!hideLabel && <span>: </span>}
                        {!hideLabel && (
                            <Box className={classes.limit}>
                                {t('numberOfConsultations')} {limit}{' '}
                            </Box>
                        )}
                        {/*//FIXME: Date is commented temporarily for Millennium Leasing needs*/}
                        {/*<Box className={classes.validUntil}>*/}
                        {/*    ({t('until')} {getShortDateFromISO(validUntil)})*/}
                        {/*</Box>*/}
                    </Box>
                    <Box className={classes.icon}>{infoIcon}</Box>
                </Box>
            </Box>
        </AppointmentPatientPurchaseOptionWrapper>
    );
};

export default PatientGroupPackagePurchaseOption;
