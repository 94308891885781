import { Box, TextField, Typography } from '@material-ui/core';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button, Loading } from '../../components';
import {
    PHONE_NUMBER_CHANGED_PAGE,
} from '../../const/routes';
import { useAppDispatch, useAppSelector } from '../../hooks/customReduxHooks';
import {
    selectAuthStatus,
    selectAuthUserData,
} from '../../store/auth';
import useStyles from './ChangePhoneNumberVerificationPageStyles';
import AccountProvider from '../../services/accountProvider';
import useQuery from '../../hooks/useQuery';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import { AxiosError } from 'axios';
import { changePhoneNumberErrorHandler as errorHandler } from '../../utils/error';
import { isNotNullOrUndefined } from '../../utils/lang';
type LocationState = {
    phone: string;
};

const ChangePhoneNumberVerificationPage = () => {
    const { t } = useTranslation(i18nNamespaces.SETTINGS)
    const dispatch = useAppDispatch();
    const authUserData = useAppSelector(selectAuthUserData);
    const status = useAppSelector(selectAuthStatus);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation<LocationState>();
    const history = useHistory();
    const [code, setCode] = useState<string>('');

    useEffect(() => {
        const { phone } = location.state;
        console.log('Change Phone Verification: ', phone);
    }, [authUserData, history, location]);

    useEffect(() => {
        if (status === 'failed') setError(t("invalidCodeTryAgain"));
    }, [status]);

    const handleError = (error: AxiosError) => {
        setError(t(errorHandler(error.response.data[1])));
    };

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const { phone } = location.state;
        try {
            setLoading(true);
            await AccountProvider.verifySMSCodeToChangePhoneNumber(phone, code);
            history.push(PHONE_NUMBER_CHANGED_PAGE);
        }
        catch (e) {
            handleError(e as AxiosError);
        }
        finally {
            setLoading(false);
        }
    };

    const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
        setError('');
    };

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Loading loading={loading} withBackdrop />
            <Box
                py={3}
                justifyContent="center"
                alignItems="center"
                display={'flex'}
                flexDirection="column"
            >
                <Typography variant={'h3'}>
                    {t('phoneNumberVerification')}
                </Typography>
                <Box component="span" pt={2} pb={4}>
                    {t('smsWithVerificationCodeWasSend')}
                </Box>
                <form onSubmit={onSubmit}>
                    <Box mb={2}>
                        <TextField
                            className={classes.input}
                            label={t('enterCodeFromSMS')}
                            type="text"
                            id="secondName"
                            variant="outlined"
                            value={code}
                            onChange={onCodeChange}
                            autoComplete="off"
                            error={!!error}
                            helperText={error && error}
                        />
                    </Box>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        className={classes.button}
                        disabled={!code}
                    >
                        {t('next')}
                    </Button>
                </form>
            </Box>
        </div>
    );
};

export default ChangePhoneNumberVerificationPage;
