import {Box} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {ArticleBlocker, ArticleContent, Loading} from '../../components';
import { MEDIC } from '../../const/auth';
import { MEDIC_CALENDAR } from '../../const/routes';
import {useAppSelector} from '../../hooks/customReduxHooks';
import articlesProvider from '../../services/articlesProvider';
import {selectAuthUserData} from '../../store/auth';
import { ArticleAccessDto } from '../../types/articles';

const ArticlePage = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
           <ArticleContent />
    );
};
export default ArticlePage;
