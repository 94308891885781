import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { desktop, mobile } from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: mobile.contentLargerSpacing,
        },
        title: {
            fontSize: 36,
            fontWeight: 'bold',
            marginBottom: 30,
        },
        formField: {
            marginBottom: 24,
        },
        submitButton: {
            marginBottom: 24,
        },
        passwordLabelContainer: {
            width: '100%',
        },
        passwordStrengthBarContainer: {
            flex: 1,
            paddingLeft: 10,
        },
        error: {
            color: 'red',
            marginBottom: 10,
        },
        [theme.breakpoints.up('sm')]: {
            container: {
                padding: desktop.contentLargerSpacing,
                width: '100%',
            },
            title: {
                fontSize: 56,
            },
            submitButton: {
                width: 300,
            },
            passwordLabelContainer: {
                width: 300,
            },
            image: {
                width: 480,
            },
        },
    }),
);

export default useStyles;
