import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Appointment, OccupationalMedicineData} from '../types/appointments';
import AppointmentsProvider from '../services/appointmentsProvider';
import mapAppointment, {synchronizeAppointment} from './mappers/mapAppointment';
import {RootState} from './store';
import appointmentsProvider from '../services/appointmentsProvider';
import {appointmentsApi} from '../api/appointments/appointmentsApi';

const sliceName = 'appointmentDetails';

interface AppointmentDetailsState {
    appointment?: Appointment;
    status: 'idle' | 'loading' | 'failed';
    appointmentSynchronizationErrorKey: string;
    occupationalMedicineData?: OccupationalMedicineData;
}

const initialState: AppointmentDetailsState = {
    status: 'idle',
    appointmentSynchronizationErrorKey: '',
};

export const fetchAppointmentDetails = createAsyncThunk<
    Appointment,
    {appointmentId: string; fetchSilently: boolean},
    unknown
>(`${sliceName}/fetchAppointmentDetails`, async ({appointmentId}) => {
    const appointment = await AppointmentsProvider.getAppointment(
        appointmentId,
    );
    return mapAppointment(appointment);
});

export const fetchOccupationalMedicineData = createAsyncThunk<
    OccupationalMedicineData,
    {appointmentId: string},
    unknown
>(`${sliceName}/fetchOccupationalMedicineData`, async ({appointmentId}) => {
    const {data} = await appointmentsApi.getOccupationalMedicineData(
        appointmentId,
    );
    return data;
});

export const synchronizeAppointmentDetails = createAsyncThunk<
    Appointment,
    {appointmentId: number},
    {state: RootState}
>(
    `${sliceName}/synchronizeAppointmentDetails`,
    async ({appointmentId}, thunkAPI) => {
        const appointment = thunkAPI.getState().appointmentDetails.appointment;
        const {data} = await appointmentsProvider.getAppointmentChangingData(
            appointmentId,
        );
        return synchronizeAppointment(appointment, data);
    },
);

export const setAdditionalPatientDetails = createAsyncThunk<
    void,
    {appointmentId: string; patientReasons: string},
    unknown
>(
    `${sliceName}/setAdditionalPatientDetails`,
    async ({appointmentId, patientReasons}) => {
        await AppointmentsProvider.setPatientAppointmentAdditionalDetails(
            appointmentId,
            patientReasons,
        );
    },
);

const appointmentDetailsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        clearAppointmentDetailsState: () => initialState,
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAppointmentDetails.pending, (state, action) => {
                state.status = action.meta.arg.fetchSilently
                    ? state.status
                    : 'loading';
            })
            .addCase(fetchAppointmentDetails.fulfilled, (state, action) => {
                state.appointment = action.payload;
                state.status = 'idle';
            })
            .addCase(fetchAppointmentDetails.rejected, state => {
                state.status = 'failed';
            });

        builder
            .addCase(
                synchronizeAppointmentDetails.fulfilled,
                (state, action) => {
                    state.appointment = action.payload;
                },
            )
            .addCase(synchronizeAppointmentDetails.rejected, state => {
                state.appointmentSynchronizationErrorKey =
                    'errorOccurredWhileFetchingActualDataOfAppointment';
            });

        builder
            .addCase(
                fetchOccupationalMedicineData.fulfilled,
                (state, action) => {
                    state.occupationalMedicineData = action.payload;
                },
            )
            .addCase(fetchOccupationalMedicineData.rejected, state => {
                state.appointmentSynchronizationErrorKey =
                    'errorOccurredWhileFetchingActualDataOfAppointment';
            });
    },
});

export const {clearAppointmentDetailsState} = appointmentDetailsSlice.actions;

export const selectAppointmentDetails = (state: RootState) =>
    state.appointmentDetails.appointment;

export const selectAppointmentStatus = (state: RootState) =>
    state.appointmentDetails.status;

export const selectAppointmentGroup = (state: RootState) =>
    state.appointmentDetails.appointment.appointmentGroup;

export const selectSynchronizationErrorKey = (state: RootState) =>
    state.appointmentDetails.appointmentSynchronizationErrorKey;

export const selectAppointmentPaymentstatus = (state: RootState) =>
    state.appointmentDetails.appointment.paymentStatus;

export const selectOccupationalMedicineData = (state: RootState) =>
    state.appointmentDetails.occupationalMedicineData;

export default appointmentDetailsSlice.reducer;
