import {Box, Button} from '@material-ui/core';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Loading, MojeIDRegisterForm} from '../../components';
import i18nNamespaces from '../../const/i18nNamespaces';
import {CONFIRMED_IDENTITY, PHONE_VERIFICATION_PAGE} from '../../const/routes';
import accountProvider from '../../services/accountProvider';
import {ConfirmedIdentityDto} from '../../types/auth';
import {RegisterWithIdentityDto} from '../../types/Login';
import useStyles from './RegisterMojeIDPageStyles';
import auth, {
    authStatusSpecified,
    confirmIdentityWithMojeId,
    selectAuthEmail,
    selectAuthError,
    selectAuthStatus,
    selectAuthUserData,
    signUpRegisterMojeId,
} from '../../store/auth';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {MojeIDRegisterFormValues} from '../../components/forms/MojeIDRegisterForm/MojeIDRegisterForm';

const RegisterMojeIDPage = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const authStatus = useAppSelector(selectAuthStatus);
    const authEmail = useAppSelector(selectAuthEmail);
    const singUpError = useAppSelector(selectAuthError);

    const authData = useAppSelector(selectAuthUserData);
 
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const [confirmedIdentityLoading, setConfirmedIdentityLoading] =
        useState<boolean>(false);
    const [registerMode, setRegisterMode] = useState<boolean>(false);

    const loading = confirmedIdentityLoading || authStatus === 'loading';

    const [confirmedIdentity, setConfirmedIdentity] =
        useState<ConfirmedIdentityDto>(null);

    const loadConfirmedIdentity = useCallback(async () => {
        setConfirmedIdentityLoading(true);

        setConfirmedIdentity(await accountProvider.getConfirmedIdentity());

        setConfirmedIdentityLoading(false);
    }, []);

    const submitHandler = async (
        registerWithIdentity: MojeIDRegisterFormValues,
    ) => {
        await dispatch(signUpRegisterMojeId(registerWithIdentity));
        history.push({
            pathname: PHONE_VERIFICATION_PAGE,
            state: {email: authEmail},
        });
    };

    const submitConfirmIdentities = async (
        
    )=>{
        await dispatch(confirmIdentityWithMojeId());
    }


    useEffect(() => {
        loadConfirmedIdentity();
    }, [loadConfirmedIdentity]);


    useEffect(()=>{
        if(authData===null){
            setRegisterMode(true);
        }
        else
            history.push(CONFIRMED_IDENTITY)

    }, [authData]);

    useEffect(() => {
        if(registerMode){
            if (authStatus === 'loaded') {
                history.push({
                    pathname: PHONE_VERIFICATION_PAGE,
                    state: {email: authEmail},
                });
            }
            return () => {
                dispatch(authStatusSpecified('idle'));
            };
        }
    }, [authStatus, registerMode]);

    const classes = useStyles();

    return (
        <>
            <Loading loading={loading} withBackdrop />
            <Box className={classes.container}>
                <Box className={classes.header}>
                    {t('enterRegistrationData')}
                </Box>
                {confirmedIdentity && (
                    <Box>
                        <Box>
                            {confirmedIdentity.firstName}{' '}
                            {confirmedIdentity.lastName}
                        </Box>
                        <Box>PESEL: {confirmedIdentity.pesel}</Box>
                    </Box>
                )}
                <MojeIDRegisterForm
                    onSubmit={submitHandler}
                    error={singUpError}
                />
            </Box>
        </>
    );
};

export default RegisterMojeIDPage;
