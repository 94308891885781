export const postDraft = () =>
    `/api/kedzla/draft`;

export const getCodes = () =>
    `/api/kedZla/cancel-reasons`

export const getIfCancellationPossible = (seriesAndNumber: string) =>
    `/api/kedZla/${seriesAndNumber}/cancellation`;

export const deleteZLA = (seriesAndNumber: string) =>
    `/api/kedZla/${seriesAndNumber}`;

export const postZLA = () =>
    `/api/kedzla`;

export const getZLAsByAppointment = (appointmentId: number)=>
    `/api/kedzla/appointments/${appointmentId}`;

export const getZLAs = (page: number, size: number)=>
    `/api/kedzla?page=${page}&size=${size}`;

export const getLinkedZLAs = (seriesAndNumber: string) =>
    `/api/kedzla/${seriesAndNumber}/linked`;

export const getZLA = (seriesAndNumber: string)=>
    `/api/kedzla/${seriesAndNumber}/summary`;
