import { MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { useAppDispatch } from "../../../hooks/customReduxHooks";
import { isNotNullOrUndefined } from "../../../utils/lang";
import * as yup from 'yup';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18nNamespaces from "../../../const/i18nNamespaces";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import { useFormik } from "formik";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { PatientSummaryDto } from "../../../types/occupationalMedicine";
import CustomSelectField from "../../common/CustomSelectField/CustomSelectField";
import { RenderReturnNextButtons } from "../CommonView";
import { useParams } from "react-router";
import { fetchSection7Data } from "../../../store/occupationalMedicineSlice";

const REQUIRED_STRING = 'requiredField';
const SECTION = 7;

export type Props = {
    data: PatientSummaryDto,
    onSubmitSection: (values:string, fetchDataFunction:any, currentSection:number, stayOnCurrent?:boolean) =>void
    currentExpanded:number
    onReturn: () => void;
    refSection:MutableRefObject<any>
}
const PatientSummaryComponent = ({
    data, onSubmitSection, currentExpanded, onReturn, refSection
}:Props) =>{

    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const {t:commonT} = useTranslation(i18nNamespaces.COMMON);
    const classes = useOccupationalMedicineStyles();
    const {appointmentId} = useParams<{appointmentId: string}>();
    const dispatch = useAppDispatch();

    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(()=>{
        if(isNotNullOrUndefined(data))
            setIsSaved(true);
    }, [data]);
    
    
    const onSubmit = () => {
        onSubmitSection(JSON.stringify(values), fetchSection7Data({id:appointmentId}),7);
    }
    
    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {
                healthAssessment:data?.healthAssessment??'',
                hadSurgery:{
                    occured: data?.hadSurgery.occured ?? false,
                    description: data?.hadSurgery.description ?? '',
                },
                isUnderSpecialistClinicCare: {
                    occured: data?.isUnderSpecialistClinicCare.occured ?? false,
                    description: data?.isUnderSpecialistClinicCare.description ?? '',
                },
                takesMedications:{
                    occured: data?.takesMedications.occured ?? false,
                    description: data?.takesMedications.description ?? '',
                },
                
            },

            validationSchema: yup.object({
                healthAssessment: yup.string().required(commonT(REQUIRED_STRING)),
                hadSurgery: yup.object().shape({
                    description: yup.string().when('occured', {
                        is:true,
                        then: yup.string().required(commonT(REQUIRED_STRING)),
                    })
                }),
                isUnderSpecialistClinicCare: yup.object().shape({
                    description: yup.string().when('occured', {
                        is:true,
                        then: yup.string().required(commonT(REQUIRED_STRING)),
                    })
                }),
                takesMedications: yup.object().shape({
                    description: yup.string().when('occured', {
                        is:true,
                        then: yup.string().required(commonT(REQUIRED_STRING)),
                    })
                })
            }),
            onSubmit
        });

    const renderGridItemWithCheckBox = (idAndName:string, value:boolean, translation:string) =>
        (
            <Grid item xs={8}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={idAndName}
                                name={idAndName}
                                checked={value}
                                onChange={handleChange}
                            />
                        }
                        label={
                            <Box className={classes.text}>
                                {t(translation)}
                            </Box>
                        }    
                        />
                </FormGroup>
            </Grid>               
        )

    const renderGridItemWithTextField = (idAndName:string, value:string, translation:string, touched:any, errors:any) =>(
            <Grid item xs={8}>
                <FormGroup>
                    <Label htmlFor={translation}>{t(translation)} *</Label>
                    <CustomTextField 
                            fullWidth 
                            id={idAndName}
                            name={idAndName}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={value}
                            helperText={touched && t(errors)}
                            error={touched && !!errors}
                            />
                </FormGroup>
            </Grid>)

    
    return (
        <form onSubmit={handleSubmit}>
       <Accordion square defaultExpanded={currentExpanded===SECTION} className={classes.container} key={SECTION+currentExpanded} ref={refSection}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >

                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('summary')}
                        </h4>

                        {isSaved && <CheckCircleOutline className={classes.alertIcon}/>}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.detailsContainer}>
                    <Box>
                        <Grid container spacing={1} className={classes.gridContainer}>

                          <Grid item xs={4}>
                            <FormGroup>
                                    <Box className={classes.titleContainer + " "+ classes.sectionHeader}>
                                        {t('healthAssessment')} *
                                    </Box>
                                    <CustomSelectField
                                        id="healthAssessment"
                                        name="healthAssessment"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.healthAssessment}
                                        helperText={touched.healthAssessment && t(errors.healthAssessment)}
                                        error={touched.healthAssessment && !!errors.healthAssessment}
                                    >
                                        <MenuItem value={t('veryGood')} id="0">
                                            {t('veryGood')}
                                        </MenuItem>
                                        <MenuItem value={t('good')} id="1">
                                            {t('good')}
                                        </MenuItem>
                                        <MenuItem value={t('ratherGood')} id="2">
                                            {t('ratherGood')}
                                        </MenuItem>
                                        <MenuItem value={t('ratherWeak')} id="3">
                                            {t('ratherWeak')}
                                        </MenuItem>
                                        <MenuItem value={t('wood')} id="4">
                                            {t('weak')}
                                        </MenuItem>
                                    </CustomSelectField>                          
                            </FormGroup>
                        </Grid>  
                        <Grid item xs={8}></Grid>

                        {renderGridItemWithCheckBox("hadSurgery.occured", 
                                values.hadSurgery.occured, "hadSurgery")}  
                            <Grid item xs={4}></Grid>
                            {values.hadSurgery.occured
                                 && renderGridItemWithTextField("hadSurgery.description", 
                                 values.hadSurgery.description, "whatWhenDescriprion", 
                                 touched.hadSurgery?.description, errors.hadSurgery?.description)} 

                        {renderGridItemWithCheckBox("isUnderSpecialistClinicCare.occured", 
                                values.isUnderSpecialistClinicCare.occured, "isUnderSpecialistClinicCare")}  
                            <Grid item xs={4}></Grid>
                            {values.isUnderSpecialistClinicCare.occured
                                 && renderGridItemWithTextField("isUnderSpecialistClinicCare.description", 
                                 values.isUnderSpecialistClinicCare.description, "whichDescription", 
                                 touched.isUnderSpecialistClinicCare?.description, errors.isUnderSpecialistClinicCare?.description)} 

                        {renderGridItemWithCheckBox("takesMedications.occured", 
                                values.takesMedications.occured, "takesMedications")}  
                            <Grid item xs={4}></Grid>
                            {values.takesMedications.occured
                                 && renderGridItemWithTextField("takesMedications.description", 
                                 values.takesMedications.description, "whatDescription", 
                                 touched.takesMedications?.description, errors.takesMedications?.description)}  

                        </Grid>
                        {RenderReturnNextButtons(classes, t, onReturn)}
                    </Box>

                </AccordionDetails>
            </Accordion>
        </form>
    )
};

export default PatientSummaryComponent;