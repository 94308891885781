import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {QuestionnaireDetails} from "../types/appointments";
import AppointmentsProvider from "../services/appointmentsProvider";
import {RootState} from "./store";

const sliceName = 'questionnaireDetails';

interface QuestionnaireDetailsState {
    questionnaireDetails: QuestionnaireDetails | null;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: QuestionnaireDetailsState = {
    questionnaireDetails: null,
    status: 'idle',
}

export const fetchQuestionnaireDetails =
    createAsyncThunk<QuestionnaireDetails, {appointmentId: string; fetchSilently: boolean}, unknown>(
        `${sliceName}/fetchQuestionnaireDetails`,
        async ({appointmentId}) => {
            return await AppointmentsProvider.getQuestionnaireDetails(appointmentId);
        });

const questionnaireDetailsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        clearQuestionnaireDetails: (state) => {
            state.questionnaireDetails = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchQuestionnaireDetails.pending, (state, action) => {
                state.status = action.meta.arg.fetchSilently
                    ? state.status
                    : 'loading';
            })
            .addCase(fetchQuestionnaireDetails.fulfilled, (state, action) => {
                state.questionnaireDetails = action.payload;
                state.status = 'idle';
            });
    },
})

export const {clearQuestionnaireDetails} = questionnaireDetailsSlice.actions;

export const selectQuestionnaireDetails = (state: RootState) =>
    state.questionnaireDetails.questionnaireDetails;

export const selectQuestionnaireDetailsStatus = (state: RootState) =>
    state.questionnaireDetails.status;

export default questionnaireDetailsSlice.reducer;

