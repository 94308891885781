import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../const/colors';
import {desktop, mobile} from '../../const/sizes';

export const availabilityTemplateDayWidth = '300px';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        changesFromWrapper: {
            width: '100%',
            padding: mobile.contentLargerSpacing,
            marginTop: mobile.contentSmallerSpacing,
            backgroundColor: WHITE,
        },
        changesFromTitle: {
            fontWeight: 'bold',
            fontSize: 18,
            marginBottom: mobile.contentSmallerSpacing,
        },
        changesFromContent: {
            color: theme.palette.grey[600],
        },
        availabilityTemplateDaysContainer: {
            padding: `0 ${mobile.contentLargerSpacing}`,
        },
        availabilityTemplateDayWrapper: {
            marginTop: mobile.contentSmallerSpacing,
        },
        availabilityTemplateSummaryWrapper: {
            margin: `${mobile.contentSmallerSpacing} 0`,
        },
        effectiveDate: {
            color: theme.palette.grey['700'],
            marginBottom: desktop.contentSmallerSpacing,
        },
        [theme.breakpoints.up('sm')]: {
            availabilityTemplateContainer: {
                marginLeft: desktop.contentLargerSpacing,
            },
            availabilityTemplateDaysContainer: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                padding: 0,
            },
            availabilityTemplateDayWrapper: {
                width: availabilityTemplateDayWidth,
                marginTop: desktop.contentSmallerSpacing,
                marginRight: desktop.contentSmallerSpacing,
            },
            divider: {
                marginTop: desktop.contentSmallerSpacing,
                marginRight: desktop.contentSmallerSpacing,
            },
            availabilityTemplateSummaryWrapper: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                margin: `${desktop.contentSmallerSpacing} 0`,
                paddingRight: desktop.contentSmallerSpacing,
            },
            saveChanges: {
                width: availabilityTemplateDayWidth,
            },
        },
    }),
);

export default useStyles;
