import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {LoadingStatus} from '../../const/loadingStatus';
import {Appointment} from '../../types/appointments';
import AppointmentsProvider from '../../services/appointmentsProvider';
import mapAppointment from '../mappers/mapAppointment';
import {RootState} from '../store';

const sliceName = 'finished-appointments';

const finishedAppointmentsAdapter = createEntityAdapter<Appointment>({
    selectId: appointment => appointment.id,

});

const initialState = finishedAppointmentsAdapter.getInitialState({
    status: 'idle' as LoadingStatus,
    page: 0,
    size: 10,
    count: 0,
});

export type FinishedAppointmentsSliceState = typeof initialState;

export const fetchFinishedAppointments = createAsyncThunk<
    {
        appointments: Appointment[];
        page: number;
        count: number;
    },
    {fetchSilently: boolean},
    {
        state: RootState;
    }
>(`${sliceName}/fetchFinishedAppointments`, async (_, thunkApi) => {
    const {page, size} = selectFinishedAppointments(thunkApi.getState());
    const {
        page: resultPage,
        results,
        count,
    } = await AppointmentsProvider.getFinishedAppointments(page, size);

    return {
        appointments: results.map(mapAppointment),
        page: resultPage,
        count,
    };
});

const finishedAppointmentsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        pageSpecified(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        sizeSpecified(state, action: PayloadAction<number>) {
            state.size = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFinishedAppointments.pending, (state, action) => {
                state.status = action.meta.arg.fetchSilently
                    ? state.status
                    : 'loading';
            })
            .addCase(fetchFinishedAppointments.fulfilled, (state, action) => {
                state.status = 'loaded';

                state.page = action.payload.page;
                state.count = action.payload.count;
                finishedAppointmentsAdapter.setAll(
                    state,
                    action.payload.appointments,
                );
            })
            .addCase(fetchFinishedAppointments.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const {pageSpecified} = finishedAppointmentsSlice.actions;

export const selectFinishedAppointments = (state: RootState) =>
    state.finishedAppointments;
export const selectFinishedAppointmentsStatus = (state: RootState) =>
    selectFinishedAppointments(state).status;
export const selectFinishedAppointmentsPage = (state: RootState) =>
    selectFinishedAppointments(state).page;
export const selectFinishedAppointmentsSize = (state: RootState) =>
    selectFinishedAppointments(state).size;
export const selectFinishedAppointmentsCount = (state: RootState) =>
    selectFinishedAppointments(state).count;

export const {
    selectIds: selectFinishedAppointmentIds,
    selectById: selectFinishedAppointmentById,
} = finishedAppointmentsAdapter.getSelectors<RootState>(state =>
    selectFinishedAppointments(state),
);

export default finishedAppointmentsSlice.reducer;
