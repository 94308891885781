import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    reasonsContainer: {
        marginBottom: '24px',
    },
    reasons: {
        margin: '6px 0 0',
        [theme.breakpoints.up('md')]: {
            margin: '8px 0 0',
        }
    },
    listWrapper: {
        marginTop: '19px',
        [theme.breakpoints.up('md')]: {
            marginTop: '16px',
        },
    },
    list: {
        padding: 0,
    },
    listItem: {
        padding: 0,
        width: '375px',
    },
    listItemNext: {
        marginTop: '8px',
    },
    headerContainer:{
        display: 'flex',
        alignItems:'center'
    },
}));

export default useStyles;