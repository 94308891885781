import React, {memo} from 'react';
import {Box, createStyles, makeStyles} from '@material-ui/core';
import {BuyerDataForm, Container} from '../../../../components';
import {WHITE} from '../../../../const/colors';
import {useBuyerDataFrom} from '../../../../hooks/useBuyerDataFrom';
import PaymentSubmit from '../../../../components/payment/PaymentSubmit/PaymentSubmit';
import {BuyerDataDto} from '../../../../types/buyerData';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';

const useStyles = makeStyles(() =>
    createStyles({
        invoiceInformationRoot: {
            marginTop: 8,
        }
    }),
);

interface Props {
    onSubmit: (buyerData: BuyerDataDto) => void;
}

const InvoiceInformationCollect = memo(function MedicalSearchFilters({onSubmit}: Props) {
    const classes = useStyles();
    const {t: tPayment} = useTranslation(i18nNamespaces.TIME_SLOTS_PAYMENT);

    const paymentSubmitHandler = async (buyerData: BuyerDataDto) => {
        const data: BuyerDataDto = {
            type: buyerData.type,
            name: buyerData.name,
            surname: buyerData.surname,
            companyName: buyerData.companyName,
            nip: buyerData.nip,
            streetName: buyerData.streetName,
            houseNumber: buyerData.houseNumber,
            apartmentNumber: buyerData.apartmentNumber,
            postcode: buyerData.postcode,
            cityName: buyerData.cityName,
        }
        onSubmit(data);
    }

    const buyerDataFormConfig = useBuyerDataFrom(paymentSubmitHandler);

    return (
        <Box className={classes.invoiceInformationRoot}>
            <form onSubmit={buyerDataFormConfig.handleSubmit}>
                <Box style={{backgroundColor: WHITE}}>
                    <Container mdDesktop>
                        <BuyerDataForm buyerDataFormConfig={buyerDataFormConfig} />
                    </Container>
                </Box>
                <Box style={{marginTop: 8}}>
                    <PaymentSubmit submitText={tPayment('next')} />
                </Box>
            </form>
        </Box>
    );
});

export default InvoiceInformationCollect;