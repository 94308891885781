import { Grid, IconButton } from "@material-ui/core";
import { AccountCircleOutlined, ChildCareOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../const/i18nNamespaces";
import { useAppDispatch, useAppSelector } from "../../hooks/customReduxHooks";
import AccountProvider from "../../services/accountProvider";
import { selectAuthUserData } from "../../store/auth";
import {selectChild, 
    selectSelectedChildId } from "../../store/childSelectionSlice";
import { PatientsChildDto } from "../../types/children";
import useStyles from "./PatientSelectorStyles";


const PatientSelector = () =>{
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();

    const [patientsChildren, setPatientsChildren] = useState<PatientsChildDto[]>([]);

    const selectedChildId = useAppSelector(selectSelectedChildId);
     //to wyciągnięcia patientId 
    const fetchPatientsChildren = async () => {
        const {data} = await AccountProvider.getPatientChildren();
        setPatientsChildren(data);
    }

    useEffect(() => {
        fetchPatientsChildren();
    }, []);

    const selectPatientId = () =>{
        dispatch(selectChild({id: null}));
    }

    const selectChildId = (id: number) =>{
        dispatch(selectChild({id: id}));
    }

    return (
        <Grid 
            container 
            direction={isDesktop ? 'row' : 'column'}
            className={classes.container}
            spacing={1}>
            <Grid item>{t('showResultsFor')}</Grid>
            <Grid item>
                
                <IconButton                    
                    className={selectedChildId===null ? classes.buttonSelected: classes.button}
                    onClick={selectPatientId}
                    >
                    <AccountCircleOutlined 
                        className={classes.editIcon}/>
                    {t('me')}
                </IconButton>
              
            </Grid>
            {patientsChildren && patientsChildren.map(
                child=>(
                    <Grid item key={child.id}>
                        <IconButton 
                            className={selectedChildId===child.id ?  classes.buttonSelected: classes.button}
                            onClick={()=> selectChildId(child.id)}>
                            <ChildCareOutlined 
                                className={classes.editIcon}/>
                            {child.name}
                        </IconButton>
                    </Grid>
                )
            )}
            
        </Grid>)
}

export default PatientSelector;