import {createStyles, makeStyles} from "@material-ui/core";
import {LIGHT_DISABLED, MAGENTA} from "../../../../const/colors";

const useStyles = makeStyles(() => createStyles({
    container: {
        position: 'relative',
        marginLeft: '11px',
        display: 'flex',
        alignItems: 'center',
        color: MAGENTA,
        cursor: 'pointer',
    },
    saveIcon: {
        marginRight: '7px',
    },
    disabled: {
        color: LIGHT_DISABLED,
        cursor: 'default',
    },
    progress: {
        color: MAGENTA,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        animation: 'none',
        zIndex: 1,
    },
}));

export default useStyles;