import React, {memo, useEffect, useState} from 'react';
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {CustomAlertProps} from '../../../types/alert';

const AUTO_HIDE_DURATION = 10000;

const CustomAlert = (props: CustomAlertProps) => {
    const {message, severity, disableAutoHide, onClose, open, duration} = props;

    const autoHideDuration = !disableAutoHide
        ? duration || AUTO_HIDE_DURATION
        : null;

    return message && severity ? (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
        >
            <Alert severity={severity} onClose={onClose}>
                {message}
            </Alert>
        </Snackbar>
    ) : null;
};

export default memo(CustomAlert);
