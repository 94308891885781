import {Box, createStyles, Grid, makeStyles} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {
    BACKGROUND_SECONDARY,
    TEXT_SECONDARY,
    WHITE,
} from '../../../const/colors';
import {mobile} from '../../../const/sizes';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
        },
        goBack: {
            width: 40,
            height: 40,
            backgroundColor: BACKGROUND_SECONDARY,
            color: TEXT_SECONDARY,
            borderRadius: 20,
            marginRight: mobile.contentLargerSpacing,
            padding: 6,
            cursor: 'pointer',
        },
        text: {
            fontSize: 18,
            fontWeight: 'bold',
        },
    }),
);

type MobileSubheaderProps = {
    onBack: () => void;
    text: string;
};

const MobileSubheader = ({onBack, text}: MobileSubheaderProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.container}
        >
            <Box onClick={onBack} className={classes.goBack}>
                <ChevronLeft />
            </Box>
            <Box className={classes.text}>{text}</Box>
        </Grid>
    );
};

export default MobileSubheader;
