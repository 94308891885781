import {DateTime} from 'luxon';
import {v4} from 'uuid';
import {
    DayScheduleTemplate,
    DayScheduleTemplateDto,
    TimeSlotTemplateDto,
} from '../../types/dayScheduleTemplates';
import TimeOfDay from '../../utils/timeOfDay';

export const mapFromServerDayScheduleTemplate = (
    dayScheduleTemplateResponse: DayScheduleTemplateDto,
) =>
    ({
        dayOfWeek: dayScheduleTemplateResponse.dayOfWeek,
        effectiveDate: DateTime.fromISO(
            dayScheduleTemplateResponse.effectiveDate,
        ),
        canAddNewSlotTemplate: false,
        timeSlotTemplates: dayScheduleTemplateResponse.timeSlotTemplates
            .map(timeSlotTemplateResponse => ({
                id: v4(),
                canEdit: false,
                earliestStartTime: null,
                startTime: new TimeOfDay(timeSlotTemplateResponse.startTime),
                endTime: new TimeOfDay(timeSlotTemplateResponse.endTime),
            }))
            .sort(
                (firstTimeSlotTemplate, secondTimeSlotTemplate) =>
                    +firstTimeSlotTemplate.startTime -
                    +secondTimeSlotTemplate.startTime,
            ),
    } as DayScheduleTemplate);

export const mapToFirstEffectiveDate = (
    dayScheduleTemplateResponses: DayScheduleTemplate[],
    medicStartDate: string,
) =>
    dayScheduleTemplateResponses.length
        ? dayScheduleTemplateResponses
              .map(dayScheduleTemplate => dayScheduleTemplate.effectiveDate)
              .sort((first, second) =>
                  first < second ? -1 : first > second ? 1 : 0,
              )[0]
        : DateTime.fromISO(medicStartDate);

export const mapToLastEffectiveDate = (
    dayScheduleTemplateResponses: DayScheduleTemplate[],
) =>
    dayScheduleTemplateResponses.length
        ? dayScheduleTemplateResponses
              .map(dayScheduleTemplate => dayScheduleTemplate.effectiveDate)
              .sort((first, second) =>
                  first < second ? -1 : first > second ? 1 : 0,
              )[dayScheduleTemplateResponses.length - 1]
        : DateTime.now();

export const mapToServerDayScheduleTemplate = (
    dayScheduleTemplate: DayScheduleTemplate,
) =>
    ({
        dayOfWeek: dayScheduleTemplate.dayOfWeek,
        timeSlotTemplates: dayScheduleTemplate.timeSlotTemplates.map(
            timeSlotTemplate =>
                ({
                    startTime: +timeSlotTemplate.startTime,
                    endTime: +timeSlotTemplate.endTime,
                } as TimeSlotTemplateDto),
        ),
    } as DayScheduleTemplateDto);
