import { BuyerDataDto } from "../types/buyerData";
import { DEFAULT_BUYER_DATA } from "./endpoints";
import { rawHttp } from "./http";


class buyerDataProvider {
    async getDefaultByuerData(){
        return await rawHttp.get<BuyerDataDto>(DEFAULT_BUYER_DATA);
    }
}

export default new buyerDataProvider();
