// Development: For DEV environment on DevOps (is set automatically on release)
// Test: For TEST environment on DevOps (is set automatically on release)
// Production: For PROD environment on DevOps (will be added)
type Environment = 'Development' | 'Test' | 'Production';

type ConfigurationType = {
    baseURL: string;
    P1baseURL: string;
    ZLAbaseURL: string;
    environment: Environment;
    joinTimeLimit: number;
    payUPosId: string;
    gaId: string;
    gaTagManagerId: string;
    landingPageURL: string;
    twojaprzychodniaURL: string;
    qbCardApplyUrl: string;
    findUnilinkAgentUrl: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configuration = (window as any)._configuration as ConfigurationType;

export default configuration;
