import { P1Response } from "../types/p1Service";
import { PersonalDataStatusDto, NFZInsuranceDeclarationStatusDto } from "../types/patient";
import { rawP1Service } from "./P1Service";
import { PATIENT_PERSONAL_DATA_STATUS } from "./endpoints";
import { http } from "./http";

export const PATIENT_DECLARATION_DATA_STATUS = '/api/PatientInsurance/Declaration/status';

class PatientProvider {
    async getPatientPersonalDataStatus(
    ) {
        return (
            await http.get<PersonalDataStatusDto>(
                PATIENT_PERSONAL_DATA_STATUS,
            )
        ).data;
    }

    async getNFZInsuranceDeclarationStatus(
        ) {
            return (
                await rawP1Service.get<P1Response<NFZInsuranceDeclarationStatusDto>>(
                    PATIENT_DECLARATION_DATA_STATUS,
                )
            ).data.result;
        }
}

export default new PatientProvider();
