import { Box, List, ListItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getMedicalTestsPdfResults } from "../../../services/diagnostic/orderHeadersProvider";
import { MedicalPdfResults } from "../../../types/referrals";
import UploadedItem from "../../common/UploadedItem/UploadedItem";
import useStyles from "./MedicaltestPdfTesultStyles";
import {PdfImage } from "../../../assets";
import { DateTime } from "luxon";
import FilesDownloadProvider from "../../../services/filesDownloadProvider";
import Loading from "../../common/Loading/Loading";
import { CustomAlert } from "../../common/feedback";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import axios from "axios";

const pdfContentType =  "application/pdf";

const MedicalTestPdfResult= ()=>{
    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);
    const {id} = useParams<{id: string}>();
    const [data, setData] = useState<MedicalPdfResults[]>();
    const [loading, setLoading] = useState<boolean>(false); 
    const [error, setError] = useState('');

    const classes = useStyles();
    
    const fetchData = async () => {
        try{
            setLoading(true);
            setError('');
            const {data} = await getMedicalTestsPdfResults(id);
            setData(data);       
        }
        catch(error){
            if (
                axios.isAxiosError(error) &&
                error.response.status === 404
            )
                return;
            console.error(error);
            setError(t('getFilesError'));
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const downloadFileAsync = (i:number)=>{
        FilesDownloadProvider.downloadFileFromUrl(
            data[i].url, `${t('results')}.pdf`
        );
    }

    return (
    <Box>
        <Box className={classes.loadingContainer}>
            <Loading loading={loading} />
        </Box>
        
        {error && <CustomAlert severity="error" message={error} />}
        {data && data.length==0 && 
            <Box className={classes.info}>{t('noPdfFiles')}</Box>}
        <List className={classes.fileList}>
            
            {data && 
                data.map((file, i) => (
                    <ListItem
                        className={classes.fileListItem}
                        key={i}
                        onClick={() => downloadFileAsync(i)}
                    >
                        <UploadedItem
                            fileName={t('results')}
                            fileIcon={PdfImage}
                            fileOwner={null}
                            fileDateCreated={DateTime.fromISO(file.date).toFormat("dd'.'MM'.'yyyy")}
                            fileTimeCreated={DateTime.fromISO(file.date).toFormat("HH':'mm")}           
                            removeFileEnabled={false}
                        />
                    </ListItem>
                ))
            }
        </List>
    </Box>)
}
export default MedicalTestPdfResult;

