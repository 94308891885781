import {Box, Grid, Theme, Typography, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import IndividualPackage from '../IndividualPackage/IndividualPackage';
import {bundles} from '../../../const/individualPackages';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectedBundleIdSpecified,
    selectSelectedBundleId,
} from '../../../store/individualPackagesSlice';
import useStyles from './ExpandedIndividualPackagesStyles';
import i18nNamespaces from '../../../const/i18nNamespaces';
import IndividualPackagesTable from '../IndividualPackagesTable/IndividualPackagesTable';
import IndividualPackagesTableMobile from '../IndividualPackagesTableMobile/IndividualPackagesTableMobile';
import {generatePath, useHistory} from 'react-router';
import {LOGIN_PAGE, SUBSCRIPTION_PAYMENT_PAGE} from '../../../const/routes';
import {selectAuthUserData} from '../../../store/auth';

const ExpandedIndividualPackages = () => {
    const selectedBundleId = useAppSelector(selectSelectedBundleId);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const onSelectBundle = (id: number) => {
        dispatch(selectedBundleIdSpecified(id));
    };

    const history = useHistory();
    const authUserData = useAppSelector(selectAuthUserData);

    const handleBuy = (individualPackageDraftId: number) => {
        if (authUserData) {
            history.push(
                generatePath(SUBSCRIPTION_PAYMENT_PAGE, {
                    individualPackageDraftId,
                }),
            );
        } else {
            history.push({
                pathname: LOGIN_PAGE,
                search:
                    '?returnUrl=' +
                    encodeURIComponent(history.location.pathname),
            });
        }
    };

    return (
        <Grid container className={classes.container}>
            <Grid item>
                <Typography variant="h1" className={classes.title}>
                    {t('individualPackagesComparison')}
                </Typography>
                <Typography className={classes.subscriptionsDescription}>
                    {t('individualPackagesDescription')}
                </Typography>
            </Grid>
            <Grid item container justifyContent="center" spacing={2}>
                {bundles.map(bundle => (
                    <Grid item key={bundle.id}>
                        <IndividualPackage
                            id={bundle.id}
                            title={t(bundle.title)}
                            image={bundle.image}
                            description={t(bundle.description)}
                            onClick={onSelectBundle}
                            selected={selectedBundleId === bundle.id}
                            withMoreButton={!isDesktop}
                        />
                        {selectedBundleId === bundle.id && !isDesktop && (
                            <IndividualPackagesTableMobile onBuy={handleBuy} />
                        )}
                    </Grid>
                ))}
            </Grid>
            <Grid item container direction="column">
                {isDesktop && (
                    <>
                        <IndividualPackagesTable onBuy={handleBuy} />
                    </>
                )}
                <Box className={classes.tableCaption}>{t('tableCaption2')}</Box>
            </Grid>
        </Grid>
    );
};

export default ExpandedIndividualPackages;
