import React, {useEffect} from 'react';
import VideoParticipant from '../ParticipantVideo/ParticipantVideo';
import {Room} from 'twilio-video';
import useStyles from './VideoRoomStyles';
import {AccessTime} from '@material-ui/icons';
import {CustomModal} from '../../../';
import {Box} from '@material-ui/core';
import {
    PARTICIPANT_HUNG_UP,
    WAITING_FOR_CONNECTION,
} from '../../../../const/conversation';
import clsx from 'clsx';
import ConversationTopBar from '../../topBar/ConversationTopBar';
import Settings from '../../settings/Settings';
import {MuteIcon} from '../../../../assets';
import ChatConversation from '../../chat/ChatConversation/ChatConversation';
import AttachmentConversation from '../../chat/AttachmentConversation/AttachmentsConversation';
import {CustomInputChangeEvent} from '../../../../types/common';
import {Conversation} from '@twilio/conversations/lib/conversation';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import useChatEventListener from '../../../../hooks/conversation/useChatEventListener';

interface Props {
    showSettings: boolean;
    handleSelectVideoInput: (e: CustomInputChangeEvent) => void;
    setShowSettings: (value: boolean) => void;
    onUpdate: () => void;
    handleSelectAudioInput: (e: CustomInputChangeEvent) => void;
    handleSelectAudioOutput: (e: CustomInputChangeEvent) => void;
    handleHangout: () => void;
    handleVideoTurnOnOff: () => void;
    handleAudioTurnOnOff: () => void;
    setChatOpen: (chatOpen: boolean) => void;
    chatOpen?: boolean;
    setUploadFileOpen: (uploadFileOpen: boolean) => void;
    uploadFileOpen?: boolean;
    videoInputOn?: boolean;
    audioInputOn?: boolean;
    room: Room;
    roomState: string;
    remoteParticipants: JSX.Element[];
    audioInputDevices: MediaDeviceInfo[];
    videoInputDevices: MediaDeviceInfo[];
    loading?: boolean;
    conversation: Conversation;
    error?: string;
    setError: (error: string) => void;
}

const VideoRoom = ({
    showSettings,
    handleSelectVideoInput,
    setShowSettings,
    onUpdate,
    handleSelectAudioInput,
    handleSelectAudioOutput,
    handleHangout,
    handleAudioTurnOnOff,
    handleVideoTurnOnOff,
    setChatOpen,
    setUploadFileOpen,
    uploadFileOpen,
    chatOpen,
    videoInputOn,
    audioInputOn,
    room,
    roomState,
    remoteParticipants,
    audioInputDevices,
    videoInputDevices,
    conversation,
    loading,
    error,
    setError,
}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.CONVERSATIONS);

    const {messageCounter, resetCounter} = useChatEventListener({
        conversation,
        chatOpen,
    });

    useEffect(() => {
        if (chatOpen) resetCounter();
    }, [chatOpen]);

    useEffect(() => {
        messageCounter > 0 && setChatOpen(true);
    }, [messageCounter]);

    return (
        <div>
            <ConversationTopBar
                handleSettings={() => setShowSettings(true)}
                handleSwitchChat={() => setChatOpen(!chatOpen)}
                handleAttachFile={() => setUploadFileOpen(!uploadFileOpen)}
                attachFileOpen={uploadFileOpen}
                chatOpen={chatOpen}
                handleSwitchVideo={handleVideoTurnOnOff}
                handleSwitchMic={handleAudioTurnOnOff}
                handleHangout={handleHangout}
                videoInputOn={videoInputOn}
                audioInputOn={audioInputOn}
                unseenMessages={messageCounter}
            />
            <Box className={classes.container}>
                <Box className={classes.videoCallWrapper}>
                    {!audioInputOn && (
                        <img
                            src={MuteIcon}
                            alt="mute"
                            aria-label="mute"
                            className={classes.muteIcon}
                        />
                    )}

                    {/* Local participant */}
                    <VideoParticipant
                        participant={room.localParticipant}
                        className={clsx(classes.video, classes.mirrorImage)}
                    />

                    {roomState === WAITING_FOR_CONNECTION ||
                    roomState === PARTICIPANT_HUNG_UP ? (
                        <Box className={classes.statusScreen}>
                            {t('waitingForConnection')}
                            <AccessTime style={{marginTop: 26}} />
                        </Box>
                    ) : (
                        remoteParticipants
                    )}
                </Box>

                {chatOpen && (
                    <Box className={classes.chatWrapper}>
                        <ChatConversation
                            conversation={conversation}
                            loading={loading}
                            error={error}
                            setError={setError}
                        />
                    </Box>
                )}

                {uploadFileOpen && (
                    <Box className={classes.chatWrapper}>
                        <AttachmentConversation
                            uploadFileOpen={uploadFileOpen}
                            handleClose={() =>
                                setUploadFileOpen(!uploadFileOpen)
                            }
                            conversation={conversation}
                            error={error}
                            setError={setError}
                            loading={loading}
                        />
                    </Box>
                )}
            </Box>
            <CustomModal
                open={showSettings}
                onClose={() => setShowSettings(false)}
                onCloseButtonClick={() => setShowSettings(false)}
            >
                {audioInputDevices && videoInputDevices && (
                    <Settings
                        approve={onUpdate}
                        handleSelectAudioInput={handleSelectAudioInput}
                        handleSelectAudioOutput={handleSelectAudioOutput}
                        handleSelectVideoInput={handleSelectVideoInput}
                        handleVideoTurnOnOff={handleVideoTurnOnOff}
                        handleAudioTurnOnOff={handleAudioTurnOnOff}
                        inAppointment={true}
                    />
                )}
            </CustomModal>
        </div>
    );
};

export default VideoRoom;
