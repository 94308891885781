import {Select, SelectProps, Theme, withStyles} from '@material-ui/core';
import {CSSProperties, WithStylesOptions} from '@material-ui/styles';

const CustomSelect = withStyles<string, WithStylesOptions<Theme>, SelectProps>({
    root: props => {
        const rootStyles = {
            backgroundColor: 'white',
            borderRadius: 3,
            height: 48,
            display: 'flex',
            alignItems: 'center',
            padding: '0 17px',
        } as CSSProperties;

        if (props.variant !== 'outlined') {
            rootStyles.border = 0;
        }

        return rootStyles;
    },
})(Select);

export default CustomSelect;
