import {
    Box,
    createStyles,
    IconButton,
    Link,
    makeStyles,
    Theme,
} from '@material-ui/core';
import {ReactNode} from 'react';
import AppointmentPatientPurchaseOptionWrapper from '../AppointmentPatientPurchaseOptionWrapper/AppointmentPatientPurchaseOptionWrapper';
import {InfoOutlined} from '@material-ui/icons';
import {mobile} from '../../../../const/sizes';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {useTranslation} from 'react-i18next';

type AppointmentPatientPurchaseOptionProps = {
    id: string;
    label: ReactNode;
    price?: number;
    infoIcon?: ReactNode;
    limit?: number;
    hideLimit?: boolean;
};

const AppointmentPatientPurchaseOption = ({
    id,
    label,
    price,
    infoIcon,
    limit,
    hideLimit,
}: AppointmentPatientPurchaseOptionProps) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            label: {
                fontSize: '1rem',
            },
            icon: {
                marginLeft: 10,
            },
            limit: {
                color: theme.palette.secondary.main,
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
            },
            [theme.breakpoints.up('sm')]: {
                limit: {
                    display: 'inline',
                },
            },
        }),
    );

    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);

    return (
        <AppointmentPatientPurchaseOptionWrapper
            id={id}
            price={price}
            disable={limit <= 0}
        >
            <Box className={classes.label}>
                <Box display={'flex'}>
                    <Box display={'flex'} flexDirection={'column'}>
                        {label}
                        {limit >= 0 && !hideLimit && (
                            <>
                                <Box className={classes.limit}>
                                    {t('numberOfConsultations')}: {limit}{' '}
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box className={classes.icon}>{infoIcon}</Box>
                </Box>
            </Box>
        </AppointmentPatientPurchaseOptionWrapper>
    );
};

export default AppointmentPatientPurchaseOption;
