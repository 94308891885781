import useStyles from './OccupationalMedicineDetailsNotEditableStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Box, Checkbox, FormControlLabel, ListItem} from '@material-ui/core';
import {Appointment, Attachment} from '../../../../types/appointments';
import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {Loading, UploadedItem} from '../../../index';
import {
    selectOMCertificateAttachmentsMetaData,
    selectOMPreventiveExaminationCardAttachmentsMetaData,
} from '../../../../store/appointmentAttachmentsSlice';
import {getFileIcon} from '../../../../utils/file';
import {useDownloadAsync} from '../../../../hooks/appointmentFileUpload';

interface Props {
    appointment: Appointment;
}

const OccupationalMedicineDetailsNotEditable = ({appointment}: Props) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const classes = useStyles();
    const preventiveExaminationCardAttachments = useAppSelector(
        selectOMPreventiveExaminationCardAttachmentsMetaData,
    );
    const certificateAttachments = useAppSelector(
        selectOMCertificateAttachmentsMetaData,
    );
    const [receiptConfirmed, setReceiptConfirmed] = useState<boolean>(false);

    useEffect(() => {
        setReceiptConfirmed(
            certificateAttachments?.some(
                a => a.downloadDateTimeByPatient !== null,
            ),
        );
    }, [certificateAttachments]);

    const downloadFileAsync = useDownloadAsync();

    const mapAttachment = (attachment: Attachment) => ({
        fileItemKey: attachment.id,
        fileItemClickHandler: () =>
            downloadFileAsync(appointment.id, `${attachment.id}`),
        fileName: attachment.fileName,
        fileIcon: getFileIcon(attachment.contentType, attachment.thumbnail),
        fileOwner: attachment.person,
        fileDateCreated: attachment.uploadDate.toFormat("dd'.'MM'.'yyyy"),
        fileTimeCreated: attachment.uploadDate.toFormat("HH':'mm"),
        downloadDateTimeByPatient: attachment.downloadDateTimeByPatient,
    });

    return (
        <>
            <ul className={classes.listWrapper}>
                <li>
                    <Box className={classes.title}>
                        1. {t('preventiveExaminationCard')}
                    </Box>
                    {preventiveExaminationCardAttachments.length > 0 ? (
                        preventiveExaminationCardAttachments
                            .map(mapAttachment)
                            .map((fileProps, i) => (
                                <ListItem
                                    key={fileProps.fileItemKey ?? i}
                                    onClick={fileProps.fileItemClickHandler}
                                >
                                    <UploadedItem
                                        fileName={fileProps.fileName}
                                        fileIcon={fileProps.fileIcon}
                                        fileOwner={fileProps.fileOwner}
                                        fileDateCreated={
                                            fileProps.fileDateCreated
                                        }
                                        fileTimeCreated={
                                            fileProps.fileTimeCreated
                                        }
                                        removeFileEnabled={false}
                                        patientDownloadDate={
                                            fileProps.downloadDateTimeByPatient
                                        }
                                    />
                                </ListItem>
                            ))
                    ) : (
                        <Box style={{marginTop: 8}}>{t('noData')}</Box>
                    )}
                </li>
                <li>
                    <Box className={classes.title}>2. {t('decision')}</Box>
                    {certificateAttachments.length > 0 && (
                        <FormControlLabel
                            style={{marginTop: 18, marginBottom: -16}}
                            disabled={receiptConfirmed}
                            control={
                                <Checkbox
                                    id={'confirmationOfReceipt'}
                                    name={'confirmationOfReceipt'}
                                    checked={receiptConfirmed}
                                    onChange={e =>
                                        setReceiptConfirmed(e.target.checked)
                                    }
                                    style={{
                                        marginLeft: 20,
                                    }}
                                />
                            }
                            label={
                                <Box style={{fontSize: 16, marginTop: 1}}>
                                    {t('confirmReciptOfDecision')} *
                                </Box>
                            }
                        />
                    )}
                    {certificateAttachments.length > 0 ? (
                        certificateAttachments
                            .map(mapAttachment)
                            .map((fileProps, i) => (
                                <ListItem
                                    key={fileProps.fileItemKey ?? i}
                                    onClick={
                                        receiptConfirmed
                                            ? fileProps.fileItemClickHandler
                                            : null
                                    }
                                >
                                    <UploadedItem
                                        fileName={fileProps.fileName}
                                        fileIcon={fileProps.fileIcon}
                                        fileOwner={fileProps.fileOwner}
                                        fileDateCreated={
                                            fileProps.fileDateCreated
                                        }
                                        fileTimeCreated={
                                            fileProps.fileTimeCreated
                                        }
                                        removeFileEnabled={false}
                                        patientDownloadDate={
                                            fileProps.downloadDateTimeByPatient
                                        }
                                        disabled={!receiptConfirmed}
                                    />
                                </ListItem>
                            ))
                    ) : (
                        <Box style={{marginTop: 8}}>{t('noData')}</Box>
                    )}
                </li>
            </ul>
            <Loading loading={loading} withBackdrop />
        </>
    );
};

export default OccupationalMedicineDetailsNotEditable;
