import {
    Box,
    createStyles,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import MedicCard from '../MedicCard/MedicCard';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {selectMedics} from '../../../../store/referrals/makePatientReferralSlice';
import {CardList, CardListItem} from '../../../index';
import {WHITE} from '../../../../const/colors';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: WHITE,
        },
    }),
);

const MedicCardList = () => {
    const medics = useAppSelector(selectMedics);
    const classes = useStyles();
    const theme = useTheme();
    const isMediumScreenUp = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Box className={classes.root}>
            <CardList mdDownTopOffset={7}>
                {medics?.map(medic => (
                    <CardListItem
                        key={medic.id}
                        itemDisableGutters={isMediumScreenUp}
                    >
                        <MedicCard medic={medic} />
                    </CardListItem>
                ))}
            </CardList>
        </Box>
    );
};

export default MedicCardList;
