import { Avatar, Box, Card, Grid, Link, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./DiagnosticOrderItemStyles";
import { useAppSelector } from "../../../../hooks/customReduxHooks";
import { selectOrderedDiagnosticOrderHeaderWithResultById } from "../../../../store/referrals/orderedDiagnosticOrderHeaderWithResultsSlice";
import { LARGE_DESKTOP_BREAKPOINT } from "../../../../const/sizes";
import AppointmentTimeDetails from "../../../common/Appointment/AppointmentTimeDetails/AppointmentTimeDetails";
import { MedicalTestIcon, PdfImage } from "../../../../assets";
import { isNotNullOrUndefined } from "../../../../utils/lang";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import { DateTime } from "luxon";
import { ArrowForwardIos } from "@material-ui/icons";
import CalendarLink from "../../../appointmentsCalendar/AppointmentLink/CalendarLink";
import { PATIENT } from "../../../../const/auth";

type Props = {
    diagnosticServiceOrderId: string;
};

const DiagnosticOrderItem = ({diagnosticServiceOrderId}:Props) =>{
    const classes = useStyles();
    const theme = useTheme();

    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(LARGE_DESKTOP_BREAKPOINT);
    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);

    const orderedDiagnosticOrderHeader = useAppSelector(state =>
        selectOrderedDiagnosticOrderHeaderWithResultById(state, diagnosticServiceOrderId),
    );

    const openResults = (url:string)=> {
        const win = window.open(
            url,
            '_blank',
        );
        win.focus();
    }
    return (
        <Card
            style={{
                padding: smUp ? '24px 0' : 16,
                borderBottom: `1px solid ${theme.palette.grey['300']}`,
                boxShadow: 'none',
            }}
        >
            <Grid
                container
                wrap={smUp ? 'nowrap' : 'wrap'}
                alignItems="center"
                spacing={lgUp ? 2 : 1}
                style={{
                    cursor: !mdUp ? 'pointer' : 'default',
                }}
            >
                <Grid item xs={12} sm="auto">
                    <AppointmentTimeDetails
                        date={ DateTime.fromISO(orderedDiagnosticOrderHeader.createDate)}
                        isNow={false}
                        isInTheFuture={false}
                        square={smUp}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Grid
                        container
                        wrap={'nowrap'}
                        alignItems="center"
                        spacing={lgUp ? 2 : 1}
                    >
                        <Grid item xs="auto">
                            <Avatar
                                alt={'photo'}
                                src={MedicalTestIcon}
                                className={classes.photo}
                            />
                        </Grid>
                        <Grid item xs>
                        <Grid
                            container
                            direction="column"
                            style={{
                                overflowWrap: 'anywhere',
                            }}
                        >
                            <Box className={classes.type}>
                                { isNotNullOrUndefined(orderedDiagnosticOrderHeader.medicalPackage)
                                    ? orderedDiagnosticOrderHeader.medicalPackage 
                                    : t(orderedDiagnosticOrderHeader.type)}
                            </Box>
                            <Box className={classes.text}>
                                {orderedDiagnosticOrderHeader.diagnosticServices.map(d => d.name).join(', ')}
                            </Box>     
                            <Box className={classes.text}>
                                {orderedDiagnosticOrderHeader.medicName}
                            </Box>
                            <Box className={classes.text}>
                                {orderedDiagnosticOrderHeader.medicalServiceName}
                            </Box>
                            
                        </Grid>
                        </Grid>

                    {mdUp &&
                        <Grid item xs="auto">
                            <Grid container direction="column">
                                {orderedDiagnosticOrderHeader.resultStorageUrls.length>0 ?
                                orderedDiagnosticOrderHeader.resultStorageUrls.map((item,i) =>(
                                    <Link key={item.storageId}                               
                                        className={classes.link}
                                        onClick={() =>openResults(item.url)}
                                        >
                                        {orderedDiagnosticOrderHeader.resultStorageUrls.length==1 ? t('seeTheResults') : `${t('seeTheResults')} ${t('part')} ${i+1}` }
                                        
                                    </Link>
                                )) :
                                <Box className={classes.status}>
                                    {t(orderedDiagnosticOrderHeader.diagnosticOrderResultStatus)}
                                </Box>
                                }
                            </Grid> 
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {!mdUp &&
            <Grid container direction="row">
                {orderedDiagnosticOrderHeader.resultStorageUrls.length>0 &&
                orderedDiagnosticOrderHeader.resultStorageUrls.map((item,i) =>(
                    <Link key={item.storageId}                               
                        className={classes.link}
                        onClick={() =>openResults(item.url)}
                        >
                        {orderedDiagnosticOrderHeader.resultStorageUrls.length==1 ? t('seeTheResults') : `${t('seeTheResults')} ${t('part')} ${i+1}` }
                        
                    </Link>
            ))
            }
        </Grid>}
        </Card>
    )
}
export default DiagnosticOrderItem