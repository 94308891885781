import { Box } from "@material-ui/core"
import useStyles from "./MedicalTestResultsStyles";
import PatientSelector from "../patientSelector/PatientSelector";
import FinishedMedicalTests from "./finishedMedicalTests/FinishedMedicalTests";


const MedicalTestResults = () => {
    const classes = useStyles();
    return  (
        <Box className={classes.container}>
            <PatientSelector />
            <FinishedMedicalTests></FinishedMedicalTests>
        </Box>
        );
}

export default MedicalTestResults

