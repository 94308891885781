import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '25px 0',
        },
        iconContainer:{
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'center'
        },
        titleContainer:{
            alignItems:"center",
            fontSize:'16px',
            fontWeight:'bold'

        },
        text:{
            fontWeight:'normal'
        }
    }),
);

export default useStyles;
