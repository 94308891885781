import {matchPath, useLocation} from 'react-router';
import {
    EMAIL_VERIFICATION_PAGE,
    TIME_SLOTS_PAGE,
    TIME_SLOTS_SEARCH_PAGE,
    LOGIN_PAGE,
    MEDIC_CALENDAR_DATE_SELECTION,
    PHONE_VERIFICATION_PAGE,
    REGISTER_PAGE,
    ERROR,
    EMPTY_TIME_SLOTS_PAGE,
    INDIVIDUAL_PACKAGES_PAGE,
    APPOINTMENT_PAYMENT_CONTINUE,
    IDENTITY_CONFIRMATION_NEEDED_PAGE,
    REGISTER_MOJE_ID_PAGE,
    FORGOT_PASSWORD_PAGE,
    PASSWORD_CHANGE_FINISH_PAGE,
    CHANGE_PASSWORD_PAGE,
    ERROR_404_PAGE,
    CHANGE_PHONE_NUMBER_PAGE,
    PHONE_NUMBER_CHANGED_PAGE,
    MEDICAL_TEST_RESULT_PAGE,
    FORCED_PASSWORD_CHANGE_PAGE,
    CHANGE_PHONE_NUMBER_VERIFICATION_PAGE,
    HELP_PAGE,
    ARTICLES_PAGE,
    ARTICLE_PAGE,
    NO_INTERNET_ERROR,
    APPOINTMENT_SEARCH_WIDGET_PAGE,
    SUBSCRIPTION_PAYMENT_FAILED_PAGE,
    PRESCRIPTION_ISSUED_PAGE,
    CHANGE_ADDRESS_PAGE,
    DECLARATION_OF_PUBLIC_INSURANCE_PAGE,
    VOUCHER_PAGE,
    CONFIRMED_IDENTITY,
    SUBSCRIPTION_PAYMENT_CONTINUE,
    SICK_LEAVE_ISSUED_PAGE,
    SICK_LEAVES,
    OCCUPATIONAL_MEDICINE_PAGE_FILLED
} from '../const/routes';
import {alternativeTheme, defaultTheme} from '../utils/theme';

// Specify routes which should use white background
const alternativeThemeRoutes = [
    LOGIN_PAGE,
    TIME_SLOTS_SEARCH_PAGE,
    REGISTER_PAGE,
    TIME_SLOTS_PAGE,
    EMAIL_VERIFICATION_PAGE,
    PHONE_VERIFICATION_PAGE,
    FORCED_PASSWORD_CHANGE_PAGE,
    MEDIC_CALENDAR_DATE_SELECTION,
    ERROR,
    EMPTY_TIME_SLOTS_PAGE,
    INDIVIDUAL_PACKAGES_PAGE,
    APPOINTMENT_PAYMENT_CONTINUE,
    IDENTITY_CONFIRMATION_NEEDED_PAGE,
    REGISTER_MOJE_ID_PAGE,
    FORGOT_PASSWORD_PAGE,
    PASSWORD_CHANGE_FINISH_PAGE,
    CHANGE_PASSWORD_PAGE,
    ERROR_404_PAGE,
    CHANGE_PHONE_NUMBER_PAGE,
    CHANGE_PHONE_NUMBER_VERIFICATION_PAGE,
    PHONE_NUMBER_CHANGED_PAGE,
    MEDICAL_TEST_RESULT_PAGE,
    HELP_PAGE,
    ARTICLES_PAGE,
    ARTICLE_PAGE,
    NO_INTERNET_ERROR,
    APPOINTMENT_SEARCH_WIDGET_PAGE,
    SUBSCRIPTION_PAYMENT_FAILED_PAGE,
    PRESCRIPTION_ISSUED_PAGE,
    CHANGE_ADDRESS_PAGE,
    DECLARATION_OF_PUBLIC_INSURANCE_PAGE,
    CONFIRMED_IDENTITY,
    SUBSCRIPTION_PAYMENT_CONTINUE,
    SICK_LEAVES,
    OCCUPATIONAL_MEDICINE_PAGE_FILLED
];

const useThemeForRoute = () => {
    const location = useLocation();
    return alternativeThemeRoutes.some(path =>
        matchPath(location.pathname, {path, exact: true}),
    )
        ? alternativeTheme
        : defaultTheme;
};

export default useThemeForRoute;
