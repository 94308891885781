import { Box, Grid, Theme, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { MedicPackageDto } from "../../../types/diagnosticServicePackage";
import useStyles from "./MedicalPackagesStyles";
import MedicPackageProvider from '../../../services/diagnostic/medicPackageProvider';
import {CardList, CardListItem, TileList, TileListItem} from "../../../components/index";
import MedicalPackageCard from "./MedicalPackageCard";
import { useParams } from "react-router";
import useQuery from "../../../hooks/useQuery";

const MedicalPackages  = () => {
    const classes = useStyles();
    const [packages, setPackages] = useState<MedicPackageDto[]>([]);
    const isDesktopViewBreakPoint = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('md'),
    );

    const query = useQuery();
    const appointmentId = parseInt(query.get('appointmentId'));

    useEffect(() => {
        MedicPackageProvider
            .getMedicPackages(appointmentId)
            .then(setPackages)
    }, []);


    return(
        <Box
            className={classes.container}>
        {isDesktopViewBreakPoint ? 
            (<Box>
                <CardList>
                    {packages && packages.map(medicalPackage =>(
                        <CardListItem
                            key={medicalPackage.id}
                            itemDisableGutters={true}>
                                <MedicalPackageCard 
                                    medicalPackage = {medicalPackage}/>
                        </CardListItem>
                    ))}
                </CardList>    
            </Box>) :
            (
                <TileList>
                    {packages && packages.map(medicalPackage => (
                        <TileListItem key={medicalPackage.id}>
                            <MedicalPackageCard 
                                    medicalPackage = {medicalPackage}/>
                        </TileListItem>
                    ))}
                </TileList>
            )}

        </Box>
    )
}

export default MedicalPackages;