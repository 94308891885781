import {TranslationsDto} from '../types/translations';
import {isNotNullOrUndefined} from './lang';

export const getLanguage = (language: string) => {
    switch (language) {
        case 'pl':
        case 'pl-PL':
            return 'PL';
        case 'en':
        case 'en-EN':
            return 'EN';
        default:
            return 'EN';
    }
};

export const getTranslation = (
    defaultValue: string,
    translations: TranslationsDto[],
    currentLanguage: string,
    key: string,
) => {
    if (translations && translations.length !== 0) {
        const translation = translations.find(o => {
            return o.languageCode === currentLanguage && o.key === key;
        });

        if (
            isNotNullOrUndefined(translation) &&
            isNotNullOrUndefined(translation.value)
        )
            return translation.value;
    }

    return defaultValue;
};

export const defaultDatabaseLanguage = 'PL';
