import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { BACKGROUND_PRIMARY, BACKGROUND_SECONDARY, GRAY, LIGHT_GRAY, WHITE } from '../../../const/colors';
import { desktop, mobile } from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section:{
            backgroundColor: WHITE,
            margin: '25px',
            padding:'24px',
        },
        header:{
            fontSize:'24px',
            fontWeight: 700,
        },
        title:{
            fontSize:'16px',
            fontWeight: 400,
        },
        
    }),
);

export default useStyles;
