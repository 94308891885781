import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            margin: 0,
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 700,
            marginBottom: 10,
            [theme.breakpoints.up('md')]: {
                fontSize: '20px',
                lineHeight: '32px',
            },
        },
        sickLeavesList: {
            marginBottom: 20,
        },
        loadCertificateLink: {
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 'bold',
        },
    }),
);

export default useStyles;
