import {getLanguage} from '../utils/translation';
import i18n from '../i18n/config';
import configuration from '../configuration';
import {getLandingPath, Path} from '../utils/landingPaths';
import useQuery from './useQuery';
import {useEffect} from 'react';
import * as storage from '../storage/storage';

const useLandingPageUrl = () => {
    const currentLanguage = getLanguage(i18n?.language);
    const landingURL = configuration?.landingPageURL;
    const query = useQuery();

    useEffect(() => {
        if (query.has('origin')) {
            const origin = query.get('origin');
            if (!origin.includes('https://')) return;
            storage.setOriginUrl(origin);
        }
    }, []);

    const getCurrentUrl = () => {
        if (storage.getOriginUrl()) {
            return decodeURIComponent(storage.getOriginUrl());
        }
        if (currentLanguage === 'PL') return landingURL;
        return `${landingURL}/${currentLanguage.toLowerCase()}`;
    };

    const getRoute = (path: Path) => {
        return `${getCurrentUrl()}/${getLandingPath(currentLanguage, path)}`;
    };

    return {getCurrentUrl, getRoute};
};

export default useLandingPageUrl;
