import React, {useEffect, useState} from 'react';
import {Box, createStyles, Grid, makeStyles, Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useTranslation} from 'react-i18next';
import {v4} from 'uuid';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {
    ISSUE_PRESCRIPTION_CONFIGURATION_URL,
    ISSUE_SICK_LEAVE_CONFIGURATION_URL
} from '../../../../services/endpoints';
import {getDestinationLinkFromConfiguration} from '../../../../utils/getDestinationLinkFromConfiguration';
import {MAGENTA} from '../../../../const/colors';
import {
    PATIENT_REFERRAL_FOR_MEDICAL_TESTS_PAGE,
    PATIENT_REFERRAL_TO_MEDIC_PAGE
} from '../../../../const/routes';
import NewTabLink from "../../../common/navigation/NewTabLink";

interface Props {
    appointmentIdAsString: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        link: {
            display: 'block',
            margin: '18px 28px 0 0',
            fontSize: 16,
            lineHeight: '20px',
            width: 'auto',
            cursor: 'pointer',
            textAlign: 'left',
            textDecoration: 'none',
            color: MAGENTA,
        }
    }),
);

const NotesLinks = ({appointmentIdAsString}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const {t: tReferrals} = useTranslation(i18nNamespaces.REFERRALS);
    const [issuePrescriptionUrl, setIssuePrescriptionUrl] = useState<string>(null);
    const [issueSickLeaveUrl, setIssueSickLeaveUrl] = useState<string>(null);
    const [error, setError] = useState<string>(null);

    const links = [
        {
            href: `${PATIENT_REFERRAL_TO_MEDIC_PAGE}/?appointmentId=${appointmentIdAsString}`,
            text: tReferrals('makeReferralToSpecialist')
        },
        {
            href: `${PATIENT_REFERRAL_FOR_MEDICAL_TESTS_PAGE}/?appointmentId=${appointmentIdAsString}`,
            text: tReferrals('makeReferralForMedicalTests')
        },
        {
            href: issuePrescriptionUrl,
            text: `${t('issuePrescription')} (gabinet.gov.pl)`
        },
        {
            href: issuePrescriptionUrl,
            text: `${t('issueReferralToHospitalOrPictureDiagnosis')} (gabinet.gov.pl)`
        },
        {
            href: issueSickLeaveUrl,
            text: `${t('issueSickLeave')} (ZUS PUE)`
        },
    ];

    const fetchIssuePrescriptionLink = async () => {
        try {
            const urlFromConfiguration =
                await getDestinationLinkFromConfiguration(
                    ISSUE_PRESCRIPTION_CONFIGURATION_URL
                );
            setIssuePrescriptionUrl(urlFromConfiguration);
        } catch {
            setError(t('fetchingLinkForIssuingPrescriptionError'));
        }
    }

    const fetchIssueSickLeaveUrl = async () => {
        try {
            const urlFromConfiguration =
                await getDestinationLinkFromConfiguration(
                    ISSUE_SICK_LEAVE_CONFIGURATION_URL
                );
            setIssueSickLeaveUrl(urlFromConfiguration);
        } catch {
            setError(t('fetchingLinkForIssuingSickLeaveError'));
        }
    }

    useEffect(() => {
        fetchIssuePrescriptionLink();
        fetchIssueSickLeaveUrl();
    }, []);

    return (
        <Box>
            <Grid container>
                {
                    links.map(link => (
                        <Grid
                            item
                            alignItems={'center'}
                            xs={12}
                            sm={6}
                            key={v4()}
                        >
                            <NewTabLink
                                className={classes.link}
                                href={link.href || '#'}
                            >
                                {link.text}
                            </NewTabLink>
                        </Grid>
                    ))
                }
            </Grid>
            <Snackbar
                open={!!error}
                autoHideDuration={10000}
                onClose={() => setError(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={() => setError(null)} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default React.memo(NotesLinks);