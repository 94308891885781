import {
    CaseReducer,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {LoadingStatus} from '../const/loadingStatus';
import AppointmentsProvider from '../services/appointmentsProvider';
import {MedicalServiceDto, TimeSlot} from '../types/timeSlot';
import {RootState} from './store';
import mapTimeSlot from './mappers/mapTimeSlot';
import {MedicalServicePurchaseOptionsDto} from '../types/medicalServicePurchaseOptions';
import medicalServiceProvider from '../services/medicalServiceProvider';
import timeSlotsProvider from '../services/timeSlotsProvider';
import appointmentsProvider from '../services/appointmentsProvider';
import {TranslationsDto} from '../types/translations';
import {AppointmentGroupEnum} from '../types/appointments';

const sliceName = 'timeSlots';

const initialState = {
    isChildrenRequired: false as boolean,
    medicalServiceName: null as string,
    medicalServiceId: null as number,
    reservedSlotId: null as string,
    slotsInTimeWindow: null as boolean,
    appointments: [] as TimeSlot[],
    selectedTimeSlotId: null as string,
    purchaseOptions: null as MedicalServicePurchaseOptionsDto,
    translations: [] as TranslationsDto[],
    status: 'idle' as LoadingStatus,
    timeSlotsToken: null as string,
    appointmentGroup: null as AppointmentGroupEnum,
};

export type AppointmentSliceState = typeof initialState;

const medicalServiceIdSpecifiedReducer: CaseReducer<
    AppointmentSliceState,
    PayloadAction<number>
> = (state, action) => {
    state.medicalServiceId = action.payload;
};

const selectedAppointmentSpecifiedReducer: CaseReducer<
    AppointmentSliceState,
    PayloadAction<{appointmentId: string}>
> = (state, action) => {
    const {appointmentId} = action.payload;
    state.selectedTimeSlotId = appointmentId;
};

export const fetchAppointments = createAsyncThunk<
    {
        isChildrenRequired: boolean;
        reservedSlotId: string;
        appointments: TimeSlot[];
        slotsInTimeWindow: boolean;
        purchaseOptions: MedicalServicePurchaseOptionsDto;
        medicalService: MedicalServiceDto;
    },
    {
        medicalServiceId: number;
        appointmentId?: number;
        referralId?: number;
        occupationalMedicineReferralId?: number;
    },
    {state: RootState}
>(
    `${sliceName}/fetchAppointments`,
    async (
        {
            medicalServiceId,
            appointmentId,
            referralId,
            occupationalMedicineReferralId,
        },
        thunkAPI,
    ) => {
        const {timeSlotsToken} = thunkAPI.getState().timeSlots;
        const fetchAppointments =
            timeSlotsProvider.getTimeSlots(timeSlotsToken);

        const fetchMedicalServicePurchaseOptions = appointmentId
            ? appointmentsProvider.getAppointmentPurchaseOptions(appointmentId)
            : medicalServiceProvider.getMedicalServicePurchaseOptions(
                  medicalServiceId,
                  {referralId, occupationalMedicineReferralId},
              );

        const fetchMedicalService =
            medicalServiceProvider.getMedicalService(medicalServiceId);

        const [timeSlots, purchaseOptions, medicalService] = await Promise.all([
            fetchAppointments,
            fetchMedicalServicePurchaseOptions,
            fetchMedicalService,
        ]);

        return {
            isChildrenRequired: timeSlots.isChildrenRequired,
            reservedSlotId: timeSlots.reservedSlotId,
            appointments: timeSlots.timeSlots.map(mapTimeSlot),
            slotsInTimeWindow: timeSlots.slotsInTimeWindow,
            purchaseOptions,
            medicalService,
        };
    },
);

export const fetchPurchaseOptions = createAsyncThunk<
    {
        purchaseOptions: MedicalServicePurchaseOptionsDto;
    },
    {
        medicalServiceId: number;
        childId: number | null;
        referralId: number | null;
    },
    {state: RootState}
>(
    `${sliceName}/fetchPurchaseOptions`,
    async ({medicalServiceId, childId, referralId}, thunkAPI) => {
        const fetchMedicalServicePurchaseOptions =
            medicalServiceProvider.getMedicalServicePurchaseOptionsForChild(
                medicalServiceId,
                childId,
                referralId,
            );

        const [purchaseOptions] = await Promise.all([
            fetchMedicalServicePurchaseOptions,
        ]);

        return {
            purchaseOptions,
        };
    },
);

export const getTimeSlotsToken = createAsyncThunk<
    {
        timeSlotsToken: string;
    },
    {timeSlotId?: string; medicId?: string},
    {state: RootState}
>(
    `${sliceName}/getTimeSlotsToken`,
    async ({timeSlotId, medicId}, {getState}) => {
        const {medicalServiceId} = getState().timeSlots;
        const {startDateTime, endDateTime} = getState().timeSlotsSearch;

        const timeWindow = {
            startDate: startDateTime.toUTC().toISO(),
            endDate: endDateTime.toUTC().toISO(),
        };
        let timeSlotsToken;

        if (timeSlotId) {
            timeSlotsToken = await AppointmentsProvider.getAppointmentsToken({
                medicalService: medicalServiceId,
                timeWindow,
                timeSlotId,
            });
        } else {
            timeSlotsToken = await AppointmentsProvider.getAppointmentsToken({
                medicalService: medicalServiceId,
                timeWindow,
                medicId,
            });
        }

        return {
            timeSlotsToken: timeSlotsToken.data,
        };
    },
);

export const statusSpecifiedReducer: CaseReducer<
    AppointmentSliceState,
    PayloadAction<LoadingStatus>
> = (state, action) => {
    state.status = action.payload;
};

const timeSlotsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        medicalServiceIdSpecified: medicalServiceIdSpecifiedReducer,
        selectedAppointmentSpecified: selectedAppointmentSpecifiedReducer,
        timeSlotsStatusSpecified: statusSpecifiedReducer,
    },
    extraReducers(builder) {
        builder
            .addCase(getTimeSlotsToken.pending, state => {
                state.status = 'saving';
            })
            .addCase(getTimeSlotsToken.fulfilled, (state, action) => {
                state.timeSlotsToken = action.payload.timeSlotsToken;
                state.status = 'saved';
            })
            .addCase(getTimeSlotsToken.rejected, state => {
                state.status = 'failed';
            });
        builder
            .addCase(fetchAppointments.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchAppointments.fulfilled, (state, action) => {
                state.isChildrenRequired = action.payload.isChildrenRequired; // Deprecated: medicalService.appointmentGroup === 'PediatricAppointment' is used instead
                state.reservedSlotId = action.payload.reservedSlotId;
                state.appointments = action.payload.appointments;
                state.slotsInTimeWindow = action.payload.slotsInTimeWindow;
                state.selectedTimeSlotId =
                    action?.payload?.reservedSlotId ||
                    action.payload.appointments[0]?.id;
                state.purchaseOptions = action.payload.purchaseOptions;
                state.medicalServiceName = action.payload.medicalService.name;
                state.translations = action.payload.medicalService.translations;
                state.appointmentGroup =
                    action.payload.medicalService.appointmentGroup;
                state.status = 'loaded';
            })
            .addCase(fetchAppointments.rejected, state => {
                state.status = 'failed';
            });
        builder
            .addCase(fetchPurchaseOptions.fulfilled, (state, action) => {
                state.purchaseOptions = action.payload.purchaseOptions;
                state.status = 'loaded';
            })
            .addCase(fetchPurchaseOptions.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const actions = timeSlotsSlice.actions;

export const selectTimeSlotsAppointments = (state: RootState) =>
    state.timeSlots.appointments;
export const selecttimeSlotsStatus = (state: RootState) =>
    state.timeSlots.status;
export const selectSlotsInTimeWindow = (state: RootState) =>
    state.timeSlots.slotsInTimeWindow;
export const selectselectedTimeSlotId = (state: RootState) =>
    state.timeSlots.selectedTimeSlotId;
export const selectSelectedAppointment = (state: RootState) =>
    state.timeSlots.appointments.find(
        appointment => appointment?.id === state.timeSlots.selectedTimeSlotId,
    );
export const selecttimeSlotsToken = (state: RootState) =>
    state.timeSlots.timeSlotsToken;
export const selectAppointmentPurchaseOptions = (state: RootState) =>
    state.timeSlots.purchaseOptions;
export const selectMedicalServiceId = (state: RootState) =>
    state.timeSlots.medicalServiceId;
export const selectMedicalServiceName = (state: RootState) =>
    state.timeSlots.medicalServiceName;
export const selectMedicalServiceTranslations = (state: RootState) =>
    state.timeSlots.translations;
export const selectReservedSlotId = (state: RootState) =>
    state.timeSlots.reservedSlotId;
export const selectIsChildrenRequired = (state: RootState) =>
    state.timeSlots.isChildrenRequired;
export const selectAppointmentGroup = (state: RootState) =>
    state.timeSlots.appointmentGroup;

export const {
    selectedAppointmentSpecified,
    medicalServiceIdSpecified,
    timeSlotsStatusSpecified,
} = timeSlotsSlice.actions;

export default timeSlotsSlice.reducer;
