import {Chip, ChipProps, makeStyles} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import clsx from 'clsx';
import {BACKGROUND_SECONDARY, BLACK, TEXT_PRIMARY} from '../../../const/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '8px 12px',
        fontSize: 16,
        lineHeight: '24px',
        borderRadius: 8,
        color: BLACK,
        backgroundColor: BACKGROUND_SECONDARY,
    },
    deleteIcon: {
        color: TEXT_PRIMARY,
        [theme.breakpoints.up('md')]: {
            width: 20,
            height: 20,
        }
    },
}));

const CustomChip = ({className,  ...props}: ChipProps) => {
    const classes = useStyles();
    return (
        <Chip
            className={
                clsx(classes.root, className)
            }
            deleteIcon={
                <Cancel className={classes.deleteIcon} />
            }
            {...props}
        />
    );
}

export default CustomChip;