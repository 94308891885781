import React, {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useCallback,
    useState,
} from 'react';
import {CustomAlert} from '../components/common/feedback';
import {CustomAlertProps} from '../types/alert';

type AlertState = Omit<CustomAlertProps, 'onClose' | 'disableAutoHide'>;
type AlertContextState = Dispatch<SetStateAction<AlertState>>;

export const AlertContext = createContext<AlertContextState>(null);

const alertInitValue: AlertState = {
    severity: null,
    message: null,
    duration: null,
};

interface AlertContextProviderProps {
    children: ReactNode;
}

export const AlertContextProvider: FC<AlertContextProviderProps> = props => {
    const {children} = props;

    const [alert, setAlert] = useState<AlertState>(alertInitValue);
    // const [open, setOpen] = useState<boolean>(false);

    const onClose = useCallback(() => setAlert(alertInitValue), []);

    return (
        <AlertContext.Provider value={setAlert}>
            {children}
            <CustomAlert
                {...alert}
                disableAutoHide={false}
                open={!!alert}
                onClose={onClose}
            />
        </AlertContext.Provider>
    );
};
