import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorWrapper: {
            height: '100%',
            marginTop: '16px',
        },
        errorTitle: {
            fontSize: '36px',
            fontWeight: 'bold',
        },
        errorContent: {
            fontSize: '1rem',
            marginTop: '40px',
            marginBottom: '40px',
        },
        errorImage: {
            width: '100%',
            marginBottom: '40px',
        },
        refreshButton:{
            marginTop:'40px',
            marginBottom:'40px',
            width:'100%'
        },
        [theme.breakpoints.up('sm')]: {
            errorWrapper: {
                marginTop: '88px',
            },
            errorContent: {
                fontSize: '1rem',
                marginTop: '32px',
                marginBottom: '32px',
            },
            errorImage: {
                width: '500px',
                marginBottom: 0,
            },
            refreshButton:{
                marginTop:'40px',
                marginBottom:'40px',
                width:'200px'
            },
        },
    }),
);

export default useStyles;
