import React from 'react';
import {Box, createStyles, Grid, makeStyles} from '@material-ui/core';
import {CustomButton, CustomModal} from '../../index';

interface NoSlotsInTimeWindowModalProps {
    showModal: boolean;
    label: string;
    buttonLabel?: string;
    onButtonClick?: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        modalContent: {
            maxWidth: 360,
        },
        title: {
            fontWeight: 'bold',
            textAlign: 'center',
        },
    }),
);

const NoSlotsInTimeWindowModal = ({
    label,
    buttonLabel,
    onButtonClick,
    showModal,
}: NoSlotsInTimeWindowModalProps) => {
    const classes = useStyles();
    return (
        <CustomModal open={showModal} style={{width: '100vw'}}>
            <Grid
                container
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                className={classes.modalContent}
            >
                <Box className={classes.title}>{label}</Box>
                <CustomButton
                    variant={'contained'}
                    color={'secondary'}
                    style={{marginTop: 14}}
                    onClick={onButtonClick}
                    fullWidth
                >
                    {buttonLabel || 'Zobacz inne proponowane terminy'}
                </CustomButton>
            </Grid>
        </CustomModal>
    );
};

export default NoSlotsInTimeWindowModal;
