import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../../const/sizes';
import {TEXT_SECONDARY} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        medicDataWrapper: {
            backgroundColor: 'white',
        },
        title: {
            fontSize: 36,
            marginBottom: 30,
            fontWeight: 'bold',
            marginLeft: 18,
            marginTop: 10,
        },
        subtitle: {
            marginLeft: 18,
            marginBottom: 30,
        },
        medicName: {
            fontSize: 16,
            fontWeight: 'bold',
        },
        medicMedicalService: {
            marginBottom: 7,
        },
        medicPhoto: {
            width: 70,
            height: 70,
            marginRight: 16,
        },
        secondaryText: {
            fontSize: 14,
            color: TEXT_SECONDARY,
        },
        dateTimeWrapper: {
            paddingTop: 8,
        },
        [theme.breakpoints.up('md')]: {
            medicDataWrapper: {
                height: 'calc(100vh - 128px)',
                padding: 16,
            },
            title: {
                fontSize: 56,
                marginBottom: 30,
                marginLeft: 28,
            },
            subtitle: {
                marginLeft: 32,
            },
            medicPhoto: {
                width: theme.spacing(10),
                height: theme.spacing(10),
            },
            medicName: {
                fontSize: 24,
                marginBottom: 7,
            },
            dateTimeWrapper: {
                padding: `24px ${desktop.contentLargerSpacing} 24px 24px`,
            },
        },
    }),
);

export default useStyles;
