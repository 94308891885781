import {
    AppBar,
    Box,
    Hidden,
    Theme,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
} from '@material-ui/core';
import React, {ReactNode} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router';
import {HELP_PAGE, USER_ACCOUNT_PAGE} from '../../../const/routes';
import {clearToken} from '../../../storage/storage';
import BigMenu from './BigMenu/BigMenu';
import SmallMenu from './SmallMenu/SmallMenu';
import useStyles from './TopBarStyles';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {selectAuthUserData, signOut} from '../../../store/auth';
import AppLogo from '../AppLogo/AppLogo';
import clsx from 'clsx';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {MEDIC} from '../../../const/auth';
import useQuery from '../../../hooks/useQuery';

interface Elevation {
    window?: () => Window;
    children: React.ReactElement;
}

interface Props extends RouteComponentProps {
    window?: () => Window;
    children?: ReactNode;
    className?: string;
    welcomeDisabled?: boolean;
}

const ElevationScroll = ({window, children}: Elevation) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
};

const TopBar = ({window, children, className, welcomeDisabled}: Props) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {i18n, t} = useTranslation(i18nNamespaces.COMMON);
    const authUserData = useAppSelector(selectAuthUserData);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
    const isMedic = authUserData?.role === MEDIC;
    const welcomeMessage = `${t('welcome')}, ${
        authUserData?.displayName.split(' ')[0]
    }!`;
    const query = useQuery();

    const removeParamFromQuery = (param: string) => {
        if (query.has(param)) query.delete(param);
    };

    const clearQueryParams = () => {
        removeParamFromQuery('occupationalMedicineReferralId');
        removeParamFromQuery('appointmentId');
        removeParamFromQuery('referralId');
        removeParamFromQuery('medicId');
        removeParamFromQuery('timeSlotId');
        history.replace({search: query.toString()});
    };

    const onClickAccount = () => {
        history.push(USER_ACCOUNT_PAGE);
    };

    const onClickLogout = () => {
        dispatch(signOut());
        clearToken();
        clearQueryParams();
    };

    const onChangeLanguage = (event: React.ChangeEvent<any>) => {
        if (query.has('lang')) {
            query.set('lang', event?.target?.value);
        } else {
            query.append('lang', event?.target?.value);
        }
        history.replace({search: query.toString()});
    };

    const onClickHelp = () => {
        history.push(HELP_PAGE);
    };

    return (
        <Box className={classes.root}>
            <ElevationScroll window={window}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, className)}
                >
                    <Toolbar className={classes.content}>
                        <Box display="flex" alignItems="center">
                            <AppLogo />
                            {!welcomeDisabled && isDesktop && (
                                <Box className={classes.displayName}>
                                    {authUserData &&
                                        isDesktop &&
                                        (isMedic
                                            ? t('doctorPanel') +
                                              '・' +
                                              welcomeMessage
                                            : welcomeMessage)}
                                </Box>
                            )}
                        </Box>
                        {children || (
                            <>
                                <Hidden smDown>
                                    <BigMenu
                                        onClickAccount={onClickAccount}
                                        onClickLogout={onClickLogout}
                                        onChangeLanguage={onChangeLanguage}
                                        onClickHelp={onClickHelp}
                                    />
                                </Hidden>
                                <Hidden mdUp>
                                    <SmallMenu
                                        onClickAccount={onClickAccount}
                                        onClickLogout={onClickLogout}
                                        onChangeLanguage={onChangeLanguage}
                                    />
                                </Hidden>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar />
        </Box>
    );
};

export default withRouter(TopBar);
