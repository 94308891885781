import {
    AppointmentCreationResultDto,
    AppointmentDto,
    NewAppointmentDto,
    AttachmentDto,
    UploadAttachmentData,
    Attachment,
    QuestionnaireDto,
    QuestionnaireDetails,
    AppointmentChangingData,
    AppointmentDetailsForPrescriptionDto,
} from '../types/appointments';
import {PaginatedResults} from '../types/common';
import {InitializeDto, TimeWindowDates} from '../types/timeSlot';
import {
    APPOINTMENTS,
    TIME_SLOTS_REQUESTS,
    INITIALIZE,
    TIME_SLOT_APPOINTMENTS,
    ADDITIONAL_PATIENT_APPOINTMENT_DETAILS,
    ADDITIONAL_MEDIC_APPOINTMENT_DETAILS,
    GET_ATTACHMENTS_META_DATA_WITHOUT_PRESCRIPTIONS,
    GET_PRESCRIPTIONS_META_DATA,
    UPLOAD_APPOINTMENT_ATTACHMENTS,
    APPOINTMENT_QUESTIONNAIRE,
    REMOVE_DOWNLOAD_APPOINTMENT_ATTACHMENT,
    GET_QUESTIONNAIRE_DETAILS,
    GET_ALL_ATTACHMENTS_META_DATA,
    CANCEL_APPOINTMENT,
    APPOINTMENT_PURCHASE_OPTIONS,
    PAY_AGAIN,
    REMOVE_APPOINTMENT,
    getAppointmentDetailsForPrescriptionEndpoint,
} from './endpoints';
import {http, rawHttp} from './http';

interface GetUserHistoricalAppointmentsProps {
    page: number;
    pageSize: number;
    userId: string;
    childId?: number;
    appointmentId: string;
    medicalServiceId?: number;
}

class AppointmentsProvider {
    async getInitialization() {
        return (await http.get<InitializeDto>(INITIALIZE)).data;
    }

    async getFinishedAppointments(page = 0, size = 10) {
        return (
            await http.get<PaginatedResults<AppointmentDto>>(APPOINTMENTS, {
                params: {
                    page,
                    size,
                    historical: true,
                    withStatus: true,
                    order: 1,
                },
            })
        ).data;
    }

    async getUpcomingAppointments() {
        return (
            await http.get<PaginatedResults<AppointmentDto>>(APPOINTMENTS, {
                params: {
                    historical: false,
                    withStatus: true,
                },
            })
        ).data.results;
    }

    getAppointmentsToken(payload: {
        medicalService: number;
        timeWindow: TimeWindowDates;
        timeSlotId?: string;
        medicId?: string;
    }) {
        return rawHttp.post<string>(TIME_SLOTS_REQUESTS, payload);
    }

    async getPageOfUserHistoricalAppointments(
        props: GetUserHistoricalAppointmentsProps,
    ) {
        const {pageSize, ...otherProps} = props;

        return (
            await http.get<{
                count: number;
                page: number;
                results: AppointmentDto[];
            }>(APPOINTMENTS, {
                params: {
                    historical: true,
                    order: 'descending',
                    ...otherProps,
                    size: pageSize,
                },
            })
        ).data;
    }

    createAppointment(timeSlotId: string, newAppointment: NewAppointmentDto) {
        return rawHttp.post<AppointmentCreationResultDto>(
            TIME_SLOT_APPOINTMENTS(timeSlotId),
            newAppointment,
        );
    }

    async payAgain(appointmentId: number) {
        return (
            await http.put<AppointmentCreationResultDto>(
                PAY_AGAIN(appointmentId),
            )
        ).data;
    }

    async getAppointment(appointmentId: string, raw = true) {
        const axiosInstance = raw ? rawHttp : http;
        return (
            await axiosInstance.get<AppointmentDto>(
                APPOINTMENTS + `/${appointmentId}`,
            )
        ).data;
    }

    async getAppointmentAxios(appointmentId: string) {
        return await http.get<AppointmentDto>(
            APPOINTMENTS + `/${appointmentId}`,
        );
    }

    async getAppointmentChangingData(appointmentId: number) {
        return await http.get<AppointmentChangingData>(
            `${APPOINTMENTS}/${appointmentId}/status`,
        );
    }

    async cancelAppointment(appointmentId: string) {
        return await http.put<string>(CANCEL_APPOINTMENT(appointmentId));
    }

    async removeAppointment(appointmentId: string) {
        return await http.put<string>(REMOVE_APPOINTMENT(appointmentId));
    }

    async setPatientAppointmentAdditionalDetails(
        appointmentId: string,
        patientReasons: string,
    ) {
        return http.put(ADDITIONAL_PATIENT_APPOINTMENT_DETAILS(appointmentId), {
            patientReasons,
        });
    }

    setMedicAppointmentAdditionalDetails(
        appointmentId: string,
        additionalDetails: {
            drugMedicalRecommendations: string;
            referralMedicalRecommendations: string;
            otherMedicalRecommendations: string;
            medicalInterview: string;
        },
    ) {
        return http.put(
            ADDITIONAL_MEDIC_APPOINTMENT_DETAILS(appointmentId),
            additionalDetails,
        );
    }

    async getAllAttachmentsMetaData(
        appointmentId: string,
    ): Promise<AttachmentDto[]> {
        return (await http.get(GET_ALL_ATTACHMENTS_META_DATA(appointmentId)))
            .data;
    }

    async getAttachmentsMetaDataWithoutPrescriptions(
        appointmentId: string,
    ): Promise<AttachmentDto[]> {
        return (
            await http.get(
                GET_ATTACHMENTS_META_DATA_WITHOUT_PRESCRIPTIONS(appointmentId),
            )
        ).data;
    }

    async getPrescriptionsMetaData(
        appointmentId: string,
    ): Promise<AttachmentDto[]> {
        return (await http.get(GET_PRESCRIPTIONS_META_DATA(appointmentId)))
            .data;
    }

    async uploadAttachments(appointmentId: string, data: UploadAttachmentData) {
        return http.post(UPLOAD_APPOINTMENT_ATTACHMENTS(appointmentId), data);
    }

    async removeAttachment(appointmentId: string, fileId: string) {
        return http.delete(
            REMOVE_DOWNLOAD_APPOINTMENT_ATTACHMENT(appointmentId, fileId),
        );
    }

    async downloadAttachment(
        appointmentId: string,
        fileId: string,
    ): Promise<Attachment> {
        return (
            await http.get<Attachment>(
                REMOVE_DOWNLOAD_APPOINTMENT_ATTACHMENT(appointmentId, fileId),
            )
        ).data;
    }

    async getMedicalServices() {
        return (await http.get<InitializeDto>(INITIALIZE)).data.medicalServices;
    }

    async getQuestionnaire(appointmentId: string) {
        return (
            await http.get<QuestionnaireDto>(
                APPOINTMENT_QUESTIONNAIRE(appointmentId),
            )
        ).data;
    }

    async addQuestionnaireAnswers(
        appointmentId: string,
        data: QuestionnaireDto,
    ) {
        return http.post(APPOINTMENT_QUESTIONNAIRE(appointmentId), data);
    }

    async getQuestionnaireDetails(
        appointmentId: string,
    ): Promise<QuestionnaireDetails> {
        return (await http.get(GET_QUESTIONNAIRE_DETAILS(appointmentId))).data;
    }

    async getAppointmentPurchaseOptions(appointmentId: number) {
        return (await http.get(APPOINTMENT_PURCHASE_OPTIONS(appointmentId)))
            .data;
    }

    async completeAppointment(appointmentId: string) {
        return http.put(`${APPOINTMENTS}/${appointmentId}/complete`);
    }

    async settleAppointment(appointmentId: string) {
        return http.put(`${APPOINTMENTS}/${appointmentId}/settle`);
    }

    async updateAppointmentIcd10Code(appointmentId: string, icd10Code: string) {
        return rawHttp.put(`${APPOINTMENTS}/${appointmentId}/icd10code`, {
            icd10Code,
        });
    }

    async getRecommendations(appointmentId: string) {
        return rawHttp.get(`${APPOINTMENTS}/${appointmentId}/recommendations`);
    }

    async getChatTranscript(appointmentId: string) {
        return rawHttp.get(
            `${APPOINTMENTS}/${appointmentId}/chat-transcription`,
        );
    }

    getAppointmentDetailsForPrescription(appointmentId: string) {
        return http.get<AppointmentDetailsForPrescriptionDto>(
            getAppointmentDetailsForPrescriptionEndpoint(appointmentId),
        );
    }
}

export default new AppointmentsProvider();
