import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';
import {LIGHT_GRAY, WHITE} from '../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 20,
            fontWeight: 'bold',
            padding: mobile.contentLargerSpacing,
        },
        pageContent: {
            backgroundColor: WHITE,
        },
        bottomBorder: {
            borderBottom: `1px solid ${LIGHT_GRAY}`,
        },
        [theme.breakpoints.up('md')]: {
            container: {
                margin: desktop.contentSmallerSpacing,
                marginLeft: desktop.contentLargerSpacing,
                padding: 0,
                paddingBottom: 24,
            },
            title: {
                padding: 0,
                paddingBottom: 24,
            },
            pageContent: {
                padding: 24,
            },
        },
    }),
);

export default useStyles;
