import {
    OnePersonBundleImage,
    ParentAndChildBundleImage,
    TwoPeopleBundleImage,
} from '../assets';

export const bundles = [
    {
        id: 0,
        title: 'packageForOnePerson',
        image: OnePersonBundleImage,
        description:
            'packageForOnePersonDescription',
        maxUsersCount: 1,
        childrenIncluded: false,
    },
    {
        id: 1,
        title: 'packageForTwoPeople',
        image: TwoPeopleBundleImage,
        description:
            'packageForTwoPeopleDescription',
        maxUsersCount: 2,
        childrenIncluded: false,
    },
    {
        id: 2,
        title: 'packageForParentAndChildren',
        image: ParentAndChildBundleImage,
        description:
            'packageForParentAndChildrenDescription',
        maxUsersCount: 2,
        childrenIncluded: true,
    },
];
