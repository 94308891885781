import {isDefault, isImage, isMsword, isPdf, isText} from "../services/contentTypeProvider";
import {defaultFileIcon, docFileIcon, PdfImage, textFileIcon} from "../assets";

export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (readerEvt) => resolve(btoa(readerEvt.target.result as string));
    reader.onerror = error => reject(error);
    reader.readAsBinaryString(file);
});

export const convertCanvasToImageBlob = (canvas: HTMLCanvasElement, fileName: string) => {
    return new Promise<File>((resolve) => {
        canvas.toBlob((blob) => {
            const file = new File([blob], fileName, { type: "image/jpeg" });
            resolve(file);
        });
    });
}

export const getFileIcon = (contentType: string, thumbnail?: string) => {
    if (isPdf(contentType)) {
        return PdfImage;
    }
    if (isMsword(contentType)) {
        return docFileIcon;
    }
    if (isImage(contentType)) {
        return thumbnail ? `data:${contentType};base64, ${thumbnail}` : '';
    }
    if (isText(contentType)) {
        return textFileIcon;
    }
    if (isDefault(contentType)) {
        return defaultFileIcon;
    }
}