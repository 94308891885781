import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
        },
        label: {
            marginBottom: theme.spacing(0.5),
            color: theme.palette.primary.main,
        },
    }),
);

export default useStyles;
