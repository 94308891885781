import {
    Box,
    BoxProps,
    createStyles,
    Grid,
    makeStyles,
    Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {desktop, mobile} from '../../../const/sizes';
import {
    PayUCvvSecureForm,
    PayUDateSecureForm,
    PayUNumberSecureForm,
} from '../../common/payUSecureForms';
import SubscriptionPaymentInput from '../SubscriptionPaymentInput/SubscriptionPaymentInput';

type SubscriptionPaymentCardProps = {
    header?: React.ReactNode;
    requiredCheckForced?: boolean;
    className?: BoxProps['className'];
};

const SubscriptionPaymentCard = ({
    header,
    requiredCheckForced,
    className,
}: SubscriptionPaymentCardProps) => {
    const {t} = useTranslation(i18nNamespaces.SUBSCRIPTION_PAYMENT);

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            header: {
                fontSize: 18,
                fontWeight: 'bold',
            },
            formGroup: {
                width: '100%',
                marginTop: desktop.contentSmallerSpacing,
            },
            smallFormGroup: {
                width: '50%',
                marginTop: desktop.contentSmallerSpacing,
            },
            expirationDate: {
                paddingRight: mobile.contentSmallerSpacing,
            },
            cvv: {
                paddingLeft: mobile.contentSmallerSpacing,
            },
            [theme.breakpoints.up('sm')]: {
                header: {
                    fontSize: 20,
                },
                formGroup: {
                    width: 300,
                    marginRight: desktop.contentSmallerSpacing,
                },
                smallFormGroup: {
                    width: 138,
                    marginRight: desktop.contentSmallerSpacing,
                },
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box className={className}>
            {header || (
                <Box className={classes.header}>
                    {t('provideCreditCardDetails')}
                </Box>
            )}
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
            >
                <Box className={classes.formGroup}>
                    <SubscriptionPaymentInput
                        input={PayUNumberSecureForm}
                        label={t('cardNumber') + ' *'}
                        requiredCheckForced={requiredCheckForced}
                    />
                </Box>
                <Box
                    className={clsx(
                        classes.smallFormGroup,
                        classes.expirationDate,
                    )}
                >
                    <SubscriptionPaymentInput
                        input={PayUDateSecureForm}
                        label={t('expirationDate') + ' *'}
                        requiredCheckForced={requiredCheckForced}
                    />
                </Box>
                <Box className={clsx(classes.smallFormGroup, classes.cvv)}>
                    <SubscriptionPaymentInput
                        input={PayUCvvSecureForm}
                        label={t('cvv') + ' *'}
                        requiredCheckForced={requiredCheckForced}
                    />
                </Box>
            </Grid>
        </Box>
    );
};

export default SubscriptionPaymentCard;
