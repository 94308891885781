import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useLocation, useParams} from 'react-router';
import {PaymentPage, TimeSlotPaymentPrice} from '../../components';
import i18nNamespaces from '../../const/i18nNamespaces';
import useQuery from '../../hooks/useQuery';
import {BuyerDataDto} from '../../types/buyerData';
import {TimeSlotDto} from '../../types/timeSlot';
import {MedicalServicePurchaseOptionsDto} from '../../types/medicalServicePurchaseOptions';
import timeSlotsProvider from '../../services/timeSlotsProvider';
import medicalServiceProvider from '../../services/medicalServiceProvider';
import {AppointmentPurchaseType} from '../../const/appointmentPurchaseType';
import {getDateFromISO, getTimeFromISO} from '../../utils/date';
import {formatAppointmentType} from '../../utils/formatAppointmentType';
import appointmentsProvider from '../../services/appointmentsProvider';
import {
    AppointmentConsultationType,
    NewAppointmentDto,
} from '../../types/appointments';
import {useBuyerDataFrom} from '../../hooks/useBuyerDataFrom';
import {APPOINTMENT_CALENDAR, USER_ACCOUNT_PAGE} from '../../const/routes';
import {gtmService} from '../../services/gtm/gtmService';
import {medicalServiceApi} from '../../api/medicalService/medicalServiceApi';
import {createAppointmentErrorHandler} from '../../utils/error';

const TimeSlotPaymentPage = () => {
    const {timeSlotId} = useParams<{timeSlotId: string}>();
    const query = useQuery();
    const appointmentType = query.get(
        'appointmentType',
    ) as AppointmentConsultationType;
    const appointmentPurchaseType = query.get(
        'appointmentPurchaseType',
    ) as AppointmentPurchaseType;
    const addPatientToQueue = Boolean(query.get('addPatientToQueue'));
    const startDate = query.get('startDate');
    const endDate = query.get('endDate');
    const individualPackageDraftId = parseInt(
        query.get('individualPackageDraftId'),
    );
    const patientGroupDraftId = parseInt(query.get('patientGroupDraftId'));
    const childId = parseInt(query.get('childId'));
    const referralId = parseInt(query.get('referralId'));
    const occupationalMedicineReferralId = parseInt(
        query.get('occupationalMedicineReferralId'),
    );
    const cancelUrl = query.get('cancelUrl');

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [timeSlot, setTimeSlot] = useState<TimeSlotDto>(null);
    const [medicalServicePurchaseOptions, setMedicalServicePurchaseOptions] =
        useState<MedicalServicePurchaseOptionsDto>(null);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const timeSlot = await timeSlotsProvider.getTimeSlot(timeSlotId);

            const medicalServicePurchaseOptions =
                await medicalServiceProvider.getMedicalServicePurchaseOptions(
                    timeSlot?.bookedMedicalService?.id,
                    {referralId, occupationalMedicineReferralId},
                );
            setTimeSlot(timeSlot);
            setMedicalServicePurchaseOptions(medicalServicePurchaseOptions);
            setIsLoading(false);
        }

        fetchData();
    }, [
        setIsLoading,
        timeSlotId,
        setTimeSlot,
        setMedicalServicePurchaseOptions,
        setIsLoading,
    ]);

    const onUseDiscountCode = async (code: string) => {
        setIsLoading(true);
        const timeSlot = await timeSlotsProvider.getTimeSlot(timeSlotId);
        const medicalServicePurchaseOptions =
            await medicalServiceProvider.getMedicalServicePurchaseOptionsWithDiscount(
                timeSlot?.bookedMedicalService?.id,
                code,
            );

        setTimeSlot(timeSlot);
        setMedicalServicePurchaseOptions(medicalServicePurchaseOptions);
        setIsLoading(false);
    };

    const onCancel = () => {
        if (cancelUrl) {
            history.push(decodeURIComponent(cancelUrl));
        } else {
            history.push('/');
        }
    };

    const onSubmit = async (buyerData: BuyerDataDto) => {
        setIsLoading(true);
        let newAppointmentDto: NewAppointmentDto;

        const newChildId = childId !== -1 ? childId : null;

        if (addPatientToQueue) {
            newAppointmentDto = {
                childId: newChildId,
                referralId,
                payment: {
                    buyerData,
                    individualPackageDraftId,
                    patientGroupDraftId,
                    type: appointmentPurchaseType,
                },
                type: appointmentType,
                timeWindow: {
                    startDate,
                    endDate,
                },
                discountCodeId:
                    medicalServicePurchaseOptions.discountCode !== null
                        ? medicalServicePurchaseOptions.discountCode.id
                        : null,
                occupationalMedicineReferralId,
            };
        } else {
            newAppointmentDto = {
                childId: newChildId,
                referralId,
                payment: {
                    buyerData,
                    individualPackageDraftId,
                    patientGroupDraftId,
                    type: appointmentPurchaseType,
                },
                type: appointmentType,
                discountCodeId:
                    medicalServicePurchaseOptions.discountCode !== null
                        ? medicalServicePurchaseOptions.discountCode.id
                        : null,
                occupationalMedicineReferralId,
            };
        }
        appointmentsProvider
            .createAppointment(timeSlotId, newAppointmentDto)
            .then(response => {
                gtmService.triggerTimeSlotConversion(
                    timeSlotId,
                    medicalServicePurchaseOptions.oneTimePrice,
                );
                return response;
            })
            .then(response => {
                window.location.href = response.data.paymentUrl;
            })
            .catch(error => {
                history.push({
                    pathname: USER_ACCOUNT_PAGE + APPOINTMENT_CALENDAR,
                    search: `error=${createAppointmentErrorHandler(
                        error.response.data[0],
                    )}`,
                });
                console.error(error);
            });
    };

    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS_PAYMENT);

    const paymentInfo =
        timeSlot &&
        `${t('doc')} ${timeSlot.briefMedic.name} 
    ${timeSlot.briefMedic.surname} - 
    ${
        timeSlot.briefMedic.specialties?.length &&
        timeSlot.briefMedic.specialties[0]
    }`;

    const additionalPaymentInfo =
        timeSlot &&
        `${getDateFromISO(timeSlot.startDate)}, 
    ${commonT('hourShort')} 
    ${getTimeFromISO(timeSlot.startDate)}, 
    ${commonT('via')} 
    ${formatAppointmentType(appointmentType)}`;

    const buyerDataFormConfig = useBuyerDataFrom(onSubmit);

    return (
        <PaymentPage
            additionalPaymentInfo={additionalPaymentInfo}
            buyerDataFormConfig={buyerDataFormConfig}
            handleSubmit={buyerDataFormConfig.handleSubmit}
            isLoading={isLoading || !timeSlot}
            onCancel={onCancel}
            paymentInfo={paymentInfo}
            priceSection={
                medicalServicePurchaseOptions && (
                    <TimeSlotPaymentPrice
                        medicalServicePurchaseOptions={
                            medicalServicePurchaseOptions
                        }
                        individualPackageDraftId={individualPackageDraftId}
                        patientGroupDraftId={patientGroupDraftId}
                        type={appointmentPurchaseType}
                        onUseDiscountCode={onUseDiscountCode}
                        loading={isLoading}
                    />
                )
            }
            submitText={t('next')}
            title={t('payment')}
        />
    );
};

export default TimeSlotPaymentPage;
