import React from 'react';
import {Box, createStyles, Grid, makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

interface Props {
    medicTitle: string;
    medicFirstName: string;
    medicSurname: string;
    medicalServiceName: string;
    patientFirstName: string;
    patientSurname: string;
}

const textsCommonStyles = {
    fontSize: 16,
    lineHeight: '24px',
}

const useStyles = makeStyles(() =>
    createStyles({
        medicName: {
            ...textsCommonStyles,
            fontWeight: 'bold',
        },
        medicalServiceName: {
            ...textsCommonStyles,
        },
        patientName: {
            ...textsCommonStyles,
        }
    }),
);

const PatientToMedicWithSpeciality = React.memo(
    function PatientToMedicWithSpecialities({
        medicTitle,
        medicFirstName,
        medicSurname,
        medicalServiceName,
        patientFirstName,
        patientSurname,
    }: Props) {
    const classes = useStyles();
    const {t: tAppointment} = useTranslation(i18nNamespaces.APPOINTMENT);
    return (
        <Grid
            container
            direction="column"
            style={{
                overflowWrap: 'anywhere',
            }}
        >
            <Box className={classes.medicName}>
                {medicTitle}{' '}
                {medicFirstName}{' '}
                {medicSurname}
            </Box>
            <Box className={classes.medicalServiceName}>
                {medicalServiceName}
            </Box>
            <Box className={classes.patientName}>
                {tAppointment('patient')}:{' '}
                {patientFirstName}{' '}
                {patientSurname}
            </Box>
        </Grid>
    )
});

export default PatientToMedicWithSpeciality;