import React, {useContext, useState} from 'react';
import {Box, Link} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {AlertContext} from '../../../contexts/AlertContext';
import accountProvider from '../../../services/accountProvider';
import axios from 'axios';

const TIME_TO_NEXT_CODE_SEND = 30000;

type SendCodeAgainProps = {
    mt?: number | string;
    mb?: number | string;
};
const SendCodeAgain = ({mt, mb}: SendCodeAgainProps) => {
    const {t} = useTranslation(i18nNamespaces.AUTH);
    const [remainingTime, setRemainingTime] = useState<number | null>(null);
    let interval: NodeJS.Timeout;
    const openAlert = useContext(AlertContext);

    const startCountdown = () => {
        if (remainingTime === 0) return clearInterval(interval);
        setRemainingTime(TIME_TO_NEXT_CODE_SEND);
        interval = setInterval(() => {
            setRemainingTime(prev => prev - 1000);
        }, 1000);
    };

    const handleSendCodeAgain = async () => {
        try {
            startCountdown();
            await accountProvider.sendSMSCode();
        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error))
                openAlert({
                    severity: 'error',
                    message: t(error.response?.data[0]),
                });
        }
    };

    return (
        <Box mt={mt} mb={mb}>
            {t('codeNotReceived')}{' '}
            {remainingTime > 0 ? (
                t('resendIn', {seconds: remainingTime / 1000})
            ) : (
                <Link
                    color={'secondary'}
                    style={{cursor: 'pointer'}}
                    onClick={handleSendCodeAgain}
                >
                    {t('sendAgain')}
                </Link>
            )}
        </Box>
    );
};

export default SendCodeAgain;
