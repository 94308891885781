import {
    CaseReducer,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {RootState} from './store';

const initialState = {
    selectedChildId: null as number,
};

export type ChildSelectionSliceState = typeof initialState;


const selectChildReducer: CaseReducer<
ChildSelectionSliceState,
    PayloadAction<{id: number}>
> = (state, action) => {
        const {id} = action.payload;
        state.selectedChildId = id;
};

const childSelectionSlice = createSlice({
    name: 'chilsSelection',
    initialState,
    reducers: {
        selectChild: selectChildReducer
    },
});

export const selectSelectedChildId = (state: RootState) => state.childSelection.selectedChildId;

export const {
    selectChild
} = childSelectionSlice.actions;

export default childSelectionSlice.reducer;
