import {createStyles, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        title: {fontSize: 18, fontWeight: 'bold'},
        button: {marginTop: 16, width: 328, height: 48, fontWeight: 'bold'},
    }),
);

export default useStyles;
