import {
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer,
} from '@material-ui/core';
import {
    AccountCircleOutlined,
    CalendarTodayOutlined,
    Close,
    Menu,
    PowerSettingsNew,
} from '@material-ui/icons';
import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {MEDIC} from '../../../../const/auth';
import {MEDIC_CALENDAR, USER_ACCOUNT_PAGE} from '../../../../const/routes';
import useStyles from './SmallMenuStyles';
import {useTranslation} from 'react-i18next';
import {selectAuthUserData} from '../../../../store/auth';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {
    getLogOutNavigationLinks,
    getMedicMobileNavigationLinks,
    getPatientNavigationLinks,
} from '../../../../utils/navigation';
import {MobileNavigationLink} from '../../../../types/navigation';
import ExternalLink from '../../../common/ExternalLink/ExternalLink';
import useLandingPageUrl from '../../../../hooks/useLandingPageUrl';
import {SelectInputProps} from '@material-ui/core/Select/SelectInput';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

type Props = {
    onChangeLanguage?: SelectInputProps['onChange'];
    onClickAccount?: () => void;
    onClickLogout?: () => void;
};

const SmallMenu = ({onChangeLanguage, onClickLogout}: Props) => {
    const classes = useStyles();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const authUserData = useAppSelector(selectAuthUserData);
    const {t} = useTranslation();

    const notLoggedInUser = authUserData === null;
    const isMedic = authUserData?.role.includes(MEDIC);

    const landingPageUrl = useLandingPageUrl();

    const navigationLinkGroups = notLoggedInUser
        ? getLogOutNavigationLinks(landingPageUrl)
        : isMedic
        ? getMedicMobileNavigationLinks()
        : getPatientNavigationLinks(landingPageUrl);

    const itemsList = (links: MobileNavigationLink[]) =>
        links.map(link =>
            link.external ? (
                <ExternalLink
                    key={link.id}
                    to={link.route}
                    className={classes.mobileMenuLink}
                >
                    <ListItem button>
                        <ListItemIcon className={classes.mobileMenuIcon}>
                            {link.icon}
                        </ListItemIcon>
                        <ListItemText
                            disableTypography={true}
                            primary={t(link.labelKey)}
                        />
                    </ListItem>
                </ExternalLink>
            ) : (
                <NavLink
                    key={link.id}
                    to={link.route}
                    className={classes.mobileMenuLink}
                >
                    <ListItem button>
                        <ListItemIcon className={classes.mobileMenuIcon}>
                            {link.icon}
                        </ListItemIcon>
                        <ListItemText
                            disableTypography={true}
                            primary={t(link.labelKey)}
                        />
                    </ListItem>
                </NavLink>
            ),
        );

    const links = isMedic ? (
        <>
            <NavLink
                to={MEDIC_CALENDAR}
                activeClassName={classes.isActive}
                className={classes.menuButton}
            >
                <CalendarTodayOutlined />
            </NavLink>
            <Menu
                className={classes.menuButton}
                onClick={() => setDrawerVisible(true)}
                style={{cursor: 'pointer'}}
            />
        </>
    ) : (
        <>
            <Menu
                className={classes.menuButton}
                onClick={() => setDrawerVisible(true)}
                style={{cursor: 'pointer'}}
            />
            <NavLink
                to={USER_ACCOUNT_PAGE}
                activeClassName={classes.isActive}
                className={classes.menuButton}
            >
                <AccountCircleOutlined />
            </NavLink>
        </>
    );

    return (
        <>
            <div className={classes.menu}>
                <LanguageSelector
                    className={classes.menuButton}
                    onChangeLanguage={onChangeLanguage}
                />
                {links}
                {authUserData && (
                    <PowerSettingsNew
                        onClick={onClickLogout}
                        className={classes.menuButton}
                        style={{cursor: 'pointer'}}
                    />
                )}
            </div>
            <SwipeableDrawer
                anchor={'top'}
                open={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                onOpen={() => setDrawerVisible(true)}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.mobileMenuHeader}
                >
                    <Box>{t('menu')}</Box>
                    <Box onClick={() => setDrawerVisible(false)}>
                        <Close />
                    </Box>
                </Grid>
                {navigationLinkGroups.map((linkGroup, linkGroupIndex) => (
                    <>
                        <List key={linkGroup.id}>
                            {itemsList(linkGroup.links)}
                        </List>
                        {navigationLinkGroups.length - 1 !== linkGroupIndex && (
                            <Divider />
                        )}
                    </>
                ))}
            </SwipeableDrawer>
        </>
    );
};

export default SmallMenu;
