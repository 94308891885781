import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SvgIcon,
} from '@material-ui/core';
import {
    CalendarToday,
    FavoriteBorderOutlined,
    InsertDriveFileOutlined,
    SettingsOutlined,
    CardGiftcardOutlined,
    LocalHospitalOutlined
} from '@material-ui/icons';
import {
    NavLink,
    useRouteMatch,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import {
    AccountDataPage,
    AppointmentsCalendarPage,
    MedicalTestResultsPage,
    PatientMedicsPage,
    PatientPackagesPage,
    VoucherPage,
} from '..';
import {AuthRoute} from '../../components';
import SideMenuWrapper from '../../components/common/SideMenuWrapper/SideMenuWrapper';
import {PATIENT} from '../../const/auth';
import {
    ACCOUNT_DATA,
    ACCOUNT_DATA_PAGE,
    APPOINTMENT_CALENDAR,
    MEDICAL_TEST_RESULTS,
    SICK_LEAVES,
    VOUCHER,
    VOUCHER_PAGE,
    YOUR_BUNDLES,
    YOUR_DOCTORS_PAGE
} from '../../const/routes';
import useStyles from './UserAccountPageStyles';
import {PayUSecureFormsProvider} from '../../components/common/payUSecureForms';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';

import {ReactComponent as MedicalTestResult} from '../../assets/img/testResults.svg';
import SickLeavePage from '../SickLeavesPage/SickLeavesPage';

const UserAccountPage = () => {
    const match = useRouteMatch();
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.COMMON);

    return (
        <>
            <SideMenuWrapper
                menu={
                    <List>
                        <NavLink
                            to={`${match.url}${APPOINTMENT_CALENDAR}`}
                            className={classes.menuLink}
                            activeClassName={classes.menuActiveLink}
                        >
                            <ListItem button>
                                <ListItemIcon className={classes.menuButton}>
                                    <CalendarToday />
                                </ListItemIcon>
                                <ListItemText disableTypography={true}>
                                    {t('consultationCalendar')}
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                        <NavLink
                            to={`${match.url}${YOUR_DOCTORS_PAGE}`}
                            className={classes.menuLink}
                            activeClassName={classes.menuActiveLink}
                        >
                            <ListItem button>
                                <ListItemIcon className={classes.menuButton}>
                                    <FavoriteBorderOutlined />
                                </ListItemIcon>
                                <ListItemText disableTypography={true}>
                                    {t('yourDoctors')}
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                        <NavLink
                            to={`${match.url}${MEDICAL_TEST_RESULTS}`}
                            className={classes.menuLink}
                            activeClassName={classes.menuActiveLink}
                        >
                            <ListItem button>
                                <ListItemIcon className={classes.menuButton}>
                                    <MedicalTestResult />
                                </ListItemIcon>

                                <ListItemText disableTypography={true}>
                                    {t('medicalTestResults')}
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                        <NavLink
                            to={`${match.url}${SICK_LEAVES}`}
                            className={classes.menuLink}
                            activeClassName={classes.menuActiveLink}
                        >
                            <ListItem button>
                                <ListItemIcon className={classes.menuButton}>
                                    <LocalHospitalOutlined />
                                </ListItemIcon>

                                <ListItemText disableTypography={true}>
                                    {t('sickLeaves')}
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                        <NavLink
                            to={`${match.url}${YOUR_BUNDLES}`}
                            className={classes.menuLink}
                            activeClassName={classes.menuActiveLink}
                        >
                            <ListItem button>
                                <ListItemIcon className={classes.menuButton}>
                                    <InsertDriveFileOutlined />
                                </ListItemIcon>
                                <ListItemText disableTypography={true}>
                                    {t('yourPackages')}
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                        <NavLink
                            to={`${match.url}${ACCOUNT_DATA}`}
                            className={classes.menuLink}
                            activeClassName={classes.menuActiveLink}
                        >
                            <ListItem button>
                                <ListItemIcon className={classes.menuButton}>
                                    <SettingsOutlined
                                        className={classes.menuButton}
                                    />
                                </ListItemIcon>
                                <ListItemText disableTypography={true}>
                                    {t('accountDetails')}
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                        <NavLink
                            to={`${match.url}${VOUCHER}`}
                            className={classes.menuLink}
                            activeClassName={classes.menuActiveLink}
                        >
                            <ListItem button>
                                <ListItemIcon className={classes.menuButton}>
                                    <CardGiftcardOutlined
                                        className={classes.menuButton}
                                    />
                                </ListItemIcon>
                                <ListItemText disableTypography={true}>
                                    {t('vouchers')}
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                    </List>
                }
            >
                <Switch>
                    <AuthRoute
                        exact
                        path={`${match.url}${APPOINTMENT_CALENDAR}`}
                        roles={[PATIENT]}
                        component={AppointmentsCalendarPage}
                    />
                    <AuthRoute
                        exact
                        path={`${match.url}/medical-test-results`}
                        roles={[PATIENT]}
                        component={MedicalTestResultsPage}
                    />
                    <AuthRoute
                        exact
                        path={`${match.url}/your-doctors`}
                        roles={[PATIENT]}
                        component={PatientMedicsPage}
                    />
                    <AuthRoute
                        exact
                        path={`${match.url}/your-bundles`}
                        roles={[PATIENT]}
                    >
                        <PayUSecureFormsProvider>
                            <PatientPackagesPage />
                        </PayUSecureFormsProvider>
                    </AuthRoute>
                    <AuthRoute
                        exact
                        path={`${match.url}${ACCOUNT_DATA_PAGE}`}
                        component={AccountDataPage}
                        roles={[PATIENT]}
                    />
                    <AuthRoute
                        exact
                        path={`${match.url}${VOUCHER}`}
                        component={VoucherPage}
                        roles={[PATIENT]}
                    />
                     <AuthRoute
                        exact
                        path={`${match.url}${SICK_LEAVES}`}
                        component={SickLeavePage}
                        roles={[PATIENT]}
                    />
                    <Route path={`${match.url}`}>
                        <Redirect to={`${match.url}${APPOINTMENT_CALENDAR}`} />
                    </Route>
                </Switch>
            </SideMenuWrapper>
        </>
    );
};

export default UserAccountPage;
