import { Avatar, Box, Card, Grid, Theme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../../const/i18nNamespaces";
import { PatientOrderedDiagnosticOrderHeadersDto } from "../../../services/diagnostic/orderHeadersProvider";
import useStyles from "./MedicaltestDetailsStyles";
import { LARGE_DESKTOP_BREAKPOINT } from "../../../const/sizes";
import AppointmentTimeDetails from "../../common/Appointment/AppointmentTimeDetails/AppointmentTimeDetails";
import { DateTime } from "luxon";
import { MedicalTestIcon } from "../../../assets";
import { isNotNullOrUndefined } from "../../../utils/lang";

interface Props {
    orderedDiagnosticServiceOrder: PatientOrderedDiagnosticOrderHeadersDto
}

const MedicalTestDetails = ({orderedDiagnosticServiceOrder}:Props) =>{

    const {t} = useTranslation(i18nNamespaces.MEDICAL_TESTS);
    const {t: tAppointment} = useTranslation(i18nNamespaces.APPOINTMENT);

    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(LARGE_DESKTOP_BREAKPOINT);

    const formatedDate = DateTime.fromISO(orderedDiagnosticServiceOrder.orderDateTime);

    const classes = useStyles();

    return (
        <Card
            className={classes.card}>
            <Grid
                container
                wrap={smUp ? 'nowrap' : 'wrap'}
                alignItems="center"
                spacing={lgUp ? 2 : 1}
                style={{
                    cursor: !mdUp ? 'pointer' : 'default',
                }}
            >
                <Grid item xs={12} sm="auto">
                    <AppointmentTimeDetails
                        date={formatedDate}
                        isNow={false}
                        isInTheFuture={false}
                        square={smUp}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Grid
                        container
                        wrap={'nowrap'}
                        alignItems="center"
                        spacing={lgUp ? 2 : 1}
                    >
                        <Grid item xs="auto">
                            <Avatar
                                alt={'photo'}
                                src={MedicalTestIcon}
                                className={classes.photo}
                            />
                        </Grid>
                        <Grid item xs>
                        <Grid
                            container
                            direction="column"
                            style={{
                                overflowWrap: 'anywhere',
                            }}
                        >
                            <Box className={classes.type}>
                                {isNotNullOrUndefined(orderedDiagnosticServiceOrder.medicalPackageName) 
                                    ? orderedDiagnosticServiceOrder.medicalPackageName 
                                    : t(orderedDiagnosticServiceOrder.type)}
                            </Box>
                            <Box className={classes.laboratoryAddress}>
                                {orderedDiagnosticServiceOrder.laboratoryAddress},
                                {' '}{orderedDiagnosticServiceOrder.zipCode}
                                {' '}{orderedDiagnosticServiceOrder.laboratoryCity}
                            </Box>
                            <Box className={classes.laboratoryAddress}>
                                Tel: {orderedDiagnosticServiceOrder.contactPhoneNumber}
                                {orderedDiagnosticServiceOrder.contactEmail && 
                                (<span>,{' '}e-mail:{' '}{orderedDiagnosticServiceOrder.contactEmail}</span>)}                             
                            </Box>
                            <Box className={classes.patientName}>
                                {tAppointment('patient')}:{' '}
                                {orderedDiagnosticServiceOrder.patientName}{' '}
                                {orderedDiagnosticServiceOrder.patientSurname}
                            </Box>
                        </Grid>
                        </Grid>
                        {lgUp && (
                            <Grid item lg="auto" className={classes.status}>
                                {t(orderedDiagnosticServiceOrder.status)}
                            </Grid>
                        )}
                         
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
  
}

export default MedicalTestDetails;