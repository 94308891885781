import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {desktop, mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            padding: 0,
        },
        menuLink: {
            textDecoration: 'none',
        },
        menuActiveLink: {
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
        },
        menuItem: {
            padding: `12px ${desktop.contentSmallerSpacing}`,
        },
        menuButton: {
            color: 'inherit',
            minWidth: 0,
            marginRight: mobile.contentLargerSpacing,
        },
        menuIcon: {
            width: 24,
            height: 24,
        },
    }),
);

export default useStyles;
