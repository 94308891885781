import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { BACKGROUND_PRIMARY } from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '25px 0',
        },
        sectionContent: {
            width: '100%',
            paddingTop: '16px',
            marginTop: '1px',
        },
        showMoreHistorical: {
            borderTop: `8px solid ${BACKGROUND_PRIMARY}`,
            padding: '16px 0',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                borderTop: `1px solid ${BACKGROUND_PRIMARY}`,
                padding: '21px 0',
                margin: '0 47px',
            }
        },
    }),
);

export default useStyles;
