import {QbCardOwner} from '../types/individualPackages';

export const getToken = () => localStorage.getItem('jwt');
export const setToken = (token: string) => localStorage.setItem('jwt', token);
export const clearToken = () => {
    localStorage.removeItem('jwt');
};

export const getMedicAsPatient = () =>
    JSON.parse(localStorage.getItem('medicAsPatient'));
export const setMedicAsPatient = (medicAsPatient: boolean) =>
    localStorage.setItem('medicAsPatient', JSON.stringify(medicAsPatient));
export const clearMedicAsPatient = () =>
    localStorage.removeItem('medicAsPatient');

export const setOriginUrl = (originUrl: string) =>
    localStorage.setItem('originUrl', originUrl);
export const getOriginUrl = () => localStorage.getItem('originUrl');
export const clearOriginUrl = () => localStorage.removeItem('originUrl');

export const setQbCardOwner = (qbCardOwner: QbCardOwner) =>
    localStorage.setItem('qbCardOwner', JSON.stringify(qbCardOwner));
export const getQbCardOwner = () =>
    JSON.parse(localStorage.getItem('qbCardOwner'));
export const clearQBCardOwner = () => localStorage.removeItem('qbCardOwner');
