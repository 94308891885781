import React, {useEffect, useState} from 'react';
import {Box, List, ListItem, MenuItem} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import {PdfImage} from '../../assets';
import {CustomSelect, Loading} from '../../components';
import medicWorkingTimeSettlementsProvider from '../../services/medicWorkingTimeSettlementsProvider';
import {MedicWorkingTimeSettlement} from '../../types/medicWorkingTimeSettlements';
import useStyles from './MedicWorkingTimeSettlementsPageStyles';
import {CustomInputChangeEvent} from '../../types/common';
import filesDownloadProvider from '../../services/filesDownloadProvider';

const MedicWorkingTimeSettlementsPage = () => {
    const classes = useStyles();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const [yearSelectItems, setYearSelectItems] = useState<number[]>([]);
    const [yearSelectItemsLoading, setYearSelectItemsLoading] = useState(false);
    const [selectedYearItem, setSelectedYearItem] = useState<number>(null);
    const [workingTimeSettlements, setWorkingTimeSettlements] = useState<
        MedicWorkingTimeSettlement[]
    >([]);
    const [workingTimeSettlementsLoading, setWorkingTimeSettlementsLoading] =
        useState(false);
    const loading = yearSelectItemsLoading || workingTimeSettlementsLoading;

    const yearSelectionHandler = async (selectedYear: number) => {
        setSelectedYearItem(selectedYear);
        setWorkingTimeSettlementsLoading(true);
        const workingTimeSettlementsByYear =
            await medicWorkingTimeSettlementsProvider.getWorkingTimeSettlementsByYear(
                selectedYear,
            );
        setWorkingTimeSettlements(workingTimeSettlementsByYear);
        setWorkingTimeSettlementsLoading(false);
    };

    const fetchInitialData = async () => {
        setYearSelectItemsLoading(true);
        const years =
            await medicWorkingTimeSettlementsProvider.getYearsForWorkingTimeSettlements();
        setYearSelectItems(years);
        setYearSelectItemsLoading(false);
        if (years.length) {
            yearSelectionHandler(years[years.length - 1]);
        }
    };

    const downloadWorkingTimeSettlement = async (
        item: MedicWorkingTimeSettlement,
    ) => {
        try{
        const downloadLink =
            await medicWorkingTimeSettlementsProvider.fetchDownloadLinkForWorkingTimeSettlement(
                item.year,
                item.month,
            );
        filesDownloadProvider.downloadFileFromUrl(
            downloadLink,
            `${getFileName(item)}.pdf`,
        );
        }
        catch (e) {
            console.error(
                e instanceof Error
                    ? e.message
                    : 'An error occurred while fetching instruction data'
            );
        } 
    };

    const getFileName = (item: MedicWorkingTimeSettlement) => {
        return `${commonT(`months.${item.month}`)} ${item.year}`;
    };

    useEffect(() => {
        fetchInitialData();
    }, []);

    return (
        <Box className={classes.root}>
            <Loading withBackdrop={true} loading={loading} />
            <Box className={classes.headerText}>
                {commonT('workingTimeSettlements')}
            </Box>
            <CustomSelect
                className={classes.yearSelect}
                variant={'outlined'}
                value={selectedYearItem || ''}
                onChange={(e: CustomInputChangeEvent) => {
                    yearSelectionHandler(e.target.value as number);
                }}
            >
                {yearSelectItems.map(yearSelectItem => (
                    <MenuItem key={yearSelectItem} value={yearSelectItem}>
                        {yearSelectItem}
                    </MenuItem>
                ))}
            </CustomSelect>
            <List disablePadding>
                {workingTimeSettlements.map(workingTimeSettlementsItem => (
                    <ListItem
                        className={classes.listItem}
                        key={workingTimeSettlementsItem.month}
                        onDoubleClick={() =>
                            downloadWorkingTimeSettlement(
                                workingTimeSettlementsItem,
                            )
                        }
                    >
                        <img src={PdfImage} width={24} />
                        <Box component="span" className={classes.datePeriod}>
                            {getFileName(workingTimeSettlementsItem)}
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default MedicWorkingTimeSettlementsPage;
