import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { LoadingStatus } from '../../const/loadingStatus';
import { PatientDiagnosticOrdersReferralsDto, PatientOrderedDiagnosticOrderHeaders } from '../../services/diagnostic/orderHeadersProvider';
import referralsProvider from '../../services/referralsProvider';
import mapOrderedDiagnosticOrderHeader from '../mappers/mapOrderedDiagnosticOrderHeader';
import { RootState } from '../store';


const sliceName = 'orderedDiagnosticOrderHeadersWithResults';

const orderedDiagnosticOrderHeadersAdapter = createEntityAdapter<PatientDiagnosticOrdersReferralsDto>({
    selectId: orderedDiagnosticOrderHeaders => orderedDiagnosticOrderHeaders.createDate.toString(),
});

const initialState = orderedDiagnosticOrderHeadersAdapter.getInitialState({
    status: 'idle' as LoadingStatus,
    page: 0,
    size: 5,
    count: 0,
});

export type orderedDiagnosticOrderHeadersWithResultsSliceState = typeof initialState;

export const fetchOrderedDiagnosticOrderHeadersWithResult = createAsyncThunk<
    {
        orderedDiagnosticOrderHeaders: PatientDiagnosticOrdersReferralsDto[];
        page: number;
        count: number;
    },
    {
        patientId:string;
        childId?:number
    },
    {
        state: RootState;
    }
>(`${sliceName}/fetchOrderedDiagnosticOrderHeaders`, async ({patientId, childId}, thunkApi) => {
    const {page, size} = selectOrderedDiagnosticOrderHeadersWithResult(thunkApi.getState());
    const {
        page: resultPage,
        results,
        count,
    } = await referralsProvider.getPatientOrderedMedicalTests(
        patientId, 
        page,
        size,
        childId
    )

    return {
        orderedDiagnosticOrderHeaders: results,
        page: resultPage,
        count,
    };
});

const orderedDiagnosticOrderHeadersWithResultsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        pageSpecified(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        sizeSpecified(state, action: PayloadAction<number>) {
            state.size = action.payload;
        },
        clear(state) {
            state.page = initialState.page;
            state.size = initialState.size;
            state.count = initialState.count;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOrderedDiagnosticOrderHeadersWithResult.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchOrderedDiagnosticOrderHeadersWithResult.fulfilled, (state, action) => {
                state.status = 'loaded';

                state.page = action.payload.page;
                state.count = action.payload.count;
                orderedDiagnosticOrderHeadersAdapter.setAll(state, action.payload.orderedDiagnosticOrderHeaders);
            })
            .addCase(fetchOrderedDiagnosticOrderHeadersWithResult.rejected, state => {
                state.status = 'failed';
            });
    },
});

export const {pageSpecified} = orderedDiagnosticOrderHeadersWithResultsSlice.actions;
export const {sizeSpecified} = orderedDiagnosticOrderHeadersWithResultsSlice.actions;

export const selectOrderedDiagnosticOrderHeadersWithResult = (state: RootState) => state.orderedDiagnosticOrderHeadersWithResults;

export const selectOrderedDiagnosticOrderHeadersWithResultStatus = (state: RootState) =>
    selectOrderedDiagnosticOrderHeadersWithResult(state).status;
export const selectOrderedDiagnosticOrderHeadersWithResultPage = (state: RootState) =>
    selectOrderedDiagnosticOrderHeadersWithResult(state).page;
export const selectOrderedDiagnosticOrderHeadersWithResultSize = (state: RootState) =>
    selectOrderedDiagnosticOrderHeadersWithResult(state).size;
export const selectOrderedDiagnosticOrderHeadersWithResultCount = (state: RootState) =>
    selectOrderedDiagnosticOrderHeadersWithResult(state).count;

export const {selectIds: selectOrderedDiagnosticOrderHeadersWithResultIds, selectById: selectOrderedDiagnosticOrderHeaderWithResultById} =
    orderedDiagnosticOrderHeadersAdapter.getSelectors<RootState>(state => selectOrderedDiagnosticOrderHeadersWithResult(state));

export default orderedDiagnosticOrderHeadersWithResultsSlice.reducer;
