import {AppBar, Box, Toolbar} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import AppLogo from "../../Layout/AppLogo/AppLogo";
import React from "react";
import useStyles from './TopBarDesktopStyles';
import clsx from "clsx";

interface Props {
    referralForText: string,
    firstName?: string,
    surname?: string,
    onCloseIconClick?: () => void;
}

const TopBarDesktop = ({referralForText, firstName, surname, onCloseIconClick}: Props) => {
    const classes = useStyles();
    return (
        <AppBar
            className={classes.appBar}
            position={'static'}
        >
            <Toolbar className={classes.content}>
                <AppLogo />
                <Box
                    className={classes.referPatient}
                >
                    {(firstName && surname) && `${referralForText} ${firstName} ${surname}`}
                </Box>
                <Close
                    className={
                        clsx(classes.closeIcon, {
                            ['hidden']: !onCloseIconClick
                        })
                    }
                />
            </Toolbar>
        </AppBar>
    )
}

export default TopBarDesktop;