import React from 'react';
import {Grid} from '@material-ui/core';
import {IconButtonWithText} from '../../index';
import {RemoveCircleOutline} from '@material-ui/icons';
import {MemberDto} from '../../../types/patientPackages';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        optionButton: {
            marginTop: 8,
        },
        [theme.breakpoints.up('sm')]: {
            optionButton: {
                marginLeft: 18,
                marginTop: 0,
            },
        },
    }),
);

interface MembersListProps {
    members: MemberDto[];
    onRemoveMember: (pesel: string) => void;
    isRemoveEnabled:boolean;
}

const MembersList = ({members, onRemoveMember, isRemoveEnabled}: MembersListProps) => {
    const {t} = useTranslation(i18nNamespaces.PATIENT_PACKAGES);
    const classes = useStyles();

    return (
        <>
            {members.map(member => (
                <React.Fragment key={member.pesel}>
                    <Grid item>
                        {t('secondPersonCoveredByHealthCare')}:{' '}
                        <span style={{fontWeight: 'bold'}}>
                            {member.name} {member.surname}
                        </span>
                    </Grid>
                    {isRemoveEnabled && <Grid item className={classes.optionButton}>
                        <IconButtonWithText
                            label={t('remove')}
                            icon={<RemoveCircleOutline />}
                            onClick={() => onRemoveMember(member.pesel)}
                        />
                    </Grid>}
                </React.Fragment>
            ))}
        </>
    );
};

export default MembersList;
