import {useTranslation} from 'react-i18next';
import useStyles from './L4ListStyles';
import i18nNamespaces from '../../../const/i18nNamespaces';
import useCertificateThumbprint from '../../../hooks/certificate/useCertificateThumbprint';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../../store/auth';
import Link from '@material-ui/core/Link';
import {
    NEW_SICK_LEAVE_PAGE,
    SICK_LEAVE_ISSUED_PAGE,
} from '../../../const/routes';
import {Box, LinearProgress} from '@material-ui/core';
import {
    fetchSickLeaves,
    selectSickLeaves,
    selectSickLeavesStatus,
} from '../../../store/sickLeaveSlice';
import SickLeavesListItem from '../../sickLeave/SickLeaveListItem/SickLeavesListItem';
import {useEffect} from 'react';

type L4ListProps = {
    appointmentId: number;
};

const L4List = ({appointmentId}: L4ListProps) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.L4);
    const dispatch = useAppDispatch();

    const userData = useAppSelector(selectAuthUserData);
    const status = useAppSelector(selectSickLeavesStatus);
    const sickLeaves = useAppSelector(selectSickLeaves);
    const {loading: thumbprintLoading, thumbprint} = useCertificateThumbprint(
        userData?.userId,
    );

    useEffect(() => {
        dispatch(fetchSickLeaves({appointmentId}));
    }, []);

    const getSickLeaves = () => dispatch(fetchSickLeaves({appointmentId}));

    useEffect(() => {
        getSickLeaves();
        // Fetch prescriptions on tab focus
        window.addEventListener('focus', getSickLeaves);

        return () => {
            window.removeEventListener('focus', getSickLeaves);
        };
    }, []);

    const handleClickIssueSickLeave = () => {
        // Open page in new tab
        const win = window.open(
            `${NEW_SICK_LEAVE_PAGE}/${appointmentId}`,
            '_blank',
        );
        win.focus();
    };

    return (
        <>
            <h4 className={classes.header}>{t('sickLeaves')}</h4>

            {/* <Loading loading={thumbprintLoading} withBackdrop /> */}
            {status === 'loading' && <LinearProgress color={'secondary'} />}
            <Box className={classes.sickLeavesList}>
                {sickLeaves?.map((sickLeaves, idx) => (
                    <SickLeavesListItem
                        index={idx}
                        key={idx}
                        sickLeave={sickLeaves}
                    />
                ))}
            </Box>
            {thumbprint ? (
                <Link
                    color={'secondary'}
                    style={{cursor: 'pointer'}}
                    onClick={handleClickIssueSickLeave}
                >
                    {t('issueSickLeave')}
                </Link>
            ) : (
                <Link
                    color={'secondary'}
                    className={classes.loadCertificateLink}
                    onClick={() => {}} //TODO
                >
                    {t('loadCertificate')}
                </Link>
            )}
        </>
    );
};

export default L4List;
