import {TextField} from '@material-ui/core';
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import {useAppDispatch, useAppSelector} from "../../../../hooks/customReduxHooks";
import {
    resetMedics,
    resetTags,
    selectMedicalServices,
    selectSelectedMedicalServiceId,
    selectSelectedTags,
    setSelectedMedicalServiceId
} from "../../../../store/referrals/makePatientReferralSlice";
import {Service} from "../../../../types/timeSlot";
import {ChangeEvent} from "react";

interface Props {
    fetchMedicAsync: (medicalServiceId: number, tagIds: number[]) => void;
}

const MedicalServiceSelection = ({fetchMedicAsync}: Props) => {
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();
    const medicalServices = useAppSelector(selectMedicalServices);
    const selectedMedicalServiceId = useAppSelector(selectSelectedMedicalServiceId);
    const selectedTags = useAppSelector(selectSelectedTags);

    const getSelectedMedicalServiceOption = () =>
        medicalServices
            .find(medicalService => medicalService.id === selectedMedicalServiceId)
            || null;

    const medicalServiceSelectionChangeHandler = async (_: ChangeEvent<unknown>, selectedMedicalService: string | Service) => {
        if (selectedMedicalService !== null) {
            const medicalServiceId = (selectedMedicalService as Service).id
            dispatch(
                setSelectedMedicalServiceId(medicalServiceId)
            );
            fetchMedicAsync(medicalServiceId, selectedTags.map(tag => tag.id));
        } else {
            dispatch(resetMedics());
            dispatch(resetTags());
            dispatch(setSelectedMedicalServiceId(null));
        }
    }

    return (
        <Autocomplete
            options={medicalServices}
            value={getSelectedMedicalServiceOption()}
            getOptionLabel={medicalService => medicalService.name}
            onChange={medicalServiceSelectionChangeHandler}
            renderInput={params =>
                <TextField
                    {...params}
                    label={tCommon('selectTypeOfService')}
                    variant="outlined"
                />
            }
        />
    )
}

export default MedicalServiceSelection;