import {useEffect, useState} from 'react';

export const useVideoStreamParams = (videoStream: MediaStream) => {
    const [aspectRatio, setAspectRatio] = useState<number>(1);

    useEffect(() => {
        if (!videoStream) return;
        const {aspectRatio} = videoStream.getVideoTracks()[0].getSettings();
        setAspectRatio(aspectRatio);
    }, [videoStream]);

    return {aspectRatio};
};
