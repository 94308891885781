import {Box, createStyles, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import isBoolean from "lodash-es/isBoolean"
import clsx from "clsx";
import i18nNamespaces from "../../../../const/i18nNamespaces";
import {GRAY, MAGENTA, WHITE} from "../../../../const/colors";

interface Props {
    isSettled: boolean | null,
    className?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        label: {
            display: 'inline-block',
            padding: '4px 8px',
            fontSize: 14,
            lineHeight: '24px',
            borderRadius: 4,
            color: WHITE,
        }
    }),
);

const AppointmentSettleStatusLabel = ({isSettled, className}: Props) => {
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT);
    const classes = useStyles();
    const getLabelProps = () => {
        if (isSettled) {
            return {
                children: t('settled'),
                style: {backgroundColor: GRAY}
            }
        } else {
            return {
                children: t('notSettled'),
                style: {backgroundColor: MAGENTA}
            }
        }
    }
    return (
        isBoolean(isSettled)
            ? (
                <Box
                    className={
                        clsx(classes.label, {
                            [className]: className
                        })
                    }
                    component={'span'}
                    {...getLabelProps()}
                />
            )
            : null
    )
}

export default AppointmentSettleStatusLabel;