import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../const/i18nNamespaces";
import useStyles from "./MedicAccountDataPageStyles";
import { Grid, Link} from '@material-ui/core';
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import { CHANGE_PASSWORD_PAGE, CHANGE_PHONE_NUMBER_PAGE } from "../../const/routes";
import {useHistory} from 'react-router';


const MedicAccountDataPage = () => {

    const classes = useStyles();
    const history = useHistory();
    const {t: tSettings} = useTranslation(i18nNamespaces.SETTINGS);

    return (
    
        <Grid container direction='column' className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Link
                        onClick={() => history.push(CHANGE_PASSWORD_PAGE)}
                        style={{cursor: 'pointer'}}
                        className={classes.buttonTile}
                    >
                        {tSettings('changePassword')}
                        <ArrowRightIcon color={'secondary'}/>
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Link
                        onClick={() => history.push(CHANGE_PHONE_NUMBER_PAGE)}
                        style={{cursor: 'pointer'}}
                        className={classes.buttonTile}
                    >
                        {tSettings('changePhone')}
                        <ArrowRightIcon color={'secondary'}/>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    )
    
}

export default MedicAccountDataPage;