import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router';
import {
    Box,
    createStyles,
    Grid,
    Hidden,
    makeStyles,
    TextField,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {ArrowForwardIos} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchReferrals,
    selectPatientReferralById,
} from '../../../store/patientCalendar/referralsToSpecialistSlice';
import {CustomAvatar, Label, Loading, PatientToMedicWithSpeciality} from '../../index';
import referralsProvider from '../../../services/referralsProvider';
import {FIND_TIME_SLOTS} from '../../../const/routes';
import CalendarLink from '../AppointmentLink/CalendarLink';
import {generateFindTimeSlotsUrl} from '../../../utils/timeSlots';
import { MAGENTA, RED } from '../../../const/colors';
import filesDownloadProvider from '../../../services/filesDownloadProvider';
import axios from 'axios';
import { CustomAlert } from '../../common/feedback';

interface Props {
    referralId: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrowForward: {
            color: theme.palette.grey[300],
        },
        linkLabel:{
            color: MAGENTA,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        info:{
            color: RED
        }
    }),
);

const PatientReferral = ({referralId}: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const referral = useAppSelector(state =>
        selectPatientReferralById(state, referralId),
    );

    const [error, setError] = useState<string>(null);
    const getFindTimeSlotsPageRoute = useCallback(
        () =>
            generateFindTimeSlotsUrl({
                medicalServiceId: referral.medicalService.id,
                medicId: referral.toMedic.id,
                referralId: referral.id,
                childId: referral.child?.id || null,
            }),
        [referral],
    );

    const deleteReferral = useCallback(async () => {
        await referralsProvider.deleteReferral(referralId);
        dispatch(fetchReferrals());
    }, [dispatch, referralId]);

    const downloadPdf = async (id:number) =>{
        try{
            setLoading(true);
            const url = (await referralsProvider.getReferralPdfUrl(id)).data;
            if(url!==null){
                filesDownloadProvider.downloadFileFromUrl(
                    url,
                    tCommon('referral')+".pdf"
                );
            }
        } catch(error){
            if (axios.isAxiosError(error) &&
                error.response.status === 404)
                setError(tCommon('fileNotGenerated'))
            console.error(error);
        } finally{
            setLoading(false);
        }
    }

    const clearError = () =>{
        setError(null);
    }

    return (
        <Grid
            container
            wrap={'nowrap'}
            alignItems="center"
            spacing={lgUp ? 2 : 1}
        >
            <Loading loading={loading}
                withBackdrop/>
            <CustomAlert
                message={error}
                open={error!==null} 
                severity={'error'}  
                onClose={clearError}              
                />
            <Grid item xs="auto">
                <CustomAvatar
                    alt={`Medic photo`}
                    src={referral.toMedic.photoUrl}
                />
            </Grid>
            <Grid item xs>
                <PatientToMedicWithSpeciality
                    medicTitle={referral.toMedic.title}
                    medicFirstName={referral.toMedic.firstName}
                    medicSurname={referral.toMedic.surname}
                    medicalServiceName={referral.medicalService.name}
                    patientFirstName={
                        referral.child?.firstName ?? referral.patient.firstName
                    }
                    patientSurname={
                        referral.child?.surname ?? referral.patient.surname
                    }
                />
                {referral.availableInPdfForm  && <Box className={classes.info}>{tCommon('contactSignalIdunaToBookAnAppointment')}</Box>}
            </Grid>
            <Hidden smDown>
                <Grid
                    item
                    xs="auto"
                    style={{
                        display: 'flex',
                    }}
                >
                    {referral.availableInPdfForm 
                        ? <label htmlFor='download'
                                onClick={() => downloadPdf(referral.id)}
                                className = {classes.linkLabel}>
                            {tCommon('download')}
                        </label>
                        : <CalendarLink to={getFindTimeSlotsPageRoute()}>
                            {tCommon('bookAppointment').replace(/\s/g, '\n')}
                        </CalendarLink>
                    }
                    
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid item xs="auto">
                    <ArrowForwardIos
                        className={classes.arrowForward}
                        style={{cursor: 'pointer'}}
                        onClick={() =>
                            history.replace(getFindTimeSlotsPageRoute())
                        }
                    />
                </Grid>
            </Hidden>
        </Grid>
    );
};

export default PatientReferral;
