import { useEffect } from "react";
import { useAppDispatch } from "../../hooks/customReduxHooks";
import { signOut } from "../../store/auth";


const LogoutPage = () => {
      const dispatch = useAppDispatch();

    useEffect(()=>{
            dispatch(signOut());
    }, [])

    return (<></>)
}

export default LogoutPage;