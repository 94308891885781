import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            paddingTop: 1,
            paddingBottom: mobile.contentSmallerSpacing,
        },
        formContainer: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
        },
        fromSection: {
            width: '100%',
            paddingBottom: desktop.contentSmallerSpacing,
        },
        toSection: {
            paddingBottom: desktop.contentSmallerSpacing,
        },
        formGroup: {
            flex: '1',
        },
        formDate: {
            paddingRight: mobile.contentSmallerSpacing,
        },
        formTime: {
            paddingLeft: mobile.contentSmallerSpacing,
        },
        timeSlotInput: {
            height: 48,
            width: '100%',
            fontSize: 16,
        },
        saveButtonContainer: {
            width: '100%',
        },
        saveButton: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            header: {
                fontSize: 20,
            },
            formContainer: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 0,
            },
            fromSection: {
                width: 'auto',
                paddingRight: mobile.contentSmallerSpacing,
                paddingBottom: desktop.contentLargerSpacing,
            },
            toSection: {
                paddingLeft: mobile.contentSmallerSpacing,
                paddingBottom: desktop.contentLargerSpacing,
            },
            formGroup: {
                flex: 0,
            },
            formInput: {
                width: 310,
            },
            saveButtonContainer: {
                textAlign: 'center',
            },
            saveButton: {
                width: 310,
            },
        },
    }),
);

export default useStyles;
