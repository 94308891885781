import {Box, Button} from '@material-ui/core';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Loading, MojeIDRegisterForm} from '../../components';
import i18nNamespaces from '../../const/i18nNamespaces';
import {PHONE_VERIFICATION_PAGE} from '../../const/routes';
import accountProvider from '../../services/accountProvider';
import {ConfirmedIdentityDto} from '../../types/auth';
import {RegisterWithIdentityDto} from '../../types/Login';
import useStyles from './ConfirmIdentityPageStyles';
import auth, {
    authStatusSpecified,
    confirmIdentityWithMojeId,
    selectAuthEmail,
    selectAuthError,
    selectAuthStatus,
    selectAuthUserData,
    signUpRegisterMojeId,
} from '../../store/auth';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {MojeIDRegisterFormValues} from '../../components/forms/MojeIDRegisterForm/MojeIDRegisterForm';

const ConfirmIdentityPage = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const singUpError = useAppSelector(selectAuthError);

    const authData = useAppSelector(selectAuthUserData);
    const authEmail = useAppSelector(selectAuthEmail);

    const {t} = useTranslation(i18nNamespaces.AUTH);
    const [confirmedIdentityLoading, setConfirmedIdentityLoading] =
        useState<boolean>(false);

    // const loading = confirmedIdentityLoading || authStatus === 'loading';

    const [loading, setLoading] = useState<boolean>(false);
    const [confirmedIdentity, setConfirmedIdentity] =
        useState<ConfirmedIdentityDto>(null);

    const loadConfirmedIdentity = useCallback(async () => {
        setConfirmedIdentityLoading(true);

        setConfirmedIdentity(await accountProvider.getConfirmedIdentity());

        setConfirmedIdentityLoading(false);
    }, []);

    
    const submitConfirmIdentities = async (
        
    )=>{
        await dispatch(confirmIdentityWithMojeId());
        history.push({
            pathname: PHONE_VERIFICATION_PAGE,
            state: {email: authEmail},
        });
    }


    useEffect(() => {
        loadConfirmedIdentity();
    }, [loadConfirmedIdentity]);

   
    const classes = useStyles();

    return (
        <>
            <Loading loading={loading} withBackdrop />
            <Box className={classes.container}>
                <Box className={classes.header}>
                    {t('checkIdentityData')}
                </Box>
                {confirmedIdentity && (
                    <Box>
                        <Box>
                            {confirmedIdentity.firstName}{' '}
                            {confirmedIdentity.lastName}
                        </Box>
                        <Box>PESEL: {confirmedIdentity.pesel}</Box>
                    </Box>
                )}
                
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={submitConfirmIdentities}
                        className={classes.submitButton}
                >
                    {t('confirm')}
                </Button>
            </Box>
        </>
    );
};

export default ConfirmIdentityPage;
