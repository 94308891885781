import {rawHttp} from '../../services/http';
import {REFERRALS_ADDITIONAL_DATA} from './referralEndoints';
import {InitializeDto} from '../../types/timeSlot';

class ReferralApi {
    getReferralAdditionalData() {
        return rawHttp.get<InitializeDto>(REFERRALS_ADDITIONAL_DATA);
    }
}

export const referralApi = new ReferralApi();
