import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 36,
            fontWeight: 'bold',
            marginBottom: 30,
        },
        formField: {
            marginBottom: 24,
        },
        submitButton: {
            marginBottom: 24,
        },
        passwordLabelContainer: {
            width: '100%',
        },
        passwordStrengthBarContainer: {
            flex: 1,
            paddingLeft: 10,
        },
        error: {
            color: 'red',
            marginBottom: 10,
        },
        [theme.breakpoints.up('sm')]: {
            title: {
                fontSize: 56,
            },
            submitButton: {
                width: 300,
            },
            passwordLabelContainer: {
                width: 300,
            },
        },
    }),
);

export default useStyles;
