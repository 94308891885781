import {createStyles, makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    listItem: {
        display: 'block',
        padding: '0',
        '&:not(:last-child)': {
            padding: '0 0 24px'
        },
        '&:last-child': {
            padding: '0',
        },
    },
    header: {
        margin: '0',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700
    },
    content: {
        margin: '6px 0 0',
        fontSize: '16px',
        lineHeight: '24px',
        [theme.breakpoints.up('md')]: {
            margin: '8px 0 0',
        }
    }
}));

export default useStyles;