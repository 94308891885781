import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useOccupationalMedicineStyles from "../OccupationalMedicineFormStyles";
import { useAppDispatch } from "../../../hooks/customReduxHooks";
import { isNotNullOrUndefined } from "../../../utils/lang";

import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18nNamespaces from "../../../const/i18nNamespaces";
import { AccordionDetails, Accordion, AccordionSummary } from "../../common/Accordion/Accordion";
import { useFormik } from "formik";
import * as yup from 'yup';
import { PatientPrescriptionHistoryDto } from "../../../types/prescription";
import { PatientPlaceOfWorkDto, AuxiliaryTestItemDto } from "../../../types/occupationalMedicine";
import Label from "../../common/Label/Label";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import { DateTime } from "luxon";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { RenderReturnNextButtons } from "../CommonView";
import { getShortDateFromISO } from "../../../utils/date";
import {Close, Edit} from '@material-ui/icons'
import useStyles from "./AuxiliaryTestsComponentStyles";


const REQUIRED_STRING = 'requiredField';

export type Props = {
    index:number,
    data: AuxiliaryTestItemDto,
    onDelete: (index:number) => void,
    onEdit: (index: number, data:AuxiliaryTestItemDto) => void
}
const AuxiliaryTestsItem = ({
    index,
    data,
    onDelete,
    onEdit
}:Props) =>{

    const {t} = useTranslation(i18nNamespaces.OCCUPATIONAL_MEDICINE);
    const classes = useOccupationalMedicineStyles();
    const customClasses = useStyles();
    
    return (
            <Box className={customClasses.border}>             
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={4}>
                        <Box>
                            {data.type}
                        </Box>
                        <Box>
                            {t('referralDate')}: {getShortDateFromISO(data.referralToMedicalTestDate.toString())}
                        </Box>
                        {data.medicalTestDate &&
                            <Box>
                                {t('medicalTestDate')}: {getShortDateFromISO(data.medicalTestDate.toString())}
                            </Box>}
                    </Grid>
                    <Grid item xs={4}>
                        <Box>
                            {data.results}
                        </Box>      
                    </Grid>
                    <Grid item xs={4} className={customClasses.actionIconContainer}>
                        <Box className={customClasses.closeButtonCntainer}>
                            <IconButton onClick={() => {onDelete(index)}} 
                            className={customClasses.iconButton}>
                                <Close className={customClasses.closeButton}/>
                            </IconButton>
                            
                        </Box>
                        <Box className={customClasses.editButtonContainer}>
                            <IconButton onClick={() => {onEdit(index, data)}}
                            className={customClasses.iconButton}>
                                <Edit/>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid> 
            </Box>
    )
};

export default AuxiliaryTestsItem;