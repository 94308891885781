import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    GroupPackageMembershipDto,
    MemberDto,
} from '../../../types/patientPackages';
import useStyles from './MembershipDetailsStyles';
import {AddCircleOutline} from '@material-ui/icons';
import {IconButtonWithText} from '../../index';
import {LIGHT_DISABLED} from '../../../const/colors';
import {getShortDateFromISO} from '../../../utils/date';
import {useHistory} from 'react-router';
import AddPersonToPackageForm from '../../forms/AddPersonToPackageForm/AddPersonToPackageForm';
import MembersList from '../MembersList/MembersList';
import {AxiosError} from 'axios';
import {patientPackagesErrorHandler as errorHandler} from '../../../utils/error';
import packagesProvider from '../../../services/packagesProvider';
import {useState} from 'react';
import { getLanguage, getTranslation } from '../../../utils/translation';
import i18n from '../../../i18n/config';

type MembershipDetailsProps = {
    membership: GroupPackageMembershipDto;
    setLoading: (loading: boolean) => void;
    onChange: () => void;
};

const MembershipDetails = ({
    membership,
    setLoading,
    onChange,
}: MembershipDetailsProps) => {
    const {t} = useTranslation(i18nNamespaces.PATIENT_PACKAGES);
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const history = useHistory();
    const [error, setError] = useState<string>('');
    const currentLanguage = getLanguage(i18n?.language);

    const {
        groupPackage,
        assignmentMembers,
        limits,
        validTo,
        isOwn,
        id,
        numberOfAvailableMemberChanges,
        medicalPackageLimits
    } = membership;
    const maxAdditionalMembersCount = groupPackage.maxUsersCount - 1;
    const isAdultMemberLimitReached =
        assignmentMembers.length >= maxAdditionalMembersCount;
    const memberCanBeAdded =
        !isAdultMemberLimitReached &&
        isOwn &&
        numberOfAvailableMemberChanges > 0;

    const handleError = (error: AxiosError) => {
        setError(t(errorHandler(error?.response?.data[0])));
        setLoading(false);
    };

    const addAdultHandler = async (id: number, member: MemberDto) => {
        try {
            setError('');
            setLoading(true);
            await packagesProvider.addMemberToGroup(id, member);
            onChange();
        } catch (error) {
            handleError(error as AxiosError);
        }
    };

    const removeAdultHandler = async (id: number, pesel: string) => {
        try {
            setError('');
            setLoading(true);
            await packagesProvider
                .removeGroupMember(id, pesel)
                .catch(handleError);
            onChange();
        } catch (error) {
            handleError(error as AxiosError);
        }
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.horizontalPadding}>
                <Box className={classes.packageName}>{getTranslation(groupPackage.name, groupPackage.translations, currentLanguage, 'groupPackage_name')}</Box>
                {limits.map((limit, idx) => (
                    <Box key={idx} className={classes.remainingServiceLimit}>
                        {getTranslation(limit.medicalServiceGroup, limit.translations, currentLanguage, "description")}
                        <span style={{fontWeight: 'bold'}}>
                            {!limit.hideLimit && (<span>:{' '}{t('availableAppointmentsNum')}: {limit.remainingLimit}</span>) }                       
                        </span>{' '}
                        <span style={{color: LIGHT_DISABLED}}>
                            ({t('to')} {getShortDateFromISO(validTo)})
                        </span>
                        {limit.medicalServices && 
                            <ul>
                                {limit.medicalServices.map((medicalService, i)=>(
                                    <li
                                        key={i}
                                    >
                                        <Box>{getTranslation(medicalService.name, medicalService.translations, currentLanguage, "name")}</Box>
                                    </li>

                                ))}
                            </ul>
                        }

                    </Box>
                ))}

                {medicalPackageLimits && medicalPackageLimits.map((limit, idx) =>(
                    <Box key={idx} className={classes.remainingServiceLimit}>
                        {limit.medicalPackages.name}:
                        <span style={{fontWeight: 'bold'}}>
                            {' '}{t('limit')}: {limit.remainingLimit}
                        </span>
                    </Box>
                ))}

                <Grid
                    container
                    direction={isDesktop ? 'row' : 'column'}
                    alignItems={isDesktop ? 'center' : 'flex-start'}
                    style={{
                        marginTop: 16,
                        marginBottom: 8,
                    }}
                >
                    {assignmentMembers && (
                        <MembersList
                            members={assignmentMembers}
                            onRemoveMember={pesel =>
                                removeAdultHandler(id, pesel)
                            }
                            isRemoveEnabled={true}
                        />
                    )}
                </Grid>
                
                {groupPackage.childrenIncluded && (
                    <Grid
                        container
                        direction={isDesktop ? 'row' : 'column'}
                        alignItems={isDesktop ? 'center' : 'flex-start'}
                        style={{
                            marginTop: 16,
                            marginBottom: 8,
                        }}
                    >
                        <Grid item>{t('healthCareForKidsFrom5Yo')}</Grid>
                        <Grid item className={classes.optionButton}>
                            <IconButtonWithText
                                label={t('addChildrenAccount')}
                                icon={<AddCircleOutline />}
                                onClick={() =>
                                    history.push('/account/account-data')
                                }
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
            {memberCanBeAdded && (
                <>
                    <Box className={classes.horizontalLine} />
                    <Box className={classes.horizontalPadding}>
                        <AddPersonToPackageForm
                            onSubmit={(data: MemberDto) =>
                                addAdultHandler(id, data)
                            }
                        />
                        {!!error && <Box style={{color: 'red'}}>{error}</Box>}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default MembershipDetails;
