import useStyles from './ArticlesListStyles';
import {useEffect} from 'react';

import {Box, Grid, Theme, useMediaQuery} from '@material-ui/core';

import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchArticles,
    pageSpecified,
    sizeSpecified,
    selectArticlesIds,
    selectArticlesCount,
    selectArticlesPage,
    selectArticlesSize,
    clearArticles,
} from '../../../store/articleSlice';
import ArticleTileDesktop from '../ArticleTileDesktop/ArticleTileDesktop';
import ArticleTileMobile from '../ArticleTileMobile/ArticleTileMobile';

import Paging from '../../common/Paging/Paging';

interface Props {
    size?: number;
}

const ArticlesList = ({size}: Props) => {
    const dispatch = useAppDispatch();

    const classes = useStyles();
    const articlesIds = useAppSelector(selectArticlesIds);
    const articlesPage = useAppSelector(selectArticlesPage);
    const articlesSize = useAppSelector(selectArticlesSize);
    const articlesCount = useAppSelector(selectArticlesCount);

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    useEffect(() => {
        if (size !== null) dispatch(sizeSpecified(size));

        dispatch(fetchArticles());

        return () => {
            dispatch(clearArticles());
        };
    }, [dispatch]);

    const pageChangeHandler = (page: number) => {
        dispatch(pageSpecified(page));
        dispatch(fetchArticles());
    };

    return (
            <>
                {isDesktop &&
                    articlesIds.map(articleId => (
                        <ArticleTileDesktop
                            key={articleId}
                            articleId={articleId as number}
                        />
                    ))}
                {!isDesktop &&
                    articlesIds.map(articleId => (
                        <ArticleTileMobile
                            key={articleId}
                            articleId={articleId as number}
                        />
                    ))}
                {size === null && articlesSize < articlesCount && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.pagingWrapper}
                    >
                        <Paging
                            page={articlesPage}
                            size={articlesSize}
                            count={articlesCount}
                            onChange={pageChangeHandler}
                        />
                    </Grid>
                )}
            </>
   
    );
};

export default ArticlesList;
