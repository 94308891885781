import React, {useCallback, useEffect} from 'react';
import {Box, Grid} from '@material-ui/core';
import clsx from 'clsx';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Appointment, AppointmentStateEnum} from '../../../../types/appointments';
import useStyles from './ActionButtonsStyles';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';
import {ConfirmOrRejectModal, Loading} from '../../../index';
import {useCancelAppointmentDetailsText} from '../../../../hooks/appointment/useCancelAppointmentDetailsText';
import ActionOnAppointmentButton from '../ActionOnAppointmentButton/ActionOnAppointmentButton';
import CancelOrEvaluateAppointmentButton from '../CancelOrEvaluateAppointmentButton/CancelOrEvaluateAppointmentButton';
import {
    fetchAppointmentDetails,
    selectAppointmentGroup,
    selectAppointmentPaymentstatus,
    selectAppointmentStatus,
} from '../../../../store/appointmentDetailsSlice';
import useCancelAppointment from '../../../../hooks/appointment/useCancelAppointment';
import {ERROR} from '../../../../const/routes';
import usePostCancellationFetchAppointment from '../../../../hooks/appointment/usePostCancellationFetchAppointment';
import { isAppointmentEndedOrCanceled } from '../../../../utils/appointment';

interface Props {
    appointment: Appointment;
}

const ActionButtons = ({appointment}: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useAppDispatch();


    const appointmentStatus = useAppSelector(selectAppointmentStatus);
    const appointmentGroup = useAppSelector(selectAppointmentGroup);
    const appointmentLoading = appointmentStatus === 'loading';

    const appointmentPaymentStatus = useAppSelector(
        selectAppointmentPaymentstatus,
    );

    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const {t: tAppointment} = useTranslation(i18nNamespaces.APPOINTMENT);

    const {
        canCancelWithRefund,
        type: appointmentType,
        id: appointmentId,
        state: {state: appointmentState},
        medic: {userId: medicId},
        medicalService: {id: medicalServiceId},
    } = appointment;

    const {
        cancelAppointmentModalOpen,
        openCancelAppointmentConfirmModal,
        closeCancelAppointmentConfirmModal,
        cancelAppointmentLoading,
        cancelAppointmentAction,
        cancelAppointmentError,
    } = useCancelAppointment();

    const cancelDetailsText = useCancelAppointmentDetailsText(
        appointmentState,
        canCancelWithRefund,
    );

    const appointmentFetch = useCallback(async () => {
        dispatch(
            fetchAppointmentDetails({
                appointmentId: appointmentId,
                fetchSilently: true,
            }),
        );
    }, [appointmentId]);

    const {
        postCancellationAppointmentLoading,
        setPostCancellationAppointmentLoading,
    } = usePostCancellationFetchAppointment(
        appointment,
        appointmentLoading,
        appointmentFetch,
    );

    const cancelAppointmentHandler = useCallback(async () => {
        await cancelAppointmentAction(appointmentId);
        setPostCancellationAppointmentLoading(true);
    }, []);

    const error = cancelAppointmentError || appointmentStatus === 'failed';
    const loading =
        cancelAppointmentLoading ||
        appointmentLoading ||
        postCancellationAppointmentLoading;

    useEffect(() => {
        if (error) {
            history.replace(ERROR);
        }
    }, [error]);

    return (
        <Box>
            <Loading withBackdrop={true} loading={loading} />
            <Grid container className={classes.buttonsContainer}>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.buttonsContainerItem}
                >
                    <ActionOnAppointmentButton
                        appointmentType={appointmentType}
                        appointmentState={appointmentState}
                        appointmentPaymentStatus={appointmentPaymentStatus}
                        appointmentId={appointmentId}
                        medicId={medicId}
                        medicalServiceId={medicalServiceId}
                        appointmentGroup={appointmentGroup}
                        className={classes.actionBtn}
                        bookedByTheAgent={appointment.bookedByAgent}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.buttonsContainerItem}
                >
                    <CancelOrEvaluateAppointmentButton
                        appointmentId={appointmentId}
                        appointmentState={appointmentState}
                        canCancelWithRefund={canCancelWithRefund}
                        cancelAppointmentClick={
                            openCancelAppointmentConfirmModal
                        }
                        className={clsx(
                            classes.actionBtn,
                            classes.rightActionBtn,
                        )}
                        bookedByTheAgent={appointment.bookedByAgent}

                    />
                </Grid>
            </Grid>
            {!!!appointment.bookedByAgent &&
                <Box className={classes.cancelAppointmentMessage}>
                    { cancelDetailsText}
                </Box>
            }
            {appointment.bookedByAgent 
            && appointment.state.state===AppointmentStateEnum.Planned &&
                <Box className={classes.cancelAppointmentMessage}>
                    {tAppointment('alertOnPostpone')}
                </Box>
            }
            {appointment.bookedByAgent 
            && isAppointmentEndedOrCanceled(appointmentState) &&
                <Box className={classes.cancelAppointmentMessage}>
                    {tAppointment('alertOnRepeate')}
                </Box>
            }
            <ConfirmOrRejectModal
                open={cancelAppointmentModalOpen}
                primaryText={tAppointment(
                    'pleaseConfirmYouWantToCancelTheAppointment',
                )}
                confirmButtonText={tCommon('confirm')}
                onConfirm={cancelAppointmentHandler}
                onReject={closeCancelAppointmentConfirmModal}
            />
        </Box>
    );
};

export default ActionButtons;
