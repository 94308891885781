import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {WHITE} from "../../../const/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            color: 'black',
            backgroundColor: WHITE,
            zIndex: theme.zIndex.appBar
        },
        content: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        referPatient: {
            fontSize: 16,
            margin: '0 90px 0 0',
            lineHeight: '18px',
            fontWeight: 'bold',
        },
        closeIcon: {}
    }),
);

export default useStyles;