import React from 'react';
import {Avatar, Badge, Box, Grid, Link} from '@material-ui/core';
import useStyles from './PatientMedicTileStyles';
import {PatientMedic} from '../../../types/patientMedics';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {CloseIcon} from '../../../assets';
import { useHistory } from 'react-router';
import { TIME_SLOTS_PAGE } from '../../../const/routes';
import { parseTimeSlotQuery } from '../../../utils/timeSlots';

interface MedicTileProps {
    medic: PatientMedic;
    onRemove: () => void;
}

const PatientMedicTile = ({medic, onRemove}: MedicTileProps) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);
    const {
        medicTitle,
        medicName,
        medicSurname,
        medicPhotoUrl,
        medicSpecialities,
        medicalServiceId
    } = medic;

    const history = useHistory();

    const searchTimeSlot = (medicalServiceId: number) => {
        history.push({
            pathname: TIME_SLOTS_PAGE,
            search: parseTimeSlotQuery({
                medicalServiceId: medicalServiceId,
            }),
        });
    }
    
    return (
        <Grid container className={classes.container}>
            <Grid container wrap={'nowrap'}>
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    badgeContent={
                        <img
                            src={CloseIcon}
                            alt={'Remove'}
                            className={classes.removeButton}
                            onClick={onRemove}
                        />
                    }
                >
                    <Avatar
                        src={medicPhotoUrl}
                        className={classes.medicPhoto}
                    />
                </Badge>
                <Grid container className={classes.medicData}>
                    <Box className={classes.medicName}>
                        {medicTitle} {medicName} {medicSurname}
                    </Box>
                    <Box>{medicSpecialities?.join(', ')}</Box>
                </Grid>
            </Grid>
            <Link color={'secondary'}
                onClick={() => searchTimeSlot(medicalServiceId)}
            >{t('bookConsultation')}</Link>
        </Grid>
    );
};

export default PatientMedicTile;
