import {Box} from '@material-ui/core';
import {useCallback, useEffect, useState} from 'react';
import {
    Loading,
    MembershipDetails,
    SubscriptionDetails,
} from '../../components';
import packagesProvider from '../../services/packagesProvider';
import {
    IndividualPackageSubscriptionDto,
    MedicalServiceGroupAssignmentDto,
    PatientPackagesDto,
} from '../../types/patientPackages';
import useStyles from './PatientPackagesPageStyles';
import {isEmpty} from 'lodash-es';
import EmptyAccountPage from '../../components/common/EmptyAccountPage/EmptyAccountPage';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import IndividualPackageSubscriptionPaymentHistory from '../../components/timeSlotsSearch/IndividualPackageSubscriptionPaymentHistory/IndividualPackageSubscriptionPaymentHistory';

const PatientPackagesPage = () => {
    const classes = useStyles();
    const [patientPackages, setPatientPackages] =
        useState<PatientPackagesDto>(null);
    const [loading, setLoading] = useState<boolean>();
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.PATIENT_PACKAGES);
    const isListEmpty =
        isEmpty(patientPackages?.groupPackageMemberships) &&
        isEmpty(patientPackages?.individualPackageSubscriptions);

    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [
        individualPackageSubscriptionHistory,
        setIndividualPackageSubscriptionHistory,
    ] = useState<IndividualPackageSubscriptionDto>(null);

    const fetchPackages = useCallback(async () => {
        const patientPackages = await packagesProvider.getPatientPackages();
        setPatientPackages(patientPackages);
        setLoading(false);
    }, []);

    const showHideHistory = (
        subscription: IndividualPackageSubscriptionDto,
    ) => {
        if (!showHistory) setIndividualPackageSubscriptionHistory(subscription);
        else setIndividualPackageSubscriptionHistory(null);

        setShowHistory(!showHistory);
    };

    useEffect(() => {
        setLoading(true);
        fetchPackages();
    }, [fetchPackages, setPatientPackages]);

    return (
        <Box className={classes.container}>
            <Loading loading={loading} withBackdrop />
            {isListEmpty ? (
                <Box className={classes.emptyListContainer}>
                    <Box className={classes.emptyListTitle}>
                        {commonT('yourPackages')}
                    </Box>
                    <EmptyAccountPage label={t('noPackages')} />
                </Box>
            ) : (
                <>
                    {showHistory ? (
                        <>
                            <SubscriptionDetails
                                key={individualPackageSubscriptionHistory.id}
                                subscription={
                                    individualPackageSubscriptionHistory
                                }
                                //medicalServiceGroupAssignments={subs}
                                setLoading={setLoading}
                                onChange={fetchPackages}
                                onShowHistory={showHideHistory}
                                showHistory={showHistory}
                            />
                            <IndividualPackageSubscriptionPaymentHistory
                                subscription={
                                    individualPackageSubscriptionHistory
                                }
                            ></IndividualPackageSubscriptionPaymentHistory>
                        </>
                    ) : (
                        <>
                            {patientPackages?.individualPackageSubscriptions.map(
                                subscription => (
                                    <SubscriptionDetails
                                        key={subscription.id}
                                        subscription={subscription}
                                        setLoading={setLoading}
                                        onChange={fetchPackages}
                                        onShowHistory={showHideHistory}
                                        showHistory={showHistory}
                                    />
                                ),
                            )}
                            {patientPackages?.groupPackageMemberships.map(
                                membership => (
                                    <MembershipDetails
                                        key={membership.id}
                                        membership={membership}
                                        setLoading={setLoading}
                                        onChange={fetchPackages}
                                    />
                                ),
                            )}
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default PatientPackagesPage;
