import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {WHITE} from '../../../const/colors';
import {desktop, mobile} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paymentSection: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
            marginBottom: mobile.contentSmallerSpacing,
        },
        paymentSectionHeader: {
            fontSize: 18,
            fontWeight: 'bold',
        },
        paymentTitleContainer: {
            marginBottom: desktop.contentLargerSpacing,
        },
        paymentTitle: {
            fontSize: 36,
            fontWeight: 'bold',
        },
        timeSlotDetails: {
            fontSize: 16,
        },
        buyerDataForm: {
            padding: mobile.contentLargerSpacing,
        },
        resellerCodeSection: {
            backgroundColor: WHITE,
            padding: mobile.contentLargerSpacing,
            marginBottom: mobile.contentSmallerSpacing,
        },
        resellerCodeSectionTitle: {
            fontSize: 20,
            fontWeight: 'bold',
        },
        [theme.breakpoints.up('sm')]: {
            page: {
                minHeight: '100vh',
            },
            pageContent: {
                flex: 1,
            },
            paymentSection: {
                padding: `32px ${desktop.contentLargerSpacing}`,
            },
            paymentTitleContainer: {
                marginBottom: 56,
            },
            paymentImage: {
                width: 150,
            },
            paymentTitle: {
                fontSize: 56,
                fontWeight: 'bold',
            },
            timeSlotDetails: {
                fontSize: 20,
            },
            buyerDataForm: {
                padding: `${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
            },
            resellerCodeSection: {
                padding: `${desktop.contentSmallerSpacing} ${desktop.contentLargerSpacing}`,
            },
        },
    }),
);

export default useStyles;
