import {memo} from 'react';
import {Box} from '@material-ui/core';
import RegionSelection from './RegionSelection';
import LaboratoryAddresses from './LaboratoryAddresses';

const MedicalLaboratoriesSearch = memo(function MedicalSearchFilters() {
    return (
        <Box>
            <RegionSelection />
            <LaboratoryAddresses />
        </Box>
    )
});

export default MedicalLaboratoriesSearch;