import {makeStyles, Theme} from '@material-ui/core';
import {BACKGROUND_SECONDARY, TEXT_SECONDARY} from '../../../../const/colors';

const useStyles = makeStyles((theme: Theme) => ({
    dropzone: {
        height: '48px',
        minHeight: 'initial',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: BACKGROUND_SECONDARY,
        [theme.breakpoints.up('md')]: {
            height: '80px',
            justifyContent: 'center',
        },
    },
    dropzoneText: {
        color: TEXT_SECONDARY,
        fontSize: '16px',
        marginTop: '105px',
        marginLeft: '10px',
    },
    dropzoneIcon: {
        margin: '0 17px',
    },
    listWrapper: {
        marginTop: '16px',
        [theme.breakpoints.up('md')]: {
            marginTop: '32px',
        },
    },
}));

export default useStyles;
