import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
} from '@material-ui/core';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    PrescriptionInPrescriptionSet,
    PrescriptionSetDto,
} from '../../../types/prescription';
import {TFunction, useTranslation} from 'react-i18next';
import {GetApp} from '@material-ui/icons';
import {Loading} from '../../index';
import {useState} from 'react';
import prescriptionsApi from '../../../api/prescriptions/prescriptionsApi';
import filesDownloadProvider from '../../../services/filesDownloadProvider';
import {getPrescriptionSetFileName} from '../../../utils/prescription';

type PrescriptionsListItemProps = {
    prescriptionSet: PrescriptionSetDto;
    index: number;
    downloadable?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prescriptionDetails: {
            display: 'flex',
            flexDirection: 'column',
        },
        prescriptionHeaderWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }),
);

const PrescriptionSetHeader = ({
    prescriptionSet,
    index,
    t,
}: {
    prescriptionSet: PrescriptionSetDto;
    index: number;
    t: TFunction;
}) => (
    <Box>
        {index + 1}. {t('issueDate')}:{' '}
        {new Date(prescriptionSet.effectiveDateTime).toLocaleDateString('pl')}
        {', '}
        {t('positionsCount')}: {prescriptionSet.prescriptions.length}
        {', '}
        {t('accessCode')}: {prescriptionSet.code}
    </Box>
);

const PrescriptionDetails = ({
    prescription,
    index,
    t,
}: {
    prescription: PrescriptionInPrescriptionSet;
    index: number;
    t: TFunction;
}) => (
    <Box style={{marginLeft: 20}}>
        {prescription.order}
        {'. '}
        {prescription.medicinalProduct.displayValue}
        {', '}
        {prescription.quantity} {t('packs')}
        {', '}
        {prescription.medicationPack.volume} {prescription.medicationPack.unit}
        {', D.S. '}
        {prescription.usageRecommendation}
        {', '}
        {t('paymentRate')}
        {': '}
        {prescription.paymentLevelDisplayValue}
        {', '}
        {t('effectiveDate')}:{' '}
        {new Date(prescription.effectiveDate).toLocaleDateString('pl')}
    </Box>
);
const PrescriptionsListItem = ({
    prescriptionSet,
    index,
    downloadable,
}: PrescriptionsListItemProps) => {
    const {t} = useTranslation(i18nNamespaces.PRESCRIPTIONS);
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const handleClickDownload = async (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.stopPropagation();
        try {
            setLoading(true);
            const {data: url} = await prescriptionsApi.getPrescriptionPdf(
                prescriptionSet.id,
            );
            filesDownloadProvider.downloadFileFromUrl(
                url,
                getPrescriptionSetFileName(prescriptionSet, t),
            );
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Loading withBackdrop loading={loading} />
            <Accordion>
                <AccordionSummary style={{fontWeight: 'bold'}}>
                    <Box className={classes.prescriptionHeaderWrapper}>
                        <PrescriptionSetHeader
                            prescriptionSet={prescriptionSet}
                            index={index}
                            t={t}
                        />
                        {downloadable && (
                            <IconButton
                                size={'small'}
                                style={{marginLeft: 10}}
                                onClick={handleClickDownload}
                            >
                                <GetApp fontSize={'small'} />
                            </IconButton>
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.prescriptionDetails}>
                        {prescriptionSet.prescriptions.map(prescription => (
                            <PrescriptionDetails
                                key={index + prescription.order}
                                prescription={prescription}
                                index={index}
                                t={t}
                            />
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default PrescriptionsListItem;
