import {Redirect, Route, RouteProps} from 'react-router-dom';
import {Role} from '../../../const/auth';
import {ARTICLE_PAGE, LOGIN_PAGE} from '../../../const/routes';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {getToken} from '../../../storage/storage';
import {selectAuthUserData} from '../../../store/auth';
import {generatePath} from 'react-router';
import useQuery from '../../../hooks/useQuery';
import {useEffect} from 'react';

type AuthRouteProps = RouteProps & {
    roles: Role[];
    withoutReturnUrl?: boolean;
};

const AuthRoute = ({roles, withoutReturnUrl, ...props}: AuthRouteProps) => {
    const authUserData = useAppSelector(selectAuthUserData);
    const userHasRoles = roles.includes(authUserData?.role);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const returnUrlParam = () => {
        if (window.location?.pathname && !authUserData && !withoutReturnUrl) {
            const returnUrl = encodeURIComponent(window.location.pathname);
            return `?returnUrl=${returnUrl}`;
        }
        return '';
    };

    const children = userHasRoles ? (
        props.children
    ) : (
        <Redirect to={LOGIN_PAGE + returnUrlParam()} />
    );

    return <Route {...props}>{children}</Route>;
};

export default AuthRoute;
