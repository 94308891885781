import {useState, useEffect, useRef} from 'react';
import {
    AudioTrackPublication,
    Participant,
    RemoteAudioTrack,
    RemoteTrack,
    RemoteVideoTrack,
    VideoTrackPublication,
} from 'twilio-video';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../../../store/auth';
/* eslint @typescript-eslint/no-explicit-any: 0 */
interface Props {
    participant: Participant;
    className?: string;
    activeSinkId?: string;
    browserAudioOutputCapabilitiesOn?: boolean;
}

const VideoParticipant = ({
    participant,
    className,
    activeSinkId,
    browserAudioOutputCapabilitiesOn,
}: Props) => {
    const [videoTrack, setVideoTrack] = useState<RemoteVideoTrack>(null);
    const [audioTrack, setAudioTrack] = useState<RemoteAudioTrack>(null);

    const videoRef = useRef();
    const audioRef = useRef();

    const authUserData = useAppSelector(selectAuthUserData);

    const attachAudioOutputToAudioElement = () => {
        if (
            browserAudioOutputCapabilitiesOn &&
            audioRef?.current &&
            (audioRef as any)?.current?.setSinkId
        ) {
            (audioRef as any).current.setSinkId(activeSinkId);
        }
    };

    const trackpubsToTracks = (
        trackMap: Map<string, VideoTrackPublication | AudioTrackPublication>,
    ) =>
        Array.from(trackMap.values())
            .map((publication: any) => publication.track) // eslint-disable-line @typescript-eslint/no-explicit-any
            .filter(track => track !== null)[0]; //odfiltrowujemy nieistniejące

    useEffect(() => {
        setVideoTrack(trackpubsToTracks(participant.videoTracks));
        setAudioTrack(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = (track: RemoteTrack) => {
            if (track.kind === 'video') {
                setVideoTrack(track);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                track.on('enabled', (track: RemoteVideoTrack) => {
                    console.log('track video enabled');
                    //UŻYTKOWNIK (REMOTE PARTICIPANT) WŁĄCZYŁ KAMERĘ
                });
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                track.on('disabled', (track: RemoteVideoTrack) => {
                    console.log('track video disabled');
                    //UŻYTKOWNIK (REMOTE PARTICIPANT) WYŁĄCZYŁ KAMERĘ
                });
            } else if (track.kind === 'audio') {
                setAudioTrack(track);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                track.on('enabled', (track: RemoteAudioTrack) => {
                    console.log('track audio enabled');
                    //UŻYTKOWNIK (REMOTE PARTICIPANT) WŁĄCZYŁ MIKROFON
                });
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                track.on('disabled', (track: RemoteAudioTrack) => {
                    console.log('track audio disabled');
                    //UŻYTKOWNIK (REMOTE PARTICIPANT) WYŁĄCZYŁ MIKROFON
                });
            }
        };

        //wywoływane gdy zewnętrzny uczestnik rozłączy się (anuluje przesyłanie streamów)
        const trackUnsubscribed = (track: RemoteTrack) => {
            if (track.kind === 'video') {
                setVideoTrack(null);
            } else if (track.kind === 'audio') {
                setAudioTrack(null);
            }
        };

        const trackStarted = (track: RemoteTrack) => {
            if (track.kind === 'audio') {
                track.attach(audioRef.current);
                attachAudioOutputToAudioElement();
            }
        };

        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);
        participant.on('trackStarted', trackStarted);

        if (authUserData && participant.identity === authUserData.userId) {
            participant.on('trackPublished', () => {
                setVideoTrack(trackpubsToTracks(participant.videoTracks));
                setAudioTrack(trackpubsToTracks(participant.audioTracks));
            });
        }

        return () => {
            setVideoTrack(null);
            setAudioTrack(null);
        };
    }, [participant]);

    useEffect(() => {
        if (videoTrack) {
            videoTrack.attach(videoRef.current); //Create an HTMLVideoElement and attach the VideoTrack to it.
            return () => {
                videoTrack.detach(); //Detach the VideoTrack from a previously attached HTMLMediaElement specified by document.querySelector.
            };
        }
    }, [videoTrack]);

    useEffect(() => {
        if (audioTrack) {
            audioTrack.attach(audioRef.current);

            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTrack]);

    useEffect(() => {
        attachAudioOutputToAudioElement();
    }, [activeSinkId]);

    return (
        <>
            <video ref={videoRef} muted={true} className={className} />
            <audio ref={audioRef} muted={false} />
        </>
    );
};

export default VideoParticipant;
