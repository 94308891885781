import {createStyles, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            marginBottom: 64,
        },
        title: {fontSize: 40},
        description: {
            marginTop: 14,
            marginBottom: 48,
            maxWidth: 900,
            textAlign: 'center',
        },
    }),
);

export default useStyles;
