import React, {memo} from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import calendarSectionUseStyles from '../appointmentsCalendarSectionStyles';
import {CardList, CardListItem} from '../../index';
import useReferralsForMedicalTestsContext from '../../../hooks/referrals/patientMedicalTests/useReferralsForMedicalTestsContext';
import PatientReferralForMedicalTests from '../PatientReferralForMedicalTests/PatientReferralForMedicalTests';
import {RED} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: RED,
            padding: '25px 16px',
            [theme.breakpoints.up('sm')]: {
                padding: '25px 0',
            },
        },
    }),
);

const PatientReferralsForMedicalTests = memo(
    function PatientReferralsForMedicalTests() {
        const classes = useStyles();
        const calendarSectionClasses = calendarSectionUseStyles();
        const {t} = useTranslation(i18nNamespaces.REFERRALS);
        const theme = useTheme();
        const isSmallScreenUp = useMediaQuery(theme.breakpoints.up('sm'));
        const {referrals, error} = useReferralsForMedicalTestsContext();

        if (referrals.length === 0) return null;

        return (
            <Box className={calendarSectionClasses.appointmentWrapper}>
                <Box className={calendarSectionClasses.boxTitle}>
                    {t('ReferralsForMedicalTests')}
                </Box>
                {!error ? (
                    <CardList
                        disableFirstItemTopDivider
                        disableLastItemBottomDivider
                    >
                        {referrals.map(referral => (
                            <CardListItem
                                key={referral.id}
                                itemDisableGutters={isSmallScreenUp}
                            >
                                <PatientReferralForMedicalTests
                                    referral={referral}
                                />
                            </CardListItem>
                        ))}
                    </CardList>
                ) : (
                    <Box className={classes.error}>{error}</Box>
                )}
            </Box>
        );
    },
);

export default PatientReferralsForMedicalTests;
