import * as yup from 'yup';
import {isValidPhoneNumber} from 'react-phone-number-input';

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
const REQUIRED_STRING = 'requiredField';
const REQUIRED_CONSENT = 'requiredConsent';

const mojeIDRegisterSchema = yup.object().shape({
    email: yup.string().email('wrongEmail').required(REQUIRED_STRING),
    password: yup
        .string()
        .min(6)
        .required(REQUIRED_STRING)
        .matches(PASSWORD_REGEX, 'passwordRules'),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'passwordsMustBeTheSame')
        .required(REQUIRED_STRING),
    phoneNumber: yup
        .string()
        .min(12, 'tooShortPhoneNumber')
        .test('isValidNumber', 'invalidNumber', value =>
            isValidPhoneNumber(value),
        )
        .required(REQUIRED_STRING),
    streetName: yup.string().required(REQUIRED_STRING),
    houseNumber: yup.string().required(REQUIRED_STRING),
    postcode: yup.string().required(REQUIRED_STRING),
    cityName: yup.string().required(REQUIRED_STRING),
    gender: yup.number().nullable().required(REQUIRED_STRING),
    acceptPolicy: yup.boolean().oneOf([true], REQUIRED_CONSENT),
    acceptPrivacyPolicy: yup.boolean().oneOf([true], REQUIRED_CONSENT),
});

export default mojeIDRegisterSchema;
