import {Avatar, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {isNotNullOrUndefined} from '../../../utils/lang';
import RatingWithCount from '../MedicRating/RatingWithCount';
import TextClip from '../TextClip/TextClip';
import useStyles from './MedicCardWithDetailsStyles';
import {useState} from 'react';
import clsx from 'clsx';

interface Props {
    header: string;
    subheader: string;
    reviewRate: number;
    reviewCount: number;
    description: string;
    extraDetails?: Record<string, string>;
    photoUrl: string;
    noPadding?: boolean;
}

const MedicCardWithDetails = ({
    header,
    subheader,
    reviewRate,
    reviewCount,
    description,
    extraDetails,
    photoUrl,
    noPadding,
}: Props) => {
    const classes = useStyles();
    const [showMore, setShowMore] = useState<boolean>(false);

    const {t} = useTranslation(i18nNamespaces.MEDIC);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);

    return (
        <Box
            className={clsx(classes.container, {
                [classes.containerPadding]: !noPadding,
            })}
        >
            <Avatar
                alt={`${header} photo`}
                src={photoUrl}
                className={classes.avatar}
            />
            <Box className={classes.header}>{header}</Box>
            <Box className={classes.subheader}>{subheader}</Box>
            <Box className={classes.ratingWrapper}>
                <RatingWithCount
                    reviewRate={reviewRate}
                    reviewCount={reviewCount}
                />
            </Box>
            <Box className={classes.description}>{description}</Box>
            <Box style={{marginBottom: 8}}>
                {extraDetails &&
                    showMore &&
                    Object.keys(extraDetails).map(
                        key =>
                            isNotNullOrUndefined(extraDetails[key]) &&
                            extraDetails[key] != '' && (
                                <Box key={key}>
                                    <Box className={classes.extraDetailsTitle}>
                                        {t(key)}
                                    </Box>
                                    <TextClip text={extraDetails[key]} />
                                </Box>
                            ),
                    )}
            </Box>
            {extraDetails && (
                <span
                    className={classes.truncationToggleText}
                    onClick={() => setShowMore(t => !t)}
                >
                    {showMore ? commonT('showLess') : commonT('showMore')}
                </span>
            )}
            <Box className={'clearfix'} />
        </Box>
    );
};

export default MedicCardWithDetails;
