import {createStyles, makeStyles, Theme} from '@material-ui/core';

const CHAT_HEIGHT = 'calc(calc(var(--vh, 1vh) * 100) - 169px)';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
            height: CHAT_HEIGHT,
            boxShadow: 'none',
            [theme.breakpoints.up('md')]: {
                height: 'calc(100vh - 60px)',
                boxShadow: `0 30px 30px rgba(0, 0, 0, 0.1)`,
            },
        },
        messagesList: {
            height: `calc(${CHAT_HEIGHT} - 80px)`,
            overflowY: 'scroll',
            paddingBottom: 30,
            [theme.breakpoints.up('md')]: {
                height: 'calc(100% - 80px)',
            },
        },
        messageWrapper: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        authorMessageWrapper: {
            justifyContent: 'flex-end',
        },
        message: {
            backgroundColor: 'white',
            borderRadius: 12,
            padding: 16,
            maxWidth: 300,
            wordWrap: 'break-word',
        },
        authorMessage: {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
        inputRow: {
            height: 80,
            paddingLeft: 24,
            paddingRight: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'flex-end',
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
        input: {
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            flexGrow: 1,
            height: 48,
            border: 'none',
            fontSize: 16,
            paddingLeft: 17,
            paddingRight: 17,
        },
        sendButton: {
            minWidth: 48,
            minHeight: 48,
            maxWidth: 48,
            maxHeight: 48,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: theme.palette.secondary.main,
        },
    }),
);

export default useStyles;
