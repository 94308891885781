import {Box} from '@material-ui/core';
import useStyles from './AppointmentDataTileMobileStyles';
import {
    Appointment,
    AppointmentStateEnum,
} from '../../../../types/appointments';
import {
    AppointmentSettleStatusLabel,
    AppointmentStateLabel,
    AppointmentTimeDetails,
} from '../../../index';

interface Props {
    appointment: Appointment;
}

const AppointmentDataTileMobile = ({appointment}: Props) => {
    const classes = useStyles();

    const startDateTime = appointment.startDate;
    const appointmentState = appointment.state.state;
    const isInTheFuture = appointmentState === AppointmentStateEnum.Planned;
    const isNow = appointmentState === AppointmentStateEnum.InProgress;

    return (
        <Box className={classes.appointmentDetailsContainer}>
            <AppointmentTimeDetails
                isNow={isNow}
                isInTheFuture={isInTheFuture}
                date={startDateTime}
            />
            <Box className={classes.appointmentDetails}>
                <span className={classes.patientName}>
                    {appointment.child !== null
                        ? `${appointment.child.firstName} ${appointment.child.surname}`
                        : `${appointment.patient.firstName} ${appointment.patient.surname}`}
                </span>
                <br />
                {appointment.medicalService.name}
                <br />
            </Box>
            <Box className={classes.appointmentStatesContainer}>
                <AppointmentStateLabel
                    state={appointmentState}
                    className={classes.stateLabel}
                />
                {
                    <AppointmentSettleStatusLabel
                        isSettled={appointment.state.isSettled}
                        className={classes.stateLabel}
                    />
                }
            </Box>
        </Box>
    );
};

export default AppointmentDataTileMobile;
