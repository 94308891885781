import { GET_APPOINTMENTS_REFERRALS } from "../endpoints";
import { rawHttp } from "../http";
import { AppointmentReferralDto } from "./orderHeadersProvider";


class AppointmentReferralsProvider{

    async getAppointmentReferrals(id: string){
        return (await rawHttp.get<AppointmentReferralDto[]>(GET_APPOINTMENTS_REFERRALS(id))).data;
    }
}

export default new AppointmentReferralsProvider();
