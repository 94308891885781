import {Box, Button, FormGroup, InputAdornment} from '@material-ui/core';
import CustomTextField from '../../common/CustomTextField/CustomTextField';
import useStyles from './DiscountCodeStyles';
import {Add} from '@material-ui/icons';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';

type DiscountCodeProps = {
    onUseDiscountCode: (code: string) => void;
    errorText: string;
    loading: boolean;
};

const DiscountCode = ({
    onUseDiscountCode,
    errorText,
    loading,
}: DiscountCodeProps) => {
    const classes = useStyles();

    const {t} = useTranslation(i18nNamespaces.DISCOUNT_CODE);
    const [discountCode, setDiscountCode] = useState<string>(null);
    const [errorAlert, setErrorAlert] = useState<string>(null);

    useEffect(() => {
        if (!loading) setErrorAlert(errorText);
    }, [errorText, loading]);

    const updateDiscountCode = (code: string) => {
        setDiscountCode(code);
        setErrorAlert(null);
    };

    return (
        <Box className={classes.container}>
            <FormGroup>
                <CustomTextField
                    id="code"
                    name="code"
                    type="text"
                    onChange={v => updateDiscountCode(v.target.value)}
                    error={errorAlert !== null}
                    helperText={t(errorAlert)}
                    placeholder={t('iHaveDiscountCode')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={classes.adorment}
                            >
                                <Button
                                    className={classes.button}
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() =>
                                        onUseDiscountCode(discountCode)
                                    }
                                >
                                    <Add className={classes.icon} />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormGroup>
        </Box>
    );
};
export default DiscountCode;
